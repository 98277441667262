<template>
  <portal to="toolbarTitle">
    <help>
      <template #title>Relatórios de Rupturas</template>
      <p>
        Aqui são exibidos os relatórios de rupturas, por produto, pontos de
        vendas e ocorrências relacionadas a cada item de inventário.
        Independente das caracteristicas individuais de cada relatório, é
        exibida uma
        <strong>estimativa</strong> de possíveis vendas perdidas, em
        <strong>unidades</strong> e <strong>valor</strong>.
      </p>
      <p>
        O intervalo de <strong>datas</strong> presente no filtro, determinará em
        qual intervalo de datas se encontra o <strong>início</strong> da
        ruptura.
      </p>
      <p>
        Cada <strong>ruptura</strong>(ocorrência), que posteriormente é tratada
        em diferentes relatórios, aplica-se a seguinte fórmula:
      </p>
      <ul align="justify">
        <li>
          O momento em que se <strong>inicia</strong> a ruptura, é determinado
          por uma movimentação de estoque de venda, em que o resultado final do
          estoque do item é zero.
        </li>
        <li>
          O momento em que a ruptura é <strong>finalizada</strong>, é
          determinado por uma movimentação de estoque que resulte em uma
          quantidade maior do que zero (pode ser inventário ou abastecimento).
          Destaca-se, que se caso o item não tenha sido abastecido, tal fim é o
          exato presente.
        </li>
        <li>
          Com o início e fim da ruptura, calcula-se o tempo de
          <strong>indisponibilidade</strong> do item.
        </li>
        <li>
          A quantidade de possíveis vendas <strong>perdidas</strong>, é
          calculada com base na média dos últimos 7 dias que antecedem o início
          de cada ruptura. Tal valor, é multiplicado pelo tempo de
          <strong>indisponibilidade</strong>. Destaca-se, que na situação que
          ocorrer uma média que não complete um valor inteiro, será considerado
          <strong>Vendas Perdidas</strong> como zero.
        </li>
        <li>
          O <strong>valor</strong> de possíveis vendas perdidas, é a
          multiplicação de unidades que deixou de vender, pelo valor praticado
          no último planograma em que o item se encontra.
        </li>
      </ul>
      <p></p>
      <help-video  help-code="Ruptures"/>
    </help>
  </portal>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>

