export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "POS ID",
      actions: "Actions",
      customer: "Customer",
      location: "Location",
      specificLocation: "Specific Location",
      idSubEnvironment: "Sub Environment ID",
      subEnvironment: "Sub Environment",
      cnpj: "CNPJ",
      billing: "Billing (last 30 days)",
      machines: "Machine count",
      stores: "Store count",
      nps: "NPS Average",
      name: "Name",
      type: "Type",
      lastUpdated: "Update date",
      items: "Items",
      activate: "Activate",
      remove: "Delete",
      code: "Code",
      description: "Description",
      barCode: "Barcode",
      category: "Category",
      cost: "Cost",
      price: "Price",
      markUp: "Markup",
      responsible: "Responsible",
      applyPriceTableOnPointOfSale: "Apply price table to points of sale",
      removePriceTable: "Remove price table",
      visualizeFranchiseeEnvironment: "View Environment",
      result: 'Result',
      value: 'Value',
      card: 'Card',
      verificationStatus: 'Verification Status',
      event: 'Event',
      channel: 'Channel',
      day: 'Day',
      dateHour: 'Date/Hour',
      date: 'Date',
      initialHour: 'Initial Hour',
      finalHour: 'Final Hour',
      duration: 'Duration',
      ip: 'IP',
      users: 'Users',
      parameters: 'Parameters',
      lockCode: 'Lock Code',
      lockName: 'Lock Name',
      details: 'Details',
      deviceName: 'Device Name',
      openingResponsible: 'responsible for opening',
      cpf: 'CPF',
      ageRestriction: 'Age Restriction',
      status: 'Status',
      product: 'Product',
      productCode: 'Product Code',
      productCategory: 'Product Category',
      quantity: 'Quantity',
      cmv: 'COGS',
      margin: 'Margin',
      selection: 'Selection',

      associatedPos: 'Associated POS',
      inventory: 'Inventory',
      inventoryAddress: 'Inventory Address',
      lastInventory: 'Last Inventory',
      lastPurchaseCost: 'Last Purchase Cost',
      meanCost: 'Mean Cost',
      totalCost: 'Total Cost',
      reserved: 'Reserved',
      minimumCritical: 'Minimum Critical',
      minimumQuantity: 'Minimum Purchase Quantity',

      previousQuantity: 'Previous Quantity',
      originInventory: 'Origin inventory',
      movimentQuantity: 'Moved Quantity',
      destinyInventory: 'Destination inventory',
      endQuantity: 'Final Quantity',
      operation: 'Operation',
      operationId: 'Operation ID',

      nit: "NIT",
      ein: "EIN",
      email : "Email",
      active: "Active",
      wiFiPercentage: 'Wi-Fi (%)',
      mobilePercentage: 'Mobile (%)',
      offlinePercentage: 'Offline (%)',
      pointOfSale: 'Point of Sale',
      productDescription: 'Product Description',
      dateOfReading: 'Reading Date',
      pointOfSaleReading: 'Point of Sale Read',
      typeOfAlert: 'Type of Alert',
    },
    noData: "No results",
    none: "No data",
    total: "Total"
  }
}