export default {
    statusCanceled: 'Canceled',
    statusActive: 'Active',
    purchasesHeaderIdOperation: 'Operation ID',
    purchasesHeaderStatus: 'Status',
    purchasesHeaderIdErp: 'ID in ERP',
    purchasesHeaderNoteNumber: 'Note Number',
    purchasesHeaderInventoryId: 'Inventory ID',
    purchasesHeaderStock: 'Stock',
    purchasesHeaderQuantityItems: 'Quantity of Items',
    purchasesHeaderNoteValue: 'Note Value',
    purchasesHeaderTotalCostPurchase: 'Total Cost of Purchase',
    purchasesHeaderReceivedDate: 'Received Date',
    purchasesError: 'Error searching for purchases'
  };
  