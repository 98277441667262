export default {
    January: 'Enero',
    Jan: 'Ene',
    February: 'Febrero',
    Feb: 'Feb',
    March: 'Marzo',
    Mar: 'Mar',
    April: 'Abril',
    Apr: 'Abr',
    May: 'Mayo',
    June: 'Junio',
    Jun: 'Jun',
    July: 'Julio',
    Jul: 'Jul',
    August: 'Agosto',
    Aug: 'Ago',
    September: 'Septiembre',
    Sep: 'Sep',
    October: 'Octubre',
    Oct: 'Oct',
    November: 'Noviembre',
    Nov: 'Nov',
    December: 'Diciembre',
    Dec: 'Dic',
}