export default {
    filterFilesDownloads: 'Filtering files',
    generatingFileDownloads: 'Generating file',
    fileGenerationFailure: 'Failed to generate file',
    downloadFileError: 'The file could not be downloaded',
    deleteFileError: 'Could not delete file',
    dateDownloads: 'Date',
    fileDownloads: 'File',
    downloadSize: 'Size',
    download: 'Download',
    excelFilesDownloads: 'Excel files',
    compressedFilesDownloads: 'Compressed Files',
    downloadAllDownloads: 'Download all',
    downloadSelected: 'Download selected',
    deleteAllDownloads: 'Delete all',
    deleteSelectedDownloads: 'Delete selected',
    confirmDeleteDownloads: 'Are you sure you want to remove ',
    filesQuestionDownloads: ' file(s)?',
    allFilesQuestionDownloads: 'all files?',
  };