<template>
  <v-dialog max-width="480" v-model="dialog">
    <v-card>
      <v-card-title class="title">
        {{ $t('planogramStatusTitle') }}
      </v-card-title>

      <v-card-text>
        <v-layout row justify-center wrap>
          <v-flex xs4>
            <planogram-status status="Draft"></planogram-status>
          </v-flex>
          <v-flex xs8>
            {{ $t('draftDescription') }}
          </v-flex>
          <v-flex xs4 class="mt-4">
            <planogram-status status="Pending"></planogram-status>
          </v-flex>
          <v-flex xs8 class="mt-4">
            {{ $t('pendingDescription') }}
          </v-flex>
          <v-flex xs4 class="mt-4">
            <planogram-status status="Active"></planogram-status>
          </v-flex>
          <v-flex xs8 class="mt-4">
            {{ $t('activeDescription') }}
          </v-flex>
          <v-flex xs4 class="mt-4">
            <planogram-status status="Archived"></planogram-status>
          </v-flex>
          <v-flex xs8 class="mt-4">
            {{ $t('archivedDescription') }}
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.native="dialog = false">{{ $t('understoodBtn') }}</v-btn>
      </v-card-actions>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
  </v-dialog>
</template>

<script>
import PlanogramStatusChip from '@/planogram/PlanogramStatusChip'

export default {
  data() {
    return {
      dialog: false
    }
  },
  components: {
    'planogram-status': PlanogramStatusChip,
  }
}
</script>