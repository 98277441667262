<template>
    <Help>
        <template #title>
            {{ $t('promotionalLogos.helpBlockLogo.title') }}
        </template>
        <p>
            {{ $t('promotionalLogos.helpBlockLogo.description') }}
        </p>
    </Help>
</template>

<script>
import Help from '@/components/Help.vue';

export default {
    name: 'PointOfSaleDialogHelp',
    components: {
        Help
    }
};
</script>
