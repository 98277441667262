export default {
    titlePosWithoutPickList: 'Todos os pontos de venda',
    titleWithoutPickList: 'Pontos de venda sem Pick List pendente',
    showAllPosPosWithoutPickList: 'Mostrar todos os PDVs',
    selectAtLeastOnePosWithoutPickList: 'Selecione pelo menos um PDV',
    generatePickLists: 'Gerar Pick Lists',
    searchPosWithoutPickList: 'Pesquisar',
    noResultsPosWithoutPickList: 'Sem resultados',
    machineModelColumnPosWithoutPickList: 'Máquina',
    codeColumnPosWithoutPickList: 'Código PDV',
    customerColumnPosWithoutPickList: 'Cliente',
    locationColumnPosWithoutPickList: 'Local',
    specificLocationColumnPosWithoutPickList: 'Local específico',
    idColumnPosWithoutPickList: 'PDV',
  };