export default {
    detailXmlDialogInf: 'INVOICE INFORMATION',
    detailXmlDialogAddProducts: 'Details',
    detailXmlDialogReceiptNumber: 'Invoice Number',
    detailXmlDialogReceiptSeries: 'Series',
    detailXmlDialogReceiptSupplier: 'Supplier',
    detailXmlDialogReceiptAccessKey: 'Access Key (NFe)',
    detailXmlDialogReceiptIssueDate: 'Issue Date',
    detailXmlDialogReceiptProducts: 'Products',
    detailXmlDialogReceiptDelete: 'Delete',
    detailXmlDialogReceiptReceiveProducts: 'Receive Products',
    detailXmlDialogReceiptCode: 'Code',
    detailXmlDialogReceiptProduct: 'Product',
    detailXmlDialogReceiptQuantity: 'Quantity on Invoice',
    detailXmlDialogReceiptPurchaseUnit: 'Purchase Unit',
    detailXmlDialogReceiptTotalUnit: 'Total Unit',
    detailXmlDialogReceiptUnitPrice: 'Unit Price',
    detailXmlDialogReceiptTotalPrice: 'Total Price',
    detailXmlDialogReceiptActions: 'Actions',
    detailXmlDialogReceiptSuccess: 'Purchase successfully inserted',
    detailXmlDialogReceiptFailure: 'Failed to insert purchase',
    detailXmlDialogReceiptTotalPurchaseValue: 'Total Purchase Value',
    detailXmlDialogReceiptUnit: 'Unit',
    detailXmlDialogReceiptBox: 'Box',
    detailXmlDialogReceiptBundle: 'Bundle',
    detailXmlDialogReceiptPackage: 'Package',
    detailXmlDialogReceiptUnknown: 'Unknown',
    detailXmlDialogStockCentral: 'To proceed, fill in the central stock',
};
