<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
    <v-card v-if="invoice">
      <v-card-title>
        <div class="title">{{ $t('detailsInvoiceDialog') }}</div>
      </v-card-title>
      <v-card-text>
        <div>{{ $t('statusInvoiceDialog') }} {{ statusDescription }}</div>

        <template v-if="invoice.status === 'Approved' || invoice.status === 'Denied'">
          <div>{{ $t('createdAtInvoiceDialog') }} {{ $moment(invoice.dateCreated).format('DD/MM/YYYY HH:mm') }}</div>
          <div>{{ $t('updatedAtInvoiceDialog') }} {{ $moment(invoice.dateUpdated).format('DD/MM/YYYY HH:mm') }}</div>
          <div>{{ $t('seriesInvoiceDialog') }} {{ invoice.series }}</div>
          <div>{{ $t('numberInvoiceDialog') }} {{ invoice.number }}</div>
        </template>

        <template v-if="invoice.status === 'Approved'">
          <div><a target="_blank" rel="noopener noreferrer" :href="invoice.danfeUrl">{{ $t('downloadDanfeInvoiceDialog') }}</a></div>
          <div><a target="_blank" rel="noopener noreferrer" :href="invoice.xmlUrl">{{ $t('downloadXmlInvoiceDialog') }}</a></div>
        </template>

        <div v-if="invoice.status !== 'Approved'">{{ $t('reasonInvoiceDialog') }} {{ invoice.statusReason }}</div>
        <feedback ref="feedback"></feedback>
      </v-card-text>
      <v-card-actions>

        <v-dialog v-if="invoice.status !== 'Approved'" v-model="retryConfirmationDialog.enabled" max-width="460">
          <template #activator="{ on }">
            <v-btn v-on="on" text color="primary">{{ $t('retryInvoiceDialog') }}</v-btn>
          </template>

          <v-card>
            <v-card-title>{{ $t('retryConfirmationInvoiceDialog') }}</v-card-title>
            <v-card-text>{{ $t('confirmRetryInvoiceDialog') }}
            <v-switch :label="$t('keepSequentialInvoiceDialog')" v-model="retryConfirmationDialog.keepNumber" light></v-switch>
            <v-alert class="text-left mt-2" dense outlined text type="info" border="left">
              {{ $t('keepSequentialRecommendation') }}
            </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                color="primary" 
                :disabled="retryConfirmationDialog.loading" 
                text 
                @click.native="retryConfirmationDialog.enabled = false"
              >{{ $t('cancelInvoiceDialog') }}</v-btn>
              <v-btn
                color="primary"
                :loading="retryConfirmationDialog.loading"
                text
                @click.native="retryInvoice"
              >{{ $t('retryInvoiceDialog') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.native="dialog = false">{{ $t('closeInvoiceDialog') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text class="pt-4">
        {{ $t('enableNfceInvoiceDialog') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.native="dialog = false">{{ $t('okInvoiceDialog') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    invoice: { type: Object, default: null }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    statusDescription() {
      return this.translateStatus(this.invoice?.status);
    }
  },
  watch: {
    dialog(enabled) {
      if (!enabled) {
        this.retryConfirmationDialog.enabled = false;
        this.retryConfirmationDialog.loading = false;
      }
    }
  },
  data() {
    return {
      retryConfirmationDialog: {
        enabled: false,
        loading: false,
        keepNumber: true
      }
    }
  },
  methods: {
    retryInvoice() {
      const oldInvoiceUuid = this.invoice.id;
      const shouldCreateNewInvoice = !this.retryConfirmationDialog.keepNumber;
      this.retryConfirmationDialog.loading = true;
      this.axios.put(`api/invoicesWeb/${oldInvoiceUuid}/retry?createNew=${shouldCreateNewInvoice}`)
        .then(response => {
          const invoice = response.data;
          if (invoice.status === 'Approved') {
            this.$refs.feedback.handleSuccess(this.$t('successMessageInvoiceDialog'));
          } else {
            this.$refs.feedback.handleError(this.$t('errorMessageInvoiceDialog') +
             `${this.translateStatus(invoice.status)}: ${this.invoice.statusReason}`);
          }
          this.$emit('new-invoice', invoice, oldInvoiceUuid);
        }).catch(error => this.$refs.feedback.handleError(this.$t('retryErrorInvoiceDialog'), error))
        .then(() => this.retryConfirmationDialog.loading = false)
    },
    translateStatus(status) {
      if (status === 'Processing') return this.$t('processingInvoiceDialog');
      if (status === 'Approved') return this.$t('approvedInvoiceDialog');
      if (status === 'Denied') return this.$t('deniedInvoiceDialog');
      if (status === 'RequestError') return this.$t('requestErrorInvoiceDialog');
      else return this.$t('unknownProblemInvoiceDialog');
    }
  }
}
</script>