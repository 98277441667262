export default {
    myProducts: 'Mis productos',
    searchProduct: 'Buscar producto',
    excelProduct: 'Excel',
    noItemsRegisteredProduct: 'No hay elementos registrados',
    confirmRemoveProduct: '¿Estás seguro de que deseas eliminar el producto?',
    noProduct: 'No',
    removeProduct: 'Eliminar',
    errorGenerateExcelProduct: 'Error al generar Excel',
    productSavedSuccessProduct: 'Producto guardado con éxito',
    productUpdatedSuccessProduct: 'Producto actualizado con éxito',
    itemRemovedProduct: 'Elemento eliminado',
    errorRemoveItemProduct: 'Error al eliminar elemento',
    idProduct: 'ID',
    codeProduct: 'Código',
    descriptionProduct: 'Descripción',
    categoryProduct: 'Categoría',
    defaultPriceProduct: 'Precio predeterminado',
    barCodeProduct: 'Código de barras',
    availabilityProduct: 'Disponibilidad',
    actionsProduct: 'Acciones',
    imageProduct: 'Imagen',
    copyProduct: 'Crear copia del producto',
    productsMustBeAddedErp: 'Los productos deben ser agregados desde el ERP',
    groupIdPublicProduct: 'Público',
    groupIdGroupProduct: 'Grupo:',
    dateCreatedProduct:'Fecha de creación',
    viewProductDeleted: 'Mostrar productos inactivos',
    productActivatedSuccess: 'Producto restaurado con éxito',
    errorActivateProduct: 'Error al restaurar producto',
    errorSearchProduct: 'No fue posible cargar los productos, intenta nuevamente',
    chooseAProduct: 'Selecciona un producto',
};
