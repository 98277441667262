// noinspection SpellCheckingInspection

import i18n from "@/locales/i18n"

export default {
    localType: [
      { name: i18n.t('residentialHouse'), value: 'House' },
      { name: i18n.t('residentialApartment'), value: 'Apartment' },
      { name: i18n.t('businessOffice'), value: 'Office' },
      { name: i18n.t('businessIndustry'), value: 'Industry' },
      { name: i18n.t('hospitalLocalType'), value: 'Hospital' },
      { name: i18n.t('university'), value: 'University' },
      { name: i18n.t('othersLocalType'), value: 'Others' },
    ],
    posType: [
      { name: i18n.t('vendingSnacks'), value: 'Snacks' },
      { name: i18n.t('vendingCafe'), value: 'OCS' },
      { name: i18n.t('vendingEntertainment'), value: 'Entertainment' },
      { name: i18n.t('microMarketPosType'), value: 'MicroMarket' },
    ]
  }