export default {
    transactionsTitleTransactionsHelp: 'Transações',
    transactionsDescriptionTransactionsHelp: 'Aqui são exibidas as vendas realizadas nas lojas ou vending machines.',
    resultTransactionsHelp: `<strong>Resultado</strong>: indica se o produto foi entregue.
    Útil no caso de vending machines, onde pode ocorrer uma falha na liberação do produto.`,
    customerTransactionsHelp: '<strong>Cliente</strong>: nome do cliente conforme cadastrado no PDV.',
    locationTransactionsHelp: '<strong>Local</strong>: local e local específico conforme cadastrado no PDV.',
    posTransactionsHelp: '<strong>PDV</strong>: ID do PDV, confome página Cadastro PDVs.',
    amountTransactionsHelp: '<strong>Valor (R$)</strong>: valor pago pelo comprador.',
    marginAmountTransactionsHelp: '<strong>Margem (R$)</strong>: Diferença entre o preço pago pelo comprador e o custo da mercadoria vendida (CMV).',
    marginPercentageTransactionsHelp: '<strong>Margem (%)</strong>: razão, em porcentagem, entre a margem acima e o valor pago pelo comprador.',
    methodTransactionsHelp: '<strong>Método</strong>: forma de pagamento (débito, crédito, voucher, etc).',
    cardTransactionsHelp: '<strong>Cartão</strong>: exibimos os 4 últimos dígitos do cartão quando temos essa informação.',
    dateTimeTransactionsHelp: '<strong>Data/hora</strong>: data da transação no horário deste dispositivo.',
    cmvInfoTransactionsHelp: `Para que as informações de CMV e margem estejam disponíveis, é necessário ter o sistema de gestão e 
    estoque central habilitado no sistema TouchPay.`,
    taxesHelp: '<strong>Impostos (R$)</strong>: representa os impostos cobrados na transação.'
  }
  