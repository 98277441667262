export default {
    myProducts: 'Meus produtos',
    searchProduct: 'Procurar produto',
    excelProduct: 'Excel',
    noItemsRegisteredProduct: 'Sem itens cadastrados',
    confirmRemoveProduct: 'Tem certeza que deseja remover o produto',
    noProduct: 'Não',
    removeProduct: 'Remover',
    errorGenerateExcelProduct: 'Erro ao gerar excel',
    productSavedSuccessProduct: 'Produto salvo com sucesso',
    productUpdatedSuccessProduct: 'Produto atualizado com sucesso',
    itemRemovedProduct: 'Item removido',
    errorRemoveItemProduct: 'Erro ao remover item',
    idProduct: 'ID',
    codeProduct: 'Código',
    descriptionProduct: 'Descrição',
    categoryProduct: 'Categoria',
    defaultPriceProduct: 'Preço padrão',
    barCodeProduct: 'Código de barras',
    availabilityProduct: 'Disponibilidade',
    actionsProduct: 'Ações',
    imageProduct: 'Imagem',
    copyProduct: 'Criar cópia do produto',
    productsMustBeAddedErp: 'Os produtos dever ser adicionados pelo ERP',
    groupIdPublicProduct: 'Público',
    groupIdGroupProduct: 'Grupo: ',
    dateCreatedProduct:'Data de criação',
    dateUpdatedProduct: 'Última atualização',
    costProduct: 'Custo do produto',
    productToTaxGroup: 'Digite o nome do produto que deseja adicionar ao cenário fiscal',
    productCode: 'Código do produto',
    viewProductDeleted: 'Mostrar produtos inativos',
    productActivatedSuccess: 'Produto restaurado com sucesso',
    errorActivateProduct: 'Erro ao restaurar produto',
    errorSearchProduct: 'Não foi possível carregar os produtos, tente novamente',
    chooseAProduct: 'Selecione um produto',
};
  