export default {
    lossesProductAndPosHelpTitle: 'Indicador de pérdida: Productos',
    lossesProductAndPosHelpIntroduction: `Este indicador ofrece un análisis detallado de las pérdidas financieras asociadas a productos o puntos de venta específicos,
    permitiendo una rápida identificación de los principales factores que contribuyen a estas pérdidas.`,    
    lossesProductAndPosHelpP1: `Por defecto, nuestro sistema mostrará:
    <li> Información sobre <strong>pérdidas de productos</strong>. Sin embargo, al seleccionar <strong>"Ver por POS"</strong>, el indicador mostrará las pérdidas por Punto de Venta.</li>
    <li> Información de pérdida por <strong>motivo "Vigencia vencida"</strong>. Sin embargo, al seleccionar otro <strong>"Motivo"</strong>, el indicador mostrará pérdidas según el motivo seleccionado.</li>`,
    lossesProductAndPosHelpProduct: `<strong>Los 5 productos principales con mayor pérdida financiera: </strong>
    Este informe destaca los cinco productos que están provocando las mayores pérdidas financieras en su inventario.
    Identificar estos productos puede ayudarle a comprender mejor las razones detrás de las pérdidas y tomar medidas correctivas como ajustes de precios,
    control de stock o revisión de proveedores.`,
    lossesProductAndPosHelpPos: `<strong>Los 5 principales puntos de venta con mayor pérdida financiera: </strong>
    En este informe encontrará los cinco puntos de venta que están registrando las mayores pérdidas económicas.
    Esto puede ser indicativo de cuestiones operativas o de gestión que deben abordarse, como cuestiones de seguridad, residuos o la necesidad de formación del personal.`,
    lossesProductAndPosHelpInf: `<strong>Importante: </strong>
    Próximamente estará disponible el filtro “Punto de Venta”, ubicado en la parte superior de la página. Sin embargo, el filtro "Fecha" ahora está disponible para esta tarjeta.`,
};