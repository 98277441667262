
import Vue from 'vue';
import { formatCnpj } from '@/utility/TextFormatting';
import agent from '@/api/agent';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import FranchiseeDialog from '@/views/franchisee/components/FranchiseDialog.vue';
import FranchiseeAboutHelp from './helpers/FranchiseeAboutHelp.vue';


export default Vue.extend({
  components: {
    FranchiseeDialog,
    'franchisee-about': FranchiseeAboutHelp,
  },
  data() {
    return {
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['groupId'],
        sortDesc: [false],
        mustSort: true
      },
      franchiseUp: {} as any,
      loading: true,
      show: false,
      aboutDialog: {
        enabled: false,
      },
      translations: {
        response: {
          groups: {
            search: {
              error: this.$t('franchisee.response.groups.search.error').toString(),
            },
            access: {
              error: this.$t('franchisee.response.groups.access.error').toString()
            }
          }
        },
        table: {
          item: {
            visualizeFranchiseeEnvironment: this.$t('tables.headers.visualizeFranchiseeEnvironment').toString(),
          },
          noData: this.$t('tables.noData').toString(),
        },
        button: {
          search: this.$t('buttons.search').toString(),
        }
      },
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getViewFranchisee();
        }
      },
    },
  },
  methods: {
    getViewFranchisee() {
      this.loading = true
      let params = toApiPagination(this.options, this.search.text);
      agent.FranchiseEnvironment.getFranchise(params)
        .then((response) => {
          this.franchiseUp = response
          this.totalItems = response.totalItems

          if (response.items.length === 0 || response.totalItems === 0) {
            this.franchiseUp.items = [];
          }
          this.loading = false
        }).catch(
          (error) => (this.$refs.feedback as any).handleError(this.translations.response.groups.search.error, error)
        )
        .finally(() => {
          this.loading = false
        })
    },
    redirectToExternalSystem(id: number) {
      let token = (this as any).$auth.token();
      localStorage.setItem('oldToken', token);

      agent.FranchiseEnvironment.getFranchiseeAmbient(id).then()
        .catch(
          (error) => (this.$refs.feedback as any).handleError(this.translations.response.groups.access.error, error)
        );
      setTimeout(() => {
        window.location.reload()
      }, 300);
    },
    formatCnpj,
  },
  mounted() {
    this.getViewFranchisee();
  },
  computed: {
    headers() {
      const isBrazil = (this as any).$auth.user().tenantCountry === 'BR';
      const headers = [
        { text: this.$t('tables.headers.idSubEnvironment'), align: 'left', sortable: true, value: 'groupId' },
        { text: this.$t('tables.headers.subEnvironment'), align: 'left', sortable: true, value: 'groupName' },
        { text: this.$t('tables.headers.billing'), align: 'left', sortable: false, value: 'billingMonth' },
        { text: this.$t('tables.headers.machines'), align: 'left', sortable: false, value: 'machineQuantity' },
        { text: this.$t('tables.headers.stores'), align: 'left', sortable: false, value: 'localQuantity' },
        { text: this.$t('tables.headers.nps'), align: 'left', sortable: false, value: 'averageNps' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'toView' },
      ];
      return isBrazil 
        ? headers.slice(0, 2)
          .concat({ text: this.$t('tables.headers.cnpj'), align: 'left', sortable: true, value: 'cnpj' })
          .concat(headers.slice(2))
        : headers;
    }
  }
})
