
import Discounts from './coupons/Discounts.vue';
import HelpVideo from '@/components/HelpVideo.vue'
import Promotions from './promotions/Promotions.vue';

export default {
  data() {
    return {
      activeTab: 0 as number,
    }
  },
  components: {
    Discounts,
    Promotions,
    HelpVideo,
  }
}
