<template>
  <v-row>
    <v-col cols="12" sm-and-down="3" >
      <v-card class="mb-16">
        <v-card-title>
          <div class="title">{{ $t('myCategories') }}</div>
          <v-spacer></v-spacer>
          <v-col cols="12" class="py-0" order-sm-and-down="2" md="3" sm="6">
            <v-form
              ref="form"
              lazy-validation
              v-model="search.value"
              @submit.prevent="getCategories"
            >
              <v-text-field
                class="mr-2 pt-0"
                v-model="search.text"
                append-icon="search"
                :label="$t('searchCategory')"
                single-line
                hide-details
                clearable
                filled
                rounded
                dense
                @click:clear="search.text = ''; getCategories();"
                @click:append="options.page = 1; getCategories()"
                @input="options.page = 1"
              ></v-text-field>
            </v-form>
          </v-col>
          <categories-dialog
            v-model="itemDialog"
            :edited-index="editedIndex"
            :edited-entry="editedEntry"
            :pos-link="queryParams.pdv"
            :planogram-link="queryParams.pog"
            @success="(message) => handleSuccess(message)"
            @error="(message, error) => handleError(message, error)"
            order-sm-and-down="1"
          ></categories-dialog>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          item-key="id"
          :loading="loading.categories"
          :headers="headers"
          :items="categories"
          :options.sync="options"
          :server-items-length="totalItems"
          :no-data-text="$t('noCategories')"
          :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          :search="search.text"
        >

        <template #item.name=" { item }">
          <v-edit-dialog
            v-if="enabled.category"
            large
            :cancel-text="$t('informationCancel')"
            :save-text="$t('informationSave')"
            @open="openNameText(item)"
            @save="saveNameText(item)"
            @cancel="cancelNameText"
          >{{ item.name }}
            <v-icon class="pb-1 pl-1">edit</v-icon>
            <template #input>
              <v-text-field
                v-model="editedName"
                :label="$t('categoryName')"
                :rules="[rules.validCharacters]"
                single-line>
              </v-text-field>
            </template>
            </v-edit-dialog>
            <template v-else>
              {{ item.name }}
            </template>
        </template>
       <template #item.actions="{ item }" class="justify-center px-0">
          <v-btn icon class="mx-0" @click.native.stop="confirmRemoval(item)" :disabled="!enabled.category">
            <v-icon color="error">delete</v-icon>
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </v-col>

    <feedback ref="feedback"></feedback>


    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">{{ $t('confirmDeleteCategory') }} {{removeDialog.item.name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('cancelCategory') }}</v-btn>
          <v-btn color="green darken-1" :loading="loading.removeItem" text @click.native="deleteItem(removeDialog.item)">{{ $t('removeCategory') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import { shouldRefreshData, toApiPagination } from '../../components/TableOptionsComparator';

export default {
  data() {
    return {
      rules: {validCharacters: (v) => /^[\w.\-+ ]*$/.test(v) || this.$t('invalidCharacters') },
      // selected: [],
      categories: [],
      itemDialog: false,
      editedIndex: -1,
      categoryRegistrationDisabled: false,
      headers: [
        { text: this.$t('idCategory'), align: "left", sortable: true, value: "id" },
        { text: this.$t('categoryName'), align: "left", sortable: true, value: "name" },
        { text: this.$t('numberOfProductsCategory'), align: "left", sortable: true, value: "productCount"},
        { text: this.$t('actionsCategory'), align: "left", sortable: false, value: "actions" }
      ],
      loading: {
        categories: true,
        removeItem: false,
        newCategory: true
      },
      editedName: '',
      editedEntry: {},
      removeDialog: {
        enabled: false,
        item: {}
      },
      search: {
        text: "",
        valid: true,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["id"],
        sortDesc: [false],
        mustSort: true,
      },
      queryParams: {
        id: null,
        pdv: null,
        pog: null,
      },
      enabled: {
        category: true
      }
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getCategories();
        }
      },
      deep: true
    },
    itemDialog(isOpen) {
      if(!isOpen) {
        this.editedIndex = -1;
        this.editedEntry = this.newEditedEntry();
      }
    }
  },
  mounted() {
    const params = Object.assign({}, this.$route.query);
    if (params) {
      this.queryParams.id = params.id
      this.queryParams.pdv = params.pdv
      this.queryParams.pog = params.pog
      delete params.id
      delete params.pdv
      delete params.pog
      this.$router.replace({ query: params});
    }

    this.getCategories();
    this.IsCategoryEnabled();
  },
  methods: {
    IsCategoryEnabled(){
      const type = 'ProductCategory';
      this.axios.get(`api/Features/Enabled/${type}`)
        .then((response) => {
          this.enabled.category = response.data;
        });
    },
    getCategories() {
      this.loading.categories = true;
      this.axios.get('api/ProductCategories/details', { params: toApiPagination(this.options, this.search.text) })
        .then((response) => {
          this.loading.categories = false;
          this.categories = response.data.items;
          this.totalItems = response.data.totalItems;
        });

      if (this.queryParams.id) {
        this.editItemByRouteId(this.queryParams.id)
      }
    },
    editItemByRouteId(id){
      this.axios.get('api/Products/' + id)
        .then((response) => {
          this.editedIndex = -2; // is existing item from route
          this.editedEntry = Object.assign({}, response.data);
          this.itemDialog = true;
        });
    },
    editItem(item){
      this.editedIndex = this.categories.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.itemDialog = true;
    },
    confirmRemoval(item) {
      this.removeDialog.item = item;
      this.removeDialog.enabled = true;
    },
    deleteItem(item) {
      this.loading.removeItem = true;
      this.axios.delete(`api/ProductCategories/${item.id}`)
        .then(() => {
          const entryIndex = this.categories.indexOf(item);
          this.categories.splice(entryIndex, 1);
          this.handleSuccess(this.$t('categoryRemovedCategory'));
        }).catch((error) => this.handleError(this.$t('errorRemovingCategory'), error))
          .then(() => {
            this.loading.removeItem = false;
          });
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.removeDialog.enabled = false;
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    openNameText(item) {
      this.editedName = item.name;
    },
    saveNameText(item) {
      const nameToSave = this.editedName;
      if (this.rules.validCharacters(nameToSave) === this.$t('invalidCharacters')) {
        this.editedName = '';
        this.$refs.feedback.handleError(this.$t('categoryNameNotChanged'));
        return;
      }
      this.loading.table = true;
      const newItem = {name: nameToSave, id: item.id};
      this.axios.put(`api/ProductCategories/${item.id}`, newItem)
        .then(() => {
          item.name = nameToSave;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('errorChangingName'), error);
        }).then(() => {
          this.loading.table = false;
          this.editedName = '';
        })
    },
    cancelNameText() {
      this.editedName = '';
    }


  }
};
</script>
