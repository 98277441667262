export default {
    resetSelectedPickListDetails: 'Zerar selecionados',
    showZeroPickListDetails: 'Mostrar zerados',
    searchProductPickListDetails: 'Procurar produto',
    productCodePickListDetails: 'Código produto',
    productNamePickListDetails: 'Nome do Produto',
    productBarCodePickListDetails: 'Código de Barras',
    categoryPickListDetails: 'Categoria',
    quantityPickListDetails: 'Quantidade',
    currentQuantityPickListDetails: 'Qtd. Atual',
    desiredQuantityPickListDetails: 'Qtd. Desejada',
    criticalMinimumPickListDetails: 'Mínimo Crítico',
    salesThisWeekPickListDetails: 'Vendas Últimos 7 dias',
    centralStockQuantity: 'Qtd. Estoque Central',
    closePickListDetails: 'Fechar',
    pickListInfoPickListDetails: 'Pick List ',
    pickListInfoPosDetails: 'PDV', 
    pickListInfoPlanogramDetails: ' Planograma ', 
    pickListInfoCreatedDetails: 'Criada: ', 
    pickListInfoUpdatedDetails: 'Atualizada: ', 
    changeErrorPickListDetails: 'Erro ao alterar quantidade',
    quantityNotChangedPickListDetails: 'Quantidade não alterada: formato do número é inválido',
    cancelPickListDetails: 'Cancelar',
    toSavePickListDetails: 'Salvar',
    emptySelectedError: 'Erro ao alterar zerar itens',
    pendingPickListDetails: 'Pendente',
    usedPickListDetails: 'Usada',
};
  