<template>
  <v-dialog persistent v-model="show" max-width="720" @keydown.esc="show = false">
    <v-card>
      <v-card-title>
        {{ $t('changeLogo') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <editable-image
              :image-url="imageUrl"
              :image-path="imagePath"
              :use-existing="this.imagePath != null"
              entity-type="logo"
              :entity-id="posId.toString()"
              @create="onImageCreated"
              @delete="onImageDeleted"
              @cancel-existing="imageUrl = null; imagePath = null"
            ></editable-image>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="subtitle">{{ $t('suggestionsSubtitle') }}</div>
            <v-progress-linear v-if="loadingSuggestions" indeterminate></v-progress-linear>
            <v-row v-else align="start">
              <v-col v-for="image in suggestions" :key="image.path" cols="6" md="4">
                <span class="clickable">
                  <v-img :src="image.url" contain @click="onImageClicked(image)"></v-img>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="show = false">{{ $t('cancelPointOfSaleImageSelector') }}</v-btn>
      </v-card-actions>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import EditableImage from '../components/EditableImage.vue';
export default {
  components: { EditableImage },
  props: {
    posId: { type: Number, required: true }
  },
  data() {
    return {
      show: false,
      suggestions: [],
      loadingSuggestions: true,
      imageUrl: null,
      imagePath: null,
      created: false
    }
  },
  watch: {
    show(value) {
      if (!value) {
        this.imagePath = null;
        this.imageUrl = null;
      }
    }
  },
  mounted() {
    this.axios.get('api/images/logo/suggestions')
      .then(response => {
        this.suggestions = response.data;
        this.loadingSuggestions = false;
      })
  },
  methods: {
    onImageClicked(image) {
      this.imageUrl = image.url;
      this.imagePath = image.path;
    },
    onImageCreated(_, url) {
      this.show = false;
      this.$emit('logo-change', url);
    },
    onImageDeleted() {
      this.show = false;
      this.imagePath = null;
      this.imageUrl = null;
      this.$emit('logo-change', null);
    }
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer
  }
</style>