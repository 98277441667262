export default {
    planogramMainHelpTitle: 'Planogramas por PDV',
    introParagraphHelp: 'Essa página lista os planogramas ativos, pendentes e rascunhos associados a cada ponto de venda. Além disso, permite ações em lote para planogramas, como copiar um planograma para vários PDVs, adicionar um item em vários planogramas e modificar o preço de um produto em vários planogramas.',
    headingHelp: 'Copiando um planograma para vários PDVs',
    descriptionPosHelp: 'Selecione os PDVs de destino, e clique no botão Planogramas. Marque a opção "Copiar de outro planograma", e selecione o ponto de venda e planograma de origem. Em seguida, clique em Criar.',
    additionalInfoHelp: 'Os planogramas são criados no modo rascunho para que possam ser feitas eventuais alterações. Portanto, é necessário finalizá-los para que possam ser ativados nos PDVs. Só é possível selecionar PDVs que já não possuam planogramas no modo rascunho ou pendente.',
    addSeveralHelp: 'Adicionando um item em vários planogramas',
    selectPosHelp: 'Selecione os PDVs em que deseja fazer essa operação, e clique no botão add Itens. Os PDVs selecionados devem possuir um planograma ativo ou rascunho. Caso o PDV já possua um planograma rascunho, o item será adicionado nesse planograma. Caso possua apenas o planograma ativo, o item será adicionado em um novo planograma rascunho que é cópia do planograma ativo.',
    modifyingProductHelp: 'Modificando um produto em vários planogramas',
    selectingProductsToModifyHelp: 'Selecione os PDVs em que os produtos serão modificados, e clique no botão edit Itens. Os PDVs selecionados devem possuir um planograma ativo ou rascunho. Caso o PDV já possua um planograma rascunho, os produtos serão atualizados nesse planograma. Caso possua apenas o planograma ativo, os produtos serão modificados em um novo planograma rascunho que é cópia do planograma ativo.',
    
};