<template>
  <div>
    <slot :loading="loading" :download="download">
    </slot>

    <v-dialog v-model="dialog" max-width="520" @keydown.esc="dialog = false">
      <v-card>
        <v-card-title>
          {{ $t('downloadTitleInvoiceXml') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t('downloadInitiated') }} <strong>{{ filename }}</strong></p>
          <p>{{ $t('fileAvailableFor24Hours') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="dialog = false">{{ $t('closeInvoiceXml') }}</v-btn>
          <v-btn color="primary" text :to="`/${$t('path.nameDownloads')}`">{{ $t('goToDownloadsInvoiceXml') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    initialDate: { type: String, default: null },
    finalDate: { type: String, default: null },
    customerId: { type: Number, default: null },
    localId: { type: Number, default: null },
    pointOfSaleId: { type: Number, default: null }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      filename: ''
    }
  },
  methods: {
    download() {
      this.loading = true;
      this.axios.get('api/invoicesWeb/xml', { params: 
      { 
        initialDate: this.initialDate, 
        finalDate: this.finalDate, 
        customerId: this.customerId, 
        localId: this.localId,
        pointOfSaleId: this.pointOfSaleId
      }}).then((response) => {
        this.filename = response.data.filename;
        this.dialog = true;
      }).catch((error) => this.$emit('error', error))
      .then(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
  .fixed {
    position: fixed;
  }
</style>