export default {
    usability: 'Usability',
    easeOfUse: 'Ease of Use',
    confusingPage: 'Confusing Page',
    performance: 'Performance',
    speed: 'Speed',
    slowPage: 'Slow Page',
    usefulness: 'Usefulness',
    usefulnessPositive: 'Usefulness',
    usefulnessNegative: 'Not Useful',
    errors: 'Errors',
    errorsPositive: 'No Errors',
    errorsNegative: 'Errors on Page',
    others: 'Others',
    othersPositive: 'Others',
    othersNegative: 'Others',
  };
  