export default {
  searchBoxManagement: 'Pesquisar',
  boxesManagement: 'Dispositivos',
  noResultsBoxManagement: 'Sin resultados',
  idBoxManagement: 'ID',
  pdvBoxManagement: 'PDV',
  acquirerBoxManagement: 'Modelo',
  versionBoxManagement: 'Versión',
  passwordBoxManagement: 'Contraseña',
  notFoundMasterBarcode: 'Código maestro no encontrado',
};
