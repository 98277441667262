
import Vue from 'vue';
interface KeyTranslationValue {
  [key: string]: string;
}

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    targetType: {type: String, required: true}
  },
  data() {
    return {
      translations: {
        button: {
          no: this.$t('buttons.no').toString(),
          yes: this.$t('buttons.yes').toString()
        },
        dialog: {
          table: {
            action: {
              change: {
                PriceOnly: this.$t('priceTable.help.table.action.toPrice').toString(),
                Markup: this.$t('priceTable.help.table.action.toMarkUp').toString()
              } as KeyTranslationValue,
            },
          }
        }
      },
    }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    confirmChange() {
      this.$emit("confirm-change");
    }
  }
});
