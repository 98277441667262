export default {
  audit: {
    title: "Auditoria",
    helpMaster: {
      description: `Aqui é exibida a auditoria de edições no sistema WEB. A auditoria permite que os administradores vejam quais usuários criaram, editaram ou excluiram certos dados no sistema, ajudando assim a rastrear erros no uso do sistema.`
    },
    helpTable: {
      description: `
        <p align="justify">
          Aqui, as alterações feitas em {auditCategoryName} usando o sistema AMLabs TouchPay
          podem ser visualizadas em formato de tabela.
        </p>
        <ul align="justify">
          <li>
            <strong>IP:</strong> IP do computador que fez a modificação mostrada na linha.
          </li>
          <li>
            <strong>Usuário:</strong> Usuário que fez a modificação mostrada na linha.
          </li>
          <li>
            <strong>Data e Hora:</strong> Momento em que a modificação mostrada na linha foi feita.
          </li>
          <li>
            <strong>Parâmetros:</strong> Parâmetros que foram usados para fazer a modificação mostrada na linha.
          </li>
          <li>
            <strong>Ação:</strong> Ação que foi tomada na modificação mostrada na linha.
          </li>
        </ul>
      `
    },
    actions: {
      planogram: {
        title: "Planograma",
        finishPlanogram: "Finalização de planograma",
        bulkFinishPlanograms: "Finalização de planogramas em lote",
        activatePlanogram: "Ativação de planograma",
        bulkActivatePlanograms: "Ativação de planogramas em lote",
        postPlanogram: "Criação de planograma",
        bulkPostPlanogram: "Criação de planogramas em lote",
        deletePlanogram: "Remoção de planograma",
        bulkDeletePlanograms: "Remoção de planogramas em lote",
        postPlanogramWithoutUnusedProduct: "Criação de planograma removendo produtos sem movimentação"
      },
      pointsOfSale: {
          title: "Pontos de venda",
          putPointOfSale: "Edição do ponto de venda",
          postPointOfSale: "Criação do ponto de venda",
          deletePointOfSale: "Remoção do ponto de venda",
          restoreDeletedPointOfSale: "Restauração do ponto de venda"
      },
      priceTable: {
          title: "Tabela de preços",
          postPriceTable: "Criação de tabela de preços",
          patchPriceTable: "Edição de nome da tabela de preços",
          patchPriceTableType: "Edição de tipo da tabela de preços",
          deletePriceTable: "Remoção de Tabela de Preços",
          deletePriceTableItem: "Remoção de item da tabela de preços",
          bulkApplyPriceTableNew: "Aplicação em lote de tabela de preços",
          putUpdateCostFromDistributionCenter: "Atualização de custos pelo estoque central"
      },
      products: {
          title: "Produtos",
          putProduct: "Edição de produto",
          postProduct: "Adição de produto",
          deleteProduct: "Remoção de produto"
      },
      taxScenarios: {
          title: "Cenários fiscais",
          mapProductToTaxGroup: "Modificação de produto no cenário fiscal",
          addProductToTaxGroup: "Produto adicionado ao cenário fiscal",
          removeProductFromTaxGroup: "Produto removido do cenário fiscal",
          postBrazilianTaxing: "Criação de cenário fiscal",
          putBrazilianTaxing: "Atualização de cenário fiscal",
          deleteBrazilianTaxGroup: "Remoção do cenário fiscal"
      },
      users: {
          title: "Usuários",
          deleteUsers: "Remoção de usuário",
          putUsers: "Edição de usuário",
          postUsers: "Criação de usuário"
      }
    }
  }
}
