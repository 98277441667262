export default {
    lastSupplyTitle: 'Últimas operaciones',
    lastSupplySubTitle:'Verifique las operaciones más recientes',
    lastSupplyLabel: 'Tipo de operación',
    lastSupplyColumnPos: 'Número POS',
    lastSupplyColumnCustomerLocation: 'Cliente/Ubicación',
    lastSupplyColumnOperationType: 'Tipo de operación',
    lastSupplyColumnDate: 'Fecha',
    lastSupplyColumnLosses: 'Pérdidas',
    lastSupplyTypeOperationsSupply: 'Suministro',
    lastSupplyTypeOperationsInventory: 'Inventario',
    lastSupplyTypeOperationsPicking: 'Seleccionando',
    lastSupplyTypeOperationsCombined: 'Combinado',
    lastSupplyTypeOperationsFull: 'Completo',
    lastSupplyColumnUser: 'Usuario',
    lastSupplyColumnDuration: 'Duración',
};