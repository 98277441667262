export default{
  alerts:{
    title: "Alertas",
    subtitle: "Adicionar novo email para alerta",
    form:{
      email: "Email",
    },
    table:{
      title: "Alertas cadastrados",
    },
    actions:{
      remove: {
        message: "Tem certeza que deseja remover o email?",
      },
    },
    help:{
      title: "Alertas",
      description: `<p>Fique por dentro do status dos terminais de pagamento.
      Cadastre seu e-mail abaixo para ser notificado em caso de queda significativa no serviço.</p>
      <strong>Atenção</strong>: Use um e-mail ativo para garantir que as notificações cheguem até você.`,
    }
  },
}