
// eslint-disable-next-line no-unused-vars
import Vue, { DirectiveFunction } from 'vue';
// @ts-ignore
import { mask } from 'vue-the-mask';
import { TenantFeedbackCategory, TenantFeedbackForm, feedbackCategories } from "@/types/TenantFeedback";
import PhoneNumberFormatter from '@/utility/PhoneNumberFormatter';

const maskdir = mask as DirectiveFunction;

export default Vue.extend({
  directives: { maskdir },
  mixins: [PhoneNumberFormatter],
  data() {
    return {
      dialog: false as boolean,
      cancelDialog: false as boolean,
      form: {
        webPage: "" as string,
        grade: 5 as number,
        comment: "" as string, 
        categories: [] as string[],
        contactNumber: null
      } as TenantFeedbackForm,
      maskedContactNumber: "" as string,
      valid: false as boolean,
      loading: false as boolean,
      feedbackCategories: [...feedbackCategories],
      hasSentReview: false as boolean
    }
  },
  computed: {
    page() { return this.$route.path },
    // ratingHoverIndex() { return this.$refs.rating.$data.hoverIndex } a suggestion to be implemented
    rules() {
      const rules: { (s: any): string|boolean; }[] = []

      if (this.form.categories.includes("Errors") && this.form.grade < 4) {
        let rule = (v: any) => !!v || this.$t('errorThatHappens') as string;
        rules.push(rule);
      }

      if (this.form.categories.includes("Others")) {
        let rule = (v: any) => !!v || this.$t('feedbackReason') as string;
        rules.push(rule);
      }

      return rules;
    },
    filteredCategories(): TenantFeedbackCategory[] | unknown {
      if (this.form == null) {
        return this.feedbackCategories;
      }
      else {
        // to remove "errors" as a positive review category
        return this.form.grade > 3 ? this.feedbackCategories.filter(fbc => fbc.value != 'Errors') : this.feedbackCategories;
      }
    },
    dialogWidth(): number {
      if (this.hasSentReview == true) {
        return 500;
      }
      else {
        return 800;
      }
    },
  },
  watch: {
    page() {
      this.clearForm();
      this.hasSentReview = false
    },
    'form.grade'(value) {
      if (value < 4 && this.form.categories.length == 0) {
        this.form.categories = ["Errors"];
      }
      if (value > 3 ) { // to remove "errors" as a positive review category
        this.form.categories = this.form.categories.filter(fbc => fbc != 'Errors');
      }
    },
    maskedContactNumber(value: string) {
      const contactNumber = value.replace(/\D/g, '');
      this.form.contactNumber = contactNumber != "" ? contactNumber : null;
    }
  },
  methods: {
    postTenantFeedback() {
      if((this.$refs.form as any).validate()){
        this.loading = true;
        this.form.webPage = this.page.split('/')[1];
        this.axios.post('api/TenantFeedback', this.form)
          .then(() => {
            this.loading = false;
            this.hasSentReview = true;
          }).catch((error: any) => {
            this.handleError(String(this.$t('postTenantFeedbackError')), error);
            this.loading = false;
          })
      }
    },
    closeFeedback() {
      this.cancelDialog = false;
      this.clearForm();
      this.$emit('close');
    },
    clearForm() {
      const newForm = {
        webPage: "" as string,
        grade: 5 as number,
        comment: "" as string, 
        categories: [] as string[],
        contactNumber: null
      } as TenantFeedbackForm
      Object.assign(this.form, newForm);
      this.maskedContactNumber = "";
    },
    getCategoryText(category: TenantFeedbackCategory) {
      return this.form.grade >= 4 ? category.positiveText : category.negativeText;
    },
    getOpinionText() {
      switch(true) {
        case this.form.grade < 4:
          return this.$t('whatsWrong');
        case this.form.grade == 4:
          return this.$t('whatCanImprove');
        case this.form.grade == 5:
          return this.$t('whatPleasedYou');
      }
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
})
