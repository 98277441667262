export default{
    titleProductsPurchase: 'INVOICE INFORMATION',
    productsProductsPurchase: 'Products',
    productsComplementProductsPurchase: 'of',
    infoProductsPurchase: 'Product information',
    helpProductsPurchase: 'Add products to the invoice manually, to add a new product click next, to finalize the invoice click finish',
    selectedProductsPurchase: 'Product already selected',
    unitInvoiceProductsPurchase: 'Unit on the invoice',
    valueUnitHelpTitleProductsPurchase: 'Value per unit',
    valueUnitHelpProductsPurchase: 'Enter the value of each unit and the quantity of the corresponding product on the invoice.',
    quantityProductsPurchase: 'Quantity of the item:',
    quantityHelpTitleProductsPurchase: 'Conversion unit / Units',
    quantityHelpProductsPurchase: 'Select the conversion unit in the system. (like units, boxes or bales) and indicate how many units make up this conversion measure (for example, unit = 1, box = 10, bale = 6).',
    quantityTotalProductsPurchase: 'Total quantity of the product:',
    quantityTotalHelpTitleProductsPurchase: 'Total quantity of the product',
    quantityTotalHelpProductsPurchase: `After entering the quantity and cost data, our system will automatically calculate the total units 
    the corresponding unit price. For example, when entering two boxes, each containing 10 units, at a cost of 
    10 reais per box: Total units: 20 | Unit price: R$ 1.00. This functionality simplifies the visualization of 
    the total products and their cost per unit, making it easier to analyze the information.`,
    valueUnitProductPurchase: 'Unit value of the product:',
    productNotRegistry: 'Product not registered?',
    closeProductsPurchase: 'CLOSE',
    finishProductsPurchase: 'ADD AND FINALIZE',
    backProductsPurchase: 'BACK',
    addProductsPurchase: 'ADD OTHER',
    nextProductsPurchase: 'NEXT',
    messageValidButtonProductsPurchase: 'To proceed, fill in all the data',
    unitConversionProductsPurchase: 'Conversion unit',
    valueProductPurchase: 'Value in $',
    removeProductsPurchase: 'REMOVE',
}
