
import Vue from 'vue';
import CompaniesTable from './CompaniesTable.vue';
import InvoicesTable from './InvoicesTable.vue';

export default Vue.extend({
  components: {
    InvoicesTable,
    CompaniesTable
  },
  data() {
    return {
      activeTab: 0
    }
  }
})
