export default {
  users: {
    title: "Usuários",
    group: "Grupo",
    roles: {
      admin: "Administrador",
      adminApp: "Administrador App",
      adminPrivateLabel: "Administrador Private Label",
      registrations: "Cadastros",
      accounting: "Contabilidade",
      priceEditing: "Edição de Preços",
      orderManager: "Gerenciador de Pedidos",
      manager: "Gerente",
      blindInventoryAndSupply: "Inventário às cegas e abastecimento",
      logistics: "Logística",
      remoteMonitoring: "Monitoramento Remoto",
      invoices: "Notas Fiscais",
      pushNotification: "Push Notification",
      security: "Segurança",
      buildingManager: "Síndico",
      support: "Suporte",
      telemetry: "Telemetria",
      touchPaySupplier: "TouchPay Abastecedor",
      sales: "Vendas",
      planogramView: "Visualização de Planogramas",
      privateLabelView: "Visualização de Private Label",
      transactionView: "Visualização de Transações",
    },
    help: {
      page: {
        title: "Usuários",
        description: `
        <p>
          Nesta página, você pode visualizar, criar e gerenciar usuários dentro do sistema. São exibidas as seguintes informações sobre os usuários registrados: e-mail, grupo, se aplicável, e permissões. Para cada usuário, há opções de edição ou exclusão, permitindo ajustes nas configurações ou a remoção do sistema.
        </p>
        <p>
          Você pode criar novos usuários informando apenas o e-mail e a senha. Para isso, basta utilizar a funcionalidade de <strong>Novo Usuário</strong>, que permite cadastrar e definir os acessos.
        </p>
        <p>
          Os grupos no TouchPay centralizam as informações dos licenciados ou franqueados em um único ambiente de visualização. Cada grupo tem acesso exclusivo às suas próprias operações e informações, sem visualizar os dados de outros grupos. Essa funcionalidade permite que redes de franquias acompanhem suas operações de forma centralizada, mantendo a privacidade entre os grupos.
        </p>`,
      },
    },
  }
};