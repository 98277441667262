import { Bar } from 'vue-chartjs'
import Vue from 'vue'

export default Vue.extend({
  extends: Bar,
  props: {
    labels: { type: Array, required: true },
    datasets: { type: Array, required: true },
    barsize:{ type: Number, default: 150},
    labelY: { type: [String, Boolean], default: 'totalSold' },
    formatY: { type: String, default: 'currency' },
    maintainAspectRatio: { type: Boolean, default: true }
  },
  data(){
    return {
      tooltipTotal: 0
    }
  },
  mounted () {
    const $cf = this.$currencyFormatter
    const formatYAxes = this.formatY;
    const maintainAspectRatio = this.maintainAspectRatio
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets,
    }, {
      maintainAspectRatio: maintainAspectRatio,
      legend: {
        labels: {
          usePointStyle: true, 
        },
        position: 'bottom',
        align: 'end', 
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            callback: function(value) {
              return $cf.format(value);
            }
          }
        }]
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItems, data) {
            let label = data.labels[tooltipItems[0].index];
            if(Array.isArray(label)){
              return label.join("\r\n");
            }
            else return label;
          },
          label: function(tooltipItem, data){
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            this.tooltipTotal = 0;
            data.datasets.forEach((dataset) => {
              this.tooltipTotal += dataset.data[tooltipItem.index];
            });
    
            if(formatYAxes == 'currency'){
              this.total += value;
              return label + ": " + $cf.format(value)
            }
            else if (formatYAxes == 'uni'){
              return label + ' un. ';
            }

          },
        }
      }

    });
  }
})
