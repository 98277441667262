
  import Vue from 'vue'  
  
  export default Vue.extend ({
    props: {
      value: {type: Boolean, required: true },
      criticalMinimumItem:{type: Object, default: () => {}}
      
    },
    watch: {
      criticalMinimumItem: {
        handler(): void {
          this.minimumQuantity  = this.criticalMinimumItem.minimumQuantity
        },
        deep: true
      },
      show: {
        handler(): void {
          (this.$refs.form as any).resetValidation();
        }
      },
    },
    computed: {
      show: {
        get(): Boolean {
          return this.value;
        },
        set(value: Boolean) {
          this.$emit('close', value);
        }
      },    
    },
    data() {
      return {
          loading: false,
          minimumQuantity: '',
          rules: {
            integer: (v: string) => {
              if (v === '') {
                return true;
              } else if (/^\d+$/.test(v)) {
                return true; 
              } else {
                return this.$t('validations.insertInteger');
              }
            },
          }
      }  
    },
    methods: {

      setMinimumQuantity() {
        if ((this.$refs.form as any).validate()) {
          this.loading = true 
          this.axios.patch(`api/web/inventory/items/${this.criticalMinimumItem.id}/minimum-quantity`, {minimumQuantity: this.minimumQuantity})
          .then((response) => {              
              this.loading = false;
              this.show = false;
              this.$emit("update", response.data)          
            })
            .catch((error: any) => {
              (this.$refs.feedback as any).handleError(this.$t('inventory.items.dialogs.minimuCritical.messages.error.minimumCriticalUpdated'), error);
              this.loading = false;
            });
          }      
      },
    }
  })
  