export default{
  unlock: {
    operations: {
      result : {
        success: "Success",
        invalid: "Invalid",
        error: "Error",
        details: {
          lockOpened: "Lock Opened",
          facialRecognitionSuccess: "Facial Recognition Success",
          phoneNotValidated: "Phone Not Validated",
          emailNotValidated: "Email Not Validated",
          cpfNotValidated: "CPF Not Validated",
          ageNotValidated: "Age Not Validated",
          userBlocked: "User Blocked",
          unknown: "Unknown",
          lockNotFound: "Lock Not Found",
          deviceNotFound: "Device Not Found",
          deviceNotPaired: "Device Not Paired",
          deviceNotConnected: "Device Not Connected",
          deviceNotResponsive: "Device Not Responsive",
          communicationTimeout: "Communication Timeout",
          communicationError: "Communication Error",
          terminalNotReachable: "Terminal Not Reachable",
          bluetoothDisabled: "Bluetooth Disabled",
          noCertificate: "No Certificate",
          invalidClientCertificate: "Invalid Client Certificate",
          invalidServerCertificate: "Invalid Server Certificate",
          facialRecognitionFail: "Facial Recognition Fail"
        }
      }
    }

  }
}