export default {
    titleInvoiceManual: 'INVOICE INFORMATION',
    infoInvoiceManual: 'Fill in with the invoice data',
    numberInvoiceManual: 'Invoice Number',
    keyInvoiceManual: 'Access Key (NFe)',
    dateEmmitedInvoiceManual: 'Issue date',
    closeInvoiceManual: 'CLOSE',
    nextInvoiceManual: 'NEXT',
    validMessageInvoiceManual: 'To proceed, fill in all the data',
    invoiceManualHelpTitle: "Invoice Information",
    invoiceManualHelp: "Fill in with the invoice data, invoice number, access key (NFe) and issue date.",
    invoiceManualHelpNumber: 'Invoice Number:',
    invoiceManualHelpNumberText: 'Represents the invoice number.',
    invoiceManualHelpKey: 'Access key (NFe):',
    invoiceManualHelpKeyText: 'Represents the access key of the invoice. Our system does not allow uploading two invoices with the same access key.',
    invoiceManualHelpDate: 'Issue date:',
    invoiceManualHelpDateText: 'Represents the issue date of the invoice.',

}
