
import Vue from 'vue';
import IconButton from "@/components/buttons/IconButton.vue";
import agent from '@/api/agent';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    discount: { type: Object, default: null },
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      deleting: false,
      headers: [
        { text: this.$t('couponCode'), sortable: false },
        { text: this.$t('numberUtilization'), sortable: false },
        { text: this.$t('endsIn'), sortable: false },
        { text: this.$t('phoneNumber'), sortable: false },
        { text: this.$t('actions'), sortable: false }
      ],
      removeDialog: {
        enabled: false,
        loading: false,
        discount: null
      }
    };
  },
  methods: {
    getDiscountName(discount: any) {
      if (discount.description) return `${discount.description} -`;
    },
    getDiscountText(discount: any) {
      if (discount.type === 'Percentage') return `${discount.value.toLocaleString('pt-BR')}%`;
      if (discount.type === 'Fixed') return (this as any).$currencyFormatter.format(discount.value);
      else return discount.value;
    },

    confirmDeleteCoupon(coupon: any) {
      this.removeDialog.discount = coupon;
      this.removeDialog.enabled = true;
    },

    deleteCoupon(coupon: any) {
      this.removeDialog.loading = true;
      agent.Discounts.deleteCoupon(coupon.code)
        .then(() => {
          const index = this.discount.coupons.indexOf(coupon);
          if (index >= 0) this.discount.coupons.splice(index, 1);
        })
        .catch(error => (this as any).$refs.feedback.handleError(this.$t('noRemovedCoupon'), error))
        .finally(() => {
          this.removeDialog.loading = false;
          this.removeDialog.enabled = false;
        });
    },

    formatDateTimeMinutes
  },
  components: {
    IconButton
  }
});
