<template>
  <v-form ref="form" v-model="valid" @submit.prevent="saveDiscount">
    <v-card>
      <v-card-title>
        <span class="title">{{ $t('newDiscount') }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.description"
              :label="$t('nameDiscount')"
              :rules="[rules.nonEmpty, rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <discount-type
              v-model="form.type"
              :prependIcon="''"
              :rules="[rules.nonEmpty, rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              type="number"
              min="0"
              max="form.type === 'Percentage' ? 100 : null"
              step="0.01"
              placeholder="0.00"
              :label="$t('valueToApply') + valueLabel"
              v-model="form.value"
              :rules="[rules.nonEmpty, rules.float, rules.required, rules.maxValue]"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="number"
              min="0"
              step="0.01"
              placeholder="0.00"
              :label="$t('minimumPurchaseOptional')"
              v-model="form.minThreshold"
              :rules="[rules.float]"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <coupon-type v-model="codeType" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" v-if="codeType === 'specified'">
            <v-text-field
              type="number"
              min="0"
              :label="$t('couponLimit')"
              v-model="form.usageLimit"
              :rules="[rules.integer, rules.required]"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDialog()">{{ $t('btnClose') }}</v-btn>
        <v-btn type="submit" color="primary" :disabled="!valid || !form.type">{{ $t('btnNext') }}</v-btn>
      </v-card-actions>
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-form>
</template>

<script>
import agent from '@/api/agent';
import DiscountType from '@/components/filters/DiscountType.vue';
import CouponType from '@/components/filters/CouponType.vue';

export default {
  data() {
    return {
      form: {
        type: null,
        value: null,
        minThreshold: null,
      },
      valid: false,
      loading: false,
      types: null,
      codeType: 'single',
      rules: {
        nonEmpty: v => /\S/.test(v) || this.$t('insertSomeValue'),
        float: v => (!v || (!isNaN(v) && v >= 0)) || this.$t('insertValidValueOrLeaveEmpty'),
        integer: v => /^\d+$/.test(v) || this.$t('insertIntegerNumber'),
        required: v => !!v || this.$t('requiredField'),
        maxValue: v => (this.form.type !== 'Percentage' || v <= 100) || this.$t('percentageMustBeBetween0And100')
      }
    };
  },

  watch: {
    'form.type'(newType) {
      if (newType) {
        this.form.value = null;
      }

      if (newType === 'Percentage' && this.form.value > 100) {
        this.form.value = '';
      }
      this.$refs.form.validate();
    }
  },

  computed: {
    valueLabel() {
      let suffix = '';
      if (this.form.type === 'Percentage') suffix = ' [%]';
      else if (this.form.type === 'Fixed') suffix = ` [${this.$currencyFormatter.getSign()}]`;
      return `${suffix}`;
    }
  },

  methods: {
    saveDiscount() {
      if (this.valid && this.form.type) {
        this.loading = true;

        if (this.form.minThreshold === '' || this.form.minThreshold === null) {
          this.form.minThreshold = 0;
        }

        if (this.codeType === 'single') {
          this.form.usageLimit = 1;
        } else if (this.codeType === 'unlimited') {
          this.form.usageLimit = null;
        }

        agent.Discounts.createDiscount(this.form)
          .then(response => {
            this.$emit('created', response.id);
          })
          .catch(error => {
            this.$refs.feedback.handleError(this.$t('errorCreatingDiscount'), error);
          })
          .finally(() => (this.loading = false));
      }
    },
    resetData() {
      this.form = {
        type: null,
        value: null,
        minThreshold: null,
      };
      this.valid = false;
      this.loading = false;
    },
    closeDialog() {
      this.$emit('close');
      this.resetData();
    }
  },

  components: {
    DiscountType,
    CouponType
  }
};
</script>
