
import Vue, {DirectiveFunction} from 'vue';
import agent from '@/api/agent';
import { mask } from 'vue-the-mask';
import PhoneNumberFormatter from '@/utility/PhoneNumberFormatter';

const maskdir = mask as DirectiveFunction;

export default Vue.extend({
  directives: { maskdir },
  mixins: [PhoneNumberFormatter],
  props: {
    discount: { type: Number }
  },
  data() {
    return {
      form: {
        code: null as number | null,
        expiresOn: null as string | null,
        consumerCode: null as string | null,
        discountId: null as number | null,
      },
      valid: false,
      loading: false,
      date: null,
      show: false as boolean,
      maskedContactNumber: "" as string,
    };
  },
  watch: {
    date: function (date : string | null) {
      this.form.expiresOn = date != null ? this.$moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') : null;
    },
    maskedContactNumber(value: string) {
      const contactNumber = value.replace(/\D/g, '');
      this.form.consumerCode = contactNumber != "" ? contactNumber : null;
    }
  },
  methods: {
    saveCoupon() {
    this.loading = true;
    this.form.discountId = this.discount;
    agent.Discounts.createCoupon(this.form)
      .then( () => {
        this.show = false;
        (this as any).$refs.feedback.handleSuccess(this.$t('couponCreated'));
        this.closeDialog();
      })
      .catch(error => {
        (this as any).$refs.feedback.handleError(this.$t('errorCreatingCoupon'), error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    closeDialog() {
      (this as any).$emit("close");
      this.resetData();
    },
    resetData() {
      this.form = {
        code: null,
        expiresOn: null,
        consumerCode: null,
        discountId: this.discount,
      };
      this.date = null;
      this.valid = false;
    }
  },
});
