export default {
    transactionsTitleTransactionsHelp: 'Transactions',
    transactionsDescriptionTransactionsHelp: 'Sales made in stores or vending machines are displayed here',
    resultTransactionsHelp: `<strong>Result</strong>: indicates whether the product has been delivered.
    Useful in the case of vending machines, where there may be a failure to release the product.`,
    customerTransactionsHelp: '<strong>Customer</strong>: name of the customer as registered at the POS.',
    locationTransactionsHelp: '<strong>Location</strong>: location and specific location as registered at the POS.',
    posTransactionsHelp: '<strong>POS</strong>: POS ID, as per the POS Registration page.',
    amountTransactionsHelp: '<strong>Amount ($)</strong>: amount paid by the buyer)',
    marginAmountTransactionsHelp: '<strong>Margin ($)</strong>: Difference between the price paid by the buyer and the cost of goods sold (CMV).',
    marginPercentageTransactionsHelp: '<strong>Margin (%)</strong>: ratio, in percentage, between the margin above and the amount paid by the buyer.',
    methodTransactionsHelp: '<strong>Method</strong>: payment method (debit, credit, voucher, etc.)',
    cardTransactionsHelp: '<strong>Card</strong>: we display the last 4 digits of the card when we have this information',
    dateTimeTransactionsHelp: '<strong>Date/time</strong>: date of the transaction at the time of this device.',
    cmvInfoTransactionsHelp: `For the CMV and margin information to be available, you need to have the management system and 
    management system enabled in the TouchPay system.`,
    taxesHelp: '<strong>Taxes ($)</strong>: represents the taxes charged in the transaction.'
  }