import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PortalVue from 'portal-vue';
import moment from 'moment';
// @ts-ignore
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
// @ts-ignore
import VueMoment from 'vue-moment';
import 'moment/locale/pt-br';
// @ts-ignore
import qs from 'qs';

//import './stylus/main.scss';

import App from './App.vue';
import router from './router/index';
// @ts-ignore
import Feedback from '@/components/Feedback'
// @ts-ignore
import Help from '@/components/Help'
import '@/styles/main.scss'

Vue.component('feedback', Feedback);
Vue.component('help', Help);

import tenants from '@/tenants.js';
import vuetify from './plugins/vuetify';
import Validations from '@/constants/Validations';
import VueRouter, {Route} from "vue-router";
import {RawLocation} from "vue-router/types/router";
import CurrencyFormatter from '@/utility/CurrencyFormatter.vue';

Vue.prototype.$tenants = tenants;
Vue.prototype.$validations = new Validations();
Vue.prototype.$currencyFormatter = new CurrencyFormatter();


Vue.use(PortalVue);
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.defaults.paramsSerializer = function(params) {
  return qs.stringify(params, { arrayFormat: 'repeat' });
}

moment.locale(i18n.locale);
moment.updateLocale(moment.locale(), { invalidDate: String(i18n.t('noDate')) });
Vue.use(VueMoment, { moment });

// Sometimes we must change route parameters without changing the route, so we must avoid NavigationDuplicated error
// @ts-ignore

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalReplace.call(this, location, () => {
      resolve(this.currentRoute)
    }, (error: any) => {
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      resolve(this.currentRoute)
    }, (error: any) => {
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
}

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'staging'){
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::352958456067:role/RUM-Monitor-us-east-1-352958456067-8748478956861-Unauth",
      identityPoolId: "us-east-1:cb5254ca-4170-4a26-a3d7-22f96204812d",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };

    const APPLICATION_ID: string = '242c984d-7dab-4321-942d-df1919f8edbc';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-east-1';

    const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    );

    // @ts-ignore
    VueRouter.prototype.$awsRum = awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

// @ts-ignore
Vue.router = router;

Vue.use(require('@websanova/vue-auth'), {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  loginData: { url: 'account/login', method: 'POST', redirect: '', fetchUser: true },
  fetchData: { url: 'account/getUser', method: 'GET', enabled: true },
  refreshData: { url: 'account/refresh', method: 'GET', enabled: true, interval: 15 },
  logoutData: {redirect: '/login'},
  rolesVar: 'claims',
});

Vue.config.productionTip = false;

//i18n
import i18n from '@/locales/i18n';
import {createPinia, PiniaVuePlugin} from "pinia";

// Pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')



