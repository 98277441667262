export default{
  pickLists:{
    title: 'Pick Lists',
    create:{
      title: 'Gerar Pick Lists',
      pickListsDescription: 'Descrição da Pick List',
      pickListsByCategoryDescription: 'Quando agrupado por categoria, cada Pick List será nomeada de acordo com o nome da categoria',
      allProducts: 'Todos os produtos do planograma',
      categoryLabel: 'Filtrar produtos por categoria',
      productRestriction: 'Restrição de produto',
      criticalLabel: 'Incluir apenas produtos com estoque crítico',
      inventoryAvailable: 'Incluir apenas produtos com saldo de estoque disponível',
      advancedSection: 'Avançado',
      secondaryLabel: 'Marcar como Pick List secundária',
      groupByCategoryLabel: 'Gerar uma Pick list por categoria',
      groupByCategoryDisabledTooltip: 'Para poder gerar uma Pick List por categoria, você deve marcá-la como secundária e filtrar por pelo menos uma categoria',
      error: 'Não foi possível gerar Pick List',
    }
  }
}
