export default {
    titleTransactionsReportsFilters: 'Filtros y acciones',
    startDateTransactionsReportsFilters: 'Fecha de inicio',
    endDateTransactionsReportsFilters: 'Fecha de fin',
    clearTransactionsReportsFilters: 'Limpiar',
    locationTransactionsReportsFilters: 'Ubicación',
    stateTransactionsReportsFilters: 'Estado',
    customerTransactionsReportsFilters: 'Cliente',
    pointOfSaleTransactionsReportsFilters: 'Punto de venta',
    productTransactionsReportsFilters: 'Producto',
    categoryTransactionsReportsFilters: 'Categoría',
    groupByTransactionsReportsFilters: 'Agrupar por',
    excelTransactionsReportsFilters: 'Excel',
    updateTransactionsReportsFilters: 'Actualizar',
    selectCustomerFirstTransactionsReportsFilters: 'Seleccione primero un cliente',
    lastThirtyDaysTransactionsReportsFilters: 'Últimos 30 días',
    lastFifteenDaysTransactionsReportsFilters: 'Últimos 15 días',
    lastSevenDaysTransactionsReportsFilters: 'Últimos 7 días',
    sameDayTransactionsReportsFilters: 'Mismo día',    
    productsTransactionsReportsFilters: 'Productos',
    customersTransactionsReportsFilters: 'Clientes',
    pointsOfSaleproductsTransactionsReportsFilters: 'Puntos de Venta',
    statesTransactionsReportsFilters: 'Estados',
    paymentMethodsTransactionsReportsFilters: 'Métodos de Pago',
    cardFlagsTransactionsReportsFilters: 'Banderas de Tarjetas',
    dayTransactionsReportsFilters: 'Día',
    monthTransactionsReportsFilters: 'Mes',
    dayOfTheWeekproductsTransactionsReportsFilters: 'Día de la Semana',
    toCleanTransactionsReportsFilters: 'Limpiar',
};
