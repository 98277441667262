export type Tenant = {
  id: number,
  name?: string,
  cpf?: string,
  cnpj?: string,
}

export type Group = {
  groupId: number,
  groupName: string,
  cnpj?: string,
}

export class TenantClass {
  id: number;
  name?: string;
  cpf?: string;
  cnpj?: string;

  constructor(tenant: Tenant) {
    this.id = tenant.id;
    this.name = tenant.name;
    this.cpf = tenant.cpf;
    this.cnpj = tenant.cnpj;
  }

  get text(): string {
    return `${this.id} - ${this.name} - ${this.cpf !== null ? this.cpf : this.cnpj}`;
  }
}

export class GroupClass {
  groupId: number;
  groupName: string;
  cnpj?: string;

  constructor(group: Group) {
    this.groupId = group.groupId;
    this.groupName = group.groupName;
    this.cnpj = group.cnpj;
  }

  get text(): string {
    return `${this.groupId} - ${this.groupName} - ${this.cnpj}`;
  }
}
