<template>
  <v-card-text class="ma-0 pa-0">
    <v-row class="my-2 mx-1">
      <h2>
        <v-btn text color="primary" @click="returnView()"> 
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        {{ selectedInventoryItem.productDescription }}
      </h2>
      <v-spacer></v-spacer>
      <v-btn
        class='mx-2'
        outlined
        color="info"
        :loading="loading.button"
        @click="distributeQuantities()"
      >
        <v-icon left>mdi-clipboard-arrow-down</v-icon>{{ $t('redistributeButtonNegativeProductDetails') }}
      </v-btn>
    </v-row>
    <div class="px-4 py-2 block">{{ $t('stockNegativeProductDetails') }} {{ selectedInventoryItem.inventoryName }}</div>
    <div class="px-2 py-2 block">{{ $t('inStockNegativeProductDetails') }} <h3 class="block">{{ selectedInventoryItem.quantity }}</h3></div>
    <div class="px-2 py-2 block">{{ $t('reservationsNegativeProductDetails') }} <h3 class="block">{{ reservedQuantity }}</h3></div>
    <div class="px-2 py-2 block" :style="balanceColorStyle">{{ $t('balanceNegativeProductDetails') }} <h3 class="block" :style="balanceColorStyle">{{ editedBalance }}</h3></div>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="productPickLists"
      :options.sync="options"
      :loading="loading.table"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
    >
      <template #item.quantity="{ item }">
        <v-edit-dialog
          large
          type="number"
          :cancel-text="$t('cancelNegativeProductDetails')"
          :save-text="$t('toSaveNegativeProductDetails')"
          @open="openQuantity(item)"
          @save="saveQuantity(item)"
          @cancel="cancelQuantity"
        >
          {{ item.quantity }} 
          <v-icon color="primary">mdi-pencil</v-icon>
          <template #input>
            <div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="editedQuantity"
                :label="$t('quantityNegativeProductDetails')"
                type="number"
                :rules="[rules.integer]"
              >
              </v-text-field>
            </div>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <feedback ref="feedback"></feedback>
  </v-card-text>
</template>

<script>
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import agent from "@/api/agent";

export default {
  props: {
    selectedInventoryItem: { type: Object, default: () => {} },
    search: { type: String, default: ''}
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getProductDetails();
        }
      },
      deep: true
    },
  },
  computed: {
    editedBalance() {
      return parseInt(this.selectedInventoryItem.quantity) - parseInt(this.reservedQuantity);
    },
    balanceColorStyle() {
      return this.editedBalance >= 0 ? "color: green" : "color: red";
    }
  },
  mounted() {
    this.getProductDetails();
    this.reservedQuantity = this.selectedInventoryItem.reservedQuantity;
  },
  data() {
    return {
      productPickLists: [],
      loading: {
        table: false,
        button: false
      },
      headers: [{ text: this.$t('picklistNegativeProductDetails'), align: 'left', sortable: true, value: 'pickListId' },
                { text: this.$t('pointOfSaleNegativeProductDetails'), align: 'left', sortable: true, value: 'pointOfSaleId' },
                { text: this.$t('customerNegativeProductDetails'), align: 'left', sortable: true, value: 'customerName' },
                { text: this.$t('locationNegativeProductDetails'), align: 'left', sortable: true, value: 'localName'},
                { text: this.$t('specificLocationNegativeProductDetails'), align: 'left', sortable: true, value: 'specificLocation' },
                { text: this.$t('planogramNegativeProductDetails'), align: 'left', sortable: true, value: 'planogramId' },
                { text: this.$t('reservedQuantityNegativeProductDetails'), align: 'left', sortable: true, value: 'quantity' },
                { text: this.$t('salesThisWeekNegativeProductDetails'), align: 'left', sortable: false, value: 'count' },
                { text: this.$t('currentQuantityNegativeProductDetails'), align: 'left', sortable: false, value: 'currentQuantity' },
                { text: this.$t('desiredQuantityNegativeProductDetails'), align: 'left', sortable: false, value: 'quantityToSupply' },
                { text: this.$t('criticalMinimumNegativeProductDetails'), align: 'left', sortable: false, value: 'minimumQuantity' }],
      rules: {
        integer: (v) => /^\d+$/.test(v) || this.$t('integerRulesPositive')
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['pickListId'],
        sortDesc: [false],
        mustSort: true
      },
      totalItems: 0,
      editedQuantity: 0,
      reservedQuantity: 0,
    }
  },
  methods: {
    getProductDetails() {
      this.loading.table = true;
      this.axios.get(`api/Picklists/Items/NegativeBalance/${this.selectedInventoryItem.id}`, { params: toApiPagination(this.options, this.search) })
        .then((response) => {
          this.loading.table = false;
          this.productPickLists = response.data.items;
          this.productPickLists.map(p => p.originalReserves = p.quantity);
          this.totalItems = response.data.totalItems;
        })
    },
    returnView() {
      this.$emit('return-view');
    },
    openQuantity(item) {
      this.editedQuantity = parseInt(item.quantity);
    },
    saveQuantity(item) {
      this.loading.table = true;
      const quantityToSave = this.editedQuantity;
      const difference = item.quantity - this.editedQuantity;
      this.axios.put(`api/PickLists/${item.pickListId}/Quantity/${quantityToSave}`, [item.productId])
        .then(() => {
          item.quantity = quantityToSave;
          this.reservedQuantity -= parseInt(difference);
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('saveQuantityErrorNegativeProductDetails'), error);
        }).then(() => {
          this.loading.table = false;
          this.editedQuantity = 0;
        })
    },
    cancelQuantity() {
      this.editedQuantity = 0;
    },
    distributeQuantities() {
      this.loading.button = true;
      agent.PickLists.distributeProduct(this.selectedInventoryItem.id)
        .then(() => {
          this.reservedQuantity = this.selectedInventoryItem.quantity;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('distributeQuantitiesErrorNegativeProductDetails'), error);
        }).then(() => {
          this.loading.button = false;
          this.getProductDetails();
        })
    }
  }

}
</script>

<style>
  .text-field {
    width: 96px !important;
  }
  .dialog {
    width: 200px !important;
  }
  .block {
    display: inline-block;
  }
</style>