export default {
    DialogTokenHelpTitle: 'Token Help',
    DialogTokenBody: `To obtain the PagSeguro API token, just follow the steps below:
    <ul>
        <li>Access your PagSeguro account (the same account used for your card machines);</li>
        <li>In the side menu, select <strong>"Sales"</strong> and then scroll down to find the <strong>"Integrations"</strong> option, and click on this option;</li>
        <li>On the "Integrations" page, go to the "API Usage" section and click the <strong>"Generate Token"</strong> button;</li>
        <li>Copy the token generated on the screen or that was sent by email!</li>
    </ul>`,
    DialogTokenMessage: 'If you have difficulties, click the link below to watch a video explaining in detail how to obtain this information!',

    DialogActivationTitle: 'Activation Code Instructions:',
    DialogActivationBody: `<p>To obtain your PagSeguro activation code, just follow the steps below:</p>
    <ul>
        <li>Access your PagSeguro account (the same account used for your card machines);</li>
        <li>In the left side menu, select <strong>"Card Machines"</strong>;</li>
        <li>After opening <strong>"Card Machines"</strong>, select <strong>"Manage Card Machines"</strong>;</li>
        <li>After opening <strong>"Manage Card Machines"</strong>, you will find your Activation Code on this screen, it's a 6-digit number!</li>
    </ul>`,
    DialogActivationTitleImg: 'Activation Code',
};
