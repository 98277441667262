
import Vue from 'vue';
import {InvoicePurchase, Product, PurchaseType} from "@/types/Purchases"
import Feedback from '@/components/Feedback.vue';
import agent from '@/api/agent';
import LocalStorageDialog from '../LocalStorageDialog.vue';
import InvoiceManual from './InvoiceManual.vue';
import ProductsManual from './ProductsManual.vue';
import DetailManualDialog from './DetailManualDialog.vue';

export default Vue.extend({
    components: { 
    DetailManualDialog,
    Feedback,
    LocalStorageDialog,
    InvoiceManual,
    ProductsManual
  },
   data() {
    return {
        currentStep: 1 as number,
        invoice: {
              productRegistrationDisabled: false as Boolean,
              nfeNumber: '' as string,
              serie: '' as string,
              dateEmitted: '' as string,
              providerName: '' as string,
              key: '' as string,
              purchaseType: PurchaseType.MANUAL,
              inventoryId: null as number | null,
              products: [] as Product[],
            } as InvoicePurchase,
        storageDialog: false as boolean,
        localStorageSelected : false as boolean,
        indexProductEdit: undefined as number | null | undefined,
        addNewProduct: false as boolean,
    }
   },
   props: {
    value: {
      type: Boolean,
      default: false
    },
   },
   computed: {
    dialog: {
      get() : boolean {
        if(this.value && this.indexProductEdit === undefined)
        {
          this.verifyAddProductEnable();
          this.verifyLocalStorage();
        }
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value);
      }
    },
  },
   methods:{
    verifyAddProductEnable(){
      const type = 'Product';
      agent.Features.VerifyFeatureIsEnable(type).then((response) =>{
        this.invoice.productRegistrationDisabled = !response;
      }).catch(() => {
        this.invoice.productRegistrationDisabled = false;
      });

    },
    closeDialog(){
        this.dialog = false;
        this.currentStep = 1;

        this.invoice = {
          productRegistrationDisabled: false as boolean,
          nfeNumber: '' as string,
          serie: '' as string,
          dateEmitted: '' as string,
          providerName: '' as string,
          key: '' as string,
          purchaseType: PurchaseType.MANUAL,
          inventoryId: null as number | null,
          products: [] as Product[],
        } as InvoicePurchase;

        this.storageDialog = false;
        this.localStorageSelected = false;
        this.indexProductEdit = undefined;
        this.addNewProduct = false;
    },
    verifyLocalStorage(){
      const step = parseInt(localStorage.getItem('stepInvoice') as string);
      const invoiceLocalStorage = JSON.parse(localStorage.getItem('invoiceImported') as string);

      if(step !== null && step > 1 && invoiceLocalStorage != null && !this.localStorageSelected && invoiceLocalStorage.purchaseType === PurchaseType.MANUAL){
        this.storageDialog = true;
        this.dialog = false;
      }
      else if(this.localStorageSelected && invoiceLocalStorage.purchaseType === PurchaseType.MANUAL){
        this.getLocalStorage();
      }
      else{
        this.initialProcess();
      }
    },
    getLocalStorage(){
      this.currentStep = parseInt(localStorage.getItem('stepInvoice') as string);
      this.invoice = JSON.parse(localStorage.getItem('invoiceImported') as string);
    },
    completeProduct(invoicePurchase: InvoicePurchase){
      this.invoice.products = invoicePurchase.products.slice();

      this.currentStep = 3;
      this.setLocalStorage();
      this.indexProductEdit = null;
      this.addNewProduct = false;
    },
    initialProcess(){
      localStorage.removeItem('stepInvoice');
      localStorage.removeItem('invoiceImported');
    },
    closeLocalStorage(value : boolean, localStorageSelected : boolean){
      this.localStorageSelected = localStorageSelected;
      this.storageDialog = value;
      this.dialog = true;

      if(!localStorageSelected){
        this.initialProcess();
      }

    },
    handleError(message: string, error: any) {
      this.closeDialog();
      (this.$refs.feedback as any).handleError(message, error);
   },
   handlerSuccess(message: string) {
      this.closeDialog();
      (this.$refs.feedback as any).handleSuccess(message);
   },
   setLocalStorage(){
      localStorage.setItem('stepInvoice', this.currentStep.toString());
      localStorage.setItem('invoiceImported', JSON.stringify(this.invoice));
   },
    addInvoiceManual(invoice : InvoicePurchase){
      this.invoice = invoice;
      this.currentStep = 2;
      this.setLocalStorage();
    },
    editProduct(index: number) {
      this.indexProductEdit = index;

      this.currentStep = 2;
    },
    addProduct(){
      this.addNewProduct = true;

      this.currentStep = 2;
    }
   }
})
