
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    finished: {type: Boolean, required: true},
    description: {type: String, required: true},
    name: {type: String, required: true},
  },
  data() {
    return {
      submitting: false,
      translations: {
        dialog: {
          remove: {
            title: (description: string, name: string): string => {
              return this.$t('dialog.remove.title', { description, name }).toString()
            },
          }
        },
        button: {
          no: this.$t('buttons.no').toString(),
          remove: this.$t('buttons.remove').toString()
        },
      }
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    finished(value: boolean) {
      if (value) {
        this.submitting = false;
        this.dialog = false;
      }
    },
  },
  methods: {
    deleteItem() {
      this.submitting = true;
      this.$emit("removeItemConfirmation");
    },
    title(): string {
      return this.translations.dialog.remove.title(this.description, this.name);
    }
  }
});
