export default {
    removeConfirmationPosManagement: 'Are you sure you want to remove the point of sale?',
    searchPosManagement: 'Search',
    newPosPosManagement: 'New POS',
    idPosManagement: 'ID',
    paymentSystemPosManagement: 'Payment system',
    posCodePosManagement: 'POS code',
    modelManufacturerPosManagement: 'Model / Manufacturer',
    customerPosManagement: 'Customer',
    locationPosManagement: 'Location',
    distributionCenterPosManagement: 'Distribution Center',
    actionsPosManagement: 'Actions',
    incompletePosWarning: 'This POS has pending registration',
    deletePosManagement: 'Remove',
    noResultsPosManagement: 'No results',
    feedbackErrorPosManagement: 'The POS could not be removed',
    noPosManagement: 'No',
    pointsOfSalePosManagement: 'Points of sale',
    dateCreatedPosManagement:'Date Created',
  };