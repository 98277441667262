export default {
  tenant: {
    group: {
      help: {
        about: {
          title: 'Criação de novos Grupos',
          description: `
          <p>
            Nessa funcionalidade, você pode criar novos Grupos.
          </p>
          <ul>
            <li>
               Quando a tela de criação de novos Grupos é aberta, o sistema busca e sugere o próximo ID disponível para o novo Grupo.
            </li>
            <li>
              O campo <strong>{employerIdentificationType}</strong> é obrigatório. 
              <strong>Ressalta-se que o {employerIdentificationType} preenchido, será usado para cobranças</strong>.
            </li>
            <li>
              O campo <strong>Nome do Grupo</strong> é obrigatório e deve ter entre 5 e 60 caracteres. 
              <strong>Sugere-se o uso do nome fantasia referente ao {employerIdentificationType}</strong>.
            </li>
          </ul>
          `
        },
      },
    }
  },
}