export default {
    lossesProductAndPosTitleProduct: 'Pérdidas por productos',
    lossesProductAndPosSubTitleProduct: 'Identifique los artículos con mayores pérdidas en su tienda',
    lossesProductAndPosTitlePos: 'Pérdidas por PDV',
    lossesProductAndPosSubTitlePos: 'Identifique los PDV con mayores pérdidas',
    lossesProductAndPosLabel: 'Razón',
    lossesProductAndPosyColumnProduct: 'Productos',
    lossesProductAndPosColumnLossesValue: 'Valor perdido',
    lossesProductAndPosColumnAmount: 'Cantidad',
    lossesProductAndPosLabelToViewPos: 'Ver punto de venta',
    lossesProductAndPosLabelToViewProduct: 'Ver productos',
    reasonsForLossesExpired: 'Validez caducada',
    reasonsForLossesDamaged: 'Producto dañado',
    reasonsForLossesOther: 'Otro',
    reasonsForLossesDetour: 'Desvío',
    lossesProductAndPosyColumnPos: 'Punto de venta',
}