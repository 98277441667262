export default {
  connectivity: {
    title: 'Conectividad',
    help: {
      description: `<p>En la pestaña <strong>Conectividad</strong>, puedes monitorear el estado de conexión de cada PDV. 
      La página muestra el nombre del PDV y detalla cuánto tiempo permaneció conectado a través de Wi-Fi, chip de datos o cuando estuvo fuera de conexión. 
      Esto permite evaluar la estabilidad de la conexión de los terminales a Internet, lo cual es esencial para realizar ventas.</p>
      <p>En la pestaña <strong>Línea de Tiempo</strong>, encontrarás una visualización gráfica que ilustra la conectividad del PDV a lo largo del tiempo. 
      Con esta visualización, es posible seguir la conectividad y garantizar que el PDV siempre esté en funcionamiento.</p>`
    },
    timeline: {
      title: 'Línea de tiempo',
      chart: {
        title: 'Línea de tiempo de conectividad',
        xAxis: 'Fecha y hora',
        yAxis: 'Mediciones de los Puntos de Venta',
      },
      help: {
        description: `<p align="justify">Este gráfico muestra la conectividad de los puntos de venta durante un período determinado.</p>
        <p align="justify">La conectividad se registra a partir de la telemetría de un punto de venta.
        Cada punto en el gráfico representa una telemetría realizada. Las telemetrías deben ocurrir cada cinco minutos.
        Si dos puntos sucesivos tienen más de cinco minutos de diferencia entre uno y otro, es porque hubo un problema de conexión durante ese período.</p>
        <p align="justify">En "<strong>Fecha</strong>" eliges el día que quieres ver de las telemetrías de un punto de venta.</p>
        <p align="justify">En "<strong>Punto de Venta</strong>" eliges cuál de tus puntos de venta deseas analizar las telemetrías.</p>`
      }
    },
    errors: {
      loadConnectivity: 'No se pudo cargar la conectividad de los puntos de venta',
      loadTimeline: 'No se pudo cargar la línea de tiempo de los puntos de venta',
      excel: 'No se pudo descargar el archivo, inténtelo de nuevo',
    }
  },
}