export default {
    createPlanogramTitle: 'Create planogram',
    copyFromActive: 'Copy from active planogram',
    emptyPlanogram: 'Create empty planogram',
    copyFromOtherPlanogram: 'Copy from another planogram',
    copyFromPriceTable: 'Copy from a price table',
    copyFromOtherPlanogramText: 'Copy from another planogram',
    copyFromPriceTableText: 'Copy from a price table',
    versionOfPlanogram: 'Planogram Version',
    noPlanograms: 'No planograms in the points of sale',
    selectPriceTable: 'Select a price table',
    addProductsFromPriceTable: 'Add products from Price Table missing in Planogram',
    removeProductsFromPlanogram: 'Remove from Planogram products missing from Price Table',
    btnClose: 'Close',
    btnCreate: 'Create',
    errorCreatingPlanogram: 'Error creating planogram',
    errorLoadingPriceTables: 'Error loading price tables',
    priceTableLabel: 'Price table',
    planogramReturn: 'Planogram',
    createPlanogramError: 'Error when applying the price table ',
    createPlanogramPos: 'at the point of sale',
    createPlanogramTryAgain: ' Try again.',
    errorCopyingPriceTable: 'Error when copying price table: ',
    errorCreatingPosPlanograms: 'Error when creating planogram at point of sale',
    warningEmptyPlanogramCreation: `<strong>ATTENTION:</strong>
    <p>
      The selected points of sale already have an active planogram with products, and continuing will result in an empty planogram.
      Remember to add product(s) later to finalize the draft.
      This is important to ensure that operating terminals continue transacting and that you do not lose saved stock.
    </p>  
    <p>To add products to the already active planogram, use the <strong>"Copy from active planogram"</strong> option.
    <p>Do you want to continue anyway?</p> `,
    cancelButton: 'Cancel',
    attentionEmptyPlanogramCreation: 'NOTICE',
  };
  