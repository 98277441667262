
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import { DivergentProduct } from '@/types/PriceTable';
// eslint-disable-next-line no-unused-vars
import {SingleOperationBulkResult} from "@/types/common/BulkOperation";

export default Vue.extend({
  props: {
    infoMessages: { type: Array, required: true }
  },
  data() {
    return {
      show: false,
      tab: 0,
      translations: {
        action: {
          divergence: this.$t('priceTable.action.divergence').toString(),
        },
        pointOfSale: {
          data: {
            id: (id: number): string => {
              return this.$t('pointOfSale.data.id', {
                pointOfSaleId: id
              }).toString();
            }
          }
        },
        dialog: {
          help: {
            divergence: {
              title: this.$t('priceTable.help.divergence.title').toString(),
              type: {
                priceTable: {
                  description: this.$t('priceTable.help.divergence.type.priceTable.description').toString(),
                  absenceReason: (productId: number, productDescription: string, pointOfSaleId: number) => {
                    return this.$t('priceTable.help.divergence.type.priceTable.absenceReason', {
                      productId,
                      productDescription,
                      pointOfSaleId
                    }).toString();
                  },
                },
                planogram: {
                  description: this.$t('priceTable.help.divergence.type.planogram.description').toString(),
                  absenceReason: (productId: number, productDescription: string, pointOfSaleId: number) => {
                    return this.$t('priceTable.help.divergence.type.planogram.absenceReason', {
                      productId,
                      productDescription,
                      pointOfSaleId
                    }).toString();
                  },
                },
              },
              description: this.$t('priceTable.help.divergence.description').toString(),
            }
          }
        },
        button: {
          close: this.$t('buttons.close').toString()
        }
      },
    }
  },
  computed: {
    filteredInfoMessages() {
      return this.infoMessages.filter((im: any) => im.divergentProducts !== undefined);
    },
    absencesInPriceTablesPreFiltered(): SingleOperationBulkResult[] {
      return (this.filteredInfoMessages as SingleOperationBulkResult[])
        .filter((im: SingleOperationBulkResult) => im.divergentProducts!
          .some((dp: DivergentProduct) => dp.divergentProductType == "AbsentPriceTable"));
    },
    absencesInPriceTablesFiltered(): SingleOperationBulkResult[] {
      return (this.absencesInPriceTablesPreFiltered as SingleOperationBulkResult[])
        .map((item: SingleOperationBulkResult) : SingleOperationBulkResult => {
          const divergentProducts = item.divergentProducts!.filter(dp => dp.divergentProductType == "AbsentPriceTable")
          return {
            entityId: item.entityId,
            success: item.success,
            error: item.error,
            divergentProducts: divergentProducts
          }
        });
    },
    absencesInPlanogramsPreFiltered(): SingleOperationBulkResult[] {
      return (this.filteredInfoMessages as SingleOperationBulkResult[])
        .filter((im: SingleOperationBulkResult) => im.divergentProducts!
          .some((dp: DivergentProduct) => dp.divergentProductType == "AbsentPlanogram"));
    },
    absencesInPlanogramsFiltered(): SingleOperationBulkResult[] {
      return this.absencesInPlanogramsPreFiltered
        .map((item: SingleOperationBulkResult) : SingleOperationBulkResult => {
          const divergentProducts = item.divergentProducts!.filter(dp => dp.divergentProductType == "AbsentPlanogram")
          return {
            entityId: item.entityId,
            success: item.success,
            error: item.error,
            divergentProducts: divergentProducts
          }
        });
    },
    showButton(): boolean {
      const showButton = ((this.absencesInPriceTablesPreFiltered as SingleOperationBulkResult[]).length > 0
        || (this.absencesInPlanogramsPreFiltered as SingleOperationBulkResult[]).length > 0)
      return showButton;
    },
  },
  methods: {
    absencesInPriceTablesText(result: SingleOperationBulkResult, divergentProduct: DivergentProduct): string {
      if (result != undefined && divergentProduct != undefined) {
        return this.translations.dialog.help.divergence.type.priceTable
          .absenceReason(divergentProduct.productId, divergentProduct.productDescription, result.entityId);
      }
      else return '';
    },
    absencesInPlanogramsText(result: SingleOperationBulkResult, divergentProduct: DivergentProduct): string {
      if (result != undefined && divergentProduct != undefined) {
        return this.translations.dialog.help.divergence.type.planogram
          .absenceReason(divergentProduct.productId, divergentProduct.productDescription, result.entityId);
      }
      else return '';
    },
  }
})
