export default {
    myProducts: 'My products',
    searchProduct: 'Search for product',
    excelProduct: 'Excel',
    noItemsRegisteredProduct: 'No items registered',
    confirmRemoveProduct: 'Are you sure you want to remove the product',
    noProduct: 'No',
    removeProduct: 'Remove',
    errorGenerateExcelProduct: 'Error generating excel',
    productSavedSuccessProduct: 'Product saved successfully',
    productUpdatedSuccessProduct: 'Product successfully updated',
    itemRemovedProduct: 'Item removed',
    errorRemoveItemProduct: 'Error removing item',
    idProduct: 'ID',
    codeProduct: 'Code',
    descriptionProduct: 'Description',
    categoryProduct: 'Category',
    defaultPriceProduct: 'Default price',
    barCodeProduct: 'Barcode',
    availabilityProduct: 'Availability',
    actionsProduct: 'Actions',
    imageProduct: 'Image',
    copyProduct: 'Create copy of product',
    productsMustBeAddedErp: 'Products must be added by ERP',
    groupIdPublicProduct: 'Public',
    groupIdGroupProduct: 'Group: ',
    dateCreatedProduct:'Date Created',
    viewProductDeleted: 'Show inactive products',
    productActivatedSuccess: 'Product successfully restored',
    errorActivateProduct: 'Error restoring product',
    errorSearchProduct: 'Unable to load products, please try again',
    chooseAProduct: 'Select a product',
  };