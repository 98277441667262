<template>
  <v-text-field
    v-model="internalCnpj"
    dense
    prepend-icon="mdi-domain"
    label="CNPJ"
    required
    type="text"
    :rules="cnpjRules"
    :loading="loading"
  ></v-text-field>
</template>

<script>
import { ref, watch, computed } from 'vue';

export default {
  name: 'CnpjTextFilter',
  props: {
    cnpj: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    cnpjIsValid: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, { emit }) {
    const internalCnpj = ref(props.cnpj);

    watch(
      () => props.cnpj,
      (newCnpj) => {
        internalCnpj.value = newCnpj;
      }
    );

    watch(
      internalCnpj,
      (newCnpj) => {
        const isValid = validateCnpj(newCnpj);
        emit('update:cnpj', newCnpj);
        emit('update:cnpjIsValid', isValid);
      }
    );

    function validateCnpj(value) {
      const cnpj = value.replace(/[^\d]+/g, '');

      if (cnpj.length !== 14) {
        return false;
      }

      let length = cnpj.length - 2;
      let numbers = cnpj.substring(0, length);
      const digits = cnpj.substring(length);
      let sum = 0;
      let pos = length - 7;

      for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(0))) {
        return false;
      }

      length = length + 1;
      numbers = cnpj.substring(0, length);
      sum = 0;
      pos = length - 7;

      for (let i = length; i >= 1; i--) {
        sum += parseInt(numbers.charAt(length - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(1))) {
        return false;
      }

      return true;
    }

    const cnpjRules = computed(() => [
      (v) => !!v || 'CNPJ é obrigatório',
      (v) => /^[0-9]+$/.test(v) || 'Apenas números são permitidos',
      (v) => validateCnpj(v) || 'CNPJ inválido',
    ]);

    return {
      internalCnpj,
      cnpjRules,
      validateCnpj,
    };
  },
};
</script>

<style scoped>
/* Seus estilos aqui */
</style>
