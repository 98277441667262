
import Vue from 'vue'
import agent from '@/api/agent'

export default Vue.extend({
  data() {
    return {
      loading: false as boolean,
      paramsPost: {},
    }
  },
  props: {
    value: { type: Boolean, default: false },
    planogramId: { type: Number, default: null },
    posId: { type: Number, default: null },
    dateFilter: { type: String, default: null },
    selectedItems: { type: Array, default: null },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        (this as any).$emit("close", value);
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    createPlanogram() {
      this.loading = true;

      this.paramsPost = {
        lastUpdateProduct: this.dateFilter,
        productIds: this.selectedItems,
        creationType: "fromActive",
        destinationPosId: this.posId,
      }

      agent.Planogram.createPlanogramWithoutUnusedProducts(this.paramsPost)
        .then((response) => {
          let planogram = response;
          this.$emit('createSucess', planogram);
        })
        .catch(error => {
          this.$emit('error', this.$t('dialogCreatDraftProductUnusedError'), error);
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    }
  },
})
