export type Bills = {
  bills: Bill[];
}

export type Bill = {
  omieBillId: number;
  omieBillIdUrl: string | null;
  nfeKey: string | null;
  billStatus: BillStatus;
  billType: BillType;
  taxDocumentId: string;
  serviceOrderCode: number;
  serviceOrderUrl: string | null;
  billAmount: number;
  issueDateTime: Date;
  expirationDateTime: Date;
  forecastDateTime: Date;
  chargedItems: BillChargedItem[];
}

export type BillChargedItem = {
  unitPrice: number;
  quantity: number;
  totalPrice: number;
  billServiceType: string;
  description: string | null;
}

export enum BillStatus {
  ToBePaid = "ToBePaid",
  DueToday = "DueToday",
  Late = "Late",
  Paid = "Paid",
  Canceled = "Canceled",
  Partial = "Partial"
}

export enum BillType {
  Unknown = "Unknown",
  ServicesMonthlyFee = "ServicesMonthlyFee",
  ActivationFee = "ActivationFee",
  EquipmentSale = "EquipmentSale"
}

export type CheckIfCustomerIsDebtFreeDto ={
  isDebtFree: boolean;
}
