
import Vue from 'vue';
import NewMarketIntelAuthorization from '@/views/dashboard-new/NewMarketIntelAuthorization.vue'
import MarketInformationsCards from './components/MarketInformationsCards.vue';
import { MarketIntelligenceAccess } from "@/api/agent";
import { MarketIntelUserAccess } from '@/types/MarketIntelUserAccess'
import { MANAGER_HIERARCHY } from '@/constants/Roles';

export default Vue.extend({
  components: {
    "Authorization": NewMarketIntelAuthorization,
    MarketInformationsCards,
  },
  data() {
    return {
      userAccess: {
        tenantId: (this as any).$auth.user().tenantId as number,
        groupId: (this as any).$auth.user().tenantGroupId as number | null,
        privacyPolicyVersion: -1 as number, // policyVersion = -1 means user has not accepted policy
        hasAllPosRegistered: false,
        accessAllowed: false,
      } as MarketIntelUserAccess,

      error: "" as any
    }
  },
  props: {
    mustUpdate: { type: Boolean, required: true },
    activeTab: { type: Number, required: true },
    form: { type: Object, required: true },
  },
  mounted() {
    (this as any).getUserAuthorization();
  },
  computed:{
    userIsManager() {
      return MANAGER_HIERARCHY.some(role => (this as any).$auth.check(role));
    },
    mustCallEndpoints(): boolean {
      return this.mustUpdate && (this as any).activeTab == 2
    }
  },
  methods: {
    async getUserAuthorization() {
      const tenantId = (this as any).$auth.user().tenantId;
      const groupId = (this as any).$auth.user().tenantGroupId;
      try {
        const response = await MarketIntelligenceAccess.getUserAccess({tenantId: tenantId, groupId: groupId});
        (this as any).userAccess = response;
      }
      catch (error){
        (this as any).error = error
      }
    }
  },
    watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          (this as any).$nextTick(() => (this as any).$emit('updated'))
        }
      },
      immediate: true
    }
  }  
})
