export default {
  translateOperationType: function (type, isBlindOperation) {
    if (type === 'Inventory' && isBlindOperation) return 'Inventário às Cegas';
    if (type === 'Inventory') return 'Inventário';
    if (type === 'Supply') return 'Abastecimento';
    if (type === 'Combined') return 'Combinado';
    if (type === 'Picking') return 'Separação';	
  },
  translateRemovalReason: function(reason) {
    if (reason === 'Expired') return 'Validade vencida';
    if (reason === 'Damaged') return 'Produto danificado';
    if (reason === 'Return') return 'Devolução';
    return 'Outro motivo';
  }
}