<template>
  <span>
    <span v-if="auditCategories === 'Planograma'">
      <v-icon left>mdi-chevron-right</v-icon>
      <span class="title">
        <v-icon left>mdi-view-comfy</v-icon>Planogramas
      </span>
      <audits-help-table auditCategoryName="Planogramas"  />
    </span>
    <span v-if="auditCategories === 'Tabela de preço'">
      <v-icon left>mdi-chevron-right</v-icon>
      <span class="title">
        <v-icon left>mdi-table-edit</v-icon>Tabelas de Preços
      </span>
      <audits-help-table auditCategoryName="Tabelas de Preços"  />
    </span>
    <!-- As we add more possibilities to audit, we will add more cases to the 'v-if' -->
    <span v-else>
    </span>
  </span>
</template>

<script>
import AuditsHelpTable from "@/views/audits/helpers/AuditsHelpTable";
export default {
  components: {AuditsHelpTable},
  props: {
    auditCategories: {type: String, default: null}
  },
};
</script>

<style>

</style>