export default {
  audit: {
    title: "Auditoría",
    helpMaster: {
      description: `Aquí se muestra la auditoría de ediciones en el sistema WEB. La auditoría permite a los administradores ver qué usuarios crearon, editaron o eliminaron ciertos datos en el sistema, ayudando así a rastrear errores en el uso del sistema.`
    },
    helpTable: {
      description: `
        <p align="justify">
          Aquí se pueden ver los cambios realizados en {auditCategoryName} utilizando el sistema AMLabs TouchPay
          en formato de tabla.
        </p>
        <ul align="justify">
          <li>
            <strong>IP:</strong> IP de la computadora que realizó la modificación mostrada en la fila.
          </li>
          <li>
            <strong>Usuario:</strong> Usuario que realizó la modificación mostrada en la fila.
          </li>
          <li>
            <strong>Fecha y Hora:</strong> Momento en que se realizó la modificación mostrada en la fila.
          </li>
          <li>
            <strong>Parámetros:</strong> Parámetros que se utilizaron para realizar la modificación mostrada en la fila.
          </li>
          <li>
            <strong>Acción:</strong> Acción que se tomó en la modificación mostrada en la fila.
          </li>
        </ul>
      `
    },
    actions: {
      planogram: {
          title: "Planograma",
          finishPlanogram: "Finalización de planograma",
          bulkFinishPlanograms: "Finalización de planogramas en lote",
          activatePlanogram: "Activación de planograma",
          bulkActivatePlanograms: "Activación de planogramas en lote",
          postPlanogram: "Creación de planograma",
          bulkPostPlanogram: "Creación de planogramas en lote",
          deletePlanogram: "Eliminación de planograma",
          bulkDeletePlanograms: "Eliminación de planogramas en lote",
          postPlanogramWithoutUnusedProduct: "Creación de planograma eliminando productos sin uso"
      },
      pointsOfSale: {
          title: "Puntos de venta",
          putPointOfSale: "Edición del punto de venta",
          postPointOfSale: "Creación del punto de venta",
          deletePointOfSale: "Eliminación del punto de venta",
          restoreDeletedPointOfSale: "Restauración del punto de venta"
      },
      priceTable: {
          title: "Tabla de precios",
          postPriceTable: "Creación de tabla de precios",
          patchPriceTable: "Edición del nombre de la tabla de precios",
          patchPriceTableType: "Edición del tipo de tabla de precios",
          deletePriceTable: "Eliminación de tabla de precios",
          deletePriceTableItem: "Eliminación de ítem de la tabla de precios",
          bulkApplyPriceTableNew: "Aplicación en lote de tabla de precios",
          putUpdateCostFromDistributionCenter: "Actualización de costos desde el centro de distribución"
      },
      products: {
          title: "Productos",
          putProduct: "Edición de producto",
          postProduct: "Adición de producto",
          deleteProduct: "Eliminación de producto"
      },
      taxScenarios: {
          title: "Escenarios fiscales",
          mapProductToTaxGroup: "Modificación de producto en el escenario fiscal",
          addProductToTaxGroup: "Producto añadido al escenario fiscal",
          removeProductFromTaxGroup: "Producto eliminado del escenario fiscal",
          postBrazilianTaxing: "Creación de escenario fiscal",
          putBrazilianTaxing: "Actualización de escenario fiscal",
          deleteBrazilianTaxGroup: "Eliminación del escenario fiscal"
      },
      users: {
          title: "Usuarios",
          deleteUsers: "Eliminación de usuario",
          putUsers: "Edición de usuario",
          postUsers: "Creación de usuario"
      }
    }
  }
}
