export default {
    addressStateFilterNamesTranslations: 'Estado',
    localIdFilterNamesTranslations: 'Ubicación',
    posIdFilterNamesTranslations: 'Punto de Venta',
    productIdFilterNamesTranslations: 'ID de Producto',
    dateIntervalFilterNamesTranslations: 'Intervalo de Fechas:',
    productCategoryIdFilterNamesTranslations: 'ID de Categoría de Producto',
    inventoryIdFilterNamesTranslations: 'ID de Inventario',
    typeFilterNamesTranslations: 'Tipo de Operación',
    customerIdFilterNamesTranslations: 'ID de Cliente',
};