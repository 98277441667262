<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12">
        <v-card class="mb-16">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-data-table
              :headers="filteredHeaders"
              :items="tableItems"
              :search="draftSearch"
              :server-items-length="calculatedTotalItemsFastEdition"
              :options.sync="$parent.$data.options"
              item-key="productId"
              must-sort
              sort-by="productId"
              :custom-sort="customSort"
              :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
            >
              <template #[`item.productImageUrl`]="{ item }">
                <v-img
                  class="py-2"
                  v-if="item.productImageUrl != null"
                  :src="item.productImageUrl"
                  :disabled="item.editType === 'Delete'"
                  :id="item.editType === 'Delete' ? 'img' : ''"
                  max-width="95"
                  max-height="95"
                  contain
                ></v-img>
              </template>

              <template #[`item.productDescription`]="{ item }">
                <v-autocomplete
                  :items="
                    products.map((item) => {
                      return item.searchText;
                    })
                  "
                  v-model="item.searchText"
                  :loading="loading.products"
                  :label="$t('productNameDraftEdition')"
                  item-text="searchText"
                  item-value="id"
                  :no-data-text="$t('noProductsDraftEdition')"
                  required
                  :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                  :disabled="item.editType === 'Delete'"
                  :rules="[rules.required, rules.nonEmpty]"
                  @change="onProductEdited(item)"
                ></v-autocomplete>
              </template>

              <template #[`item.price`]="{ item }">
                <v-text-field
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="0.00"
                  v-model="item.price"
                  :label="$t('priceDraftEdition')"
                  :prefix="$currencyFormatter.getSign()"
                  @input="changePrice(item)"
                  :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                  :disabled="item.editType === 'Delete'"
                  :rules="[rules.required, rules.nonEmpty, rules.float]"
                ></v-text-field>
              </template>

              <template #[`item.capacity`]="{ item }">
                <v-text-field
                  type="number"
                  :rules="[rules.required, rules.integer]"
                  v-model="item.capacity"
                  :label="$t('capacityDraftEdition')"
                  :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                  :disabled="item.editType === 'Delete'"
                  @input="changeCapacity(item)"
                ></v-text-field>
              </template>

              <template #[`item.quantityToSupply`]="{ item }">
                <v-text-field
                  type="number"
                  :rules="[rules.requiredDesired, rules.integer]"
                  v-model="item.quantityToSupply"
                  :label="$t('desiredQuantityDraftEdition')"
                  :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                  :disabled="item.editType === 'Delete'"
                  @input="changeQuantityToSupply(item)"
                ></v-text-field>
              </template>

              <template #[`item.minimumQuantity`]="{ item }">
                <v-text-field
                  type="number"
                  :rules="[rules.integer]"
                  v-model="item.minimumQuantity"
                  :label="$t('criticalMinimumDraftEdition')"
                  :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                  :disabled="item.editType === 'Delete'"
                  @input="changeMinimumQuantity(item)"
                ></v-text-field>
              </template>

              <template
                #[`item.actions`]="{ item }"
                class="justify-center px-0"
              >
                <v-tooltip left>
                  <template #activator="{ on }">
                    <v-btn
                      text
                      icon
                      color="error"
                      class="ma-0"
                      v-on="on"
                      :class="item.editType === 'Delete' ? 'my-textfield' : '' "
                      :disabled="item.editType === 'Delete'"
                      @click.native="onProductRemoved(item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('removeItemDraftEdition') }}</span>
                </v-tooltip>
              </template>

              <template #[`item.selection`]="{ item }">
                <p :class="item.editType === 'Delete' ? 'my-textfield' : '' ">
                  {{ item.selection }}
                </p>
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <portal to="planogramToolbar">
      <add-entries-bulk
        ref="addEntriesBulk"
        :products="products"
        @add-bulk-entries="addBulkEntries()"
        :pos-type="posType"
      >
        <template #activator="{ on }">
          <v-btn text color="green darken-3" v-on="on">
            <v-icon left>mdi-playlist-plus</v-icon>{{ $t('addMultipleDraftEdition') }}
          </v-btn>
        </template>
      </add-entries-bulk>
      <v-tooltip bottom :disabled="valid">
        <span>{{ $t('fixErrorsDraftEdition') }}</span>
        <template #activator="{ on }">
          <span v-on="on">
            <v-btn :disabled="!valid" text color="info" @click="save" :loading="loading.save">
              <v-icon left>mdi-content-save-all</v-icon>{{ $t('saveDraftEdition') }}
            </v-btn>
          </span>
        </template>
      </v-tooltip>
      <v-btn text color="error" @click="openCancelDialog">
        <v-icon left>mdi-close-thick</v-icon>{{ $t('cancelDraftEdition') }}
      </v-btn>
    </portal>

    <feedback ref="feedback"></feedback>
    <v-dialog v-model="cancelDialog" width="400px">
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('cancelEditPromptDraftEdition') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelDialog = false">{{ $t('informationNo') }}</v-btn>
          <v-btn color="primary" @click="sendCancelFastEditMessage()">{{ $t('informationYes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Feedback from '../components/Feedback.vue';
import AddEntriesBulk from '@/planogram/AddEntriesBulk'

export default {
  components: {
    Feedback,
    'add-entries-bulk': AddEntriesBulk
  },
  props: {
    filteredItems: { type: Array, required: true },
    posType: { type: String },
    testDialog: { type: Boolean },
    planogramId: { type: Number, required: true },
    draftSearch: { type: String },
    totalItems: { type: Number }
  },
  watch: {
    filteredItems (items) {
      this.parentItems = items.map(i => Object.assign({}, i));
      this.parentItems = this.parentItems.map(i => {
        i.searchText =( i.productCode !== null ? i.productCode + " - " : "") + i.productDescription
        return i;
      })
    },

  },
  computed: {
    calculatedTotalItemsFastEdition() {
      return this.totalItems + this.totalItemsVariation;
    },
    filteredHeaders() {
      return this.posType === "MicroMarket"
        ? this.headers.filter((header) => header.value !== "capacity" && header.value !== 'selection')
        : this.headers;
    },
    tableItems() {
      const inventoryItemsIds = this.editOperations.map(i => i.inventoryItemId);
      return this.editOperations.concat(this.parentItems.filter(fi => !inventoryItemsIds.includes(fi.inventoryItemId)));
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      headers: [
        { text: this.$t('productImageDraftEdition'), align: "left", sortable: false, value: "productImageUrl" },
        { text: this.$t('selectionDraftEdition'), align: "left", sortable: true, value: "selection", width: "75px", type: Number},
        { text: this.$t('productDescriptionDraftEdition'), align: "left", sortable: true, value: "productDescription" },
        { text: this.$t('priceDraftEdition'), align: "left", sortable: false, value: "price" },
        { text: this.$t('capacityDraftEdition'), align: "left", sortable: false, value: "capacity" },
        { text: this.$t('desiredQuantityDraftEdition'), align: "left", sortable: false, value: "quantityToSupply" },
        { text: this.$t('criticalMinimumDraftEdition'), align: "left", sortable: false, value: "minimumQuantity"},
        { text: this.$t('actionsDraftEdition'), align: "left", sortable: false, value: "actions" },
      ],
      rules: {
        integer: (v) => !v || /^\d+$/.test(v) || this.$t('integerRules'),
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
        required: (v) => !!v || this.$t('requiredRules'),
        float: (v) => !isNaN(v) || this.$t('floatRules'),
        requiredDesired: (v) => v !== null && v !== undefined && v !== "" || this.$t('requiredDesiredRules'),
      },
      totalItemsVariation : 0,
      valid: true,
      removeDialog: {
        enabled: false,
        item: null,
      },
      fastEditButtons: false,
      loading: {
        products: false,
        save: false
      },
      products: [],
      editOperations: [],
      cancelDialog: false,
      parentItems: [...this.filteredItems]
    };
  },
  methods: {
    onProductRemoved(item) {
      this.totalItemsVariation--;
      if (this.editOperations.includes(item)) {
        // ja existe uma operacao do produto. Se for de edicao, trocar por remocao. Se for de adicao, remover-la da lista.
        if (item.editType === "Add") {
          this.editOperations = this.editOperations.filter((p) => p != item); // remove o item adicionado da lista de operacoes.
        }
        if (item.editType === "Update") {
          // troca a operacao de edicao para remocao.
          item.editType = "Delete";
        }
      } else {
        // nao existe uma operacao previa, ou seja, o produto ja estava no planograma antes da edicao. Inserir operacao de remocao.
        item.editType = "Delete";
        this.editOperations.push(item);
      }
      // this.selectionList = this.selectionList.filter(p => p!= item.selection);
      this.parentItems = this.parentItems.filter((p) => p != item);
    },
    addNewItem() {
      this.totalItemsVariation++;
      var newItem = {
        planogramId: this.planogramId,
        selection: null,
        productId: null,
        productCode: null,
        productDescription: null,
        productCategoryId: null,
        productCategoryName: null,
        productImageUrl: null,
        capacity: null,
        quantityToSupply: null,
        price: null,
        externalUrl: null,
        editType: "Add"
      };
      this.editOperations.unshift(newItem);
    },
    openCancelDialog() {
      if (this.editOperations.length > 0) {
        this.cancelDialog = true;
      } else {
        this.sendCancelFastEditMessage();
      }
    },
    sendCancelFastEditMessage() {
      this.editOperations = [];
      this.$emit("draft-child-cancel");
    },
    onProductEdited(item) {
      const product = this.products.find(
        (p) => p.searchText == item.searchText
      );
      item.price = product.defaultPrice;
      item.productDescription = product.description;
      item.productId = product.id;
      item.productImageUrl = null;
      // Todo: criar metodo para buscar a url da imagem do produto utilizando o id dele.
      if (!this.editOperations.includes(item)) {
        item.editType = "Update";
        this.editOperations.push(item);
      }
    },
    changePrice(item) {
      if (!this.editOperations.includes(item)) {
        item.editType = "Update";
        this.editOperations.push(item);
      }
    },
    changeCapacity(item) {
      if (!this.editOperations.includes(item)) {
        item.editType = "Update";
        this.editOperations.push(item);
      }
    },
    changeQuantityToSupply(item) {
      if (!this.editOperations.includes(item)) {
        item.editType = "Update";
        this.editOperations.push(item);
      }
    },
    changeMinimumQuantity(item) {
      if (!this.editOperations.includes(item)) {
        item.editType = "Update";
        this.editOperations.push(item);
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading.save = true;
        this.axios.put(`api/PlanogramEntries/fast/${this.planogramId}`, this.editOperations)
        .then(response => {
          this.$refs.feedback.handleSuccess(this.$t('successSaveDraftEdition'));
          this.$emit('draft-save', response.data);
          this.parentItems = [];
          this.$forceUpdate();
          this.editOperations = [];
          this.totalItemsVariation = 0;
        })
        .catch(error => {
          this.$refs.feedback.handleError(this.$t('saveFailureDraftEdition'), error);
        })
        .then(() => this.loading.save = false);
      }
    },
    init() {
      this.loading.products = true;
      this.parentItems = this.parentItems.map(i => {
        i.searchText =
          (i.productCode !== null ? i.productCode + " - " : "") + i.productDescription;
        return i;
      })
      this.axios
        .get("api/products/productBaseSimpleInfo", {
          params: {
            page: 1,
            pageSize: -1,
            sortOrder: "description",
            descending: false,
          },
        })
        .then((response) => {
          this.loading.products = false;
          this.products = response.data.items.map((prod) => {
            prod.searchText =
              (prod.code !== null ? prod.code + " - " : "") + prod.description;
            return prod;
          });
        });
    },
    customSort: function(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (sortBy[0] == 'selection') {
          if (a.editType == "Add") {
            return -1;
          }
          if (b.editType == "Add") {
            return 1;
          }
          if (sortDesc[0]) {
            return - (a.selection - b.selection);
          }
          return a.selection - b.selection;
        }
        if (sortBy[0] == 'productDescription') {
          if (a.editType == "Add") {
            return -1;
          }
          if (b.editType == "Add") {
            return 1;
          }
          if (sortDesc[0]) {
            return - a.productDescription.localeCompare(b.productDescription);
          }
          return a.productDescription.localeCompare(b.productDescription);
        }
      });
      return items;
    },
    addBulkEntries() {
      const productsToAdd = this.$refs.addEntriesBulk.productsToAdd;
      this.totalItemsVariation += productsToAdd.length;

      for (let productToAdd of productsToAdd) {
        let newItem = {
        planogramId: this.planogramId,
        selection: null,
        productId: productToAdd.id,
        productCode: productToAdd.code,
        productDescription: productToAdd.description,
        searchText: productToAdd.searchText,
        productCategoryId: null,
        productCategoryName: null,
        productImageUrl: null,
        capacity: this.$refs.addEntriesBulk.capacity,
        quantityToSupply: this.$refs.addEntriesBulk.quantityToSupply,
        price: productToAdd.defaultPrice,
        externalUrl: null,
        editType: "Add"
        };
        this.tableItems.push(newItem);
        this.editOperations.push(newItem);
      }
    }
  }
}
</script>

<style>
.my-textfield {
  text-decoration: line-through;
}

#img {
  filter: grayscale(100%);
}

</style>
