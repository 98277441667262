export default {
    titlePosWithoutPickList: 'Todos los puntos de venta',
    titleWithoutPickList: 'Puntos de venta sin Pick List pendiente',
    showAllPosPosWithoutPickList: 'Mostrar todos los PDVs',
    selectAtLeastOnePosWithoutPickList: 'Selecciona al menos un PDV',
    generatePickLists: 'Generar Pick Lists',
    searchPosWithoutPickList: 'Buscar',
    noResultsPosWithoutPickList: 'Sin resultados',
    machineModelColumnPosWithoutPickList: 'Máquina',
    codeColumnPosWithoutPickList: 'Código PDV',
    customerColumnPosWithoutPickList: 'Cliente',
    locationColumnPosWithoutPickList: 'Ubicación',
    specificLocationColumnPosWithoutPickList: 'Ubicación específica',
    idColumnPosWithoutPickList: 'ID PDV',
};
