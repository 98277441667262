<template>
  <v-expansion-panels v-model="panel" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div><v-icon>filter_list</v-icon> {{ $t('filters.filtersActions') }}</div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form ref="form" v-model="valid">
          <!-- Filters Row -->
          <v-row class="pt-5">
            <!-- 1st column -->
            <v-col cols="12" md="3" class="search-field">
              <v-col class="search-field">
                <point-of-sale-selector
                  v-model="form.pointOfSaleId"
                  :clearable="true"
                  :prependIcon="'mdi-domain'"
                ></point-of-sale-selector>
              </v-col>
            </v-col>

            <!-- Date Picker Column -->
            <v-col cols="12" md="3" class="search-field">
              <v-col class="search-field">
                <date-picker
                  v-model="dateRange"
                  @modify="updateDateRange"
                  :clear="clearDate"
                  @clearComplete="clearDate = false"
                  :allowed-dates="allowedDates"
                />
              </v-col>
            </v-col>
          </v-row>

          <!-- Buttons Row -->
          <v-row justify="end">
            <clear-button
              @clear="clearQuery"
            />

            <excel-button
              @excel="emitExcelRequest"
              @downloaded="notifyExcelDownloaded"
            />

            <update-button
              :valid="valid" 
              @refresh="emitModification"
            />
          </v-row>
        </v-form>
      
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script >
import moment from "moment";
import PointOfSaleSelector from "@/components/PointOfSaleSelector";
import DatePicker from "@/components/filters/DatePicker.vue";
import UpdateButton from "@/components/buttons/UpdateButton.vue";
import ClearButton from "@/components/buttons/ClearButton.vue";
import ExcelButton from "@/components/buttons/ExcelButton.vue";

export default {
  components: {
    PointOfSaleSelector,
    DatePicker,
    UpdateButton,
    ClearButton,
    ExcelButton
  },
  data() {
    return {
      valid: false,
      panel: null,
      form: {
        minDate: null,
        maxDate: null,
        pointOfSaleId: null,
      },
      dateRange: [],
      menu: false,
      loading: {
        excel: false
      },
      downloadDialog: {
        show: false,
        fileName: ""
      },
      clearDate: false,
      minAllowedDate: moment().subtract(3, 'months').format("YYYY-MM-DD"),
      maxAllowedDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    formattedDateRange() {
      if (this.dateRange.length === 2) {
        return `${this.formatDate(this.dateRange[0])} - ${this.formatDate(this.dateRange[1])}`;
      }
      return '';
    },
  },
  watch: {
    dateRange(newRange) {
      if (newRange.length === 2) {
        this.form.minDate = newRange[0];
        this.form.maxDate = newRange[1];
      }
    }
  },
  mounted() {
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 90);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");
    this.dateRange = [this.form.minDate, this.form.maxDate];
    this.$emit("modify", this.form);
  },
  methods: {
    allowedDates(date) {
      const minDate = moment().subtract(3, 'months');
      const maxDate = moment();
      return moment(date).isBetween(minDate, maxDate, 'days', '[]');
    },
    newForm() {
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
      return {
        minDate: moment(minDate).format("YYYY-MM-DD"),
        maxDate: moment(maxDate).format("YYYY-MM-DD"),
        pointOfSaleId: null,
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.clearDateRange();
      this.clearDate = true;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clearDateRange() {
      this.dateRange = [];
      this.form.minDate = null;
      this.form.maxDate = null;
    },
    updateDateRange() {
      if (this.dateRange.length === 2) {
        if (moment(this.dateRange[0]).isBefore(this.minAllowedDate) || moment(this.dateRange[1]).isAfter(this.maxAllowedDate)) {
          this.clearDateRange();
          this.clearDate = true;
        } else {
          this.form.minDate = this.dateRange[0];
          this.form.maxDate = this.dateRange[1];
        }
      }
    },
    emitModification() { 
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    emitExcelRequest() {
      this.loading.excel = true;
      this.$emit("request-excel");
    },
    notifyExcelDownloaded() {
      this.loading.excel = false;
    }
  }
};
</script>