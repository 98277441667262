
import Vue from 'vue';

export default Vue.extend({
  name: "NextFinishButton",
  props: {
    disabled: { type: Boolean, default: false },
    finishing: { type: Boolean, default: false },
  },
});
