export default {
    helpPurchaseTitle: 'Compras',
    helpPurchaseDescription: `
      Nesta página, estão organizadas as notas de compras inseridas pelo usuário, seja por meio de arquivos XML, além das importadas 
      de um sistema ERP. Ao selecionar uma compra específica, é possível acessar detalhes sobre os produtos envolvidos no processo.
    `,
    helpPurchaseInfo: 'Informações da compra:',
    helpOperationId: `
      <strong>ID Operação:</strong>
      ID de identificação da compra. Útil para identificação da movimentação relizada no estoque 
      correspondente nas abas: Estoques &rarr; Movimentações.
    `,
    helpStatus: `
      <strong>Status:</strong> 
      Assim que é importada, a compra possui o status <strong>Ativa</strong>.
      Caso haja o cancelamento da compra, seu status se torna <strong>Cancelada</strong>
      e suas movimentações de estoque são desfeitas.
    `,
    helpErpId: `
      <strong>ID no ERP:</strong> 
      ID interno de identificação da compra no ERP (apenas para notas importadas de sistemas ERP)
    `,
    helpNoteNumber: `
      <strong>Número da nota:</strong> 
      Número de identificação da nota fiscal.
    `,
    helpInventoryId: `
      <strong>ID Estoque e Estoque:</strong> 
      Estoque onde as movimentações da compra foram realizadas.
    `,
    helpItemQuantity: `
      <strong>Quantidade de itens:</strong> 
      Quantidade total de itens contidos na compra.
    `,
    helpNoteValue: `
      <strong>Valor da nota:</strong> 
      Representa o valor total de todos os produtos contidos na nota fiscal da compra.
    `,
    helpTotalCost: `
      <strong>Custo total da compra:</strong> 
      Representa o custo total de todos os produtos contidos na nota fiscal da compra
      já considerando estimativa de impostos calculado.
    `,
    helpReceivedDate: `
      <strong>Data de recebimento:</strong> 
      Data e hora que foi realizado o recebimento da compra no ERP.
    `
  };
  