export default {
    supplyHelpTitle: `Supply`,
    textSupplyHelp: `<p>This screen displays the refills and inventory controls performed on TouchPay.</p>
    <p>The refilling option can be accessed in three ways:</p>
    <ul>
        <li><strong>Refilling via terminal</strong>: The refilling option via terminal is accessed through the payment terminal's administrator menu.</li>
        <li><strong>Refilling via TouchPay Refiller app</strong>: Refilling via the app can be done when a pick list is pending/separated. In this option, the user can edit quantities and expiration dates, as well as remove items.</li>
        <li><strong>Refilling via telemetry</strong>: Refilling can also be done via the telemetry page of the web system; however, in this mode, it is not possible to edit the refilled quantities, perform inventory control, or inform product expiration dates. It is possible to refill a created pick list or perform a full refill. In a full refill, items will be refilled to reach the desired quantity for that planogram, always being the difference between the current quantity and the desired amount.</li>
    </ul>
    <p>The refilling updates the stock of your point of sale with the refilled products. A Pick List is required for each refill that is not complete.</p>
    <p>The inventory option can be accessed in three ways:</p>
    <ul>
        <li><strong>Inventory via terminal</strong>: The inventory option via terminal is accessed through the payment terminal's administrator menu.</li>
        <li><strong>Inventory via TouchPay Refiller app</strong>: Inventory via the app can be done when a POS has a Planogram. In this option, the user can edit quantities and expiration dates, as well as remove items.</li>
        <li><strong>Inventory via Stock</strong>: Inventory can also be performed via the stock page of the web system; however, in this mode, it is not possible to edit product expiration dates, and there is no reference for the desired quantity. This operation can only be performed by users with manager or administrator permissions.</li>
    </ul>`,
}