export default {
    newProductDialog: 'Nuevo producto',
    editProductDialog: 'Editar producto',
    ageRestricted: 'Restringido por edad',
    conversionUnit: 'Unidad de conversión',
    codeProductDialog: 'Código',
    descriptionProductDialog: 'Descripción',
    categoryProductDialog: 'Categoría',
    defaultPriceProductDialog: "Precio por defecto ({sign})",
    barCodeProductDialog: 'Código de barras',
    ncmProductDialog: 'NCM',
    extipiProductDialog: 'EX-TIPI',
    cestProductDialog: 'CEST',
    originProductDialog: 'Origen',
    purchaseSettings: 'Configuraciones de compra',
    addTaxGroupProductDialog: 'Agregar Grupo Fiscal',
    saveProductDialog: 'Guardar',
    closeProductDialog: 'Cerrar',
    backToPlanogram: 'Volver al planograma',
    fiscalInformation: 'Información fiscal',
    fiscalScenariosProductDialog: 'Escenarios fiscales',
    productsMustBeAddedByErp: 'Los productos deben ser agregados por el ERP',
    noFiscalScenariosRegistered: 'No hay escenarios fiscales registrados',
    errorSavingItemProductDialog: 'Error al guardar el elemento',
    fiscalScenarioProductDialog: 'Escenario Fiscal',
    principalProductDialog: 'Principal',
    deleteProductDialog: 'Eliminar',
    fiscalScenarioAddedSuccessfully: 'Escenario fiscal agregado correctamente',
    unitProductDialog: 'Unidad',
    quantityPerProductDialog: 'Cantidad por ',
    informationUpdatedSuccessfully: 'Información actualizada correctamente',
    synchronizedWithErp: 'Este producto está sincronizado con el ERP. Algunos campos solo pueden ser modificados por el ERP.',
    noTaxScenarios: 'No hay escenarios fiscales para el producto',
    uniMustBeA: 'La unidad de "unidad" debe ser 1',
    saveItemErrorProductDialog: 'Error al guardar el producto',
    getTaxGroupsCfop: ' - CFOP: ',
};
