export default {
    xmlProductsDialogCardTitle: 'PRODUCTS INFORMATION',
    xmlProductsDialogHelp1: 'Product information',
    xmlProductsDialogParagraph1: `On this page, data extracted from submissions via XML is available for association with products
    specific, allowing the selection of the conversion unit to update the stock.`,
    xmlProductsDialogProductName: 'Product name',
    xmlProductsDialogProductCod: 'Product code',
    xmlProductsDialogUnitConversion: 'conversion unit',
    xmlProductsDialogUnit: 'Units: ',
    xmlProductsDialogSelectProduct: 'Select Product ',
    xmlProductsDialogProductSelect: 'Product already selected',
    xmlProductsDialogHelp2: 'If the barcode of the product on the note is the same as the code of a product in the system, it will already be pre-selected.',
    xmlProductsDialogUnitConversionLabel: 'Conversion unit',
    xmlProductsDialogUnitConversionTitle: 'Conversion unit / Units',
    xmlProductsDialogProductsTitle: 'Products',
    xmlProductsDialogParagraph2: `If the drive is classified as unknown, this indicates that our system was unable to interpret
    the unit mentioned in the XML. To correct, simply select the price per unit (such as units, boxes or bales) and
    indicate how many units make up this conversion measurement (e.g. unit = 1, box = 10, bale = 6).<br>
    Note: the quantity entered does not accept decimal numbers.`,
    xmlProductsDialogParagraph3: 'Total product quantity: ',
    xmlProductsDialogHelp3: 'Total product quantity',
    xmlProductsDialogParagraph4: `After entering quantity and cost data, our system will automatically calculate the total units
    the corresponding unit price. For example, by inserting two boxes, each containing 10 units, at the cost of
    10 reais per box: Total units: 20 | Unit price: R$ 1.00. This functionality simplifies viewing the
    total number of products and their cost per unit, making information analysis easier.`,
    xmlProductsDialogRegisteredProduct: 'Is this product not registered?',
    xmlProductsDialogUnitaryValue: 'Product unit value:',
    xmlProductsDialogClose: 'CLOSE',
    xmlProductsDialogToGoBsck: 'BACK',
    xmlProductsDialogParagraph5: 'To proceed, fill in: unit value, product, conversion unit, quantity.',
    xmlProductsDialogRules: 'Only integers are accepted',
    xmlProductsDialogError: 'Error fetching conversion units',
    xmlProductsDialogFeedback: 'Unable to select a grouping',
    xmlProductsDialogProduct: 'Product',
    xmlProductsDialogProduct2: 'OF',
    xmlProductsDialogProductNext: 'NEXT',
    xmlProductsDialogProductFinish: 'FINISH',
    };