
import {defineComponent} from 'vue'
import PointOfSaleSelector from "@/components/PointOfSaleSelector.vue";
import agent from "@/api/agent";
import DownloadDialog from "@/components/common/DownloadDialog.vue";
import TaxingInvoiceTestHelp from "@/views/taxings/helpers/TaxingInvoiceTestHelp.vue";

const formInitialState = {
  pointOfSaleId: null as number | null,
}

export default defineComponent({
  components: {TaxingInvoiceTestHelp, DownloadDialog, PointOfSaleSelector},
  props: {
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      form: {...formInitialState},
      loading: false,
      valid: false,
      downloadDialog: {
        show: false,
        fileName: ""
      }
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    validatePointOfSaleTaxings() {
      this.loading = true;
      agent.Taxings.pointOfSaleNfcValidation(this.form.pointOfSaleId!)
        .then((response) => {
          this.downloadDialog.fileName = response;
          this.downloadDialog.show = true;
        })
        .catch((error) => {
          this.handleError("Não foi possível gerar excel", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
})
