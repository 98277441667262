<template>
  <v-row>
    <portal to="toolbarTitle">
      <help>
        <template #title>
          {{$t('inventory.help.title')}}
        </template>
       <div v-html="$t('inventory.help.description')"></div>
      </help>
    </portal>
    <v-col cols="12">
      <v-tabs
        v-model="active"
        grow
        background-color="grey lighten-4"
      >
        <v-tab :key="0">{{$t('inventory.items.title')}}</v-tab>
        <v-tab :key="1">{{$t('inventory.inventoryTransaction.tab')}}</v-tab>
        <v-tab-item :key="0">
          <inventory-items></inventory-items>
        </v-tab-item>
        <v-tab-item :key="1">
          <inventory-transactions></inventory-transactions>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import InventoryItems from "./InventoryItems.vue";
import InventoryTransactions from './InventoryTransactions.vue';

export default {
  data() {
    return {
      active: 0,
    };
  },
  components: {
    InventoryItems,
    InventoryTransactions,
  },
  computed: {
    routeParams() {
      return Object.assign({}, this.$route.query);
    }
  },
  watch: {
    active: {
      handler(value) {
        // If this.active is to be initialized as 0, but there is also a query in the $route,
        // then it means the page is coming from a product in a planogram, and so it has to
        // show the transactions page, thus active = 1;
        if (Object.keys(this.routeParams).length != 0 && value == 0) {
          this.active = 1;
        }
      },
      // Immediate, so it listens to when the value is first valued by the data() return object.
      immediate: true
    }
  },
};
</script>
