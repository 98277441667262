export default {
    detailXmlDialogInf: 'INFORMAÇÕES DA NOTA FISCAL',
    detailXmlDialogAddProducts: 'Detalhes',
    detailXmlDialogReceiptNumber: 'Número na Nota',
    detailXmlDialogReceiptSeries: 'Série',
    detailXmlDialogReceiptSupplier: 'Fornecedor',
    detailXmlDialogReceiptAccessKey: 'Chave de acesso (NFe)',
    detailXmlDialogReceiptIssueDate: 'Data de Emissão',
    detailXmlDialogReceiptProducts: 'Produtos',
    detailXmlDialogReceiptDelete: 'Excluir',
    detailXmlDialogReceiptReceiveProducts: 'Receber Produtos',
    detailXmlDialogReceiptCode: 'Código',
    detailXmlDialogReceiptProduct: 'Produto',
    detailXmlDialogReceiptQuantity: 'Qtd na nota',
    detailXmlDialogReceiptPurchaseUnit: 'Unidade de Compra',
    detailXmlDialogReceiptTotalUnit: 'Unidade total',
    detailXmlDialogReceiptUnitPrice: 'Valor Unitário',
    detailXmlDialogReceiptTotalPrice: 'Valor total',
    detailXmlDialogReceiptActions: 'Ações',
    detailXmlDialogReceiptSuccess: 'Compra inserida com sucesso',
    detailXmlDialogReceiptFailure: 'Não foi possível inserir a compra',
    detailXmlDialogReceiptTotalPurchaseValue: 'Valor Total da compra',
    detailXmlDialogReceiptUnit: 'Unidade',
    detailXmlDialogReceiptBox: 'Caixa',
    detailXmlDialogReceiptBundle: 'Fardo',
    detailXmlDialogReceiptPackage: 'Pacote',
    detailXmlDialogReceiptUnknown: 'Desconhecido',
    detailXmlDialogStockCentral: 'Para prosseguir preencha o estoque central',
};
