import i18n from "@/locales/i18n";

const paymentMethodTranslations = {}
paymentMethodTranslations['Credit'] = i18n.t('creditPaymentMethods');
paymentMethodTranslations['Debit'] = i18n.t('debitPaymentMethods');
paymentMethodTranslations['Voucher'] = i18n.t('voucherPaymentMethods');
paymentMethodTranslations['RemoteCredit'] = i18n.t('remoteCreditPaymentMethods');
paymentMethodTranslations['Cash'] = i18n.t('cashPaymentMethods');
paymentMethodTranslations['AppSale'] =i18n.t('appSalePaymentMethods');
paymentMethodTranslations['PicPay'] = i18n.t('picPayPaymentMethods');
paymentMethodTranslations['Free'] = i18n.t('freePaymentMethods');
paymentMethodTranslations['ExternalApp'] = i18n.t('externalAppPaymentMethods');
paymentMethodTranslations['AppCreditCard'] = i18n.t('appCreditCardPaymentMethods');
paymentMethodTranslations['Pix'] = i18n.t('pixPaymentMethods');
paymentMethodTranslations["Cashless"] = i18n.t('cashlessPaymentMethods');
paymentMethodTranslations["Others"] = i18n.t('othersPaymentMethods');
paymentMethodTranslations["QrCode"] = i18n.t('qrcodePaymentMethods');
paymentMethodTranslations['Unknown'] = "-";

export { paymentMethodTranslations };
