export default {
    createPlanogramTitle: 'Crear planograma',
    copyFromActive: 'Copiar del planograma activo',
    emptyPlanogram: 'Crear planograma vacío',
    copyFromOtherPlanogram: 'Copiar de otro planograma',
    copyFromPriceTable: 'Copiar desde una tabla de precios',
    copyFromOtherPlanogramText: 'Copiar de otro planograma',
    copyFromPriceTableText: 'Copiar desde una tabla de precios',
    versionOfPlanogram: 'Versión del planograma',
    noPlanograms: 'Sin planogramas en los puntos de venta',
    selectPriceTable: 'Seleccione una tabla de precios',
    addProductsFromPriceTable: 'Agregar productos de la tabla de precios que no estén en el planograma',
    removeProductsFromPlanogram: 'Eliminar del planograma productos que no estén en la tabla de precios',
    btnClose: 'Cerrar',
    btnCreate: 'Crear',
    errorCreatingPlanogram: 'Error al crear el planograma',
    errorLoadingPriceTables: 'Error al cargar las tablas de precios',
    priceTableLabel: 'Tabla de precios',
    planogramReturn: 'Planograma',
    createPlanogramError: 'Error al aplicar la tabla de precios ',
    createPlanogramPos: 'en el punto de venta',
    createPlanogramTryAgain: ' Intente de nuevo.',
    errorCopyingPriceTable: 'Error al copiar la tabla de precios: ',
    errorCreatingPosPlanograms: 'Error al crear el planograma en el punto de venta',
    warningEmptyPlanogramCreation: `<strong>ATENCIÓN:</strong>
    <p>
      Los puntos de venta seleccionados ya tienen un planograma activo con productos, y continuar resultará en un planograma vacío.
      Recuerde agregar producto(s) posteriormente para finalizar el borrador.
      Esto es importante para garantizar que los terminales en operación continúen transaccionando y que no pierda el inventario guardado.
    </p>  
    <p>Para agregar productos en el planograma ya activo, utilice la opción <strong>"Copiar del planograma activo"</strong>.</p>
    <p>¿Desea continuar de todos modos?</p>`,
    cancelButton: 'Cancelar',
    attentionEmptyPlanogramCreation: 'AVISO',
};
