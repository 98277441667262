
import Vue from 'vue';
import PrivateLabelUsers from './PrivateLabelUsers.vue'
import PrivateLabelHelp from './PrivateLabelHelp.vue'
import PrivateLabelOperations from './PrivateLabelOperations.vue'

export default Vue.extend({
  components: {
    PrivateLabelUsers,
    PrivateLabelHelp,
    PrivateLabelOperations
  },
  data() {
    return {
      activeTab: 0,
    }
  }
})
