
import Vue from "vue";
import { formatPercentage } from "@/utility/TextFormatting";
import { PointOfSaleConnectivityDashboard } from "@/types/Connectivity";
import { ProgressLinear } from "@/types/ProgressLinear";
import ConnectivityHelp from "@/views/dashboard-new/helpers-new/ConnectivityPointOfSaleHelp.vue";
import agent from "@/api/agent";
import DoughnutChart from "@/report/DoughnutChart";
import LinearBar from "@/components/LinearBar.vue";

export default Vue.extend({
  data() {
    return {
      total: 0 as number,
      quantityConnectedPos: {
        onlineQuantity: 0,
        onlinePercent: 0,
        alertQuantity: 0,
        alertPercent: 0,
        offlineQuantity: 0,
        offlinePercent: 0,
      } as PointOfSaleConnectivityDashboard,
      graph: {
        visible: false as boolean,
        datasets: [] as object[],
      },
      colors: {
        online: (this.$vuetify.theme as any).defaults.dashboard.dashboardGreenDarken1,
        alert: (this.$vuetify.theme as any).defaults.dashboard.dashboardYellow,
        offline: (this.$vuetify.theme as any).defaults.dashboard.dashboardRed,
      },
      statusItems: [] as ProgressLinear[],
    };
  },
  mounted() {
    this.getPointOfSaleMostSales();
  },
  methods: {
    getPointOfSaleMostSales() {
      this.graph.visible = false;
      agent.Connectivity.percentList()
        .then((response) => {
          this.quantityConnectedPos = response;

          this.total =
            this.quantityConnectedPos.onlineQuantity +
            this.quantityConnectedPos.alertQuantity +
            this.quantityConnectedPos.offlineQuantity;

          this.graph.datasets = [
            {
              label: [
                this.$t("posStatusPercentageOnline"),
                this.$t("posStatusPercentageAlert"),
                this.$t("posStatusPercentageOffline")
              ],
              data: [
                formatPercentage(this.quantityConnectedPos.onlinePercent),
                formatPercentage(this.quantityConnectedPos.alertPercent),
                formatPercentage(this.quantityConnectedPos.offlinePercent),
              ],
              backgroundColor: [
                this.colors.online,
                this.colors.alert,
                this.colors.offline,
              ],
            },
          ];
          this.statusItems = this.getStatusItems();
        })
        .catch(() => {})
        .finally(() => {
          this.graph.visible = true;
        });
    },
    getStatusItems(): ProgressLinear[] {
      return [
        {
          label: this.$t("telemetry.status.online") as string,
          percentage: this.quantityConnectedPos.onlinePercent,
          value: `${this.quantityConnectedPos.onlineQuantity}/${this.total}`,
          color: this.colors.online,
        },
        {
          label: this.$t("telemetry.status.alert") as string,
          percentage: this.quantityConnectedPos.alertPercent,
          value: `${this.quantityConnectedPos.alertQuantity}/${this.total}`,
          color: this.colors.alert,
        },
        {
          label: this.$t("telemetry.status.offline") as string,
          percentage: this.quantityConnectedPos.offlinePercent,
          value: `${this.quantityConnectedPos.offlineQuantity}/${this.total}`,
          color: this.colors.offline,
        },
      ];
    },
    formatPercentage,
  },
  components: {
    DoughnutChart,
    ConnectivityHelp,
    LinearBar,
  },
});
