
import {defineComponent} from 'vue'
import agent from "@/api/agent";
import {GroupClass, TenantClass} from "@/types/Tenant";

export default defineComponent({
  data() {
    return {
      tenants: [] as TenantClass[],
      groups: [] as GroupClass[],
      loading: {
        tenants: false,
        groups: false,
        change: false,
      },
      tenantId: null,
      groupId: null,
      valid: true,
    }
  },
  mounted() {
    this.getTenants();
    this.groupId = (this as any).$auth.user().tenantGroupId;
    this.tenantId = (this as any).$auth.user().tenantId;
  },
  watch: {
    tenantId(_, oldValue) {
      if (oldValue != null) {
        this.groupId = null;
      }
      this.getTenantGroups();
    }
  },
  methods: {
    getTenants() {
      this.loading.tenants = true;
      agent.Tenants.getTenants()
        .then((response) => {
          this.loading.tenants = false;
          this.tenants = response.map((t) => new TenantClass(t));
        })
    },
    getTenantGroups() {
      if (this.tenantId == null) return;
      this.loading.groups = true;
      agent.Tenants.getTenantGroups(this.tenantId)
        .then((response) => {
          this.loading.groups = false;
          this.groups = response.map((t) => new GroupClass(t));
          if (this.groups.every((g) => g.groupId != this.groupId)) {
            this.groupId = null;
          }
        })
    },
    submit() {
      const valid = (this as any).$refs.form.validate();
      if (!valid || this.tenantId == null) return;
      this.loading.change = true;
      agent.User.getNewTenantToken(this.tenantId, this.groupId)
        .then(() => {
          location.reload();
        }).catch((error) => {
          (this as any).$refs.feedback.handleError(error);
        }).then(() => {
          this.loading.change = false;
        });
    }
  }
})
