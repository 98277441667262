export default {
  buildingManagerReportHeader: "Filters",
  buildingManagerMinDateLabel: "Start Date",
  buildingManagerMaxDateLabel: "End Date",
  buildingManagerExcel: "Excel",
  buildingManagerUpdate: "Update",
  buildingManagerTableTitle: "Sales Report",
  bManagerSalesPointOfSaleId: "POS Id" ,
  bManagerSalesBoxId: "Box Id",
  bManagerSalesLocalCustomerName: "Customer",
  bManagerSalesLocalName: "Location",
  bManagerSalesSpecificLocation: "Specific Location",
  bManagerSalesTotal: "Total Sales",
  buildingManagerHandleErrorMessage: "There was an error loading the report",
  bManagerExcelDownloadMessage: "The report {filename} will be available in the Downloads page",
  salesByCategoryDialogTitle: "Top Selling Categories of POS {posId}",
  categorySalesHeaderCategoryId: "Category Id",
  categorySalesHeaderCategory: "Category",
  categorySalesHeaderQuantity: "Quantity",
  categorySalesHeaderTotals: "Sales",
  categorySalesCloseButton: "Close",
  buildingManagerGetCategoriesSalesErrorMessage: "There was an error loading the sales by category"
}
