
  import Vue from 'vue';
  import LossesProductAndPosHelp from '@/views/dashboard-new/helpers-new/LossesProductAndPosHelp.vue';
  import i18n from '@/locales/i18n';
  import agent from "@/api/agent";
  import {ProductPosLosses} from "@/types/OperationalReports";

  export default Vue.extend({
    components: {
      LossesProductAndPosHelp
    },
    props: {
      mustCallEndpoints: { type: Boolean, default: false },
      form: { type: Object, required: true }
    },
    data() {
      return {
        productsPosLosses: {} as ProductPosLosses,
        params: {
          pointOfSaleId: [] as number[],
          reasonsForLosses: "Expired",
          timezoneOffset: new Date().getTimezoneOffset(),
        },

        productLoss: {
          loading: true,
          headers: [
            { text: i18n.t('lossesProductAndPosyColumnProduct'), align: 'left', sortable: false, value: 'description'},
            { text: i18n.t('lossesProductAndPosColumnLossesValue'), align: 'left', sortable: false, value: 'productTotalLosses', width: '30px'},
            { text: i18n.t('lossesProductAndPosColumnAmount'), align: 'left', sortable: false, value: 'productTotalQuantities', width: '30px'},
          ],
          items: [],
          options: {
            page: 1,
            itemsPerPage: 5,
            sortBy: ['productTotalLosses'],
            sortDesc: [true]
          },
          totalItems: 0
        },

        posLoss: {
          loading: true,
          headers: [
            { text: i18n.t('lossesProductAndPosyColumnPos'), align: 'left', sortable: false, value: 'customerName' },
            { text: i18n.t('lossesProductAndPosColumnLossesValue'), align: 'left', sortable: false, value: 'posTotalLosses', width: '30px'},
            { text: i18n.t('lossesProductAndPosColumnAmount'), align: 'left', sortable: false, value: 'posTotalQuantities', width: '30px'},
          ],
          items: [],
          options: {
            page: 1,
            itemsPerPage: 5,
            descBy: ['posTotalLosses'],
            sortDesc: [true]
          },
          totalItems: 0
        },

        reasonsForLosses: [
          {text: i18n.t('reasonsForLossesExpired'), value: "Expired"},
          {text: i18n.t('reasonsForLossesDamaged'), value: "Damaged"},
          {text: i18n.t('reasonsForLossesDetour'), value: "Correction"},
          {text: i18n.t('reasonsForLossesOther'), value: "Other"},
        ],

        selectedReasonForLoss: 'Expired',
        selectedView: 'products',
      };
    },
    mounted() {
      this.getProductPosLosses();
    },
    watch: {
      mustCallEndpoints: {
        handler(value) {
          if (value == true) {
            this.params.pointOfSaleId = this.form.pointOfSaleId
            this.params.timezoneOffset = this.form.timezoneOffset;
            this.getProductPosLosses();
          }
        },
      },
    },
    methods: {
      filterByReason() {
        if (this.selectedReasonForLoss) {
          this.params.reasonsForLosses = this.selectedReasonForLoss;
        }
        this.getProductPosLosses();
      },
    getProductPosLosses() {
        this.productLoss.loading = true;
        this.posLoss.loading = true;
        let params = { 
          ...this.form,
          reasonsForLosses: this.params.reasonsForLosses,
        };  
        agent.OperationalReports.getProductPosLosses(params)
          .then((response) => {
            this.productLoss.loading = false;
            this.posLoss.loading = false;
            this.productsPosLosses = response;
          })
      },
    switchView(value: boolean) {
      this.selectedView = value ? 'pos' : 'products';
    },
    },
    computed: {
    cardTitle() {
      return (this as any).selectedView === 'products' ? i18n.t('lossesProductAndPosTitleProduct') : i18n.t('lossesProductAndPosTitlePos');
    },
    cardSubTitle(){
      return (this as any).selectedView === 'products' ? i18n.t('lossesProductAndPosSubTitleProduct') : i18n.t('lossesProductAndPosSubTitlePos');
    },    
    labelProductPos() {
      return (this as any).selectedView === 'pos' ? i18n.t('lossesProductAndPosTitleProduct') : i18n.t('lossesProductAndPosTitlePos') ;
    }
  }
  });
  