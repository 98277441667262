export default {
  taxings: {
    brazil: {
      reductionPercentageIcms: 'ICMS base reduction percentage',
      help: {
        description: `
            <p>
              The ICMS tax relief percentage is used to calculate partial or total exemption of the tax on the value of the goods or services.
              <strong>This tax relief is valid for presumed profit and real profit tax regimes. Depending on the applied Tax Situation Code (CST), the way the relief is calculated may vary:</strong>
            </p>
            <ul>
              <li>
                <strong>CST 20 or 70</strong>: In these cases, the relief is calculated based on the reduction percentage applied to the ICMS rate. The calculation uses the formula:
                <br><br>
                <code>
                Relief Amount = (Total Value × (1 - (Rate × (1 - Reduction Percentage / 100)))) / (1 - Rate) - Total Value
                </code>
                <br><br>
                The ICMS rate is adjusted based on the informed reduction percentage.
              </li><br>
              <li>
                <strong>CST 30 or 40</strong>: In these cases, the relief is fully applied to the total ICMS, and the relieved amount is calculated directly as a fraction of the total value, based on the tax rate:
                <br><br>
                <code>
                Relief Amount = Total Value × Rate
                </code>
                <br><br>
              </li>
            </ul>
        `,
        alert: `The reason for the tax relief on the invoice is assigned with the value "9", which corresponds to "others". This occurs automatically by the system when filling in the ICMS base reduction percentage field.`
      }
    }
  }
}
