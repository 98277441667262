export default {
  methods: {
    formatMaskPN() {
      if (this.$auth.user().tenantCountry == "BR") return "+## ## #####-####";
      if (this.$auth.user().tenantCountry == "US") return "+# ###-###-####";
      if (this.$auth.user().tenantCountry == "CO") return "+## (###) ### ####";
      
      return "+## ## #####-####";
    },
    placeholderPN() {
      if (this.$auth.user().tenantCountry == "BR") return "+55 11 11111-1111"
      if (this.$auth.user().tenantCountry == "US") return "+1 111-111-1111"
      if (this.$auth.user().tenantCountry == "CO") return "+57 (111) 111 1111"
      
      return "+55 11 11111-1111"
    },
    rulePN() {
      if (this.$auth.user().tenantCountry == "BR") {
        return (v) => !v || (v.replace(/\D/g, '').length === 13 ||  v.replace(/\D/g, '').length === 11) || this.$t('countryCodeRules')
      }
      if (this.$auth.user().tenantCountry == "US") {
        return (v) => !v || (v.replace(/\D/g, '').length === 11 ||  v.replace(/\D/g, '').length === 10) || this.$t('countryCodeRules')
      }
      if (this.$auth.user().tenantCountry == "CO") {
        return (v) => !v || (v.replace(/\D/g, '').length === 12 ||  v.replace(/\D/g, '').length === 10) || this.$t('countryCodeRules')
      }
      
      return (v) => !v || (v.replace(/\D/g, '').length === 13 ||  v.replace(/\D/g, '').length === 11) || this.$t('countryCodeRules')
    }
  }
}
