export default{
    titleProductsPurchase: 'INFORMACIÓN DE LA FACTURA',
    productsProductsPurchase: 'Productos',
    productsComplementProductsPurchase: 'de',
    infoProductsPurchase: 'Información del producto',
    helpProductsPurchase: 'Añade productos a la factura manualmente, para añadir un nuevo producto haz clic en siguiente, para finalizar la factura haz clic en terminar',
    selectedProductsPurchase: 'Producto ya seleccionado',
    unitInvoiceProductsPurchase: 'Unidad en la factura',
    valueUnitHelpTitleProductsPurchase: 'Valor por unidad',
    valueUnitHelpProductsPurchase: 'Introduce el valor de cada unidad y la cantidad del producto correspondiente en la factura.',
    quantityProductsPurchase: 'Cantidad del artículo:',
    quantityHelpTitleProductsPurchase: 'Unidad de conversión / Unidades',
    quantityHelpProductsPurchase: 'Selecciona la unidad de conversión en el sistema. (como unidades, cajas o fardos) e indica cuántas unidades componen esta medida de conversión (por ejemplo, unidad = 1, caja = 10, fardo = 6).',
    quantityTotalProductsPurchase: 'Cantidad total del producto:',
    quantityTotalHelpTitleProductsPurchase: 'Cantidad total del producto',
    quantityTotalHelpProductsPurchase: `Después de introducir los datos de cantidad y coste, nuestro sistema calculará automáticamente las unidades totales 
    el precio unitario correspondiente. Por ejemplo, al introducir dos cajas, cada una conteniendo 10 unidades, a un coste de 
    10 reales por caja: Unidades totales: 20 | Precio por unidad: R$ 1.00. Esta funcionalidad simplifica la visualización de 
    los productos totales y su coste por unidad, facilitando el análisis de la información.`,
    valueUnitProductPurchase: 'Valor unitario del producto:',
    productNotRegistry: '¿Producto no registrado?',
    closeProductsPurchase: 'CERRAR',
    finishProductsPurchase: 'AGREGAR Y FINALIZAR',
    backProductsPurchase: 'ATRÁS',
    nextProductsPurchase: 'SIGUIENTE',
    addProductsPurchase: 'AGREGAR OTRO',
    messageValidButtonProductsPurchase: 'Para proceder, rellena todos los datos',
    unitConversionProductsPurchase: 'Unidad de conversión',
    valueProductPurchase: 'Valor en $',
    removeProductsPurchase: 'ELIMINAR',
}
