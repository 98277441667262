export default {
    createPlanogramTitle: 'Criar planograma',
    copyFromActive: 'Copiar do planograma ativo',
    emptyPlanogram: 'Criar planograma vazio',
    copyFromOtherPlanogram: 'Copiar de outro planograma',
    copyFromPriceTable: 'Copiar a partir de uma tabela de preços',
    copyFromOtherPlanogramText: 'Copiar de outro planograma',
    copyFromPriceTableText: 'Copiar a partir de uma tabela de preços',
    versionOfPlanogram: 'Versão do Planograma',
    noPlanograms: 'Sem planogramas no pontos de venda',
    selectPriceTable: 'Selecione uma tabela de preços',
    addProductsFromPriceTable: 'Adicionar produtos da Tabela de Preço ausentes no Planograma',
    removeProductsFromPlanogram: 'Remover do Planograma produtos ausentes da Tabela de Preço',
    btnClose: 'Fechar',
    btnCreate: 'Criar',
    errorCreatingPlanogram: 'Erro ao criar planograma',
    errorLoadingPriceTables: 'Erro ao carregar as tabelas de preço',
    priceTableLabel: 'Tabela de preços',
    planogramReturn: 'Planograma',
    createPlanogramError: 'Erro ao aplicar a tabela de preço ',
    createPlanogramPos: 'no ponto de venda',
    createPlanogramTryAgain: ' Tente novamente.',
    errorCopyingPriceTable: 'Erro ao copiar tabela de preço: ',
    errorCreatingPosPlanograms: 'Erro ao criar planograma no ponto de venda',
    warningEmptyPlanogramCreation: `<strong>ATENÇÃO:</strong>
    <p>
      Os pontos de venda selecionados já possuem um planograma ativo com produtos, e prosseguir resultará em um planograma vazio.
      Lembre-se de adicionar produto(s) posteriormente para finalizar o rascunho.
      Isso é importante para garantir que os terminais em operação continuem transacionando e que você não perca o estoque salvo.
    </p>  
    <p>Para adicionar produtos no planograma já ativo, utilize a opção <strong>"Copiar do planograma ativo"</strong>.</p>
    <p>Deseja continuar mesmo assim?</p>`,
    cancelButton: 'Cancelar',
    attentionEmptyPlanogramCreation: 'AVISO',
  };