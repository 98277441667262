export default {
  "promotion": {
    "name": "Nome da promoção",
    "type": "Tipo de promoção",
    "starts-on": "Inicia em",
    "expires-on": "Expira em",
    "date-created": "Data de criação",
    "usage": "Número de utilizações",
    "actions": "Ações",
    "no": "Não",
    "created": "Promoção criada",
    "edited": "Promoção editada",
    "no-items": "Não há promoções cadastradas",
    "remove": {
      "label": "Excluir",
      "message": "Tem certeza de que deseja remover a promoção",
      "success": "Promoção removida com sucesso",
      "fail": "Erro ao remover promoção",
    },
    "period": "Período da promoção",
    "details": {
      "new": {
        "text": "Nova promoção",
        "error": "Erro ao salvar promoção",
      },
      "edit": {
        "text": "Editar promoção",
        "error": "Erro ao editar promoção"
      },
      "title": "Promoção {description}",
      "description": "Breve descrição sobre a promoção",
      "starts-on": "Data de início",
      "starts-on-label": "Data de início da promoção",
      "expires-on": "Data de encerramento",
      "expires-on-label": "Data de encerramento da promoção",
      "expires-on-no-date": "Não expira",
      "points-of-sale": {
        "all": "Aplicar em todos os pontos de venda",
        "specific": "Aplicar em pontos de venda específicos",
        "specific-error": "Escolha pelo menos um ponto de venda",
        "choose": "Escolha os pontos de venda que serão aplicados",
      },
      "product": {
        "add": "Adicionar produtos",
        "name": "Produto",
        "name-label": "Escolha um produto",
        "items": "Quantidade de itens por compra",
        "items-label": "Unidades do produto",
        "button-add": "Adicionar produto",
        "error": {
          "required": "A promoção deve ter pelo menos um produto",
        },
      },
      "discount": {
        "type": {
          "text": "Tipo de desconto",
          "label": "Em porcentagem ou valor fixo"
        },
        "invalid": "Valor de desconto inválido",
        "amount": {
          "label": "Valor que será aplicado",
          "percentage": "Valor em porcentagem",
          "fixed": "Valor fixo em",
        },
        "choose": "Selecione o tipo de desconto",
      },
      "missing-data": "Há dados faltando",
    },
  },
}