<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-model="active" grow background-color="grey lighten-4">
        <v-tab :key="0">{{ $t('productsMaster') }}</v-tab>
        <v-tab :key="1">{{ $t('productsMasterCategories') }}</v-tab>
        <v-tab :key="2">{{ $t('productsMasterGroupings') }}</v-tab>
        <v-tab-item :key="0">
          <products></products>
        </v-tab-item>
        <v-tab-item :key="1">
          <categories></categories>
        </v-tab-item>
        <v-tab-item :key="2">
          <product-groups></product-groups>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import Products from '@/views/products/Products'
import Categories from '@/views/products/Categories'
import ProductGroups from '@/views/products/ProductGroups'

export default {
  data() {
    return {
      active: 0
    }
  },
  components: {
    Products,
    Categories,
    ProductGroups
  }
}
</script>
