export default {
  mostSoldCategoriesTitle: 'Most sold categories',
  mostSoldCategoriesSubtitle: 'Identify the most popular categories in your store.',
  mostSoldCategoriesErrorMessage: 'There was an error loading the top selling information',
  mostSoldCategoriesHelpTitle: 'Most sold categories',
  mostSoldCategoriesHelpText:
  `
  <p style="text-align: justify;">
    This indicator displays the top five categories of sold products,
    offering a quick and straightforward view of the most profitable areas of your business.
  </p>
  <p style="text-align: justify;">
    Categories are ranked based on sales volume, allowing for easy identification of consumption trends and areas of opportunity.
    Use this information to drive marketing strategies, optimize inventory, and boost your sales.
  </p>  
  `
}
