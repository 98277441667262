
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: '',},
    message: { type: String, default: '',},
  },
  computed: {
    dialog: {
      get() : boolean {
        return this.value;
      },
      set(value : boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    confirmAction() {
      this.$emit('confirm');
    },
  },
});
