
export default{
  missingProducts:{
    title: 'Produtos não Encontrados',
    typeOfAlert: {
      title: 'Tipos de alerta',
      insertInPlanogram: 'Inserir no Planograma',
      insertProduct: 'Cadastrar Produto',
      productIdentified: 'Produto Identificado',
    },
    error:{
      excel: 'Erro ao exportar excel',
    },
    insertInPlanogram: {
      title: 'Deseja inserir o produto no planograma ativo?',
      message: 'Ao clicar em \'sim\' seu produto será adicionado diretamente no planograma ativo do seu PDV.',
    },
    excelFileName: 'Produtos_nao_encontrados.xlsx',
    help:{
      description:`
      <p>
        Na pagina de produtos não encontrados, você pode visualizar os código de barras que, após a leitura, não foram encontrados, seja por não ter sido cadastrado no sistema ou por não estar no planograma atual do respctivo ponto de venda .
        
        Na coluna Tipos de Alerta, você pode visualiar os seguintes status:
        <ul>
          <li><strong>Cadastrar Produto:</strong> O sistema não tem esse código de barras registrado, sendo necessário cadastrar o produto antes de adicioná-lo ao planograma.</li>
          <li><strong>Inserir no Planograma:</strong> O produto está registrado no sistema, mas não faz parte do planograma atual do ponto de venda.</li>
          <li><strong>Produto Identificado:</strong> O produto que foi lido e agora está cadastrado no respectivo planograma.</li>
        </ul>
      </p>`,
    }
  },
}