export default {
    residentialHouse: 'Casa Residencial',
    residentialApartment: 'Apartamento Residencial',
    businessOffice: 'Oficina Comercial',
    businessIndustry: 'Industria Comercial',
    hospitalLocalType: 'Hospital',
    university: 'Universidad',
    othersLocalType: 'Otros',
    vendingSnacks: 'Máquina Expendedora de Snacks',
    vendingCafe: 'Máquina Expendedora de Café',
    vendingEntertainment: 'Máquina Expendedora de Entretenimiento',
    microMarketPosType: 'MicroMarket',
};
