import { Line } from 'vue-chartjs'
import Vue from 'vue'

export default Vue.extend({
  extends: Line,
  props: {
    labels: { type: Array, required: true },
    datasets: { type: Array, required: true },
    title: { type: String, default: null },
    yLabelString: { type: String, default: null },
    xLabelString: { type: String, default: null }
  },
  data(){
    return {
      tooltipTotal: 0
    }
  },
  mounted () {
    const $currencyFormatter = this.$currencyFormatter
    this.renderChart({
        labels: this.labels,
        datasets: this.datasets
      },{
        title: {
          display: this.title !== null,
          text: this.title,
          fontSize: 16,
          padding: 30,
          fontColor: '#333'
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: this.xLabelString !== null,
              labelString: this.xLabelString
            },
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: this.yLabelString !== null,
              labelString: this.yLabelString
            },
            ticks: {
              callback: function(value) {
                return $currencyFormatter.format(value);
              }
            }
          }]
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data){
                var label = data.datasets[tooltipItem.datasetIndex].label;
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                this.tooltipTotal = 0;
                data.datasets.forEach((dataset) => {
                  this.tooltipTotal += dataset.data[tooltipItem.index];
                });
                this.total += value;
                return label + ": " + $currencyFormatter.format(value); 
              },
              footer: function() {
                return "TOTAL: " + $currencyFormatter.format(this.tooltipTotal);
              }
            }
          }
      });
  }
});
