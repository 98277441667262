
import Vue from 'vue';
import {MostSoldProduct} from "@/types/SalesDashboard";
import agent from "@/api/agent";
//import moment from "moment"
import ProductMostSoldHelp from '../helpers-new/ProductMostSoldHelp.vue';

export default Vue.extend({
  props: {
    mustCallEndpoints: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },
  components: {
    ProductMostSoldHelp
  },
  data() {
    return {
      productList: [] as MostSoldProduct[],
      abcCurve: {
        numberOfAProducts: 0,
        numberOfCProducts: 0,
        totalNoOfProducts: 0
      },
      headers: [
        { text: this.$t('productsSold'), align: 'left', sortable: false, value: 'description' },
        { text: this.$t('productsSoldAmount'), align: 'left', sortable: false, value: 'quantity' },
        { text: this.$t('valueSoldProducts'), align: 'left', sortable: false, value: 'total' },
        //{ text: this.$t('valueRupturesProducts'), align: 'left', sortable: false, value: 'ruptures' },
      ],
      loading: {
        table: false,
        ruptures: false,
        abcCurve: false
      },
      ruptures: [] as any[]
    };
  },
  mounted() {
    this.getProductsMostSales();
    this.getAbcCurve();
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.getProductsMostSales();
          this.getAbcCurve();
        }
      },
    }
  },
  methods: {
    async getProductsMostSales() {
      try {
        this.loading.table = true
        const params = { minDate: this.form.minDate, maxDate: this.form.maxDate,  pointOfSaleId: [this.form.pointOfSaleId], timezoneOffset: this.form.timezoneOffset }
        const response = await agent.SalesDashboard.getMostProductsSold(params)
          
        this.productList = response;
      }
      finally {
        this.loading.table = false
        //this.getRuptures()
      }
        
    },
    getProductEnabledClass(productDeleted : boolean){
      return productDeleted ? 'grey--text' : ''
    },
    async getRuptures() {
      const productsIds = this.productList.map(i => i.productId)
      const form = {...this.form, inventoryType: "pointOfSale", productsIds: productsIds}

      this.loading.ruptures = true
      try {
        const response = await agent.Ruptures.getRupturesOfCollectionOfItems(form)
        this.ruptures = response
        
        this.productList.map(i => {
          const itemRuptureValue = this.ruptures.find(rupture => rupture.itemProductId == i.productId)?.totalProfitLoss as number | null
          this.$set(i, 'ruptures', itemRuptureValue)
        })
      }
      catch(error) {
        (this.$refs.feedback as any).handleError(this.$t('rupturesGetErrorMessage'), error)
      }
      finally {
        this.loading.ruptures = false
      }
    },
    async getAbcCurve() {
      this.loading.abcCurve = true
      try {
        const params = { minDate: this.form.minDate, maxDate: this.form.maxDate,  pointOfSaleIdList: [this.form.pointOfSaleId], timezoneOffset: this.form.timezoneOffset }
        const response = await agent.SalesDashboard.getAbcCurve(params)

        this.abcCurve.numberOfAProducts = response.numberOfAProducts
        this.abcCurve.numberOfCProducts = response.numberOfCProducts
        this.abcCurve.totalNoOfProducts = response.totalNoOfProducts
      }
      catch(error) {
        (this.$refs.feedback as any).handleError(this.$t('abcCurveGetErrorMessage'), error)
      }
      finally {
        this.loading.abcCurve = false
      }
    }
  },
});
