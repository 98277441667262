export default {
  npsDialogQuestionOne: "¿Qué tan probable es que recomiende AMLabs a otra persona?",
  npsCategoriesQuestion: "¿Qué le pareció su experiencia?",
  npsLeaveYourFeedback: "Deje sus comentarios. Comparta con nosotros cómo podemos mejorar:",
  npsEaseOfUse: "Sistema fácil de usar",
  npsGoodPerformance: "Gran rendimiento del sistema",
  npsUsefulness: "Las funcionalidades son adecuadas",
  npsEffectiveSupport: "Equipo de soporte eficiente",
  npsSatisfactoryUpdate: "Las actualizaciones del sistema son satisfactorias",
  npsOthers: "Otros",
  npsHardOfUse: "Sistema difícil de utilizar",
  npsUselessness: "Las funcionalidades son inadecuadas",
  npsPoorPerformance: "Sistema lento",
  npsIneffectiveSupport: "Equipo de soporte ineficaz",
  npsLimitedUpdates: "Las actualizaciones del sistema son limitadas",
  send: "Enviar"
}
