export default {
    lastSupplyTitle: 'Últimas operações',  
    lastSupplySubTitle:'Confira as operações mais recentes',
    lastSupplyLabel: 'Tipo de operação',
    lastSupplyColumnPos: 'N° PDV',
    lastSupplyColumnCustomerLocation: 'Cliente/Local',
    lastSupplyColumnOperationType: 'Tipo de operação',
    lastSupplyColumnDate: 'Data',
    lastSupplyColumnLosses: 'Perdas',
    lastSupplyTypeOperationsSupply: 'Abastecimento',
    lastSupplyTypeOperationsInventory: 'Inventário',
    lastSupplyTypeOperationsPicking: 'Separação',
    lastSupplyTypeOperationsCombined: 'Combinado',
    lastSupplyTypeOperationsFull: 'Completo',
    lastSupplyColumnUser: 'Usuário',
    lastSupplyColumnDuration: 'Duração',
}