
  import Vue from 'vue';
  import { PointOfSale } from "@/types/PointOfSale";
  import { TableColumn } from '@/types/common/TableColumn';
  import {shouldRefreshData, toApiPagination} from "@/components/TableOptionsComparator";
  import PointOfSaleDialogHelp from './PointOfSaleDialogHelp.vue';
  import {ADMINISTRATOR_ROLE} from "@/constants/Roles";
  import agent from '@/api/agent';
  


  export default Vue.extend({
    components: {
      PointOfSaleDialogHelp
    },
    props: {
      value: {type: Boolean, required: true},
      finishActionName: {type: String, required: true}
    },
    data() {
      return {
        pointOfSale: [] as PointOfSale[] | undefined,
        pointOfSaleToAdd: [] as PointOfSale[],
        headers: [
          {text: this.$t('tables.headers.id'), align: 'left', value: 'id', width: '100px'},
          {text: this.$t('tables.headers.customer'), align: 'left', value: 'localCustomerName', width: '130px'},
          {text: this.$t('tables.headers.location'), align: 'left', value: 'localName', width: '130px'},
          {text: this.$t('tables.headers.specificLocation'), align: 'left', value: 'specificLocation', width: '150px'},
          {text: this.$t('promotionalLogos.block'), align: 'left', value: 'isLogoBlocked', width: '90px', sortable: true},
        ] as TableColumn<PointOfSale>[],
        loading: {
          pointOfSale: true
        },
        search: {
          valid: true,
          text: '' || null,
        },
        totalItems: 0,
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['id'],
          sortDesc: [false],
          mustSort: true
        },
        selectAllBlockedLogo: false,
      };
    },
    computed: {
      dialog: {
        get(): boolean {
          return this.value;
        },
        set(value: boolean) {
          this.$emit('input', value);
        }
      },
      filteredHeaders() : TableColumn<PointOfSale>[] {
        if ((this as any).$auth.user().tenantId != '268'){
          return this.headers.filter((header:{ value: string }) => header.value !== 'isLogoBlocked');
        }

        return this.headers;
      },
      userIsAdministrator() {
        return (this as any).$auth.check(ADMINISTRATOR_ROLE);
      },
    },
    watch: {
      dialog(value: boolean) {
        if (value) {
          this.pointOfSaleToAdd = [];
          this.getPointOfSales();
        }
      },
      options: {
        handler(newOptions, oldOptions) {
          if (shouldRefreshData(newOptions, oldOptions)) {
            this.getPointOfSales();
          }
        },
        deep: true,
      },
      pointOfSaleToAdd(value) {
        this.selectAllBlockedLogo = this.pointOfSaleToAdd.length > 0 && this.pointOfSaleToAdd.every((pos) => pos.isLogoBlocked);
        this.$emit("pointOfSaleToAddChange", value)
      }
    },
    methods: {
      getPointOfSales() {
        this.loading.pointOfSale = true;
        let params = toApiPagination(this.options, this.search.text);
        agent.PointsOfSale.getPaginated(params)
            .then((response) => {
              this.loading.pointOfSale = false;
              this.pointOfSale = response.items;
              this.totalItems = response.totalItems;
              this.fixValueSelectedItems();
            });
      },
      changeSelectAllBlockedLogo(){
        if(this.selectAllBlockedLogo){
          this.pointOfSaleToAdd.forEach((pos) => {
            pos.isLogoBlocked = true;
          });
        } else {
          this.pointOfSaleToAdd.forEach((pos) => {
            pos.isLogoBlocked = false;
          });
        }

        this.fixValueSelectedItems();
      },
      closeDialog() {
        this.dialog = false;
        this.pointOfSaleToAdd = [];
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['id'],
          sortDesc: [false],
          mustSort: true
        };
        this.selectAllBlockedLogo= false;
      },
      addPointOfSales() {
        this.dialog = false;
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['id'],
          sortDesc: [false],
          mustSort: true
        };
        this.selectAllBlockedLogo= false;
        
        this.$emit("pointOfSalesToApply", this.pointOfSaleToAdd);
      },
      changeSwitchItem(item: PointOfSale){
        const index = this.pointOfSaleToAdd.findIndex(pos => pos.id === item.id);
    
        index !== -1 
          ? this.pointOfSaleToAdd.splice(index, 1, item)
          : this.pointOfSaleToAdd.push(item);
      },
      fixValueSelectedItems(){
        this.pointOfSaleToAdd.forEach((updatedItem) => {
          const originalItem = this.pointOfSale!.find(item => item.id === updatedItem.id);

          if (originalItem) {
            originalItem.isLogoBlocked = updatedItem.isLogoBlocked;
          }
        });
      },
    }
  });
  