
import Vue from "vue";
import { VerifyAuthenticatorRequest, SupportedLoginExtensions } from '@/types/AutenticationTypes';

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    userId: { type: String },
    tempToken: { type: String },
    rememberMe: { type: Boolean, default: false },
    supportLoginExtensions: { type: SupportedLoginExtensions }
  },
  data() {
    return {
      loading: false,
      code: '',
      valid: true,
      error: null as string | null
    }
  },
  methods: {
    setRememberCookie() { // Based on @websanova/vue-auth/src/lib/cookie.js
      let expiration: string;
      if (this.rememberMe) {
        const date = new Date();
        date.setTime(date.getTime() + 12096e5);
        expiration = date.toUTCString();
      }
      else expiration = '0';
      document.cookie =
        `rememberMe=${this.rememberMe}; Expires=${expiration}; Path=/; Domain=${window.location.hostname};`;
    },
    performValidation() {
      if (!(this.$refs as any).totpForm.validate()) return
      const request = new VerifyAuthenticatorRequest(
        this.userId, this.code, this.tempToken, this.supportLoginExtensions);
      this.loading = true;
      this.axios.post("account/verifyAuthenticator", request)
        .then(() => {
          this.setRememberCookie();
          (this as any).$auth.fetch({enabled: true});
        })
        .catch(() => {
          this.error = String(this.$t('twoFactorFailed'));
        })
        .then(() => this.loading = false);
    },
    goBack() {
      this.code = '';
      this.$emit('input', false);
    }
  }
});
