
import Vue from 'vue';
import i18n from '@/locales/i18n';

export default Vue.extend({
  props: {
    value: {} as any,
    multiple: { type: Boolean, default: false},
  },
  data() {
    return {
      operationTypes: [
        {text:  i18n.t('lastSupplyTypeOperationsSupply'), value: "supply"},
        {text:  i18n.t('lastSupplyTypeOperationsInventory'), value: "inventory"},
        {text:  i18n.t('lastSupplyTypeOperationsPicking'), value: "picking"},
        {text:  i18n.t('lastSupplyTypeOperationsCombined'), value: "combined"},        
        {text:  i18n.t('lastSupplyTypeOperationsFull'), value: "Full"},        
      ],
    };
  },
  computed: {
    selectedTypeOperation: {
      get(): any {
        return this.value;
      },
      set(value: any) {
        this.$emit("input", value);
      }
    },
  },
})
