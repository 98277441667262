
    import Vue from 'vue';
    import { shouldRefreshData } from '@/components/TableOptionsComparator';
    import { toApiPagination } from '@/components/TableOptionsComparator';
    import agent from '@/api/agent';
    import i18n from '@/locales/i18n';
    import { formatDateTimeMinutes } from '@/utility/TextFormatting';
    import TaxGroupProductCreator from '@/views/taxings/components/TaxGroupProductCreator.vue';
    type TaxGroupProperties = {
      propertyName: string,
      text: string,
      value?: string,
    };
    type ProductTaxGroup = {
        id: number;
        code?: string;
        description: string;
        barCode?: string;
        categoryName?: string;
        cost?: number;
        price: number;
        dateUpdated: Date;
        isPrincipal: boolean;
        canHaveCurrentTaxGroupAsPrincipal: boolean;
    };
    
    export default Vue.extend({
        mounted() {
          this.mapTaxGroupData();
          this.productsOfTaxGroup();
        },
        data() {
            return {
                id: parseInt(this.$route.params.id),
                taxGroupName: {
                    propertyName: 'name', 
                    text: this.$t('headerName')
                } as TaxGroupProperties,
                taxGroupData: [
                    {propertyName: 'taxation', text: this.$t('headerTaxation')},
                    {propertyName: 'state', text: this.$t('headerState')},
                    {propertyName: 'cstIcms', text: this.$t('headerCstIcms')},
                    {propertyName: 'cstPis', text: this.$t('headerCstPis')},
                    {propertyName: 'cstCofins', text: this.$t('headerCstCofins')},
                    {propertyName: 'cstIpi', text: this.$t('headerCstIpi')},
                ] as TaxGroupProperties[],
                productRegistrationDisabled: false,
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ['code'],
                    sortDesc: [false],
                    mustSort: true
                },
                products: [] as ProductTaxGroup[],
                totalItems: 0,
                headers: [
                  { text: this.$t('productCode'), align: 'left', sortable: true, value: 'code' },
                  { text: this.$t('descriptionProduct'), align: 'left', sortable: true, value: 'description' },
                  { text: this.$t('barCodeProduct'), align: 'left', sortable: true, value: 'barCode' },
                  { text: this.$t('categoryProduct'), align: 'left', sortable: true, value: 'categoryName' },
                  { text: this.$t('dateUpdatedProduct'), align: 'left', sortable: true, value: 'dateUpdated' },
                  { text: this.$t('mainTaxGroup'), align: 'left', sortable: false, value: 'isPrincipal' },
                  { text: this.$t('deleteProductFromTaxGroup'), align: 'left', sortable: false, value: 'delete' }
                ],
                loading: {
                  products: true,
                },
                search: {
                    valid: true,
                    text: null,
                },
                createMode: {
                  enable: false,
                },
            };
        },
        watch: {
          options: {
            handler(newOptions, oldOptions) {
              if (shouldRefreshData(newOptions, oldOptions)) {
                  this.productsOfTaxGroup();
              }
            },
            deep: true
          },
        },
        methods: {
            mapTaxGroupData() {
                const properties = [...this.taxGroupData, this.taxGroupName]
                const propertiesNames = [
                    ...properties.map(g => g.propertyName),
                ];
                const keys = Object.keys(this.$route.query);
                for (let index = 0; index < keys.length; index++) {
                    const param = keys[index];
                    const taxData = properties.find(h => h.propertyName === param);
                    if(propertiesNames.indexOf(param) < 0 || !taxData) {
                        continue;
                    }
                    const paramValue = this.$route.query[param] as string;
                    if(param === 'name') {
                        this.taxGroupName.value = paramValue;
                    } else {
                        taxData.value = paramValue;
                    }
                }
            },
            productsOfTaxGroup() {
                this.loading.products = true;
                const params = toApiPagination(this.options, this.search.text);
                agent.Taxings.listTaxGroupProducts(this.id, params).then((response) => {
                    this.products = response.items.map(tg => ({...tg.product, isPrincipal: tg.isPrincipal}));
                    this.totalItems = response.totalItems;
                })
                .catch((errorResponse) => {
                    this.handleError(`Não foi possível carregar os produtos, tente novamente`, errorResponse);
                })
                .finally(() => {
                    this.loading.products = false;
                });                
            },
            redirectToTaxScenario() {
                this.$router.push({name: i18n.t('routes.nameTaxScenarios').toString(),});
            },        
            setCreateMode: function () {
                this.createMode.enable = !this.createMode.enable;
            },
            onSuccessAddingProduct(message: string) {
                this.handleSuccess(message);
                this.productsOfTaxGroup();
            },
            handleSuccess(message: string) {
                (this.$refs.feedback as any).handleSuccess(message);
            },
            handleError(message: string, error?: any) {
                (this.$refs.feedback as any).handleError(message, error);
            },
            setTaxGroupAsPrincipal(item: ProductTaxGroup) {
              this.loading.products = true;
              const principalTaxGroup = item.isPrincipal;
              const params = {
                  productId: item.id,
                  isPrincipal: principalTaxGroup,
              };
              agent.Taxings.mapProductTaxGroup(this.id, params)
                  .then(() => {
                        this.loading.products = false;
                   })
                  .catch((errorResponse) => {
                    this.handleError(`Não foi possível atualizar o produto, tente novamente.`, errorResponse);
                    item.isPrincipal = !principalTaxGroup;
                  })
                  .finally(() => {
                    this.loading.products = false;
                  });
            },
            removeProductFromTaxGroup(product: ProductTaxGroup) {
              agent.Taxings.removeProductFromTaxGroup(this.id, {productId: product.id})
                .then(() => {
                  const entryIndex = this.products.indexOf(product);
                  this.products!.splice(entryIndex, 1);
                  if (this.products!.length === 0) {
                    this.options.page = 1;
                    this.productsOfTaxGroup();
                  } else {
                    this.totalItems--;
                  }
                this.handleSuccess(`Sucesso ao remover produto ${product.description} do cenário fiscal.`);
                })
                .catch((error: any) => {
                this.handleError(`Não foi possível remover o produto ${product.description} do cenário fiscal.`, error);
              });
            },
            tooltipText(item: ProductTaxGroup) {
              if (!item.canHaveCurrentTaxGroupAsPrincipal) return "Produto definido como principal em outro cenário fiscal";
              return "";
            },
            formatDateTimeMinutes,
        },
        components: {
            TaxGroupProductCreator
        }
    });
