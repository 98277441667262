export default {
    purchasesFiltersTitle: 'Filtros y acciones',
    purchasesFiltersStartDate: 'Fecha inicial',
    purchasesFiltersEndDate: 'Fecha final',
    purchasesFiltersBtnClear: 'Limpiar',
    purchasesFiltersAddNote: 'AGREGAR NOTA',
    purchasesFiltersXml: 'XML',
    purchasesFiltersManual: 'Manual',
    purchasesFiltersRefresh: 'Actualizar',
    purchasesFiltersRulesDate: 'Sin Fecha',
    purchasesFiltersRulesRequired: 'Este campo es obligatorio.',
    labelFiltersStock: 'Existencias',  
};
  