export default {
    DialogTokenHelpTitle: 'Ayuda del token',
    DialogTokenBody: `Para obtener el token de la API PagSeguro, sigue los pasos a continuación:
    <ul>
        <li>Accede a tu cuenta de PagSeguro (la misma cuenta utilizada para tus máquinas de tarjeta);</li>
        <li>En el menú lateral, selecciona <strong>"Ventas"</strong> y luego baja hasta encontrar la opción <strong>"Integraciones"</strong>, y haz clic en esta opción;</li>
        <li>En la página de "Integraciones", ve a la parte de "Uso de API's" y haz clic en el botón <strong>"Generar Token"</strong>;</li>
        <li>¡Copia el token generado en la pantalla o que fue enviado por correo electrónico!</li>
    </ul>`,
    DialogTokenMessage: 'Si tienes dificultades, haz clic en el enlace a continuación para ver un video que explica detalladamente cómo obtener esta información.',

    DialogActivationTitle: 'Orientaciones para código de activación:',
    DialogActivationBody: `<p>Para obtener tu código de activación de PagSeguro, sigue los pasos a continuación:</p>
    <ul>
        <li>Accede a tu cuenta de PagSeguro (la misma cuenta utilizada para tus máquinas de tarjeta);</li>
        <li>En el menú lateral izquierdo, selecciona <strong>"Máquinas de tarjeta"</strong>;</li>
        <li>Después de abrir <strong>"Máquinas de tarjeta"</strong>, selecciona <strong>"Gestionar Máquinas de tarjeta"</strong>;</li>
        <li>Después de abrir <strong>"Gestionar Máquinas de tarjeta"</strong>, tendrás en esta pantalla tu Código de Activación, ¡son 6 números!</li>
    </ul>`,
    DialogActivationTitleImg: 'Código de Activación',
};
