export default {
    searchTransactionsReports: 'Buscar',
    totalTransactionsReports: 'Total',
    selectGroupingsTransactionsReports: 'Seleccione uno o más agrupamientos y haga clic en Actualizar.',
    productCodeTransactionsReports: 'Cód. Producto',
    productDescriptionTransactionsReports: 'Descripción Producto',
    categoryTransactionsReports: 'Categoría',
    pointOfSaleIdTransactionsReports: 'ID PdV',
    pointOfSaleCodeTransactionsReports: 'Cód. PdV',
    customerTransactionsReports: 'Cliente',
    locationTransactionsReports: 'Ubicación',
    specificLocationTransactionsReports: 'Ubicación específica',
    stateTransactionsReports: 'Estado',
    paymentMethodTransactionsReports: 'Método de Pago',
    cardBrandTransactionsReports: 'Marca de Tarjeta',
    dateTransactionsReports: 'Fecha',
    monthTransactionsReports: 'Mes',
    dayOfWeekTransactionsReports: 'Día de la Semana',
    quantityTransactionsReports: 'Cant.',
    totalValueTransactionsReports: 'Valor Total',
    totalCostTransactionsReports: 'Costo Total',
    dynamicTransactionSummaryTransactionsReports: 'Resumen dinámico de transacciones',
    othersTransactionsReports: 'OTROS',
    TransactionsReports: 'Resumen dinámico de transacciones',
    hour: 'Hora',
};
