export default {
    clickForMoreInfoIndividual: 'Haz clic para obtener más información',
    searchProductIndividual: 'Buscar producto',
    noPlanogramsIndividual: 'Sin planogramas registrados',
    planogramIndividual: 'Planograma',
    showAllIndividual: 'Mostrar todos',
    filterCriticalIndividual: 'Filtrar críticos',
    quickEditIndividual: 'Edición rápida',
    newItemIndividual: 'Nuevo elemento',
    finalizeDraftIndividual: 'Finalizar borrador',
    activateNowIndividual: 'Activar ahora',
    removePlanogramIndividual: 'Eliminar planograma',
    confirmRemovePlanogramIndividual: '¿Estás seguro de que quieres eliminar el planograma de borrador?',
    cancelIndividual: 'Cancelar',
    removeIndividual: 'Eliminar',
    finalizePlanogramIndividual: 'Finalizar planograma',
    finalizePlanogramWarningIndividual: 'Después de finalizar el planograma, entrará en modo pendiente y no se podrá editar. El planograma pendiente reemplazará al planograma activo al hacer clic en el botón "Abastecer" en el menú de administración del sistema de pago o al hacer clic en "Activar ahora" en la página del planograma.',
    activatePlanogramIndividual: 'Activar de todos modos',
    activatePlanogramWarningIndividual: 'La activación convertirá el planograma pendiente en activo. Atención: para mantener correcto el conteo de inventario, recomendamos, en lugar de activar el planograma aquí, usar la opción "Abastecer" del menú de administración del sistema de pago. De esta manera, además de informar el abastecimiento, el planograma pendiente se activará y tendrá el inventario correspondiente al abastecimiento.',
    viewMovementsHistoryIndividual: 'Ver historial de movimientos',
    goToProductRegistrationIndividual: 'Ir al registro del producto',
    noItemsIndividual: 'Sin elementos registrados',
    updateQuantityIndividual: 'Actualizar cantidad',
    editItemIndividual: 'Editar elemento',
    deleteItemIndividual: 'Eliminar elemento',
    changeDefaultProductIndividual: 'Cambiar producto predeterminado',
    criticalStockIndividual: 'Inventario en nivel crítico',
    removeItemConfirmationIndividual: '¿Estás seguro de que deseas eliminar el elemento?',
    planogramRemovedIndividual: 'Planograma eliminado',
    itemRemovedIndividual: 'Elemento eliminado',
    planogramCreatedIndividual: 'Planograma creado',
    planogramFinalizedIndividual: 'Planograma finalizado',
    planogramActivatedIndividual: 'Planograma activado',
    errorRemovingPlanogramIndividual: 'Error al eliminar el planograma',
    errorRemovingItemIndividual: 'Error al eliminar el elemento',
    errorFinalizingPlanogramIndividual: 'Error al finalizar el planograma',
    errorActivatingPlanogramIndividual: 'Error al activar el planograma',
    saveSuccessfulIndividual: 'Elemento guardado exitosamente',
    draftModeIndividual: 'Ya existe un planograma en modo borrador o pendiente',
    cardVisionIndividual: 'Visión de tarjeta',
    tabularViewIndividual: 'Vista tabular',
    iconExcel: 'Excel',
    btnFinish: 'Finalizar',
    activatePlanogramCard: 'Activar planograma',
    paginationDisplay: 'Mostrando: ',
    paginationItemsPerPage: 'Elementos por página:',
    paginationOf: 'de',
    criticalStock: 'Inventario crítico',
    requiredQuantity: 'La cantidad deseada es obligatoria',
    desiredQuantityNotFilled: 'El campo "Cantidad deseada" no está lleno',
    removeNo: 'No',
    headersImageIndividual: 'Imagen',
    headersSelectionIndividual: 'Selección',
    headersProductCodeIndividual: 'Código de producto',
    headersProductNameIndividual: 'Nombre del producto',
    headersProductCategoryIndividual: 'Categoría del producto',
    headersPriceIndividual: 'Precio',
    headersCurrentQuantityIndividual: 'Cantidad actual / capacidad',
    headersDesiredParLevelIndividual: 'Cantidad deseada (nivel de reorden)',
    headersActionIndividual: 'Acciones',
    activatePlanogramFeedback: 'El planograma se está activando. Este proceso puede tardar un tiempo.',
    getPlanogramDescriptionStatus: 'Este planograma aún no está activo',
    ProductsUnused: 'Ver productos sin movimientos',
};
