export default {
    titleSaveReportMenu: 'Salvar filtros do relatório',
    saveFiltersSaveReportMenu: 'Salvar Filtros',
    cancelSaveReportMenu: 'Cancelar',
    saveSaveReportMenu: 'Salvar',
    deleteConfirmationSaveReportMenu: 'Deletar filtros do relatório',
    unableToRemoveSaveReportMenu: 'Não é possível remover item',
    productSaveReportMenu: 'Produto',
    categorySaveReportMenu: 'Categoria',
    stockSaveReportMenu: 'Estoque',
    operationSaveReportMenu: 'Operação',
    daySaveReportMenu: 'Dia',
    monthSaveReportMenu: 'Mês',
    dayOfTheWeekSaveReportMenu: 'Dia da Semana',
    customersSaveReportMenu: 'Clientes',
    pointsOfSaleSaveReportMenu: 'Pontos de Venda',
    statesSaveReportMenu: 'Estados',
    paymentMethodsSaveReportMenu: 'Métodos de Pagamento',
    cardFlagsSaveReportMenu: 'Bandeiras de Cartões',
    savedFiltersSaveReportMenu: 'Filtros Salvos',
    onlyUserFiltersSaveReportMenu: 'Apenas filtros de usuário podem ser excluídos',
    filtersSaveReportMenu: 'Filtros: ',
    groupingsSaveReportMenu: 'Agrupamentos: ',
    savedSuccessfullySaveReportMenu: ' salvado com sucesso',
    errorWhenSavingFiltersSaveReportMenu: 'Houve um erro ao salvar os filtros',
    reportNameSaveReportMenu: 'Nome do relatório',
    filterSaveReportMenu: 'Filtro ',
};
  