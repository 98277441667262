<template>
  <div>
    <portal to="toolbar">
      <app-pos-selector style="width: 100%" class="mr-2"></app-pos-selector>
    </portal>
    <planogram 
      v-if="posId != null && !isNaN(posId)" 
      :posId="posId" 
      :planogram-id-query="planogramId"
      :key="posId"
      ></planogram>
    <planogram-main v-else></planogram-main>
  </div>
</template>

<script>
import Planogram from '@/planogram/Planogram';
import PlanogramMain from '@/planogram/PlanogramMain';
import AppBarPointOfSaleSelector from '@/components/AppBarPointOfSaleSelector';

export default {
  watch: {
    '$route'(to, from) {
      if (to != from) {
        this.posId = parseInt(to.query.pdv); 
        this.planogramId = parseInt(to.query.pog);
      }
    },
  },
  data() {
    return {
      posId: parseInt(this.$route.query.pdv),
      planogramId: parseInt(this.$route.query.pog)
    }
  },
  components: {
    Planogram,
    PlanogramMain,
    'app-pos-selector': AppBarPointOfSaleSelector
  }
}
</script>