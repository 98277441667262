export default {
    transactionsTitleTransactionsHelp: 'Transacciones',
    transactionsDescriptionTransactionsHelp: 'Aquí se muestran las ventas realizadas en las tiendas o máquinas expendedoras.',
    resultTransactionsHelp: `<strong>Resultado</strong>: indica si el producto fue entregado.
    Útil en el caso de máquinas expendedoras, donde puede ocurrir un fallo en la liberación del producto.`,
    customerTransactionsHelp: '<strong>Cliente</strong>: nombre del cliente según lo registrado en el punto de venta.',
    locationTransactionsHelp: '<strong>Ubicación</strong>: ubicación y ubicación específica según lo registrado en el punto de venta.',
    posTransactionsHelp: '<strong>Punto de venta</strong>: ID del punto de venta, según la página de Registro de puntos de venta.',
    amountTransactionsHelp: '<strong>Valor ($)</strong>: valor pagado por el comprador.',
    marginAmountTransactionsHelp: '<strong>Margen ($)</strong>: Diferencia entre el precio pagado por el comprador y el costo de la mercancía vendida (CMV).',
    marginPercentageTransactionsHelp: '<strong>Margen (%)</strong>: razón, en porcentaje, entre el margen anterior y el valor pagado por el comprador.',
    methodTransactionsHelp: '<strong>Método</strong>: forma de pago (débito, crédito, vale, etc).',
    cardTransactionsHelp: '<strong>Tarjeta</strong>: mostramos los últimos 4 dígitos de la tarjeta cuando tenemos esa información.',
    dateTimeTransactionsHelp: '<strong>Fecha/hora</strong>: fecha de la transacción en el horario de este dispositivo.',
    cmvInfoTransactionsHelp: `Para que la información de CMV y margen estén disponibles, es necesario tener el sistema de gestión y 
    inventario central habilitado en el sistema TouchPay.`,
    taxesHelp: '<strong>Impuestos ($)</strong>: representa los impuestos cobrados en la transacción.'
}
