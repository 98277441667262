import { Line } from 'vue-chartjs';
import moment from 'moment';

export default {
  extends: Line,
  props: {
    posId: { type: Number, required: true },
    dayOffset: { type: Number, required: true },
    salesHealth: { type: Object, default: null }
  },
  mounted() {
    this.axios.get(`api/dashboard/salesAnalysis/${this.posId}?interval=01:00:00`)
      .then((response) => {
        if (response.data == null || response.data.curve == null) {
          // Remove canvas if there is no chart to display
          let canvasContainer = this.$refs.canvas.parentNode;
          canvasContainer.parentNode.removeChild(canvasContainer);
          return;
        }
        const datasets = [{
          label: this.$t('averageLabelSalesChart'),
          borderColor: '#BBDEFB',
          backgroundColor: '#BBDEFB88',
          data: response.data.curve.points.map((p) => p.value) 
        }, {
          pointStyle: 'dash',
          borderColor: '#00000015',
          backgroundColor: '#FFFFFF00',
          borderDash: [5, 3],
          borderWidth: 1,
          data: response.data.curve.points.map((p) => p.value + p.stdDev)
        }, {
          pointStyle: 'dash',
          borderColor: '#00000015',
          backgroundColor: '#FFFFFF00',
          borderDash: [5, 3],
          borderWidth: 1,
          data: response.data.curve.points.map((p) => p.value - p.stdDev)
        }];
        if (this.salesHealth != null) {
          const finishMoment =  moment(this.salesHealth.finishTime, 'HH:mm:ss').startOf('hour');
          const finishTime = finishMoment.format('HH:mm');
          const startTime = finishMoment.subtract(1, 'hours').format('HH:mm');
          datasets.push({
            label: this.$t('currentLabelSalesChart'),
            borderColor: '#FF9800',
            backgroundColor: '#FF980088',
            data: [{x: startTime, y: this.salesHealth.currentCount}, {x: finishTime, y: this.salesHealth.currentCount}]
          });
        }
        this.renderChart({
          labels: response.data.curve.points.map((p) => this.formatDate(p.time)),
          datasets: datasets
        },{
          title: {
            display: true,
            fontStyle: 'normal',
            fontColor: '#707070',
            fontSize: 14,
            text: `${this.$t('chartTitleSalesChart')} (${moment().locale('pt-br').format('dddd').toLowerCase()})`
          },
          legend: {
            display: true,
            onClick: null,
            labels: {
              filter: function(item) { // Hide standard deviation curves labels
                return item.text != null;
              }
            }
          },
          tooltips: {
            filter: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label != null;
            },
            callbacks: {
              title: function(tooltipItem, data) {
                if (tooltipItem.length == 0) return;
                const datasetIndex = tooltipItem[0].datasetIndex;
                if (datasetIndex == 0) {
                  return tooltipItem[0].xLabel;
                } else {
                  return data.datasets[datasetIndex].data[tooltipItem[0].index].x;
                }
              },
              label: function(tooltipItem) {
                return tooltipItem.yLabel.toFixed(2);
              }
            }
          }
        });
      })
  },
  methods: {
    formatDate(date) {
      date = date.replace('1.', '') // Fix for TimeSpan 1.00:00:00
      return moment(date, 'HH:mm:ss').format('HH:mm')
    }
  }
}