  
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      translations: {
        help: {
          markUpCategory: {
            title: this.$t('priceTable.help.markUpCategory.title').toString(),
            description: this.$t('priceTable.help.markUpCategory.description').toString(),
            option: {
              category: {
                action: this.$t('priceTable.help.markUpCategory.option.category.action').toString(),
                description: this.$t('priceTable.help.markUpCategory.option.category.description').toString()
              },
              markUp: {
                action: this.$t('priceTable.help.markUpCategory.option.markUp.action').toString(),
                description: this.$t('priceTable.help.markUpCategory.option.markUp.description').toString()
              },
              condition: this.$t('priceTable.help.markUpCategory.option.condition').toString()
            }
          },
        }
      },
    }
  }
});
