export default {
    topProductsTitle: 'Productos más vendidos',
    loadingMessage: 'Cargando...',
    noResultsMessage: 'Sin resultados',
    topFiveProducts: 'Productos',
    topFiveState: 'Estado',
    topProductsGraphSubtitle: 'Vea los productos favoritos de los clientes',
    topProductsSales: 'Ventas',
    topProductsNoRecords: 'No hay registros',
};
