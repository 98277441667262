<template>
  <iframe 
    :width="width" 
    :height="height" 
    :src="`https://www.youtube.com/embed/${id}`" 
    frameborder="0" 
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
</template>

<script>
export default {
  props: {
    id: { type: String, required: true },
    width: { type: Number, default: 560 },
    height: { type: Number, default: 315 }
  }
}
</script>