export default {
  tenants: [
    {
      id: 1,
      claims: ['master', 'pagseguro']
    }
  ],
  hasClaim(user, claim) {
    let tenant = this.tenants.find((t) => t.id === user.tenantId);
    return user && tenant && tenant.claims.indexOf(claim) >= 0;
  }
}