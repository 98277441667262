export default {
    recentTransactionsRemoteCredit: 'Últimas transações',
    dispensedRemoteCredit: 'Dispensado',
    amountRemoteCredit: 'Valor',
    methodRemoteCredit: 'Método',
    cardRemoteCredit: 'Cartão',
    productRemoteCredit: 'Produto',
    dateTimeRemoteCredit: 'Data/hora',
    creditTransferRemoteCredit: 'Envio de créditos',
    quantityRemoteCredit: 'Quantidade',
    resetCreditsRemoteCredit: 'Zerar créditos',
    closeRemoteCredit: 'Fechar',
    sendRemoteCredit: 'Enviar',
    noResultsRemoteCredit: 'Sem resultados',
    creditsSentRemoteCredit: 'Créditos encaminhados',
    creditsReceivedRemoteCredit: 'Créditos recebidos',
    creditsUsedRemoteCredit: 'Créditos utilizados',
    errorSendingCredits: 'Erro ao enviar créditos',
    errorUsingRemoteCredit: 'Erro ao utilizar crédito remoto',
    paymentMethodCreditRemoteCredit: 'Crédito',
    paymentMethodDebitRemoteCredit: 'Débito',
    paymentMethodVoucherRemoteCredit: 'Voucher',
    remotePaymentMethod: 'Remoto',
    paymentMethodKindRemoteCredit: 'Espécie',
    runknownRemoteCredit: 'Desconhecido',
    failedToDismissRemoteCredit: 'Falha ao dispensar',
    reversedRemoteCredit: 'Estornada',
    reversalCancelledRemoteCredit: 'Estorno cancelado',
    errorReversingRemoteCredit: 'Erro ao estornar',
    productNotSelectedRemoteCredit: 'Produto não selecionado',
  };
  