<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title>
            <div class="title">Ativações</div>
            <v-spacer></v-spacer>
            
            <v-autocomplete
              :items="tenants"
              v-model="tenantId"
              :loading="loading.tenants"
              label="Tenant"
              item-text="text"
              item-value="id"
            ></v-autocomplete>

            <!-- Tenant info dialog -->
            <v-dialog persisten v-model="tenantDialog" width="960">
              <template #activator="{ on }">
                <v-btn :hidden="tenantId == null" color="info" v-on="on" class="mx-2">
                  <v-icon left>info</v-icon>Informações tenant
                </v-btn>
              </template>
              <v-card>
                <v-form ref="tenantForm" lazy-validation v-model = "tenantFormValid" @submit.prevent="updateTenantInfo">
                  <v-card-title>
                    <span class="title">{{editedTenant.name}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field v-model="editedTenant.name" label="Nome" :counter="30"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm4>
                          <v-select
                              label="Tipo de cliente"
                              :items="clientTypeMap"
                              v-model="editedTenant.clientCategory"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field v-model="editedTenant.cnpj" label="CNPJ" :counter="14"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field v-model="editedTenant.cpf" label="CPF" :counter="11"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="editedTenant.comments" label="Observações"></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.native="tenantDialog = false">Fechar</v-btn>
                    <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading.updateTenant">Salvar</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <!-- Entry add / update dialog -->
            <v-dialog persistent v-model="itemDialog" width="960">
              <template #activator="{ on }">
                <v-btn :hidden="tenantId == null" color="success" v-on="on">
                  <v-icon left>add</v-icon>Nova ativação
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="saveItem">
                  <v-card-title>
                    <span class="title">{{dialogTitle}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="ID sistema"
                            v-model="editedEntry.boxId"
                            :disabled="editedIndex !== -1"
                            :rules="[rules.integer, rules.requiredIf(editedEntry.boxId, editedIndex !== -1)]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            label="Status"
                            :items="activationStatusMap"
                            v-model="editedEntry.activationStatus"
                            :rules="[rules.required, rules.nonEmpty]"
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            label="Tipo de cobrança"
                            :items="billingCategoryMap"
                            v-model="editedEntry.billingCategory"
                            :rules="[rules.required, rules.nonEmpty]"
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            type="number"
                            min="0"
                            step="0.01" 
                            placeholder="0.00"
                            label="Preço do chip"
                            v-model="editedEntry.mobilePlanPrice"
                            :rules="[rules.required, rules.float]"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            type="number"
                            min="0"
                            step="0.01" 
                            placeholder="0.00"
                            label="Preço do comodato"
                            v-model="editedEntry.lendingPrice"
                            :rules="[rules.required, rules.float]"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            type="number"
                            min="0"
                            step="0.01" 
                            placeholder="0.00"
                            label="Preço da licença"
                            v-model="editedEntry.licensePrice"
                            :rules="[rules.required, rules.float]"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            label="Fornecedor da conexão"
                            :items="connectionProviderMap"
                            v-model="editedEntry.connectionProvider"
                            :rules="[rules.required, rules.nonEmpty]"
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            label="Operadora do chip"
                            :items="carrierMap"
                            v-model="editedEntry.carrier"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            label="Interface box"
                            :items="machineInterfaceMap"
                            v-model="editedEntry.machineInterfaceType"
                            :rules="[rules.required, rules.nonEmpty]"
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                          <v-menu class="pr-2" lazy :close-on-content-click="true" transition="scale-transition"
                              offset-y full-width :nudge-right="40" max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                              <v-text-field v-on="on" label="Data de envio"
                                :value="formatDate(editedEntry.sendDate)" readonly></v-text-field>
                            </template>
                            <v-date-picker v-model="editedEntry.sendDate" no-title scrollable locale="pt-br">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="editedEntry.sendDate = null">Limpar</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                          <v-menu class="pr-2" lazy :close-on-content-click="true" transition="scale-transition"
                              offset-y full-width :nudge-right="40" max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                              <v-text-field v-on="on" label="Data início cobrança"
                                :value="formatDate(editedEntry.billingStartDate)" readonly></v-text-field>
                            </template>
                            <v-date-picker v-model="editedEntry.billingStartDate" no-title scrollable locale="pt-br">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="editedEntry.billingStartDate = null">Limpar</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                          <v-menu class="pr-2" lazy :close-on-content-click="true" transition="scale-transition"
                              offset-y full-width :nudge-right="40" max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                              <v-text-field v-on="on" label="Data de ativação"
                                :value="formatDate(editedEntry.activationDate)" readonly></v-text-field>
                            </template>
                            <v-date-picker v-model="editedEntry.activationDate" no-title scrollable locale="pt-br">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="editedEntry.activationDate = null">Limpar</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm6 md3>
                          <v-menu class="pr-2" lazy :close-on-content-click="true" transition="scale-transition"
                              offset-y full-width :nudge-right="40" max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                              <v-text-field v-on="on" label="Data de desativação"
                                :value="formatDate(editedEntry.deactivationDate)" readonly></v-text-field>
                            </template>
                            <v-date-picker v-model="editedEntry.deactivationDate" no-title scrollable locale="pt-br">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="editedEntry.deactivationDate = null">Limpar</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field v-model="editedEntry.comments" label="Observações"></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.native="itemDialog = false">Fechar</v-btn>
                    <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading.newItem">Salvar</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

          </v-card-title>
          <v-divider></v-divider>
          <v-data-table 
            :loading="loading.contracts" 
            :headers="headers" 
            :items="contracts" 
            must-sort 
            hide-default-footer
            disable-pagination
            no-data-text="Sem itens cadastrados"
            sort-by="boxId"
          >
            <template #item="{ item }">
              <tr>
                <td v-for="col in columns" :key="col" @click="editItem(item)">{{ item.string(col) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      
      <v-snackbar :timeout="snackbar.timeout" :color="snackbar.color" v-model="snackbar.enabled">
        {{snackbar.message}}
        <v-btn dark text @click.native="snackbar.enabled = false">Ok</v-btn>
      </v-snackbar>

      <!-- Remove dialog -->
      <v-dialog v-model="removeDialog.enabled" max-width="400">
        <v-card>
          <v-card-text class="pt-4">Tem certeza que deseja remover o produto {{removeDialog.item.description}}?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">Não</v-btn>
            <v-btn color="green darken-1" :loading="loading.removeItem" text @click.native="deleteItem(removeDialog.item)">Remover</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      tenants: [],
      tenantId: null,
      tenant: {},
      contracts: [],
      itemDialog: false,
      tenantDialog: false,
      editedEntry: {},
      editedTenant: {},
      editedIndex: -1,
      valid: true,
      tenantFormValid: true,
      rules:{
        nonEmpty: (v) => /\S/.test(v) || 'Insira algum nome válido',
        required: (v) => !!v || 'Este campo é obrigatório.',
        integer: (v) => /^\d+$/.test(v) || 'Insira um número inteiro',
        float: (v) => !isNaN(v) || 'Insira um valor válido',
        validCharacters: (v) => /^[\w\-+ ]*$/.test(v) || 'Insira caracteres válidos',
        uniqueCode: (v) => this.editedIndex >= 0 || !this.editedEntry.code || !this.codeList.includes(v) || 'Essa código já existe',
        requiredIf: (v, condition) => !condition || !!v || 'Este campo é obrigatório.'
      },
      columns: [
        'boxId', 'activationStatus', 'billingCategory', 'mobilePlanPrice', 'lendingPrice', 'licensePrice', 'connectionProvider',
        'carrier', 'machineInterfaceType', 'sendDate', 'billingStartDate', 'activationDate', 'deactivationDate', 'comments'
      ],
      headers: [
        { text: 'ID sistema', align: 'left', sortable: true, value: 'boxId' },
        { text: 'Status', align: 'left', sortable: true, value: 'activationStatus' },
        { text: 'Tipo de cobrança', align: 'left', sortable: true, value: 'billingCategory' },
        { text: '$ Chip', align: 'left', sortable: true, value: 'mobilePlanPrice' },
        { text: '$ Comodato', align: 'left', sortable: true, value: 'lendingPrice' },
        { text: '$ Licença', align: 'left', sortable: true, value: 'licensePrice' },
        { text: 'Conexão', align: 'left', sortable: true, value: 'connectionProvider' },
        { text: 'Operadora', align: 'left', sortable: true, value: 'carrier' },
        { text: 'Box', align: 'left', sortable: true, value: 'machineInterfaceType' },
        { text: 'Envio', align: 'left', sortable: true, value: 'sendDate' },
        { text: 'Início cobrança', align: 'left', sortable: true, value: 'billingStartDate' },
        { text: 'Ativação', align: 'left', sortable: true, value: 'activationDate' },
        { text: 'Desativação', align: 'left', sortable: true, value: 'deactivationDate' },
        { text: 'Observações', align: 'left', sortable: true, value: 'comments' }
      ],
      loading: {
        contracts: false,
        tenants: true,
        newItem: false,
        removeItem: false,
        updateTenant: false
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      snackbar: {
        enabled: false,
        message: null,
        color: 'error',
        timeout: 3000
      },
      clientTypeMap: [
        { value: 'Star', text: 'Estrela' },
        { value: 'Primary', text: 'Primário' },
        { value: 'Secondary', text: 'Secundário' },
        { value: 'Fiat', text: 'Fiat' },
      ],
      activationStatusMap: [
        { value: 'Inactive', text: 'Inativo' },
        { value: 'Trial', text: 'Trial' },
        { value: 'Active', text: 'Ativo' },
        { value: 'Cancelled', text: 'Cancelado' }
      ],
      billingCategoryMap: [
        { value: 'Sale', text: 'Venda' },
        { value: 'Lending', text: 'Comodato' },
        { value: 'Demo', text: 'Demonstração' }
      ],
      connectionProviderMap: [
        { value: 'Ours', text: 'Mercúrio' },
        { value: 'Client', text: 'WiFi' }
      ],
      carrierMap: [
        { value: 'Vivo', text: 'Vivo' },
        { value: 'Tim', text: 'Tim' },
        { value: 'Claro', text: 'Claro' }
      ],
      machineInterfaceMap: [
        { value: 'Cashless', text: 'MDB' },
        { value: 'Pulse', text: 'Pulso' },
        { value: 'FakeChanger', text: 'Fake Changer' },
        { value: 'Serial', text: 'Serial' }
      ]
    }
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'Nova ativação' : 'Editar ativação';
    },
    codeList() {
      return this.contracts.map((p) => p.code);
    }
  },
  watch: {
    itemDialog(isOpen) {
      if(isOpen){
        this.valid = true;
        this.$refs.form.resetValidation();
      }
      else{
        this.editedIndex = -1;
        this.editedEntry = {};
        this.$refs.form.reset();
      }
    },
    tenantDialog(isOpen) {
      if (isOpen) {
        this.editedTenant = Object.assign({}, this.tenant);
        this.tenantFormValid = true;
        this.$refs.tenantForm.resetValidation();
      }
      else {
        this.editedTenant = {};
        this.$refs.tenantForm.reset();
      }
    },
    tenantId(id) {
      this.tenant = this.tenants.find((t) => t.id === id);
      this.contracts = [];
      this.loading.contracts = true;
      if (id != null) {
        this.axios.get(`api/Contracts/${id}`)
          .then((response) => {
            this.loading.contracts = false;
            this.contracts = response.data;
            this.contracts.forEach((c) => {
              if (c.id == 0) {
                Object.keys(c).forEach(k => {
                  if (Object.prototype.hasOwnProperty.call(c, k) && k !== 'boxId' && k !== 'id' && k !== 'dateUpdated' && k !== 'dateCreated') {
                    c[k] = null;
                  }
                })
              }
              c.string = (prop) => {
                  switch(prop) {
                      case 'id': return c.id;
                      case 'boxId': return c.boxId;
                      case 'activationStatus': return (this.activationStatusMap.find((m) => m.value === c.activationStatus)||{}).text;
                      case 'billingCategory': return (this.billingCategoryMap.find((m) => m.value === c.billingCategory)||{}).text;
                      case 'mobilePlanPrice': return this.toPrice(c.mobilePlanPrice);
                      case 'lendingPrice': return this.toPrice(c.lendingPrice);
                      case 'licensePrice': return this.toPrice(c.licensePrice);
                      case 'connectionProvider': return (this.connectionProviderMap.find((m) => m.value === c.connectionProvider)||{}).text;
                      case 'carrier': return (this.carrierMap.find((m) => m.value === c.carrier)||{}).text;
                      case 'machineInterfaceType': return (this.machineInterfaceMap.find((m) => m.value === c.machineInterfaceType)||{}).text;
                      case 'sendDate': return this.formatDate(c.sendDate);
                      case 'billingStartDate': return this.formatDate(c.billingStartDate);
                      case 'activationDate': return this.formatDate(c.activationDate);
                      case 'deactivationDate': return this.formatDate(c.deactivationDate);
                      case 'comments': return c.comments;
                  }
              }
            })
          });
      }
    }
  },
  mounted() {
    this.axios.get('api/Tenants')
      .then((response) => {
        this.loading.tenants = false;
        this.tenants = response.data;
        this.tenants.forEach((t) => t.text = `${t.id} - ${t.name} - ${t.cpf !== null ? t.cpf : t.cnpj}`)
      })
  },
  methods: {
    editItem(item){
      this.editedIndex = this.contracts.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.itemDialog = true;
    },
    saveItem(){
      if(this.$refs.form.validate()) {
        const index = this.editedIndex;
        this.loading.newItem = true;
        this.axios.post(`api/contracts/${this.tenantId}`, this.editedEntry)
          .then((response) => {
              if (index === -1) { // is new item
                this.contracts.push(response.data);
              }
              else {
                Object.assign(this.contracts[index], response.data);
              }
              this.handleSuccess('Ativação salva com sucesso');
          }).catch((error) => this.handleError('Erro ao salvar ativação', error))
          .then(() => this.loading.newItem = false);
      }
    },
    updateTenantInfo() {
      if (this.$refs.tenantForm.validate()) {
        const editedTenant = this.editedTenant;
        this.axios.put(`api/tenants/${this.tenant.id}`, editedTenant)
          .then(() => {
            this.tenant = editedTenant;
            this.handleSuccess('Cliente atualizado');
          }).catch((error) => this.handleError('Erro ao atualizar cliente', error))
          .then(() => this.loading.updateTenant = false);
      }
    },
    // confirmRemoval(item){
    //   this.removeDialog.item = item;
    //   this.removeDialog.enabled = true;
    // },
    // deleteItem(item) {
    //   this.loading.removeItem = true;
    //   this.axios.delete(`api/contracts/${item.id}`)
    //     .then((response) => {
    //       const entryIndex = this.contracts.indexOf(item);
    //       this.contracts.splice(entryIndex, 1);
    //       this.handleSuccess('Item removido');
    //     }).catch((error) => this.handleError('Erro ao remover item', error))
    //       .then(() => {
    //         this.loading.removeItem = false;
    //       });
    // },
    handleSuccess(message){
      this.snackbar.message = message;
      this.snackbar.color = 'success';
      this.snackbar.timeout = 3000;
      this.snackbar.enabled = true;
      this.itemDialog = false;
      this.removeDialog.enabled = false;
    },
    handleError(message, error){
      if(error.response && error.response.data) error = error.response.data;
      this.snackbar.message = message + (error == null ? '' : (': ' + error));
      this.snackbar.color = 'error';
      this.snackbar.timeout = 5000;
      this.snackbar.enabled = true;
    },
    toPrice(value) {
      return this.$currencyFormatter.format(value);
    },
    formatDate(date) {
      return date != null ? moment(date).format('DD/MM/YYYY') : '';
    },
  }
}
</script>
