export default {
    giveFeedback: 'Dar feedback da página',
    feedbackDialogTitle: 'Feedback da página',
    yourRatingForPage: 'Sua nota para a página',
    selectOneOrMore: 'Selecione um ou mais', 
    whatsWrong: 'O que está ruim?',
    whatCanImprove: 'O que pode melhorar?',
    whatPleasedYou: 'O que mais agradou?',
    contactNumber: 'Número de contato',
    weMayContactYou: 'Poderemos entrar em contato com você',
    contactNumberNote: 'Este número é para podermos entrar em contato. Se o seu problema precisar de acompanhamento, entre em contato com o suporte: 19 99959-6630.',
    cancelConfirmation: 'Tem certeza de que deseja cancelar? As alterações serão perdidas.',
    cancel: 'Cancelar',
    submit: 'Enviar',
    thankYouForRating: 'Obrigado por avaliar a página',
    yourOpinionIsImportant: 'Sua opinião é muito importante para nós.',
    close: 'Fechar',
    contactSupport: 'Se o seu problema precisar de acompanhamento, entre em contato com o suporte: 19 99959-6630.',
    toGoBack: 'Voltar',
    yesOption: 'Sim',
    feedbackReason: 'Por favor informe o motivo do feedback (categoria "Outros").',
    errorThatHappens: 'Por favor informe o erro que acontece.',
    countryCode: '+55 11 11111-1111',
    countryCodeRules: 'Insira um celular válido incluindo o código do país',
    formatInput: '+## ## #####-####',
    postTenantFeedbackError: 'Não foi possível registrar o feedback',
  };
  