export default function(response, defaultFileName) {
  let filename = defaultFileName;
  if (response.headers["content-disposition"] != null) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(response.headers["content-disposition"]);
    if (matches != null && matches[1] && matches[1].length > 0) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function downloadFromUrl(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  link.rel = "noopener noreferrer";
  document.body.appendChild(link);
  link.click();
}