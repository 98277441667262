<template>
  <v-container fluid class="pa-0">

    <!-- This apply help to the Header -->
    <portal to="toolbarTitle">
      <help>
        <template #title>
          Encomendas
        </template>
        <p>
          Aqui são exibidas todas as encomendas realizadas por cada cliente.
          Cada encomenda possui um status, que possui cores diferentes para ajudar na visualização,
          que indica em qual parte do processo ela se encontra.
        </p>
        <p>
          Na coluna de <strong>ações</strong> você irá encontrar três botões que representam a
          atualização no status que você pode fazer:
          <ul>
            <li>O botão <v-icon color="success">mdi-check-circle</v-icon> quando acionado, atualiza o status atual da encomenda para Confirmado.</li>
            <li>O botão <v-icon color="error">mdi-close-circle</v-icon> quando acionado, atualiza o status atual da encomenda para Cancelado.</li>
            <li>O botão <v-icon color="info">mdi-truck-check</v-icon> quando acionado, atualiza o status atual da encomenda para Retirado.</li>
          </ul>
          <strong>Obs:</strong> Se o botão estiver com a <strong>cor cinza</strong>, indica que aquela ação não faz parte do fluxo para o status atual.
        </p>
        <p>
          Ao expandir a linha clicando em <v-icon>keyboard_arrow_down</v-icon>, você vê uma listagem com todos os produtos que pertecem a encomenda.
        </p>

        <help-video  help-code="Orders"/>
      </help>
    </portal>


    <v-layout>
      <v-flex xs12>
        <v-card class="mb-16">

          <report-filters-orders @modify="updateFilters"></report-filters-orders>

          <v-divider></v-divider>

          <v-data-table
            :loading="loading.orders"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :single-expand=true
            :expanded.sync="expanded"
            item-key="uuid"
            show-expand
            :server-items-length="totalItems"
            no-data-text="Sem encomendas"
            :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          >
            <template v-slot:[`item.dateCreated`]="{ item }">
              <span>{{ formatDate(item.dateCreated) }}</span>
            </template>
            <template v-slot:[`item.expectedDeliveryDate`]="{ item }">
              <span>{{ formatDate(item.expectedDeliveryDate) }}</span>
            </template>
            <template #item.totalPrice="{ item }">
              <span>{{ $currencyFormatter.format(item.totalPrice) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip :color="getColor(item.status)" dark>{{ formatStatus(item.status) }}</v-chip>
            </template>
            <template #item.actions="{ item }" class="justify-center px-0">
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" @click="handleClick(item.uuid, 1)" v-on="on"
                    v-bind:disabled="item.status == 'Opened' ? false : true">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>Confirmar</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" @click="handleClick(item.uuid, 2)" v-on="on"
                  v-bind:disabled="(item.status == 'Opened' || item.status == 'Confirmed') ? false : true">
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Cancelar</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" @click="handleClick(item.uuid, 3)" v-on="on"
                  v-bind:disabled="(item.status == 'Confirmed') ? false : true">
                    <v-icon color="info">mdi-truck-fast</v-icon>
                  </v-btn>
                </template>
                <span>Marcar como disponível</span>
              </v-tooltip>

              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" @click="handleClick(item.uuid, 4)" v-on="on"
                  v-bind:disabled="(item.status == 'Available') ? false : true">
                    <v-icon color="info">mdi-truck-check</v-icon>
                  </v-btn>
                </template>
                <span>Marcar como retirado</span>
              </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">

              <td :colspan="headers.length" class="ma-0 pa-0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <td colspan="2" class="grey lighten-4 elevation-0">
                          <strong>Pedido por:</strong> {{ item.requester.name }} <strong> - </strong>
                          <v-icon color="info">mdi-phone</v-icon> {{ item.requester.phone }} <strong> - </strong>
                          <v-icon color="info">mdi-email-outline</v-icon> {{ item.requester.email }}
                        </td>
                        <td colspan="2" class="grey lighten-4 elevation-0" style="text-align:right">
                          <v-icon color="info">mdi-barcode</v-icon><strong>Código de barras: </strong>{{ item.barcode }}
                        </td>
                      </tr>
                    </thead>
                    <tr class="grey lighten-4 elevation-0">
                      <th>Código produto</th>
                      <th>Nome produto</th>
                      <th>Quantidade</th>
                      <th>Preço</th>
                    </tr>
                    <tbody>
                      <tr v-for="field in Object.keys(item.items)" v-bind:key="item.items[field].product.description">
                        <td>
                          {{item.items[field].product.code}}
                        </td>
                        <td>
                          {{item.items[field].product.description}}
                        </td>
                        <td>
                          {{item.items[field].quantity}}
                        </td>
                        <td>
                          {{$currencyFormatter.format(item.items[field].price)}}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>

          </v-data-table>
        </v-card>
      </v-flex>

      <v-snackbar :timeout="snackbar.timeout" :color="snackbar.color" v-model="snackbar.enabled">
        {{snackbar.message}}
        <v-btn dark text @click.native="snackbar.enabled = false">Ok</v-btn>
      </v-snackbar>

    </v-layout>
  </v-container>
</template>

<script>
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import moment from 'moment';
import ReportFiltersOrders from '@/views/orders/ReportFiltersOrders';
import HelpVideo from '@/components/HelpVideo.vue';

export default {
  data() {
    return {
      expanded: [],
      orders: [],
      valid: true,
      rules:{
      },
      headers: [
        { text: 'Cliente', align: 'left', sortable: false, value: 'pointOfSale.customerName' },
        { text: 'Local', align: 'left', sortable: false, value: 'pointOfSale.localName' },
        { text: 'PDV', align: 'left', sortable: false, value: 'pointOfSale.id' },
        { text: 'Usuário', align: 'left', sortable: false, value: 'requester.name' },
        { text: 'Data de criação', align: 'left', sortable: true, value: 'dateCreated' },
        { text: 'Preço', align: 'left', sortable: true, value: 'totalPrice' },
        { text: 'Status', align: 'left', sortable: true, value: 'status' },
        { text: 'Ações', align: 'left', sortable: false, value: 'actions' }
      ],
      loading: {
        orders: true,
        newItem: false,
        removeItem: false
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      snackbar: {
        enabled: false,
        message: null,
        color: 'error',
        timeout: 3000
      },
      form: {},
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateCreated'],
        sortDesc: [false],
        mustSort: true
      }
    }
  },
  computed: {
    codeList(){
      return this.orders.map((p) => p.code);
    },
    isActionDisabled(status){
      console.log(status)
      return true
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getOrders();
        }
      },
      deep: true
    },
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.getOrders();
    },
    getOrders() {
      this.loading.orders = true;

      let params = toApiPagination(this.options, null);
      this.insertFiltersFromForm(params);

      this.axios.get('api/Orders', { params: params })
        .then((response) => {
          this.loading.orders = false;
          this.orders = response.data.items;
          this.totalItems = response.data.totalItems;
        });
    },
    insertFiltersFromForm(params) {
      const form = this.form;

      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.pointOfSaleId = form.pointOfSaleId;
      params.status = form.status;

      return params;
    },
    getColor (status) {
      if (status == 'Opened') return 'orange'
      else if (status == 'Confirmed') return 'green'
      else if (status == 'Cancelled') return 'red lighten-3'
      else if (status == 'Available') return 'blue'
      else return 'grey lighten-1'
    },
    formatDate(date) {
      return moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : "";
    },
    formatStatus(oldStatus) {
      let newStatus = ""
      if (oldStatus == 'Opened') newStatus = 'Pendente'
      else if (oldStatus == 'Confirmed') newStatus = 'Confirmado'
      else if (oldStatus == 'Cancelled') newStatus = 'Cancelado'
      else if (oldStatus == 'Available') newStatus = 'Disponível para retirar'
      else if (oldStatus == 'Purchased') newStatus = 'Retirado'

      return newStatus
    },
    handleClick(uuid, action) {
      let newStatus = 'Opened'
      if (action == 1) {
        newStatus = 'Confirmed'
      } else if (action == 2){
        newStatus = 'Cancelled'
      } else if (action == 3) {
        newStatus = 'Available'
      } else if (action == 4){
        newStatus = 'Purchased'
      }




      this.axios.put(`api/Orders/${uuid}/Status?newStatus=${newStatus}`)
      .then((response) => {
        console.log(response)
        this.handleSuccess('Status da encomenda atualizado com sucesso');
        this.getOrders()
      }).catch((error) => this.handleError('Erro ao atualizar o Status', error));
    },
    handleSuccess(message) {
      this.snackbar.message = message;
      this.snackbar.color = 'success';
      this.snackbar.timeout = 3000;
      this.snackbar.enabled = true;
      this.removeDialog.enabled = false;
    },
    handleError(message, error) {
      if(error.response && error.response.data) error = error.response.data;
      this.snackbar.message = message + (error == null ? '' : (': ' + error));
      this.snackbar.color = 'error';
      this.snackbar.timeout = 5000;
      this.snackbar.enabled = true;
    }
  },
  components: {
    ReportFiltersOrders,
    HelpVideo
  }
}
</script>
