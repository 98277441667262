export default {
    titleTransactionsReportsFilters: 'Filtros e ações',
    startDateTransactionsReportsFilters: 'Data inicial',
    endDateTransactionsReportsFilters: 'Data final',
    clearTransactionsReportsFilters: 'Limpar',
    locationTransactionsReportsFilters: 'Localização',
    stateTransactionsReportsFilters: 'Estado',
    customerTransactionsReportsFilters: 'Cliente',
    pointOfSaleTransactionsReportsFilters: 'Ponto de venda',
    productTransactionsReportsFilters: 'Produto',
    categoryTransactionsReportsFilters: 'Categoria',
    groupByTransactionsReportsFilters: 'Agrupar por',
    excelTransactionsReportsFilters: 'Excel',
    updateTransactionsReportsFilters: 'Atualizar',
    selectCustomerFirstTransactionsReportsFilters: 'Selecione antes um cliente',
    lastThirtyDaysTransactionsReportsFilters: '30 dias',
    lastFifteenDaysTransactionsReportsFilters: '15 dias',
    lastSevenDaysTransactionsReportsFilters: '7 dias',
    sameDayTransactionsReportsFilters: 'mesmo dia',    
    productsTransactionsReportsFilters: 'Produtos',
    customersTransactionsReportsFilters: 'Clientes',
    pointsOfSaleproductsTransactionsReportsFilters: 'Pontos de Venda',
    statesTransactionsReportsFilters: 'Estados',
    paymentMethodsTransactionsReportsFilters: 'Métodos de Pagamento',
    cardFlagsTransactionsReportsFilters: 'Bandeiras de Cartões',
    dayTransactionsReportsFilters: 'Dia',
    monthTransactionsReportsFilters: 'Mês',
    dayOfTheWeekproductsTransactionsReportsFilters: 'Dia da Semana',
    toCleanTransactionsReportsFilters: 'Limpar',
  };
  