
import {defineComponent} from 'vue'
import {TenantGroup} from "@/types/Customers";
import agent from "@/api/agent";
import {formatCnpj} from "@/utility/TextFormatting";

export default defineComponent({
  props: {
    value: {type: Number, default: null},
    load: {type: Boolean, default: false}
  },
  data() {
    return {
      tenantGroups: [] as TenantGroup[],
      loading: true,
    }
  },
  watch: {
    load: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.GetTenantGroupsSimpleInfo();
          this.$emit('update:loadDropDowns', false);
        }
      }
    }
  },
  computed: {
    tenantGroupId: {
      get(): number | null {
        return this.value;
      },
      set(value: number | null): void {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    GetTenantGroupsSimpleInfo() {
      this.loading = true;
      agent.Tenants.getTenantGroupsSimpleInfo()
        .then((response) => {
          this.tenantGroups = response.map((g: TenantGroup) => {
            return {
              groupId: g.groupId,
              groupName: g.groupName,
              cnpj: g.cnpj,
              dropdownText: `${g.groupId} - ${g.groupName} - (${formatCnpj(g.cnpj)})`,
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  formatCnpj
})
