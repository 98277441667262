<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <feedback ref="feedback"></feedback>

    <v-card>
      <v-card-title>
        <div class="title">{{ $t('consultationTitleInvalidationsDialog') }}</div>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="$t('noItemsInvalidationsDialog')"
        :mobile-breakpoint="1024"
        :footer-props="{ itemsPerPageOptions: [5, 10] }"
        must-sort
      >
        <template #item.status="{ item }">
          <span :class="getStatusColor(item.status)">
            {{ translateStatus(item.status) }}
          </span>
        </template>
        <template #item.dateCreated="{ item }">
          {{ $moment(item.dateCreated).format('DD/MM/YYYY HH:mm') }}
        </template>
        <template #item.dateUpdated="{ item }">
          {{ $moment(item.dateUpdated).format('DD/MM/YYYY HH:mm') }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';

export default {
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getInvalidations();
        }
      },
     },
    dialog(visible) {
      if (visible) this.getInvalidations();
    }
  },
  data() {
    return {
      dialog: false,
      loading: true,
      items: [],
      headers: [
        { text: this.$t('cnpjInvalidationsDialog'), align: 'left', sortable: false, value: 'companyCnpj' },
        { text: this.$t('seriesInvalidationsDialog'), align: 'left', sortable: true, value: 'series' },
        { text: this.$t('initialNumberInvalidationsDialog'), align: 'left', sortable: true, value: 'initialNumber' },
        { text: this.$t('finalNumberInvalidationsDialog'), align: 'left', sortable: true, value: 'finalNumber' },
        { text: this.$t('statusInvalidationsDialog'), align: 'left', sortable: true, value: 'status' },
        { text: this.$t('reasonInvalidationsDialog'), align: 'left', sortable: false, value: 'statusReason' },
        { text: this.$t('justificationInvalidationsDialog'), align: 'left', sortable: false, value: 'reason' },
        { text: this.$t('sefazProtocolInvalidationsDialog'), align: 'left', sortable: false, value: 'authorizationProtocol' },
        { text: this.$t('createdDateInvalidationsDialog'), align: 'left', sortable: true, value: 'dateCreated' },
        { text: this.$t('updatedDateInvalidationsDialog'), align: 'left', sortable: true, value: 'dateUpdated' }
      ],
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['dateUpdated'],
        sortDesc: [true],
        mustSort: true
      },
      totalItems: 0
    }
  },
  methods: {
    getInvalidations() {
      this.loading = true;
      this.axios.get('api/invoiceInvalidations?syncInvalidations=true', { params: toApiPagination(this.options) })
        .then((response) => {
          this.loading = false;
          this.totalItems = response.data.totalItems;
          this.items = response.data.items;
        });
    },
    translateStatus(status) {
      if (status === 'Processing') return this.$t('processingStatusInvalidationsDialog');
      if (status === 'Approved') return this.$t('approvedStatusInvalidationsDialog');
      if (status === 'Denied') return this.$t('deniedStatusInvalidationsDialog');
      if (status === 'RequestError') return this.$t('requestErrorStatusInvalidationsDialog');
      else return this.$t('unknownProblemInvalidationsDialog');
    },
    getStatusColor(status) {
      if (status === 'Approved') return "green--text text--darken-2";
      if (status === 'Denied') return "red--text text--darken-2";
      else return "";
    }
  }
}
</script>
