export default {
  products: {
    title: 'Productos',
    data: {
      purchaseSettings: {
        conversionUnits: {
          title: 'Unidades de Conversión',
          box: 'Caja',
          unit: 'Unidad',
          package: 'Fardo',
          bag: 'Paquete',
          unknown: 'Desconocido'
        }
      },
      description: 'Descripción del Producto',
      code: 'Código',
      unitValue: 'Valor Unitario',
    },
    register: {
      select: 'Seleccione un producto',
      isSelect: 'Producto ya seleccionado',
      notRegistred: '¿No tiene este producto registrado?',
    },
    help: {
      description: `
        <p>Gestiona tus productos visualizando información como imagen, ID, Código Interno (SKU), descripción, categoría, precio estándar, Código de Barras (EAN), fecha de creación y disponibilidad (Público o Privado). Puedes agregar, editar, eliminar productos y exportar los datos a Excel.</p>
        <p><strong>Para productos públicos, solo la red/licenciante podrá editarlos y eliminarlos. Puedes utilizarlos y exportar los datos a Excel, pero sin la posibilidad de modificación.</strong></p>
  
        <p><strong>Categorías:</strong></p>
        <p>Muestra el ID, nombre de la categoría y la cantidad de productos. Puedes eliminar categorías según sea necesario.</p>
  
        <p><strong>Agrupaciones:</strong></p>
        <p>Agrupa productos similares en un solo ítem. Muestra ID, descripción, categoría, precio estándar y fecha de creación, sin incluir Código de Barras (EAN) o la opción de exportar a Excel. Ideal para gestionar variaciones del mismo producto, como diferentes sabores o tamaños.</p>
      `,
    },
  }
}