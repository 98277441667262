export default {
  inventory: {
    title: 'Inventory',
    help: {
      title: 'Inventory Information',
      description: `<p>Keep accurate control of available items and movements in your inventory. 
          Use this page to monitor inventory in different locations, make adjustments, and analyze movement patterns.
        </p>
        <p><strong>Inventory items: </strong>Explore inventory items and transactions details with custom filters. 
          You can segment by Store Inventory or Central Inventory. Adjust the item's address, 
          quantity, critical limit, and minimum purchase quantity. Additionally, you can edit quantities directly in the system and download the data in Excel format.
        </p>
        <p><strong>Transactions: </strong>Analyze operations by specific touch or across your entire operation with detailed filters. 
          Understand movement patterns and identify potential logistical errors to optimize your operation.
        </p>`
    },
    items: {
      title: 'Inventory items',
      tableTitle: 'Items in Inventory',
      tooltips: {
        onlyDistributionCenter: 'Select only one distribution center',
        notDeleteInventoryPDV: 'Cannot delete product from point of sale inventory',
      },
      messages: {
        error: {
          downloadFile: 'Unable to download the file',
          consult: 'Unable to consult the inventory',
        },
        success: {
          minimumQuantityUpdated: 'Minimum purchase quantity successfully updated',
          addressUpdated: 'inventory address successfully updated',
          minimumCriticalUpdated: 'Minimum critical quantity successfully updated',
          quantityUpdated: 'Quantity successfully updated',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Remove products from distribution center',
          all: 'Do you want to remove all products from the distribution center that are not in an active planogram?',
          specific: 'Do you want to remove the product {product} from the distribution center?',
          attention: 'Attention',
          messages: {
            success: {
              productsRemoved: 'Products were removed from distribution center',
              noProductsToRemove: 'There are no products to remove from distribution center',
            },
            error: {
                removeProducts: 'Unable to remove products from distribution center',
            },
          }
        },
        itemAddress: {
          title: 'Inventory Address',
          messages: {
            error: {
              addressUpdated: 'Unable to edit inventory address',
            },
          },
          help: {
            description: `<p align="justify">
            The <strong>inventory Address</strong> is the location of the item in the distribution center.
            </p>
            <p align="justify">
              This address is standardized according to the inventory, usually a code that indicates where the item is stored in the distribution center.
            </p>
            <p align="justify">
              For example, some Coca-Cola bottles are stored on the shelf with code PRAT20.
              Thus, the inventory address for this item is PRAT20, which allows it to be located in the distribution center.
            </p>`
          }
        },
        addProduct: {
          title: 'Add products to distribution center',
          radioButtons: {
            addAllProducts: 'Add all products from the planogram to the distribution center',
            addSpecificProducts: 'Add specific products to the distribution center',
          },
          messages: {
            success: {
              productsAdded: 'Products were added to the distribution center',
              noProductsToAdd: 'There are no products to add to the distribution center',
            },
            error: {
              addProducts: 'Unable to add products to the distribution center',
            },
          }
        },
        minimuCritical: {
          title: 'Critical Minimum',
          help: {
            description: `<p align="justify">
                The <strong>Critical Minimum</strong> is an essential indicator for inventory management, signaling when it is necessary to replenish merchandise to prevent shortages.
                Keeping an eye on this value is vital to optimize operational efficiency and ensure customer satisfaction. Our feature is fully editable, providing flexibility 
                to adjust it according to specific operational needs. After editing, the new value is saved, allowing personalized control of the inventory.
                For example, if you update the value from 5 to 10, the system will consider 10 as the new "Critical Minimum." This keeps inventory levels ideal and prevents drops 
                in essential products in the store.
              </p>`
          },
          label: 'Edit critical minimum',
          messages: {
            error: {
              minimumCriticalUpdated: 'Error saving',
            },
          }
        },
        purchaseQuantity: {
          title: 'Minimum Quantity',
          help: {
            description: `<p align="justify">
              The <strong>Minimum Purchase Quantity</strong> is the minimum purchase order that can be placed to supply your distribution center.
              </p>
              <p align="justify">
                This value is based on the <strong>Purchase Unit</strong> (such as box, pack, package, etc.) and how many individual products that unit contains.
              </p>
              <p align="justify">
                For example, a beverage supplier only sells with a minimum order of 10 packs containing 6 units each.
                Thus, the minimum purchase quantity in this case is 60 units. You would enter <strong> "10" in this form</strong> for a 
                <strong>Minimum Purchase Quantity of 60 units.</strong>
              </p>`
          },
          minQuantity: '<strong>Minimum Purchase Quantity:</strong> total of {minimumPurchaseQuantity} units',
          messages: {
            error: {
              minimumQuantityUpdated: 'Unable to edit the Minimum Purchase Quantity',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title: 'Transactions Made',
      tab: 'Transactions',
      messages: {
        error: {
          generateFile: 'Error generating file',
        }
      }
    },
    operationTypes: {
      correction: "Correction",
      sale: "Sale",
      purchase: "Purchase",
      reversed: "Operation Reversal",
      automaticCorrection: "Automatic Correction",
      supply: "Supply",
      expired: "Expired",
      damaged: "Damaged Product",
      return: "Return",
      removedInventory: "Removed from Inventory",
      other: "Others",
      expirationDateChanged: "Expiration Date Changed",
      expirationDateAdded: "Expiration Date Added",
      expirationDateRemoved: "Expiration Date Removed",
    }
  }
}
