<template>
    <help>
        <template #title>{{ $t('dialogProductsUnusedHelpTitle') }}</template>
        <p>
            {{ $t('dialogProductsUnusedIntroParagraphHelp') }}
        </p>
        <ul>
            <li>{{ $t('dialogProductsUnusedFilterPeriod') }}</li>
        </ul>
        <br>
        <p>
            {{ $t('dialogProductsUnusedFilterPeriodSingleDate') }}
        </p>
        <p>
            {{ $t('dialogProductsUnusedFilterPeriodDescription') }}
        </p>
    </help>
  </template>
  
  <script>
  export default {
    name: 'DialogProductsUnusedHelp'
  }
  </script>
  