export default {
    purchaseDetailsHelpTitle: 'Detalhes da compra',
    purchaseDetailsHelpDescription: 'Essa página lista os produtos da compra importada, além de permitir o cancelamento da mesma.',
    purchaseDetailsHelpInfo: 'Detalhes:',
    purchaseDetailsHelpInfoDetail: 'Para uma melhor visualização dos detalhes, os dados representados na tabela de compras também são demonstrados nesta aba.',
    purchaseDetailsHelpProductInfo: 'Informações do produto:',
    purchaseDetailsHelpProductNoteValue: `
    <strong>Valor total da nota:</strong> 
    Representa o valor total do produto contido na nota fiscal importada.
    `,
    purchaseDetailsHelpProductTotalCost: `
    <strong>Custo total do produto:</strong> 
    Representa o custo total do produto já considerando estimativa de impostos calculado.
    `,
    purchaseDetailsHelpUndoPurchase: 'Desfazer compra:',
    purchaseDetailsHelpUndoInstructions: `Para desafazer a compra, basta clicar no botão <strong>Desfazer</strong> nesta aba.`,
    purchaseDetailsHelpUndoWarning: 'Assim que desfeita, todas as movimentações de estoque realizadas pela compra serão desfeitas, sendo uma operação irreversível.'
  };
  