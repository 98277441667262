export default {
    helpTitleProductGroupsHelp: 'Product groupings',
    introductionProductGroupsHelp: 'A product grouping is used to manage different product variations as if they were a single product.',
    exampleExplanationProductGroupsHelp: `For example, let's say you sell eggs in your store, and your supplier has boxes of brand A or brand B eggs. 
    It's important to always have eggs in your store, but it doesn't matter whether they're brand A or B. 
    You then have the products "Eggs A" and "Eggs B" in your product register. 
    You could have these two products in the planogram, but it would be difficult to manage the Pick Lists, because you would always have 
    both brands of eggs would always appear, and you would have to manually edit the Pick List to leave only the brand you are going to supply that day.`,
    solutionExplanationProductGroupsHelp: `Grouping products solves this problem. You can create an "Eggs" grouping and add the products "Eggs A" and "Eggs B"
    to this grouping. Then, in your planogram, you would have one item with the grouping instead of separate items for each product.
    In this way, stock control, Pick Lists and replenishment are simplified.`,
    salesRecordExplanationProductGroupsHelp: `Sales, on the other hand, are recorded with the specific product. Thus, in the sales report by product, it is possible to identify which of the
    brand A or B is more popular with consumers.`,
  };
  