export default {
  searchBoxManagement: 'Search',
  boxesManagement: 'Boxes',
  noResultsBoxManagement: 'No results',
  idBoxManagement: 'ID',
  pdvBoxManagement: 'PDV',
  acquirerBoxManagement: 'Model',
  versionBoxManagement: 'Version',
  passwordBoxManagement: 'Password',
  notFoundMasterBarcode: 'Admin code not found',
};
