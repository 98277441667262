export class VerifyAuthenticatorRequest {
  constructor(
    public userId: string,
    public code: string,
    public tempToken: string,
    public supportLoginExtensions?: SupportedLoginExtensions,
  ) {}
}

export class SupportedLoginExtensions {
  constructor(
    public enableSupportLogin: boolean,
    public tenantId?: number,
    public groupId?: number
  ) {}
}
