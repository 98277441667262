export default {
    invalidateTitleInvalidationRequest: 'Inutilizar numeração',
    cnpjLabelInvalidationRequest: 'CNPJ da empresa',
    cnpjHintInvalidationRequest: 'Insira o CNPJ da empresa',
    noCompaniesInvalidationRequest: 'Não há empresas cadastradas.',
    seriesLabelInvalidationRequest: 'Série da nota',
    initialNumberLabel: 'Número inicial',
    finalNumberLabel: 'Número final',
    reasonLabelInvalidationRequest: 'Motivo (opcional)',
    closeInvalidationRequest: 'Fechar',
    invalidateButton: 'Inutilizar',
    requestSentInvalidationRequest: 'Solicitação enviada à Sefaz. Acompanhe o status pelo menu Consultar inutilizações.',
    requestFailedInvalidationRequest: 'Não foi possível solicitar a inutilização'
  }
  