export default {
    titleSellingProducts: 'Best selling products',
    abcText: `{numberOfAProducts} out of {totalNoOfProducts} products account for 80% of your revenue, and
    {numberOfCProducts} out of {totalNoOfProducts} products account for 5% of your revenue`,
    newTabLink: 'Open in New Tab',
    pointOfSaleLabel: 'Points of Sale',
    quantitySold: 'Quantity Sold',
    totalSold: 'Total Sold',
    productsSoldNoResults: 'No results',
    notRegistered: 'Product not registered',
    productsSold: 'Product',
    productsSoldAmount: 'Quantity sold',
    valueSoldProducts: 'Value sold',
    valueRupturesProducts: 'Ruptures',
    rupturesGetErrorMessage: 'There was an error loading product ruptures',
    abcCurveGetErrorMessage: 'There was an error loading the ABC analysis information',
    productsMostSoldHelpText: `<p style="text-align: justify;">
    Find out which are the top products in demand at your stores! Understand their sales and avoid possible stockouts due to lack of supply.
    </p>
    <p style="text-align: justify;">
    Additionally, we provide analyses on the importance of product mix in your operation. We identify the items that significantly drive your revenue, representing 80% of your sales.
    </p>
    <p style="text-align: justify;">
    On the other hand, we also highlight low-performing products, which contribute only 5% to your total income.
    </p>`
  };