export default {
    typeOfLocation: 'Tipo de Local',
    locationTypeDescription: 'El Tipo de Local se refiere al tipo de establecimiento en el que has instalado físicamente tu punto de venta (PDV). Por ejemplo:',
    locationTypeOptions: 
    `<li>PDV instalado en condominio de casas o apartamentos</li>
    <li>PDV instalado en empresa de oficinas o industria</li>
    <li>PDV instalado en hospital</li>
    <li>PDV instalado en universidad</li>
    <li>Otros</li>`,
    locationTypeSelect: 'Selecciona el lugar antes de definir el tipo',
};
