// This file is to be used to translate actions names (endpoints) to
// user display in the Audits view

export default {
  auditColorsPallete: [
    'blue lighten-2',
    'amber lighten-2',
    'lime lighten-2',
    'red lighten-2',
    'pink lighten-2',
    'green lighten-2',

    'blue',
    'amber darken-1',
    'lime darken-1',
    'green darken-1',
    'pink',
    'red',
  ]
}