export default{
    titleProductsPurchase: 'INFORMAÇÕES DA NOTA FISCAL',
    productsProductsPurchase: 'Produtos',
    productsComplementProductsPurchase: 'de',
    infoProductsPurchase: 'Informações dos produtos',
    helpProductsPurchase: 'Adicionar produtos na nota de forma manual, para adicionar um novo produto clique em próximo, para finalizar a nota clique em finalizar',
    selectedProductsPurchase: 'Produto já selecionado',
    unitInvoiceProductsPurchase: 'Unidades na nota',
    valueUnitHelpTitleProductsPurchase: 'Valor por unidade',
    valueUnitHelpProductsPurchase: 'Insira o valor de cada unidade e a quantidade do produto correspondente na nota.',
    quantityProductsPurchase: 'Quantidade do item:',
    quantityHelpTitleProductsPurchase: 'Unidade de conversão / Unidades',
    quantityHelpProductsPurchase: 'Selecione a unidade de conversão no sistema. (como unidades, caixas ou fardos) e indique quantas unidades compõem essa medida de conversão (por exemplo, unidade = 1, caixa = 10, fardo = 6).',
    quantityTotalProductsPurchase: 'Quantidade total do produto:',
    quantityTotalHelpTitleProductsPurchase: 'Quantidade total do produto',
    quantityTotalHelpProductsPurchase: `Após inserir os dados de quantidade e custo, nosso sistema realizará automaticamente o cálculo do total de unidades 
    o preço unitário correspondente. Por exemplo, ao inserir duas caixas, cada uma contendo 10 unidades, ao custo de 
    10 reais por caixa: Total de unidades: 20 | Preço unitário: R$ 1,00. Essa funcionalidade simplifica a visualização do 
    total de produtos e seu custo por unidade, tornando mais fácil a análise das informações.`,
    valueUnitProductPurchase: 'Valor unitário do produto:',
    productNotRegistry: 'Produto não cadastrado?',
    closeProductsPurchase: 'FECHAR',
    finishProductsPurchase: 'ADICIONAR E FINALIZAR',
    backProductsPurchase: 'VOLTAR',
    addProductsPurchase: 'ADICIONAR OUTRO',
    nextProductsPurchase: 'PROXIMO',
    messageValidButtonProductsPurchase: 'Para prosseguir preencha todos os dados',
    unitConversionProductsPurchase: 'Unidade de conversão',
    valueProductPurchase: 'Valor em R$',
    removeProductsPurchase: 'REMOVER',
}