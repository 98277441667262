
import Vue from 'vue';
import ProductsCloseToExpiration from './components/ProductsCloseToExpiration.vue';
import LastSupplyNew from './components/LastSupplyNew.vue';
import StatusPicklist from './components/StatusPicklist.vue';
import ConnectivityPointOfSale from './components/ConnectivityPointOfSale.vue';
import LossesProductAndPos from './components/LossesProductAndPos.vue';

export default Vue.extend({
  components: {
    ProductsCloseToExpiration,
    StatusPicklist,
    LastSupplyNew,
    ConnectivityPointOfSale,
    LossesProductAndPos,
  },
  props: {
    mustUpdate: { type: Boolean, required: true },
    activeTab: { type: Number, required: true },
    form: { type: Object, required: true },
  },
  computed: {
    mustCallEndpoints(): boolean {
      return this.mustUpdate && this.activeTab == 1
    }
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.$nextTick(() => this.$emit('updated'))
        }
      },
      immediate: true
    }
  }
})
