
import Vue from "vue";
import { ProgressLinear } from "@/types/ProgressLinear";
import { formatPercentage } from "@/utility/TextFormatting";

export default Vue.extend({
  props: {
    items: {
      type: Array as () => Array<ProgressLinear>,
      required: true,
    },
  },
  methods: {
    formatPercentage,
  },
});
