export default {
    helpPurchaseTitle: 'Purchases',
    helpPurchaseDescription: `
      On this page, the purchase notes entered by the user are organized, either through XML files, as well as those imported 
      from an ERP system. By selecting a specific purchase, it is possible to access details about the products involved in the process.
    `,
    helpPurchaseInfo: 'Purchase Information:',
    helpOperationId: `
      <strong>Operation ID:</strong>
      Identification ID of the purchase. Useful for identifying the movement performed in the stock 
      corresponding in the tabs: Stocks &rarr; Movements.
    `,
    helpStatus: `
      <strong>Status:</strong> 
      As soon as it is imported, the purchase has the status <strong>Active</strong>.
      If the purchase is canceled, its status becomes <strong>Canceled</strong>
      and its stock movements are undone.
    `,
    helpErpId: `
      <strong>ID in ERP:</strong> 
      Internal identification ID of the purchase in the ERP (only for notes imported from ERP systems)
    `,
    helpNoteNumber: `
      <strong>Note Number:</strong> 
      Identification number of the invoice.
    `,
    helpInventoryId: `
      <strong>Inventory ID and Stock:</strong> 
      Stock where the purchase movements were made.
    `,
    helpItemQuantity: `
      <strong>Item Quantity:</strong> 
      Total quantity of items contained in the purchase.
    `,
    helpNoteValue: `
      <strong>Note Value:</strong> 
      Represents the total value of all products contained in the purchase invoice.
    `,
    helpTotalCost: `
      <strong>Total Cost of Purchase:</strong> 
      Represents the total cost of all products contained in the purchase invoice
      already considering the estimated calculated taxes.
    `,
    helpReceivedDate: `
      <strong>Received Date:</strong> 
      Date and time the purchase was received in the ERP.
    `
  };
  