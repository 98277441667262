export default {
    usability: 'Usabilidade',
    easeOfUse: 'Facilidade de uso',
    confusingPage: 'Página confusa',
    performance: 'Performance',
    speed: 'Velocidade',
    slowPage: 'Página muito lenta',
    usefulness: 'Utilidade',
    usefulnessPositive: 'Utilidade',
    usefulnessNegative: 'Página pouco útil',
    errors: 'Erros',
    errorsPositive: 'Sem erros',
    errorsNegative: 'Erros na página',
    others: 'Outros',
    othersPositive: 'Outros',
    othersNegative: 'Outros',
  };
  