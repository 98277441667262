export default {
  textFieldSearchText: "Procurar",
  deleteNonUsedButton: "Excluir não utilizados",
  testNfceEmissionButton: "Testar emissão NFC-e",
  deleteTaxScenarioDeleteDialogText: "Tem certeza de que deseja deletar esse cenário fiscal?",
  cancelButtonDeleteDialog: "Cancelar",
  deleteButtonDeleteDialog: "Deletar",
  dataTableNoDataText: "Sem cenários fiscais cadastrados",
  taxRateIcms: "Alíquota ICMS (%):",
  taxRatePis: "Alíquota PIS (%):",
  taxRateCofins: "Alíquota COFINS (%):",
  taxRateIpi: "Alíquota IPI (%):",
  taxBenefit: "Benefício Fiscal:",
  headerIsInvoiceDataComplete: "Completo",
  headerId: "Id",
  headerName: "Nome",
  headerTaxation: "Regime Fiscal",
  headerState: 'Estado',
  headerCstIcms: 'CST-ICMS',
  headerCstPis: 'CST-PIS',
  headerCstCofins: 'CST-COFINS',
  headerCstIpi: 'CST-IPI',
  headerNumberOfProducts: 'Nº Produtos',
  headerActions: 'Ações',
  mainTaxGroup: 'Cenário Principal',
  successProductToTaxGroup: 'Sucesso ao adicionar produto ao cenário fiscal',
  errorProductToTaxGroup: 'Não foi possível adicionar o produto ao cenário fiscal',
  updatedBy: 'Responsável por última atualização no produto',
  deleteProductFromTaxGroup: 'Excluir'
}