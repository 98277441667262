
import Vue from 'vue';
import agent from "@/api/agent";
import { TaxGroupProducts } from "@/types/Taxing";
import {shouldRefreshData, toApiPagination} from '@/components/TableOptionsComparator';

export default Vue.extend({

props: {
    TaxId: { type: Number},
    TaxName: { type: String },
    Visible: { type: Boolean, default: false }
  },
  data() {
    return {
      taxProducts: [] as TaxGroupProducts[],
      dialogVisible: this.Visible,
      taxName: this.TaxName,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['code'],
        sortDesc: [true],
        mustSort: true
      },
      search: {
        text: '' || null,
        valid: true
      },
      headers : [
        { text: this.$t('headerCode'), align: 'left', sortable: true, value: 'code' },
        { text: this.$t('headerDescription'), align: 'left', sortable: true, value: 'description' },
        { text: this.$t('headerCategoryName'), align: 'left', sortable: true, value: 'categoryName' },
      ]
    };
  },
  methods: {
    getTaxProducts() {
      let params = toApiPagination(this.options, this.search.text)
      agent.Taxings.TaxGroupProducts(this.TaxId, params).then((response) => {
        this.taxProducts = response;
      });
    },
    closeDialog(){
      this.dialogVisible = false;
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.search.text = '' || null;
      this.$emit('close')
    }
  },
  watch: {
    Visible(newVisible) {
      this.dialogVisible = newVisible;
      if(this.dialogVisible && this.TaxId != null){
        this.getTaxProducts();
      }
    },
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTaxProducts();
        }
      },
    },
  }
});
