
import Vue from 'vue';
import i18n from '@/locales/i18n';

export default Vue.extend({
  props: {
    value: {} as any,
    label: { type: String, default: i18n.t('discountType') },
    multiple: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-sale-outline" },
    rules: { type: Array, default: () => [], required: false },
    clearable: { type: Boolean, default: true }
  },
  data() {
    return {
      discountTypes: [
        { text: i18n.t('percentage'), value: "Percentage" },
        { text: i18n.t('fixed'), value: "Fixed" }
      ]
    };
  },
  computed: {
    selectedTypeDiscount: {
      get(): any {
        return this.value;
      },
      set(value: any) {
        this.$emit("input", value);
      }
    }
  }
});
