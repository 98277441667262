
import Vue from "vue";
import { PosMostSales } from "@/types/SalesDashboard";
import agent from "@/api/agent";
import moment from "moment";
import BarChart from "@/newReport/BarChart";
import Vuetify from "vuetify";
import PosMostSalesHelp from "../helpers-new/PosMostSalesHelp.vue";

Vue.use(Vuetify);

export default Vue.extend({
  props: {
    mustCallEndpoints: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      posMostSalesList: [] as PosMostSales[],
      graph: {
        bottomPosLabels: [] as string[],
        visible: false as boolean,
        data: [] as object[],
        height: 0 as number,
      },
      dateIntervals: [
        { text: this.$t("thirtyDays"), value: 30 },
        { text: this.$t("fifteenDays"), value: 15 },
        { text: this.$t("sevenDays"), value: 7 },
        { text: this.$t("today"), value: 0 },
      ],
      dateInterval: 30,
      barColor: (this.$vuetify.theme as any).defaults.dashboard.barColor,
    };
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value == true) {
          this.getPointOfSaleMostSales();
        }
      },
    },
  },
  mounted() {
    this.getPointOfSaleMostSales();
    this.verifyResize();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    getPointOfSaleMostSales() {
      this.graph.visible = false;
      let params = { minDate: this.form.minDate, maxDate: this.form.maxDate, timezoneOffset: this.form.timezoneOffset };
      agent.SalesDashboard.getPosMostSales(params).then((response) => {
        this.posMostSalesList = response;

        this.graph.bottomPosLabels = this.posMostSalesList.map((x) => {
          return x.description;
        });
        const values = this.posMostSalesList.map((x) => x.total);

        this.graph.data = [
          {
            label: this.$t(""),
            backgroundColor: this.barColor,
            data: values,
          },
        ];

        this.graph.visible = true;
      });
    },
    verifyResize() {
      if (this.$vuetify.breakpoint.xs) {
        this.graph.height = 460;
      } else if (this.$vuetify.breakpoint.sm) {
        this.graph.height = 150;
      } else if (this.$vuetify.breakpoint.md) {
        this.graph.height = 150;
      } else {
        this.graph.height = 150;
      }
    },
    handleResize() {
      this.graph.visible = false;
      this.verifyResize();
      setTimeout(() => {
        this.graph.visible = true;
      }, 100);
    },
    getDatesInterval(): any {
      let params = {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().add(-this.dateInterval, "days").format("YYYY-MM-DD"),
      };
      return params;
    },
  },
  components: {
    "bar-chart": BarChart,
    PosMostSalesHelp,
  },
});
