
import Vue from 'vue';
import TenantFeedback from '@/components/TenantFeedback.vue';

export default Vue.extend({
    components: {
        TenantFeedback
    },
    methods: {
        closeFeedback() {
            this.$emit('finish');
        }
    }
})
