export default {
    createPickLists: 'Create',
    pendingPickLists: 'Pending',
    separatedPickLists: 'Separated',
    suppliedPickLists: 'Supplied',
    pickListCreationPickLists: 'Creation of Pick Lists',
    helpPickList: `<p>
    Here, points of sale that have an active or pending planogram are displayed, but which do not have
    no pending Pick List.
    </p>
    <p>
    <strong>Pick List Generation:</strong>
    </p>
    <ul>
    <li>
        To create a Pick List, simply select one or more points of sale and click <strong>"Generate pick
        list"</strong>,
        You can configure the Pick List into 2 types:
        <ul>
        <li>
            <strong>Primary Pick List:</strong>
            <ul>
            <li>
                the primary Pick List serves as the main Pick List, typically with the most products
                of your planogram. You can only have one main Pick List at a time;
            </li>
            <li>
                primary Pick Lists appear with the "<v-icon>mdi-check</v-icon>" symbol in the "Pending" tab;
            </li>
            </ul>
        </li>
        <li>
            <strong>Secondary Pick List:</strong>
            <ul>
            <li>
                the secondary Pick List serves as an auxiliary Pick List, and may have more than one Pick List
                secondary by POS. You can name them according to the content;
            </li>
            <li>
                To mark the Pick List as secondary, simply click <strong>"Advanced"</strong> and mark
                the option;
            </li>
            <li>
                secondary Pick Lists appear with the "
                <v-icon>mdi-close</v-icon>
                " in the "Pending" tab;
            </li>
            <li>
                by placing the option "Filter products by category" +
                "Generate pick list by category", this creates a secondary pick list
                separately for each selected category, for each selected POS.
            </li>
            </ul>
        </li>
        </ul>
    </li>
    <li>
        In addition to the 2 types of Pick List, you can configure the content of the Pick List, being able to select all
        the products on your planogram, or filter by specific categories;
    </li>
    <li>
        You can also include only products that are in critical stock, regardless of whether they are all
        the products or just the chosen categories.
    </li>
    </ul>`,
    pickListPendingPickLists: 'Pick Lists Pending',
    pendingPickListsHelp: `<p>
    The Pick List is a list of products to be taken when the point of sale is next supplied.
    To see changes to products and their quantities, click on a Pick List.
    </p>
    <p>
    If there is a pending Pick List for a point of sale,
    when using the <strong>Replenish </strong> feature of the payment system,
    The number of items on the Pick List will be added to the point of sale's stock.
    The Pick List will then enter the <strong>supplied</strong> Pick Lists table,
    and the point of sale will return to the <strong>POS without Pick List</strong> table.
    </p>`,
    pickListSeparatedPickLists: 'Separated Pick Lists',
    pickListSeparatedPickListsHelp: `<p>
    Here the Pick Lists that were used using the <strong>Separate</strong> feature are displayed.
    of the payment system. They are similar to pending Pick Lists, but they already have their products
    separated in stock, only remaining to be used to actually supply the Point of Sale.
    </p>`,
    pickListSuppliedPickLists: 'Pick Lists Supplied',
    pickListSuppliedPickListsHelp: `<p>
    Here the Pick Lists that were used through the <strong>Restock</strong> feature are displayed.
    of the payment system. They are for consultation purposes only and have no effect on future
    supplies to points of sale.</p>`,
    separationDatePickLists: 'Separated in ',
    supplyDatePickLists: 'Supplied on ',
}