<template>
  <v-card class="mb-16">
    <v-card-title>
      <v-row justify="end" class="pa-3">
        <v-btn
          color="success"
          class="ml-2"
          @click.native="newPushNotification">
          <v-icon left>add</v-icon>Criar notificação
        </v-btn>
      </v-row>


    </v-card-title>
    <v-divider></v-divider>

    <push-notification-dialog
      v-model="itemDialog"
    >
    </push-notification-dialog>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-data-table
      :headers="headers"
      :items="notifications"
      :options.sync="options"
      :expanded.sync="expanded"
      must-sort
      show-expand
      @click:row="(item, props) => props.expand(!props.isExpanded)"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, 50, 100] }"
    >

    <template #[`header.data-table-expand`]>
              <v-btn icon>
                <v-icon small @click.native="onExpandClick">{{
                  expandAllIcon
                }}</v-icon>
              </v-btn>
              </template>
    <template #[`item.body`]="{ item }">
      {{ item.body != null ? item.body.substring(0, 25) + "...": "-"}}
    </template>

    <template #[`item.defaultNotification`]="{ item }">
        <v-icon v-if="item.defaultNotification">check_circle</v-icon>
    </template>

    <template #[`item.date`]="{ item }">
      {{ formatDateTime(item.date) }}
    </template>

    <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="ma-0 pa-0">
          <v-simple-table class="details-table blue-grey lighten-5 elevation-0">
            <tbody>
              <tr>
                <td class="caption"><strong> Título: </strong> {{ item.title }} </td>
                <td class="caption"><strong> Descrição: </strong> {{ item.body }} </td>
                <td class="caption"><strong> Usuários atingidos: </strong> {{ item.notifiedUsers }} </td>
                <td class="caption"><strong> Data: </strong> {{ formatDateTime(item.date) }} </td>
                <td class="caption">
                  <strong>
                    Notificação Padrão?
                  </strong>
                  <div>
                    {{ item.defaultNotification ? "Sim" : "Não" }}
                  </div>
                </td>
                <td class="caption">
                  <strong>
                    Imagem enviada:
                  </strong>
                  <v-img
                    class="py-2"
                    v-if="item.imageUrl != null"
                    :src="item.imageUrl"
                    max-width="150"
                    max-height="150"
                    contain
                  >
                  </v-img>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import PushNotificationDialog from './PushNotificationDialog.vue';

export default {
  components: { PushNotificationDialog },
  data(){
    return {
      expanded: [],
      itemDialog: false,
      headers: [
        { text: 'Código', sortable: true, value: 'id'},
        { text: 'Título', sortable: true, value: 'title'},
        { text: 'Descrição', sortable: true, value: 'body'},
        { text: 'Padrão', sortable: true, value: 'defaultNotification'},
        { text: 'Usuários atingidos', sortable: true, align: 'center', value: 'notifiedUsers'},
        { text: 'Data', sortable: true, value: 'date'}
      ],
      notifications: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      },
    }
  },
  mounted() {
    this.getNotifications();
  },
  computed: {
    isAllExpanded() {
      return (
        this.notifications.length > 0 &&
        this.expanded?.length === this.notifications.length
      );
    },
    expandAllIcon() {
      return this.isAllExpanded
        ? "mdi-arrow-collapse-all"
        : "mdi-arrow-expand-all";
    },
  },
  methods: {
    newPushNotification(){
      this.itemDialog = true;
    },
    formatDateTime(date) {
      return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.notifications;
    },
    getNotifications() {
      this.loading = true;
      const wasAllExpanded = this.isAllExpanded;
      this.axios.get(`/api/pushNotifications`)
      .then((response) => {
        this.notifications = response.data;
        this.loading = false;
        this.expanded = wasAllExpanded ? this.notifications : [];
      })
    },
  }
}
</script>
