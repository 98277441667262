export default {
    posStatus: 'Status dos PDVs',
    posStatusSubTitle:'Visualize o status de seus PDVs',
    totalPos: 'Total PDVs',
    onlineQuantity: 'Quantidade online',
    offlineQuantity: 'Quantidade offline',
    onlinePercent: 'Online(%)',
    offlinePercent: 'Offline(%)',
    noResults: 'Sem resultados',
    posStatusPercentage: 'Porcentagem',
    posStatusPercentageOnline: 'Online',
    posStatusPercentageOffline: 'Offline',
    posStatusPercentageAlert: 'Alerta',
    posStatusAmount: 'Quantidade',
    modeOnline: 'Online(%)',
    modeOffline: 'Offline(%)',
    quantityPos: 'PDV(s) no total',
    onlineColor: 'Verde',
    offlineColor: 'Vermelho',
    alertColor: 'Amarelo',
    onlineDescription: 'Terminal está operacional.',
    offlineDescription: 'Terminal totalmente fora de serviço por motivos como tela desligada, falta de conexão de internet/energia, avaria na máquina ou motivo desconhecido, porém com o problema persistente.',
    alertDescription: 'Terminal com questões como falta de energia, em processo de manutenção, perda de conexão à internet ou problema no leitor de cartão.',
    posStatusDescription: 'Visando assegurar uma experiência de pagamento eficiente, nosso sistema executa verificações de conectividade a cada cinco minutos. Os status são designados por cores',
    posStatusRecommendation: 'Se o seu terminal permanecer por um período prolongado em estado vermelho, recomendamos entrar em contato com nosso suporte técnico para assistência imediata.',
}    