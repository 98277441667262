export default {
    searchPageMobile: 'Search Page',
    favoritesMenuMobile: 'Favorites',
    telemetryMenuMobile: 'Telemetry',
    salesMenuMobile: 'Sales',
    stockMenuMobile: 'Stock',
    generalMenuMobile: 'General',
    registersMenuMobile: 'Registers',
    crmMenuMobile: 'CRM',
    securityMenuMobile: 'Security',
    financialMenuMobile: 'Financial',
    savedPagesMenuMobile: 'There are no pages saved',
    favoritePageMobile: 'Add to favorites',
    toRemovefavoritePageMobile: 'Remove from favorites',
  };