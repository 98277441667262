export default {
  searchBoxManagement: 'Pesquisar',
  boxesManagement: 'Dispositivos',
  noResultsBoxManagement: 'Sem resultados',
  idBoxManagement: 'ID',
  pdvBoxManagement: 'PDV',
  acquirerBoxManagement: 'Modelo',
  versionBoxManagement: 'Versão',
  passwordBoxManagement: 'Senha',
  notFoundMasterBarcode: 'Código administrador não encontrado',
};
