export default{
  alerts:{
    title: "Alerts",
    subtitle: "Add a new email for alert",
    form:{
      email: "Email",
    },
    table:{
      title: "Registered alerts",
    },
    actions:{
      remove: {
        message: "Are you sure you want to remove the email?",
      },
    },
    help:{
      title: "Alerts",
      description: `<p>Stay updated on the status of payment terminals.
      Register your email below to be notified in case of a significant service drop.</p>
      <strong>Attention</strong>: Use an active email to ensure notifications reach you.`,
    }
  },
}