
import Vue from 'vue';
import FranchiseDialogHelp from '@/views/franchisee/components/FranchiseDialogHelp.vue';
import MonthSelectorFilter from '@/components/filters/MonthSelectorFilter.vue';
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import { FranchiseEnvironment } from '@/api/agent';
import moment from 'moment';

export default Vue.extend({
  components: {
    FranchiseDialogHelp,
    DownloadDialog,
    MonthSelectorFilter
  },
  props: {
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      show: false,
      valid: false,
      loading: false,
      form: {
        minDate: '',
        maxDate: '',
        month: null as number | null,
      },
      downloadDialog: {
        show: false,
        fileName: ""
      },
      translations: {
        data: {
          title: this.$t('franchisee.data.title').toString(),
        },
        report: {
          response: {
            excel: {
              error: this.$t('report.response.excel.error').toString(),
            },
          }
        },
        button: {
          close: this.$t('buttons.close').toString(),
          excel: this.$t('buttons.excel').toString(),
          closing: this.$t('franchisee.data.title').toString(),
        }
      }
    };
  },
  watch:{
    'form.month': {
      handler: function(month: number | null) {
        if (month === null) {
          this.form.minDate = '';
          this.form.maxDate = '';
        }else{
          const year = moment().year();
          const minDate = moment([year, month - 1]).startOf('month');
          const maxDate = moment([year, month - 1]).endOf('month');
          this.form.minDate = minDate.format('YYYY-MM-DD');
          this.form.maxDate = maxDate.format('YYYY-MM-DD');
        }
        this.valid = !!this.form.month;
      },
    }
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.resetForm();
    },
    resetForm() {
      this.form.minDate = '';
      this.form.maxDate = '';
      this.form.month = null;
      this.valid = false;
    },
    async getExcel() {
      this.loading = true;
      let params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate
      };
      try {
        const response = await FranchiseEnvironment.getFranchiseeAmbientExcel(params);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      } 
      catch(error){
        (this as any).$refs.feedback.handleError(this.translations.report.response.excel.error, error);
      }
      finally {
        this.loading = false;
      }
    }
  }
});
