
import Vue from 'vue';
import TopSellingProducts from './TopSellingProductsNew.vue';
import NewMarketAverageTicket from './NewMarketAverageTicket.vue';
import ProductSalesInfo from '@/views/dashboard/MarketIntelComponents/ProductSalesInfo.vue';
import BillingByResidents from '@/views/dashboard/MarketIntelComponents/BillingByResidents.vue';

export default Vue.extend({
  props: {
    mustUpdate: { type: Boolean, required: true },
    activeTab: { type: Number, required: true },
    form: { type: Object, required: true },
  },
  components: {
    TopSellingProducts,
    NewMarketAverageTicket,
    ProductSalesInfo,
    BillingByResidents
  },
  computed: {
    mustCallEndpoints(): boolean {
      return this.mustUpdate && this.activeTab == 2
    }
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.$nextTick(() => this.$emit('updated'))
        }
      },
      immediate: true
    }
  }

})
