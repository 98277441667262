<template>
  <v-container fluid>
    <v-layout wrap fill-height>
      <v-flex xs12>
        <iframe width="100%" height="800px" id="swaggerFrame"></iframe>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    mounted() {
      this.axios.get('swagger/')
            .then((response) => {
              var dummy = document.createElement('html');
              dummy.innerHTML = response.data;
              var base = document.createElement("base");
              base.setAttribute("href", this.axios.defaults.baseURL + 'swagger/');
              var head = dummy.getElementsByTagName("head")[0];
              head.insertBefore(base, head.firstChild);
              var doc = document.getElementById('swaggerFrame').contentWindow.document;
              doc.open();
              doc.write(dummy.innerHTML);
              doc.close();
            }).catch((error) => {
              console.log(error);
            });
    }
  };
</script>