<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
      <youtube v-if="videoId" :width="videoWidth" :height="videoHeight" :id="videoId"></youtube>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import YouTube from '@/components/YouTube'
export default {
  props: {
    title: { type: String, required: true },
    videoId: { type: String, required: false }
  },
  data() {
    return {
      videoWidth: 840,
      videoHeight: 473
    }
  },
  components: {
    'youtube': YouTube
  }
}
</script>