export default {
    planogramMainHelpTitle: 'Planograms by POS',
    introParagraphHelp: 'This page lists the active, pending and draft planograms associated with each point of sale. In addition, it allows batch actions for planograms, such as copying a planogram to several POS, adding an item to several planograms and modifying the price of a product in several planograms',
    headingHelp: 'Copying a planogram to several POS',
    descriptionPosHelp: 'Select the target POS, and click on the Planograms button. Check the "Copy from another planogram" option, and select the point of sale and source planogram. Then click Create',
    additionalInfoHelp: 'Planograms are created in draft mode so that any changes can be made. They must therefore be finalized before they can be activated at the POS. You can only select POS that do not already have planograms in draft or pending mode',
    addSeveralHelp: 'Adding an item to several planograms',
    selectPosHelp: 'Select the POS where you want to do this operation, and click on the add items button. The POS selected must have an active or draft planogram. If the POS already has a draft planogram, the item will be added to that planogram. If it only has an active planogram, the item will be added to a new draft planogram which is a copy of the active planogram.',
    modifyingProductHelp: 'Modifying a product in several planograms',
    selectingProductsToModifyHelp: 'Select the POS where the products will be modified, and click on the edit Items button. The POS selected must have an active or draft planogram. If the POS already has a draft planogram, the products will be updated in this planogram. If it only has an active planogram, the products will be modified in a new draft planogram which is a copy of the active planogram.',
};    
