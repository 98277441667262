
import Vue from "vue";
import CustomersTenants from "./CustomersTenants.vue";
import CustomersGroups from "./CustomersGroups.vue";

// The correct name should be TenantsMaster.vue, but tenant is a vue reserved word.

export default Vue.extend({
  data() {
    return {
      active: 0,
    };
  },
  components: {
    CustomersTenants,
    CustomersGroups
    },
});
