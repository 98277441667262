export default {
  telemetry: {
    title: 'Telemetry',
    deviceStatus: {
      active: 'Active',
      inactive: 'Inactive',
      notConnected: 'Not Connected',
      unknown: 'Unknown',
    },
    lastUpdate: 'Last update',
    code: 'Code',
    version: 'Version',
    serial: 'Serial',
    system: 'System',
    outdatedVersion: 'Outdated version',
    status: {
      offline: 'Offline',
      online: 'Online',
      alert: 'Alert',
      noPower: 'No power',
      noPowerConnected: 'Payment system not connected to power',
      outOfService: 'Out of service',
      maybeOutOfService: 'Possible maintenance or machine refueling',
      damagedMachine: 'Machine with damage',
      screenOff: 'Screen off',
      cardReaderFail: 'Check card reader',
      maybeCardReaderFail: 'Possible problem with the card reader',
      disconnected: 'No internet connection',
      maybeDisconnected: 'Possible connection drop',
      deadBattery: 'No connection due to lack of power',
      noLauncher: 'Application is not the default launcher',
      unknownReason: 'Unknown reason',
      last: '{time} ago'
    },
    actions: {
      edit: 'Edit',
      replenish: 'Replenish',
      restartTerminal: 'Restart terminal',
      pushNotification: 'Push notification',
      unlockDoor: 'Unlock a lock',
      remoteCredit: 'Send remote credit',
    },
    tooltips: {
      help: 'Go to help video',
      noPlanogram: 'No planogram registered',
      activePlanogram: 'Active planogram',
      pendingPlanogram: 'Pending planogram',
      lastSale: 'Last sale {time} ago',
      belowMinimumCritical: '{quantity} products below the critical minimum',
    },
    card: {
      sales: {
        today: 'Today',
        sevenDays: '7 days',
        thirtyDays: '30 days',
        period: 'Period',
        quantity: 'Qty.',
        price: 'Value',
        health: 'Current sales performance: {performance}',
        last: '{time} ago',
        seeOf: 'View transactions from {time}',
        performance: {
          bad: 'Bad',
          average: 'Regular',
          good: 'Good',
        },
      },
      stock: {
        percentage: '{percentage}% stock',
        products: '{count} products',
      },
    },
    help: {
      title: 'Point of sale telemetry',
      description: `<p>
      All points of sale are displayed here as a card for each POS. 
      The colored bar at the top of the card indicates the availability of the POS. 
      When it's <span class="green--text">green</span>, the POS is operating correctly.
      <span class="orange--text">Orange</span> indicates that something is probably wrong.
      <span class="red--text">Red</span> indicates that the POS is unavailable.
    </p>
    <p>
      Next to the POS name and location, you can send <strong>remote credits</strong> 
      (<i class="mdi mdi-cash"></i>) and check the <strong>planograms</strong> 
      (<i class="mdi mdi-view-comfy"></i>) of the POS.
      The planogram button is colored according to its status.
    </p>
    <p>
      Further down the card, we have a table showing the quantity and value of products sold
      today, in the past 7 days, and in the past 30 days. Next to this table, we have other information:
      <strong>signal level</strong>, <strong>stock level</strong>, number
      of products below the <strong>critical minimum</strong>, how long ago the 
      <strong>last purchase</strong> was, and the <strong>current sales performance</strong>, respectively.
      The current performance is calculated based on the total items sold in the last few hours, 
      compared to the average sales on the same days of the week and at the same time.
    </p>
    <p>
      By expanding the card by clicking on <i class="mdi mdi-chevron-down"></i>, you see a chart of 
      <strong>sales by hour</strong> for the current day of the week. The blue curve (average) represents the 
      average sales per hour on the current weekday, while the orange segment represents the current hourly sales.
    </p>
    <p>
      Below the sales by hour chart, we have the option to <strong>replenish</strong> the point of sale.
    </p>`
    }
  },
}