export default {
  products: {
    title: 'Products',
    data: {
      purchaseSettings: {
        conversionUnits: {
          title: 'Conversion Units',
          box: 'Box',
          unit: 'Unit',
          package: 'Package',
          bag: 'Bag',
          unknown: 'Unknown'
        }
      },
      description: 'Product Description',
      code: 'Code',
      unitValue: 'Unit Value',
    },
    register: {
      select: 'Select a product',
      isSelect: 'Product already selected',
      notRegistred: 'Don\'t have this product registered?',
    },
    help: {
      description: `
        <p>Manage your products by viewing information such as image, ID, Internal Code (SKU), description, category, standard price, Barcode (EAN), creation date, and availability (Public or Private). You can add, edit, delete products, and export the data to Excel.</p>
        <p><strong>For public products, only the network/licensor can edit and delete them. You can use and export the data to Excel, but without the possibility of modification.</strong></p>
  
        <p><strong>Categories:</strong></p>
        <p>Displays the ID, category name, and the number of products. You can delete categories as needed.</p>
  
        <p><strong>Groupings:</strong></p>
        <p>Group similar products into a single item. Displays ID, description, category, standard price, and creation date, without including Barcode (EAN) or the option to export to Excel. Ideal for managing variations of the same product, such as different flavors or sizes.</p>
      `,
    },
  }
}