
import Vue from 'vue';
import agent from '@/api/agent';
import { FormCreatePickList } from '@/types/PickLists';
import { Category } from '@/types/ProductsTypes';

const formInitialState = {
  critical: false,
  groupByCategory: false,
  secondary: false,
  description: '',
  includeOnlyAvailableInventory: false
} as FormCreatePickList;

export default Vue.extend({
  props: {
    selectedPos: {type: Array, required: true},
    purchaseSuggestions: {type: Array, required: false}
  },
  data() {
    return {
      show: false,
      valid: true,
      creating: false,
      creationType: 'all' as string,
      categories: {
        loading: true,
        items: [] as Array<Category>,
        selected: [] as number[] 
      },
      form: {
        critical: false,
        groupByCategory: false,
        secondary: false,
        description: '' as string,
        includeOnlyAvailableInventory: false,
      } as FormCreatePickList,
      userHasEditedName: false,
      distributionCenterEnable: false,
    };
  },
  computed: {
    disableGroupByCategory() :boolean {
      return !this.form.secondary || this.creationType != 'category' || this.categories.selected.length == 0;
    },
    disableDescriptionTextField(): boolean {
      return this.form.groupByCategory;
    }
  },
  watch: {
    show: function (enabled) {
      if (enabled) {
        this.valid = true;
        this.creationType = 'all';
      } else {
        (this as any).$refs.pickListForm.reset();
      }
    },
    disableGroupByCategory(value) {
      if (value == true) {
        this.form.groupByCategory = false;
      }
    },
    'categories.selected'(value) {
      if (this.form.groupByCategory == false && this.userHasEditedName == false) {
        const categoriesNames = this.categories.items.filter(c => value.includes(c.id)).map(c => c.name);
        this.form.description = categoriesNames.join(", ");
      }
    },
  },  
  mounted() {
    this.getCategories();
    this.verifyDistributionCenterEnable();
  },
  methods: {
    getCategories(){
      agent.Products.getProductsCategory().then((response) => {
        this.categories.items = response;
      }).finally(() => {
        this.categories.loading = false;
      });
    },
    createPickLists() {
      this.creating = true;
      if (this.form.critical == null) this.form.critical = false;
      const request = {
        pointOfSaleIds: this.selectedPos,
        pointOfSaleId:null as number|null, 
        productCategoryIds: this.creationType === 'category' ? this.categories.selected : null,
        critical: this.form.critical,
        isSecondary: this.form.secondary,
        description: this.form.description,
        purchaseSuggestions: this.purchaseSuggestions,
        groupByCategory: this.form.groupByCategory,
        includeOnlyAvailableInventory: this.form.includeOnlyAvailableInventory
      };
      if (this.purchaseSuggestions) {
        request.pointOfSaleId = this.selectedPos[0] as number;
        
        agent.PickLists.createPickList(request)
          .then(() => {
            this.creating = false;
            this.show = false;
            this.$emit('pick-list-creation');
          })
          .catch((error) => this.$emit("single-picklist-creation-error", error))
          .finally(() => this.creating = false);
      } else {
        agent.PickLists.createPickListBulk(request)
          .then((response) => {
            this.creating = false;
            this.show = false;
            this.$emit('pick-list-creation', response);
          })
          .catch((error: any) => this.handleError(this.$t('pickLists.create.error').toString(), error))
          .finally(() => Object.assign(this.form, {...formInitialState}));
      }
    },
    handleError(message: string, error: any) {
      (this as any).$refs.feedback.handleError(message, error);
    },
    verifyDistributionCenterEnable() {
      const type = 'DistributionCenter';
      agent.Features.VerifyFeatureIsEnable(type).then((response) =>{
        this.distributionCenterEnable = response;
      }).catch(() => {
        this.distributionCenterEnable = false;
      });
    }
  }
});
