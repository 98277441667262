export default {
    productRegistration: 'Cadastro de produtos',
    codeProductHelp: 'Código',
    codeDescriptionProductHelp: 'Código interno do produto (opcional).',
    descriptionProductHelp: 'Descrição',
    descriptionDetailsProductHelp: ' descrição do produto. Aparecerá nos relatórios e identificará o produto para o usuário final do TouchPay Market.',
    categoryProductHelp: 'Categoria',
    categoryDetailsProductHelp: 'Categoria do produto (opcional). É útil para visualização em relatórios de venda e pode ser usada para gerar pick-lists para uma ou mais categorias específicas. O usuário final do TouchPay Market também tem a opção de buscar produtos por categoria.',
    defaultPriceProductHelp: 'Preço padrão',
    defaultPriceDetails: ' é o preço que aparecerá por padrão quando o produto for adicionado em um planograma. O preço do produto no ponto de venda é o preço definido do planograma, que pode ser diferente deste preço padrão.',
    PriceDetails: ' usado para identificar o produto no micro-market através do leitor de código de barras. Veja abaixo como cadastrar códigos de barras quando o preço do produto é variável.',
    ageRestrictedProductHelp: 'Proibido para menores',
    ageRestrictedDetails: ' quando esta opção estiver marcada, uma confirmação será exigida para o consumidor final do micro-market.',
    variablePriceBarcode: 'Código de barras com preço variável',
    variablePriceBarcodeDetails: 'Os produtos que possum o preço definido pelo código de barras, em geral seguem o seguinte padrão: Parte fixa seguida do preço em centavos seguida de um dígito verificador. Use o caractere # para os dígitos correspondentes ao preço, e o caractere * para o dígito verificador.',
    variablePriceBarcodeExample: 'Exemplo: se um código de barras de um produto for cadastrado como 2345678#####*, e for lido o código 2345678011575 no leitor do ponto de venda, o preço calculado será R$ 11,57.',
  };
  