export default {
    textFieldSearchText: "Buscar",
    deleteNonUsedButton: "Eliminar no utilizados",
    testNfceEmissionButton: "Probar emisión NFC-e",
    deleteTaxScenarioDeleteDialogText: "¿Estás seguro de que deseas eliminar este escenario fiscal?",
    cancelButtonDeleteDialog: "Cancelar",
    deleteButtonDeleteDialog: "Eliminar",
    dataTableNoDataText: "No hay datos de escenarios fiscales registrados",
    taxRateIcms: "Tasa de ICMS (%):",
    taxRatePis: "Tasa de PIS (%):",
    taxRateCofins: "Tasa de COFINS (%):",
    taxRateIpi: "Tasa de IPI (%):",
    taxBenefit: "Beneficio Fiscal:",
    headerIsInvoiceDataComplete: "Completo",
    headerId: "Id",
    headerName: "Nombre",
    headerTaxation: "Régimen Fiscal",
    headerState: 'Estado',
    headerCstIcms: 'CST-ICMS',
    headerCstPis: 'CST-PIS',
    headerCstCofins: 'CST-COFINS',
    headerCstIpi: 'CST-IPI',
    headerNumberOfProducts: 'Nº de Productos',
    headerActions: 'Acciones',
}
  