export default {
    lossesProductAndPosHelpTitle: `Indicador de perdas: Produtos/PDV's`,
    lossesProductAndPosHelpIntroduction: `Este indicador oferece uma análise detalhada das perdas financeiras associadas a produtos específicos ou pontos de venda, 
    permitindo uma identificação rápida dos principais fatores que contribuem para essas perdas.`,
    lossesProductAndPosHelpP1: `Por padrão, nosso sistema exibirá:
    <li> Informações de <strong>perdas por produtos</strong>. No entanto, ao selecionar <strong>"Visualizar por PDV"</strong>, o indicador mostrará as perdas por Ponto de Venda.</li>
    <li> Informações de perdas por <strong>motivo "Validade vencida"</strong>. Porém, ao selecionar outro <strong>"Motivo"</strong>, o indicador mostrará as perdas de acordo com o motivo selecionado.</li>`,
    lossesProductAndPosHelpProduct: `<strong>Top 5 Produtos com Maior Perda Financeira: </strong>
    Este relatório destaca os cinco produtos que estão causando as maiores perdas financeiras em seu inventário. 
    Identificar esses produtos pode ajudar a entender melhor as razões por trás das perdas e a tomar medidas corretivas, como ajustes de preço, 
    controle de estoque ou revisão de fornecedores.`,
    lossesProductAndPosHelpPos: `<strong>Top 5 Pontos de Venda com Maior Perda Financeira: </strong>
    Neste relatório, você encontrará os cinco pontos de venda que estão registrando as maiores perdas financeiras. 
    Isso pode ser indicativo de problemas operacionais ou de gestão que precisam ser abordados, como problemas de segurança, desperdício ou necessidade de treinamento da equipe.`,
    lossesProductAndPosHelpInf: `<strong>Importante: </strong>
    O filtro "Ponto de Venda", localizado na parte superior da página, estará disponível em breve. No entanto, o filtro por "Data" já está disponível para este card.`,
};