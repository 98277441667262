<template>
  <v-autocomplete
    prepend-icon="mdi-warehouse"
    :items="inventories"
    v-model="inventoryId"
    :label="$t('labelFiltersStock')"
    item-text="name"
    item-value="id"
    single-line
    clearable
    :multiple="multiple"
    :rules="rules"
  >
  </v-autocomplete>
</template>
<script>

export default {
  props: {
    resetComponent: {},
    value: {},
    inventoryTypeFilter: { type: String, default: null },
    multiple: { type: Boolean, default: false},
    rules: { type: Array },
  },
  data() {
    return {
      posInventories: [],
      centralInventories: []
    };
  },
  computed: {
    inventoryId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    inventories() {
      if (this.inventoryTypeFilter == null) {
        return this.centralInventories.concat(this.posInventories);
      }
      else if (this.inventoryTypeFilter === 'distributionCenter') {
        return this.centralInventories;
      }
      else if (this.inventoryTypeFilter === 'pointOfSale') {
        return this.posInventories;
      }
      return null;
    }
  },
  mounted() {
    //  Load dropdown when component is mounted.
    this.axios.get("api/web/inventory/", { params: { centralOnly: true } })
      .then((response) => {
        this.centralInventories = response.data;
      });
    this.axios.get("api/PointsOfSale", { params: { hasInventory: true, hideSecondary: true }}).then((response) => {
      this.$emit('pos-get', response.data);
      this.posInventories = response.data.map(i => {
        return { 
          id: i.inventoryId,
          name: `(PDV ${i.id}) ${i.localCustomerName} - ${i.localName}` + (i.specificLocation != null ? ` - ${i.specificLocation}` : '')
        }
      });
    })
  },
  watch: {
    resetComponent: function() {
      this.resetDropDown();
    },
    inventoryTypeFilter() {
      if (this.inventories.find(i => i.id === this.inventoryId) == null) {
        this.inventoryId = Array.isArray(this.inventoryId) ? [] : null;
      }
    }
  },
  methods: {
    resetDropDown() {
      this.inventoryId = null;
    },
  },
};
</script>