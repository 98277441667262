
import Vue from 'vue';
import TaxingBrazil from './TaxingBrazil/TaxingBrazil.vue';
import TaxingUsa from './TaxingUsa/TaxingUsa.vue';


export default Vue.extend({
  components: {
    TaxingBrazil,
    TaxingUsa
  }
})
