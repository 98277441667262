export default {
  products: {
    title: 'Produtos',
    data: {
      purchaseSettings: {
        conversionUnits: {
          title: 'Unidades de Conversão',
          box: 'Caixa',
          unit: 'Unidade',
          package: 'Fardo',
          bag: 'Pacote',
          unknown: 'Desconhecido'
        }
      },
      description: 'Descrição do Produto',
      code: 'Código',
      unitValue: 'Valor Unitário',
    },
    register: {
      select: 'Selecione um produto',
      isSelect: 'Produto já selecionado',
      notRegistred: 'Não tem esse produto cadastrado?',
    },
    help: {
      description: `
        <p>Gerencie seus produtos visualizando informações como imagem, ID, Código Interno (SKU), descrição, categoria, preço padrão, Código de Barras (EAN), data de criação e disponibilidade (Público ou Privado). É possível cadastrar, editar, excluir produtos e exportar os dados para Excel.</p>
        <p><strong>Para produtos públicos, apenas a rede/licenciadora poderá editá-los e excluí-los. Você pode utilizá-los e exportar os dados para Excel, mas sem a possibilidade de alteração.</strong></p>
    
        <p><strong>Categorias:</strong></p>
        <p>Exibe o ID, nome da categoria e a quantidade de produtos. Você pode excluir categorias conforme necessário.</p>
    
        <p><strong>Agrupamentos:</strong></p>
        <p>Agrupe produtos similares em um único item. Exibe ID, descrição, categoria, preço padrão e data de criação, sem incluir Código de Barras (EAN) ou a opção de exportar para Excel. Ideal para gerenciar variações do mesmo produto, como diferentes sabores ou tamanhos.</p>
      `,
    },
  }
}