export default {
  salesByDay: 'Total de ventas por día',
  topSellingPos: 'PDVs que más venden',
  topAndBottonSellingPos: 'PDVs que más y menos venden',
  bottomSellingPos: 'PDVs que menos venden',
  salesByPos: 'Total de ventas por PDV',
  salesByProduct: 'Ventas por producto',
  excel: 'Excel',
  totalLowerCase: 'Total',
  productDescription: 'Descripción',
  productCategory: 'Categoría',
  defaultPrice: 'Precio estándar',
  averagePrice: 'Precio medio',
  pointOfSaleSystem: 'Sistema de pago',
  modelManufacturer: 'Modelo / Fabricante',
  productNotRegistered: 'Productos no registrados',
  salesSummary: 'Resumen de ventas',
  loading: 'Cargando',
  salesReport: 'Informe de ventas',
  productReport: 'Informe de productos',
  totalSalesByPos: 'Total de ventas por PDV',
  productSales: 'Ventas por producto',
  searchPlaceholder: 'Buscar producto',
  quantity: 'Cantidad',
  value: 'Valor',
  location: 'Ubicación',
};
