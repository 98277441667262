<template>
  <v-expansion-panels hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <v-icon>filter_list</v-icon>
          Filtros e ações
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pb-2" eager>
        <v-form 
          ref="form" 
          v-model="valid"
          @submit.prevent="applyFilters">
          <v-row>
            <!-- <v-col cols="5" md="3" class="search-field pa-3">
              <point-of-sale-selector 
                prepend-icon="business"
                v-model="form.pointOfSaleIds"
                clearable
                >
              </point-of-sale-selector>
            </v-col> -->
            <v-col cols="5" md="3" class="search-field pa-3">
              <card-number-filter 
                v-model="form.cardNumber" >
              </card-number-filter>
            </v-col>
            <v-col cols="5" md="3" class="search-field pa-3">
              <cashless-costumer
                v-model="form.consumerIds">
              </cashless-costumer>
            </v-col>
            
            <v-col cols="5" md="3" class="search-field pa-3">
              <v-autocomplete
                prepend-icon='mdi-cash'
                v-model="form.types"
                :items="cashlessOperations"
                clearable
                :label="'Tipo de operação'"
                single-line
                hide-details>
              </v-autocomplete>
            </v-col>

            <v-col cols="5" md="3" class="search-field pa-3">
              <date-picker 
                v-model="form.date" 
                :clear="clearDate"
                @clearComplete="clearDate = false"
                @modify="filterDateChanged">
              </date-picker>
            </v-col>
          </v-row>
          <v-row class="pa-2" justify="end">
            <v-btn 
              class="ma-1" 
              color="error" 
              text 
              @click.native="clearFilters">
              <v-icon left>clear</v-icon>
              Limpar
            </v-btn>

            <v-btn 
              :disabled="!valid" 
              class="ma-1" 
              color="info"  
              @click.native="getExcel"
              :loading="excelLoading">
              <v-icon left>mdi-file-excel</v-icon>Excel
            </v-btn>
            
            <v-btn 
              :disabled="!valid" 
              class="ma-1" 
              color="success" 
              v-on:click.native="applyFilters">
              <v-icon left>refresh</v-icon>
              Atualizar
            </v-btn>
          </v-row>        
        </v-form>
        <feedback ref="feedback"></feedback>

        <download-dialog
          v-model="downloadDialog.show"
          :fileName="downloadDialog.fileName"
          @update="(value) => downloadDialog.show = value"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// import PointOfSaleSelector from '@/components/PointOfSaleSelector.vue';
import CardNumberFilter from '@/components/filters/CardNumberFilter.vue';
import CashlessCostumer from '@/components/filters/CashlessCustomer.vue';
import { cashlessOperationTypes } from '@/constants/CashlessOperation';
import DatePicker from '@/components/filters/DatePicker.vue';
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import { CashlessTransactions } from '@/api/agent';
import moment from 'moment';

const newForm = {
  minDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
  maxDate: moment().format('YYYY-MM-DD'),
  date: [],
  pointOfSaleIds: null,
  cardNumber: null,
  consumerIds: null,
  types: null,
}

export default {
  components: {
    // PointOfSaleSelector,
    CardNumberFilter,
    CashlessCostumer,
    DatePicker,
    DownloadDialog,
  },
  data() {
    const cashlessOperations =[
        { text: cashlessOperationTypes["AddCredit"], value: "AddCredit" },
        { text: cashlessOperationTypes["Purchase"], value: "Purchase" },
        { text: cashlessOperationTypes["Recharge"], value: "Recharge" },
        { text: cashlessOperationTypes["RemoveCredits"], value: "RemoveCredits"},
      ];
    return {
      valid: false,
      form: {},
      downloadDialog: {
        show: false,
        fileName: ""
      },
      excelLoading: false,
      cashlessOperations: cashlessOperations,
      clearDate: false
    };
  },
  mounted() {
    Object.assign(this.form, newForm);
    this.applyFilters();
  },
  methods: {
    filterDateChanged(date) {
      [this.form.minDate, this.form.maxDate] = date
    },
    applyFilters() {
      this.$emit('apply-filters', this.form);
    },
    clearFilters() {
      this.form = { ...newForm }
      this.clearDate = true;
    },
    async getExcel() {
      this.excelLoading = true;
      let params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,          
        pointOfSaleIds: this.form.pointOfSaleIds ?? [],
        cardNumber: this.form.cardNumber,
        consumerIds: this.form.consumerIds ?? [],
        types: this.form.types ?? [],
      }
      try {
        const response = await CashlessTransactions.getExcel(params);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      } catch (error) {
        this.$refs.feedback.handleError("Houve um erro ao gerar o relatório", error)
      } finally {
        this.excelLoading = false;
      }
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
  },
};
</script>
