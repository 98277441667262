<template>
  <v-autocomplete ref="autocomplete" v-model="productId" :items="products" :loading="isLoading"
    :search-input.sync="search" item-text="searchText" item-value="id" :label="labelProductFilter"
    :placeholder="placeholderEnterTheSearchFilter" :prepend-icon="icon" :required="required"
    :no-data-text="noDataEnterInTheSearchFilter" :rules="rules" :disabled="disabled" :class="displayClass" 
    @change="changeProduct">
  </v-autocomplete>
</template>

<script>
import agent from '@/api/agent';
export default {
  name: "ProductDiscountFilter",
  props: {
    itemId: { type: Number, required: true },
    value: { type: String, default: null },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    icon: { type: String, default: '' },
    required: { type: Boolean, default: false },
    noData: { type: String, default: null },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    displayClass: { type: String, default: '' },
    discountProducts: { type: Array, required: true },
  },
  computed: {
    productId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    labelProductFilter() {
      return this.label || this.$t('productFilter');
    },
    placeholderEnterTheSearchFilter() {
      return this.placeholder || this.$t('enterTheSearchFilter');
    },
    noDataEnterInTheSearchFilter() {
      return this.noData || this.$t('enterInTheSearchFilter');
    },
  },
  data() {
    return {
      products: [],
      productObject: {},
      isLoading: false,
      search: null,
      options: {
        page: 1,
        pageSize: -1,
        sortOrder: "description",
      },
      translation: {
        errorSearch: this.$t('errorSearchProduct'),
      },
    }
  },
  mounted() {
    if (this.discountProducts && this.discountProducts.length > 0) {
      const product = this.discountProducts.find(product => product.id === this.productId);
      if (!product) {
        return;
      }
      product.searchText = (product.code !== null ? product.code + " - " : "") + product.description;
      this.products.push(product);
    }
  },
  rules: {
    nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
    required: (v) => !!v || this.$t('requiredRules'),
  },
  watch: {
    search(val) {
      if (/^\s+/.test(val)) {
        this.productId = null;
        return;
      }
      if (val?.length >= 3 && val != this.initialSearch) {
        this.productsBySearch(val);
      }
    },
    product(value) {
      if (value != null && this.products.find(product => product.id == value) === undefined) {
        this.productById(value);
      }
    },
    discountProducts: {
      handler: function (incomingProducts) {
        if (incomingProducts.length === 0) {
          return;
        }
        const products = [...incomingProducts].filter(product => product.id !== this.productId)
          .filter(product => !product.assigned);
        this.products = [...this.products, ...products];
      },
      deep: true
    },
  },
  methods: {
    productsBySearch(value) {
      const productFound = this.products.find(p => p.searchText === value);
      if (productFound) {
        return;
      }
      this.isLoading = true;
      const params = {
        ...this.options,
        search: value,
      };
      agent.Products.simpleInfo(params)
      .then((response) => {
        const products = response.items.map((product) => {
          product.id = `${product.id}`;
          product.searchText = (product.code !== null ? product.code + " - " : "") + product.description;
          return product;
        });
        this.products = [...products];
        this.$emit('products-searched', this.products);
      })
      .catch((error) => {
        this.handleError(this.translation.errorSearch, error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    changeProduct() {
      this.$nextTick(() => {
        if (this.value != null) {
          const productObject = this.products.find(product => product.id === this.value);
          productObject.itemId = this.itemId;
          this.$emit('product-changed', productObject);
          this.productObject = productObject;
        }
      })
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
  },
}
</script>
