export default {
	transactions: {
		title: 'Transactions',
		status: {
			paid: 'Paid',
			cancelled: 'Cancelled',
			paymentError: 'Payment Error',
		},
		internalCode: 'Internal Code',
		discountCoupon: 'Discount Coupon',
		errorMessage: 'Error Message',
		report: {
			dynamic: {
				title: 'Dynamic Sales Summary',
				help: {
					description: `
						<p><strong>On this page, you can generate custom sales reports according to your needs. Use the following features to configure your reports:</strong></p>
						<p><strong>Filters and Actions:</strong> Select the desired period using the start and end dates. Additionally, you can choose fixed intervals or even the same day.</p>
						<p><strong>Location:</strong> Use this filter to choose locations of interest. This may include different Points of Sale (POS) or other relevant locations.</p>
						<p><strong>Product:</strong> Select specific products or categories to detail the report according to your analysis needs.</p>
						<p><strong>Data Grouping:</strong> Choose how you want to organize the data in the report. Grouping options include Products, Category, Customers, Points of Sale (POS), States, Payment Methods, Card Brands, Day, Month, Day of the Week, or Hour.</p>
						<p><strong>Save Filters:</strong> Save your filter preferences for future use, speeding up the process.</p>
						<p><strong>Report Export:</strong> After configuring the filters according to your needs, you can export the report in Excel format by clicking the "Excel" button.</p>
						<p><strong>Remember to click "Update" after adjusting the filters to view the most recent information.</strong></p>
					`,
				}
			}
		}		
	}
}
