export default {
    okPosMachineStatus: 'En línea',
    noLauncherPosMachineStatus: 'La aplicación no es el lanzador predeterminado',
    noPowerPosMachineStatus: 'El sistema de pago no está conectado a la energía',
    maybeOutOfServicePosMachineStatus: 'Posible mantenimiento o abastecimiento de la máquina',
    maybeDisconnectedPosMachineStatus: 'Posible pérdida de conexión',
    maybeCardReaderFailPosMachineStatus: 'Posible problema con el lector de tarjetas',
    outOfServicePosMachineStatus: 'Motivo desconocido',
    screenOffPosMachineStatus: 'Pantalla apagada',
    cardReaderFailPosMachineStatus: 'Fallo del lector de tarjetas',
    disconnectedPosMachineStatus: 'Sin conexión a internet',
    outOfServiceNoPowerPosMachineStatus: 'Sin energía',
    outOfServiceMachinePosMachineStatus: 'Máquina averiada',
    deadBatteryPosMachineStatus: 'Sin conexión debido a falta de energía',
};
