export default {
    purchaseDetails: 'Compra',
    purchaseDetailsIdInERP: 'Id en ERP: ',
    purchaseDetailsNoteNumber: 'Número de la nota: ',
    purchaseDetailsReceiptDate: 'Fecha de recepción: ',
    purchaseDetailsInventory: 'Inventario: ',
    purchaseDetailsTotalNoteValue: 'Valor total de la nota: ',
    purchaseDetailsTotalPurchaseCost: 'Costo total de la compra: ',
    purchaseDetailsQuantity: 'Cantidad: ',
    purchaseDetailsUnits: ' unidades',
    purchaseDetailsSearchProduct: 'Buscar producto',
    purchaseDetailsUndo: 'Deshacer',
    purchaseDetailsClose: 'Cerrar',
    purchaseDetailsHeadersID: 'ID',
    purchaseDetailsHeadersProduct: 'Producto',
    purchaseDetailsHeadersCategory: 'Categoría',
    purchaseDetailsHeadersCode: 'Código',
    purchaseDetailsHeadersQuantity: 'Cant.',
    purchaseDetailsHeadersNoteValue: 'Valor total de la nota ',
    purchaseDetailsHeadersProductTotalCost: 'Costo total del producto ',
    purchaseDetailsErrorFetching: 'Error al buscar compras',
    purchaseDetailsErrorUndo: 'Error al deshacer compra',
    purchaseDetailsPurchaseCanceled: 'Compra cancelada',
  };
  