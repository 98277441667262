
import Vue from 'vue';
import { displayDateFormatted } from "@/utility/TextFormatting";
import PointOfSalesMultipleSelectorVue from '@/components/filters/PointOfSalesMultipleSelector.vue';
import moment from "moment";
import TimePicker from '@/components/filters/TimePicker.vue';
import ClearButton from '@/components/buttons/ClearButton.vue';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
import {Country, DateTimePrecision} from "@/constants/enums";

const newForm = {
  minDate: moment().subtract(2, 'days').format("YYYY-MM-DD"),
  maxDate: moment().format("YYYY-MM-DD"),
  minTime: moment().startOf("day").format("HH:mm"),
  maxTime: moment().endOf("day").format("HH:mm"),
  posIds: [] as number[]
}

export default Vue.extend({
  components: {
    'point-of-sale-selector': PointOfSalesMultipleSelectorVue,
    TimePicker,
    ClearButton,
    UpdateButton
  },
  data() {
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: "",
        maxDate: "",
        minTime: "",
        maxTime: "",
        posIds: [] as number[]
      },
      minDateMenu: false,
      maxDateMenu: false,
      today: moment().format('YYYY-MM-DD'),
      sixMonthsAgo: moment().subtract(6, 'months').format('YYYY-MM-DD')
    };
  },
  computed: {
    formattedMinDate: function (): string {
      return displayDateFormatted(this.form.minDate, this.country, false, DateTimePrecision.DateOnly);
    },
    formattedMaxDate(): string {
      return displayDateFormatted(this.form.maxDate, this.country, false, DateTimePrecision.DateOnly);
    },
    country(): Country {
      return (this as any).$auth.user().tenantCountry as Country;
    },
  },
  mounted() {
    Object.assign(this.form, newForm);
    (this.$refs.form as any).resetValidation();
  },
  methods: {
    emitModification() {
      if ((this.$refs.form as any).validate()) {
        this.$emit("modify", this.form);
      }
    },
    clearQuery() {
      Object.assign(this.form, newForm);
    },
    dateRangeRule() {
      const dateDiff = moment(this.form.maxDate).diff(this.form.minDate, 'days')
      return dateDiff <= 2 && dateDiff >= 0 ? true : this.$t('filters.timelineDateAlert');
    },
  },
})
