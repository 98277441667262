<template>
  <div>
    <v-list style="padding-top: 23px;">
      <v-list-item class="button-list-item" style="padding-bottom: 23px;">
        <a class="button-content" href="/"><img class="touchpay-logo" src="../../public/assets/touchpay-logo.svg"/></a>
      </v-list-item>
      <v-list-item v-if="$vuetify.breakpoint.xlOnly">
        <!-- A blank list item to have space between logo and list, when viewport is extra large -->
      </v-list-item>
      <v-list-item class="button-list-item">
        <v-dialog
          v-model="searchPageDialog"
          width="auto"
        >
          <template #activator="{ on: dialog, attrs }">
            <v-tooltip right>
              <template #activator="{ on: tooltip}">
                <v-btn
                  v-on="{ ...tooltip, ...dialog }"
                  v-bind="attrs"
                  height="60"
                  width="60"
                  depressed
                  color="white"
                >
                  <span class="button-content pa-2">
                    <v-icon width="30" height="30">search</v-icon>
                    <span class="pt-1" style="color: #000000">{{ $t('searchMenu')}}</span>
                  </span>
                </v-btn>
              </template>
              <span>{{ $t('searchPage') }}</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>{{ $t('searchPage') }}</v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="pageToGo"
                :items="availablePages"
                item-text="name"
                item-value="route"
                clearable
                @input="navigateToPage"
                autofocus
                :label="$t('searchPage')"
                prepend-inner-icon="search"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-list-item>
      <v-list-item class="button-list-item">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" height="60" width="60" depressed color="white">
              <span class="button-content pa-2">
                <v-icon width="30" height="30" color="amber">mdi-star-outline</v-icon>
                <span class="pt-1" style="color: #000000">{{ $t('favoritesMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list style="overflow-y: auto; max-height: 90vh">
            <v-list-item v-for="page in favoritePages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="favoritePages.length == 0">
              <v-list-item-content style="color: rgba(0, 0, 0, 0.38);">{{ $t('savedPagesMenu') }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsTelemetry || userIsRemoteMonitoring">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn id="telemetryPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'telemetryPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'telemetryPages'" src="../../public/assets/nav-drawer-icons/icon-01-telemetria.svg" alt="" width="30" height="30">
                <img v-else src="../../public/assets/nav-drawer-icons/icon-01-color.svg" alt="" width="30" height="30">
                <span class="pt-1" :style="activeGroup != 'telemetryPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('telemetryMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.telemetryPages} !important;`" dark>
            <v-list-item v-for="page in telemetryPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="showSalesButton">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn id = "salesPage-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'salesPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'salesPages'" src="../../public/assets/nav-drawer-icons/icon-02-vendas.svg" alt="" width="30" height="30"/>
                <img v-else src="../../public/assets/nav-drawer-icons/icon-02-color.svg" alt="" width="30" height="30"/>
                <span class="pt-1" :style="activeGroup != 'salesPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('salesMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.salesPages} !important;`" dark>
            <v-list-item v-for="page in salesPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsSupplier">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs}">
            <v-btn id="logisticsPages" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'logisticsPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'logisticsPages'" src="../../public/assets/nav-drawer-icons/icon-03-estoque.svg" alt="" width="30" height="30"/>
                <img v-else src="../../public/assets/nav-drawer-icons/icon-03-color.svg" alt="" width="30" height="30"/>
                <span class="pt-1" :style="activeGroup != 'logisticsPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('stockMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.logisticsPages} !important;`" dark>
            <v-list-item v-for="page in logisticsPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="!userIsRemoteMonitoring">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn id="generalPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'generalPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'generalPages'" src="../../public/assets/nav-drawer-icons/icon-04-dashboard.svg" alt="" width="30" height="30"/>
                <img v-else src="../../public/assets/nav-drawer-icons/icon-04-color.svg" alt="" width="30" height="30"/>
                <span class="pt-1" :style="activeGroup != 'generalPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('generalMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.generalPages} !important;`" dark>
            <v-list-item v-for="page in generalPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsRegister">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs}">
            <v-btn id="registerPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'registerPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'registerPages'" src="../../public/assets/nav-drawer-icons/icon-05-cadastros.svg" alt="" width="30" height="30">
                <img v-else src="../../public/assets/nav-drawer-icons/icon-05-color.svg" alt="" width="30" height="30">
                <span class="pt-1" :style="activeGroup != 'registerPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('registersMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.registerPages} !important;`" dark>
            <v-list-item v-for="page in registerPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsAppAdministrator && isBrazil">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs}">
            <v-btn id="crmPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'crmPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'crmPages'" src="../../public/assets/nav-drawer-icons/icon-06-app.svg" alt="" width="30" height="30">
                <img v-else src="../../public/assets/nav-drawer-icons/icon-06-color.svg" alt="" width="30" height="30">
                <span class="pt-1" :style="activeGroup != 'crmPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('crmMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.crmPages} !important;`" dark>
            <v-list-item v-for="page in crmPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsSecurity || userIsRemoteMonitoring">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs}">
            <v-btn id="securityPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'securityPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup != 'securityPages'" src="../../public/assets/nav-drawer-icons/icon-07-seguranca.svg" alt="" width="30" height="30">
                <img v-else src="../../public/assets/nav-drawer-icons/icon-07-color.svg" alt="" width="30" height="30">
                <span class="pt-1" :style="activeGroup != 'securityPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('securityMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.securityPages} !important;`" dark>
            <v-list-item v-for="page in securityPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2" :id="page.name.replace(/ /g, '-')">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="userIsManager && isBrazil">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs}">
            <v-btn id="billingPages-button" v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup === 'billingPages'">
              <span class="button-content pa-2">
                <img v-if="activeGroup !== 'billingPages'" src="../../public/assets/nav-drawer-icons/icon-08-financeiro.svg" alt="" width="30" height="30">
                <img v-else src="../../public/assets/nav-drawer-icons/icon-08-color.svg" alt="" width="30" height="30">
                <span class="pt-1" :style="activeGroup !== 'billingPages' ? 'color: #b1b1b1;' : 'color: #000000'">{{ $t('financialMenu') }}</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.billingPages} !important;`" dark>
            <v-list-item flat>
              <v-list-item-content class="subtitle-2" style="cursor: pointer" @click="newBillingAccessDialog = true">{{ $t('routes.nameMyBills') }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item class="button-list-item" v-if="$tenants.hasClaim($auth.user(), 'master' || 'pagseguro')">
        <v-menu offset-x open-on-hover>
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" height="60" width="60" depressed color="white" :input-value="activeGroup == 'amlabsPages'">
              <span class="button-content pa-2">
                <img class="amlabs-icon" src="../../public/assets/icons/amlabs-icon.png" alt=""/>
                <span class="pt-1" :style="activeGroup != 'amlabsPages' ? 'color: #b1b1b1;' : 'color: #000000'">AMLabs</span>
              </span>
            </v-btn>
          </template>
          <v-list :style="`background-color:${backGroundColor.amlabsPages} !important;`" dark>
            <v-list-item v-for="page in amlabsPages" :key="page.name" flat :to="page.route" v-show="page.permission">
              <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>

    <!-- This dialog is temporary. It indicates the new access to the billing page.
    It will be removed after the billing access is definitely moved to the user area. -->
    <v-dialog v-model="newBillingAccessDialog" max-width="622px">
      <v-card height="100%">
        <v-card-title style="justify-content: center" class="text-uppercase">
          {{ $t('Attention') }}
        </v-card-title>
        <v-card-text>
          <v-row no-gutters align-content="center" justify="center">
            <v-col style="max-width: 584px;">
              <p class="text-center" v-html="$t('newBillingAccessDialogText')">
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters align-content="center" justify="center">
            <img src="../../public/assets/new-billing-access.gif" alt="" style="max-width: 584px; max-height: 314px;">
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5">
          <v-spacer></v-spacer>
          <v-btn @click="newBillingAccessDialog = false" color="primary">{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    activeGroup: { type: String, required: true },
    favoritePages: { type: Array, required: true },
    generalPages: { type: Array, required: true },
    salesPages: { type: Array, required: true },
    logisticsPages: { type: Array, required: true },
    telemetryPages: { type: Array, required: true },
    registerPages: { type: Array, required: true },
    crmPages: { type: Array, required: true },
    securityPages: { type: Array, required: true },
    billingPages: { type: Array, required: true },
    amlabsPages: { type: Array, required: true },
    showSalesButton: { type: Boolean, required: true },
    userIsSupplier: { type: Boolean, required: true },
    userIsTelemetry: { type: Boolean, required: true },
    userIsRegister: { type: Boolean, required: true },
    userIsAppAdministrator: { type: Boolean, required: true },
    userIsSecurity: { type: Boolean, required: true },
    userIsManager: { type: Boolean, required: true },
    userIsSupport: { type: Boolean, required: true },
    userIsRemoteMonitoring: { type: Boolean, required: true },
    isBrazil: { type: Boolean, required: true },
  },
  data() {
    return {
      pageToGo: null,
      backGroundColor:{
        telemetryPages: "#86009B",
        salesPages: "#4E009B",
        logisticsPages: "#03009B",
        generalPages: "#00479B",
        registerPages: "#00889B",
        crmPages: "#009B92",
        securityPages: "#009B5A",
        billingPages: "#00B31D",
        amlabsPages: "#212121",
      },
      searchPageDialog: false,
      newBillingAccessDialog: false
    }
  },
  computed: {
    availablePages() {
      let pages = !this.userIsRemoteMonitoring ? [...this.generalPages] : [];

      if(this.showSalesButton) pages = pages.concat(this.salesPages);
      if(this.userIsSupplier) pages = pages.concat(this.logisticsPages);
      if(this.userIsTelemetry || this.userIsRemoteMonitoring) pages = pages.concat(this.telemetryPages);
      if(this.userIsRegister) pages = pages.concat(this.registerPages);
      if(this.userIsAppAdministrator) pages = pages.concat(this.crmPages);
      if(this.userIsSecurity || this.userIsRemoteMonitoring) pages = pages.concat(this.securityPages);
      if(this.userIsSupport) pages = pages.concat(this.billingPages);
      if(this.$tenants.hasClaim(this.$auth.user(), 'master' || 'pagseguro')) pages = pages.concat(this.amlabsPages);

      pages = pages.filter(page => page.permission);

      return pages
    },
  },
  methods: {
    navigateToPage() {
      if (this.pageToGo != null && this.pageToGo != "/" + this.$route.path.split('/')[1]) {
        this.$router.push(this.pageToGo);
        this.pageToGo = "";
        this.searchPageDialog = false;
      }
    },
  }
}
</script>

<style>
.toolbarItemsPortal {
    height: inherit;
}

/* This class sets the buttons icons heigth */
.button-icon {
  width: 100%;
  text-align: center;
  font-size: calc(0.4 * 100px);
}

.amlabs-icon {
  width: 30px;
  text-align: center;
}

/* This class aligns the contents inside the buttons */
.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, .54)
}

/* This class aligns the button relative to the navigation drawer space */
.button-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  z-index: 9999;
}

.touchpay-logo {
  width: 60px;
  height: 83px;
  /* max-height: 75%;
  max-width: 75%; */
}

/deep/ .v-toolbar__extension {
  padding: 0px !important;
}
</style>
