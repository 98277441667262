export default {
  priceTable: {
    action: {
      new: "Nova tabela de preços",
      create: {
        action: "Criar tabela de preços",
        option: {
          empty: "Criar tabela vazia",
          planogram: "Copiar de planograma"
        }
      },
      edit: "Editar produtos",
      remove: {
        action: "Remover",
        description: "a tabela de preços"
      },
      divergence: "Ver divergências",
      updateMarkupByCategory: "Definir markup por categoria",
      importfromCentralStock: "Importar do estoque central",
      updateMarkUp: "Atualizar markup",
      return: "Voltar às tabelas de preços"
    },
    data: {
      table: "Tabela",
      name: "Nome da tabela de preços",
      cost: "Custo",
      markUp: "Markup",
      price: "Preço",
      category: "Categoria"
    },
    response: {
      fetch: {
        error: "Não foi possível carregar as tabelas de preços, tente novamente"
      },
      create: {
        success: "Sucesso ao criar tabela de preços {priceTableName}",
        successPlanogram: "Sucesso ao criar tabela de preços {priceTableName} do planograma {planogramId}",
        error: "Não foi possível criar a tabela de preços"
      },
      edit: {
        success: "Sucesso ao editar tabela de preços de {priceTableNamePrevious} para {priceTableName}",
        error: "Não foi possível editar a tabela de preços"
      },
      remove: {
        success: "Sucesso ao remover tabela de preços {priceTableName}",
        error: "Não foi possível remover a tabela de preços"
      },
      updateMarkupByCategory: {
        success: "Sucesso ao atualizar markup e preço por categoria",
        error: "Não foi possível atualizar markup por categoria"
      },
      changePriceTableType: {
        markUpOnly: "na adição dos campos custo e markup para cada item da tabela de preços",
        priceOnly: "na perda irreversível de todos os valores adicionados em custo e markup"
      },
      importfromCentralStock: {
        success: "Sucesso ao importar custo do estoque central",
        error: "Não foi possível importar custo do estoque central, tente novamente"
      }
    },
    help: {
      divergence: {
        title: "Divergências na aplicação da tabela",
        type: {
          priceTable: {
            description: "Tabela de Preços",
            absenceReason: "{productId} - {productDescription} está no PDV {pointOfSaleId} mas está ausente da tabela aplicada."
          },
          planogram: {
            description: "Planograma",
            absenceReason: "{productId} - {productDescription} está na tabela aplicada mas está ausente do PDV {pointOfSaleId}."
          }
        },
        description: `
          <p align="justify">
            Essa caixa de diálogo mostra as divergências que houveram ao aplicar a tabela de preços aos Pontos de Vendas selecionados.
          </p>
          <p align="justify">
            Há duas formas de divergência possíveis: Divergência de <strong>Tabela de Preço</strong> e divergência de <strong>Planograma</strong>.
          </p>
          <ul>
            <li align="justify">
              <strong>Tabela de Preços:</strong> A divergência de Tabela de preço ocorre quando um produto está presente<strong> no PDV</strong> 
              a ser aplicado, mas está <strong>ausente</strong> da <strong>tabela de preços aplicada.</strong>
            </li>
            <li align="justify">
              <strong>Planograma:</strong> A divergência de Planograma ocorre quando um produto está presente <strong>na Tabela de Preços</strong> 
              mas está <strong>ausente</strong> do planograma do <strong>PDV aplicado</strong>.
            </li>
          </ul>
        `,
      },
      about: {
        title: "Tabelas de Preços",
        description: `
          <p>
            Essa página lista as Tabelas de Preços que foram criadas; podendo criar, editar e ativá-las em diversos pontos de vendas.
          </p>
          <p>
            <strong>Criar uma tabela de Preços:</strong>
          </p>
          <ul>
            <li>
              Para criar uma tabela de preços, basta clicar em <strong>"+ NOVA TABELA DE PREÇOS"</strong>, adicionar o nome de sua tabela e selecionar 
              entre duas maneiras de criação.
              <ul>
                <li>
                  <strong>Criar tabela vazia -</strong> Essa maneira apenas cria a Tabela de Preços sem nenhuma informação;
                </li>
                <li>
                  <strong>Copiar de planograma -</strong> Ao selecionar essa maneira, abre as abas para selecionar qual o Ponto de venda que deseja copiar 
                  o planograma e qual a sua versão;
                </li>
              </ul>
            </li>
            <li>
              Para adicionar produtos na sua tabela de preços, clique em <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i> e depois clique em 
              <strong>"+ ADICIONAR PREÇO"</strong> e preencher os campos de Produto e Preço e depois clicando em <strong>adicionar</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Edição da tabela de preços:</strong>
          </p>
          <ul>
            <li>
              Para editar o nome da tabela de preços, basta clicar no <i style="font-size: 16px;" class="mdi mdi-pencil info--text"></i> e alterar o nome, clicando em 
              <strong>salvar</strong> para finalizar.
            </li>
            <li>
              Para editar o preço de algum produto, é só clicar em <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i> e alterar o valor no campo 
              de preço, ao clicar fora ou navegar usando TAB (no teclado) o valor é <strong>automaticamente salvo</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Ativação da tabela de preços:</strong>
          </p>
          <ul>
            <li>
              Para ativar a tabela de preços, basta clicar em <i style="font-size: 16px;" class="mdi mdi-domain info--text"></i>, selecionar os pontos de venda que 
              deseja aplicar a tabela de preços e depois clicar em <strong>CRIAR RASCUNHOS</strong>.
            </li>
            <li>
              Após clicar em Prosseguir, será criado um planograma rascunho para os pontos de venda selecionados, lembrando que é 
              <strong>necessário</strong> ter o produto no planograma anteriormente para os preços serem aplicados.
            </li>
          </ul>
          <p></p>
        `
      },
      centralStock: {
        title: "Importar do estoque central",
        description: `
          <p>
            Nessa funcionalidade, você pode selecionar qual estoque central será usado para a atualização da tabela de preços.
          </p>
          <p>
            <strong>Seleção de Estoque Central:</strong>
          </p>
          <p>
            Seleção de qual estoque central será importado os custos.
          </p>
          <p>
            <strong>Cálculos de Markup e Preço:</strong>
          </p>
          <p>
            A tabela de preços será atualizada seguindo os seguintes critérios.
          </p>
          <ul>
            <li>
              Para preços sem custo ou markup. O custo será importado do estoque e o markup será calculado.
            </li>
            <li>
              Para preços já completos*, por padrão será feito o cálculo do novo preço com base no custo importado.
            </li>
            <li>
              Para preços já completos* e com a opção de "Atualizar Markup" ativa, será feito o cálculo do novo Markup, sem alterar o preço.
            </li>
          </ul>
          <br/>
          <p>
            <i>*Campos de Custo e Markup já preenchidos</i>
          </p>
        `,
      },
      markUpCategory: {
        title: "Markup por categoria",
        description: "Nessa funcionalidade, você pode atualizar o Markup dos produtos da tabela pela categoria.",
        option: {
          category: {
            action: "Seleção de Categoria:",
            description: "Seleção de qual categoria deseja atualizar markup."
          },
          markUp: {
            action: "Markup:",
            description: "Novo valor do markup para atualizar os produtos da categoria selecionada."
          },
          condition: "*Apenas produtos que estão na tabela e que correspondem à categoria selecionada serão atualizados."
        }
      },
      table: {
        action: {
          toMarkUp: `
            Alterar a tabela somente <strong>Preço</strong> para <strong>Markup</strong>, resultará na adição dos campos Custo e Markup para cada item 
            da Tabela de Preços.
            <br />
            Deseja continuar?
          `,
          toPrice: `
            Alterar a tabela <strong>Markup</strong> para somente <strong>Preço</strong>, resultará na perda irreversível de todos os valores 
            adicionados em Custo e Markup.
            <br />
            Deseja continuar?
          `
        }
      }
    },
    item: {
      action: {
        create: "Adicionar preço",
        importCost: "Importar custo",
        remove: "Remover produto",
      },
      response: {
        create: {
          success: "Sucesso ao criar preço",
          error: "Não foi possível criar preço"
        },
        fetch: {
          error: "Não foi possível carregar preços, tente novamente"
        },
        edit: {
          success: "Sucesso ao editar preço de {productDescription}",
          error: "Não foi possível editar preço de {productDescription}"
        },
        remove: {
          success: "Sucesso ao remover preço de {productDescription}.",
          error: "Não foi possível remover preço de {productDescription}"
        },
        priceTableTypeChange: {
          success: "Sucesso ao alterar tipo de tabela de preços",
          error: "Não foi possível alterar tabela de preços"
        }
      },
      help: {
        about: {
          title: "Produtos da tabela de preços",
          description: `
            <p>
              Essa página lista os preços da tabela selecionada; podendo criar, editar e apagar seus respectivos valores.
            </p>
            <p> <strong>Persistência de dados:</strong> </p>
            <ul>
              <li>
                A tabela de preços, possui um design para facilitar a persistência de dados. Em que quando um dos campos
                (Custo, Markup ou Preço) perde o foco, (seleciona outro campo) é salvo no banco de dados.
                Com isso, a navegação e edição é feita com menos procedimentos.
                Uma boa prática é utilizar as teclas <strong>TAB</strong> e <strong>SHIFT + TAB</strong> no seu teclado.
              </li>
            </ul>
            <br/>
            <p> <strong>Cálculos de Markup e Preço:</strong> </p>
            <p>
              A tabela de preços possui cálculos que funcionam da seguinte maneira:
            </p>
            <ul>
              <li> O <strong>Custo</strong> não possui cálculos e não será interferido por Markup ou Preço. </li>
              <li> O <strong>Markup</strong> é calculado pela edição do Custo ou Preço. </li>
              <li> O <strong>Preço</strong> é calculado pela edição do Markup. </li>
            </ul>
            <br/>
            <p>
              Para que os cálculos funcionem, os três campos (Custo, Markup e Preço) devem estar preenchidos. Todavia, caso
              tenha apenas o Preço, basta colocar o Custo que o Markup será automaticamente calculado.
            </p>
            <p>
              A tabela de preços, foi projetada para entregar o máximo de flexibilidade, o único campo obrigatório é o preço.
            </p>
            <p>
              <strong>Criar um Preço:</strong>
            </p>
            <ul>
              <li>
                Para criar um novo preço, basta clicar em <strong>"+ ADICIONAR PREÇO"</strong>. No topo da tabela será
                exibida uma linha para selecionar o produto ao qual deseja adicionar o Preço, Markup e Custo.
              </li>
            </ul>
            <br/>
          `,
        }
      }
    },
  },
}