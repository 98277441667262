
import Vue from 'vue';
import axios from 'axios';
import BarChart from "@/newReport/BarChart";
import StatusPickListHelp from '@/views/dashboard-new/helpers-new/StatusPickListHelp.vue';

  
export default Vue.extend({
    components: {
      BarChart,
      StatusPickListHelp
    },
    data() {
      return {
        graph: {
          data: [] as object[],
          visible: false,
          height: 350,
        },
        pendingColor: (this.$vuetify.theme as any).defaults.dashboard.dashboardYellow,
        separatedColor: (this.$vuetify.theme as any).defaults.dashboard.dashboardGreenDarken1
      };
    },
    methods: {
        fetchPickListCounts() {
            axios.get('/api/PickLists/Counts')
                .then(response => {
                    this.graph.data = [{
                    label: 'Status',
                    backgroundColor: [this.pendingColor, this.separatedColor],
                    data: [response.data.pendingCount, response.data.separatedCount]
                }];
                this.graph.visible = true;
            })
            .catch(error => {
                (this.$refs.feedback as any).handleError(error.data.message, error);
            });
        },
        getComputedStyleValue(varName: string): string {
            return getComputedStyle(document.documentElement).getPropertyValue(varName).trim();
        }
    },
    mounted() {
      this.fetchPickListCounts();
    },
  })
