export default {
    searchTransactionsReports: 'Search',
    totalTransactionsReports: 'Total',
    selectGroupingsTransactionsReports: 'Select one or more groupings and click Update.',
    productCodeTransactionsReports: 'Code. Product',
    productDescriptionTransactionsReports: 'Product Description',
    categoryTransactionsReports: 'Category',
    pointOfSaleIdTransactionsReports: 'POS ID',
    pointOfSaleCodeTransactionsReports: 'Code. POS',
    customerTransactionsReports: 'Customer',
    locationTransactionsReports: 'Location',
    specificLocationTransactionsReports: 'Specific location',
    stateTransactionsReports: 'State',
    paymentMethodTransactionsReports: 'Payment Method',
    cardBrandTransactionsReports: 'Card Flag',
    dateTransactionsReports: 'Date',
    monthTransactionsReports: 'Month',
    dayOfWeekTransactionsReports: 'Day of the Week',
    quantityTransactionsReports: 'Qty.',
    totalValueTransactionsReports: 'Total Value',
    totalCostTransactionsReports: 'Total Cost',
    dynamicTransactionSummaryTransactionsReports: 'Dynamic transaction summary',
    othersTransactionsReports: 'OTHERS',
    TransactionsReports: 'Dynamic transaction summary',
    hour: 'Hour',
};