
import Vue from 'vue';
import InventoriesDropDown from "@/components/filters/InventoriesDropDown.vue";
import agent from "@/api/agent";
import PriceTableInventorySelectorHelp from "@/views/priceTables/helpers/PriceTableInventorySelectorHelp.vue";

const formInitialState = {
  inventoryId: null,
  recalculateMarkup: false
}

export default Vue.extend({
  components: {PriceTableInventorySelectorHelp, InventoriesDropDown},
  props: {
    priceTableId: {type: Number, required: true},
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      form: {...formInitialState},
      loading: false,
      valid: false,
      translations: {
        action: {
          importfromCentralStock: this.$t('priceTable.action.importfromCentralStock').toString(),
          updateMarkUp: this.$t('priceTable.action.updateMarkUp').toString(),
        },
        response: {
          importfromCentralStock: {
            success: this.$t('priceTable.response.importfromCentralStock.success').toString(),
            error: this.$t('priceTable.response.importfromCentralStock.error').toString(),
          }
        },
        button: {
          close: this.$t('buttons.close').toString(),
          import: this.$t('buttons.import').toString(),
        }
      },
      helpDialog: {
        enabled: false,
      },
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
        if (!value) {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    syncInventory() {
      this.loading = true;
      let params = {recalculateMarkup: this.form.recalculateMarkup};
      agent.PriceTables.syncDistributionCenter(this.priceTableId, this.form.inventoryId!, params)
          .then(() => {
            this.$emit('success-sync', this.translations.response.importfromCentralStock.success);
            this.dialog = false;
          })
          .catch((error) => {
            this.handleError(this.translations.response.importfromCentralStock.error, error);
          })
          .finally(() => {
            this.loading = false;
            this.resetForm();
          });
    },
    resetForm(): void {
      Object.assign(this.form, {...formInitialState});
      (this.$refs.form as any).resetValidation();
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
});
