export default {
    giveFeedback: 'Give feedback on the page',
    feedbackDialogTitle: 'Page Feedback',
    yourRatingForPage: 'Your rating for the page',
    selectOneOrMore: 'Select one or more',
    whatsWrong: 'Whats bad?',
    whatCanImprove: 'What can improve?',
    whatPleasedYou: 'What pleased you the most?',
    contactNumber: 'Contact Number',
    weMayContactYou: 'We may contact you',
    contactNumberNote: 'This number is for us to contact you. If your issue requires follow-up, contact support: 19 99959-6630.',
    cancelConfirmation: 'Are you sure you want to cancel? Changes will be lost.',
    cancel: 'Cancel',
    submit: 'Submit',
    thankYouForRating: 'Thank you for rating the page',
    yourOpinionIsImportant: 'Your opinion is very important to us.',
    close: 'Close',
    contactSupport: 'If your problem requires follow-up, contact support: 19 99959-6630.',
    toGoBack: 'To go back',
    yesOption: 'Yes',
    feedbackReason: 'Please provide the reason for the feedback ("Other" category).',
    errorThatHappens: 'Please report the error that occurs.',
    countryCode: '+1 111-111-1111',
    countryCodeRules: 'Enter a valid cell phone including country code',
    formatInput: '+# ###-###-####',
    postTenantFeedbackError: 'Unable to record feedback',
  };
  