export default {
    totalSales: 'Total de ventas',
    period: 'Período',
    soldQuantity: 'Cantidad vendida',
    soldValue: 'Valor vendido',
    noResults: 'Sin resultados',
    today: 'Hoy',
    sevenDays: '7 días',
    thirtyDays: '30 días'
};
