export default {
    url: "#",
    headers: { "Cache-Control": "max-age=31536000" },
    method: 'PUT',
    thumbnailWidth: 600,
    thumbnailHeight: 200,
    addRemoveLinks: true,
    acceptedFiles: ".pfx, .p12",
    maxFiles: 1,
    autoProcessQueue: false,
    dictDefaultMessage: "<i class='mdi mdi-cloud-upload-outline font-size-icon'><br><br></i>" + 
    "<span class='font-size-text'> Arraste e solte seu certificado aqui ou selecione um arquivo. </span>" +
    "<br><br><br><span class='gray-background-text font-size-selection'><strong>SELECIONE O ARQUIVO</strong></span>",
    dictFallbackMessage: "Este navegador não é suportado para subir arquivos. Tente uma versão recente do Chrome ou Firefox",
    dictFileTooBig: "O arquivo é muito pesado, tente um menor que {{maxFilesize}}MB",
    dictInvalidFileType: "Esse formato de arquivo não suportado, apenas arquivos .pfx e .p12 são aceitos",
    dictResponseError: "Não foi possível subir a arquivo (erro {{statusCode}})",
    dictCancelUpload: "Cancelar upload",
    dictUploadCanceled: "O upload foi cancelado",
    dictCancelUploadConfirmation: "Deseja cancelar o upload?",
    dictRemoveFile: '',
    dictMaxFilesExceeded: "Limite máximo de arquivos atingido",
    dictFileSizeUnits: { tb: "TB", gb: "GB", mb: "MB", kb: "KB", b: "b" },
    init: function() {
        this.on('addedfile', function(file) {
            const previewElement = file.previewElement.querySelector('.dz-progress');
            if (previewElement) {
                previewElement.style.display = 'none';
            }
            if (this.files.length > 1) {
                this.removeFile(this.files[0]);
            }
            file.previewElement.querySelector('.dz-remove').style.display = 'none'; // Add this line
        });
        this.on('processing', function (file) { 
            const elements = document.getElementsByClassName('dz-progress');
            [].forEach.call(elements, function (element) {
                element.style.visibility = 'visible';
            });

            this.options.url = file.uploadURL;
        });
    },
    sending(file, xhr) {
        const send = xhr.send;
        xhr.send = () => {
            send.call(xhr, file);
        }
    },
}