export default {
    lossesProductAndPosTitleProduct: 'Losses by products',
    lossesProductAndPosSubTitleProduct: 'Identify the items with the highest losses in your store',
    lossesProductAndPosTitlePos: 'Losses by POS',
    lossesProductAndPosSubTitlePos: 'Identify the POS with the highest losses',
    lossesProductAndPosLabel: 'Reason',
    lossesProductAndPosyColumnProduct: 'Products',
    lossesProductAndPosColumnLossesValue: 'Lost value',
    lossesProductAndPosColumnAmount: 'Amount',
    lossesProductAndPosLabelToViewPos: 'View POS',
    lossesProductAndPosLabelToViewProduct: 'View Products',
    reasonsForLossesExpired: 'Validity expired',
    reasonsForLossesDamaged: 'Damaged product',
    reasonsForLossesOther: 'Other',
    reasonsForLossesDetour: 'Detour',
    lossesProductAndPosyColumnPos: 'POS',
   }