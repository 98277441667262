
import Vue from 'vue';


export default Vue.extend({

   data() {
    return {
        localStorageSelected : false as  boolean,
    }
   },
   props: {
    value: {
      type: Boolean,
      default: false
    },
   },
   computed: {
    dialog: {
      get() : boolean {
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value, this.localStorageSelected);
      }
    },
  },
  methods: {
    getLocalStorageData() {
      this.localStorageSelected = true;
      this.dialog = false;
  },
  resetData() {
    this.localStorageSelected = false;
    this.dialog = false; 
  },
  },
  
})
