<template>
  <v-dialog :max-width="width" v-model="dialog">
    <v-card>
      <v-card-title class="title">
        <v-icon left>help</v-icon>
        <slot name="title"></slot>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click.native="dialog = false">{{ $t('iUnderstoodHelp') }}</v-btn>
      </v-card-actions>
    </v-card>

    <template #activator="{ on }">

      <v-btn v-on="on"
        icon text
        color="accent">

      <v-icon :class="$vuetify.breakpoint.smAndDown ? 'mb-5' : ''">help</v-icon>
      </v-btn>

    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: {type: Number, required: false, default: 560},
  },
  data() {
    return {
      dialog: false
    }
  },
}
</script>
