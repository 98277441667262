<template>
  <v-layout row wrap>
    <v-flex xs12>

      <!-- Used for Edit Mode (Preview) -->
      <template v-if="!editMode">
        <v-img :src="imageUrl" contain
          max-width="240"
          max-height="240">
          <v-layout v-if="!useExisting" justify-end>
            <v-menu bottom left>
              <template #activator="{ on }">
                <v-btn v-on="on" fab dark color="primary" class="ma-4">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.native="editMode = true">
                  <v-icon left>cloud_upload</v-icon>{{ $t('changeImage') }}
                </v-list-item>
                <v-list-item @click.native="removeDialog.entityId = entityId; removeDialog.enabled = true">
                  <v-icon left>delete</v-icon>{{ $t('removeImage') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-img>
        <template v-if="useExisting">
          <v-btn
            color="primary"
            :loading="uploading"
            @click.native="confirmExistingClicked"
            class="mr-2 mt-2"
          >
            <v-icon left>check</v-icon>{{ $t('confirm') }}
          </v-btn>
          <v-btn
            :disabled="uploading"
            color="error"
            @click.native="$emit('cancel-existing');"
            class="mt-2"
          >
            <v-icon left>clear</v-icon>{{ $t('cancel') }}
          </v-btn>
        </template>
      </template>

      <!-- Used for Upload Mode (Finish) -->
      <template v-else>
        <vue-dropzone
          ref="dropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-thumbnail="onThumbnail"
          @vdropzone-removed-file="onRemoved"
          @vdropzone-complete="onComplete"
          @vdropzone-success="onSuccess"
          @vdropzone-error="onUploadError"
        ></vue-dropzone>
        <v-btn
          v-if="uploadButton"
          color="primary"
          :loading="uploading"
          @click.native="onUploadConfirmed"
          class="mr-2 mt-2"
        >
          <v-icon left>cloud_upload</v-icon>{{ $t('confirm') }}
        </v-btn>
        <v-btn
          v-if="cancelButton"
          :disabled="uploading"
          color="error"
          @click.native="cancelClicked"
          class="mt-2"
        >
          <v-icon left>clear</v-icon>{{ $t('cancel') }}
        </v-btn>
      </template>

    </v-flex>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">{{ $t('deleteImageConfirmation') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('noEditableImage') }}</v-btn>
          <v-btn
            color="green darken-1"
            :loading="removeDialog.loading"
            text
            @click.native="deleteImage(removeDialog.entityId)"
          >{{ $t('removeEditableImage') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <feedback ref="feedback"></feedback>
  </v-layout>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import dropzoneDefaultOptions from '@/components/DropzoneOptions';

export default {
  props: {
    imageUrl: { type: [String, null], default: null },
    imagePath: { type: [String, null], default: null },
    entityId: { type: String, default: "-1", required: false },
    entityType: { type: String, required: true },
    useExisting: { type: Boolean, default: false }
  },
  data() {
    return {
      uploadButton: false,
      editMode: this.imageUrl == null,
      dropzoneOptions: dropzoneDefaultOptions,
      hasPendingFile: false,
      uploading: false,
      removeDialog: {
        enabled: false,
        loading: false,
        entityId: null
      }
    }
  },
  computed: {
    cancelButton() {
      return this.editMode && (this.imageUrl != null || this.hasPendingFile)
    }
  },
  watch: {
    imageUrl: function(url) {
      this.editMode = url == null;
    }
  },
  created() {
    this.getUploadUrl();
    document.onpaste = (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData || {}).items || [];
      for(const itemIndex in items){
        const item = items[itemIndex];
        if (item.kind === 'file') {
          this.$refs.dropzone.addFile(item.getAsFile());
        }
      }
    }
  },
  methods: {
    /**Dropzone Handling Methods.*/
    onThumbnail() {
      this.hasPendingFile = true;
      this.uploading = false;
      this.uploadButton = true;
    },
    onRemoved() {
      this.uploadButton = false;
      this.hasPendingFile = false;
    },
    onUploadConfirmed() {
      this.uploading = true;
      this.$refs.dropzone.processQueue();
    },
    onComplete() {
      this.uploading = false;
      this.uploadButton = false;
    },
    onUploadError(file, message) {
      this.$refs.feedback.handleError(this.$t('uploadImageError'), message);
    },
    onSuccess(file) {
      switch (this.entityId) {
        case "-1":
          this.uploadImage(file.uploadRequest);
          break;
        default:
          this.uploadImageAndUpdateEntity(file.uploadRequest);
          break;
      }
    },

    /**Image Handling Methods.*/
    deleteImage(entityId) {
      this.removeDialog.loading = true;
      this.axios.delete(`api/images/${this.entityType}/${entityId}`)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('imageRemoved'));
          this.removeDialog.enabled = false;
          this.$emit('delete', entityId);
        }).catch(error => {
          this.$refs.feedback.handleError(this.$t('unableToRemoveImage'), error)
        }).then(() => this.removeDialog.loading = false);
    },
    cancelClicked() {
      this.$refs.dropzone.removeAllFiles();
      this.hasPendingFile = false;
      if (this.imageUrl != null) {
        this.editMode = false;
      }
    },
    confirmExistingClicked() {
      const imageInfo = {
        entityId: this.entityId,
        path: this.imagePath,
        url: this.imageUrl
      };
      this.uploadImageAndUpdateEntity(imageInfo);
    },

    /**API Methods.*/
    getUploadUrl() {
      const component = this;
      this.dropzoneOptions.accept = function(file, done) {
        component.axios.get(`api/images/${component.entityType}/${component.entityId}/putUrl?contentType=${file.type}`)
          .then(response => {
            file.uploadRequest = response.data;
            file.uploadURL = response.data.url;
            done();
          }).catch(error => {
          const specificError = ((error || {}).response || {}).data || component.$t('unknownError');
          done(`${component.$t('authorizeUploadError')} ${specificError}`);
        });
      }
    },
    uploadImage(imageInfo) {
      this.axios.post(`api/images/${this.entityType}`, imageInfo)
        .then(() => {
          this.cancelClicked();
          this.$emit("uploaded");
          this.$refs.feedback.handleSuccess(this.$t('imageSavedSuccessfully'));
        }).catch(error => {
        this.$refs.feedback.handleError(this.$t('unableToValidateImage'), error);
      });
    },
    uploadImageAndUpdateEntity(imageInfo) {
      this.axios.put(`api/images/${this.entityType}/${imageInfo.entityId}`, imageInfo)
        .then(response => {
          this.$emit('create', imageInfo.entityId, response.data);
          this.$refs.feedback.handleSuccess(this.$t('imageSavedSuccessfully'));
        }).catch(error => {
          this.$refs.feedback.handleError(this.$t('unableToValidateImage'), error);
        });
    }
  },
  components: {
    'vue-dropzone': vue2Dropzone
  }
}
</script>

<style>

</style>
