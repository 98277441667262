<template>
  <v-autocomplete
    v-model="buildingManagerId"
    :loading="loading"
    :items="items"
    item-value="id"
    item-text="email"
    clearable
    prepend-icon="mdi-badge-account-outline"
    :label="$t('buildingManagerLabel')"
  >
    <template #append-outer>
      <help>
        <template #title>{{ $t('buildingManagerHelpTitle') }}</template>
        <p>
          {{ $t('buildingManagerHelpText') }}<br>
        </p>
      </help>
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: { type: String, default: null }
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    buildingManagerId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  async mounted() {
    this.loading = true    
    try {
      const response = await this.axios.get('api/Users/building-manager-users')
      this.items = response.data
    }
    finally {
      this.loading = false
    }
    
  }
})
</script>

<style scoped>

</style>