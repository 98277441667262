export default {
    okPosMachineStatus: 'Online',
    noLauncherPosMachineStatus: 'Application is not default launcher',
    noPowerPosMachineStatus: 'Payment system not connected to power',
    maybeOutOfServicePosMachineStatus: 'Possible maintenance or refueling of the machine',
    maybeDisconnectedPosMachineStatus: 'Possible connection drop',
    maybeCardReaderFailPosMachineStatus: 'Possible problem with card reader',
    outOfServicePosMachineStatus: 'unknown reason',
    screenOffPosMachineStatus: 'screen off',
    cardReaderFailPosMachineStatus: 'check card reader',
    disconnectedPosMachineStatus: 'no internet connection',
    outOfServiceNoPowerPosMachineStatus: 'power outage',
    outOfServiceMachinePosMachineStatus: 'machine faulty',
    deadBatteryPosMachineStatus: 'no connection due to lack of power',
  };