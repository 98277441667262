
import Vue from 'vue';
import Information from '@/components/Information.vue';

export default Vue.extend({
  components: {
    Information
  },
  data() {
    return {
      translations: {
        data: {
          title: this.$t('franchisee.data.title').toString(),
        },
        help: {
          report: {
            description: this.$t('franchisee.help.report.description').toString(),
          }
        }
      }
    };
  },
})
