<template>
  <v-expansion-panels hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div>
          <v-icon>filter_list</v-icon>
          {{ $t('filters.filtersActions') }}
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2" eager>
        <v-form ref="form" v-model="valid">

          <!-- Filters Row -->
          <v-row class="pt-5">

            <!-- 1st Column-->
            <v-col cols="12" md="3">
              <interval-date-picker
                  :interval-max-range=31
                  :interval-start-range=7
                  :max-months-allowed=6
                  :reset-date="IntervalDatePicker.reset"
                  @intervalDateUpdated="onIntervalDateUpdated"
              ></interval-date-picker>
            </v-col>

          </v-row>
          <!-- Buttons Row -->
          <v-row class="pa-2" justify="end">
            <clear-button
              @clear="clearQuery"
            />

            <v-btn
                :loading="loading.excel"
                class="ma-1"
                color="info"
                @click.native="getExcel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              {{ $t('buttons.excel') }}
            </v-btn>

            <update-button
              :valid="valid" 
              @refresh="emitModification"
            />
          </v-row>

          <download-dialog
            v-model="downloadDialog.show"
            :fileName="downloadDialog.fileName"
            @update="(value) => downloadDialog.show = value"
          />
        </v-form>
        
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import IntervalDatePicker from "@/components/filters/IntervalDatePicker";
import DownloadDialog from "@/components/common/DownloadDialog.vue";
import agent from "@/api/agent";
import ClearButton from "@/components/buttons/ClearButton.vue";
import UpdateButton from "@/components/buttons/UpdateButton.vue";

export default {
  components: {
    IntervalDatePicker,
    DownloadDialog,
    ClearButton,
    UpdateButton
  },
  data() {
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
      },
      IntervalDatePicker: {
        reset: false,
      },
      loading: {
        excel: false
      },
      downloadDialog: {
        show: false,
        fileName: ""
      }
    };
  },
  mounted() {
    this.emitModification();
  },
  methods: {
    emitModification() {
      if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.IntervalDatePicker.reset = true;
      this.emitModification();
    },
    onIntervalDateUpdated(minDate, maxDate, resetDate) {
      if (resetDate) {
        this.IntervalDatePicker.reset = false;
      }
      this.form.minDate = minDate;
      this.form.maxDate = maxDate;
    },
    getExcel() {
        this.loading.excel = true;
        agent.Connectivity.excel(this.form)
          .then(response => {
            this.downloadDialog.fileName = response.data;
            this.downloadDialog.show = true;
          })
          .catch((error) => {
            this.$emit("excelError", error);
          })
          .finally(() => this.loading.excel = false);
    },
    
  },
};
</script>
