export default {
    revenueMonthcardTitle: 'Crecimiento',
    revenueMonthcardSubtitle: 'Vea la variación mensual entre este año y el anterior',
    accumulatedRevenue: 'Facturación acumulada',
    higherRevenue: 'Mayor facturación',
    MonthCard: 'Mes de',
    YearCard: 'Año de',
    salesLegend: 'Ventas ',
    revenueMonthHelp: `
    <p style="text-align: justify;">
        Este indicador presenta la facturación mensual actual en comparación con la del año anterior, proporcionando una visualización clara y 
        detallada del rendimiento a lo largo del tiempo. Muestra dos columnas de datos: una para el año actual y otra para el año anterior, 
        facilitando la identificación de variaciones mensuales, tendencias y estacionalidades. 
    </p>
    <p style="text-align: justify;">    
        Comparar los valores mensuales entre los años permite un análisis de la evolución del rendimiento, 
        ayudando en la toma de decisiones y en la planificación.
    </p>`,
}