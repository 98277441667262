
import Vue from 'vue';

export default Vue.extend({
  name: "ExcelButton",
  methods:{
    getExcel() {
      this.$emit('excel');
    }
  }
});
