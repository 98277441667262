export default {
  deleteNonUsedButton: "Delete unused",
  deleteTaxScenarioDeleteDialogText: "Are you sure you want do delete this fiscal scenario?",
  cancelButtonDeleteDialog: "Cancel",
  deleteButtonDeleteDialog: "Delete",
  headerIsInvoiceDataComplete: "Status",
  headerId: "ID",
  headerName: "Name",
  headerFiscalRegion: 'Fiscal Region',
  headerTaxation: "Tax Regime",
  headerCountryTax: "Country Tax [%]",
  headerStateTax: "State Tax [%]",
  headerCountyTax: "County Tax [%]",
  headerNumberOfProducts: "No. Products",
  headerActions: "Actions",
  dataTableNoDataText: "No fiscal scenarios registered",
  textFieldSearchText: "Search"
}