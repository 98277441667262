export default {
  planogram: {
    data: {
      version: "Versão do planograma",
      noData: "Sem planogramas no pontos de venda"
    },
    draft: {
      action: {
        create: "Criar rascunhos",
        finish: "Finalizar rascunhos",
        activate: "Ativar planogramas",
        activatePending: "Ativar planogramas pendentes"
      },
      description: {
        finish: {
          confirm: "Deseja finalizar o planograma de rascunho para os PDVs selecionados?",
          alert: `
            <p>
              Ressalta-se que apenas os PDVs que obtiveram <strong>sucesso</strong> na estapa anterior estarão incluídos na finalização.
            </p>
            <p>
              Depois da finalização do planograma, ele entrará no modo <strong>pendente</strong> e não será mais possível editá-lo. O planograma 
              pendente substituirá o planograma ativo ao <strong>Abastecer</strong> o PDV ou <strong>Ativar</strong> o planograma.
            </p>
          `
        },
        activatePending: {
          confirm: "Deseja ativar o planograma pendente para os PDVs selecionados?",
          alert: `
            <p>
              A ativação tornará o planograma pendente como ativo. 
              <strong>Atenção</strong>: para manter a contagem do estoque correta, recomendamos, ao invés de ativar, usar a opção de 
              <strong>Abastecer</strong> no momento de abastecimento do ponto de venda. 
              Assim, além de informar o abastecimento, o planograma pendente será ativado e terá o estoque referente ao abastecimento.
            </p>
          `
        }
      },
      advanced: {
        option: "Opções avançadas",
        action: {
          add: "Adicionar produtos da tabela de preço ausentes no planograma",
          remove: "Remover do planograma produtos ausentes da tabela de preço"
        }
      },
      response: {
        finish: {
          success: "Planograma finalizado para o PDV {pointOfSaleId}",
          error: "Não foi possível finalizar o planograma para o PDV {pointOfSaleId}: {errorMessage}"
        }
      }
    },
    response: {
      apply: {
        success: "Tabela de preços aplicada no PDV {pointOfSaleId}",
        error: "Não foi possível aplicar tabela de preços no PDV {pointOfSaleId}: {errorMessage}",
        reject: "Possível erro de conexão, tente novamente"
      },
      activate: {
        success: "Planograma ativado para o PDV {pointOfSaleId}",
        error: "Não foi possível finalizar o planograma para o PDV {pointOfSaleId}: {errorMessage}",
      }
    },
  },
}