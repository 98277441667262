<template>
  <v-card class="mb-16">
    <UsersPageHelp />
    <v-card-title>
      <v-form
          v-model="search.valid"
          @submit.prevent="getUsers"
      >
        <v-text-field
            v-model="search.text"
            append-icon="search"
            class="mr-2 pt-2 pb-2"
            clearable
            dense
            filled
            hide-details
            :label="$t('userSearch')"
            rounded
            single-line
            @click:clear="search.text = ''; getUsers();"
            @click:append="options.page = 1; getUsers()"
            @input="options.page = 1"
        ></v-text-field>
      </v-form>
      <v-spacer></v-spacer>
      <tenant-group-dialog
          v-model="tenantGroupDialog.enabled"
          @success="onTenantGroupDialogSuccess"
      />
    
      <user-dialog
          v-model="userDialog.enabled"
          :entry="userDialog.entry"
          :isEdit="userDialog.isEdit"
          @input="onUserDialogChanged"
          @success-creation="onSuccessCreation"
          @success-edition="onSuccessEdition"
      />

    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
        :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
        :headers="headers"
        :items="users"
        :loading="loading.table"
        :options.sync="options"
        :search="search.text"
        :server-items-length="totalItems"
        item-key="id"
        :no-data-text="$t('userNoDataText')"
    >

      <template #item.roles="{ item }">
        {{ getUserRolesFormatted(item) }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip :disabled="userHasPermission(item)" top>
          {{ $t('userNoPermitToEdit') }}
          <template #activator="{ on: tooltip }">
          <span v-on="tooltip">
            <v-btn
                :disabled="!userHasPermission(item)"
                class="mx-0" icon
                @click="editUser(item)">
              <v-icon color="info">edit</v-icon>
            </v-btn>
            </span>
          </template>
        </v-tooltip>
        <v-tooltip :disabled="userHasPermission(item)" top>
          {{ $t('userNoPermitToDelete') }}
          <template #activator="{ on: tooltip }">
            <span v-on="tooltip">
            <v-btn
                :disabled="!userHasPermission(item)"
                class="mx-0" icon
                @click="confirmRemoval(item)">
              <v-icon color="error">delete</v-icon>
            </v-btn>
            </span>
          </template>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('userDeleteConfirmation', {user: removeDialog.user.email}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('userNo') }}</v-btn>
          <v-btn :loading="loading.dialog" color="green darken-1" text @click.native="deleteUser(removeDialog.user)">
            {{ $t('userRemove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <feedback ref="feedback"></feedback>
  </v-card>
</template>

<script>
import {shouldRefreshData, toApiPagination} from "@/components/TableOptionsComparator";
import UserDialog from "@/views/users/components/UserDialog.vue";
import {ADMINISTRATOR_ROLE, TOP_HIERARCHY} from "@/constants/Roles";
import TenantGroupDialog from "@/views/users/components/TenantGroupDialog.vue";
import UsersPageHelp from "@/views/users/helpers/UsersPageHelp.vue";
import { getRoleTranslation } from "@/utility/RoleTranslationMapper";

export default {
  components: {TenantGroupDialog, UserDialog, UsersPageHelp},
  data() {
    return {
      ADMINISTRATOR_ROLE,
      userDialog: {
        enabled: false,
        isEdit: false,
        entry: {},
      },
      removeDialog: {
        enabled: false,
        user: ''
      },
      tenantGroupDialog: {
        enabled: false,
      },
      users: [],
      headers: [
        {text: this.$t('userEmailLabel'), align: "left", sortable: true, value: "email", width: 380},
        {text: this.$t('userTenantGroupIdLabel'), align: "left", sortable: true, value: "tenantGroupId", width: 90},
        {text: this.$t('userRolesLabel'), align: "left", sortable: false, value: "roles"},
        {text: this.$t('userActionsLabel'), align: "center", sortable: false, value: "actions", width: 150},
      ],
      loading: {
        table: true,
      },
      search: {
        valid: true,
        text: "",
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["email"],
        sortDesc: [false],
        mustSort: true,
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getUsers();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    userHasPermission(user) {
      if (!user.roles.some(element => TOP_HIERARCHY.includes(element))) {
        return true;
      } else if (user.roles.some(element => TOP_HIERARCHY.includes(element)) && this.$auth.check(ADMINISTRATOR_ROLE)) {
        return true;
      } else {
        return false;
      }
    },
    getUsers() {
      this.loading.table = true;
      this.axios.get("api/Users", {params: toApiPagination(this.options, this.search.text),})
          .then((response) => {
            this.loading.table = false;
            this.users = response.data.items;
            this.totalItems = response.data.totalItems;
          });
    },
    confirmRemoval(item) {
      this.loading.table = false;
      this.removeDialog.enabled = true;
      this.removeDialog.user = item;
    },
    deleteUser(user) {
      this.loading.dialog = true;
      this.axios.delete(`api/Users/${user.id}`)
          .then(() => {
            const entryIndex = this.users.indexOf(user);
            this.users.splice(entryIndex, 1);
            this.$refs.feedback.handleSuccess(this.$t('userDeleteSuccess', {user: user.email}));
            this.removeDialog.enabled = false;
          }).catch((error) => this.$refs.feedback.handleError(this.$t('userDeleteError'), error))
          .then(() => {
            this.loading.dialog = false;
          });
    },
    editUser(user) {
      this.userDialog.enabled = true;
      this.userDialog.isEdit = true;
      this.userDialog.entry = user;
    },
    onUserDialogChanged(value) {
      if (!value) {
        this.userDialog.isEdit = false;
      }
    },
    onSuccessCreation(message) {
      this.userDialog.enabled = false;
      this.handleSuccess(message);
      this.getUsers();
    },
    onSuccessEdition(message) {
      this.userDialog.enabled = false;
      this.userDialog.isEdit = false;
      this.handleSuccess(message);
      this.getUsers();
    },
    onTenantGroupDialogSuccess(message) {
      this.handleSuccess(message);
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
    },
    getUserRolesFormatted(user) {
      return user.roles.map(role => getRoleTranslation(role)).join(", ") + ".";
    },
  },
};
</script>

<style scoped>

</style>
