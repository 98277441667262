export default {
  transferPointOfSale: 'Transferir ponto de venda para outro CNPJ',
  transferProcessDescription: 'O processo de troca de titularidade consiste na transferência de loja de um CNPJ para outro. Para garantir que esse processo ocorra de maneira fluida, siga os próximos passos. Após o envio da solicitação você poderá acompanhar o andamento do processo de troca no menu ',
  linkApproveTransfers: 'Aprovar Transferências.',
  transfer: 'Transferência',
  pointsOfSaleToBeTransferred: 'Pontos de venda a serem transferidos',
  pointsOfSaleHelpTitle: 'Pontos de venda',
  pointsOfSaleHelpDescription: 'Selecione os pontos de venda primários (com planograma) para transferir ao novo CNPJ. Máquinas secundárias associadas (compartilhando o mesmo planograma) serão transferidas automaticamente.',
  cnpjOfDestination: 'CNPJ ao qual deve ser transferido',
  helpTitle: 'Informações do Titular de Destino',
  helpDescription: 'Para concluir a troca de titularidade, é necessário informar o CNPJ da empresa e os dados completos da pessoa responsável por receber essa troca. Inclua o nome e o telefone de contato para que o processo seja feito corretamente e o destinatário possa ser facilmente contatado',
  sameNetworkTransfer: 'Essa transferência é da mesma rede/franquia',
  yes: 'Sim',
  no: 'Não',
  termsAndConditions: 'Termos e Condições',
  termsAndConditionsDescription: 'Essa transferência refere-se apenas ao PDV dentro do sistema e não altera o local onde a venda será creditada. Para realizar a transferência dos dados da máquina de cartão, é importante que assista o vídeo abaixo: ',
  watchVideo:"ASSISTIR VÍDEO",
  termsOfResponsibilityPOS: 'TERMO DE RESPONSABILIDADE DE TRANSFERÊNCIA DE MÁQUINA DE PAGAMENTO',
  agreeWithTermsOfResponsibilityPOS: 'Entendo que sou responsável pela transferência da conta da máquina de pagamento e que somente assim o CNPJ para o qual estou transferindo poderá receber o faturamento.',
  termOfResponsibilityTransfer: 'TERMO DE RESPONSABILIDADE PARA TROCA DE TITULARIDADE',
  agreeWithTermOfResponsibilityTransfer: 'Entendo que, ao transferir a conta da máquina de pagamento, todas as responsabilidades administrativas, operacionais e financeiras relacionadas a esse dispositivo passarão a ser de inteira responsabilidade do novo titular. Isso inclui, mas não se limita a, mensalidades e visibilidade do PDV na telemetria. Como antigo titular, não terei mais acesso ao faturamento proveniente desse PDV.',
  termsAndConditionsHelp: 'Caso tenha dúvidas sobre o processo, entre em contato com nosso time administrativo pelo número ',
  submitRequest: 'Enviar solicitação',
  destinationClientName: 'Nome de quem vai receber a troca',
  destinationClientPhoneNumber: 'Telefone de quem vai receber a troca',
  titleDestination: 'Informe o CNPJ e os dados de contato da pessoa que vai receber a troca de titularidade'
};
