export default {
    selectPosWithPlanogram: 'Selecione PDVs com algum planograma (exceto pendentes)',
    addOrUpdateItems: 'Adicionar ou atualizar itens',
    productNameLabel: 'Produto',
    priceNameLabel: 'Preço',
    desiredQuantityLabel: 'Quantidade desejada (nível de par)',
    criticalMinimumLabel: 'Mínimo crítico',
    optionsLabel: 'Opções',
    addOnlyLabel: 'Apenas adicionar',
    updateOnlyLabel: 'Apenas atualizar',
    addOrUpdateLabel: 'Adicionar ou atualizar',
    btnClose: 'Fechar',
    btnSave: 'Salvar',
    noProducts: 'Sem produtos cadastrados',
    insertValidNameRules: 'Insira algum nome válido',
    requiredFieldRules: 'Este campo é obrigatório.',
    insertIntegerRules: 'Insira um número inteiro',
    insertValidValueRules: 'Insira um valor válido',
    validUrlRules: 'Insira uma URL válida',
    addUpdatePos: 'Erro ao adicionar/atualizar item no planograma do PDV ',
    tryAgainPos: ' Tente novamente.',
    addPlanogramProducts: 'Aqui é possível adicionar um produto em vários planogramas, atualizá-lo em vários planogramas, ou ambos, de acordo com os modos abaixo:',
    justAdd: 'Apenas adicionar: adiciona o produto nos planogramas que não contenham o mesmo. Para os planogramas que já contém o produto, nenhuma alteração é feita.',
    justUpdate: 'Apenas atualizar: atualiza as informações do produto apenas nos planogramas que o contém. Nesse modo, apenas o campo Produto é obrigatório. Campos deixados em branco não serão modificados.',
    addOrUpdate: 'Adicionar ou atualizar: uma combinação dos dois modos anteriores. Adiciona o produto nos planogramas que não o têm, e atualiza o produto nos planogramas que já o têm.',
    justAddTheProduct: 'Apenas adiciona o produto a planogramas que não o têm.',
    onlyEditPlanograms: 'Apenas edita planogramas que já têm o produto.',
    addsTheProductToPlanograms: 'Adiciona o produto a planogramas que não o têm, e o edita no planogramas que já o têm.', 
    availabilityLocal: 'Local',
    availabilityOrder: 'Encomenda',
    availabilityLocalAndOrder: 'Local e encomenda',
    btnItems: 'Itens',
    warningAddOrUpdateItems: 'Os produtos serão adicionados ou atualizados <strong>apenas</strong> nos ponto de vendas selecionados que não possuem planogramas pendentes.'
};