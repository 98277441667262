<template>
  <v-dialog
    persistent
    v-model="show"
    :max-width="720"
    @keydown.esc="show = false"
  >
    <v-card>
      <v-card-title>{{ $t('qrCodeOperator') }}</v-card-title>
      <v-card-text id="container">
        <template>
          <span class="black--text text--darken-1">
            {{ $t('qrCodePdvQrCodeOperator') + posId }}</span
          >
        </template>
      </v-card-text>
      <v-card-text id="container">
        <template>
          <qrcode-vue
            :value="qrCodeIdPos"
            render-as="svg"
            :size="240"
            id="qrcode-svg"
          />
          <qrcode-vue
            v-show="false"
            :value="qrCodeIdPos"
            :size="240"
            id="qrcode-canvas"
          />
          <v-container>
            <v-row align="center" justify="center">
              <v-btn class="mr-2" color="secondary" @click="downloadPng">
                <v-icon left>download</v-icon>PNG
              </v-btn>
              <v-btn class="ml-2" color="secondary" @click="downloadSvg">
                <v-icon left>download</v-icon>SVG
              </v-btn>
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-card-text id="container">
        <template>
          <span class="black--text text--darken-1">
            {{ $t('useQRCodeQrCodeOperator') }}
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=amlabs.touchpay.operator"
            >
              {{ $t('qrCodeOperator') }}
            </a>
          </span>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="show = false">{{ $t('closeQrCodeOperator') }}</v-btn>
      </v-card-actions>
    </v-card>

    <template #activator="{ on }">
      <slot
        name="activator"
        :on="on"
        :loading="loading.retrieving"
      ></slot>
    </template>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    posId: { type: Number, default: null },
  },
  data() {
    return {
      show: false,
      loading: {
        retrieving: false,
        saving: false,
      },
    };
  },
  computed: {
    qrCodeIdPos() {
      return `{ "id":${this.posId} }`;
    },
  },
  methods: {
    downloadPng() {
      const qrcode = document
        .getElementsByTagName("canvas")[0]
        .toDataURL("image/png")
        .replace(/^data:image\/[^;]/, "data:application/octet-stream");

      const link = document.createElement("a");

      link.href = qrcode;
      link.target = "_blank";
      link.download = "qrcode.png";
      link.click();

      URL.revokeObjectURL(link.href);
    },
    downloadSvg() {
      const qrcode = document.getElementsByTagName("svg")[0].outerHTML;

      const blob = new Blob([qrcode], {
        type: "image/svg+xml",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.target = "_blank";
      link.download = "qrcode.svg";
      link.click();

      URL.revokeObjectURL(link.href);
    },
  },
  components: {
    QrcodeVue,
  },
  directives: { mask },
};
</script>

<style scoped>
#container {
  text-align: center;
}
</style>