export default {
  "promotion": {
    "name": "Promotion name",
    "type": "Promotion type",
    "starts-on": "Starts on",
    "expires-on": "Expires on",
    "date-created": "Date of creation",
    "usage": "Number of uses",
    "actions": "Actions",
    "no": "No",
    "created": "Promotion created",
    "edited": "Promotion edited",
    "no-items": "No promotions registered",
    "remove": {
      "label": "Delete",
      "message": "Are you sure you want to remove the promotion?",
      "success": "Promotion successfully removed",
      "fail": "Error removing promotion",
    },
    "period": "Promotion period",
    "details": {
      "new": {
        "text": "New promotion",
        "error": "Error saving promotion",
      },
      "edit": {
        "text": "Edit promotion",
        "error": "Error editing promotion"
      },
      "title": "Promotion {description}",
      "description": "Brief description of the promotion",
      "starts-on": "Start date",
      "starts-on-label": "Promotion start date",
      "expires-on": "End date",
      "expires-on-label": "Promotion end date",
      "expires-on-no-date": "No expiration",
      "points-of-sale": {
        "all": "Apply to all points of sale",
        "specific": "Apply to specific points of sale",
        "specific-error": "Choose at least one point of sale",
        "choose": "Choose the points of sale where it will be applied",
      },
      "product": {
        "add": "Add products",
        "name": "Product",
        "name-label": "Choose a product",
        "items": "Number of items per purchase",
        "items-label": "Product units",
        "button-add": "Add product",
        "error": {
          "required": "The promotion must have at least one product",
        },
      },
      "discount": {
        "type": {
          "text": "Discount type",
          "label": "In percentage or fixed amount"
        },
        "invalid": "Invalid discount value",
        "amount": {
          "label": "Amount to be applied",
          "percentage": "Value in percentage",
          "fixed": "Fixed amount in",
        },
        "choose": "Select the discount type",
      },
      "missing-data": "There is missing data",
    }
  }

}