export default {
  filters: {
    filtersActions: 'Filters and Actions',
    labels: {
      all: 'All',
      period: 'Period',
      pos: 'POS',
      onlySubtractedItems: 'Only subtracted items',
      advanced: 'Advanced',
      calendar: "Calendar",
      inventoryType: 'Inventory Type',
      central: 'Central',
      search: 'Search',
      searchProducts: 'Search a product',
      months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
      },
      customer: 'Customer',
      location: 'Location',
      locationSpecific: 'Location Specific',
      pointOfSale: 'Point of Sale',
      result: 'Result',
      minimumPrice: 'Minimum Price',
      maximumPrice: 'Maximum Price',
      product: 'Product',
      lastCardDigits: 'Last Card Digits',
      date: 'Date',
      initialDate: 'Initial Date',
      finalDate: 'Final Date',
      initialHour: 'Initial Hour',
      finalHour: 'Final Hour',
      verificationStatus: 'Verification Status',
      viewOnlyTransactionItemsSubtracted: 'View only transaction with subtracted items',
      viewProfit: 'View Profit',
      accessDetails: 'Access Details',
      audit: 'Audit',
      actions: 'Actions',
      accessResult: 'Access Result',
      operationType: 'Operation Type',
      lastMovement: 'Last movement',
    },
    messages: {
      selectMonth: "Select a month",
      selectCustomer: 'Select a customer',
      selectLocation: 'Select a location',
      selectResult: 'Select result to view',
      selectOperationType: 'Select an operation type',
    },
    timelineDateAlert: 'Maximum interval of three days',
    orderSearch: 'Sort / Search',
    status: 'Status',
    customerName:'Customer Name',
    inventory: 'Inventory',
    lastSale: 'Last sale',
    creationDate: 'Creation date (Point of sale ID)',
    order: 'Sort',
    sort: {
      asc: 'Ascending',
      desc: 'Descending',
    }
  },
}