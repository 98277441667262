
import Vue from "vue";
import agent from "@/api/agent";
import moment from "moment";
import DropzoneOptions from "./DropzoneOptionsPfxP12";
import SingleDatePicker from "@/components/filters/SingleDatePicker.vue";
import CompaniesDropDown from "@/components/filters/CompaniesDropDown.vue";
import CancelButton from "@/components/buttons/CancelButton.vue";
import NextButton from "@/components/buttons/NextButton.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import LoadFiles from "@/components/LoadFiles.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CompanyInputHelp from "./CompanyInputHelp.vue";
import { DigitalCertificateRequest } from "@/types/Invoices";

interface FormType {
  password: string;
  expirationDate: string | null;
  companyId: string;
}

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  components: {
    LoadFiles,
    SingleDatePicker,
    CompaniesDropDown,
    SaveButton,
    CancelButton,
    NextButton,
    BackButton,
    CompanyInputHelp,
  },
  data() {
    return {
      valid: false as Boolean,
      loading: false as Boolean,
      step: 1 as Number,
      fileUploaded: false as Boolean,
      showPassword: false as Boolean,
      minDate: moment().format("YYYY-MM-DD") as string,
      form: {} as FormType,
      request: {} as DigitalCertificateRequest,
      certificateUrl: "api/invoicesweb/certificate-url" as string,
      filename: "" as string,
      dropzoneOptions: DropzoneOptions,
      colors: {
        background: "#4E009B" as string,
        text: "#ffffff" as string,
      } as Object,
      rules: {
        required: (v: string) => !!v || "Este campo é obrigatório.",
        integer: (v: string) => /^\d+$/.test(v) || "Insira apenas números inteiros",
      },
    };
  },
  computed: {
    dialog: {
      get(): Boolean {
        return this.value;
      },
      set(value: Boolean): void {
        this.form = {} as FormType;
        this.step = 1;
        this.$emit("close", value);
      },
    },
  },
  watch: {
    'form.companyId': function (val: string): void {
      if (val){
        this.filename = this.form.companyId + '.pfx';
      }
      else this.filename = '';
    }
  },
  methods: {
    onFileUploaded(uploaded: Boolean): void {
      this.fileUploaded = uploaded;
    },
    uploadCertificate(): void {
      if ((this as any).$refs.form.validate()) {
        this.loading = true;
        this.request = this.createRequestFromForm();

        agent.Invoices.postDigitalCertificate(this.request)
          .then(() => {
            this.form = {} as FormType;
            this.step = 1;
            this.$emit("close", true);
          })
          .catch((error: string) => {
            (this as any).$refs.feedback.handleError("Erro ao enviar certificado", error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createRequestFromForm(): DigitalCertificateRequest {
      return {
        companyId: this.form.companyId,
        expirationDate: this.form.expirationDate as string,
        password: this.form.password,
        filename: this.filename as string,
      };
    },
  },
});
