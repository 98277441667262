export default {
    titleInvoiceManual: 'INFORMACIÓN DE LA FACTURA',
    infoInvoiceManual: 'Complete con los datos de la factura',
    numberInvoiceManual: 'Número de la factura',
    keyInvoiceManual: 'Clave de acceso (NFe)',
    dateEmmitedInvoiceManual: 'Fecha de emisión',
    closeInvoiceManual: 'CERRAR',
    nextInvoiceManual: 'SIGUIENTE',
    validMessageInvoiceManual: 'Para continuar, complete todos los datos',
    invoiceManualHelpTitle: "Información de la nota",
    invoiceManualHelp: "Complete con los datos de la factura, número de la nota, clave de acceso (NFe) y fecha de emisión.",
    invoiceManualHelpNumberText: 'Representa el número de la factura.',
    invoiceManualHelpNumber: 'Número de la Factura:',
    invoiceManualHelpKey: 'Clave de acceso (NFe):',
    invoiceManualHelpKeyText: 'Representa la clave de acceso de la factura. Nuestro sistema no permite subir dos facturas con la misma clave de acceso.',
    invoiceManualHelpDate: 'Fecha de emisión:',
    invoiceManualHelpDateText: 'Representa la fecha de emisión de la factura.',

}
