export default {
    configureFiscalNote: 'Configurar nota fiscal (NFC-e)',
    companyCnpj: 'CNPJ de la empresa',
    enterCnpj: 'Ingresa el CNPJ que emitirá la NFC-e para este PDV',
    noRegisteredCompanies: 'No hay empresas registradas.',
    disableInvoiceConfig: 'Desactivar',
    issuanceAlreadyDisabled: 'La emisión ya está desactivada',
    closeInvoiceConfig: 'Cerrar',
    saveInvoiceConfig: 'Guardar',
    nfceActivatedSuccessfully: '¡NFC-e activada correctamente para el PDV!',
    unableToActivateNfce: 'No se pudo activar la NFC-e',
    nfceDeactivatedSuccessfully: 'NFC-e desactivada correctamente',
    unableToDeactivateNfce: 'No se pudo desactivar la NFC-e',
    activateAnyway: 'Activar de todos modos',
};
