  
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      translations: {
        help: {
          about: {
            title: this.$t('franchisee.help.about.title').toString(),
            description: this.$t('franchisee.help.about.description').toString(),
          },
        }
      },
    }
  }
});
