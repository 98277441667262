export default {
    dialogTitleNewGrouping: 'New grouping',
    dialogTitleEditProductGroup: 'Edit grouping',
    closeProductGroup: 'Close',
    saveProductGroup: 'Save',
    codeProductGroup: 'Code',
    descriptionProductGroup: 'Description',
    categoryProductGroup: 'Category',
    defaultPriceProductGroup: 'Default price',
    associatedProductsProductGroup: 'Associated products',
    saveProductGroupError: 'Error saving product',
    saveItemsProductGroupError: 'Error saving item',
  };