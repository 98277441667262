export default {
  validations: {
    minCodeLength: "El código debe tener {length} caracteres",
    requiredField: "Este campo es obligatorio",
    insertValidValue: "Inserte un valor válido",
    chooseAction: "Elija al menos una acción",
    invalidEIN: "Ingrese un EIN válido",
    invalidCNPJ: "Ingrese un CNPJ válido",
    invalidNIT: "Ingrese un NIT válido",
    insertInteger: "Inserte un número entero",
    invalidEmail: "Ingrese un correo electrónico válido",
    invalidPositiveValue: "Ingrese un valor positivo",
  }
};