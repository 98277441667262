export default {
    addressStateFilterNamesTranslations: 'Estado',
    localIdFilterNamesTranslations: 'Localização',
    posIdFilterNamesTranslations: 'Ponto de venda',
    productIdFilterNamesTranslations: 'Id do produto',
    dateIntervalFilterNamesTranslations: 'Intervalo de dias:',
    productCategoryIdFilterNamesTranslations: 'Id da categoria',
    inventoryIdFilterNamesTranslations: 'Id do estoque',
    typeFilterNamesTranslations: 'Tipo de operação',
    customerIdFilterNamesTranslations: 'Id do cliente',
};
  