
import Vue from 'vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import {formatDateTimeToDate} from "@/utility/TextFormatting";

export default Vue.extend( {
    data() {
        return {
            menu: false,
            rules:{
                integer: (v : string) => Number.isInteger(Number(v)) || this.$i18n.t('rulesNumbers'),
            },
        }
    },
    props: {
        invoice: { type: Object , required: true},
    },
    components: {
       InventoriesDropDown,
    },
    methods: {
        NextStep(){
            this.$emit('update', this.invoice);
        },
        closeDialog(){
            this.$emit("close");
        },
        formIsComplete(): Boolean{
            return (Number.isInteger(Number(this.invoice.nfeNumber)) && Number.isInteger(Number(this.invoice.key)) && this.invoice.nfeNumber != '' && this.invoice.key != '' 
                && this.invoice.dateEmitted != '' && this.invoice.inventoryId != null && this.invoice.nfeNumber != '');
        },
    
    },
    computed: {
    formattedDate: function (): string {
      return formatDateTimeToDate(this.invoice.dateEmitted, true);
    },
    }
})
