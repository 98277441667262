<template>
  <help>
    <template #title>{{ $t('planogramMainHelpTitle') }}</template>
    <p>
      {{ $t('introParagraphHelp') }}
    </p>
    <h3>{{ $t('headingHelp') }}</h3>
    <p>
      {{ $t('descriptionPosHelp') }}
    </p>
    <p>
      {{ $t('additionalInfoHelp') }}
    </p>
    <h3>{{ $t('addSeveralHelp') }}</h3>
    <p>
      {{ $t('selectPosHelp') }}
    </p>
    <h3>{{ $t('modifyingProductHelp') }}</h3>
    <p>
      {{ $t('selectingProductsToModifyHelp') }}
    </p>

    <help-video  help-code="Planogram"/>
  </help>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
