
import Vue from 'vue';
import TenantFeedback from '@/components/TenantFeedback.vue';

export default Vue.extend({
  components: {
    TenantFeedback
  },

  data() {
    return {
      dialog: false as boolean,
      dialogWidth: 800 as number,
    }
  },

  methods: {
    closeFeedback() {
      this.dialog = false;
    }
  }
})
