export default {
    myInfo: 'My Info',
    phone: 'Phone',
    dateOfBirth: 'Date of Birth',
    dateFieldPlaceHolder: 'dd/mm/yyyy',
    updateInfoDialogText: 'Are you sure you want to update your information?',
    infoUpdatedMessage: 'Information updated successfully',
    editButton: 'Edit',
    onlyNumbers: 'Only numbers',
    registrationData: 'Registration Data',
    registrationDataSubtitle: 'Always keep your data up to date to ensure authenticity and facilitate communication with your account!',
 }