import { Country, DateTimePrecision } from '@/constants/enums';
import moment from 'moment';

/**Format a given value (any) to "-", if it´s null */
export function formatPlaceHolder(value: any): string {
    return value ? value : '-'
}

/**Format a given string to CNPJ mask. */
export function formatCnpj(cnpj: string): string {
    return (cnpj) ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : "-"
}

/**Format a given string to CPF mask. */
export function formatCpf(cpf: string): string {
    return (cpf) ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : "-"
}

/**Format a given date in string format to date format. */
export function formatDateStringToDate(dateStr: string, emptyResponse: boolean = false): Date | null {
    return moment(dateStr, 'DD/MM/YYYY', true).isValid() ? moment(dateStr, 'DD/MM/YYYY').toDate() : emptyResponse ? null : null
}

/**Format a given datetime to date only. */
export function formatDateTimeToDate(date: Date | string, emptyResponse: boolean = false): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : emptyResponse ? "" : "-"
}

/**Format a given datetime with minutes precision. */
export function formatDateTimeMinutes(date: Date): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm') : "-"
}

/**Format a given datetime with seconds precision. */
export function formatDateTimeSeconds(date: Date, emptyResponse: boolean = false): string {
    return moment(date).isValid() ? moment(date).format('DD/MM/YYYY HH:mm:ss') : emptyResponse ? "" : "-" 
}

/**Format a given datetime to its time in the day */
export function formatDateTimeToTimeInDay(date: Date): string {
    return moment(date).isValid() ? moment(date).format('HH:mm:ss') : ''
}

/**Format a given datetime to YMD format (It´s used on UI components) */
export function formatDateTimeToYmdDate(date: Date): string {
    return moment(date).format("YYYY-MM-DD")
}

/**Format a given number to Brazilian REAL */
export function formatCurrencyReal(currency: number | null): string {
    return currency != null ? 'R$ ' + currency.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '-' 
}

/**Format a given number to US Dollar */
export function formatCurrencyUsDollar(value: number | null): string {
    return value != null ? '$' + value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-';
}

/**Format a given number to Percentage */
export function formatPercentage(number: number): string {
    return number !== null ? (number * 100).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-' 
}
/**Format a given last 4 digit Card Holder Number */
export function formatCardHolder(cardHolder: string | null): string {
    return cardHolder ? `*-*-*-${cardHolder}` : "-"
}
export function truncateEmail(email: string): string {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
        const userName = email.slice(0, atIndex + 1);
        return userName + "...";
    } else {
        return email; 
    }
  }

/**Format a given CNPJ or CPF to digits only. */
export function formatCnpjOrCpfToNumbersOnly(value: string): string {
    return value.replace(/[^\d]/g, '');
}

/** Return an employer identification mask according to a given country  */
export function employerIdentificationMask(country: Country, defaultMask: string): string {
  switch (country)
  {
    case Country.BR:
      return "##.###.###/####-##";
    case Country.US:
      return "##-#######";
    default:
      return defaultMask;
  }
}

/** Return an employer identification placeholder according to a given country  */
export function employerIdentificationPlaceholder(country: Country, defaultPlaceHolder: string = ""): string {
  switch (country)
  {
    case Country.BR:
      return "00.000.000/0000-00";
    case Country.US:
      return "00-0000000";
    default:
      return defaultPlaceHolder;
  }
}

/**
 * Displays a date formatted based on the tenant's country and precision level.
 * If the date is invalid, it will return a default or empty response.
 *
 * @param date - The `Date` object to format.
 * @param country - The tenant's country code to determine the date format.
 * @param emptyResponse - (Optional) If true, returns an empty string for invalid dates. Defaults to false (returns `'-'`).
 * @param precision - (Optional) The precision level for formatting the date. Defaults to `DateTimePrecision.Minutes`.
 * @returns A formatted date string or a default value if the date is invalid.
 */
export function displayDateFormatted(
  date: Date | string,
  country: Country,
  emptyResponse: boolean = false,
  precision: DateTimePrecision = DateTimePrecision.Minutes
): string {
  if (!moment(date).isValid()) {
    return emptyResponse ? "" : "-";
  }

  let baseFormat: string;
  switch(country)
  {
    case Country.BR:
    case Country.CO: 
      baseFormat = 'DD/MM/YYYY';
      break;
    case Country.US:
      baseFormat = 'MM/DD/YYYY';
      break;
    default:
      return emptyResponse ? "" : "-";
  }

  switch (precision) {
    case DateTimePrecision.DateOnly:
      return moment(date).format(`${baseFormat}`);
    case DateTimePrecision.Hours:
      return moment(date).format(`${baseFormat} HH`);
    case DateTimePrecision.Minutes:
      return moment(date).format(`${baseFormat} HH:mm`);
    case DateTimePrecision.Seconds:
      return moment(date).format(`${baseFormat} HH:mm:ss`);
    default:
      return emptyResponse ? "" : "-";
  }
}