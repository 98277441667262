export default {
    dialogTitleNewGrouping: 'Novo agrupamento',
    dialogTitleEditProductGroup: 'Editar agrupamento',
    closeProductGroup: 'Fechar',
    saveProductGroup: 'Salvar',
    codeProductGroup: 'Código',
    descriptionProductGroup: 'Descrição',
    categoryProductGroup: 'Categoria',
    defaultPriceProductGroup: 'Preço padrão',
    associatedProductsProductGroup: 'Produtos associados',
    saveProductGroupError: 'Erro ao salvar produto',
    saveItemsProductGroupError: 'Erro ao salvar item',
  };
  