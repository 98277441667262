export default {
    productNameDraftEdition: 'Product',    
    productImageDraftEdition: 'Image',
    selectionDraftEdition: 'Selection',
    productDescriptionDraftEdition: 'Product name',
    priceDraftEdition: 'Price',
    capacityDraftEdition: 'Max. capacity',
    desiredQuantityDraftEdition: 'Desired quantity (par level)',
    criticalMinimumDraftEdition: 'Critical minimum',
    actionsDraftEdition: 'Actions',
    removeItemDraftEdition: 'Remove item',
    addMultipleDraftEdition: 'Add multiple',
    saveDraftEdition: 'Save',
    cancelDraftEdition: 'Cancel',
    cancelEditPromptDraftEdition: 'Are you sure you want to cancel the quick edit? Changes will be lost',
    noProductsDraftEdition: 'No products registered',
    fixErrorsDraftEdition: 'Fix the errors before saving',
    successSaveDraftEdition: 'Planogram edited successfully',
    saveFailureDraftEdition: 'There was a failure saving the planogram',
  };