export default {
  transferPointOfSale: 'Transferir punto de venta a otro CNPJ',
  transferProcessDescription: 'El proceso de transferencia de titularidad consiste en transferir una tienda de un CNPJ a otro. Para garantizar que este proceso se realice de manera fluida, siga los siguientes pasos. Después de enviar la solicitud, podrá seguir el progreso del proceso de transferencia en el menú ',
  linkApproveTransfers: 'Aprobar Transferencias.',
  transfer: 'Transferencia',
  pointsOfSaleToBeTransferred: 'Puntos de venta a ser transferidos',
  pointsOfSaleHelpTitle: 'Puntos de venta',
  pointsOfSaleHelpDescription: 'Seleccione los puntos de venta primarios (con planograma) para transferir al nuevo CNPJ. Las máquinas secundarias asociadas (que comparten el mismo planograma) se transferirán automáticamente.',
  cnpjOfDestination: 'CNPJ al cual debe ser transferido',
  helpTitle: 'Información del Titular de Destino',
  helpDescription: 'Para completar el cambio de titularidad, es necesario proporcionar el CNPJ de la empresa y los datos completos de la persona responsable de recibir este cambio. Incluya el nombre y el número de teléfono de contacto para que el proceso se realice correctamente y el destinatario pueda ser contactado fácilmente.',
  sameNetworkTransfer: 'Es esta transferencia dentro de la misma red/franquicia',
  yes: 'Sí',
  no: 'No',
  termsAndConditions: 'Términos y Condiciones',
  termsAndConditionsDescription: 'Esta transferencia se refiere solo al POS dentro del sistema y no cambia el lugar donde se acreditará la venta. Para realizar la transferencia de los datos de la máquina de tarjeta, es importante que vea el video a continuación:',
  watchVideo: 'VER VIDEO',
  termsOfResponsibilityPOS: 'TÉRMINOS DE RESPONSABILIDAD PARA LA TRANSFERENCIA DE LA MÁQUINA DE PAGO',
  agreeWithTermsOfResponsibilityPOS: 'Entiendo que soy responsable de transferir la cuenta de la máquina de pago y que solo entonces el CNPJ al que estoy transfiriendo podrá recibir los ingresos.',
  termOfResponsibilityTransfer: 'TÉRMINOS DE RESPONSABILIDAD PARA LA TRANSFERENCIA DE TITULARIDAD',
  agreeWithTermOfResponsibilityTransfer: 'Entiendo que al transferir la cuenta de la máquina de pago, todas las responsabilidades administrativas, operativas y financieras relacionadas con este dispositivo pasarán a ser responsabilidad exclusiva del nuevo titular. Esto incluye, pero no se limita a, tarifas mensuales y visibilidad del POS en la telemetría. Como antiguo titular, ya no tendré acceso a los ingresos de este POS.',
  termsAndConditionsHelp: 'Si tiene alguna duda sobre el proceso, póngase en contacto con nuestro equipo administrativo al ',
  submitRequest: 'Enviar solicitud',
  destinationClientName: 'Nombre de la persona que recibirá el intercambio',
  destinationClientPhoneNumber: 'Número de teléfono de la persona que recibirá el intercambio',
  titleDestination: 'Ingrese el CNPJ y los datos de contacto de la persona que recibirá el cambio de titularidad'
};
