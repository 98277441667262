export default {
  franchisee: {
    response: {
      groups: {
        search: {
          error: "Error al buscar grupos"
        },
        access: {
          error: "Error al acceder a grupos"
        }
      }
    },
    data: {
      title: "Cierre Mensual"
    },
    help: {
      report: {
        description: "El filtro selecciona solo meses del año actual."
      },
      about: {
        title: "Entornos",
        description: `
          <p>
            En esta página, puede visualizar la información principal de cada entorno, incluyendo:
          </p>
          <p>
            <ul>
              <li>
                <strong>ID del Sub Entorno</strong>: Número identificador del sub entorno.
              </li>
              <li>
                <strong>Sub Entorno</strong>: Nombre del sub entorno.
              </li>
              <li>
                <strong>Facturación (últimos 30 días)</strong>: Valor total facturado por el sub entorno en los últimos 30 días.
              </li>
              <li>
                <strong>Cantidad de Máquinas</strong>: Total de máquinas asociadas al sub entorno.
              </li>
              <li>
                <strong>Cantidad de Tiendas</strong>: Total de tiendas vinculadas al sub entorno.
              </li>
              <li>
                <strong>Promedio de NPS</strong>: Evaluación de la satisfacción de los clientes basada en el Net Promoter Score (NPS).
              </li>
            </ul>
          </p>
          <p>
            Utilizando el botón "Cierre Mensual", podrás visualizar la información compilada del mes seleccionado.
          </p>
        `
      }
    }
  },
}