<template>
  <v-layout wrap>
    <portal v-if="activeTab" to="toolbar">
      <app-pos-selector style="width: 100%" class="mr-2"></app-pos-selector>
    </portal>
    <v-flex xs12>
      <v-expansion-panels v-model="expansionIndex">
        <v-expansion-panel :key="1">
          <v-expansion-panel-header>
            <div><v-icon>filter_list</v-icon> {{$t('filters.filtersActions')}}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <v-form
              ref="form"
              class="px-4"
              v-model="valid"
              @submit.prevent="updateTable()"
            >
              <v-layout row wrap>
                <v-flex sm12 md3 class="search-field">
                  <v-flex sm12 class="search-field">
                    <v-menu
                      class="pr-2"
                      lazy
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px">
                      <template #activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :label="$t('tables.headers.date')"
                          :rules="[rules.required]"
                          required
                          v-model="form.formattedDate"
                          prepend-icon="date_range">
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.pickerDate"
                        no-title
                        scrollable
                        actions
                        locale="pt-br"
                        :change="(form.formattedDate = formatDate(form.pickerDate))">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="clearDate">{{ $t('buttons.clear') }}</v-btn>
                        </v-card-actions>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex sm12 class="search-field">
                    <v-menu
                      class="pr-2"
                      lazy
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px">
                      <template #activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :label="$t('tables.headers.initialHour')"
                          :rules="[rules.required]"
                          required
                          v-model="form.formattedStartTime"
                          prepend-icon="date_range">
                        </v-text-field>
                      </template>
                    </v-menu>
                  </v-flex>
                  <v-flex sm12 class="search-field">
                    <v-menu
                      lazy
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px">
                      <template #activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :label="$t('tables.headers.finalHour')"
                          :rules="[rules.required]"
                          required
                          v-model="form.formattedEndTime"
                          prepend-icon="event_available">
                        </v-text-field>
                      </template>
                    </v-menu>
                  </v-flex>
                </v-flex>
                <v-flex align-content-center sm12 class="search-field">
                  <div class="text-right mb-2">
                    <v-btn
                      class="mx-2"
                      text
                      @click.native="clearQuery"
                      color="error"
                      :disabled="!valid">
                      <v-icon left>clear</v-icon>{{ $t('buttons.clear') }}
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      type="submit"
                      color="success"
                      :disabled="!valid">
                      <v-icon left>refresh</v-icon>{{ $t('buttons.update') }}
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
    <v-flex xs12>
      <v-data-table
        :headers="headers"
        :items="videos"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 mb-10"
        must-sort
        :no-data-text="$t('tables.noData')"
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }">
        <template #item.date="{ item }">
          {{ formatDate(item.startTime) }}
        </template>
        <template #item.startTime="{ item }">
          {{ formatTime(item.startTime) }}
        </template>
        <template #item.endTime="{ item }">
          {{ formatTime(item.endTime) }}
        </template>
        <template #item.events="{ item }">
          <v-icon small :color="getEventColor(item.events)">
            {{ getEventIcon(item.events, item.flags) }}
          </v-icon>
        </template>
        <template #item.timing="{ item }">
          {{ getFormattedTiming(item.totalSeconds) }}
        </template>
        <template #item.actions="{ item }">
          <v-btn icon class="mx-0" @click="action(item)">
            <v-icon small :color="getActionsColor(item.available)">
              {{ getActionsIcon(item.available, item.url) }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from "moment";
import { shouldRefreshData } from "@/components/TableOptionsComparator";
import AppBarPointOfSaleSelector from "@/components/AppBarPointOfSaleSelector";

export default {
  props: {
    activeTab: { type: Number, default: 0 },
    customer: { type: Number, default: null }
  },
  data() {
    return {
      expansionIndex: 0,
      locations: [],
      pointOfSaleId: parseInt(this.$route.query.pdv),
      valid: false,
      headers: [
        { text: this.$t('tables.headers.event'), align: "left", sortable: true, value: "events" },
        { text: this.$t('tables.headers.channel'), align: "left", sortable: true, value: "channel" },
        { text: this.$t('tables.headers.date'), align: "left", sortable: true, value: "date" },
        { text: this.$t('tables.headers.initialHour'), align: "left", sortable: false, value: "startTime" },
        { text: this.$t('tables.headers.finalHour'), align: "left", sortable: false, value: "endTime" },
        { text: this.$t('tables.headers.duration'), align: "left", sortable: true, value: "timing" },
        { text: this.$t('tables.headers.actions'), align: "left", sortable: false, value: "actions" },
      ],
      videos: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
      },
      totalItems: 0,
      shouldUpdate: false,
      loading: false,
      updateInterval: null,
      intervalTime: 15000,
      form: {},
      rules: {
        currency: (v) => !v || /^\d\d*(?:\.\d{0,2})?$/.test(v) || this.$t('validations.insertValidValue'),
        required: (v) => !!v || this.$t('validations.requiredField'),
      },
    };
  },
  computed: {
    updateChange() {
      return (this.shouldUpdate == true) && (this.activeTab == 2);
    },
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getVideos();
        }
      },
      deep: true,
    },
    updateChange(hasToUpdate) {
      if (hasToUpdate == true) {
        this.getVideos();
      }
    },
    "form.pickerDate": function (newDate) {
      this.form.date = newDate;
    },
    '$route'(to, from) {
      if (to != from) {
        this.pointOfSaleId = parseInt(to.query.pdv);
        this.videos = [];
        this.totalItems = 0;
      }
    },
    pointOfSaleId() {
      this.setUpdateInterval();
      if (this.activeTab == 2) {
          this.shouldUpdate = true;
      }
    }
  },
  mounted() {
    const date = new Date();
    const startTime = date.setHours(8, 0, 0);
    const endTime = date.setHours(18, 0, 0);

    this.form = this.newForm();

    this.form.pickerDate = moment(date).format("YYYY-MM-DD");
    this.form.formattedStartTime = moment(startTime).format("HH:mm:ss");
    this.form.formattedEndTime = moment(endTime).format("HH:mm:ss");
  },
  methods: {
    updateTable() {
      this.setUpdateInterval();
      if (this.activeTab == 2) {
        this.getVideos();
      }
    },
    getVideos() {
      if (!this.pointOfSaleId) {
        const message = this.$t('security.deviationAlert.messages.error.choosePosInitialSearch');
        this.sendFeedBack('error', message);
        clearInterval(this.updateInterval);
        this.shouldUpdate = false;
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.updateDateTime();

        this.axios
          .get(`api/Video/${this.pointOfSaleId}`, {
            params: {
              startTime: this.form.startTime,
              endTime: this.form.endTime,
            },
          })
          .then((response) => {
            this.videos = response.data.videoInformation;
            this.totalItems = response.data.totalItems;
          })
          .catch((error) => {
            this.sendFeedBack('error', error);
            clearInterval(this.updateInterval);
          })
          .then(() => {
            this.loading = false;
            this.shouldUpdate = false;
          });
      }
    },
    downloadVideo(item) {
      if (this.$refs.form.validate()) {
        this.axios
          .get(`api/Video/${this.pointOfSaleId}/${item.filePath}`, {
            params: {
              startTime: item.startTime,
              endTime: item.endTime,
            },
          })
          .then(() => {
            const message = this.$t('security.deviationAlert.messages.success.downloadRealizeSuccess');
            this.sendFeedBack('success', message);
          })
          .catch((error) => {
            this.sendFeedBack('error', error);
          });
      }
    },
    setUpdateInterval() {
      if (this.updateInterval) clearInterval(this.updateInterval);

      this.updateInterval = setInterval(() => {
        if (this.activeTab == 2) this.shouldUpdate = true;
      }, this.intervalTime);
    },
    clearQuery() {
      this.form = this.newForm();
      this.getVideos();
    },
    newForm() {
      return {
        date: null,
        startTime: null,
        endTime: null,
        pickerDate: null,
      };
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    formatTime(time) {
      return moment(time).format("HH:mm:ss");
    },
    clearDate() {
      this.form.formattedDate = null;
      this.form.pickerDate = null;
      this.form.date = null;
    },
    clearMinDate() {
      this.form.startTime = null;
    },
    clearMaxDate() {
      this.form.endTime = null;
    },
    updateDateTime() {
      this.form.date = this.form.pickerDate;
      this.form.startTime = moment(
        this.form.date + " " + this.form.formattedStartTime
      ).format("YYYY-MM-DD HH:mm:ss");
      this.form.endTime = moment(
        this.form.date + " " + this.form.formattedEndTime
      ).format("YYYY-MM-DD HH:mm:ss");
    },
    getFormattedTiming(totalSeconds) {
      return new Date(totalSeconds * 1000).toISOString().substr(11, 8);
    },
    getEventIcon(event, flag) {
      if (event && flag === "Event") {
        return "mdi-walk";
      } else {
        return "mdi-clock";
      }
    },
    getEventColor(event) {
      if (event) {
        return "orange darken-2";
      } else {
        return "blue darken-2";
      }
    },
    getActionsIcon(available, url) {
      if (available && url) {
        return "mdi-video";
      } else {
        return "mdi-download";
      }
    },
    getActionsColor(available) {
      if (available) {
        return "blue darken-2";
      } else {
        return "green darken-2";
      }
    },
    action(item) {
      if (item.available) {
        window.open(item.url, "_blank");
      } else {
        this.downloadVideo(item);
      }
    },
    sendFeedBack(type, content) {
      this.$emit('feedbackSent', {
        type: type,
        content: content
      });
    }
  },
  components: {
    "app-pos-selector": AppBarPointOfSaleSelector,
  },
};
</script>