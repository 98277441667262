export default {
    searchCategory: 'Procurar categoria',
    noCategories: 'Sem categorias cadastradas',
    confirmDeleteCategory: 'Tem certeza que deseja excluir a categoria',
    cancelCategory: 'Não',
    removeCategory: 'Remover',
    categoryName: 'Categoria',
    invalidCharacters: 'Insira caracteres válidos',
    categoryRemovedCategory: 'Categoria removida',
    errorRemovingCategory: 'Erro ao remover categoria',
    categoryNameNotChanged: 'Nome não alterado: caracteres inválidos no nome',
    errorChangingName: 'Erro ao alterar o nome',
    myCategories: 'Minhas Categorias',
    actionsCategory: 'Ações',
    idCategory: 'ID',
    numberOfProductsCategory: 'Nº de produtos',
  };
  