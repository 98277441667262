export default {
    lossesProductAndPosHelpTitle: 'Loss indicator: Products',
    lossesProductAndPosHelpIntroduction: `This indicator offers a detailed analysis of financial losses associated with specific products or points of sale,
    allowing rapid identification of the main factors contributing to these losses.`,    
    lossesProductAndPosHelpP1: `By default, our system will display:
    <li> Information on <strong>product losses</strong>. However, when selecting <strong>"View by POS"</strong>, the indicator will show losses by Point of Sale.</li>
    <li> Loss information for <strong>reason "Expired validity"</strong>. However, when selecting another <strong>"Motive"</strong>, the indicator will show losses according to the selected reason.</li>`,
    lossesProductAndPosHelpProduct: `<strong>Top 5 Products with the Biggest Financial Loss: </strong>
    This report highlights the five products that are causing the biggest financial losses in your inventory.
    Identifying these products can help you better understand the reasons behind losses and take corrective action such as price adjustments,
    stock control or supplier review.`,
    lossesProductAndPosHelpPos: `<strong>Top 5 Points of Sale with the Biggest Financial Loss: </strong>
    In this report, you will find the five points of sale that are recording the biggest financial losses. 
    This may be indicative of operational or management issues that need to be addressed, such as safety issues, waste or the need for staff training.`,
    lossesProductAndPosHelpInf: `<strong>Important: </strong>
    The filter "Point of Sale", located at the top of the page, will be available soon. However, the "Date" filter is now available for this card.`,
   };