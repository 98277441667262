<template>
  <div>
  <v-dialog persistent v-model="dialog" max-width="600" @keydown.esc="dialog = false">
    <v-card>
      <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="send">
        <v-card-title>
          {{ $t('dialogTitlePushNotificationDialog') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('subtitlePushNotificationDialog') }}
        </v-card-subtitle>
        <v-card-text>
          <v-alert v-if="!$auth.check('White Label')" class="text-left" dense outlined text type="info" border="left">
            {{ $t('nonWhiteLabelPushCharge') }}
          </v-alert>
          <v-alert v-if="noAppOnThisPos" class="text-left" dense outlined text type="error" border="left">
            {{ $t('noAppForSendingPush') }}
          </v-alert>
          <v-textarea v-model="message" :label="$t('messageLabelPushNotificationDialog')" rows="2" counter
            :rules="[(v) => !!v || $t('requiredRules')]"></v-textarea>
          <v-col cols="12" sm="15">
            <editable-image
              ref="pushImage"
              :image-url="pushUrl"
              entity-type="notification"
              :entity-id="'0'"
              @create="(entityId, url) => pushUrl = url"
              @delete="(entityId, url) => pushUrl = null"
            >
            </editable-image>
          </v-col>
          <v-card-subtitle>
            {{ $t('imageSubtitlePushNotificationDialog') }}
          </v-card-subtitle>
          <v-checkbox v-model="defaultNotification" :label="$t('defaultNotificationLabel')"
            :hint="$t('defaultNotificationHint')" class="pr-2"></v-checkbox>
          <v-menu
            class="pr-2"
            close-on-content-click
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            max-width="290px"
            min-width="290px"
          >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              :label="$t('expirationLabelPushNotificationDialog')"
              :value="date != null ? $moment(date).format('DD/MM/YYYY') : ''"
              readonly>
            </v-text-field>
          </template>
            <v-date-picker v-model="date" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date = null">{{ $t('clearLabelPushNotificationDialog') }}</v-btn>
            </v-date-picker>
          </v-menu>
        <v-row cols="12">
            <v-col cols="3">
              <v-checkbox
                :label="$t('applyAllPosLabelPushNotificationDialog')"
                v-model="selectAllPos"
              ></v-checkbox>
            </v-col>
            <v-col cols="9">
              <v-autocomplete
                :label="$t('applySelectedPosLabel')"
                v-model="selectedPosIds"
                item-text="text"
                item-value="id"
                :items="pointsOfSale"
                :loading="loading.pos"
                v-if="!selectAllPos"
                multiple
                small-chips
              ></v-autocomplete>
            </v-col>
        </v-row>
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">{{ $t('closeLabelPushNotificationDialog') }}</v-btn>
          <v-btn type="submit" :disabled="noAppOnThisPos && selectedPosIds.length === 0" :loading="sending" color="primary">{{ $t('sendLabelPushNotificationDialog') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
  <feedback ref="feedback"></feedback>
  </div>
</template>

<script>
import EditableImage from '@/components/EditableImage';

export default {
  components: {EditableImage},
  props: {
    value: { type: Boolean, required: true },
    posId: { type: Number, default: null }
  },
  mounted(){
    this.getPointOfSale()
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    dialog: function (enabled) {
      if (enabled) {
        this.valid = true;
			} else {
        this.pushUrl = null;
        this.$refs.pushImage.cancelClicked();
        this.$refs.form.reset();
        this.defaultNotification = false;
        this.sending = false;
      }
    },
    selectAllPos: function (q) {
      if (q) {
        this.pointsOfSale.map((i) => {
          this.selectedPosIds.push(i.id)
        })
      } else {
        this.selectedPosIds = []
      }
    },
    posId: function(posId) {
      if (posId) {
        if (this.pointsOfSale.map(i => i.id).includes(posId)) {
          this.selectedPosIds = [posId];
          this.noAppOnThisPos = false;
        }
        else {
          this.noAppOnThisPos = true;
        }
      }
    }
  },
  data() {
    return {
      valid: true,
      message: null,
      sending: false,
      pushUrl: null,
      defaultNotification: false,
      date: null,
      selectAllPos: false,
      selectedPosIds: [],
      loading: {
          pos: false
      },
      pointsOfSale: [],
      noAppOnThisPos: false
    }
  },
  methods: {
    send() {
      this.sending = true;
      const pushNotification = {
        body: this.message,
        imageUrl : this.pushUrl,
        defaultNotification: this.defaultNotification,
        expiration: this.date,
        pointOfSaleIds: this.selectedPosIds
      };
      if ((this.pushUrl == null || this.pushUrl == '') && (this.message == null || this.message == '')) {
        this.$refs.feedback.handleError(this.$t('requiredMessageOrImage'));
        this.sending = false;
      }
      else {
      this.axios.post(`api/pushNotifications`, pushNotification , { params : { pointOfSaleIds: pushNotification.pointOfSaleIds }})
        .then(response => {
          const push = response.data;
          const totalCount = push.results.length;
          if (push.success && push.successCount > 0) {
            this.$refs.feedback.handleSuccess(`${this.$t('messageSentPushNotificationDialog')} ${push.successCount} ${this.$t('usersOfPushNotificationDialog')} ${totalCount} ${this.$t('activePushNotificationDialog')}`);
            this.dialog = false;
          } else if (push.successCount > 0) {
            this.$refs.feedback.handleInfo(`${this.$t('messageSentPushNotificationDialog')} ${push.successCount} ${this.$t('usersOfPushNotificationDialog')} ${totalCount} ${this.$t('activePushNotificationDialogError')}` +
            `${this.$t('notificationsNotSent')} ${push.errorMessage}`);
            this.dialog = false;
          } else {
            this.$refs.feedback.handleError(this.$t('thereAreNoUsersWithPermission'));
          }
        }).catch(error => {
          this.$refs.feedback.handleError(this.$t('feedbackErrorPushNotificationDialog'), error);
        }).then(() => {
          this.sending = false;
        })
    }
  },
  getPointOfSale() {
    this.loading.pos = true;
    this.axios.get('api/PointsOfSale?hasApp=true')
    .then((response) => {
        this.loading.pos = false;
        this.pointsOfSale = response.data.map(item => {
        item.text = `(${this.$t('getPointOfSaleItem')} ${item.id})`
        + (item.localCustomerName != null ? ` ${item.localCustomerName}` : '')
        + ` - ${item.localName}`
        + (item.specificLocation != null ? ` - ${item.specificLocation}` : '');
        return item;
      });
    });
  }
}
}
</script>
