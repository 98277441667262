const weekDayTranslations = {}

weekDayTranslations['Monday'] = "Segunda-Feira";
weekDayTranslations['Tuesday'] = "Terça-Feira";
weekDayTranslations['Wednesday'] = "Quarta-Feira";
weekDayTranslations['Thursday'] = "Quinta-Feira";
weekDayTranslations['Friday'] = "Sexta-Feira";
weekDayTranslations['Saturday'] = "Sábado";
weekDayTranslations['Sunday'] = "Domingo";

export { weekDayTranslations };