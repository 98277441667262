export default {
  salesByWeekdayTitle: 'Ventas por día de la semana',
  salesByWeekdaySubtitle: 'Siga el rendimiento semanal de las ventas',
  thisWeek: 'Esta semana',
  previousWeek: 'Semana pasada',
  average: 'Promedio por día',
  sundayShort: 'Dom.',
  mondayShort: 'Lun.',
  tuesdayShort: 'Mar.',
  wednesdayShort: 'Mié.',
  thursdayShort: 'Jue.',
  fridayShort: 'Vie.',
  saturdayShort: 'Sáb.',
  salesByWeekdayGetError: 'Hubo un error al cargar las ventas por día de la semana',
  salesByWeekdayHelpTitle: 'Indicador de Ventas por Día de la Semana',
  salesByWeekdayHelpText:`
  <p style="text-align: justify;">
    Entender cómo se comportan sus ventas a lo largo de la semana es esencial para el éxito de su negocio.
    Este indicador proporciona una vista de las ventas diarias, comparando el rendimiento entre la semana actual
    y la semana anterior, y también proporciona un promedio para un análisis más preciso.
  </p>
  <p style="text-align: justify;">
    <strong>Cómo Interpretar:</strong>
    <ul>
      <li style="text-align: justify">
        <strong>Semana Actual:</strong> Esta columna muestra las ventas de cada día de esta semana en comparación con los días correspondientes
        de la semana anterior. Puede ver de inmediato si ha habido un aumento o disminución en las ventas en comparación con la semana anterior.
      </li>
      <li style="text-align: justify">
        <strong>Semana Pasada:</strong> Aquí encontrará las ventas diarias de la semana anterior. Comparar estos números con
        la semana actual puede resaltar patrones de comportamiento del consumidor y ayudar a predecir tendencias futuras.
      </li>
      <li style="text-align: justify">
        <strong>Promedio:</strong> La línea de promedio entre las semanas proporciona una referencia estable para el rendimiento general.
        Esto ayuda a suavizar las fluctuaciones diarias y proporciona una comprensión más clara del patrón general de ventas.
      </li>
    </ul>
  </p>
  <p style="text-align: justify;">
    <strong>Sugerencias sobre cómo utilizar esta información:</strong>
    <ul>
      <li style="text-align: justify">
        Identifique los días de la semana con mayor y menor rendimiento de ventas.
      </li>
      <li style="text-align: justify">
        Ajuste la asignación de recursos según las tendencias de ventas.
      </li>
      <li style="text-align: justify">
        Monitoree regularmente para identificar cambios significativos en el comportamiento del consumidor.
      </li>
      <li style="text-align: justify">
        Haga un seguimiento del progreso hacia los objetivos de ventas semanales.
      </li>
    </ul>
  </p>
  `

}
