<template>
  <div class="login-background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
    <v-row align="center" justify-center no-gutters>
      <v-col cols="12" md="5">
        <v-card height="100vh" class="cards flex-container">
          <v-card-text>
            <v-row>
              <img id="login-logo" src="../../public/assets/logo-new.svg"/>
            </v-row>
            <v-row>
              <span id="product-name">Login suporte</span>
            </v-row>
            <v-form ref="form" v-if="!isTotp" v-model="valid" lazy-validation @submit.prevent="login" class="cards flex-item">
            <v-row row wrap>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  id="email-input"
                  autocomplete="username"
                  type="email"
                  name="username"
                  autofocus
                  label="Email"
                  v-model="form.email"
                  :rules="[v => !!v || 'Preencha seu e-mail.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  id="password-input"
                  autocomplete="current-password"
                  name="password"
                  label="Senha"
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[v => !!v || 'Preencha a senha.']"
                  :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  id="Id ambiente-input"
                  prepend-icon="mdi-briefcase"
                  label="ID ambiente"
                  v-model="form.tenantId"
                  :rules="[v => /^\d+$/.test(v) || 'Preencha o ID com um número inteiro.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  id="Id grupo-input"
                  prepend-icon="mdi-account-group"
                  label="ID grupo (opcional)"
                  v-model="form.groupId"
                  :rules="[(v) => !v || /^\d+$/.test(v) || 'Insira um número inteiro']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <div v-if="error" style="color:red">{{ error }}</div>
                <v-switch v-bind:label="` Manter-me conectado`" v-model="keepLogged" light></v-switch>
              </v-col>
            </v-row>
            <v-row cols="12" class="px-0" justify="center">
              <v-col cols="12">
                <v-btn
                  color="info"
                  type="submit"
                  :loading="loading"
                  block
                  :disabled="!valid"
                >
                  Entrar
                </v-btn>
              </v-col>
            </v-row>
            </v-form>
            <topt-validation
              v-model="isTotp"
              :user-id="userId"
              :temp-token="tempToken"
              :support-login-extensions="supportLoginExtensions"
            ></topt-validation>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ToptValidation from '@/components/TotpValidation.vue';
  export default {
    data() {
      return {
        dialog: true,
        keepLogged: '',
        valid: true,
        error: null,
        form: {
          email: null,
          password: null,
          tenantId: null,
          groupId: null
        },
        showPassword: false,
        backgroundImage: null,
        loading: null,
        isTotp: false,
        tempToken: null,
        userId: null,
        supportLoginExtensions: null
      };
    },
    mounted() {this.getImage()},

    methods: {
      login() {
        if (!this.$refs.form.validate()) return;
        this.supportLoginExtensions = {
          enableSupportLogin: true,
          tenantId: this.form.tenantId,
          groupId: this.form.groupId
        };
        const loginInfo = {
          data: {
            email: this.form.email,
            password: this.form.password,
            supportLoginExtensions: this.supportLoginExtensions
          },
          rememberMe: this.keepLogged,
        }
        this.$auth.login(loginInfo)
          .catch(error => {
            if (error.response?.status === 423 && error.response?.data?.twoFactorRequired) {
              this.isTotp = true;
              this.userId = error.response.data.userId;
              this.tempToken = error.response.data.tempToken;
              this.rememberMe = this.keepLogged;
            }
            else if (error.message === "Network Error") {
              this.error = "Sistema indisponível, tente novamente mais tarde";
            } else {
              this.error = "Usuário ou senha inválida";
            }
          });
      },
      getImage() {
        this.axios.get('api/messages/LoginImage')
          .then((response) => {
            this.backgroundImage = (response.data[0].content)
          })
      }
    },
    components: {
      ToptValidation
    }
  };
</script>

<style scoped>
@media (min-width: 960px)
{
  .v-sheet.v-card {
    border-radius: 0px 30px 30px 0px;
  }
}

#product-name {
  color:#0087C8;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-item {
  flex-grow: 1
}
</style>
