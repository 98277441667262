
import Vue from 'vue';
import EquipmentList from './EquipmentList.vue';
import MidasCloseOrder from './MidasCloseOrder.vue';
import SuccessOrder from './SuccessOrder.vue';


export default Vue.extend({
  components: {
    MidasCloseOrder,
    EquipmentList,
    SuccessOrder
  },
  data() {
    return {
      page: "catalog" as "catalog" | "finishOrder" | "npsFeedback",
      orderProducts: [] as any[]
    }
  },
  methods: {
    getNewOrder(orderProducts: any[]) {
      this.orderProducts = orderProducts
      this.page = "finishOrder";
    }
  }
})
