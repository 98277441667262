
import Vue from "vue";
import { BoxAuthentication } from '@/api/agent';
import NewBoxHelp from "@/views/box/helpers/NewBoxHelp.vue";

export default Vue.extend({
  data() {
    return {
      minLength: 8,
      code: "" as string,
      newCode: "" as string,
      valid: false as boolean,
      loading: false as boolean,
      message: null as string | null,
      messageColor: null as string | null,
      messageIcon: null as string | null
    }
  },
  mounted(){
    this.getCode();
  },
  methods: {
    activate(): void {
      if((this as any).$refs.form.validate()){
        this.loading = true;
        BoxAuthentication.activate(this.code)
          .then(() => {
            this.messageColor = "success";
            this.messageIcon = "check_circle"
            this.message = this.$t('box.activation.response.success').toString();
          })
          .catch(() => {
            this.messageColor = "error";
            this.messageIcon = "error";
            this.message = this.$t('box.activation.response.error').toString()
          })
          .then(() => this.loading = false);
      }
    },
    getCode(): void {
      BoxAuthentication.getCode()
      .then((response) => {
        this.newCode = response;
      });
    }
  },
  components: {
    NewBoxHelp
  }
});
