export default {
    DialogTokenHelpTitle: 'Ajuda do token',
    DialogTokenBody: `Para obter o token da API PagSeguro, basta seguir as etapas abaixo:
    <ul>
        <li>Acesse a sua conta PagSeguro (a mesma conta utilizada para as suas maquininhas);</li>
        <li>No menu lateral, selecione <strong>“Vendas”</strong> e depois desça até encontrar a opção <strong>“Integrações”</strong>, e clique nesta opção;</li>
        <li>Na página de “Integrações” vá até a parte de “Utilização de API’s” e clique no botão <strong>“Gerar Token”</strong>;</li>
        <li>Copie o token gerado na tela ou que foi enviado por e-mail!</li>
    </ul>`,
    DialogTokenMessage:'Se tiver dificuldades clique no link abaixo para assistir a um vídeo explicando detalhadamente como obter essa informação!',

    DialogActivationTitle: 'Orientações para código de ativação:',
    DialogActivationBody: `<p>Para obter seu código de ativação PagSeguro, basta seguir as etapas abaixo:</p>
    <ul>
        <li>Acesse a sua conta PagSeguro (a mesma conta utilizada para as suas maquininhas);</li>
        <li>No menu lateral esquerdo, selecione <strong>"Maquininhas"</strong>;</li>
        <li>Após abrir <strong>"Maquininhas"</strong>, selecione <strong>"Gerenciar Maquininhas"</strong>;</li>
        <li>Após abrir <strong>"Gerenciar Maquininhas"</strong>, você terá nesta tela o seu Código de Ativação, são 6 números!</li>
    </ul>`,
    DialogActivationTitleImg: 'Código de Ativação',
    
};