
import Vue from 'vue';
import { NpsFeedbackRequest } from '@/types/NpsFeedbackRequest'
import { NpsFeedbacks } from '@/api/agent';
import { mask } from 'vue-the-mask';
import moment from "moment";
import PhoneNumberFormatter from '@/utility/PhoneNumberFormatter';

const maskdir = mask;

export default Vue.extend({
  directives: { maskdir },
  mixins: [PhoneNumberFormatter],
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      ratingIndex: null as number | null,
      feedbackRequest: {
        grade: null as number | null,
        comment: "",
        categories: [] as string[],
        contactNumber: "" as string | null
      } as NpsFeedbackRequest,
      positiveFeedbackCategories: [
        { value: "easeOfUse", text: this.$t("npsEaseOfUse") as string },
        { value: "usefulness", text: this.$t("npsUsefulness") as string },
        { value: "goodPerformance", text: this.$t("npsGoodPerformance") as string },
        { value: "effectiveSupport", text: this.$t("npsEffectiveSupport") as string },
        { value: "satisfactoryUpdate", text: this.$t("npsSatisfactoryUpdate") as string },
        { value: "others", text: this.$t("npsOthers") as string },
      ],
      negativeFeedbackCategories: [
        { value: "hardOfUse", text: this.$t("npsHardOfUse") as string },
        { value: "uselessness", text: this.$t("npsUselessness") as string },
        { value: "poorPerformance", text: this.$t("npsPoorPerformance") as string },
        { value: "ineffectiveSupport", text: this.$t("npsIneffectiveSupport") as string },
        { value: "limitedUpdates", text: this.$t("npsLimitedUpdates") as string },
        { value: "others", text: this.$t("npsOthers") as string },
      ],
      maskedContactNumber: "" as string,    
    }
  },
  computed: {
    dialog: {
      get() : boolean {
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value);
      }
    },
    categories(): { value: string, text: string}[] {
      return this.feedbackRequest.grade > 8 ? this.positiveFeedbackCategories : this.negativeFeedbackCategories
    }
  },
  watch: {
    ratingIndex(value) {
      this.feedbackRequest.grade = value - 1;
    },
    maskedContactNumber(value: string) {
      const contactNumber = value.replace(/\D/g, '');
      this.feedbackRequest.contactNumber = contactNumber != "" ? contactNumber : null;
    },
    "feedbackRequest.grade"(newValue, oldValue) {
      if (newValue > 8 && oldValue <= 8 || newValue <= 8 && oldValue > 8) {
        this.feedbackRequest.categories = [];
      }
    }
  },
  methods: {
    async sendNpsFeedback() {
      try {
        await NpsFeedbacks.postNpsFeedback(this.feedbackRequest);

        const localStorageObject = {
          answered: true,
          dateTime: moment()
        }
        localStorage.setItem("npsFeedback", JSON.stringify(localStorageObject))
      }
      finally {
        this.dialog = false
      }
    },
    closeNpsFeedback() {
      if (this.feedbackRequest.grade == null) {
        const localStorageObject = {
          answered: false,
          dateTime: moment()
        }
        localStorage.setItem("npsFeedback", JSON.stringify(localStorageObject))
        
        this.dialog = false
      }
      else {
        this.sendNpsFeedback()
      }
    },
  }
})
