export default {
  salesByWeekdayTitle: 'Sales by weekday',
  salesByWeekdaySubtitle: 'Track the weekly sales performance',
  thisWeek: 'This week',
  previousWeek: 'Last week',
  average: 'Average per day',
  sundayShort: 'Sun.',
  mondayShort: 'Mon.',
  tuesdayShort: 'Tue.',
  wednesdayShort: 'Wed.',
  thursdayShort: 'Thu.',
  fridayShort: 'Fri.',
  saturdayShort: 'Sat.',
  salesByWeekdayGetError: 'There was an error loading sales by weekday',
  salesByWeekdayHelpTitle: 'Sales by Weekday Indicator',
  salesByWeekdayHelpText:`
  <p style="text-align: justify;">
    Understanding how your sales behave throughout the week is essential for the success of your business.
    This indicator provides a view of daily sales, comparing performance between the current week
    and the previous week, and also provides an average for more precise analysis.
  </p>
  <p style="text-align: justify;">
    <strong>How to Interpret:</strong>
    <ul>
      <li style="text-align: justify">
        <strong>Current Week:</strong> This column shows the sales for each day of this week compared to the corresponding days
        of the previous week. You can immediately see if there has been an increase or decrease in sales compared to the previous week.
      </li>
      <li style="text-align: justify">
        <strong>Previous Week:</strong> Here, you will find the daily sales from the previous week. Comparing these numbers with
        the current week can highlight consumer behavior patterns and help predict future trends.
      </li>
      <li style="text-align: justify">
        <strong>Average:</strong> The average line between the weeks provides a stable reference for overall performance.
        This helps smooth out daily fluctuations and provides a clearer understanding of the overall sales pattern.
      </li>
    </ul>
  </p>
  <p style="text-align: justify;">
    <strong>Suggestions on how to use this information:</strong>
    <ul>
      <li style="text-align: justify">
        Identify the days of the week with the highest and lowest sales performance.
      </li>
      <li style="text-align: justify">
        Adjust resource allocation based on sales trends.
      </li>
      <li style="text-align: justify">
        Regularly monitor to identify significant changes in consumer behavior.
      </li>
      <li style="text-align: justify">
        Track progress towards weekly sales goals.
      </li>
    </ul>
  </p>
  `
}
