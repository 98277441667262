<template>
  <v-list>
    <v-list-item>
      <v-autocomplete
        v-model="pageToGo"
        :label="$t('searchPageMobile')"
        :items="availablePages"
        item-text="name"
        item-value="route"
        @input="navigateToPage"
        clearable
        prepend-inner-icon="search"
      ></v-autocomplete>
    </v-list-item>
    <v-list-group>
      <template #activator>
        <v-list-item-icon>
          <v-icon style="font-size: 40px;" color="amber">mdi-star-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('favoritesMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in favoritePages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="favoritePages.length == 0">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content>{{ $t('savedPagesMenuMobile') }}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsTelemetry || userIsRemoteMonitoring">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-01-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('telemetryMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in telemetryPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="showSalesButton">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-02-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('salesMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in salesPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsSupplier">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-03-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('stockMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in logisticsPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="!userIsRemoteMonitoring">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-04-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('generalMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in generalPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsRegister">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-05-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('registersMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in registerPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsAppAdministrator">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-06-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('crmMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in crmPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsSecurity || userIsRemoteMonitoring">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-07-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('securityMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in securityPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="userIsManager">
      <template #activator>
        <v-list-item-icon>
          <img src="../../public/assets/nav-drawer-icons/icon-08-color.svg" alt="" width="40" height="40"/>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('financialMenuMobile') }}</v-list-item-title>
      </template>
      <v-list-item v-for="page in billingPages" :key="page.name" flat :to="page.route" v-show="page.permission">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group v-if="$tenants.hasClaim($auth.user(), 'master' || 'pagseguro')">
      <template #activator>
        <v-list-item-icon>
          <img class="amlabs-icon" src="../../public/assets/icons/amlabs-icon.png" alt=""/>
        </v-list-item-icon>
        <v-list-item-title>AMLabs</v-list-item-title>
      </template>
      <v-list-item v-for="page in amlabsPages" :key="page.name" flat :to="page.route">
        <v-list-item-icon></v-list-item-icon>
        <v-list-item-content class="subtitle-2">{{page.name}}</v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  props: {
    favoritePages: { type: Array, required: true },
    generalPages: { type: Array, required: true },
    salesPages: { type: Array, required: true },
    logisticsPages: { type: Array, required: true },
    telemetryPages: { type: Array, required: true },
    registerPages: { type: Array, required: true },
    crmPages: { type: Array, required: true },
    securityPages: { type: Array, required: true },
    billingPages: { type: Array, required: true },
    amlabsPages: { type: Array, required: true },
    showSalesButton: { type: Boolean, required: true },
    userIsSupplier: { type: Boolean, required: true },
    userIsTelemetry: { type: Boolean, required: true },
    userIsRegister: { type: Boolean, required: true },
    userIsAppAdministrator: { type: Boolean, required: true },
    userIsSecurity: { type: Boolean, required: true },
    userIsManager: { type: Boolean, required: true },
    userIsSupport: { type: Boolean, required: true },
    userIsRemoteMonitoring: {type: Boolean, required: true}
  },
  data() {
    return {
      pageToGo: null,
      favoritePagesRoutes: []
    }
  },
  mounted() {
    this.getFavoritePages();
  },
  computed: {
    availablePages() {
      let pages = !this.userIsRemoteMonitoring ? [...this.generalPages] : [];

      if(this.showSalesButton) pages = pages.concat(this.salesPages);
      if(this.userIsSupplier) pages = pages.concat(this.logisticsPages);
      if(this.userIsTelemetry || this.userIsRemoteMonitoring) pages = pages.concat(this.telemetryPages);
      if(this.userIsRegister) pages = pages.concat(this.registerPages);
      if(this.userIsAppAdministrator) pages = pages.concat(this.crmPages);
      if(this.userIsSecurity || this.userIsRemoteMonitoring) pages = pages.concat(this.securityPages);
      if(this.userIsSupport) pages = pages.concat(this.billingPages);
      if(this.$tenants.hasClaim(this.$auth.user(), 'master' || 'pagseguro')) pages = pages.concat(this.amlabsPages);

      pages = pages.filter(page => page.permission);

      return pages
    },
  },
  methods: {
    navigateToPage() {
      if (this.pageToGo != null) {
        this.$router.push(this.pageToGo);
        this.pageToGo = "";
      }
    },
    getFavoritePages() {
      this.axios.get("api/FavoritePages")
        .then((response) => this.favoritePagesRoutes = response.data)
        .catch((error) => alert(error))
    }
  }
}
</script>

<style>

</style>
