export default {
  tables: {
    headers: {
      id: "Id",
      pointOfSaleId: "ID PDV",
      actions: "Acciones",
      customer: "Cliente",
      location: "Ubicación",
      specificLocation: "Ubicación específica",
      idSubEnvironment: "ID del Sub Entorno",
      subEnvironment: "Sub Entorno",
      cnpj: "CNPJ",
      billing: "Facturación (últimos 30 días)",
      machines: "Cant. de máquinas",
      stores: "Cant. de tiendas",
      nps: "Promedio de NPS",
      name: "Nombre",
      type: "Tipo",
      lastUpdated: "Fecha de actualización",
      items: "Ítems",
      activate: "Activar",
      remove: "Eliminar",
      code: "Código",
      description: "Descripción",
      barCode: "Código de barras",
      category: "Categoría",
      cost: "Costo",
      price: "Precio",
      markUp: "Markup",
      responsible: "Responsable",
      applyPriceTableOnPointOfSale: "Aplicar tabla de precios en puntos de venta",
      removePriceTable: "Eliminar tabla de precios",
      visualizeFranchiseeEnvironment: "Visualizar Ambiente",
      result: 'Resultado',
      value: 'Valor',
      card: 'Tarjeta',
      verificationStatus: 'Estado de Verificación',
      event: 'Evento',
      channel: 'Canal',
      day: 'Día',
      dateHour: 'Fecha/Hora',
      date: 'Fecha',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      duration: 'Duración',
      ip: 'IP',
      users: 'Usuarios',
      parameters: 'Parámetros',
      lockCode: 'Código de Bloqueo',
      lockName: 'Nombre de Bloqueo',
      details: 'Detalles',
      deviceName: 'Nombre del Dispositivo',
      openingResponsible: 'Responsable de Apertura',
      cpf: 'CPF',
      ageRestriction: 'Restricción de Edad',
      status: 'Estado',
      product: 'Producto',
      productCode: 'Código del Producto',
      productCategory: 'Categoría del Producto',
      quantity: 'Cantidad',
      cmv: 'CMV',
      margin: 'Margen',
      selection: 'Selección',
      nit: "NIT",
      ein: "EIN",

      associatedPos: 'PDV Asociado',
      inventory: 'Inventario',
      inventoryAddress: 'Inventario Dirección',
      lastInventory: 'Último Inventario',
      lastPurchaseCost: 'Costo Últ. Compra',
      meanCost: 'Costo Medio',
      totalCost: 'Costo Total',
      reserved: 'Reservado',
      minimumCritical: 'Mínimo Crítico',
      minimumQuantity: 'Cant. Mín. Compra',

      previousQuantity: 'Cantidad Anterior',
      originInventory: 'inventario de Origen',
      movimentQuantity: 'Cantidad Movida',
      destinyInventory: 'inventario de Destino',
      endQuantity: 'Cantidad Final',
      operation: 'Operación',
      operationId: 'ID de la Operación',

      email : "Email",
      active: "Active",
      wiFiPercentage: 'Wi-Fi (%)',
      mobilePercentage: 'Mobile (%)',
      offlinePercentage: 'Offline (%)',
      pointOfSale: 'Punto de Venta',
      productDescription: 'Desc. del Producto',
      dateOfReading: 'Fecha de Lectura',
      pointOfSaleReading: 'PDV Leído',
      typeOfAlert: 'Tipo de Alerta',
    },
    noData: "Sin resultados",
    none: "Sin datos",
    total: "Total"
  }
}