import {defineStore} from "pinia";
import agent from "@/api/agent";
import {ref} from "vue";
import {useAlertsStore} from "@/stores/alertsStore";
import {ConsolidatedCatalog} from "@/types/MidasProductsOrders";

export const useAcquisitionOrdersStore = defineStore('acquisitionOrders', () => {
  const alertsStore = useAlertsStore()

  const consolidatedCatalog = ref<ConsolidatedCatalog>();

  const loadingConsolidatedCatalog = ref(false);

  async function getConsolidatedCatalog() {
    loadingConsolidatedCatalog.value = true;
    await agent.AcquisitionOrders.getConsolidatedCatalog()
      .then((response) => {
        consolidatedCatalog.value = response;
      })
      .catch((error) => alertsStore.showErrorSnackbar(error.response.data))
      .finally(() => loadingConsolidatedCatalog.value = false);
  }

  return {
    consolidatedCatalog,
    loadingConsolidatedCatalog,
    getConsolidatedCatalog
  }
})
