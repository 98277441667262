
import Vue from 'vue';

export default Vue.extend({
  name: "UpdateButton",
  props: {
    valid: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    applyFilters() {
      this.$emit('refresh');
    }
  }
});
