export default {
    requiredField: 'Este campo es obligatorio',
    numberInteger: 'Ingrese un número entero',
    commonCharacters: 'Ingrese caracteres válidos',
    minLength: 'Ingrese al menos {min} caracteres',
    noWhitespace:'No se permiten espacios en blanco',
    phoneField: 'Hubo un problema al verificar el número de teléfono. Intente ingresar solo números. Si el problema persiste, comuníquese con la administración al +55 (11) 5199-6668.',
    insertValidValue: 'Ingrese un valor válido',
    percentageMustBeBetween0And100: 'El porcentaje debe estar entre 0 y 100',
};
