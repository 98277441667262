
import Vue from 'vue';
import { MarketIntelligenceAccess } from "@/api/agent"

type CardsToShow =  "SeeMoreCard" | "PolicyCard" | "PosCard";

export default Vue.extend({
  props: {
    /**
     * Prop sent by parent. Means the state of the user access to the Market Intelligence.
     */
    userAccess: { type: Object, required: true }
  },
  data() {
    return {
      cardToShow: "SeeMoreCard" as CardsToShow,
      policyPdf: "",
      privacyPolicyVersion: 0 as number,
      loading: {
        getPrivacyPolicy: false,
        getIncompletePos: false,
        acceptPolicy: false,
        allPosButton: false
      },
      incompletePos: [] as { idPos: number }[],
    }
  },
  mounted() {
    this.getPrivacyPolicy();
  },
  methods: {
    seeMoreCardClick() {
      if (this.userAccess.privacyPolicyVersion == -1 ||
        this.userAccess.privacyPolicyVersion != this.privacyPolicyVersion) {
          this.cardToShow = 'PolicyCard'
        } else {
          this.getIncompletePos();
          this.cardToShow = 'PosCard'
        }
    },
    async getPrivacyPolicy() {
      this.loading.getPrivacyPolicy = true;
      try {
        const response = await MarketIntelligenceAccess.getPrivacyPolicy()
        this.policyPdf = response.url
        this.privacyPolicyVersion = response.version
      }
      catch (error) {
        (this.$refs.feedback as any).handleError(this.$t('getPrivacyPolicyError'), error)
      }
      this.loading.getPrivacyPolicy = false;
    },
    async getIncompletePos() {
      this.loading.getIncompletePos = true;
      try {
        const response = await MarketIntelligenceAccess.getIncompletePos();
        this.incompletePos  = response.map((pos) => ({ idPos: pos }))
      }
      catch (error) {
        (this.$refs.feedback as any).handleError(this.$t('getIncompletePosError'), error)
      }
      this.loading.getIncompletePos = false
    },
    async putUserAuthorization() {
      this.loading.acceptPolicy = true
      try {
        const params = {
          tenantId: (this as any).$auth.user().tenantId,
          groupId: (this as any).$auth.user().tenantGroupId,
          privacyPolicyVersion: this.privacyPolicyVersion
        }
        await MarketIntelligenceAccess.putUserAuthorization(params);

        this.cardToShow = "PosCard"
        this.$emit('userAuthorizedPolicy', this.privacyPolicyVersion)
        this.getIncompletePos()
      }
      catch (error) {
        (this.$refs.feedback as any).handleError(this.$t('putUserAuthorizationError'), error)
        this.loading.acceptPolicy = false
      }
    },
    async putAllPosRegistered() {
      this.loading.allPosButton = true
      const params = { 
        tenantId: (this as any).$auth.user().tenantId,
        groupId: (this as any).$auth.user().tenantGroupId
      };

      try {
        await MarketIntelligenceAccess.putAllPosRegistered(params)
        this.$emit('userIsAllowed');
      } 
      catch (error) {
        (this.$refs.feedback as any).handleError(this.$t('putAllPosRegisteredError'), error);
      }
      this.loading.allPosButton = false
    }
  },
})
