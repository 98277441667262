
import Vue from 'vue';
import moment from "moment";
import { feedbackCategories } from "@/types/TenantFeedback";
import { formatDateTimeToDate } from "@/utility/TextFormatting";

const initialForm = {
  minDate: moment("20230101", "YYYYMMDD").format("YYYY-MM-DD"),
  maxDate: moment().format("YYYY-MM-DD"),
  webPage: "",
  grade: null as null | number,
  categories: [] as string[],
}

export default Vue.extend({
  data() {
    return {
      form: { ...initialForm },
      valid: false,
      feedbackCategories: [...feedbackCategories],
      grades: [1, 2, 3, 4, 5],
      pages: [] as string[],
      minDateMenu: false,
      maxDateMenu: false,
      panel: 0
    }
  },
  computed: {
    minDateFormatted():string {
      return formatDateTimeToDate(this.form.minDate);
    },
    maxDateFormatted():string {
      return formatDateTimeToDate(this.form.maxDate);
    }
  },
  mounted() {
    this.pages = this.$router.options.routes?.map(r => r.path.split("/")[1]) ?? [];
    // first element is always "", an empty string, because "/" is a route.
    // this .shift() removes this first element.
    this.pages.shift();
    this.updateTable();
  },
  methods: {
    updateTable() {
      if ((this.$refs.form as any).validate()) {
        this.$emit("update", this.form)
      }
    },
    clearForm() {
      this.form = { ...initialForm };
    }
  }
})
