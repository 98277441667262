<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="product"
    :items="products"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="displayText"
    item-value="id" 
    :label="labelProductFilter"
    :placeholder="placeholderEnterTheSearchFilter"
    :prepend-icon="icon"
    :required="required"
    :no-data-text="noDataEnterInTheSearchFilter"
    :rules="rules"
    :disabled="disabled"
    :class="displayClass"
    clearable
    @change="changeProduct"
  >
  </v-autocomplete>
</template>

<script>
  import agent from '@/api/agent';
  export default {
    name: "ProductsTaxGroupFilter",
    props: {
      value: { type: Number, default: null },
      label: { type: String, default: null },
      placeholder: { type: String, default: null },
      icon: { type: String, default:'' },
      required: { type: Boolean, default: false },
      noData: { type: String, default: null },
      rules: { type: Array },
      disabled: { type: Boolean, default: false },
      displayClass: { type: String, default: '' },
      posId: { type: Number, default: null },
      taxGroupId: {type: Number, required: true},
      productIdAddedToTaxGroup: {type: Number, required: false}
    },
    computed: {
      product: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      labelProductFilter() {
        return this.label || this.$t('productFilter');
      },
      placeholderEnterTheSearchFilter() {
        return this.placeholder || this.$t('enterTheSearchFilter');
      },
      noDataEnterInTheSearchFilter() {
        return this.noData || this.$t('enterInTheSearchFilter');
      },
    },
    data() {
      return {
        products: [],
        productObject: {},
        isLoading: false,
        search: null,
        options: {
          page: 1,
          pageSize: 30,
          excludingTaxGroupId: this.taxGroupId
        },
      }
    },
    mounted() {
      if (this.product != null) {
        this.productById(this.product);
      }
    },
    rules: {
      nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
      required: (v) => !!v || this.$t('requiredRules'),
    },
    watch: {
      search(val) {
        if (/^\s+/.test(val)) {
          this.product = null;
          return;
        }
        if (val?.length >= 3 && val != this.initialSearch) {
          this.productsBySearch(val);
        }
      },
      product(value) {
        if (value != null && this.products.find(product => product.id == value) === undefined) {
          this.productById(value);
        }
      },
      productIdAddedToTaxGroup(productId) {
        if(productId && productId > 0) {
          const productAdded = this.products.find(p => p.id === productId);
          const entryIndex = this.products.indexOf(productAdded);
          this.products.splice(entryIndex, 1);
        } 
      }
    },
    methods: {
      productsBySearch(value){
        const productFound = this.products.find(p => p.displayText === value);
        if(productFound) {
          return;
        }
        this.isLoading = true;
        const params = {
          ...this.options,
          search: value
        };
        agent.Products.withTaxGroupsMapped(params).then((response) => {
            const products = response.items.map((product) => {
                product.displayText = (product.code !== null ? product.code + " - " : "") + product.description;
                product.searchText = value;
                return product;
            });
            this.products = [...products];
        })
        .catch((error) => {
          this.handleError(`Não foi possível carregar os produtos, tente novamente`, error);
        })
        .finally(() => {
            this.isLoading = false;
        });
      },
      changeProduct() {
        this.$nextTick(() => {
          if (this.value != null) {
            const productObject = this.products.find(product => product.id === this.value);
            this.$emit('product-changed', productObject);
            this.productObject = productObject;
          }
        })
      },
      productById(productId) {
        const searchValue = this.products.find(p => p.id === productId)?.searchText;
        if(!searchValue) {
          return;
        }
        this.isLoading = true;
        const params = {
          ...this.options,
          search: searchValue
        };
        agent.Products.withTaxGroupsMapped(params).then((response) => {
            const products = response.items.map((product) => {
                product.displayText = (product.code !== null ? product.code + " - " : "") + product.description;
                return product;
            });
            this.products = [...products];
        })
        .catch((error) => {
          this.handleError(`Não foi possível carregar o produto, tente novamente`, error);
        })
        .finally(() => {
            this.isLoading = false;
        });
      },
      handleError(message, error) {
          this.$refs.feedback.handleError(message, error);
      },
    },
  }
</script>