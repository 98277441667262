export default {
    purchaseDetailsHelpTitle: 'Purchase Details',
    purchaseDetailsHelpDescription: 'This page lists the imported purchase products and allows the cancellation of the purchase.',
    purchaseDetailsHelpInfo: 'Details:',
    purchaseDetailsHelpInfoDetail: 'For better detail visualization, the data represented in the purchases table is also shown in this tab.',
    purchaseDetailsHelpProductInfo: 'Product Information:',
    purchaseDetailsHelpProductNoteValue: `
    <strong>Total note value:</strong> 
    Represents the total value of the product in the imported invoice.
    `,
    purchaseDetailsHelpProductTotalCost: `
    <strong>Total product cost:</strong> 
    Represents the total cost of the product, including estimated calculated taxes.
    `,
    purchaseDetailsHelpUndoPurchase: 'Undo Purchase:',
    purchaseDetailsHelpUndoInstructions: `To undo the purchase, simply click the <strong>Undo</strong> button in this tab.`,
    purchaseDetailsHelpUndoWarning: 'Once undone, all stock movements made by the purchase will be undone, which is an irreversible operation.'
  };
  