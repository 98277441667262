export default {
    residentialHouse: 'Residential House',
    residentialApartment: 'Residential Apartment',
    businessOffice: 'Business Office',
    businessIndustry: 'Business Industry',
    hospitalLocalType: 'Hospital',
    university: 'University',
    othersLocalType: 'Others',
    vendingSnacks: 'Vending Snacks',
    vendingCafe: 'Vending Coffee',
    vendingEntertainment: 'Vending Entertainment',
    microMarketPosType: 'MicroMarket',
};