export default {
    searchPageMobile: 'Pesquisar página',
    favoritesMenuMobile: 'Favoritos',
    telemetryMenuMobile: 'Telemetria',
    salesMenuMobile: 'Vendas',
    stockMenuMobile: 'Estoque',
    generalMenuMobile: 'Geral',
    registersMenuMobile: 'Cadastros',
    crmMenuMobile: 'CRM',
    securityMenuMobile: 'Segurança',
    financialMenuMobile: 'Financeiro',
    savedPagesMenuMobile: 'Não há páginas salvas',    
    favoritePageMobile: 'Favoritar página',
    toRemovefavoritePageMobile: 'Remover dos favoritos',
  }