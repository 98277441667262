export default {
  salesByDay: 'Total Sales by Day',
  topAndBottonSellingPos: 'Top and Bottom Selling POS',
  topSellingPos: 'Top Selling POS',
  bottomSellingPos: 'Bottom Selling POS',
  salesByPos: 'Total Sales by POS',
  salesByProduct: 'Sales by Product',
  excel: 'Excel',
  totalLowerCase: 'Total',
  productDescription: 'Description',
  productCategory: 'Category',
  defaultPrice: 'Default Price',
  averagePrice: 'Average Price',
  pointOfSaleSystem: 'Payment System',
  modelManufacturer: 'Model / Manufacturer',
  productNotRegistered: 'Unregistered Products',
  salesSummary: 'Sales Summary',
  loading: 'Loading',
  salesReport: 'Sales Report',
  productReport: 'Product Report',
  totalSalesByPos: 'Total Sales by POS',
  productSales: 'Sales by Product',
  searchPlaceholder: 'Search Product',
  quantity: 'Quantity',
  value: 'Value',
  location: 'Location',
};
