import i18n from "@/locales/i18n";
const translations: { [key: string]: string } = {
  'Planogram': i18n.t('audit.actions.planogram.title').toString(),
  'FinishPlanogram': i18n.t('audit.actions.planogram.finishPlanogram').toString(),
  'BulkFinishPlanograms': i18n.t('audit.actions.planogram.bulkFinishPlanograms').toString(),
  'ActivatePlanogram': i18n.t('audit.actions.planogram.activatePlanogram').toString(),
  'BulkActivatePlanograms': i18n.t('audit.actions.planogram.bulkActivatePlanograms').toString(),
  'PostPlanogram': i18n.t('audit.actions.planogram.postPlanogram').toString(),
  'BulkPostPlanogram': i18n.t('audit.actions.planogram.bulkPostPlanogram').toString(),
  'DeletePlanogram': i18n.t('audit.actions.planogram.deletePlanogram').toString(),
  'BulkDeletePlanograms': i18n.t('audit.actions.planogram.bulkDeletePlanograms').toString(),
  'PostPlanogramWithoutUnusedProduct': i18n.t('audit.actions.planogram.postPlanogramWithoutUnusedProduct').toString(),

  'PointsOfSale': i18n.t('audit.actions.pointsOfSale.title').toString(),
  'PutPointOfSale': i18n.t('audit.actions.pointsOfSale.putPointOfSale').toString(),
  'PostPointOfSale': i18n.t('audit.actions.pointsOfSale.postPointOfSale').toString(),
  'DeletePointOfSale': i18n.t('audit.actions.pointsOfSale.deletePointOfSale').toString(),
  'RestoreDeletedPointOfSale': i18n.t('audit.actions.pointsOfSale.restoreDeletedPointOfSale').toString(),

  'PriceTable': i18n.t('audit.actions.priceTable.title').toString(),
  'PostPriceTable': i18n.t('audit.actions.priceTable.postPriceTable').toString(),
  'PatchPriceTable': i18n.t('audit.actions.priceTable.patchPriceTable').toString(),
  'PatchPriceTableType': i18n.t('audit.actions.priceTable.patchPriceTableType').toString(),
  'DeletePriceTable': i18n.t('audit.actions.priceTable.deletePriceTable').toString(),
  'DeletePriceTableItem': i18n.t('audit.actions.priceTable.deletePriceTableItem').toString(),
  'BulkApplyPriceTableNew': i18n.t('audit.actions.priceTable.bulkApplyPriceTableNew').toString(),

  'Products': i18n.t('audit.actions.products.title').toString(),
  'PutUpdateCostFromDistributionCenter': i18n.t('audit.actions.priceTable.putUpdateCostFromDistributionCenter').toString(),
  'PutProduct': i18n.t('audit.actions.products.putProduct').toString(),
  'PostProduct': i18n.t('audit.actions.products.postProduct').toString(),
  'DeleteProduct': i18n.t('audit.actions.products.deleteProduct').toString(),

  'TaxScenarios': i18n.t('audit.actions.taxScenarios.title').toString(),
  'MapProductToTaxGroup': i18n.t('audit.actions.taxScenarios.mapProductToTaxGroup').toString(),
  'AddProductToTaxGroup': i18n.t('audit.actions.taxScenarios.addProductToTaxGroup').toString(),
  'RemoveProductFromTaxGroup': i18n.t('audit.actions.taxScenarios.removeProductFromTaxGroup').toString(),
  'PostBrazilianTaxing': i18n.t('audit.actions.taxScenarios.postBrazilianTaxing').toString(),
  'PutBrazilianTaxing': i18n.t('audit.actions.taxScenarios.putBrazilianTaxing').toString(),
  'DeleteBrazilianTaxGroup': i18n.t('audit.actions.taxScenarios.deleteBrazilianTaxGroup').toString(),

  'Users': i18n.t('audit.actions.users.title').toString(),
  'DeleteUsers': i18n.t('audit.actions.users.deleteUsers').toString(),
  'PutUsers': i18n.t('audit.actions.users.putUsers').toString(),
  'PostUsers': i18n.t('audit.actions.users.postUsers').toString()
};

export function getAuditTranslation(key: string): string {
  return translations[key] || 'Unknown Audit';
}