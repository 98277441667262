import i18n from '@/locales/i18n';

const inventoryOperationTypes = {
  Correction: i18n.t('inventory.operationTypes.correction'),
  Sale: i18n.t('inventory.operationTypes.sale'),
  Purchase: i18n.t('inventory.operationTypes.purchase'),
  Reversed: i18n.t('inventory.operationTypes.reversed'),
  AutomaticCorrection: i18n.t('inventory.operationTypes.automaticCorrection'),
  Supply: i18n.t('inventory.operationTypes.supply'),
  Expired: i18n.t('inventory.operationTypes.expired'),
  Damaged: i18n.t('inventory.operationTypes.damaged'),
  Return: i18n.t('inventory.operationTypes.return'),
  RemovedFromInventory: i18n.t('inventory.operationTypes.removedInventory'),
  Other: i18n.t('inventory.operationTypes.other'),
  ExpirationDateChanged: i18n.t('inventory.operationTypes.expirationDateChanged'),
  ExpirationDateAdded: i18n.t('inventory.operationTypes.expirationDateAdded'),
  ExpirationDateRemoved: i18n.t('inventory.operationTypes.expirationDateRemoved'),
}; 

// obsolete Operation Types not to be used
const allInventoryOperationTypes = {
  ...inventoryOperationTypes,
  Full: "Completo",
  PickList: "Pick-list",
  Repositioning: "Reposicionamento",
  PlanogramChange: "Mudança de Planograma",
  Operation: "Abastecimento/Inventário",
};

export { inventoryOperationTypes, allInventoryOperationTypes };