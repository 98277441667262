export default {
    statusCanceled: 'Cancelada',
    statusActive: 'Activa',
    purchasesHeaderIdOperation: 'ID de la Operación',
    purchasesHeaderStatus: 'Estado',
    purchasesHeaderIdErp: 'ID en ERP',
    purchasesHeaderNoteNumber: 'Número de la Factura',
    purchasesHeaderInventoryId: 'ID del Inventario',
    purchasesHeaderStock: 'Existencias',
    purchasesHeaderQuantityItems: 'Cantidad de Artículos',
    purchasesHeaderNoteValue: 'Valor de la Factura',
    purchasesHeaderTotalCostPurchase: 'Costo Total de la Compra',
    purchasesHeaderReceivedDate: 'Fecha de Recepción',
    purchasesError: 'Error al buscar compras'
  };
  