export default{
  pickLists:{
    title: 'Pick Lists',
    create:{
      title: 'Generar Pick Lists',
      pickListsDescription: 'Descripción de de la Pick List',
      pickListsByCategoryDescription: 'Cuando se agrupe por categoría, cada Pick List tendrá como nombre el nombre de la categoría',
      allProducts: 'Todos los productos del planograma',
      categoryLabel: 'Filtrar productos por categoría',
      productRestriction: 'Restricción de producto',
      criticalLabel: 'Incluir solo productos con stock crítico',
      inventoryAvailable: 'Incluir solo productos con saldo de inventario disponible',
      advancedSection: 'Avanzado',
      secondaryLabel: 'Marcar Pick List como secundaria',
      groupByCategoryLabel: 'Generar una Pick List por categoría',
      groupByCategoryDisabledTooltip: 'Para poder generar una Pick List por categoría, debe marcarla como Pick List secundaria y filtrar por al menos una categoría',
      error: 'No se pudo generar la Pick List',
    }
  }
}
