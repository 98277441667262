export default {
    searchMenu: 'Pesquisar',
    searchPage: 'Pesquisar página',
    favoritesMenu: 'Favoritos',
    telemetryMenu: 'Telemetria',
    salesMenu: 'Vendas',
    stockMenu: 'Estoque',
    generalMenu: 'Geral',
    registersMenu: 'Cadastros',
    crmMenu: 'CRM',
    securityMenu: 'Segurança',
    financialMenu: 'Financeiro',
    savedPagesMenu: 'Não há páginas salvas',
    "Attention": "Atenção",
    newBillingAccessDialogText: `<p>Estamos mudando a tela de <strong style="color:#00B31D">Meus Boletos</strong> para a área do usuário
      <strong>localizada no topo superior da tela</strong>, confira nas instruções a seguir:</p>`,
    "Close": "Fechar",
  }