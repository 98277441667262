export default {
  mostSoldCategoriesTitle: 'Categorías más vendidas',
  mostSoldCategoriesSubtitle: 'Identifica las categorías más populares en tu tienda.',
  mostSoldCategoriesErrorMessage: 'Hubo un error al cargar la información de las categorías más vendidas',
  mostSoldCategoriesHelpTitle: 'Categorías más vendidas',
  mostSoldCategoriesHelpText:
  `
  <p style="text-align: justify;">
    Este indicador muestra las cinco categorías principales de productos vendidos,
    ofreciendo una visión rápida y directa de las áreas más rentables de su negocio.
  </p>
  <p style="text-align: justify;">
    Las categorías se clasifican según el volumen de ventas, lo que permite una fácil identificación de las tendencias de consumo y áreas de oportunidad.
    Utilice esta información para impulsar estrategias de marketing, optimizar el inventario y aumentar sus ventas.
  </p>  
  `
}
