export default {
  userDialogNewUser: 'New User',
  userDialogCreateNewUser: 'Register new user',
  userDialogEditUser: 'Edit user',
  userDialogEmailLabel: 'Email',
  userDialogCheckEmailLabel: 'Confirm Email',
  userDialogPasswordHint: 'If you leave this blank, a password will be generated for you',
  userDialogPasswordLabel: 'Password (optional)',
  userDialogCheckPasswordLabel: 'Confirm Password',
  userDialogPermissions: 'Permissions',
  userDialogAdministrator: 'Administrator',
  userDialogManagement: 'Management',
  userDialogAccounting: 'Accounting',
  userDialogManager: 'Manager',
  userDialogBuildingManager: 'BuildingManager',
  userDialogSales: 'Sales',
  userDialogAllFunctions: 'All Functions',
  userDialogPriceEdit: 'Price Editing',
  userDialogPlanogramView: 'Planogram Viewing',
  userDialogTransactionsView: 'Transactions Viewing',
  userDialogSupply: 'Stock and Supply',
  userDialogTouchpayProvider: 'Touchpay Provider',
  userDialogBlindInventory: 'Blind Inventory and Supply',
  userDialogTelemetry: 'Telemetry',
  userDialogRegistrations: 'Registrations',
  userDialogApp: 'App',
  userDialogSafety: 'Safety',
  userDialogPrivateLabel: 'Private Label',
  userDialogPrivateLabelAdmin: 'Private Label Administrator',
  userDialogVisualization: 'Visualization',
  userDialogInvoices: 'NFCe Invoices',
  userDialogClose: 'Close',
  userDialogSave: 'Save',
  userDialogCreate: 'Create',
  userDialogEmailConfirmationRule: 'The entered email is incorrect',
  userDialogPasswordConfirmationRule: 'The entered password is incorrect',
  userDialogUserCreated: 'User created',
  userDialogGeneratedPassword: 'Generated password: {password}',
  userDialogCreateUserError: 'Could not create user',
  userDialogUserEdited: 'User {userEmail} edited',
  userDialogEditUserError: 'Could not edit user',
  userDialogOrdersManagerRole: 'Orders manager',
  userDialogRemoteMonitoring: 'Remote Monitoring'
}
