
import Vue from 'vue';
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import moment from 'moment';
import { formatDateTimeSeconds, formatPercentage } from '@/utility/TextFormatting';
import {REMOTE_MONITORING_ROLE} from '@/constants/Roles';

export default Vue.extend({
  props: {
    filters: { type: Object, required: true },
    trigger: { type: Boolean, default: false }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getSuspiciousTransactions();
        }
      },
      deep: true,
    },
    trigger: {
      handler(newValue) {
        if(newValue) {
          this.getSuspiciousTransactions();
        }
      },
      immediate: true
    }
  },
  computed: {
    isAllExpanded(): boolean {
      return this.items.length > 0 && this.expanded.length === this.items.length;
    },
    expandAllIcon(): string {
      return this.isAllExpanded ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'
    },
    userIsRemoteMonitoring(){
      return (this as any).$auth.check(REMOTE_MONITORING_ROLE);
    }
  },
  data() {
    const $currencyFormatter = (this as any).$currencyFormatter;
    return {
      loading: false as boolean,
      items: [] as Object[],
      totalItems: 0 as number,
      options: {
        page: 1 as number,
        itemsPerPage: 10 as number,
        sortBy: ['date'] as string[],
        sortDesc: [false] as boolean[],
        mustSort: true as boolean
      },
      headers: [
        { text: this.$t('tables.headers.result'), align: 'left', sortable: true, value: 'result' },
        { text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: true, value: 'pointOfSaleId' },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: false, value: 'pointOfSaleLocalCustomerName' },
        { text: this.$t('tables.headers.location'), align: 'left', sortable: false, value: 'pointOfSaleLocalName' },
        { text: `${this.$t('tables.headers.value')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: true, value: 'paymentAmount' },
        { text: this.$t('tables.headers.dateHour'), align: 'left', sortable: true, value: 'date' },
        { text: this.$t('tables.headers.verificationStatus'), align: 'left', sortable: true, value: 'verificationStatus' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'actions' }        
      ],
      expanded: [] as Object[]
    }
  }, 
  methods: {
    getSuspiciousTransactions() {
      this.loading = true;
      const params = toApiPagination(this.options);
      this.insertFiltersFromForm(params);
      this.axios.get('api/Suspicious/transactions', { params })
        .then((response) => {
          this.items = response.data.items;
          this.totalItems = response.data.totalItems;
          this.items.forEach(i =>  {
            (i as any).available = false;
            (i as any).url = "";
            }
          );
          this.getTransactionsVideos();
          this.loading = false;
        })
        .catch((error) => {
          this.handleError('Erro', error);
          this.loading = false;
        })
    },
    getTransactionsVideos() {
      this.axios.get(`api/Video/Transactions`)
        .then((response) => {
          response.data.forEach((video: any) => {
            const index = this.items.findIndex(i => video.fileName.includes((i as any).uuid));
            if (index >= 0) {
              const item = this.items[index];
              (item as any).available = true;
              (item as any).url = video.url;
              this.$set(this.items, index, item);
            }
          });
        })
        .catch((error) => {
          this.handleError(this.$t('messages.error.unableUpdateStatusTransaction'), error);
        })
        .then(() => {});
    },    
    insertFiltersFromForm(params: any) {
      const form = this.filters;

      params.minDate = moment(form.minDate).format("YYYY-MM-DD HH:mm:ss");
      params.maxDate = moment(form.maxDate).format("YYYY-MM-DD HH:mm:ss");
      params.minDateUtc = moment(form.minDateUtc).format("YYYY-MM-DD HH:mm:ss");
      params.maxDateUtc = moment(form.maxDateUtc).format("YYYY-MM-DD HH:mm:ss");
      params.minTime = moment(form.minDate).format("HH:mm:ss");
      params.maxTime = moment(form.maxDate).format("HH:mm:ss");
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.pointOfSaleId = form.pointOfSaleId;
      params.status = form.status ?? [];
      params.result = form.result;
      params.subtractedItems = form.subtractedItems;

      return params;
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.items;
    },
    getResultIcon(result: string): string {
      if (result == 'Cancelled') {
        return 'mdi-minus-circle';
      } else {
        return 'mdi-check-circle';
      }
    },
    getResultColor(result: string): string {
      if (result == 'Cancelled') {
        return 'red';
      } else {
        return 'green darken-2';
      }
    },
    getResultDescription(result: string): string {
      if (result == 'Cancelled') {
        return 'Cancelado';
      } else {
        return 'Pago';
      }
    },
    getStatusColor(status: string): string {
      if (status == 'Opened') return 'orange lighten-1'
      else if (status == 'Verified') return 'green'
      else if (status == 'HasDeviations') return 'red lighten-1'
      else if (status == 'Lost') return 'grey darken-1'
      else return 'blue'
    },
    formatStatus(oldStatus: string ): string {
      let newStatus = ""
      if (oldStatus == 'Opened') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.pending') as string
      else if (oldStatus == 'Verified') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.verifiedOk') as string
      else if (oldStatus == 'HasDeviations') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.deviation') as string
      else if (oldStatus == 'Refunded') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.refunded') as string
      else if (oldStatus == 'Lost') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.lost') as string

      return newStatus
    },
    handleClick(uuid: string, action: number) {
      let newStatus = 'Opened';
      if (action == 1) {
        newStatus = 'Verified';
      } else if (action == 2){
        newStatus = 'HasDeviations';
      } else if (action == 3) {
        newStatus = 'Refunded';
      } else if (action == 4) {
        newStatus = 'Lost';
      }
      this.axios.put(`api/Suspicious/transactions/${uuid}/Status?newStatus=${newStatus}`)
      .then(() => {
        this.handleSuccess(this.$t('messages.success.updateStatusTransaction') as string);
        this.getSuspiciousTransactions();
      }).catch((error) => this.handleError(this.$t('messages.error.unableUpdateStatusTransaction'), error));
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(error: any, message:string) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    getActionsIcon(available: boolean, url: string) {
      if (available && url) {
        return 'mdi-video';
      } else {
        return 'mdi-download';
      }
    },
    getActionsColor(available: boolean) {
      if (available) {
        return 'blue darken-2';
      } else {
        return 'green darken-2';
      }
    },
    formatDateTimeSeconds,
    formatPercentage
  }

})
