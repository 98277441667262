export default {
  npsDialogQuestionOne: "Qual a chance de você recomendar a AMLabs para outra pessoa?",
  npsCategoriesQuestion: "O que achou da sua experiência?",
  npsLeaveYourFeedback: "Deixe seu feedback. Compartilhe conosco como podemos melhorar:",
  npsEaseOfUse: "Sistema fácil de uso",
  npsGoodPerformance: "Sistema com ótimo desempenho",
  npsUsefulness: "As funcionalidades são adequadas",
  npsEffectiveSupport: "Equipe de suporte eficiente",
  npsSatisfactoryUpdate: "As atualizações do sistema são satisfatórias",
  npsOthers: "Outros",
  npsHardOfUse: "Sistema difícil de utilizar",
  npsUselessness: "Funcionalidades são inadequadas",
  npsPoorPerformance: "Sistema lento",
  npsIneffectiveSupport: "Equipe de suporte ineficaz",
  npsLimitedUpdates: "As atualizações do sistema estão limitadas",
  send: "Enviar"
}