export default {
  clientRequester: 'Client Requester',
  accountPagSeguro: 'PagSeguro Account',
  enableInvoiceIssuanceTitle: 'Do you want an invoice Emission?',
  enableInvoiceIssuanceTrue: 'Yes',
  enableInvoiceIssuanceFalse: 'No',
  apiToken: 'API Token',
  activationCode: 'Activation Code',
  noPaymentProcessorAlert: 'No PagSeguro account available. Register one to select.',
  registerNewAccount: 'Register New Account',
  deliveryAddress: 'Delivery Address',
  address: 'Address',
  registerNewAddress: 'Register New Address',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsText: 'By placing your order, you agree to AMLabs Terms of Use',
  finishOrder: 'Finish Order',
  fantasyName: 'Trade Name',
  billingDocumentId: 'Company CNPJ',
  customerEmail: 'Customer Email',
  customerPhone: 'Customer Phone',
  productAddedToPurchase: 'Product added to purchase',
  midasCloseOrderAddressNumber: 'No.',
  midasCloseOrderAddress: 'Street',
  midasCloseOrderAddressComplement: 'Complement',
  midasCloseOrderCep: 'ZIP Code',
  midasCloseOrderCity: 'City',
  midasCloseOrderState: 'State',
  noAddressAvailableAlert: 'No address available. Register one to select.',
  withdrawAtCompany: 'Pick up at company: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, CEP: 13026-241',
  productSoldByAmLabs: 'Product sold by AMLabs',
  midasCloseOrderQuantity: 'Quantity',
  midasCloseOrderSave: 'Save',
  missingPaymentProcessorError: 'Select a PagSeguro account to finish the order',
  missingAddressInformationError: 'There is missing information in the delivery address. Fill it out to finish the order',
  missingPhoneInformationError: 'There is missing information in the customer phone. Fill it out to finish the order',
  missingEmailInformationError: "Customer email information is missing. Please complete it to finalize the order",
  missingApiTokenInformationError: "API token information is missing. Please complete it to finalize the order",
  missingActivationCodeInformationError: "Activation code information is missing. Please complete it to finalize the order",
  editPagseguroAccount: 'Edit Pagseguro Account',

};
