export default {
    retryTitleBulkRetryInvoice: `Batch reissue`,
    retryDescriptionBulkRetryInvoice: `This function is used to reissue all failed invoices, taking into account the transactions that have occurred
    according to the date filters on this page. You can only reissue notes for transactions that took place in the last 35 days. 
    A maximum of 500 notes will be reissued at a time.`,
    retryWaitBulkRetryInvoice: 'To prevent duplicate notes from being issued, please wait half an hour before using this function again.',
    keepNumberLabelBulkRetryInvoice: 'Keep same sequence number',
    keepNumberHintBulkRetryInvoice: 'We recommend keeping the same sequence number wherever possible.',
    closeBulkRetryInvoice: 'Close',
    retryButtonBulkRetryInvoice: 'Reissue',
    retryErrorBulkRetryInvoice: 'Reissue could not be requested'
  }