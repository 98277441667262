import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';
import es from 'vuetify/es5/locale/es';
import i18n from '@/locales/i18n';

Vue.use(Vuetify);

const dashboard = {
  cardTitleBlue: "#1976d2",
  backgroundGray: "#f0f0f0",
  cardSubtitleGray: "#666666",
  deepBlue: "#00479B",
  dashboardGreen: '#00C8AA',
  dashboardGreenDarken1: '#00A88F',
  dashboardGreenDarken2: '#008B76',
  dashboardGreenDarken3: '#006F5E',
  dashboardGreenDarken4: '#005347',
  barColor: "#00A88F",
  barColorSecondary: 'RGBA(255, 167, 38, 0.5)',
  dashboardRed: '#FF5252',
  dashboardRedDarken1: '#EC9696',
  dashboardRedDarken2: '#D9D9D9',
  dashboardYellow: "#FFA726",
  secondaryYellow: '#ffa72680',
  lightBlue: '#2196F3',
  lightBlue2: '#81C7FF',
  dashboardHelpIconGreen: '#2DCE98'
}

export default new Vuetify({
  lang: {
    locales: { pt,es },
    current: i18n.locale,
  },
  icons: {
    iconfont: 'md',
  },
  i18n: i18n,
  theme: {
    themes: { dashboard },
    options: { customProperties: true }
  },
});
