export default {
    detailsInvoiceDialog: 'NFC-e details',
    statusInvoiceDialog: 'Status: ',
    createdAtInvoiceDialog: 'Date created: ',
    updatedAtInvoiceDialog: 'Date updated: ',
    seriesInvoiceDialog: 'Series: ',
    numberInvoiceDialog: 'Number: ',
    downloadDanfeInvoiceDialog: 'Download DANFE',
    downloadXmlInvoiceDialog: 'Download XML',
    reasonInvoiceDialog: 'Reason: ',
    retryInvoiceDialog: 'Reissue',
    retryConfirmationInvoiceDialog: 'Reissue invoice',
    confirmRetryInvoiceDialog: 'Are you sure you want to reissue the invoice?',
    keepSequentialInvoiceDialog: 'Keep same sequence number',
    keepSequentialRecommendation: 'We recommend keeping the same sequential number whenever possible',
    cancelInvoiceDialog: 'Cancel',
    closeInvoiceDialog: 'Close',
    enableNfceInvoiceDialog: 'To enable the issuance of the Electronic Consumer Invoice (NFC-e),\nplease contact the AMLabs commercial area.',
    okInvoiceDialog: 'Ok',
    processingInvoiceDialog: 'In process',
    approvedInvoiceDialog: 'Approved',
    deniedInvoiceDialog: 'Rejected',
    requestErrorInvoiceDialog: 'Not created',
    unknownProblemInvoiceDialog: 'Unknown problem',
    successMessageInvoiceDialog: 'The invoice was approved',
    errorMessageInvoiceDialog: 'The reissue attempt resulted in the status ',
    retryErrorInvoiceDialog: 'Error reissuing the note'
  }