<template>
  <v-tooltip left open-delay="50" :disabled="enabled">
    <template #activator="{ on }">
      <span v-on="on">
        <v-list-item
          :disabled="!enabled" 
          class="clickable" 
          @click.stop="$emit('click')"
          ><slot></slot>
        </v-list-item>
      </span>
    </template>
    {{ error }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    enabled: { type: Boolean, required: true },
    error: { type: String, required: true }
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer
  }
</style>