export default {
  clientRequester: 'Cliente Solicitante',
  accountPagSeguro: 'Conta PagSeguro',
  enableInvoiceIssuanceTitle: 'Deseja fazer a emissão de nota fiscal?',
  enableInvoiceIssuanceTrue: 'Sim',
  enableInvoiceIssuanceFalse: 'Não',
  apiToken: 'Token da API',
  activationCode: 'Código de Ativação',
  noPaymentProcessorAlert: 'Nenhuma conta PagSeguro disponível. Cadastre uma para selecionar.',
  registerNewAccount: 'Cadastrar Nova Conta',
  deliveryAddress: 'Endereço para Entrega',
  address: 'Endereço',
  registerNewAddress: 'Cadastrar novo endereço',
  termsAndConditions: 'Termos e Condições',
  termsAndConditionsText: 'Ao fazer seu pedido, você concorda com as Condições de Uso da AMLabs',
  finishOrder: 'Finalizar Pedido',
  fantasyName: 'Nome Fantasia',
  billingDocumentId: 'CNPJ da Empresa',
  customerEmail: 'Email do Cliente',
  customerPhone: 'Telefone do Cliente',
  productAddedToPurchase: 'Produto adicionado para compra',
  midasCloseOrderAddressNumber: "Nº",
  midasCloseOrderAddress: "Rua",
  midasCloseOrderAddressComplement: "Complemento",
  midasCloseOrderCep: "CEP",
  midasCloseOrderCity: "Cidade",
  midasCloseOrderState: "Estado",
  noAddressAvailableAlert: "Nenhum endereço disponível. Cadastre um para selecionar.",
  withdrawAtCompany: 'Retirar na empresa: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, CEP: 13026-241',
  productSoldByAmLabs: 'Produto vendido pela AMLabs',
  midasCloseOrderQuantity: 'Quantidade',
  midasCloseOrderSave: 'Salvar',
  missingPaymentProcessorError: 'Selecione uma conta PagSeguro para finalizar o pedido',
  missingAddressInformationError: 'Há informações faltando no endereço de entrega. Preencha-as para finalizar o pedido',
  missingPhoneInformationError: 'Há informações faltando no telefone do cliente. Preencha-as para finalizar o pedido',
  missingEmailInformationError: 'Há informações faltando no email do cliente. Preencha-as para finalizar o pedido',
  missingApiTokenInformationError: 'Há informações faltando no token da API. Preencha-as para finalizar o pedido',
  missingActivationCodeInformationError: 'Há informações faltando no código de ativação. Preencha-as para finalizar o pedido',
  editPagseguroAccount: 'Editar Conta Pagseguro',
};
