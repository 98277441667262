export default {
    retryTitleBulkRetryInvoice: 'Reemisión en lote',
    retryDescriptionBulkRetryInvoice: `Esta función se utiliza para volver a emitir todas las notas en las que hubo un fallo en la emisión, considerando las transacciones que ocurrieron
    según los filtros de fecha de esta página. Solo es posible volver a emitir notas de transacciones que ocurrieron en los últimos 35 días.
    Se volverán a emitir como máximo 500 notas a la vez.`,
    retryWaitBulkRetryInvoice: 'Para evitar que se emitan notas duplicadas, espere media hora antes de utilizar esta función nuevamente.',
    keepNumberLabelBulkRetryInvoice: 'Mantener el mismo número secuencial',
    keepNumberHintBulkRetryInvoice: 'Recomendamos mantener el mismo número secuencial siempre que sea posible.',
    closeBulkRetryInvoice: 'Cerrar',
    retryButtonBulkRetryInvoice: 'Reemitir',
    retryErrorBulkRetryInvoice: 'No se pudo solicitar la reemisión'
}
