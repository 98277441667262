export default {
    helpTitleProductGroupsHelp: 'Agrupamentos de produtos',
    introductionProductGroupsHelp: 'Um agrupamento de produtos serve para gerenciar diferentes variações do produto como se fosse um produto único.',
    exampleExplanationProductGroupsHelp: `Por exemplo, vamos supor que você venda ovos na sua loja, e seu fornecedor tem caixas de ovos da marca A ou da marca B. 
      É importante sempre ter ovos na loja, mas não importa se eles são da marca A ou B. 
      Você então possui os produtos "Ovos A" e "Ovos B" no seu cadastro de produtos. 
      Você poderia ter esses dois produtos no planograma, porém seria difícil gerenciar as Pick Lists, pois sempre apareceriam 
      ambas as marcas de ovos, e você teria que editar manualmente a Pick List para deixar apenas a marca que vai abastecer naquele dia.`,
    solutionExplanationProductGroupsHelp: `O agrupamento de produtos resolve esse problema. Você pode criar um agrupamento "Ovos" e adicionar os produtos "Ovos A" e "Ovos B"
      a esse agrupamento. Então, no seu planograma, você teria um item com o agrupamento ao invés de itens separados para cada produto.
      Dessa maneira, o controle de estoque, Pick Lists e abastecimento é simplificado.`,
    salesRecordExplanationProductGroupsHelp: `Já as vendas são registradas com o produto específico. Assim, no relatório de vendas por produto é possível identificar qual das
      marcas A ou B possui mais aceitação entre os consumidores.`,
  };
  