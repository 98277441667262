export default {
    titleSaveReportMenu: 'Guardar filtros del informe',
    saveFiltersSaveReportMenu: 'Guardar Filtros',
    cancelSaveReportMenu: 'Cancelar',
    saveSaveReportMenu: 'Salvar',
    deleteConfirmationSaveReportMenu: 'Confirmación de eliminación de filtros del informe',
    unableToRemoveSaveReportMenu: 'No se puede eliminar el elemento',
    productSaveReportMenu: 'Producto',
    categorySaveReportMenu: 'Categoría',
    stockSaveReportMenu: 'Stock',
    operationSaveReportMenu: 'Operación',
    daySaveReportMenu: 'Día',
    monthSaveReportMenu: 'Mes',
    dayOfTheWeekSaveReportMenu: 'Día de la Semana',
    customersSaveReportMenu: 'Clientes',
    pointsOfSaleSaveReportMenu: 'Puntos de Venta',
    statesSaveReportMenu: 'Estados',
    paymentMethodsSaveReportMenu: 'Métodos de Pago',
    cardFlagsSaveReportMenu: 'Banderas de Tarjetas',
    savedFiltersSaveReportMenu: 'Filtros Guardados',
    onlyUserFiltersSaveReportMenu: 'Solo se pueden eliminar filtros de usuario',
    filtersSaveReportMenu: 'Filtros: ',
    groupingsSaveReportMenu: 'Agrupamientos: ',
    savedSuccessfullySaveReportMenu: 'guardado con éxito',
    errorWhenSavingFiltersSaveReportMenu: 'Hubo un error al guardar los filtros',
    reportNameSaveReportMenu: 'Nombre del informe',
    filterSaveReportMenu: 'Filtro ',
};
