export default {
  userSearch: 'Pesquisar',
  userNoDataText: 'Sem usuários cadastrados',
  userNoPermitToEdit: 'Você não tem permissão para editar este usuário',
  userNoPermitToDelete: 'Você não tem permissão para excluir este usuário',
  userDeleteConfirmation: 'Tem certeza que deseja remover o usuário {user}?',
  userNo: 'Não',
  userRemove: 'Remover',
  userEmailLabel: 'Email',
  userTenantGroupIdLabel: 'Grupo',
  userRolesLabel: 'Permissões',
  userActionsLabel: 'Ações',
  userDeleteSuccess: 'O acessso do usuário {user} foi bloqueado',
  userDeleteError: 'Erro ao remover usuário'
}