export default {
    purchaseDetailsHelpTitle: 'Detalles de la compra',
    purchaseDetailsHelpDescription: 'Esta página lista los productos de la compra importada y permite la cancelación de la misma.',
    purchaseDetailsHelpInfo: 'Detalles:',
    purchaseDetailsHelpInfoDetail: 'Para una mejor visualización de los detalles, los datos representados en la tabla de compras también se muestran en esta pestaña.',
    purchaseDetailsHelpProductInfo: 'Información del producto:',
    purchaseDetailsHelpProductNoteValue: `
    <strong>Valor total de la nota:</strong> 
    Representa el valor total del producto contenido en la factura importada.
    `,
    purchaseDetailsHelpProductTotalCost: `
    <strong>Costo total del producto:</strong> 
    Representa el costo total del producto, incluyendo el cálculo estimado de impuestos.
    `,
    purchaseDetailsHelpUndoPurchase: 'Deshacer compra:',
    purchaseDetailsHelpUndoInstructions: `Para deshacer la compra, simplemente haga clic en el botón <strong>Deshacer</strong> en esta pestaña.`,
    purchaseDetailsHelpUndoWarning: 'Una vez deshecha, todos los movimientos de stock realizados por la compra serán deshechos, siendo una operación irreversible.'
  };
  