export default {
    productRegistration: 'Registro de productos',
    codeProductHelp: 'Código',
    codeDescriptionProductHelp: 'Código interno del producto (opcional).',
    descriptionProductHelp: 'Descripción',
    descriptionDetailsProductHelp: 'Descripción del producto. Aparecerá en informes e identificará el producto para el usuario final del TouchPay Market.',
    categoryProductHelp: 'Categoría',
    categoryDetailsProductHelp: 'Categoría del producto (opcional). Es útil para la visualización en informes de ventas y puede usarse para generar Pick List para una o más categorías específicas. El usuario final de TouchPay Market también tiene la opción de buscar productos por categoría.',
    defaultPriceProductHelp: 'Precio predeterminado',
    defaultPriceDetails: 'Es el precio que aparecerá de forma predeterminada cuando el producto se agregue a un planograma. El precio del producto en el punto de venta es el precio definido en el planograma, que puede ser diferente al precio predeterminado.',
    PriceDetails: 'Usado para identificar el producto en el micro-market a través del lector de códigos de barras. Vea a continuación cómo registrar códigos de barras cuando el precio del producto es variable.',
    ageRestrictedProductHelp: 'Restringido para menores de edad',
    ageRestrictedDetails: 'Cuando esta opción está marcada, se requerirá una confirmación para el consumidor final del micro-market.',
    variablePriceBarcode: 'Código de barras con precio variable',
    variablePriceBarcodeDetails: 'Los productos que tienen el precio definido por el código de barras generalmente siguen el siguiente patrón: Parte fija seguida del precio en centavos seguido de un dígito verificador. Use el carácter # para los dígitos correspondientes al precio y el carácter * para el dígito verificador.',
    variablePriceBarcodeExample: 'Ejemplo: si un código de barras de un producto se registra como 2345678#####*, y se lee el código 2345678011575 en el lector del punto de venta, el precio calculado será de $11.57.',
};
