
import agent from "@/api/agent";
import {Bill} from "@/types/Bill";
import Vue from "vue";
import {ACCOUNTING_HIERARCHY, MANAGER_HIERARCHY} from "@/constants/Roles";
import {formatDateTimeToDate} from "@/utility/TextFormatting";
import axios from "axios"
import i18n from "@/locales/i18n";
import {AlertNotification} from "@/types/AlertNotification";

export default Vue.extend({
  data() {
    return {
      customerFinancialIssues: [] as Bill[] | undefined,
      dialog: false,
      customerBillsMessages: [] as AlertNotification[],
    };
  },
  mounted() {
    if (!this.userIsAccounting || !this.userIsManager) return;
    this.listCustomerFinancialIssues();
  },
  computed: {
    userIsManager() {
      return MANAGER_HIERARCHY.some(role => (this as any).$auth.check(role));
    },
    userIsAccounting() {
      return ACCOUNTING_HIERARCHY.some(role => (this as any).$auth.check(role));
    },
  },
  methods: {
    customerIsOverdue: function (): Bill | undefined {
      let treeDaysBefore = new Date();
      treeDaysBefore.setDate(treeDaysBefore.getDate() - 3);
      return this.customerFinancialIssues!.find(bill => bill.billStatus === 'Late' && new Date(bill.forecastDateTime) <= treeDaysBefore);
    },
    customerIsAtGracePeriod: function (): Bill | undefined {
      return this.customerFinancialIssues!.find(bill => bill.billStatus === 'Late');
    },
    customerHasTodayBills: function (): Bill | undefined {
      return this.customerFinancialIssues!.find(bill => bill.billStatus === 'DueToday');
    },
    customerHasNewBills: function (): Bill | undefined {
      let tenDaysAhead = new Date();
      tenDaysAhead.setDate(tenDaysAhead.getDate() + 10);
      return this.customerFinancialIssues!.find(bill => bill.billStatus === 'ToBePaid' && new Date(bill.forecastDateTime) <= tenDaysAhead);
    },
    async postBillsExemption() {
      await agent.Bills.postBillsExemption()
        .then(response => {
          this.handleSuccess("Seu compromisso foi registrado, mensagens de atraso não serão exibidas até: " + formatDateTimeToDate(response));
          this.bustCustomerFinancialIssuesCache();
        })
    },
    async bustCustomerFinancialIssuesCache() {
      const headers = {
        'Cache-Control': 'no-cache',
      }
      await axios.get('api/BillingOmie/customer-financial-issues', {headers: headers});
    },
    listCustomerFinancialIssues() {
      agent.Bills.getCustomerFinancialIssues()
        .then(response => {
          this.customerFinancialIssues = response.bills;

          let redirectRoute = '#/' + i18n.t('path.nameMyBills')

          let customerIsOverdue = this.customerIsOverdue();
          let customerHasNewBills = this.customerHasNewBills();
          let customerHasTodayBills = this.customerHasTodayBills();
          let customerIsAtGracePeriod = this.customerIsAtGracePeriod();

          if (customerIsOverdue) {
            this.dialog = true;
            this.customerBillsMessages.push({
              id: "customerIsOverdue",
              messageType: "error",
              content: "Atenção! Você possuí um ou mais boletos ainda não pagos, evite o bloqueio do sistema. Clique <a href='" + redirectRoute + "'>aqui</a> para verificar"
            });
          } else if (customerIsAtGracePeriod) {
            this.customerBillsMessages.push({
              id: "customerIsAtGracePeriod",
              messageType: "warning",
              content: "Há Boletos que podem estar vencidos, caso já tenha pago, desconsidere esta mensagem. Clique <a href='" + redirectRoute + "'>aqui</a> para verificar"
            });
          } else if (customerHasTodayBills) {
            this.customerBillsMessages.push({
              id: "customerHasTodayBills",
              messageType: "warning",
              content: "Um ou mais boletos vencem hoje. Clique <a href='" + redirectRoute + "'>aqui</a> para verificar"
            });
          } else if (customerHasNewBills) {
            this.customerBillsMessages.push({
              id: "customerHasNewBills",
              messageType: "info",
              content: "Você possuí um ou mais boletos disponíveis! Clique <a href='" + redirectRoute + "'>aqui</a> para verificar"
            });
          }

          if (this.customerBillsMessages.length > 0) {
            this.$emit('customerHasBills', this.customerBillsMessages);
          }
        })
    },
    redirectToPendingBills() {
      this.$router.push('/' + i18n.t('path.nameMyBills'));
    },
    formatDateTimeToDate,
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
  }
})
