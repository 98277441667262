
import Vue from "vue";
import agent from '@/api/agent';
import { MarketAverageTicket, AverageTicketVariety } from "@/types/DashboardMarketIntelligence";
import BarChart from "@/newReport/BarChart";
import BrazilStates from "@/components/BrazilStates";
import MarketAverageTicketHelp from "@/views/dashboard/helpers/MarketAverageTicketHelp.vue";

interface Bucket {
  [key: number]: number[];
}

export default Vue.extend({
  props: {
    mustCallEndpoints: {
      type: Boolean, default: false
    },
    form: {
      type: Object, required: true
    }
  },
  data() {
    return {
      data: {} as MarketAverageTicket,
      averageTicket: 0 as number,
      averageBilling: 0 as number,
      productVariety: 0 as number,
      chart: {
        visible: false,
        isEmpty: true as boolean,
        height: 300 as number,
        labels: [] as string[],
        datasets: [] as object[],
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      },
      states: BrazilStates.states,
      params: {
        state: [] as string[],
      },
      localCount: "0" as string,
    };
  },
  mounted() {
    this.getMarketAverageTicket();
    window.addEventListener('resize', this.handleChartSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleChartSize);
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.getMarketAverageTicket();
        }
      },
    }
  },
  methods: {
    clearCardData() {
      this.averageTicket = 0;
      this.averageBilling = 0;
      this.productVariety = 0;
      this.localCount = "0";
    },
    getMarketAverageTicket() {
      this.chart.visible = false;
      this.clearCardData();
      this.params = { state: this.form.state }
      
      agent.DashboradMarketIntelligence.getRegionSalesReport(this.params)
        .then((response) => {
          this.data = response;
          if (this.data.averageTicketVarieties.length === 0) {
            this.chart.isEmpty = true;
          }
          else {
            this.chart.isEmpty = false;
            this.processHistogramData(this.data.averageTicketVarieties);

            this.averageTicket = this.data.averageTicket;
            this.averageBilling = this.data.averageBilling;
            this.productVariety = this.data.averageProductVariety;

            this.localCount = this.data.localCount; 
          }
        })
        .catch(() => {})
        .finally(() => { this.handleChartSize() });
    },
    handleChartSize() {
      this.chart.visible = false;
      if (this.$vuetify.breakpoint.xs) {
        this.chart.height = 460;
      } else if (this.$vuetify.breakpoint.sm) {
        this.chart.height = 220;
      } else if (this.$vuetify.breakpoint.md) {
        this.chart.height = 150;
      } else {
        this.chart.height = 90;
      }
      setTimeout(() => {
        this.chart.visible = true;
      }, 100);
    },
    processHistogramData(varieties: AverageTicketVariety[]) {
      const interval = 50;
      let buckets: Bucket = {};

      for (let variety of varieties) {
        let bucket = Math.ceil(variety.productVariety / interval) * interval;
        bucket = bucket - (interval - 1);
        if (!buckets[bucket]) buckets[bucket] = [];
        buckets[bucket].push(variety.averageTicket);
      }

      let labels = [];
      let data = [];

      for (let bucket in buckets) {
        let average = buckets[bucket].reduce((sum, val) => sum + val, 0) / buckets[bucket].length;
        labels.push(`${bucket} - ${parseInt(bucket) + interval - 1}`);
        data.push(average);
      }

      this.chart.labels = labels;
      this.chart.datasets = [{
        backgroundColor: '#08aa8f',
        data: data,
      }];
    }
  },
  components: {
    "bar-chart": BarChart,
    MarketAverageTicketHelp
  },
});
