<template>
  <div>
    <v-dialog v-model="dialog" max-width="520" @keydown.esc="dialog = false">
      <v-card>
        <v-card-title>
          {{ $t('titleDownload') }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t('cardTextDownload') }} <strong>{{ fileName }}</strong></p>
          <p>{{ $t('cardAvailableAfterCreation') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.native="dialog = false">{{ $t('btnCloseDownload') }}</v-btn>
          <v-btn color="primary" text :to="`/${$t('path.nameDownloads')}`">{{ $t('btnGoToDownloads') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    fileName: { type: String, default: null },
    value: { type: Boolean, default: false }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  },
}
</script>

<style scoped>
  .fixed {
    position: fixed;
  }
</style>