export default {
    planogramsByPos: 'Planogramas por PDV',
    searchPDV: 'Procurar PDV',
    selectPDVs: 'Selecione PDVs sem planograma pendente ou rascunho',
    planograms: 'Planogramas',
    updateItems: 'Atualizar itens',
    finishDraft: 'Finalizar rascunho',
    finishDrafts: 'Finalizar rascunhos',
    confirmRemoveDrafts: 'Tem certeza que deseja remover os planogramas de rascunho para os PDVs selecionados?',
    removeDrafts: 'Remover rascunhos',
    btnNo: 'Não',
    btnRemove: 'Remover',
    activatePending: 'Ativar pendentes',
    confirmActivate: 'Deseja ativar o planograma pendente para os PDVs selecionados?',
    btnFinalize: 'Finalizar',
    noResults: 'Sem resultados',
    headersPdvID: 'ID PDV',
    headersCustomer: 'Cliente',
    headersLocation: 'Local',
    headersSpecificLocation: 'Local específico',
    headersMachine: 'Máquina',
    headersActivePog: 'POG Ativo',
    headersPendingPog: 'POG Pendente',
    headersDraftPog: 'POG Rascunho',
    finalizePogSuccess: 'Planograma finalizado para o PDV',
    finalizePogError: 'Não foi possível finalizar o planograma para o PDV',
    activatePogSuccess: 'Planograma ativado para o PDV',
    activatePogError: 'Não foi possível ativar o planograma para o PDV',
    removePogSuccess: 'Planograma',
    removePogSuccessRemoved: 'removido',
    removePogError: 'Não foi possível remover o planograma',
    createPogSuccess: 'Planograma criado para o PDV',
    createPogError: 'Não foi possível criar o planograma para o PDV',
    addItemSuccess: 'Item ',
    addItemSuccessAdded: 'adicionado',
    removeItemSuccess: 'removido ',
    updateItemSuccess: 'atualizado' ,
    selectionPos: 'Deseja finalizar o planograma de rascunho para os PDVs selecionados?',
    completionMessage: 'Depois da finalização do planograma, ele entrará no modo pendente e não será mais possível editá-lo. O planograma pendente substituirá o planograma ativo ao Abastecer o PDV ou Ativar o planograma.',
    finishPlanogramError: 'Selecione apenas PDVs com planograma rascunho',
    deletePlanogramError: 'Selecione apenas PDVs com planograma rascunho',
    activatePlanogramError: 'Selecione apenas PDVs com planograma rascunho',
    activatePendingPlanograms: 'Ativar planogramas pendentes',
    makePlanogramActive: 'A ativação tornará o planograma pendente como ativo. *Atenção: para manter a contagem do estoque correta, recomendamos, ao invés de ativar, usar a opção de Abastecer o momento de abastecimento do ponto de venda. Assim, além de informar o abastecimento, o planograma pendente será ativado e terá o estoque referente ao abastecimento.',
    activatePlanogramsError: 'Erro ao ativar o planograma. Tente novamente.',
    onFinishedCreationMessage: 'Os planogramas foram criados como rascunho. ',
    onFinishedCreationMessageAddItem: 'Você pode adicionar mais itens, editar itens existentes ou finalizar o rascunho. ',
    onEntryUpdatedMessage: 'A operação foi aplicada no planograma rascunho. ',
    onEntryUpdatedMessageAddItem: 'Você pode continuar adicionando itens, editar itens existentes ou finalizar o rascunho.',
    handleAddOrUpdateResultText: ' no planograma ',
    rascunho: 'rascunho',
    ativo: 'ativo',
    PDV: 'PDV',
};