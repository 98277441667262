export default {
  searchDiscounts: 'Search discounts',
  messageRemoveDiscount: 'Are you sure you want to remove the discount?',
  messageRemoveCoupon:'Are you sure you want to remove the coupon?',
  remove: 'Remove',
  nameDiscount: 'Discount name',
  couponsVinculated: 'Linked coupons',
  usageLimit: 'Usage limit',
  discountType: 'Discount type',
  coupomType: 'Coupon type',
  condition: 'Condition',
  creatingTheDiscount: 'Creating the discount',
  actions: 'Actions',
  noCouponsVinculated: 'No coupons linked',
  removedDiscountSuccess: 'Discount successfully removed',
  removedDiscountError: 'Error removing discount',
  createdDiscount: 'Discount Created',
  discount: 'Discount',
  creatingDiscount: 'Creating Discount',
  creatingCoupon: 'Creating Coupon',
  couponCode: 'Coupon Code',
  numberUtilization: 'Number of Uses',
  startsIn: 'Starts on',
  endsIn: 'Ends on',
  phoneNumber: 'Phone Number',
  textDiscount: 'Discount',
  minimumPurchase: 'Minimum purchase',
  noRemovedDiscount: 'Unable to remove the discount',
  noRemovedCoupon: 'Unable to remove the coupon',
  newDiscount: 'New discount',
  minimumPurchaseOptional: 'Minimum purchase amount (Optional)',
  couponLimit: 'Coupon usage limit',
  btnNext: 'Next',
  fixed: 'Fixed',
  percentage: 'Percentage',
  single: 'Single use',
  unlimited: 'Unlimited',
  specified: 'Specified',
  insertSomeValue: 'Enter a value',
  errorCreatingDiscount: 'Problem creating discount',
  valueToApply: 'Value to apply',
  forMobile: 'For mobile only (Optional)',
  btnClean: 'Clear',
  btnSave: 'Save',
  errorCreatingCoupon: 'Problem creating coupon',
  insertValidPhone: 'Enter a valid phone number',
  newCoupon: 'New Coupon',
  viewCoupon: 'View Coupon',
  addCoupon: 'Add Coupon',
  editDiscount: 'Edit Discount',
  removeDiscount: 'Remove Discount',
  removeCoupon: 'Remove Coupon',
  advanced: 'Advanced',
  couponCreated: 'Coupon created',
  onlyNumbers: 'Only numbers',
  invalidPhone: 'Invalid phone number',
};
