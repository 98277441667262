
import Vue from 'vue';
import CouponForm from './CouponForm.vue';

export default Vue.extend({
  props: {
    value: { type: Boolean, required: true },
    discount: { type: Number, default: null }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value : boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    },
    onCouponCreated(coupon : object) {
      this.$emit('couponCreated', coupon);
      this.closeDialog();
    }
  },
  components: {
    CouponForm
  }
});
