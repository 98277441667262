export default {
  missingProducts:{
    title: 'Productos no encontrados',
    typeOfAlert: {
      title: 'Tipos de alerta',
      insertInPlanogram: 'Insertar en Planograma',
      insertProduct: 'Registrar Producto',
      productIdentified: 'Producto Identificado',
    },
    error: {
      excel: 'Error al exportar excel',
    },
    insertInPlanogram: {
      title: '¿Desea insertar el producto en el planograma activo?',
      message: 'Al hacer clic en "sí", su producto se añadirá directamente al planograma activo de su PDV.',
    },
    excelFileName: 'Productos_no_encontrados.xlsx',
    help:{
      desctiption:`
      <p>
        En la página de Productos No Encontrados, puedes visualizar los códigos de barras que, tras ser leídos, no fueron encontrados, ya sea porque no fueron registrados en el sistema o porque no forman parte del planograma actual del respectivo punto de venta.

        En la columna Tipos de Alerta, puedes visualizar los siguientes estados:
        <ul>
          <li><strong>Registrar Producto:</strong> El sistema no tiene este código de barras registrado, por lo que es necesario registrar el producto antes de agregarlo al planograma.</li>
          <li><strong>Agregar al Planograma:</strong> El producto está registrado en el sistema pero no forma parte del planograma actual del punto de venta.</li>
          <li><strong>Producto Identificado:</strong> El producto ha sido leído y ahora está registrado en el planograma correspondiente.</li>
        </ul>
      </p>`,
    }
  },
};