
import Vue from 'vue';
import { Users } from '@/types/Users';
import agent from '@/api/agent';
  
export default Vue.extend({
  props: {
    value: { type: Number, default: null },
    multiple: { type: Boolean, default: false},
  },
  data() {
    return {
      users: [] as Users[],
      selectedUserId: this.value,
      loading: false,
    }
  },
  watch: {
    selectedUserId(val){
      this.$emit('input', val);
    },
    value(val) {
      this.selectedUserId = val;
    }
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers(): void {
      this.loading = true;
      
      agent.User.getUsersEmail()
        .then((response: Users[]) => {
          this.users = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
