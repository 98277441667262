
import Vue from 'vue';
import IntervalDatePicker from '@/components/filters/IntervalDatePicker.vue';
import InvoiceXml from "@/views/invoice/InvoiceXml.vue";
import BulkRetryInvoice from "@/views/invoice/BulkRetryInvoice.vue";
import InvalidationRequest from "@/views/invoice/InvalidationRequest.vue";
import InvalidationsDialog from "@/views/invoice/InvalidationsDialog.vue";
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import moment from "moment";

const initialForm = {
  minDate: moment().subtract(14, 'days').format("DD/MM/YYYY"),
  maxDate: moment().format("DD/MM/YYYY"),
  status: ["Approved", "Denied"] as string[],
}

export default Vue.extend({
  components: {
    IntervalDatePicker,
    InvoiceXml,
    BulkRetryInvoice,
    InvalidationRequest,
    InvalidationsDialog,
    DownloadDialog
  },
  data() {
    return {
      form: initialForm,
      resetDate: false,
      valid: false,
      panel: 0,
      operationStatus: [
        { text: "Aprovada", value: "Approved", color: "success" },
        { text: "Negada", value: "Denied", color: "error" },
      ],
      rule: (v: string) => /\S/.test(v) || "Este campo é obrigatório",
      downloadDialog: {
        show: false,
        fileName: ""
      },
      excelLoading: false
    }
  },
  mounted() {
    this.sendUpdate();
  },
  methods: {
    onIntervalDateUpdated(minDate: string, maxDate: string, resetDate: boolean) {
      if (resetDate) {
        this.resetDate = false;
      }
      this.form.minDate = minDate;
      this.form.maxDate = maxDate;
    },
    sendUpdate(): void {
      if ((this.$refs.form as any).validate()) {
        this.$emit("updateTable", this.form);
      }
    },
    clearForm(): void {
      this.form = {...initialForm}
      this.sendUpdate();
    },
    getExcel() {
      this.excelLoading = true;
      this.axios.get('api/invoicesWeb/Excel/invoicesTable', {
          params:  { ...this.form }
      }).then((response) => {
          this.excelLoading = false;
          this.downloadDialog.fileName = response.data;
          this.downloadDialog.show = true;
        })
        .catch((error) => {
          this.excelLoading = false;
          this.$emit('downloadError', error)
        })
    },    
  }
})
