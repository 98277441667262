export default {
    clickForMoreInfoIndividual: 'Clique para mais informações',
    searchProductIndividual: 'Procurar produto',
    noPlanogramsIndividual: 'Sem planogramas cadastrados',
    planogramIndividual: 'Planograma',
    showAllIndividual: 'Mostrar todos',
    filterCriticalIndividual: 'Filtrar críticos',
    quickEditIndividual: 'Edição rápida',
    newItemIndividual: 'Novo item',
    finalizeDraftIndividual: 'Finalizar rascunho',
    activateNowIndividual: 'Ativar agora',
    removePlanogramIndividual: 'Remover Planograma',
    confirmRemovePlanogramIndividual: 'Tem certeza que deseja remover o planograma rascunho?',
    cancelIndividual: 'Cancelar',
    removeIndividual: 'Remover',
    finalizePlanogramIndividual: 'Finalizar planograma',
    finalizePlanogramWarningIndividual: 'Depois da finalização do planograma, ele entrará no modo pendente e não será mais possível editá-lo. O planograma pendente substituirá o planograma ativo ao clicar no botão Abastecer no menu de administrador do sistema de pagamento, ou então ao clicar em Ativar agora na página do planograma.',
    activatePlanogramIndividual: 'Ativar mesmo assim',
    activatePlanogramWarningIndividual: 'A ativação tornará o planograma pendente como ativo. Atenção: para manter a contagem do estoque correta, recomendamos, ao invés de ativar o planograma por aqui, usar o recurso Abastecer do menu de administrador do sistema de pagamento. Assim, além de informar o abastecimento, o planograma pendente será ativado e terá o estoque referente ao abastecimento.',
    viewMovementsHistoryIndividual: 'Ver histórico de movimentações',
    goToProductRegistrationIndividual: 'Ir para o cadastro do produto',
    noItemsIndividual: 'Sem itens cadastrados',
    updateQuantityIndividual: 'Atualizar quantidade',
    editItemIndividual: 'Editar item',
    deleteItemIndividual: 'Deletar item',
    changeDefaultProductIndividual: 'Alterar produto padrão',
    criticalStockIndividual: 'Estoque no nível crítico',
    removeItemConfirmationIndividual: 'Tem certeza que deseja remover o item ?',
    planogramRemovedIndividual: 'Planograma removido',
    itemRemovedIndividual: 'Item removido',
    planogramCreatedIndividual: 'Planograma criado',
    planogramFinalizedIndividual: 'Planograma finalizado',
    planogramActivatedIndividual: 'Planograma ativado',
    errorRemovingPlanogramIndividual: 'Erro ao remover planograma',
    errorRemovingItemIndividual: 'Erro ao remover item',
    errorFinalizingPlanogramIndividual: 'Erro ao finalizar planograma',
    errorActivatingPlanogramIndividual: 'Erro ao ativar planograma',
    saveSuccessfulIndividual: 'Item salvo com sucesso',
    draftModeIndividual: 'Já existe um planograma no modo rascunho ou pendente',
    cardVisionIndividual: 'Visão Card',
    tabularViewIndividual: 'Visão Tabular',
    iconExcel: 'Excel',
    btnFinish: 'Finalizar',
    activatePlanogramCard: 'Ativar planograma',
    paginationDisplay: 'Exibindo: ',
    paginationItemsPerPage: 'Itens por página:',
    paginationOf: 'de',
    criticalStock: 'Estoque crítico',
    requiredQuantity: 'Qtd. Desejada é obrigatório',
    desiredQuantityNotFilled: 'O campo n\'Quantidade desejadan\' não foi preenchido',
    removeNo: 'Não',
    headersImageIndividual: 'Imagem',
    headersSelectionIndividual: 'Seleção',
    headersProductCodeIndividual: 'Código do produto',
    headersProductNameIndividual: 'Nome do produto',
    headersProductCategoryIndividual: 'Categoria do produto',
    headersPriceIndividual: 'Preço',
    headersCurrentQuantityIndividual: 'Qtd. atual / capacidade',
    headersDesiredParLevelIndividual: 'Qtd. desejada (nível de par)',
    headersActionIndividual: 'Ações',
    activatePlanogramFeedback: 'O planograma está sendo ativado. Esse processo pode levar algum tempo.',
    getPlanogramDescriptionStatus: 'Este planograma ainda não está ativo',
    ProductsUnused: 'Produtos sem movimentações',
  };