export default {
    searchDiscounts: 'Pesquisar descontos',
    messageRemoveDiscount: 'Tem a certeza que deseja remover o desconto?',
    messageRemoveCoupon: 'Tem a certeza que deseja remover o cupom?',
    remove: 'Remover',
    nameDiscount: 'Nome do desconto',
    couponsVinculated: 'Cupons vinculados',
    usageLimit: 'Limite de utilização',
    discountType: 'Tipo de desconto',
    coupomType: 'Tipo de cupom',
    condition:'Condição',
    creatingTheDiscount :'Criação do desconto',
    actions: 'Ações',
    noCouponsVinculated: 'Sem cupons vinculados',
    removedDiscountSuccess: 'Desconto removido com sucesso',
    removedDiscountError: 'Erro ao remover desconto',
    createdDiscount: 'Desconto Criado',
    discount:'Desconto',
    creatingDiscount:'Criando Desconto',
    creatingCoupon:'Criando Cupom',
    couponCode:'Código do Cupom',
    numberUtilization:'Número de Utilizações',
    startsIn:'Inicia em',
    endsIn:'Termina em',
    phoneNumber: 'Número do Celular',
    textDiscount: 'de Desconto',
    minimumPurchase: 'Compra mínima',
    noRemovedDiscount: 'Não foi possível remover o desconto',
    noRemovedCoupon: 'Não foi possível remover o cupom',
    newDiscount: 'Novo desconto',
    minimumPurchaseOptional: 'Valor mínimo da compra (Opcional)',
    couponLimit: 'Limite de uso do cupom',
    btnNext:'Próximo',
    fixed: 'Fixo',
    percentage: 'Porcentagem',
    single: 'Uso único',
    unlimited: 'Ilimitado',
    specified: 'Especificado',
    insertSomeValue: 'Insira algum valor',
    errorCreatingDiscount: 'Problema ao criar desconto',
    valueToApply: 'Valor a ser aplicado',
    forMobile: 'Apenas para o celular (Opcional)',
    btnClean: 'Limpar',
    btnSave: 'Salvar',
    errorCreatingCoupon: 'Problema ao criar cupom',
    insertValidPhone: 'Insira um número válido',
    newCoupon: 'Novo Cupom',
    viewCoupon: 'Visualizar Cupom',
    addCoupon: 'Adicionar Cupom',
    editDiscount: 'Editar Desconto',
    removeDiscount: 'Remover Desconto',
    removeCoupon: 'Remover Cupom',
    advanced: 'Avançado',
    couponCreated: 'Cupom criado',
    onlyNumbers: 'Apenas números',
    invalidPhone: 'Número de celular inválido',
};