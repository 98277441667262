<template>
  <v-dialog persistent v-model="dialog" width="960">   
    <v-card>
      <v-card-text class="pt-4">
        <div class="title lighten-2 mx-2">{{ $t('recentTransactionsRemoteCredit') }}</div>

        <v-data-table 
          v-bind:headers="headers" 
          :items="transactions" 
          :loading="loadingTable" 
          hide-default-footer
          disable-pagination 
          :no-data-text="$t('noResultsRemoteCredit')">
          <template #item="{ item }">
            <tr>
              <td>
                  <v-icon small :color="getResultColor(item.result)">
                    {{ getResultIcon(item.result) }}
                  </v-icon>
                  {{ getResultDescription(item.result) }}
              </td>
              <td>{{ $currencyFormatter.format(item.paymentAmount) }}</td>
              <td>{{ formatMethod(item.paymentMethod )}}</td>
              <td>{{ item.cardHolder !== null ? `*-*-*-${item.cardHolder}` : '-' }}</td>
              <td>{{ item.planogramEntryProductDescription }}</td>
              <td>{{ formatDateTime(item.date) }}</td>
            </tr>
          </template>
        </v-data-table>

        <div class="title lighten-2 mt-4 mx-2 pb-2">{{ $t('creditTransferRemoteCredit') }}</div>

        <v-text-field type="number" min="0" step="0.01" placeholder='0.00'
                    prepend-icon="attach_money" :label="$t('quantityRemoteCredit')" v-model="amount"></v-text-field>

        <v-alert :value="resultAlert === 'sent'" type="info" icon="done">{{ $t('creditsSentRemoteCredit') }}</v-alert>
        <v-alert :value="resultAlert === 'received'" type="success" icon="done_all">{{ $t('creditsReceivedRemoteCredit') }}</v-alert>
        <v-alert :value="resultAlert === 'used'" type="success" icon="done_outline">{{ $t('creditsUsedRemoteCredit') }}</v-alert>
        <v-alert :value="resultAlert === 'error'" type="error">{{errorMessage}}</v-alert>

      </v-card-text>

      <v-card-actions>
          <v-btn text color="primary" :loading="loading" @click.native="insertRemote(0)">{{ $t('resetCreditsRemoteCredit') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('closeRemoteCredit') }}</v-btn>
          <v-btn color="primary" :loading="loading" @click.native="insertRemote(amount)">{{ $t('sendRemoteCredit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      value: { type: Boolean, required: true },
      posId: {type: Number, required: true }
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      headers() {
        return [
          { text: this.$t('dispensedRemoteCredit'), align: 'left', sortable: false },
          { text: this.$t('amountRemoteCredit')+`( ${this.$currencyFormatter.getSign()})`, align: 'left', sortable: false },
          { text: this.$t('methodRemoteCredit'), align: 'left', sortable: false },
          { text: this.$t('cardRemoteCredit'), align: 'left', sortable: false },
          { text: this.$t('productRemoteCredit'), align: 'left', sortable: false },
          { text: this.$t('dateTimeRemoteCredit'), align: 'left', sortable: false },
          ];
      } 
    },
    data(){
      return {
        updateLastTransactionsId: null,
        checkMessageStatusId: null,
        cloudMessageId: null,
        amount: null,
        loading: false,
        loadingTable: true,
        errorMessage: null,
        resultAlert: null,
        transactions: []
      }
    },
    watch: {
      dialog: function(dialog){
        if (dialog) {
          this.updateLastTransactions();
          this.updateLastTransactionsId = setInterval(this.updateLastTransactions, 10000);
        }
        else {
          if(this.updateLastTransactionsId !== null){
            clearInterval(this.updateLastTransactionsId);
          }
          if(this.checkMessageStatusId !== null){
            clearInterval(this.checkMessageStatusId);
          }
          this.resultAlert = null;
          this.transactions = [];
          this.amount = null;
          this.loading = false;
          this.errorMessage = null;
        }
      }
    },
    methods: {
      insertRemote(amount){
        this.loading = true;
        this.resultAlert = null;
        if(this.checkMessageStatusId !== null){
          clearInterval(this.checkMessageStatusId);
        }
        this.axios.post('api/PointsOfSale/' + this.posId + '/RemoteCredit?amount=' + amount)
          .then((response) => {
            this.cloudMessageId = response.data;
            this.checkMessageStatusId = setInterval(this.checkMessageStatus, 3000);
            this.resultAlert = 'sent';
          }).catch(() => {
            this.errorMessage = this.$t('errorSendingCredits');
            this.resultAlert = 'error';
          }).then(() => {
            this.loading = false;
          });
      },
      updateLastTransactions(){
        if(this.dialog){
          this.axios.get('api/Transactions', { params: {
            pointOfSaleId: this.posId,
            page: 1,
            pageSize: 5
          }}).then((response) => {
            this.loadingTable = false;
            this.transactions = response.data.items;
          });
        }
      },
      checkMessageStatus(){
        if(this.dialog){
          this.axios.get('api/Boxes/CloudMessageStatus', { params: { name: this.cloudMessageId }})
            .then((response) => {
              switch(response.data.status){
                case 'WaitingReceipt':
                  break;
                case 'Received':
                  this.resultAlert = 'received';
                  break;
                case 'Success':
                  this.resultAlert = 'used';
                  break;
                case 'Error':
                  this.errorMessage = this.$t('errorUsingRemoteCredit');
                  this.resultAlert = 'error';
                  break;
              }
            })
        }
      },
      formatMethod(method){
        switch(method){
          case "Credit": return this.$t('paymentMethodCreditRemoteCredit');
          case "Debit": return this.$t('paymentMethodDebitRemoteCredit');
          case "Voucher": return this.$t('paymentMethodVoucherRemoteCredit');
          case "RemoteCredit": return this.$t('remotePaymentMethod');
          case "Cash": return this.$t('paymentMethodKindRemoteCredit');
          case "Unknown": return "-";
        }
      },
      getResultDescription(result){
        switch(result){
          case "Ok": return "";
          case "Unknown": return this.$t('runknownRemoteCredit');
          case "VendFailure": return this.$t('failedToDismissRemoteCredit');
          case "Refunded": return this.$t('reversedRemoteCredit');
          case "RefuncCancelled": return this.$t('reversalCancelledRemoteCredit');
          case "RefundError": return this.$t('errorReversingRemoteCredit');
          case "ProductNotSelected": return this.$t('productNotSelectedRemoteCredit');
        }
      },
      formatDateTime(date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
      },
      getResultColor(result){
        if(result === 'Ok') return 'green darken-2';
        else return 'red darken-2';
      },
      getResultIcon(result){
        if(result === 'Ok') return 'check_circle';
        else return 'error';
      }
    },
  };
</script>