
import Vue from 'vue';
import download from '@/components/Download';
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import CategoriesDropDown from '@/components/filters/CategoriesDropDown.vue';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
// eslint-disable-next-line no-unused-vars
import {PurchaseSuggestionsFilter} from '@/types/PurchaseSuggestions';


const filtersInitialState: PurchaseSuggestionsFilter = {
  pastDays: 30,
  desiredDays: 30,
  inventoryIds: [],
  productId: null,
  categoryId: null,
  belowMinimumToBuyThreshold: false,
  inventoryDaysLeftThreshold: 0,
  inventoryDaysLeftFilter: false,
  inventoryType: "distributionCenter",
  minimumQuantity: false,
};

export default Vue.extend({
  components: {
    ProductsFilter, CategoriesDropDown, InventoriesDropDown
  },
  data() {
    return {
      filters: {...filtersInitialState},
      acquiredPointOfSales: [] as object[],
      loading: false,
      panel: 0 as number | null,
      valid: true,
      inventoryTypes: [
        {text: "Central", value: "distributionCenter"},
        {text: "PDV", value: "pointOfSale"}
      ]
    };
  },
  watch: {
    'filters.productId'(value: number) {
      if (value != null) {
        this.filters.categoryId = null;
      }
    },
    'filters.categoryId'(value: number) {
      if (value != null) {
        this.filters.productId = null;
      }
    },
  },
  mounted() {
    this.updateFilters();
  },
  computed: {
    inventoryTypeFilter(): string | null {
      if (this.filters.inventoryType === (this as any).inventoryType) {
        return null;
      } else return this.filters.inventoryType;
    }
  },
  methods: {
    onPointOfSaleGet(pointOfSales: object[]) {
      this.acquiredPointOfSales = pointOfSales;
    },
    updateFilters() {
      if ((this.$refs.form as any).validate()) {
        this.$emit("input", this.filters, this.acquiredPointOfSales);
      }
    },
    getExcel() {
      if ((this.$refs.form as any).validate()) {
        this.loading = true;
        this.axios.get('api/PurchaseSuggestions/Excel', {params: this.filters, responseType: 'blob'})
            .then((response: any) => {
              this.loading = false;
              download(response);
            })
            .catch((error: any) => this.$emit("excel-error", error))
            .finally(() => this.loading = false);
      }
    },
    resetFilters() {
      Object.assign(this.filters, {...filtersInitialState});
      this.$emit("reset-filters");
    },
  }
});
