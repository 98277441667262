export default {
    userDialogNewUser: 'Nuevo Usuario',
    userDialogCreateNewUser: 'Crear nuevo usuario',
    userDialogEditUser: 'Editar usuario',
    userDialogEmailLabel: 'Correo electrónico',
    userDialogCheckEmailLabel: 'Confirmar correo electrónico',
    userDialogPasswordHint: 'Si no lo completas, se generará una contraseña para ti',
    userDialogPasswordLabel: 'Contraseña (opcional)',
    userDialogCheckPasswordLabel: 'Confirmar contraseña',
    userDialogPermissions: 'Permisos',
    userDialogAdministrator: 'Administrador',
    userDialogManagement: 'Gestión',
    userDialogAccounting: 'Contabilidad',
    userDialogManager: 'Gerente',
    userDialogBuildingManager: 'Administrador de Edificio',
    userDialogSales: 'Ventas',
    userDialogAllFunctions: 'Todas las Funciones',
    userDialogPriceEdit: 'Edición de Precios',
    userDialogPlanogramView: 'Visualización de Planogramas',
    userDialogTransactionsView: 'Visualización de Transacciones',
    userDialogSupply: 'Stock y Abastecimiento',
    userDialogTouchpayProvider: 'Proveedor de Touchpay',
    userDialogBlindInventory: 'Inventario a ciegas y Abastecimiento',
    userDialogTelemetry: 'Telemetría',
    userDialogRegistrations: 'Registros',
    userDialogApp: 'Aplicación',
    userDialogSafety: 'Seguridad',
    userDialogPrivateLabel: 'Etiqueta Privada',
    userDialogPrivateLabelAdmin: 'Administrador de Etiqueta Privada',
    userDialogVisualization: 'Visualización',
    userDialogInvoices: 'Facturas NFCe',
    userDialogClose: 'Cerrar',
    userDialogSave: 'Guardar',
    userDialogCreate: 'Crear',
    userDialogEmailConfirmationRule: 'El correo electrónico ingresado es incorrecto',
    userDialogPasswordConfirmationRule: 'La contraseña ingresada es incorrecta',
    userDialogUserCreated: 'Usuario creado. ',
    userDialogGeneratedPassword: 'Contraseña generada: {password}',
    userDialogCreateUserError: 'No se pudo crear el usuario',
    userDialogUserEdited: 'Usuario {userEmail} editado',
    userDialogEditUserError: 'No se pudo editar el usuario',
    userDialogOrdersManagerRole: 'Gestor de pedidos',
    userDialogRemoteMonitoring: 'Monitoreo Remoto'
  }
  