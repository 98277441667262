export default {
    clickForMoreInfoIndividual: 'Click for more information',
    searchProductIndividual: 'Search product',
    noPlanogramsIndividual: 'No planograms registered',
    planogramIndividual: 'Planogram',
    showAllIndividual: 'Show all',
    filterCriticalIndividual: 'Filter critical',
    quickEditIndividual: 'Quick edit',
    newItemIndividual: 'New item',
    finalizeDraftIndividual: 'Finalize draft',
    activateNowIndividual: 'Activate now',
    removePlanogramIndividual: 'Remove Planogram',
    confirmRemovePlanogramIndividual: 'Are you sure you want to remove the draft planogram?',
    cancelIndividual: 'Cancel',
    removeIndividual: 'Remove',
    finalizePlanogramIndividual: 'Finalize planogram',
    finalizePlanogramWarningIndividual: 'After finalization, the planogram will enter pending mode and can no longer be edited. The pending planogram will replace the active planogram when clicking the Refill button in the payment system administrator menu, or by clicking Activate now on the planogram page.',
    activatePlanogramIndividual: 'Activate anyway',
    activatePlanogramWarningIndividual: 'Activation will make the pending planogram active. Caution: to maintain accurate stock counting, we recommend using the Refill feature in the payment system administrator menu instead of activating the planogram here. This way, in addition to informing the refill, the pending planogram will be activated and will have stock related to the refill.',
    viewMovementsHistoryIndividual: 'View movements history',
    goToProductRegistrationIndividual: 'Go to product registration',
    noItemsIndividual: 'No items registered',
    updateQuantityIndividual: 'Update quantity',
    editItemIndividual: 'Edit item',
    deleteItemIndividual: 'Delete item',
    changeDefaultProductIndividual: 'Change default product',
    criticalStockIndividual: 'Critical stock',
    removeItemConfirmationIndividual: 'Are you sure you want to remove item ?',
    planogramRemovedIndividual: 'Planogram removed',
    itemRemovedIndividual: 'Item removed',
    planogramCreatedIndividual: 'Planogram created',
    planogramFinalizedIndividual: 'Planogram finalized',
    planogramActivatedIndividual: 'Planogram activated',
    errorRemovingPlanogramIndividual: 'Error removing planogram',
    errorRemovingItemIndividual: 'Error removing item',
    errorFinalizingPlanogramIndividual: 'Error finalizing planogram',
    errorActivatingPlanogramIndividual: 'Error activating planogram',
    saveSuccessfulIndividual: 'Item saved successfully',
    draftModeIndividual: 'A planogram already exists in draft or pending mode',
    cardVisionIndividual: 'Card View',
    tabularViewIndividual: 'Tabular View',
    iconExcel: 'Excel', 
    btnFinish: 'Finish',
    activatePlanogramCard: 'Activate planogram',
    paginationDisplay: 'Displaying: ',
    paginationItemsPerPage: 'Items per page:',
    paginationOf: 'of',
    criticalStock: 'Critical stock',
    requiredQuantity: 'Qty. Desired is required',
    desiredQuantityNotFilled: 'The n\'Desired quantityn\' field has not been filled in',
    removeNo: 'No',
    headersImageIndividual: 'Image',
    headersSelectionIndividual: 'Selection',
    headersProductCodeIndividual: 'Product code',
    headersProductNameIndividual: 'Product name',
    headersProductCategoryIndividual: 'Product category',
    headersPriceIndividual: 'Price',
    headersCurrentQuantityIndividual: 'Current quantity / capacity',
    headersDesiredParLevelIndividual: 'Desired quantity (par level)',
    headersActionIndividual: 'Actions',
    activatePlanogramFeedback: 'The planogram is being activated. This process may take some time',
    getPlanogramDescriptionStatus: 'This planogram is not yet active',
    ProductsUnused: 'Products without movements',
  };