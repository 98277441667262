export default {
    posStatus: 'Estado de los PDVs',
    posStatusSubTitle:'Visualice el estado de sus PDV',
    totalPos: 'Total de PDVs',
    onlineQuantity: 'Cantidad en línea',
    offlineQuantity: 'Cantidad fuera de línea',
    onlinePercent: 'En línea(%)',
    offlinePercent: 'Fuera de línea(%)',
    noResults: 'Sin resultados',
    posStatusPercentageOnline: 'En línea',
    posStatusPercentage: 'Porcentaje',
    posStatusPercentageOffline: 'Desconectado',
    posStatusPercentageAlert: 'Alerta',
    posStatusAmount: 'Cantidad',
    modeOnline: 'En línea(%)',
    modeOffline: 'Fuera de línea(%)',
    quantityPos: 'PDV(s) en total',
    onlineColor: "Verde",
    offlineColor: "Rojo",
    alertColor: "Amarillo",
    onlineDescription: "El terminal está operativo.",
    offlineDescription: "Terminal completamente fuera de servicio debido a razones como pantalla apagada, falta de conexión a internet/energía, avería en la máquina o razón desconocida pero con el problema persistente.",
    alertDescription: "Terminal con problemas como corte de energía, en proceso de mantenimiento, pérdida de conexión a internet o problema con el lector de tarjetas.",
    posStatusDescription: "Para asegurar una experiencia de pago eficiente, nuestro sistema realiza verificaciones de conectividad cada cinco minutos. Los estados están designados por colores",
    posStatusRecommendation: "Si su terminal permanece en estado rojo durante un período prolongado, le recomendamos que se ponga en contacto con nuestro soporte técnico para recibir asistencia inmediata."
};
