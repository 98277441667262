export default {
    filterFilesDownloads: 'Filtrar archivos',
    generatingFileDownloads: 'Generando archivo',
    fileGenerationFailure: 'Error al generar el archivo',
    downloadFileError: 'No se pudo descargar el archivo',
    deleteFileError: 'No se pudo eliminar el archivo',
    dateDownloads: 'Fecha',
    fileDownloads: 'Archivo',
    downloadSize: 'Tamaño',
    download: 'Descargar',
    excelFilesDownloads: 'Archivos Excel',
    compressedFilesDownloads: 'Archivos Comprimidos',
    downloadAllDownloads: 'Descargar todo',
    downloadSelected: 'Descargar seleccionados',
    deleteAllDownloads: 'Eliminar todo',
    deleteSelectedDownloads: 'Eliminar seleccionados',
    confirmDeleteDownloads: '¿Está seguro de que desea eliminar',
    filesQuestionDownloads: ' archivo(s)?',
    allFilesQuestionDownloads: 'todos los archivos?',
};
