
import Vue from 'vue';
import moment from 'moment';
import ScatterChart from '@/report/ScatterChart';
import agent from '@/api/agent';
import { BillingByResidents } from "@/types/DashboardMarketIntelligence";
import StateSelectorFilter from '@/components/filters/StateSelectorFilter.vue';
import BillingByResidentsHelp from '../helpers/BillingByResidentsHelp.vue';
import DateIntervalIntelCardFilter from './DateIntervalIntelCardFilter.vue';

export default Vue.extend({
  components: {
    StateSelectorFilter,
    ScatterChart,
    BillingByResidentsHelp,
    "date-interval-filter": DateIntervalIntelCardFilter
  },
  data() {
    return {
      graph: {
        labelY: this.$t('yAxisLabel') as string,
        labelX: this.$t('xAxisLabel') as string,
        visible: false as boolean,
        data: [] as object[],
        height: 0 as number,
        isEmpty: true as boolean,
      },
      params: {
        state: [] as String[],
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: moment().add(-30, 'days').format('YYYY-MM-DD')
      },
      billingResidents: [] as BillingByResidents [],
      localCount: "0" as string
    };
  },
  mounted() {
    this.getBillingResidents();
    this.verifyResize();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    verifyResize(){
      if (this.$vuetify.breakpoint.xs){
        this.graph.height = 460;
      }
      else if (this.$vuetify.breakpoint.sm){
        this.graph.height = 220;
      }
      else if (this.$vuetify.breakpoint.md){
        this.graph.height = 150;
      }
      else {
        this.graph.height = 90;
      }
    },
    getBillingResidents(){
        this.params.state = Array.isArray(this.params.state) ? this.params.state : [this.params.state];
        this.graph.visible = false;
        agent.DashboradMarketIntelligence.getBillingByResidents(this.params).then((response) => {
          this.billingResidents = response;

          this.localCount = this.getApproximateLocalCount(this.billingResidents.length); 

          const data = this.billingResidents.map(item => ({
                x: item.residentsQuantity,
                y: item.billingTotal
              }));
          if (data.length === 0) {
            this.graph.isEmpty = true;
          }
          else {
            this.graph.isEmpty = false;
            this.graph.data = [{
              label: this.$t('locationsBillingRegion'),
              backgroundColor: '#2DCE98',
              data: data,
            }];
          }
        })
        .catch(() => {})
        .finally(() => { 
          setTimeout(() => {
            this.graph.visible = true;
          }, 100);
        });
    },
    handleResize() {
      this.graph.visible = false;
      this.verifyResize();
      setTimeout(() => {
          this.graph.visible = true;
        }, 100);
    },
    getApproximateLocalCount(count: number) : string{
      switch (true) {
        case count == 0:
          return "0";
        case count < 10:
          return "10-";
        case count >= 10 && count < 30:
          return "10+";
        case count >= 30 && count < 50:
          return "30+";
        case count >= 50 && count < 100:
          return "50+";
        case count >= 100 && count < 300:
          return "100+";
        case count >= 300 && count < 500:
          return "300+";
        case count >= 500 && count < 800:
          return "500+";
        case count >= 800 && count < 1000:
          return "800+";
        case count >= 1000 && count < 2000:
          return "1000+";
        case count >= 2000 && count < 5000:
          return "2000+";
        default:
          return "5000+";
      }
    },
  },
});
