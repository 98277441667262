<template>
  <v-layout wrap>
    <suspicious-access-filters v-on:modify="update"></suspicious-access-filters>
    <v-flex xs12>
      <v-data-table
        item-key="uuid"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 mb-10"
        must-sort
        :no-data-text="$t('tables.noData')"
        :expanded.sync="expanded"
        show-expand
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
      >
        <template #[`header.data-table-expand`]>
          <v-btn icon>
            <v-icon small @click.native="onExpandClick">{{ expandAllIcon }}</v-icon>
          </v-btn>
        </template>

        <template #[`item.date`]="{ item }">
          <v-tooltip left>
            <template #activator="{ on }">
              <div class="clickable" @click="openDetailsDialog(item)" v-on="on">
                {{ formatDateTimeSeconds(item.date) }}
                <v-badge color="info" icon="mdi-magnify" bordered small class="pl-1"></v-badge>
              </div>
            </template>
            <span>{{ $t('security.deviationAlert.tooltips.relatedSuspiciuosTransactions') }}</span>
          </v-tooltip>

        </template>
        <template #[`item.verificationStatus`]="{ item }">
          <v-chip :color="getStatusColor(item.verificationStatus)" dark>{{ formatStatus(item.verificationStatus) }}</v-chip>
        </template>

        <template #[`item.actions`]="{ item }" class="justify-center px-0">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 1)" v-on="on" 
                v-bind:disabled="item.verificationStatus == 'Opened' ? false : true">
                <v-icon color="success">mdi-check-circle</v-icon> 
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.verifiedOk') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 2)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'Opened' ? false : true">
                <v-icon color="error">mdi-alert</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.deviation') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 3)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'HasDeviations' ? false : true">
                <v-icon color="success">mdi-cash-multiple</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.refunded') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 4)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'HasDeviations' ? false : true">
                <v-icon color="error">mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.lost') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="action(item)" v-on="on">
                <v-icon small :color="getActionsColor(item.available)">
                  {{ getActionsIcon(item.available, item.url) }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.videoAlerts.videos') }}</span>
          </v-tooltip>
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0">

            <v-simple-table v-if="item.user != null">
              <template v-slot:default>                    
                <thead class="grey lighten-4 elevation-0">
                  <tr>
                    <th class="grey lighten-4 elevation-0">
                      <strong>{{ $t('tables.headers.openingResponsible') }}:</strong> {{ item.user.mobileUserFullName }}
                    </th>
                    <th class="grey lighten-4 elevation-0">
                      <strong>{{ $t('tables.headers.cpf') }}:</strong> {{ item.user.mobileUserCpf }}
                    </th>
                    <th class="grey lighten-4 elevation-0">
                      <v-btn icon :href="`http://wa.me/55${item.user.mobileUserPhone}`" target="_blank"><v-icon color="info">mdi-whatsapp</v-icon></v-btn>
                      {{ item.user.mobileUserPhone }}
                    </th>
                    <th class="grey lighten-4 elevation-0">
                      <v-btn icon :href="`mailto:${item.user.mobileUserUserEmail}`"><v-icon color="info">mdi-email-outline</v-icon></v-btn>
                      {{ item.user.mobileUserUserEmail }}
                    </th>
                    <th>
                      <!-- BLock Icon -->
                    </th>                              
                  </tr>
                </thead>
              </template>
            </v-simple-table>

            <v-simple-table>
              <template v-slot:default>     
                <tr class="grey lighten-4 elevation-0">
                  <th class="text-left pa-4">{{ $t('tables.headers.lockCode') }}</th>
                  <th class="text-left pa-4">{{ $t('tables.headers.deviceName') }}</th>
                  <th class="text-left pa-4">{{ $t('tables.headers.lockName') }}</th>
                  <th class="text-left pa-4">{{ $t('tables.headers.details') }}</th>
                </tr>
                <tbody>
                  <tr>
                    <td>{{item.lockCode}}</td>
                    <td>{{item.lockDeviceName}}</td>
                    <td>{{item.lockFriendlyName}}</td>
                    <td>{{item.result}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

      </v-data-table>
      <details-dialog
        v-model="details.show"
        :suspiciousAccess="details.suspiciousAccess"
        @close="handleDialog"
      ></details-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import SuspiciousAccessFilters from './components/SuspiciousAccessFilters.vue';
import { formatDateTimeSeconds } from '@/utility/TextFormatting';
import SuspiciousAccessDetailsDialog from './components/SuspiciousAccessDetailsDialog.vue';

export default {
  props: {
    activeTab: { type: Number, default: 0 },
    customer: { type: Number, default: null }
  },
  data() {
    return {
      headers: [
        { text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: true, value: 'pointOfSaleId' },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: false, value: 'pointOfSaleLocalCustomerName' },
        { text: this.$t('tables.headers.location'), align: 'left', sortable: false, value: 'pointOfSaleLocalName' },
        { text: this.$t('tables.headers.specificLocation'), align: 'left', sortable: false, value: 'pointOfSaleSpecificLocation' },
        { text: this.$t('tables.headers.dateHour'), align: 'left', sortable: true, value: 'date' },
        { text: this.$t('tables.headers.verificationStatus'), align: 'left', sortable: true, value: 'verificationStatus' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'actions' }
      ],
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
      },
      totalItems: 0,
      expanded: [],
      form: {},
      shouldUpdate: false,
      loading: false,
      updateInterval: null,
      intervalTime: 15000,
      details: {
        show: false,
        suspiciousAccess: {}
      }
    }
  },
  computed: {
    isAllExpanded() {
    return this.items.length > 0 && this.expanded.length === this.items.length;
    },
    expandAllIcon() {
      return this.isAllExpanded ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'
    },
    updateChange() {
      return (this.shouldUpdate == true) && (this.activeTab == 1);
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          const wasAllExpanded = this.isAllExpanded;
          this.getSuspiciousAccess();
          setTimeout(() => {
            this.expanded = wasAllExpanded ? this.items : [];
          }, 700);
        }
      },
      deep: true
    },
    updateChange(hasToUpdate) {
      if (hasToUpdate == true) {
        this.getSuspiciousAccess();
      }
    }
  },
  methods: {
    update(form) {
      this.form = form;
      this.setUpdateInterval();
      this.options.page = 1;
      const wasAllExpanded = this.isAllExpanded;
      this.getSuspiciousAccess();
      setTimeout(() => {
        this.expanded = wasAllExpanded ? this.items : [];
      }, 700);
    },
    updateByAction(form){
      this.form = form;
      this.setUpdateInterval();
      this.getSuspiciousAccess();
    },
    getSuspiciousAccess() {
      this.loading = true;
      const params = toApiPagination(this.options);
      this.insertFiltersFromForm(params);
      this.axios.get('api/Suspicious/access', { params })
        .then((response) => {
          this.items = response.data.items;
          this.totalItems = response.data.totalItems;
          this.shouldUpdate = false;
          this.items.forEach(i =>  { 
            i.available = false;
            i.url = "";
            }
          );
          this.getTransactionsVideos();
          this.loading = false;
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
          this.loading = false;
        })
    },
    getTransactionsVideos() {
      this.axios.get(`api/Video/Transactions`)
        .then((response) => {
          response.data.forEach(video => {
            const index = this.items.findIndex(i => video.fileName.includes(i.uuid));
            if (index >= 0) {
              const item = this.items[index];
              item.available = true;
              item.url = video.url;
              this.$set(this.items, index, item);
            }
          });
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
        })
        .then(() => {});
    },
    setUpdateInterval() {
      if (this.updateInterval) clearInterval(this.updateInterval);

      this.updateInterval = setInterval(() => {
        if (this.activeTab == 1) this.shouldUpdate = true;
      }, this.intervalTime);
    },
    insertFiltersFromForm(params) {
      const form = this.form;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.pointOfSaleId = form.pointOfSaleId;
      params.status = form.status;
      params.unlockResult = form.unlockResult;

      return params;
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.items;
    },
    getActionsIcon(available, url) {
      if (available && url) {
        return 'mdi-video';
      } else {
        return 'mdi-download';
      }
    },
    getActionsColor(available) {
      if (available) {
        return 'blue darken-2';
      } else {
        return 'green darken-2';
      }
    },
    action(item) {
      if (item.available) {
        window.open(item.url, '_blank');
      } else {
        this.downloadVideo(item);
      }
    },
    getStatusColor (status) {
      if (status == 'Opened') return 'orange lighten-1'
      else if (status == 'Verified') return 'green'
      else if (status == 'HasDeviations') return 'red lighten-1'
      else if (status == 'Lost') return 'grey darken-1'
      else return 'blue'
    },
    formatStatus(oldStatus) {
      let newStatus = ""
      if (oldStatus == 'Opened') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.pending')
      else if (oldStatus == 'Verified') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.verifiedOk')
      else if (oldStatus == 'HasDeviations') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.deviation')
      else if (oldStatus == 'Refunded') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.refunded')
      else if (oldStatus == 'Lost') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.lost')

      return newStatus
    },
    handleClick(uuid, action) {
      let newStatus = 'Opened';
      if (action == 1) {
        newStatus = 'Verified';
      } else if (action == 2){
        newStatus = 'HasDeviations';
      } else if (action == 3) {
        newStatus = 'Refunded';
      } else if (action == 4) {
        newStatus = 'Lost';
      }
      this.axios.put(`api/suspicious/access/${uuid}/status?newStatus=${newStatus}`)
      .then(() => {
        const message =  this.$t('security.deviationAlert.messages.success.updateStatusTransaction');
        this.sendFeedBack('success', message);
        this.updateByAction(this.form)
      }).catch((error) => this.sendFeedBack('error', error));
    },
    downloadVideo(item) {
      let startTime = new Date(item.date);
      let endTime = new Date(item.date);
      let params = {
        startTime: new Date(startTime.setSeconds(startTime.getSeconds() - 20)),
        endTime: new Date(endTime.setSeconds(startTime.getSeconds() + 320))
      }
      this.axios.get(`api/Video/${item.pointOfSaleId}/${item.uuid + ".dav"}`, { params })
      .then(() => {
        const message = this.$t('security.deviationAlert.messages.success.downloadRealizeSuccess');
        this.sendFeedBack('success', message);
      })
      .catch((error) => {
        this.sendFeedBack('error', error);
      })
    },
    sendFeedBack(type, content) {
      this.$emit('feedbackSent', {
        type: type,
        content: content
      });
    },
    formatDateTimeSeconds,
    openDetailsDialog(item) {
      this.details.show = true;
      this.details.suspiciousAccess = item;
      this.details.suspiciousAccess.status = this.form.status;
    },
    handleDialog(value) {
      this.details.show = value;
    }
  },
  components: {
    SuspiciousAccessFilters,
    'details-dialog': SuspiciousAccessDetailsDialog
  }
}
</script>

<style scoped>
  .details-table {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .details-table tbody tr td {
    border:none !important;
    height: 24px !important;
  }
  .clickable {
    cursor: pointer
  }
</style>