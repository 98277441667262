export default {
    searchPageMobile: 'Buscar página',
    favoritesMenuMobile: 'Favoritos',
    telemetryMenuMobile: 'Telemetría',
    salesMenuMobile: 'Ventas',
    stockMenuMobile: 'Inventario',
    generalMenuMobile: 'General',
    registersMenuMobile: 'Registros',
    crmMenuMobile: 'CRM',
    securityMenuMobile: 'Seguridad',
    financialMenuMobile: 'Finanzas',
    savedPagesMenuMobile: 'No hay páginas guardadas',
    favoritePageMobile: 'Agregar a favoritos',
    toRemovefavoritePageMobile: 'Quitar de favoritos',
};
