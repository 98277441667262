export default {
    myInfo: 'Mi información',
    phone: 'Teléfono',
    dateOfBirth: 'Fecha de nacimiento',
    dateFieldPlaceHolder: 'dd/mm/aaaa',
    updateInfoDialogText: '¿Estás seguro de que deseas actualizar tu información?',
    infoUpdatedMessage: 'Información actualizada con éxito',
    editButton: 'Editar',
    onlyNumbers: 'Solo números',
    registrationData: 'Datos de registro',
    registrationDataSubtitle: '¡Mantén siempre tus datos actualizados para garantizar la autenticidad y facilitar la comunicación con tu cuenta!',
}