export default {
    recentTransactionsRemoteCredit: 'Transacciones recientes',
    dispensedRemoteCredit: 'Dispensado',
    amountRemoteCredit: 'Valor',
    methodRemoteCredit: 'Método',
    cardRemoteCredit: 'Tarjeta',
    productRemoteCredit: 'Producto',
    dateTimeRemoteCredit: 'Fecha/Hora',
    creditTransferRemoteCredit: 'Transferencia de créditos',
    quantityRemoteCredit: 'Cantidad',
    resetCreditsRemoteCredit: 'Restablecer créditos',
    closeRemoteCredit: 'Cerrar',
    sendRemoteCredit: 'Enviar',
    noResultsRemoteCredit: 'Sin resultados',
    creditsSentRemoteCredit: 'Créditos enviados',
    creditsReceivedRemoteCredit: 'Créditos recibidos',
    creditsUsedRemoteCredit: 'Créditos utilizados',
    errorSendingCredits: 'Error al enviar créditos',
    errorUsingRemoteCredit: 'Error al usar crédito remoto',
    paymentMethodCreditRemoteCredit: 'Crédito',
    paymentMethodDebitRemoteCredit: 'Débito',
    paymentMethodVoucherRemoteCredit: 'Voucher',
    remotePaymentMethod: 'Remoto',
    paymentMethodKindRemoteCredit: 'Efectivo',
    runknownRemoteCredit: 'Desconocido',
    failedToDismissRemoteCredit: 'Error al dispensar',
    reversedRemoteCredit: 'Revertida',
    reversalCancelledRemoteCredit: 'Reversión cancelada',
    errorReversingRemoteCredit: 'Error al revertir',
    productNotSelectedRemoteCredit: 'Producto no seleccionado',
};
