export default {
    averageTicketCardTitle: 'Ticket Promedio por Ubicaciones',
    averageTicketStateLabel: 'Estado',
    averageTicketTotalLocationsMessage: 'Total de ubicaciones consultadas: ',
    averageTicketTotalLabel: 'Ticket Promedio Total',
    averageBillingLabel: 'Promedio de Facturación',
    averageProductVarietyLabel: 'Promedio de Variedad de Productos',
    averageTicketNoResultsMessage: 'Sin resultados',
    averageTicketChartTitle: 'Ticket Promedio por Variedad de Productos',
    xAxisLabelAverageTicket: 'Variedad de Productos',
    yAxisLabelAverageTicket: 'Ticket Promedio',
    averageTicketLabel: 'Ticket Promedio',
    averageTicketCardTitleNew: 'Ticket promedio por combinación de productos',
};
