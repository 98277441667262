<template>
 <v-layout wrap class="mb-16 mt-5">
    <v-row cols="12" align-content="end" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="4">
          <v-select
            v-model="filesFilter"
            :items="filesExtensions"
            @change="selectedItems = []"
            multiple
            :label="$t('filterFilesDownloads')"
          ></v-select>
      </v-col>
      <div class="text-right my-4">
          <v-btn
            color="error"
            class="ml-4"
            :loading="removeDownloads.loading"
            @click.native="onDeleteClicked"
            :disabled="!filteredItems.length"
          >
            <v-icon>delete</v-icon>{{getTextDeleteButton()}}
          </v-btn>

        <download-button
          :url="`api/downloads/zip-selected`"
          :query-params="getParameters()"
          #default="{ loading, download }"
          @error="(error) => $refs.feedback.handleError(this.$t('downloadFileError'), error)"
          returns-link
          :methods="`POST`"
        >
          <v-btn
            color="success"
            class="mx-4"
            :loading="loading"
            @click.stop="download"
            :disabled="!filteredItems.length"
          >
            <v-icon>mdi-download</v-icon>{{getTextDownloadButton()}}
          </v-btn>
        </download-button>
      </div>
    </v-row>
    <v-flex xs12>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model="selectedItems"
              :headers="headers"
              :items="filteredItems"
              :loading="loading"
              show-select
              item-key="filename"
              class="elevation-2"
              must-sort
              sort-by="lastModified"
              sort-desc
              hide-default-footer
              disable-pagination
            >
              <template #item.lastModified="{ item }">
                {{ $moment(item.lastModified).format('DD/MM/YYYY HH:mm:ss') }}
              </template>
              <template #item.size="{ item }">
                {{ formatBytes(item.size) }}
              </template>
              <template #item.download="{ item }">
                <template v-if="item.status === 'Success'">
                  <download-button
                    :url="`api/downloads/${item.filename.replace('/','|')}`"
                    :filename="item.filename"
                    returns-link
                    #default="{ loading, download }"
                    @error="(error) => $refs.feedback.handleError(this.$t('downloadFileError'), error)"
                  >
                    <v-btn icon :loading="loading" @click.stop="download">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </download-button>
                </template>
                <template v-else-if="item.status === 'Pending'">
                  {{ $t('generatingFileDownloads') }}
                  <v-progress-circular indeterminate size="16" color="primary">
                  </v-progress-circular>
                </template>
                <template v-else>
                  {{ $t('fileGenerationFailure') }}
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>
    <v-dialog v-model="removeDownloads.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          {{this.getTextDialogDeleted()}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDownloads.enabled = false">{{ $t('informationNo') }}</v-btn>
          <v-btn
            color="green darken-1"
            :loading="removeDownloads.loading"
            text
            @click.native="onDeleteConfirmed"
          >{{ $t('informationRemove') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <feedback ref="feedback"></feedback>
  </v-layout>
</template>

<script>
import DownloadButton from '@/components/DownloadButton';

export default {
  data() {
    return {
      headers: [
        { text: this.$t('dateDownloads'), align: 'left', sortable: true, value: 'lastModified' },
        { text: this.$t('fileDownloads'), align: 'left', sortable: true, value: 'displayName' },
        { text: this.$t('downloadSize'), align: 'left', sortable: true, value: 'size' },
        { text: this.$t('download'), align: 'left', sortable: false, value: 'download' }
      ],
      selectedItems: [],
      items: [],
      loading: true,
      updateId: null,
      filesFilter: ["xlsx", "zip"],
      filesExtensions: [
        { text: this.$t('excelFilesDownloads'), value: "xlsx" },
        { text: this.$t('compressedFilesDownloads'), value: "zip" }
      ],
      removeDownloads: {
        enabled: false,
        loading: false
      },
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = []
        this.filesFilter.forEach(extension => {
          let extensionArray = []
          extensionArray = this.items.filter(download => download.filename.endsWith(extension))
          filteredItems.push(...extensionArray)
        })

      return filteredItems;
    }
  },
  mounted() {
    this.getItems();
    this.updateId = setInterval(this.getItems, 10000);
  },
  beforeDestroy() {
    clearInterval(this.updateId);
  },
  methods: {
    getItems() {
      this.loading = true;
      this.axios.get('api/downloads')
        .then((response) => {
          this.items = response.data;
          this.items.map(i => i.displayName = i.filename.split('/').at(-1));
          this.loading = false;
        })
    },
    onDeleteClicked() {
      this.removeDownloads.enabled = true;
    },
    onDeleteConfirmed(){
      this.removeDownloads.loading = true;
      this.axios.delete('api/downloads/deleted-files', {data: this.getParameters()})
        .then((response => {
          this.$refs.feedback.handleSuccess(response.data);
          this.getItems();
        }))
        .catch(error => this.$refs.feedback.handleError(this.$t('deleteFileError'), error))
        .finally(() => {
          this.removeDownloads.loading = false;
          this.selectedItems = [];
          this.removeDownloads.enabled = false;
        });
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '-';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    getParameters(){
        return  this.selectedItems.length === 0 ? {filesExtensions: [...this.filesFilter]} : {filesNames: this.selectedItems.map(i => i.filename)};
    },
    getTextDownloadButton(){
      return this.selectedItems.length === 0 ? this.$t('downloadAllDownloads') : this.$t('downloadSelected');
    },
    getTextDeleteButton(){
      return this.selectedItems.length === 0 ? this.$t('deleteAllDownloads') : this.$t('deleteSelectedDownloads');
    },
    getTextDialogDeleted(){
      return this.$t('confirmDeleteDownloads') +  (this.selectedItems.length > 0 ? this.selectedItems.length + this.$t('filesQuestionDownloads') : this.$t('allFilesQuestionDownloads'))
    }
  },
  components: {
    DownloadButton
  }
}
</script>
