
import Vue from 'vue';
import { shouldRefreshData, toApiPagination } from "../../components/TableOptionsComparator";
import { formatCpf } from '@/utility/TextFormatting'
import BlockUserIcon from "../../components/common/BlockUserIcon.vue";
import verificationIcon from "../../components/common/VerificationUserIcon.vue";
// eslint-disable-next-line no-unused-vars
import { MobileUser } from '@/types/MobileUser';
import download from '@/components/Download';
import {formatDateTimeMinutes, formatDateTimeToDate} from '@/utility/TextFormatting';


export default Vue.extend({
  components: {
    BlockUserIcon,
    verificationIcon
  },
  data() {
    return {
      mobileUsers: [] as MobileUser[],
      headers: [
        { text: "Nome", align: "left", sortable: true, value: "fullName" },
        {text: "", align: "left", sortable: false, width:"5px", value: "nameVerified"},
        { text: "Email", align: "left", sortable: true, value: "email" },
        {text: "", align: "left", sortable: false, width:"5px", value: "emailVerified"},
        { text: "Telefone", align: "left", sortable: true, value: "phone" },
        {text: "", align: "left", sortable: false, width:"5px", value: "phoneVerified"},
        { text: "Data de Nascimento", align: "left", sortable: true, value: "dateBirth" },
        {text: "", align: "left", sortable: false, width:"5px", value: "dateBirthVerified"},
        { text: "CPF", align: "left", sortable: false, value: "mobileUserCpf" },
        {text: "", align: "left", sortable: false, width:"5px", value: "cpfVerified"},
        { text: "Loja Associada", align: "left", sortable: true, value: "pointOfSaleDescription" },
        { text: "Quantidade de Compras", align: "left", sortable: false, value: "purchaseQuantity" },
        { text: "Data de Cadastro", align: "left", sortable: true, value: "dateCreated" },
        { text: "Data de Verificação", align: "left", sortable: true, value: "dateVerification" },
        { text: "Status", align: "center", sortable: false, value: "actions" }
      ],
      filterHeaders: [] as Array<object>,
      loading: {
        table: true,
        excel: false
      },
      search: {
        valid: true,
        text: "" as string,
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["fullName"],
        sortDesc: [false],
        mustSort: true,
      },
      onlyBlockedUsers: false,
      show: false,
      isWhitelabel: false,
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getMobileUsers();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getMobileUsers();
    this.filterHeaders = this.headers;
  },
  methods: {
    formatCpf(cpf: string) {
      return formatCpf(cpf)
    },
    getMobileUsers() {
      this.loading.table = true;
      const params = toApiPagination(this.options, this.search.text as any);
      Object.assign(params, { onlyBlockedUsers: this.onlyBlockedUsers });
      this.axios.get("api/MobileUsers", { params: params })
        .then((response) => {
          this.loading.table = false;
          this.mobileUsers = response.data.items;
          this.totalItems = response.data.totalItems;
          this.isWhitelabel = response.data.isWhiteLabel
          this.validateWhiteLabel(this.isWhitelabel)
        });

    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    getExcel() {
      this.loading.excel = true;
      const params = {};
      Object.assign(params, { onlyBlockedUsers: this.onlyBlockedUsers });
      this.axios.get("api/MobileUsers/excel", { params: params, responseType: 'blob' })
        .then((response) => {
          this.loading.excel = false;
          download(response, 'Usuários App.xlsx');
        })
        .catch((error) => {
          (this.$refs.feedback as any).handleError("Houve um erro ao baixar o relatório", error);
          this.loading.excel = false;
        })

    },
    validateWhiteLabel(isWhiteLabel: boolean){

        this.filterHeaders = isWhiteLabel ? this.headers: this.headers.filter(
          h => h.value != 'purchaseQuantity' &&
          h.value != 'dateCreated' &&
          h.value != 'dateVerification'
        );

    },
    formatDateTimeMinutes,
    formatDateTimeToDate
  },
});
