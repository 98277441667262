import { render, staticRenderFns } from "./MarketInformations.vue?vue&type=template&id=4cd7a7f7&scoped=true&"
import script from "./MarketInformations.vue?vue&type=script&lang=ts&"
export * from "./MarketInformations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd7a7f7",
  null
  
)

export default component.exports