export default {
        typeOfLocation: 'Location Type',
        locationTypeDescription: 'Location Type refers to the type of store where you have physically installed your POS. For example:',
        locationTypeOptions: 
        `<li>POS installed in a condominium of houses or apartments</li>
        <li>POS installed in an office or industrial company</li>
        <li>POS installed in hospital</li>
        <li>POS installed at university</li>
        <li>Others</li>`,
        locationTypeSelect: 'Select the location before defining the type',
  };