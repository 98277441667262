export default {
  discounts: {
    title: 'Descontos',
    coupons: {
      title: 'Cupons',
    },
    promotions: {
      title: 'Promoções',
    },
    help: {
      description: {
        coupons: `<p>Nesta página você pode criar descontos (botão <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Desconto) para os consumidores finais, como meio de fidelização dos mesmos. Existem dois tipos de desconto: cupons e promoções. Cada um possui uma aba correspondente na página. Os descontos estão disponíveis para o terminal TouchPay Market e o app TouchPay Mobile.</p>

        <h3>Cupons</h3>
        <p>Para um consumidor poder usar um desconto de cupom, ele precisará inserir o código do cupom no terminal de pagamento ou no app. Um cupom é sempre associado a um desconto específico. Para criar cupons, use o botão <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Cupons no desconto desejado. Caso o cupom seja removido, ninguém mais conseguirá utilizá-lo. Também é possível definir uma data de expiração para o cupom.</p>
    
        <p>O desconto do cupom pode ter um valor fixo em reais, ou ser uma porcentagem do valor total da compra. Também é possível definir um valor mínimo da compra a partir do qual o desconto poderá ser aplicado.</p>
    
        <p>O cupom pode ser associado ao número de celular do comprador. Nesse caso, ele só poderá ser usado se o consumidor, ao inserir o cupom, também inserir o seu número de celular e esse número for igual ao número associado ao cupom.</p>
        `,
        alert: 'Para que as promoções funcionem, é necessário que o TouchPay Market esteja em uma versão a partir da 3.8.0, e o TouchPay Mobile em uma versão a partir da 1.14.0.',
        promotions: `
          <p>Promoções são aplicadas sobre produtos ou combo de produtos. Ao adicionar um desconto do tipo promoção, é necessário escolher os produtos que fazem parte da promoção. Alguns exemplos de aplicação:</p>
    
          <ul>
            <li>Ao comprar 2 AMColas, a segunda sai com 50% de desconto: marque o tipo de desconto como <strong>%</strong>. Adicione um produto AMCola com quantidade 1 e desconto 0. Em seguida adicione outra AMCola com quantidade 1 e desconto 50.</li>
            <li>Ao comprar uma AMCola e dois AMChips, o combo tem 20% de desconto: marque o tipo de desconto como <strong>%</strong>. Adicione um produto AMCola com quantidade 1 e desconto 20. Em seguida adicione o produto AMChips com quantidade 2 e desconto 20.</li>
            <li>Ao comprar 2 caixas de cápsulas de café AMCoffee Expresso ou AMCoffee Longo, ganhe um desconto de 10 reais: criamos um agrupamento (menu <strong>Agrupamentos</strong>) chamado AMCoffee contendo os dois tipos de café. Na página de agrupamentos há mais informações sobre como criá-los e usá-los. Em seguida criamos um novo desconto, marcando o tipo como <strong>R$</strong>. Adicionamos um novo produto AMCoffee (que é o agrupamento) no desconto, com quantidade 2 e desconto 10.</li>
          </ul>
    
          <p>Uma promoção pode ser aplicada a todos os pontos de venda, ou ser válida apenas em alguns PDVs. Por padrão a promoção é válida para todos os PDVs. Para restringí-la a apenas alguns PDVs específicos, clique em <strong>Avançado</strong> e desmarque a opção <strong>Aplicar em todos os PDVs</strong>. Em seguida é possível escolher apenas os PDVs desejados.</p>
        `,
      }
    }
  }
}