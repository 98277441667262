
import Vue from 'vue';


export default Vue.extend({
  props: {
    isVerification: { type: Boolean, default: false },
  },
  methods: {
      getTextIsVerify(){
        var text;

        if(this.isVerification == true){
          text = "Verificado"
        }
        else if(this.isVerification == false){
          text = "Falha na verificação" 
        }
        else{
          text = "PDV com verificação desativada"
        }
        return text;
      }
  }
});
