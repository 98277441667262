export default {
  pointOfSale: {
    data: {
      id: "Ponto de venda {pointOfSaleId}",
      title: {
        pointOfSale: "Ponto de Venda",
        pointsOfSale: "Pontos de Venda"
      },
    },
    posManagement: {
      help: {
        title: "Gerenciamento de Pontos de Vendas",
        description: `
        <p>
          Nesta página, você pode visualizar e gerenciar os Pontos de Venda (PDVs) e os terminais de pagamento associados ao seu ambiente. A página é dividida em duas abas principais: Pontos de Venda e Terminais.
        </p>
        <p>
          Na aba <strong>Pontos de Venda</strong>, você encontrará a lista completa de PDVs registrados, incluindo o ID do PDV, o sistema de pagamento, o código do POS, o modelo da máquina, o cliente responsável, a localização, o centro de distribuição e a data de criação. Também é possível editar ou excluir as informações do PDV.
        </p>
        <p>
          Na aba <strong>Terminais</strong>, são exibidos todos os terminais de pagamento registrados, mesmo que não estejam associados a um PDV. As informações incluem o ID da máquina, o PDV associado (se houver), o modelo da máquina, a versão (para máquinas vinculadas a um PDV) e a senha do terminal. Utilize as funcionalidades de editar ou excluir para gerenciar seus terminais de forma eficiente.
        </p>`,
      }
    }
  }
}
