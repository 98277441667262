export default {
    systemPaymentPointOfSale: "Payment system",
    systemCodePointOfSale: "System code",
    enabledInvoicePointOfSale: "Enabled invoice",
    disabledInvoicePointOfSale: "Invoice not enabled",
    configurePointOfSale: "Configure",
    appEnabledPointOfSale: "App enabled",
    appDisabledPointOfSale: "App not enabled",
    displayPointOfSale: "Display",
    registerPointOfSale: "Register",
    picPayPointOfSale: "PicPay",
    picPayTokenPointOfSale: "PicPay Token",
    registeredLocksPointOfSale: "Registered locks",
    noLocksPointOfSale: "No registered locks",
    changePointOfSale: "Change",
    addPointOfSale: "Add",
    thenPointOfSale: "logo",
    clientPointOfSale: "Client",
    locationPointOfSale: "Location",
    internalLocationPointOfSale: "Internal location",
    statePointOfSale: "State",
    residentsQuantityPointOfSale: "Number of residents",
    employeesQuantityPointOfSale: "Number of employees",
    studentsQuantityPointOfSale: "Number of students",
    frequentPeopleQuantityPointOfSale: "Number of frequent people",
    stockPointOfSale: "Stock",
    distributionCenterPointOfSale: "Distribution center",
    sharedStockPointOfSale: "Shared stock",
    posCodePointOfSale: "POS code",
    manufacturerPointOfSale: "Manufacturer",
    modelPointOfSale: "Model",
    updatePointOfSale: "Update",
    unableToSavePosPointOfSale: "Unable to save point of sale",
    okPointOfSale: "Ok",
    identificationCodePointOfSale: "System identifier code.",
    systemNotFoundPointOfSale: "System not found",
    tokensNotFoundPointOfSale: "Tokens not found",
    selectClientPointOfSale: "Select a client or add a new one.",
    selectLocationPointOfSale: "Select a customer before choosing a location",
    totalNumberOfResidentsPointOfSale: "Select a location before setting the total number of residents",
    localTypePointOfSale: "Location type",
    nameDistributionCenterPointOfSale: "Name of the distribution center",
    distributionCenterNotRegistered: "There are no distribution centers registered",
    distributionCenterConfiguration : `Here we configure the distribution center that is associated with this point of sale.
    When a replenishment is made at this POS, the products will be subtracted from it.
    In addition, when generating Pick Lists for this POS, it will be
    the distribution center considered when checking the availability of each product.
    Sales made at this POS will also have the average cost corresponding to the average cost of the
    product of the distribution center at the time of purchase`,
    cannotBeChangedPointOfSale: `As the POS has shared stock with the point of sale {{ masterPointOfSaleName }},
    its central stock cannot be changed`,
    accessToFunctionality: "To access this functionality, you must have the stock module contracted.",
    noPointsOfSalePointOfSale: "No points of sale",
    withMoreTerminalPointOfSale: "For stores with more than one terminal",
    microMarketsFunction: `This function is only for micro-markets that have
    more than one payment terminal in the same store`,
    terminalInTheSameStore: `If there is more than one terminal in the same store, a point of sale is required for each terminal.
    a point of sale is needed for each terminal. Although they are separate POSs, because it is a single store, there must be a single planogram and a single stock.
    In this way, when any of the store's POS carry out a sale, the same stock is subtracted`,
    registrationAtOnlyOnePdv: `The planogram must be registered in only one POS, which
    we call it <strong>main POS</strong>. The consultation of
    inventory must also be carried out at the main POS. The others
    Store POS are <strong>secondary POS</strong>.
    All secondary POS must have the primary POS in the
    <strong>Shared stock</strong> field. For the POS
    main, this field is empty.`,
    touchPayReplenishmentPointOfSale: "TouchPay Replenishment",
    machinePointOfSale: "Machine",
    errorFindingTokenPointOfSale: "Could not find a token for the POS.",
    taxRegion: 'Tax region',
}