import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    datasets: { type: Array, required: true },
    showLegend: { type: Boolean, default: true },
  },
  mounted() {
    this.renderChart({
      datasets: this.datasets,
    }, {
      responsive: true, 
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.index;
            const value = data.datasets[datasetIndex].data[index];
            const label = data.datasets[datasetIndex].label[index];
  
            let tooltip = `${label}: ${value}%`
            return tooltip;
          }
        }
      }
    });
  }
};
