export default {
    itemDialogContent: 'Ítems del planograma',
    itemDialogContentDescription: 'Un ítem del planograma representa un producto en tu punto de venta, junto con algunas reglas asociadas a él. Entre estas reglas se incluyen:',
    selectionNumberHelp:
        `Número de selección: en el caso de una máquina, indica cuál muelle o compartimento contiene el producto. 
        Para micro-mercados, puede indicar el estante o posición del producto. Al crear un nuevo ítem, la selección se completa automáticamente,
        pero puede cambiarse. Si no es necesario controlar la selección, como suele ser el caso para los micro-mercados,
        es más simple mantener el valor que ya está completado.`,
    pricerHelp: `Precio: el precio a cobrar. Puede ser diferente al precio estándar definido en el registro de productos.`,
    maximumCapacityHelp: `Capacidad máxima: solo para máquinas expendedoras. Indica cuál es el máximo que cabe de este producto en esta selección.`,
    desiredQuantityHelp:
        `Cantidad deseada (nivel de reorden): cuál es la cantidad deseada de este producto en el momento del abastecimiento. 
        Esta cantidad se utiliza en la generación de listas de recolección y debe ser menor o igual a la capacidad máxima si esta está completada.`,
    criticalMinimumHelp: `Mínimo crítico: cuando el inventario alcance este número, se mostrará una alerta en la página de telemetría.`,
    advancedOptionsHelp: `Opciones avanzadas (solo para micro-mercados):`,
    availabilityHelp:
        `Disponibilidad: configura si el producto estará disponible localmente, bajo pedido o ambos.
        Los pedidos pueden realizarse a través de la aplicación TouchPay Mobile.`,
    sponsoredLinkHelp:
        `Enlace patrocinado: se puede utilizar cuando hay una asociación con algún supermercado, por ejemplo.
        Cuando este campo esté completado y el ítem esté configurado para pedidos, al hacer clic en el pedido a través de la aplicación,
        el enlace se abrirá en el navegador.`,
    selectionLabel: 'Selección',
    priceLabel: 'Precio',
    maxCapacityLabel: 'Capacidad máxima',
    desiredQuantityLabel: 'Cantidad deseada (nivel de reorden)',
    criticalMinimumLabel: 'Mínimo crítico',
    availabilityLabel: 'Disponibilidad',
    sponsoredLinkLabel: 'Enlace patrocinado',
    itemDialogContentClose: 'Cerrar',
    itemDialogContentSave: 'Guardar',
    errorSavingItem: 'Error al guardar el ítem',
    successItemCreated: 'Ítem creado con éxito',
    successItemUpdated: 'Ítem actualizado con éxito',
    itemDialogContentLocal: 'Local',
    itemDialogContentOrder: 'Pedido',
    itemDialogContentLocalAndOrder: 'Local y pedido',
    itemDialogContentAddItem: 'Agregar ítem',
    itemDialogContentChangeItem: 'Cambiar ítem',
    expandAdvanced: 'Avanzado',
};
