export default {
    requiredField: 'This field is mandatory',
    numberInteger: 'Enter an integer',
    commonCharacters: 'Enter valid characters',
    minLength: 'Enter at least {min} characters',
    noWhitespace:'Whitespace is not allowed',
    phoneField: 'There was a problem verifying the phone number. Try inputting only numbers. If the problem persists, please contact the administration at +55 (11) 5199-6668.',
    insertValidValue: 'Enter a valid value',
    percentageMustBeBetween0And100: 'Percentage must be between 0 and 100',
};
