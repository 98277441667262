export default {
    titleTransactionsReportsFilters: 'Filters and actions',
    startDateTransactionsReportsFilters: 'Start date',
    endDateTransactionsReportsFilters: 'End date',
    clearTransactionsReportsFilters: 'Clear',
    locationTransactionsReportsFilters: 'Location',
    stateTransactionsReportsFilters: 'State',
    customerTransactionsReportsFilters: 'Customer',
    pointOfSaleTransactionsReportsFilters: 'Point of sale',
    productTransactionsReportsFilters: 'Product',
    categoryTransactionsReportsFilters: 'Category',
    groupByTransactionsReportsFilters: 'Group by',
    excelTransactionsReportsFilters: 'Excel',
    updateTransactionsReportsFilters: 'Update',
    selectCustomerFirstTransactionsReportsFilters: 'Select a customer first',
    lastThirtyDaysTransactionsReportsFilters: '30 days',
    lastFifteenDaysTransactionsReportsFilters: '15 days',
    lastSevenDaysTransactionsReportsFilters: '7 days',
    sameDayTransactionsReportsFilters: 'same day',
    productsTransactionsReportsFilters: 'Products',
    customersTransactionsReportsFilters: 'Customers',
    pointsOfSaleproductsTransactionsReportsFilters: 'Points of Sale',
    statesTransactionsReportsFilters: 'States',
    paymentMethodsTransactionsReportsFilters: 'Payment Methods',
    cardFlagsTransactionsReportsFilters: 'Card Flags',
    dayTransactionsReportsFilters: 'Day',
    monthTransactionsReportsFilters: 'Month',
    dayOfTheWeekproductsTransactionsReportsFilters: 'Day of the Week',
    toCleanTransactionsReportsFilters: 'Clean',
  };