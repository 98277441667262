export default {
    searchMenu: 'Buscar',
    searchPage: 'Buscar página',
    favoritesMenu: 'Favoritos',
    telemetryMenu: 'Telemetría',
    salesMenu: 'Ventas',
    stockMenu: 'Inventario',
    generalMenu: 'General',
    registersMenu: 'Registros',
    crmMenu: 'CRM',
    securityMenu: 'Seguridad',
    financialMenu: 'Finanzas',
    savedPagesMenu: 'No hay páginas guardadas',
    "Attention": "Atención",
    newBillingAccessDialogText: `<p>Estamos moviendo la pantalla de <strong style="color:#00B31D">Mis Facturas</strong> al área de usuario
    	<strong>ubicada en la parte superior de la pantalla</strong>, por favor revise las instrucciones a continuación:</p>`,
    "Close": "Cerrar",
};
