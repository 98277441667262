export default {
      redistributeButtonNegativeProductDetails: 'Redistribuir Item',
      stockNegativeProductDetails: 'Estoque: ',
      inStockNegativeProductDetails: 'Em Estoque: ',
      reservationsNegativeProductDetails: 'Reservas: ',
      balanceNegativeProductDetails: 'Saldo: ',
      quantityNegativeProductDetails: 'Quantidade',
      picklistNegativeProductDetails: 'Pick list',
      pointOfSaleNegativeProductDetails: 'PDV',
      customerNegativeProductDetails: 'Cliente',
      locationNegativeProductDetails: 'Local',
      specificLocationNegativeProductDetails: 'Local Específico',
      planogramNegativeProductDetails: 'Planograma',
      reservedQuantityNegativeProductDetails: 'Quantidade Reservada',
      salesThisWeekNegativeProductDetails: 'Vendas na Semana',
      currentQuantityNegativeProductDetails: 'Quantidade Atual',
      desiredQuantityNegativeProductDetails: 'Quantidade Desejada',
      criticalMinimumNegativeProductDetails: 'Mínimo Crítico',
      saveQuantityErrorNegativeProductDetails: 'Erro ao alterar quantidade',
      distributeQuantitiesErrorNegativeProductDetails: 'Erro ao distribuir reservas do produto',
      cancelNegativeProductDetails: 'Cancelar',
      toSaveNegativeProductDetails: 'Salvar',
  };
  