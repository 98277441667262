export default {
  salesByWeekdayTitle: 'Vendas por dias da semana',
  salesByWeekdaySubtitle: 'Acompanhe o desempenho semanal das vendas',
  thisWeek: 'Essa semana',
  previousWeek: 'Semana passada',
  average: 'Média dos dias',
  sundayShort: 'Dom.',
  mondayShort: 'Seg.',
  tuesdayShort: 'Ter.',
  wednesdayShort: 'Qua.',
  thursdayShort: 'Qui.',
  fridayShort: 'Sex.',
  saturdayShort: 'Sáb.',
  salesByWeekdayGetError: 'Houve um erro ao carregar as vendas por dia da semana',
  salesByWeekdayHelpTitle: 'Indicador de Vendas por Dia da Semana',
  salesByWeekdayHelpText:`
    <p style="text-align: justify;">
      Entender como suas vendas se comportam ao longo da semana é essencial para o sucesso do seu negócio.
      Este indicador oferece uma visão das vendas diárias, comparando o desempenho entre a semana atual
      e a semana anterior, além de fornecer uma média para uma análise mais precisa.
    </p>
    <p style="text-align: justify;">
      <strong>Como Interpretar:</strong>
      <ul>
        <li style="text-align: justify">
          <strong>Semana Atual:</strong> Esta coluna mostra as vendas de cada dia desta semana em comparação com os dias correspondentes
          da semana passada. Você pode ver imediatamente se houve um aumento ou diminuição nas vendas em relação à semana anterior.
        </li>
        <li style="text-align: justify">
          <strong>Semana Passada:</strong> Aqui, você encontrará as vendas diárias da semana anterior. Comparar esses números com
          a semana atual pode destacar padrões de comportamento do consumidor e ajudar na previsão de tendências futuras.
        </li>
        <li style="text-align: justify">
          <strong>Média:</strong> A linha de média entre as semanas fornece uma referência estável para o desempenho geral.
          Isso ajuda a suavizar flutuações diárias e fornece uma compreensão mais clara do padrão geral de vendas.
        </li>
      </ul>
    </p>
    <p style="text-align: justify;">
      <strong>Sugestões de como utilizar essas informações:</strong>
      <ul>
        <li style="text-align: justify">
          Identifique os dias da semana com maior e menor desempenho de vendas.
        </li>
        <li style="text-align: justify">
          Ajuste a alocação de recursos com base nas tendências de vendas.
        </li>
        <li style="text-align: justify">
          Monitore regularmente para identificar mudanças significativas no comportamento do consumidor.
        </li>
        <li style="text-align: justify">
          Acompanhe o progresso em relação às metas de vendas semanais.
        </li>
      </ul>
    </p>
  `
}