export default {
    titleSaveReportMenu: 'Save report filters',
    saveFiltersSaveReportMenu: 'Save Filters',
    cancelSaveReportMenu: 'Cancel',
    saveSaveReportMenu: 'Save',
    deleteConfirmationSaveReportMenu: 'Delete report filters',
    unableToRemoveSaveReportMenu: 'Unable to remove item',
    productSaveReportMenu: 'Product',
    categorySaveReportMenu: 'Category',
    stockSaveReportMenu: 'Stock',
    operationSaveReportMenu: 'Operation',
    daySaveReportMenu: 'Day',
    monthSaveReportMenu: 'Month',
    dayOfTheWeekSaveReportMenu: 'Day of the Week',
    customersSaveReportMenu: 'Customers',
    pointsOfSaleSaveReportMenu: 'Points of Sale',
    statesSaveReportMenu: 'States',
    paymentMethodsSaveReportMenu: 'Payment Methods',
    cardFlagsSaveReportMenu: 'Card Flags',
    savedFiltersSaveReportMenu: 'Saved Filters',
    onlyUserFiltersSaveReportMenu: 'Only user filters can be deleted',
    filtersSaveReportMenu: 'Filters: ',
    groupingsSaveReportMenu: 'Groupings: ',
    savedSuccessfullySaveReportMenu: 'saved successfully',
    errorWhenSavingFiltersSaveReportMenu: 'There was an error saving filters',
    reportNameSaveReportMenu: 'Report name',
    filterSaveReportMenu: 'Filter ',
};