export default {
    dialogTitleNewGrouping: 'Nuevo agrupamiento',
    dialogTitleEditProductGroup: 'Editar agrupamiento',
    closeProductGroup: 'Cerrar',
    saveProductGroup: 'Guardar',
    codeProductGroup: 'Código',
    descriptionProductGroup: 'Descripción',
    categoryProductGroup: 'Categoría',
    defaultPriceProductGroup: 'Precio estándar',
    associatedProductsProductGroup: 'Productos asociados',
    saveProductGroupError: 'Error al guardar el producto',
    saveItemsProductGroupError: 'Error al guardar el elemento',
};
