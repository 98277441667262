export default {
  clientRequester: 'Cliente Solicitante',
  accountPagSeguro: 'Cuenta PagSeguro',
  apiToken: 'Token de la API',
  enableInvoiceIssuanceTitle: '¿Desea la emisión de una factura?',
  enableenableInvoiceIssuanceTrue: 'Sí',
  enableInvoiceIssuanceFalse: 'No',
  activationCode: 'Código de Activación',
  noPaymentProcessorAlert: 'Ninguna cuenta PagSeguro disponible. Registre una para seleccionar.',
  registerNewAccount: 'Registrar Nueva Cuenta',
  deliveryAddress: 'Dirección de Entrega',
  address: 'Dirección',
  registerNewAddress: 'Registrar Nueva Dirección',
  termsAndConditions: 'Términos y Condiciones',
  termsAndConditionsText: 'Al realizar su pedido, acepta los Términos de Uso de AMLabs',
  finishOrder: 'Finalizar Pedido',
  fantasyName: 'Nombre Comercial',
  billingDocumentId: 'CNPJ de la Empresa',
  customerEmail: 'Correo Electrónico del Cliente',
  customerPhone: 'Teléfono del Cliente',
  productAddedToPurchase: 'Producto añadido para compra',
  midasCloseOrderAddressNumber: 'Nº',
  midasCloseOrderAddress: 'Calle',
  midasCloseOrderAddressComplement: 'Complemento',
  midasCloseOrderCep: 'Código Postal',
  midasCloseOrderCity: 'Ciudad',
  midasCloseOrderState: 'Estado',
  noAddressAvailableAlert: 'Ninguna dirección disponible. Registre una para seleccionar.',
  withdrawAtCompany: 'Retirar en la empresa: AV. Monte Castelo, 575 - Jardim Proença, Campinas - SP, CEP: 13026-241',
  productSoldByAmLabs: 'Producto vendido por AMLabs',
  midasCloseOrderQuantity: 'Cantidad',
  midasCloseOrderSave: 'Guardar',
  missingPaymentProcessorError: 'Seleccione una cuenta PagSeguro para finalizar el pedido',
  missingAddressInformationError: 'Faltan informaciones en la dirección de entrega. Rellénelas para finalizar el pedido',
  missingPhoneInformationError: 'Faltan informaciones en el teléfono del cliente. Rellénelas para finalizar el pedido',
  missingEmailInformationError: "Faltan informaciones en el correo electrónico del cliente. Complételas para finalizar el pedido",
  missingApiTokenInformationError: "Faltan informaciones en el token de API. Complételas para finalizar el pedido",
  missingActivationCodeInformationError: "Faltan informaciones en el código de activación. Complételas para finalizar el pedido",
  editPagseguroAccount: 'Editar Cuenta Pagseguro',

};
