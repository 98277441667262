import i18n from "@/locales/i18n"

export default {
  filterNames: [
    { value: 'addressState', name: i18n.t('addressStateFilterNamesTranslations') },
    { value: 'localId', name: i18n.t('localIdFilterNamesTranslations') },
    { value: 'posId', name: i18n.t('posIdFilterNamesTranslations')},
    { value: 'productId', name: i18n.t('productIdFilterNamesTranslations') },
    { value: 'dateInterval', name: i18n.t('dateIntervalFilterNamesTranslations')},
    { value: 'productCategoryId', name: i18n.t('productCategoryIdFilterNamesTranslations') },
    { value: 'inventoryId', name: i18n.t('inventoryIdFilterNamesTranslations') },
    { value: 'type', name: i18n.t('typeFilterNamesTranslations') },
    { value: 'customerId', name: i18n.t('customerIdFilterNamesTranslations') },
  ]
}