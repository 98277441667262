export default {
    dialogTitlePushNotificationDialog: 'Enviar notificación push',
    subtitlePushNotificationDialog: 'El mensaje se enviará a todos los usuarios registrados en esta tienda',
    messageLabelPushNotificationDialog: 'Mensaje',
    imageSubtitlePushNotificationDialog: '* Para una mejor visualización en la aplicación, adjunte imágenes cuadradas.',
    defaultNotificationLabel: 'Notificación predeterminada',
    defaultNotificationHint: 'La notificación aparecerá para el usuario cada vez que acceda a la aplicación.',
    expirationLabelPushNotificationDialog: 'Expira en',
    clearLabelPushNotificationDialog: 'Limpiar',
    applyAllPosLabelPushNotificationDialog: 'Aplicar a todos los PDVs',
    applySelectedPosLabel: 'Aplicar solo en los PDVs seleccionados',
    closeLabelPushNotificationDialog: 'Cerrar',
    sendLabelPushNotificationDialog: 'Enviar',
    messageSentPushNotificationDialog: 'Mensaje enviado a ',
    usersOfPushNotificationDialog: ' usuarios de los ',
    activePushNotificationDialog: ' activos',
    activePushNotificationDialogError: ' activos. ',
    notificationsNotSent: ' Algunas notificaciones no se enviaron debido a: ',
    feedbackErrorPushNotificationDialog: 'Error al enviar las notificaciones',
    requiredMessageOrImage: 'Es necesario incluir un mensaje o una imagen.',
    thereAreNoUsersWithPermission: 'No hay usuarios con permiso para recibir notificaciones.',
    getPointOfSaleItem: 'PDV',
    nonWhiteLabelPushCharge: 'Hay un cargo de 10 centavos por cada cliente al que envíe una notificación push. ' +
      'Este cargo no se aplica a las aplicaciones white label.',
    noAppForSendingPush: 'Este PDV no tiene habilitado TouchPay Mobile. Habilítelo en la página "Registro de PDVs" o seleccione otro PDV.',
};
