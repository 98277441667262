
import Vue from "vue";
import roles, {ADMINISTRATOR_ROLE,MANAGER_ROLE,SUPPORT_ROLE} from "../../../constants/Roles";
import UsersDialogHelp from "@/views/users/helpers/UsersDialogHelp.vue";
import TenantGroupsDropDown from "@/components/filters/TenantGroupsDropDown.vue";

const formInitialState = {
  email: null,
  password: null,
  confirmEmail: null,
  confirmPassword: null,
  tenantGroupId: null as number | null,
  roles: [] as string[]
};

const marketHierarchyRoles = ["Edição Preços", "Visualização Transações", "Visualização Planogramas"];
const priceManagerHierarchyRoles = ["Visualização Planogramas"];
const cashlessAdministratorHierarchyRoles = ["Visualização PrivateLabel"];
const supplierHierarchyRoles = ["TouchPay Abastecedor", "Inventário às cegas e abastecimento"];
const supplierMobileHierarchyRoles = ["Inventário às cegas e abastecimento"];

export default Vue.extend({
  components: {TenantGroupsDropDown, UsersDialogHelp},
  props: {
    value: {type: Boolean, default: false},
    isEdit: {type: Boolean, default: false},
    entry: {
      type: Object, default: () => {
      }
    },
  },
  data() {
    return {
      SUPPORT_ROLE,
      form: {...formInitialState},
      rolesPanel: false,
      valid: false,
      loading: false,
      showPasswordMask: true,
      loadDropDowns: false,
      rules: {
        emailConfirmation: (v: any, c: any) => v === c || this.$t('userDialogEmailConfirmationRule'),
        passwordConfirmation: (v: any, c: any) => v === c || this.$t('userDialogPasswordConfirmationRule')
      },
    };
  },
  watch: {
    dialog(value) {
      if (value) {
        this.loadDropDowns = true;
      }
      if (value && this.isEdit) {
        Object.assign(this.form, {...this.entry});
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    roles() {
      return roles;
    },
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
        if (!value) {
          this.resetForm();
        }
      }
    },
    isPriceManagerToggled() {
      return (this as any).form.roles.includes("Edição Preços");
    },
    isMarketToggled() {
      return (this as any).form.roles.includes("Vendas");
    },
    isCashlessAdministratorToggled() {
      return (this as any).form.roles.includes("Administrador PrivateLabel");
    },
    isSupplierToggled() {
      return (this as any).form.roles.includes("Logistica");
    },
    isSupplierMobileToggled() {
      return (this as any).form.roles.includes("TouchPay Abastecedor");
    },
    hasAdministratorRole() {
      return (this as any).form.roles.includes("Administrador");
    },
    hasManagerRole() {
      return (this as any).form.roles.includes("Gerente");
    },
    hasAnyNonBuildingManagerRole() {
      return (this as any).form.roles.length > 0 && !(this as any).form.roles.includes("Síndico");
    },
    hasBuildingManagerRole() {
      return (this as any).form.roles.includes("Síndico");
    },
    hasOrdersManagerRole() {
      return (this as any).form.roles.includes("Gerenciador de pedidos");
    },
    hasAccountingRole(){
      return (this as any).form.roles.includes("Contabilidade");
    },
    userIsAdministrator() {
      return (this as any).$auth.check(ADMINISTRATOR_ROLE);
    },
    userIsSupport() { // Used for unreleased features
      return (this as any).$auth.check(SUPPORT_ROLE);
    },
    userIsManager() {
      return (this as any).$auth.check(MANAGER_ROLE);
    },
  },
  methods: {
    saveChanges(): void {
      if (this.isEdit) {
        this.editUser();
      } else {
        this.createUser();
      }
    },
    createUser() {
      if ((this.$refs as any).form.validate()) {
        this.loading = true;
        const hasPassword = !!this.form.password;
        this.axios.post('api/Users', this.form)
          .then((response) => {
            this.$emit('success-creation', (this.$t('userDialogUserCreated') as string)
              + (hasPassword ? "" : this.$t('userDialogGeneratedPassword', {password: response.data.password}))
            )
          })
          .catch((error) => {
            this.handleError(this.$t('userDialogCreateUserError') as string, error);
          })
          .finally(() => this.loading = false);
      }
    },
    editUser() {
      if ((this.$refs as any).form.validate()) {
        this.loading = true;
        this.axios.put(`api/Users/${this.entry.id}`, this.form)
          .then(() => {
            this.$emit('success-edition', this.$t('userDialogUserEdited', {userEmail: this.entry.email}));
          })
          .catch((error) => {
            this.handleError(this.$t('userDialogEditUserError') as string, error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onAdministratorSwitchToggle() {
      if (this.form.roles.includes("Administrador")) {
        this.form.roles.splice(0, this.form.roles.length, "Administrador");
      }
    },
    onManagerSwitchToggle() {
      if (this.form.roles.includes("Gerente") && this.form.roles.includes("Contabilidade")) {
        this.form.roles.splice(0, this.form.roles.length, "Gerente", "Contabilidade");
      } else if (this.form.roles.includes("Gerente")) {
        this.form.roles.splice(0, this.form.roles.length, "Gerente");
      }
    },
    onMarketSwitchToggle() {
      if (this.form.roles.includes("Vendas")) {
        this.form.roles = this.form.roles.filter(role => !marketHierarchyRoles.includes(role));
      }
    },
    onPriceManagerSwitchToggle() {
      if (this.form.roles.includes("Edição Preços")) {
        this.form.roles = this.form.roles.filter(role => !priceManagerHierarchyRoles.includes(role));
      }
    },
    onCashlessAdministratorSwitchToggle() {
      if (this.form.roles.includes("Administrador PrivateLabel")) {
        this.form.roles = this.form.roles.filter(role => !cashlessAdministratorHierarchyRoles.includes(role));
      }
    },
    onSupplierSwitchToggle() {
      if (this.form.roles.includes("Logistica")) {
        this.form.roles = this.form.roles.filter(role => !supplierHierarchyRoles.includes(role));
      }
    },
    onSupplierMobileSwitchToggle() {
      if (this.form.roles.includes("TouchPay Abastecedor")) {
        this.form.roles = this.form.roles.filter(role => !supplierMobileHierarchyRoles.includes(role));
      }
    },
    resetField() {
      (this.$refs.field as any).reset();
    },
    resetForm(): void {
      this.rolesPanel = false;
      Object.assign(this.form, {...formInitialState});
      (this.$refs.form as any).resetValidation();
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
});
