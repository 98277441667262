export default{
    titleDetailDialog: 'INVOICE INFORMATION',
    infoDetailDialog: 'Details',
    numberInvoiceDetailDialog: 'Invoice Number',
    keyDetailDialog: 'Access Key (NFe)',
    dateEmmitedDetailDialog: 'Issue date',
    productsDetailDialog: 'Products',
    addItemDetailDialog: 'Add Product',
    removeInvoiceDetailDialog: 'Delete',
    receiveProductDetailDialog: 'Receive Products',
    validMessageDetailDialog: 'To proceed, fill in all the data',
    codeDetailDialog: 'Code',
    quantityInvoiceDetailDialog: 'Qty on the invoice',
    unitPurchaseDetailDialog: 'Purchase unit',
    unitTotalDetailDialog: 'Total unit',
    unitValueDetailDialog: 'Unit value',
    valueTotalDetailDialog: 'Total value',
    actionsDetailDialog: 'Actions',
    rulesNumbers: 'Only numbers are accepted',
    returnSuccess: 'Purchase entered successfully',
    returnError: 'Unable to enter purchase',
    unit: 'Unit',
    bleats: 'Burden',
    package: 'Package',
    unitUnknown: 'Unknown',
}
