
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {CustomerRequestDto} from '@/types/Cashless';

export default Vue.extend({
  props: {
    value: {type: Boolean, default: false},
    editedCustomer: {type: Object, default: () => {}},
    isEdit: {type: Boolean, default: false},
    editedCustomerId: {type: Number, default: null}
  },
  data() {
    return {
      error409Dialog: false,
      linkedCustomerName: '',
      valid: false,
      loading: false,
      request: {
        name: null,
        externalIdentifier: null,
        card: null
      } as CustomerRequestDto
    };
  },
  watch: {
    show(value) {
      if (value && this.isEdit) {
        this.request.name = this.editedCustomer.name;
        this.request.externalIdentifier = this.editedCustomer.externalIdentifier;
        this.request.card = this.editedCustomer.card;
      } else {
        this.resetForm();
      }
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
        if (!value) {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    saveChanges(): void {
      if (this.isEdit) {
        this.editCustomer();
      } else {
        this.saveNewCustomer();
      }
    },
    saveNewCustomer(): void {
      if ((this.$refs.form as any).validate()) {
        this.loading = true;
        this.axios.post('api/cashless/customers', this.request)
            .then(() => {
              this.$emit("updatedCustomer", "Consumidor criado com sucesso!");
              this.resetForm();
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                this.error409Dialog = true;
                this.linkedCustomerName = error.response.data.existingCustomerName;
              } else {
                (this.$refs.feedback as any).handleError("Não foi possível salvar.", error);
                this.resetForm();
              }
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
    editCustomer(): void {
      if ((this.$refs.form as any).validate()) {
        this.loading = true;
        this.axios.put('api/cashless/customers/' + this.editedCustomerId, this.request)
          .then(() => {
            this.$emit("updatedCustomer", "Consumidor atualizado com sucesso!");
            this.resetForm();
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              this.error409Dialog = true;
              this.linkedCustomerName = error.response.data.existingCustomerName;
            } else {
              (this.$refs.feedback as any).handleError("Não foi possível editar.", error);
              this.resetForm();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleOverwriteRefusal(): void {
      this.error409Dialog = false;
    },
    handleOverwriteConfirm() {
      this.loading = true;
      const params = { overwriteCard: true, ...this.request };
      const basePath = 'api/cashless/customers';
      const url = this.isEdit ? `${basePath}/${this.editedCustomerId}` : basePath;
      const method = this.isEdit ? 'put' : 'post';
      const successMessage = this.isEdit
        ? "Consumidor atualizado com sucesso!"
        : "Consumidor criado com sucesso!";

      this.axios[method](url, params)
        .then((response) => {
          this.request.card = response.data.card; 
          this.$emit("updatedCustomer", successMessage);
        })
        .catch((error) => {
          const errorMessage = this.isEdit ? "Não foi possível editar." : "Não foi possível salvar.";
          (this.$refs.feedback as any).handleError(errorMessage, error);
        })
        .finally(() => {
          this.loading = false;
          this.error409Dialog = false;
        });
  },

    resetForm(): void {
      (this.$refs.form as any).reset();
    },
  }

});
