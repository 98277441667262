export default {
    revenueMonthcardTitle: 'Growth',
    revenueMonthcardSubtitle: 'See the monthly variation between this year and the previous year',
    accumulatedRevenue: 'Accumulated Revenue',
    higherRevenue: 'Higher Revenue',
    MonthCard: 'Month of',
    YearCard: 'Year of',
    salesLegend: 'Sales ',
    revenueMonthHelp:`
    <p style="text-align: justify;">
        This indicator presents the current monthly revenue compared to the previous year, providing a clear and detailed visualization of performance over time. 
        It displays two columns of data: one for the current year and one for the previous year, making it easier to identify monthly variations, 
        trends, and seasonalities.
    </p>
    <p style="text-align: justify;">   
        Comparing the monthly values between years allows for an analysis of performance evolution, aiding in decision-making and planning.
    </p>`
}