export default {
  telemetry: {
    title: 'Telemetria',
    deviceStatus: {
      active: 'Ativo',
      inactive: 'Inativo',
      notConnected: 'Não Conectado',
      unknown: 'Problema Desconhecido',
    },
    lastUpdate: 'Última atualização',
    code: 'Código',
    version: 'Versão',
    serial: 'Serial',
    system: 'Sistema',
    outdatedVersion: 'Versão desatualizada',
    status: {
      offline: 'Offline',
      online: 'Online',
      alert: 'Alerta',
      noPower: 'Falta de energia',
      noPowerConnected: 'Sistema de pagamento não conectado à energia',
      outOfService: 'Fora de serviço',
      maybeOutOfService: 'Possível manutenção ou abastecimento da máquina',
      damagedMachine: 'Máquina com avaria',
      screenOff: 'Tela desligada',
      cardReaderFail: 'Verificar leitor de cartão',
      maybeCardReaderFail: 'Possível problema com o leitor de cartão',
      disconnected: 'Sem conexão com a internet',
      maybeDisconnected: 'Possível queda de conexão',
      deadBattery: 'Sem conexão por falta de energia',
      noLauncher: 'Aplicativo não é launcher padrão',
      unknownReason: 'Motivo desconhecido',
      last: 'Há {time}'
    },
    actions: {
      edit: 'Editar',
      replenish: 'Abastecer',
      restartTerminal: 'Reiniciar terminal',
      pushNotification: 'Notificação push',
      unlockDoor: 'Destravar uma trava',
      remoteCredit: 'Enviar crédito remoto',
    },
    tooltips: {
      help: 'Ir para vídeo de ajuda',
      noPlanogram: 'Sem planograma cadastrado',
      activePlanogram: 'Planograma ativo',
      pendingPlanogram: 'Planograma pendente de ativação',
      lastSale: 'Última venda há {time}',
      belowMinimumCritical: '{quantity} produtos abaixo do mínimo crítico',
    },
    card: {
      sales: {
        today: 'Hoje',
        sevenDays: '7 dias',
        thirtyDays: '30 dias',
        period: 'Período',
        quantity: 'Qtd.',
        price: 'Valor',
        health: 'Desempenho atual de vendas: {performance}',
        last: 'Há {time}',
        seeOf: 'Visualizar as transações de {time}',
        performance: {
          bad: 'Ruim',
          average: 'Na média',
          good: 'Ótimo',
        },
      },
      stock: {
        percentage: '{percentage}% estoque',
        products: '{count} produtos',
      },
    },
    help: {
      title: 'Telemetria dos pontos de venda',
      description: `<p>
      Aqui são exibidos todos os pontos de venda na forma de um cartão para cada PDV. 
      A barra colorida no topo do cartão indica a disponibilidade do PDV. 
      Quando está <span class="green--text">verde</span>, o PDV está funcionando corretamente.
      <span class="orange--text">Laranja</span> indica que provavelmente algo está errado.
      <span class="red--text">Vermelho</span> indica que o PDV está indisponível. 
    </p>
    <p>
      Ao lado do nome e local do PDV, é possível enviar <strong>créditos remotos</strong> 
      (<i class="mdi mdi-cash"></i>) e consultar os <strong>planogramas</strong> 
      (<i class="mdi mdi-view-comfy"></i>) do PDV.
      O botão de planograma é colorido de acordo com o status do mesmo.
    </p>
    <p>
      Mais abaixo no cartão temos uma tabela com o quantidade e o valor de produtos vendidos
      hoje, desde 7 dias e desde 30 dias. Ao lado dessa tabela, temos outras informações:
      <strong>nível de sinal</strong>, <strong>nível de estoque</strong>, quantidade
      de produtos abaixo do <strong>mínimo crítico</strong>, há quanto tempo foi a 
      <strong>última compra</strong> e <strong>desempenho atual de vendas</strong>, respectivamente.
      O desempenho atual é calculado com base no total de itens vendidos nas últimas horas, 
      em comparação com a média de vendas nos mesmos dias da semana e mesmo horário.
    </p>
    <p>
      Ao expandir o cartão clicando em <i class="mdi mdi-chevron-down"></i>, você vê um gráfico de 
      <strong>vendas por hora</strong> do PDV para o dia da semana atual. A curva azul (média) é a 
      média de vendas por hora no dia da semana atual, enquanto o segmento laranja são as vendas por hora
      do momento atual.
    </p>
    <p>
      Abaixo do gráfico de vendas por hora temos a opção de <strong>abastecer</strong> o ponto de venda.
    </p>`
    }
  },
}