export default {
  box: {
    code: 'Code',
    terminals: 'Terminals',
    activation: {
      insertText: 'Enter the activation code',
      steps: {
        title: 'New activation method: <strong>If the device requests an activation code, follow the steps below to complete the process.</strong>',
        description: `
        <ol>
          <li>Enter the code <strong>{code}</strong>.</li>
          <li>Click the "Activate" button.</li>
          <li>Wait for the device activation confirmation.</li>
        </ol>`,
      },
      response: {
        success: 'The system will be activated',
        error: 'Activation error',
      }
    },
    help: {
      title: 'New TouchPay',
      description: 'On the New TouchPay page, you must enter the code in the terminal application. This code associates the terminal with the corresponding environment.',
    }
  }
};