import {defineStore} from "pinia";
import {ref} from "vue";
import agent from "@/api/agent";
import {useAlertsStore} from "@/stores/alertsStore";
import {
  AcquisitionOrderApprovalTableDto,
  AcquisitionOrderApprovalTableFilterDto,
  OrderStatus,
  AcquisitionOrderUpdateStatusdDto,
} from "@/types/ApprovalAcquisitionsOrderTypes";
import {PaginatedQuery} from "@/types/common/PaginatedQuery";
import {PaginatedList} from "@/types/common/PaginatedList";
import i18n from "@/locales/i18n";


export const useApprovalAcquisitionsOrderStore = defineStore('approvalAcquisitionsOrderStore', () => {

  const alertsStore = useAlertsStore()

  const loadingView = ref(false)
  const loadingDialog = ref(false)
  const commentsDialog = ref(false);

  const acquisitionOrderApprovalTableFilterDto: AcquisitionOrderApprovalTableFilterDto = {tenantId: 0};
  const paginatedQuery: PaginatedQuery = new PaginatedQuery(1, 10, 'Status', true, null);
  const paginatedListResponse = ref<PaginatedList<AcquisitionOrderApprovalTableDto>>();
  const aquisitionOrderApproval = ref<AcquisitionOrderUpdateStatusdDto>( {id: 0, status: 0, comments: ''});

  async function getAllAcquisitionsOrders(tenantId: number) {
    acquisitionOrderApprovalTableFilterDto.tenantId = tenantId;
    loadingView.value = true;
    paginatedQuery.search = filterAlphanumeric(paginatedQuery.search);
    await agent.AcquisitionOrders.getAcquisitionOrders(paginatedQuery, acquisitionOrderApprovalTableFilterDto)
      .then((response) => {
        paginatedListResponse.value = response;

      })
      .catch(() => {
        const errorMessage = i18n.t('errorLoadingData');
        alertsStore.showErrorSnackbar(typeof errorMessage === 'string' ? errorMessage : errorMessage.toString());
      })
      .finally(() => {
        loadingView.value = false;
      });
  }
  function formatCNPJ(cnpj : string) {
    return cnpj.replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
  function filterAlphanumeric(input: string | null | undefined): string | null | undefined {

    if (input == null) {
      return input;
    }
    return input.replace(/[^a-zA-Z0-9]/g, '');
  }



  function openDialogueComments(id: number, orderStatus: number) {
    if(orderStatus === 0) {
      aquisitionOrderApproval.value = {
        id: id,
        status: null, //It can be null when the status is not changed, when the user only wants to add comments
        comments: paginatedListResponse.value?.items.find(x => x.id === id)?.comments || ''
      };
    }
    else if(orderStatus === 1) {
      aquisitionOrderApproval.value = {
        id: id,
        status: OrderStatus.Approved,
        comments: paginatedListResponse.value?.items.find(x => x.id === id)?.comments || ''
      };
    }
    else {
      aquisitionOrderApproval.value = {
        id: id,
        status: OrderStatus.Cancelled,
        comments: paginatedListResponse.value?.items.find(x => x.id === id)?.comments || ''
      };
    }
      commentsDialog.value = true;
  }

  async function HandleAquisitionOrderApproval() {
    loadingDialog.value = true;
    await agent.AcquisitionOrders.updateAcquisitionOrderStatus(aquisitionOrderApproval.value).then(() => {
      const message = i18n.t('sucessUpdatingAcquisitionOrder');
      alertsStore.showSuccessSnackbar(typeof message === 'string' ? message : message.toString());

    }).catch(() => {
      const message = i18n.t('errorUpdatingAcquisitionOrder');
      alertsStore.showErrorSnackbar(typeof message === 'string' ? message : message.toString());
    }).finally(() => {
      commentsDialog.value = false;
      loadingDialog.value = false;
      getAllAcquisitionsOrders(acquisitionOrderApprovalTableFilterDto.tenantId);
    });
  }

  function formatPhoneNumber(phone: string) {
    const localPhone = phone.replace(/\D/g, '');
    if (localPhone.length === 13) {
      return `+${localPhone.slice(0, 2)} (${localPhone.slice(2, 4)}) ${localPhone.slice(4, 9)}-${localPhone.slice(9)}`;
    }
    return phone;
  }

  return {
    paginatedQuery,
    getAllAcquisitionsOrders,
    paginatedListResponse,
    aquisitionOrderApproval,
    formatCNPJ,
    commentsDialog,
    formatPhoneNumber,
    loadingView,
    openDialogueComments,
    HandleAquisitionOrderApproval,
    loadingDialog
  }
})
