<template>
  <v-dialog v-model="dialog" v-if="item != null" width="480" @keydown.esc="dialog = false">
    <v-card>
      <v-form ref="form" v-model="valid" @submit.prevent="saveChange">
        <v-card-title>
          <v-container fluid>
            <v-layout row>
              <v-flex md6>
                <span class="title">{{ $t('dialogTitleInventoryChange') }}</span>
              </v-flex>
              <v-flex md6>
                <v-text-field                  
                  :label="$t('currentQuantityLabelInventoryChange')"
                  v-model="item.currentQuantity"
                  :rules="[rules.integer]"
                  required
                  maxlength="8"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('informationCancel') }}</v-btn>
          <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading">{{ $t('informationConfirmation') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    item: { type: Object, default: () => {} },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    dialog: function(enabled) {
      if (enabled) {
        this.valid = true;
      }
      else {
        this.loading = false;
      }
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      rules: {
        integer: v => /^\d+$/.test(v) || this.$t('integerRules')
      }
    }
  },
  methods: {
    saveChange() {
      const inventoryItemId = this.item.inventoryItemId;
      this.axios.put(`api/inventory/${inventoryItemId}/quantity/${this.item.currentQuantity}`)
        .then((response) => {
          this.$emit("update", response.data)  
          this.dialog = false;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('errorSavingInventoryChange'), error);
        })
    },
  }
}
</script>