<template>
  <v-dialog v-model="showDialog" max-width="800px">
    <v-card>
      <v-card-title class="headline">
        <v-container>
          <v-row cols="12" style="height: 50px;">
            {{ $t('addItemAddEntriesBulk') }}
            <v-spacer></v-spacer>
            <v-col class="mt-0 pt-0" cols="6">
              <v-text-field
                v-model="search"
                :label="$t('searchProductAddEntriesBulk')"
                clearable
                outlined
                dense
                filled
                rounded
                ></v-text-field>
            </v-col>
          </v-row>
        </v-container>  
      </v-card-title>
      <v-divider></v-divider>
      <v-container class="pb-0">
        <v-row align="center">
          <v-col cols="12">
            <v-sheet outlined rounded>

              <div class="ma-2 font-weight:bold">{{ $t('productsToAddEntriesBulk') }}</div>
              <v-chip-group
                class="px-1"
                column
                clearable
                multiple
                outlined
              >
                <v-chip
                  v-for="(product, i) in productsToAdd"
                  :key="i"
                  small
                  close
                  @click="productsToAdd.splice(i,1)"
                  @click:close="productsToAdd.splice(i,1)"
                >
                  {{ product.description }}
                </v-chip>

              </v-chip-group>

            </v-sheet>

            <v-data-table
              :items="products"
              :headers="headers"
              v-model="productsToAdd"
              show-select
              dense
              :search="search"
              :items-per-page="10"
              :footer-props="{ itemsPerPageOptions: [10, 30, 100] }"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions cols="12">

        <v-text-field
          v-if="posType !== 'MicroMarket'"
          cols="1"
          v-model="capacity"
          :label="$t('defaultCapacityAddEntriesBulk')"
          dense
          outlined
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          cols="2"
          v-model="quantityToSupply"
          :label="$t('defaultQuantityToSupplyAddEntriesBulk')"
          dense
          outlined
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="closeBulkAdd()" text>{{ $t('informationCancel') }}</v-btn>
        <v-btn color="info" @click="addItems()">{{ $t('informationAdd') }}</v-btn>      
      </v-card-actions>
    </v-card>
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    products: { type: Array },
    posType: { type: String }
  },
  data() {
    return {
      productsToAdd: [],
      headers: [
        { text: this.$t('productAddEntriesBulk'), align: "left", sortable: true, value: "description"}
      ],
      search: '',
      capacity: null,
      quantityToSupply: null,
      showDialog: false
    }
  },
  methods: {
    closeBulkAdd() {
      this.productsToAdd = [];
      this.showDialog = false;
    },
    addItems() {
      this.$emit("add-bulk-entries");
      this.showDialog = false;
    }
  }
};
</script>