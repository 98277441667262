export default {
    productGroupingsTitle: 'Agrupamentos de produtos',
    searchGroup: 'Procurar agrupamento',
    noItemsGroupings: 'Sem itens cadastrados',
    confirmRemoveGroup: 'Tem certeza que deseja remover o agrupamento',
    cancelGroupings: 'Não',
    removeGroupings: 'Remover',
    idGroupings: 'ID',
    codeGroupings: 'Código',
    descriptionGroupings: 'Descrição',
    categoryGroupings: 'Categoria',
    defaultPriceGroupings: 'Preço padrão',
    productsCountGroupings: 'Qtd. produtos',
    availabilityGroupings: 'Disponibilidade',
    publicGroupings: 'Público',
    imageGroupings: 'Imagem',
    actionsGroupings: 'Ações',
    itemRemovedGroupings: 'Item removido',
    errorRemovingItemGroupings: 'Erro ao remover item',
    itemSavedSuccessfullyGroupings: 'Agrupamento salvo com sucesso',
    itemUpdatedSuccessfullyGroupings: 'Agrupamento atualizado com sucesso',
    groupGroupings: 'Grupo: ',
  };
  