import i18n from "@/locales/i18n";
import { filters, primaryUnlockFilter } from "@/types/UnlockResult";

const primaryUnlockFilterTranslations: { [key in primaryUnlockFilter]: string } = {
  [primaryUnlockFilter.Success]: i18n.t('unlock.operations.result.success').toString(),
  [primaryUnlockFilter.Invalid]: i18n.t('unlock.operations.result.invalid').toString(),
  [primaryUnlockFilter.Error]: i18n.t('unlock.operations.result.error').toString(),
};

export function getPrimaryUnlockFilterTranslation(key: primaryUnlockFilter): string {
  return primaryUnlockFilterTranslations[key] || 'Unknown Filter';
}

const filterTranslations: { [key: string]: string } = {};

Object.keys(filters).forEach(filterKey => {
  filters[filterKey as keyof typeof filters].forEach((filterValue: string) => {
    filterTranslations[filterValue] = i18n.t(`unlock.operations.result.details.${filterValue[0].toLowerCase() + filterValue.slice(1)}`).toString();
  });
});

export function getFilterTranslation(filter: string): string {
  return filterTranslations[filter] || 'Unknown Filter';
}