<template>
  <v-dialog persistent v-model="show" :max-width="720" @keydown.esc="show = false">
    <v-card>
      <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="save">
        <v-card-title>
          {{ isActive ? $t('appRegistration') : $t('registerApp') }}
        </v-card-title>
        <v-card-text id="container">
          <template v-if="isActive">
            <qrcode-vue :value="universalPointOfSale.activationCode" render-as="svg" :size="240" id="qrcode-svg" />
            <qrcode-vue v-show="false" :value="universalPointOfSale.activationCode" :size="240" id="qrcode-canvas" />
            <v-container>
              <v-row align="center" justify="center" class="pb-4">
                  <v-btn class="mr-2" color="secondary" @click="downloadPng">
                    <v-icon left>download</v-icon>PNG
                  </v-btn>
                  <v-btn class="ml-2" color="secondary" @click="downloadSvg">
                    <v-icon left>download</v-icon>SVG
                  </v-btn>
              </v-row>
            </v-container>
            <v-alert class="text-left" dense outlined text type="info" border="left" v-if="universalPointOfSale.userDocumentVerificationEnabled">
              {{ userDocumentVerificationText }}
            </v-alert>
            <v-alert class="text-left" dense outlined text type="info" border="left" v-if="userVerificationEnabled">
              {{ userVerificationText }}
            </v-alert>
          </template>
          <template v-else>
            <v-alert class="text-left" dense outlined text type="info" border="left">
              {{ $t('additionalChargeInfo') }}
            </v-alert>
            <v-alert class="text-left" dense outlined text type="info" border="left" v-if="universalPointOfSale.userDocumentVerificationEnabled">
              {{ userDocumentVerificationText }}
            </v-alert>
            <v-alert class="text-left" dense outlined text type="info" border="left" v-if="userVerificationEnabled">
              {{ userVerificationText }}
            </v-alert>
          </template>
          <v-text-field
              v-model="universalPointOfSale.brand"
              :label="$t('storeBrand')"
              prepend-icon="business"
              :hint="$t('enterBrand')"
              class="pr-2"
              required
              :rules="[(v) => !!v || $t('requiredRules')]"
            ></v-text-field>
            <v-text-field
              v-model="universalPointOfSale.local"
              :label="$t('locationSubtitleMobileActivation')"
              prepend-icon="location_on"
              :hint="$t('enterLocation')"
              class="pr-2"
              required
              :rules="[(v) => !!v || $t('requiredRules')]"
            ></v-text-field>
            <v-text-field
              v-model="maskedPhoneNumber"
              :label="$t('sacWhatsAppOptional')"
              prepend-icon="mdi-whatsapp"
              :hint="$t('sacWhatsAppNumber')"
              class="pr-2"
              v-mask="'+## ## #####-####'"
              :rules="[(v) => !v || v.replace(/\D/g, '').length === 13 || $t('countryCodeRules')]"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-switch :label="$t('displayProductPricesMobileActivation')" v-model="universalPointOfSale.priceDisplayEnabled" light></v-switch>
                <v-switch :label="$t('verifyCpf')" v-model="universalPointOfSale.userDocumentVerificationEnabled" light class="mt-1"></v-switch>
              </v-col>
              <v-col>
                <v-switch :label="$t('verifyAge')" v-model="userVerificationEnabled" light></v-switch>
                <v-switch :label="$t('verifyValidEmail')" v-model="universalPointOfSale.userEmailVerificationEnabled" light></v-switch>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-switch :label="$t('verifyPhoneNumber')"
                                v-model="universalPointOfSale.userPhoneVerificationEnabled"
                                :disabled="!isWhiteLabel"
                                light
                      ></v-switch>
                    </div>
                  </template>
                  <span>
                    {{ !isWhiteLabel ? $t('phoneNumberOption') : "" }}
                    {{ $t('confirmPhoneMobileActivation') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-select
              :items="appCheckoutType"
              v-model="universalPointOfSale.appCheckoutType"
              :label="$t('addToCartOption')"
              required
            ></v-select>
            <v-select
              :items="tokenList"
              v-model="universalPointOfSale.tokenId"
              item-text="alias"
              item-value="id"
              :label="$t('paymentTokenMobileActivation')"
              :rules="[(v) => universalPointOfSale.appCheckoutType == 'Disabled' || !!v || $t('requiredRules')]"
              >
            </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" :disabled="!isActive" @click.native="disablePos">{{ $t('disableMobileActivation') }}</v-btn>
          <v-btn text color="primary" @click="show = false">{{ $t('closeMobileActivation') }}</v-btn>
          <v-btn type="submit" :loading="loading.saving" text color="primary">
            {{ isActive ? $t('updateMobileActivation') : $t('registerMobileActivation') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on" :is-active="isActive" :loading="loading.retrieving"></slot>
    </template>

    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
  import QrcodeVue from 'qrcode.vue';
  import { mask } from 'vue-the-mask';
  import { MobileActivation } from "@/api/agent";

  export default {
    props: {
      posId: { type: Number, default: null },
    },
    computed: {
      userVerificationEnabled: {
        get() {
          return !this.universalPointOfSale.userVerificationDisabled;
        },
        set(value) {
          this.universalPointOfSale.userVerificationDisabled = !value;
        }
      }
    },
    watch: {
      maskedPhoneNumber(value) {
        const phone = value?.replace(/\D/g, '');
        if (phone !== this.universalPointOfSale.whatsAppNumber)
          this.universalPointOfSale.whatsAppNumber = phone?.length > 0 ? phone : null;
      },
      'universalPointOfSale.whatsAppNumber': function(value) {
        if (value && this.maskedPhoneNumber?.replace(/\D/g, '') !== value)
          this.maskedPhoneNumber = value;
      }
    },
    data() {
      return {
        isActive: false,
        show: false,
        valid: true,
        appCheckoutType: [
          { value: 'Disabled', text: this.$t('appCheckoutTypeDisabled') },
          { value: 'Barcode', text: this.$t('appCheckoutTypeBarcode') },
          { value: 'BarcodeAndManual', text: this.$t('appCheckoutTypeBarcodeAndManual') }
        ],
        tokenList: [],
        loading: {
          retrieving: false,
          saving: false
        },
        maskedPhoneNumber: null,
        isWhiteLabel: false,
        universalPointOfSale: {
          pointOfSaleId: this.posId,
          brand: null,
          local: null,
          whatsAppNumber: null,
          priceDisplayEnabled: true,
          appCheckoutType: 'BarcodeAndManual',
          activationCode: null,
          tokenId: null,
          userDocumentVerificationEnabled: false,
          userVerificationDisabled: true
        },
        userDocumentVerificationText: this.$t('userDocumentVerificationText'),
        userVerificationText: this.$t('userVerificationText')
      }
    },
    mounted() {
      this.getUniversalPointOfSale();
      this.axios.get(`api/mobileactivation/tokens`)
        .then(response => {
          this.tokenList = response.data;
        }).catch(error => {
          this.$refs.feedback.handleError(this.$t('errorFindingToken'), error);
      });
      MobileActivation.getSlug().then(response => {
        this.isWhiteLabel = response.isWhiteLabel;
      })
    },
    methods: {
      getUniversalPointOfSale() {
        this.loading.retrieving = true;
        this.axios.get(`api/mobileactivation/${this.posId}`)
          .then(response => {
            this.universalPointOfSale = response.data;
            this.isActive = true;
            this.loading.retrieving = false;
          }).catch(error => {
            if (error?.response?.status === 404) {
              this.isActive = false;
              this.loading.retrieving = false;
            }
          })
      },
      save() {
        if (this.$refs.form.validate()) {
          this.loading.saving = true;
          const request = this.universalPointOfSale;

          if (this.isActive) {
            this.axios.put(`api/mobileactivation/${this.posId}`, request)
              .then(response => {
                this.$refs.feedback.handleSuccess(this.$t('registrationUpdated'));
                this.universalPointOfSale = response.data;
                setTimeout(() => this.show = false, 750);
              }).catch(error => {
                this.$refs.feedback.handleError(this.$t('unableToUpdateRegistration'), error);
              }).then(() => {
                this.loading.saving = false;
              });
          } else {
            this.axios.post('api/mobileactivation/', request)
              .then(response => {
                this.$refs.feedback.handleSuccess(this.$t('appReleased'));
                this.universalPointOfSale = response.data;
                this.isActive = true;
              }).catch(error => {
                this.$refs.feedback.handleError(this.$t('unableToActivateApp'), error);
              }).then(() => {
                this.loading.saving = false;
              });
          }
        }
      },
      disablePos() {
        this.loading.saving = true;
        this.axios.delete(`api/mobileactivation/${this.posId}`)
          .then(() => {
            this.$refs.feedback.handleSuccess(this.$t('appDisabled'));
            this.isActive = false;
          })
          .catch((error) => this.$refs.feedback.handleError(this.$t('unableToDisableApp'), error))
          .then(() => this.loading.saving = false);
      },
      downloadPng() {
        const qrcode = document.getElementsByTagName("canvas")[0].toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');

        const link = document.createElement('a');

        link.href = qrcode;
        link.target = "_blank";
        link.download = "qrcode.png";
        link.click();

        URL.revokeObjectURL(link.href);
      },
      downloadSvg() {
        const qrcode = document.getElementsByTagName("svg")[0].outerHTML;

        const blob = new Blob([qrcode], {
          type: "image/svg+xml"
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.target = "_blank";
        link.download = "qrcode.svg";
        link.click();

        URL.revokeObjectURL(link.href);
      }
    },
    components: {
      QrcodeVue
    },
    directives: { mask }
  }
</script>

<style scoped>
  #container {
    text-align: center;
  }
</style>
