
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  props: {
    value: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
  },
  computed: {
    date: {
      get(): string | null {
        return this.value;
      },
      set(value: string): void {
        this.$emit("input", value);
      },
    },
    formattedDate(): string | null {
      return this.date != null
        ? moment(this.date).format("DD/MM/YYYY")
        : null;
    },
  },
  methods: {
    getAllowedDates(date: string): boolean {
      if (this.minDate && this.maxDate) {
        return date >= this.minDate && date <= this.maxDate;
      } else if (this.minDate) {
        return this.$moment(date).isSameOrAfter(this.minDate);
      } else if (this.maxDate) {
        return date <= this.maxDate;
      }
      return true;
    },
  },
});
