<template>
  <help>
    <template #title>{{ $t('supplyHelpTitle') }}</template>
    <div>
      <span v-html="$t('textSupplyHelp')"></span>
    </div>
    <help-video  help-code="Supply"/>
  </help>
</template>
<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>