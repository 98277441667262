<template>
  <v-layout row wrap>
    <v-flex xs12>
      <pos-selector
        class="mt-3"
        v-model="posId" bold
        :dense="true"
      ></pos-selector>
    </v-flex>
  </v-layout>
</template>

<script>
import PointOfSaleSelector from '@/components/PointOfSaleSelector'
export default {
  watch: {
    '$route'(to, from) {
      if (to != from) {
        this.posId = parseInt(to.query.pdv); 
      }
    },
    posId(newId) {
      let newQuery = Object.assign({}, this.$route.query);
      if (newId != null && !isNaN(newId)) {
        newQuery.pdv = newId;
      }
      else {
        delete newQuery.pdv;
      }
      this.$router.push({ query: newQuery });
    }
  },
  data() {
    return {
      posId: parseInt(this.$route.query.pdv)
    }
  },
  components: {
    'pos-selector': PointOfSaleSelector
  }
}
</script>