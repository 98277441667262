export default {
    invalidateTitleInvalidationRequest: 'Anular numeración',
    cnpjLabelInvalidationRequest: 'CNPJ de la empresa',
    cnpjHintInvalidationRequest: 'Ingrese el CNPJ de la empresa',
    noCompaniesInvalidationRequest: 'No hay empresas registradas.',
    seriesLabelInvalidationRequest: 'Serie de la factura',
    initialNumberLabel: 'Número inicial',
    finalNumberLabel: 'Número final',
    reasonLabelInvalidationRequest: 'Motivo (opcional)',
    closeInvalidationRequest: 'Cerrar',
    invalidateButton: 'Anular',
    requestSentInvalidationRequest: 'Solicitud enviada a la Sefaz. Siga el estado en el menú Consultar anulaciones.',
    requestFailedInvalidationRequest: 'No se pudo enviar la solicitud de anulación'
}
