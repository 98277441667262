export default {
  tenantGroupDialogNewGroup: 'New Group',
  tenantGroupDialogCardTitle: 'Register New Group',
  tenantGroupDialogGroupIdLabel: 'Group ID',
  tenantGroupDialogGroupNameLabel: 'Group Name',
  tenantGroupDialogCancel: 'Cancel',
  tenantGroupDialogCreate: 'Create',
  tenantGroupDialogHandleErrorMessage: 'Could not',
  tenantGroupDialogEmitSuccessMessage: 'Group id {groupId} created successfully!'
}
