export default {
    purchasesFiltersTitle: 'Filtros e ações',
    purchasesFiltersStartDate: 'Data inicial',
    purchasesFiltersEndDate: 'Data final',
    purchasesFiltersBtnClear: 'Limpar',
    purchasesFiltersAddNote: 'ADICIONAR NOTA',
    purchasesFiltersXml: 'XML',
    purchasesFiltersManual: 'Manual',
    purchasesFiltersRefresh: 'Atualizar',
    purchasesFiltersRulesDate: 'Sem Data',
    purchasesFiltersRulesRequired: 'Este campo é obrigatório.',
    labelFiltersStock: 'Estoque',
  };
  