export default {
  priceTable: {
    action: {
      new: "Nueva tabla de precios",
      create: {
        action: "Crear tabla de precios",
        option: {
          empty: "Crear tabla vacía",
          planogram: "Copiar de planograma"
        }
      },
      edit: "Editar productos",
      remove: {
        action: "Eliminar",
        description: "la tabla de precios"
      },
      divergence: "Ver divergencias",
      updateMarkupByCategory: "Definir markup por categoría",
      importfromCentralStock: "Importar del stock central",
      updateMarkUp: "Actualizar markup",
      return: "Volver a las tablas de precios"
    },
    data: {
      table: "Tabla",
      name: "Nombre de la tabla de precios",
      cost: "Costo",
      markUp: "Markup",
      price: "Precio",
      category: "Categoría"
    },
    response: {
      fetch: {
        error: "No fue posible cargar las tablas de precios, intente nuevamente"
      },
      create: {
        success: "Éxito al crear la tabla de precios {priceTableName}",
        successPlanogram: "Éxito al crear la tabla de precios {priceTableName} del planograma {planogramId}",
        error: "No fue posible crear la tabla de precios"
      },
      edit: {
        success: "Éxito al editar la tabla de precios de {priceTableNamePrevious} a {priceTableName}",
        error: "No fue posible editar la tabla de precios"
      },
      remove: {
        success: "Éxito al eliminar la tabla de precios {priceTableName}",
        error: "No fue posible eliminar la tabla de precios"
      },
      updateMarkupByCategory: {
        success: "Éxito al actualizar markup y precio por categoría",
        error: "No fue posible actualizar el markup por categoría"
      },
      changePriceTableType: {
        markUpOnly: "en la adición de los campos de costo y markup para cada ítem de la tabla de precios",
        priceOnly: "en la pérdida irreversible de todos los valores agregados en costo y markup"
      },
      importfromCentralStock: {
        success: "Éxito al importar el costo del stock central",
        error: "No fue posible importar el costo del stock central, intente nuevamente"
      }
    },
    help: {
      divergence: {
        title: "Divergencias en la aplicación de la tabla",
        type: {
          priceTable: {
            description: "Tabla de Precios",
            absenceReason: "{productId} - {productDescription} está en el PDV {pointOfSaleId} pero está ausente de la tabla aplicada."
          },
          planogram: {
            description: "Planograma",
            absenceReason: "{productId} - {productDescription} está en la tabla aplicada pero está ausente del PDV {pointOfSaleId}."
          },
        },
        description: `
          <p align="justify">
            Este cuadro de diálogo muestra las divergencias que ocurrieron al aplicar la tabla de precios a los Puntos de Venta seleccionados.
          </p>
          <p align="justify">
            Hay dos tipos de divergencias posibles: Divergencia de <strong>Tabla de Precios</strong> y divergencia de <strong>Planograma</strong>.
          </p>
          <ul>
            <li align="justify">
              <strong>Tabla de Precios:</strong> La divergencia de Tabla de Precios ocurre cuando un producto está presente 
              <strong> en el PDV</strong> a ser aplicado, pero está <strong>ausente</strong> de la <strong>tabla de precios aplicada.</strong>
            </li>
            <li align="justify">
              <strong>Planograma:</strong> La divergencia de Planograma ocurre cuando un producto está presente 
              <strong>en la Tabla de Precios</strong> pero está <strong>ausente</strong> del planograma del <strong>PDV aplicado</strong>.
            </li>
          </ul>
        `,
      },
      about: {
        title: "Tablas de Precios",
        description: `
          <p>
            Esta página lista las Tablas de Precios que se han creado; pudiendo crear, editar y activarlas en varios puntos de ventas.
          </p>
          <p>
            <strong>Crear una tabla de Precios:</strong>
          </p>
          <ul>
            <li>
              Para crear una tabla de precios, simplemente haga clic en <strong>"+ NUEVA TABLA DE PRECIOS"</strong>, añada el nombre de su tabla y 
              seleccione entre dos formas de creación.
              <ul>
                <li>
                  <strong>Crear tabla vacía -</strong> Esta opción solo crea la Tabla de Precios sin ninguna información;
                </li>
                <li>
                  <strong>Copiar de planograma -</strong> Al seleccionar esta opción, se abren las pestañas para seleccionar cuál punto de venta desea 
                  copiar el planograma y cuál es su versión;
                </li>
              </ul>
            </li>
            <li>
              Para agregar productos a su tabla de precios, haga clic en <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i> y luego haga clic en 
              <strong>"+ AGREGAR PRECIO"</strong> y complete los campos de Producto y Precio y luego haga clic en <strong>agregar</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Edición de la tabla de precios:</strong>
          </p>
          <ul>
            <li>
              Para editar el nombre de la tabla de precios, simplemente haga clic en <i style="font-size: 16px;" class="mdi mdi-pencil info--text"></i> y cambie el nombre, 
              haciendo clic en <strong>guardar</strong> para finalizar.
            </li>
            <li>
              Para editar el precio de algún producto, simplemente haga clic en <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i> y 
              cambie el valor en el campo de precio, al hacer clic fuera o navegar usando TAB (en el teclado) el valor se guarda 
              <strong>automáticamente</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Activación de la tabla de precios:</strong>
          </p>
          <ul>
            <li>
              Para activar la tabla de precios, simplemente haga clic en <i style="font-size: 16px;" class="mdi mdi-domain info--text"></i>, seleccione los 
              puntos de venta a los que desea aplicar la tabla de precios y luego haga clic en <strong>CREAR BORRADORES</strong>.
            </li>
            <li>
              Después de hacer clic en Continuar, se creará un planograma borrador para los puntos de venta seleccionados, recordando que es 
              <strong>necesario</strong> tener el producto en el planograma previamente para que se apliquen los precios.
            </li>
          </ul>
          <p></p>
        `,
      },
      centralStock: {
        title: "Importar del stock central",
        description: `
          <p>
            En esta funcionalidad, puede seleccionar cuál stock central se usará para la actualización de la tabla de precios.
          </p>
          <p>
            <strong>Selección de Stock Central:</strong>
          </p>
          <p>
            Selección de cuál stock central se importarán los costos.
          </p>
          <p>
            <strong>Cálculos de Markup y Precio:</strong>
          </p>
          <p>
            La tabla de precios se actualizará siguiendo los siguientes criterios.
          </p>
          <ul>
            <li>
              Para precios sin costo o markup. El costo se importará del stock y el markup se calculará.
            </li>
            <li>
              Para precios ya completos*, por defecto se calculará el nuevo precio con base en el costo importado.
            </li>
            <li>
              Para precios ya completos* y con la opción de "Actualizar Markup" activada, se calculará el nuevo Markup, sin cambiar el precio.
            </li>
          </ul>
          <br/>
          <p>
            <i>*Campos de Costo y Markup ya llenos</i>
          </p>
        `,
      },
      markUpCategory: {
        title: "Markup por categoría",
        description: "En esta función, puede actualizar el markup de los productos de la tabla por categoría.",
        option: {
          category: {
            action: "Selección de categoría:",
            description: "Selección de qué categoría desea actualizar el markup."
          },
          markUp: {
            action: "Markup:",
            description: "Nuevo valor del markup para actualizar los productos de la categoría seleccionada."
          },
          condition: "*Solo se actualizarán los productos que están en la tabla y que coinciden con la categoría seleccionada."
        }
      },
      table: {
        action: {
          toMarkUp: `
            Cambiar la tabla solo de <strong>Precio</strong> a <strong>Markup</strong> resultará en la adición de los campos Costo y Markup para cada 
            elemento de la Tabla de Precios.
            <br />
            ¿Desea continuar?
          `,
          toPrice: `
            Cambiar la tabla de <strong>Markup</strong> a solo <strong>Precio</strong> resultará en la pérdida irreversible de todos los valores 
            añadidos en Costo y Markup.
            <br />
            ¿Desea continuar?
          `,
        }
      }
    },
    item: {
      action: {
        create: "Agregar precio",
        importCost: "Importar costo",
        remove: "Eliminar producto",
      },
      response: {
        create: {
          success: "Éxito al crear precio",
          error: "No se pudo crear precio"
        },
        fetch: {
          error: "No se pudieron cargar precios, intente de nuevo"
        },
        edit: {
          success: "Éxito al editar el precio de {productDescription}",
          error: "No se pudo editar el precio de {productDescription}"
        },
        remove: {
          success: "Éxito al eliminar el precio de {productDescription}.",
          error: "No se pudo eliminar el precio de {productDescription}"
        },
        priceTableTypeChange: {
          success: "Éxito al cambiar el tipo de tabla de precios",
          error: "No se pudo cambiar la tabla de precios"
        }
      },
      help: {
        about: {
          title: "Productos de la tabla de precios",
          description: `
            <p>
              Esta página lista los precios de la tabla seleccionada; pudiendo crear, editar y eliminar sus respectivos valores.
            </p>
            <p> <strong>Persistencia de datos:</strong> </p>
            <ul>
              <li>
                La tabla de precios está diseñada para facilitar la persistencia de datos. Cuando uno de los campos 
                (Costo, Margen o Precio) pierde el foco (selecciona otro campo), se guarda en la base de datos. 
                Con esto, la navegación y edición se hace con menos procedimientos.
                Una buena práctica es utilizar las teclas <strong>TAB</strong> y <strong>SHIFT + TAB</strong> en su teclado.
              </li>
            </ul>
            <br/>
            <p> <strong>Cálculos de Margen y Precio:</strong> </p>
            <p>
              La tabla de precios tiene cálculos que funcionan de la siguiente manera:
            </p>
            <ul>
              <li> El <strong>Costo</strong> no tiene cálculos y no será afectado por el Margen o el Precio. </li>
              <li> El <strong>Margen</strong> se calcula mediante la edición del Costo o el Precio. </li>
              <li> El <strong>Precio</strong> se calcula mediante la edición del Margen. </li>
            </ul>
            <br/>
            <p>
              Para que los cálculos funcionen, los tres campos (Costo, Margen y Precio) deben estar completos. Sin embargo, si
              solo tienes el Precio, basta agregar el Costo para que el Margen se calcule automáticamente.
            </p>
            <p>
              La tabla de precios está diseñada para ofrecer la máxima flexibilidad, el único campo obligatorio es el precio.
            </p>
            <p>
              <strong>Crear un Precio:</strong>
            </p>
            <ul>
              <li>
                Para crear un nuevo precio, simplemente haga clic en <strong>"+ AÑADIR PRECIO"</strong>. En la parte superior de la tabla
                aparecerá una línea para seleccionar el producto al que desea agregar el Precio, Margen y Costo.
              </li>
            </ul>
            <br/>
          `
        }
      }
    }
  }
}