export default {
    purchaseDetails: 'Compra',
    purchaseDetailsIdInERP: 'Id no ERP: ',
    purchaseDetailsNoteNumber: 'Número da nota: ',
    purchaseDetailsReceiptDate: 'Recebimento: ',
    purchaseDetailsInventory: 'Estoque: ',
    purchaseDetailsTotalNoteValue: 'Valor total da nota: ',
    purchaseDetailsTotalPurchaseCost: 'Custo total da compra: ',
    purchaseDetailsQuantity: 'Quantidade: ',
    purchaseDetailsUnits: ' unidades',
    purchaseDetailsSearchProduct: 'Procurar produto',
    purchaseDetailsUndo: 'Desfazer',
    purchaseDetailsClose: 'Fechar',
    purchaseDetailsHeadersID: 'ID',
    purchaseDetailsHeadersProduct: 'Produto',
    purchaseDetailsHeadersCategory: 'Categoria',
    purchaseDetailsHeadersCode: 'Código',
    purchaseDetailsHeadersQuantity: 'Qtd.',
    purchaseDetailsHeadersNoteValue: 'Valor total da nota ',
    purchaseDetailsHeadersProductTotalCost: 'Custo total do produto ',
    purchaseDetailsErrorFetching: 'Erro ao pesquisar compras',
    purchaseDetailsErrorUndo: 'Erro ao desfazer compra',
    purchaseDetailsPurchaseCanceled: 'Compra desfeita',
  };
  