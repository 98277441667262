<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template>
        <div><v-icon class="pr-1">filter_list</v-icon>Filtros e ações</div>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" @submit.prevent="emitModification">
          <v-layout row wrap>

        <!-- POS -->           
            <v-flex sm12 md2 class="search-field mr-4 ml-1">
              <v-flex sm12 class="search-field">
                <point-of-sales-multiple-selector
                  v-model="form.posIds"
                  :counter="3"
                  prepend-icon="mdi-shape"
                  label="PDV">
                </point-of-sales-multiple-selector>  
              </v-flex>
            </v-flex>

        <!-- Calendar -->
        <v-flex sm12 md3 class="search-field mr-4">          
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
          max-width="300" 
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDates"
              label="Calendário"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              lazy-validation
              :rules="[rules.dateRangeRequired]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            no-title
            @change="handleDateChange"
            :allowed-dates="allowedDates"
          >
          <v-row>
              <v-col cols="11" sm="11" md="11" style="height: 70px;">
                <v-text-field 
                  label="Data inicial"
                  prepend-icon="mdi-calendar"                  
                  append-icon=""
                  max-width="300" 
                  class="ps-4"
                  readonly
                  v-model="formattedDates[0]"
                  @input="updateDateRangeText"
                  :rules="[rules.required]"
                  lazy-validation
                  ></v-text-field> 
              </v-col>

              <v-col cols="11" sm="11" md="11" style="height: 70px;">
                <v-text-field
                  label="Data final"
                  prepend-icon="mdi-calendar"
                  append-icon=""
                  max-width="300" 
                  class="ps-4"
                  readonly
                  v-model="formattedDates[1]"
                  @input="updateDateRangeText"
                  :rules="[rules.required]"
                  lazy-validation
                ></v-text-field>  
              </v-col>  
              <v-col cols="12" sm="12" class="d-flex justify-end mt-3">
                <v-btn text color="primary" @click="clearDates">Limpar</v-btn>
                <v-btn text color="primary" @click="saveDate" :disabled="!valid">Ok</v-btn>
              </v-col> 
            </v-row>            
          </v-date-picker>
        </v-menu>
        </v-flex>             

          <!-- Category -->
            <v-flex sm12 md3 class="search-field mr-4">
              <v-flex sm12 class="search-field">
                <template>
                  <v-autocomplete 
                    prepend-icon="mdi-shape"
                    append-icon=""
                    :items="itemsCategory"
                    label="Categoria"
                    single-line
                    v-model="form.categories"            
                  >  
                  </v-autocomplete>         
                </template>
              </v-flex>
            </v-flex> 
            
          <!-- Assessment -->
          <v-flex sm12 md3 class="search-field mr-4">
            <v-flex sm12 class="search-field">
              <template>
                <v-autocomplete
                  prepend-icon="mdi-star-outline"
                  color="warning"
                  append-icon=""
                  :items="starReviews"
                  label="Avaliação"
                  single-line
                  v-model="form.rating"
                >
                <template v-slot:item="{ item }">
                      <v-row align="center">
                        <v-col>
                          <span style="font-size: 16px;">{{ item.text }}</span>
                          <v-icon color="warning" class="pl-2 pb-2">mdi-star</v-icon>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-row align="center">
                        <v-col class="pr-1">
                          <span>{{ item.text }}</span>
                        </v-col>
                      </v-row>
                    </template>
                </v-autocomplete>
              </template>
            </v-flex>
          </v-flex>            
          </v-layout>

          <!-- Clear button -->
          <v-row justify="end" class="pt-4">
            <v-btn 
              class="mr-2 mb-2" 
              text 
              @click="cleanFilters"
              color="error" 
            >
            <v-icon left>clear</v-icon>Limpar
            </v-btn>

            <v-btn
              class="mr-2 mb-2"
              @click="getExcelReport"
              color="info"
              :loading="excelLoading"
            >
              <v-icon left>mdi-file-excel</v-icon>Excel
            </v-btn>

          <!-- Update button -->
            <v-btn
              color="success"
              type="submit"
              :disabled="!valid"
              >
              <v-icon left>refresh</v-icon>Atualizar
            </v-btn>
          </v-row>

        </v-form>
        <feedback ref="feedback"></feedback>
        
        <download-dialog
          v-model="downloadDialog.show"
          :fileName="downloadDialog.fileName"
          @update="(value) => downloadDialog.show = value"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PointOfSalesMultipleSelector from '@/components/filters/PointOfSalesMultipleSelector'
import moment from 'moment'
import { UsersFeedbacks } from '@/api/agent'
import DownloadDialog from '@/components/common/DownloadDialog.vue'

export default {
  data(){
    return {
      rules:{
        required: (v) => !!v || 'Este campo é obrigatório.',
        dateRangeRequired: () => {
          return !!this.form.minDate && !!this.form.maxDate || 'Este campo é obrigatório.';
      }
      },
      valid: false,
      expansionIndex: 0,
      form: {
        posIds: [],
        minDate: null,
        maxDate: null,
        categories: [],
        rating: null,
      },
      categoryReviewsPos: [
        {text: 'Clima', value: "Clima"},
        {text: 'Organização', value: "Organização"},
        {text: 'Produtos', value: "Produtos"},
        {text: 'Maquininha', value: "Maquininha"},        
        {text: 'Pagamento', value: "Pagamento"},   
        {text: 'Preços', value: "Preços"},        
        {text: 'Outros', value: "Outros"},       
       
      ],
      categoryReviewsApp: [
        {text: 'Aplicativo', value: "Aplicativo"},                
        {text: 'Organização', value: "Organização"}, 
        {text: 'Produtos', value: "Produtos"},
        {text: 'Preços', value: "Preços"},        
        {text: 'Outros', value: "Outros"},     
      ],
      itemsCategory: [],
      starReviews: [
        { text: '5', value: 5 },
        { text: '4', value: 4 },
        { text: '3', value: 3 },
        { text: '2', value: 2 },
        { text: '1', value: 1 }
      ],
      menu: false,
      dates: [null, null],
      minDate: null, 
      maxDate: null,   
      formattedDates: [],
      downloadDialog: {
        show: false,
        fileName: ""
      },
      excelLoading: false
    }
  },
  props: {
    activeTab: { type: Number, required: true, default: 0}
  },
  mounted() {
    this.itemsCategory = this.activeTab === 0 ? this.categoryReviewsPos : this.categoryReviewsApp;
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);

    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');

    this.dates = [moment(minDate).format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD')];
  },
  watch: {
    dates: {
      handler(value) {
        this.form.minDate = value[0];
        this.form.maxDate = value[1];
        
        this.formattedDates = value.map(date => {
          return date ? moment(date).format('DD/MM/YYYY') : '';
        });
      },
      deep: true,
    },
    activeTab: {
      handler() {
        this.form.categories = [];
        this.itemsCategory = this.activeTab === 0 ? this.categoryReviewsPos : this.categoryReviewsApp;
      }
    }
  },
  methods: {
    emitModification(){
      if(this.$refs.form.validate()){
        this.form.categories = this.form.categories == null ? [] : this.form.categories;        
        this.$emit('modify', this.form);
      }
    }, 
    updateDateRangeText() {
      const minDate = this.minDate ? moment(this.minDate).format('DD/MM/YYYY') : '';
      const maxDate = this.maxDate ? moment(this.maxDate).format('DD/MM/YYYY') : '';
      return `${minDate} - ${maxDate}`;
    },
    clearDates() {
      this.dates = [];   
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
        this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
        this.form.minDate = moment(minDate).format("YYYY-MM-DD");
    },
    saveDate() {
      this.menu = false;
      if (!this.minDate) {
        this.minDate = this.date;
      } else {
        this.maxDate = this.date;
        this.dates = [
          this.$moment(this.minDate).format('DD/MM/YYYY'),
          this.$moment(this.maxDate).format('DD/MM/YYYY')
        ];
      }
    },
    handleDateChange(selectedDates) {
    if (selectedDates.length === 2) {
      const [minDate, maxDate] = selectedDates;
      if (moment(minDate).isAfter(maxDate)) {
        this.dates = [maxDate, minDate];
      } else {
        this.dates = [minDate, maxDate];
      }
    }
    },
    cleanFilters() {
      const maxDate = new Date();
      const minDate = new Date(maxDate.getTime());
      minDate.setDate(minDate.getDate() - 30);
        this.form = {
          posIds: [],
          minDate: moment(minDate).format("YYYY-MM-DD"),
          maxDate: moment(maxDate).format("YYYY-MM-DD"),
          categories: [],
          rating: null,
        };
      this.dates = [moment(minDate).format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD')];  
      this.emitModification();
    },
    allowedDates(date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);
      return selectedDate < currentDate;
    },
    async getExcelReport() {
      this.excelLoading = true;
      try {
        const response = await UsersFeedbacks.getExcelReport(this.form);
        this.downloadDialog.show = true;
        this.downloadDialog.fileName = response;
      } catch (error) {
        this.$refs.feedback.handleError("Houve um erro ao gerar o relatório", error)
      } finally {
        this.excelLoading = false;
      }
    },
  },
  components: {
    PointOfSalesMultipleSelector,
    DownloadDialog
  }
}
</script>