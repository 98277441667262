export default{
    titleDetailDialog: 'INFORMAÇÕES DA NOTA FISCAL',
    infoDetailDialog: 'Detalhes',
    numberInvoiceDetailDialog: 'Número da Nota',
    keyDetailDialog: 'Chave de acesso (NFe)',
    dateEmmitedDetailDialog: 'Data de emissão',
    productsDetailDialog: 'Produtos',
    addItemDetailDialog: 'Adicionar Produto',
    removeInvoiceDetailDialog: 'Excluir',
    receiveProductDetailDialog: 'Receber Produtos',
    validMessageDetailDialog: 'Para prosseguir preencha todos os dados',
    codeDetailDialog: 'Código',
    quantityInvoiceDetailDialog: 'Qtd na nota',
    unitPurchaseDetailDialog: 'Unidade de compra',
    unitTotalDetailDialog: 'Unidade total',
    unitValueDetailDialog: 'Valor unitário',
    valueTotalDetailDialog: 'Valor total',
    actionsDetailDialog: 'Ações',
    rulesNumbers: 'Apenas números são aceitos',
    returnSuccess: 'Compra inserida com sucesso',
    returnError: 'Não foi possível inserir a compra',
    unit: 'Unidade',
    bale: 'Fardo',
    package: 'Package',
    unitUnknown: 'Desconhecido',
}