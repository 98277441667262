export default {
    addressStateFilterNamesTranslations: 'State',
    localIdFilterNamesTranslations: 'Location',
    posIdFilterNamesTranslations: 'Point of sale',
    productIdFilterNamesTranslations: 'Product Id',
    dateIntervalFilterNamesTranslations: 'Day interval:',
    productCategoryIdFilterNamesTranslations: 'Category Id',
    inventoryIdFilterNamesTranslations: 'Stock Id',
    typeFilterNamesTranslations: 'Operation type',
    customerIdFilterNamesTranslations: 'Customer Id',
};