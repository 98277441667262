export default {
  textFieldSearchText: "Search",
  deleteNonUsedButton: "Delete unused",
  testNfceEmissionButton: "Test NFC-e emission",
  deleteTaxScenarioDeleteDialogText: "Are you sure you want do delete this fiscal scenario?",
  cancelButtonDeleteDialog: "Cancel",
  deleteButtonDeleteDialog: "Delete",
  dataTableNoDataText: "No fiscal scenarios registered",
  taxRateIcms: "ICMS Tax rate (%):",
  taxRatePis: "PIS Tax rate (%):",
  taxRateCofins: "COFINS Tax rate (%):",
  taxRateIpi: "IPI Tax rate (%):",
  taxBenefit: "Tax benefit:",
  headerIsInvoiceDataComplete: "Status",
  headerId: "Id",
  headerName: "Name",
  headerTaxation: "Tax Regime",
  headerState: 'State',
  headerCstIcms: 'CST-ICMS',
  headerCstPis: 'CST-PIS',
  headerCstCofins: 'CST-COFINS',
  headerCstIpi: 'CST-IPI',
  headerNumberOfProducts: 'No. Products',
  headerActions: 'Actions',
}