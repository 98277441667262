export default {   
    dictDefaultMessage: "Arrastre un archivo de imagen aquí, pegue (Ctrl+V) o haga clic para elegir",
    dictFallbackMessage: "Este navegador no es compatible para cargar imágenes. Intente con una versión más reciente de Chrome o Firefox",
    dictFileTooBig: "El archivo es demasiado grande, intente con uno más pequeño que {{maxFilesize}}MB",
    dictInvalidFileType: "Este formato de imagen no es compatible",
    dictResponseError: "No se pudo cargar la imagen (error {{statusCode}})",
    dictCancelUpload: "Cancelar carga",
    dictUploadCanceled: "La carga fue cancelada",
    dictCancelUploadConfirmation: "¿Desea cancelar la carga?",
    dictRemoveFile: "Eliminar",
    dictMaxFilesExceeded: "Se ha alcanzado el límite máximo de archivos",
    dictDefaultMessageXml: `Arrastre y suelte su archivo aquí, o seleccione un archivo. `,
    dictDefaultMessageXmlSelectFile: `SELECCIONAR ARCHIVO`,
    dictInvalidFileTypeXml: "Este formato de archivo no es compatible",
    dictResponseErrorXml: "No se puede cargar el archivo (error {{statusCode}})",
    dictUploadedFileXml: "¡Archivo cargado!",
    dictErrorXml: "Error desconocido",
    dictDoneXml: "No se puede autorizar la carga del archivo:",
  };
  