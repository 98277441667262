<template>
  <v-expansion-panels hover v-model="panel">
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div><v-icon>filter_list</v-icon> {{$t('filters.filtersActions')}}</div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2">
        <v-form @submit.prevent="emitModification" v-model="valid" ref="form" lazy-validation>


          <!-- Filters Row -->
          <v-row cols="12">

            <v-col md="3" cols="12">
              <interval-date-picker
                :interval-max-range=60
                :interval-start-range=14
                :reset-date="IntervalDatePicker.reset"
                :minDateLabel="$t('filters.labels.initialDate')"
                :maxDateLabel="$t('filters.labels.finalDate')"
                @intervalDateUpdated="onIntervalDateUpdated"
              ></interval-date-picker>
            </v-col>

            <v-col md="9" cols="12" class="ma-0 pa-0">
                <!-- Controllers Column -->
                <v-col cols="12" class="pb-0">
                  <v-select
                    v-model="form.auditController"
                    :items="controllers"
                    item-value="value"
                    item-text="text"
                    :label="$t('filters.labels.audit')"
                    clearable
                    :loading="loading"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>


                <!-- Actions Column -->
                <v-col cols="12" class="pt-0">
                  <v-select
                    v-model="form.actionNames"
                    :label="$t('filters.labels.actions')"
                    item-text="description"
                    item-value="value"
                    :items="actionNames"
                    clearable
                    multiple
                    :disabled="form.auditController == null"
                    :rules="[rules.arrayRequired]"
                  >
                    <template #selection="{ item }">
                      <v-chip small :color="(item.color)">
                        {{ item.description }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>


            </v-col>


          </v-row>

          <!-- Buttons Row -->
          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="resetFilter" text class="mr-2">
              <v-icon left>clear</v-icon>{{ $t('buttons.clear') }}
            </v-btn>
            <v-btn color="success" type="submit" :disabled="!valid">
              <v-icon left>refresh</v-icon>{{ $t('buttons.update') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <feedback ref="feedback"></feedback>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import ActionsColors from '@/views/audits/ActionsColors.js';
import IntervalDatePicker from "@/components/filters/IntervalDatePicker";
import { getAuditTranslation } from '@/types/AuditTranslationMapper';

export default {
  props: {
    auditCategories: { type: Array, required: true}
  },
  components: {
    IntervalDatePicker
  },
  mounted() {
    this.resetFilter();
  },
  computed: {
    formattedMinDate: function() {
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function() {
      return this.formatDate(this.form.maxDate);
    },
  },
  watch: {
    'form.auditController': function(value) {
      if (value == null) {
        this.form.actionNames = [];
      }
      this.actionNames = this.auditCategories.find(i => i.name == value).actions;
      // The loop below applies the color to the actions objects
      for (let i = 0; i < this.actionNames.length; i++) {
        this.actionNames[i].color = ActionsColors.auditColorsPallete[i];
      }
      this.form.actionNames = this.actionNames.map(i => i.value);
      this.actionNames = this.actionNames.map(i => ({description: getAuditTranslation(i.value), value: i.value, color: i.color}));
    },
    auditCategories: function(categories) {
      const mappedCategories = [];
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        mappedCategories[i] = {text: getAuditTranslation(category.name), value: category.name};
        }
      this.controllers = mappedCategories;
    },
  },
  data() {
    return {
      hasMinDate: null,
      hasMaxDate: null,
      form: {
        minDate: null,
        maxDate: null,
        actionNames: [],
        auditController: null
      },
      loading: false,
      actionNames: [],
      controllers: [],
      rules: {
        required: (v) => !!v || this.$t('validations.requiredField'),
        arrayRequired: (v) => !!v.length || this.$t('validations.chooseAction'),
      },
      valid: false,
      IntervalDatePicker: {
        reset: false,
      },
      panel: 0
    }
  },
  methods: {
  resetFilter() {
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 15);
    this.form.maxDate = moment(maxDate).format("YYYY-MM-DD");
    this.form.minDate = moment(minDate).format("YYYY-MM-DD");
    this.form.auditController = null;
    this.form.actionNames = [];


    this.IntervalDatePicker.reset = true;
    this.$nextTick(() => {
      this.IntervalDatePicker.reset = false;
    });
  },
  emitModification() {
    if (this.$refs.form.validate()) {
      this.$emit("modification", this.form)
    }
  },
  onIntervalDateUpdated(minDate, maxDate, resetDate) {
    if (resetDate) {
      this.IntervalDatePicker.reset = false;
    }
    this.form.minDate = minDate;
    this.form.maxDate = maxDate;
  },
  getAuditTranslation
}
}
</script>

<style>

</style>
