<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="headline">{{$t('UnlockLocks')}}</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="lockList"
          hide-default-footer
          class="elevation-1"
        >
        <template v-slot:item.action="{ item }">
            <v-btn :disabled="loading.locks[item.code]" :loading="loading.locks[item.code]" color="primary" @click="unlockLock(item.code)">
              <v-icon left>mdi-lock-open-variant-outline</v-icon>
              {{$t('Unlock')}}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog=false">{{$t('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>


export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    posId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      lockList: [],
      loading: {
        getLocks: false,
        openLock: false,
        locks: {},
      },
      headers: [
        { text: this.$t('LockName'), align: 'start', value: 'deviceName' },
        { text: this.$t('Actions'), value: 'action', sortable: false },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        if(this.value === true){
          this.getLocks(this.posId);
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    unlockLock(lockId) {
      this.$set(this.loading.locks, lockId, true);

      const unlockUrl = `/api/microMarket/accessControl/unlock?pointOfSaleId=${this.posId}&lockCode=${encodeURIComponent(lockId)}&ignoreMobileValidations=${true}`;
      this.axios.post(unlockUrl)
        .then(() => {
          this.handleSuccess();
        })
        .catch(() => { // Para debugar, use .catch(error => {
            this.handleError();
        })
        .finally(() => {
          this.$set(this.loading.locks, lockId, false);
        });
    },
    getLocks(posId) {
      this.loading.openLock = true;
      this.axios.get(`api/accessControl/getLock/${posId}`)
      .then(response => {
        this.lockList = response.data.locks;
      }).catch(error => {
        console.error(error);
        }).finally(() => {
          this.loading.openLock = false;
        });
    },
    handleSuccess() {
      this.$refs.feedback.handleSuccess(this.$t('SuccessMessage'));
    },
    handleError() {
      this.$refs.feedback.handleError(this.$t('ErrorMessage'));
    }
  },
};
</script>
