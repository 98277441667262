<template>
  <v-autocomplete
    :prepend-icon="icon"
    :items="items"
    :value="value"
    v-on:input="$emit('input', $event == -1 ? null : $event)"
    :label="label"
    :hint="hint"
    :filter="customFilter"
    :loading="loading"
    :disabled="disabled"
    :persistent-hint="persistentHint"
    :search-input.sync="rawInput"
    :required="required"
    :clearable="clearable"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    single-line
  >
    <template #item="data">
      <template v-if="data.item.hasOwnProperty('isButton') && data.item.isButton">
        <v-tooltip :disabled="errors.length == 0" top>
          <template #activator="{ on }">
            <v-btn v-on="on" :disabled="errors.length > 0" color="success" @click.native.capture.stop="createItem">
              <v-icon left>add_circle_outline</v-icon>
              {{ $t('AddCrudSelect') }}
            </v-btn>
          </template>
          <span>{{errors[0]}}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title v-html="data.item[itemText]"></v-list-item-title>
        </v-list-item-content>
        <v-btn v-if="removable" color="error" text @click.native.capture.stop="removeItem(data.item[itemValue])">
          <v-icon>remove_circle_outline</v-icon>
        </v-btn>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    props: {
      value: {},
      rules: {},
      icon: { type: String, default: null },
      label: { type: String, required: true },
      apiUrl: { type: String },
      apiTextField: { type: String, default: "name" },
      apiIdField: { type: String, default: "id" },
      apiObject: { type: Object, default: () => ({}) },
      hint: { type: String },
      persistentHint: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      required: { type: Boolean, default: false },
      clearable: { type: Boolean, default: false },
      removable: { type: Boolean, default: false },
      itemText: { type: String, default: "text" },
      itemValue: { type: String, default: "value" },
      hasCustomItems: { type: Boolean, default: false },
      customItems: { type: Array },
    },
    data() {
      return {
        items: [],
        rawInput: '',
        errors: [],
        loading: false,
        customFilter(item, queryText){
          const hasValue = val => val != null ? val : '';
          const text = hasValue(item.name);
          const query = hasValue(queryText);
          return text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
            || (Object.prototype.hasOwnProperty.call(item, 'isButton') && item.isButton);
        }
      }
    },
    watch: {
      rawInput: function () {
        this.validateInput();
      },
      customItems: function (newItems) {
        if(newItems != null){
          this.swapItems(newItems);
        }
      }
    },
    mounted() {
      this.queryItems();
      this.items = [this.createHiddenButtonItem()];
      if(!this.hasCustomItems) this.loading = true; 
     },
    methods: {
      queryItems(){
        if(this.hasCustomItems || this.apiUrl == null) return;
        this.axios.get(this.apiUrl)
          .then((response) => {
            this.loading = false;
            this.swapItems(response.data);
          });
      },
      createItem(){
        this.validateInput();
        if(this.errors.length > 0) return;
        var input = this.rawInput;
        this.apiObject[this.apiTextField] = input;
        this.axios.post(this.apiUrl, this.apiObject)
          .then((response) => {
            this.items = this.items.filter((i) => !Object.prototype.hasOwnProperty.call(i, 'isButton') || !i.isButton);
            this.items.push({ [this.itemValue]: response.data.id, [this.itemText]: input } );
            this.items.push(this.createHiddenButtonItem());
            this.$emit('input', response.data.id);
            this.rawInput = input;
          });
      },
      removeItem(id){
        this.axios.delete(this.apiUrl + '/' + id)
          .then(() => {
            this.items = this.items.filter(i => i[this.itemValue] != id);
            this.$emit('input', null);
            this.rawInput = "";
          }).catch((error) => {
            if(error.response && error.response.data) error = error.response.data;
            const message = this.$t('removeItemErrorCrudSelect') + (error == null ? '' : (': ' + error));
            alert(message);
          });
      },
      validateInput(){
        if(this.rawInput == null) this.rawInput = '';
        this.errors = [];
        this.rules.forEach(rule => {
          var ruleResult = rule(this.rawInput);
          if(ruleResult !== true){
            this.errors.push(ruleResult);
          }
        });
        if(this.rawInput != null){
          this.items.forEach((i) => {
            if(i[this.itemText].toLowerCase() === this.rawInput.toLowerCase()){
              this.errors.push(this.$t('existingNameCrudSelect'));
            }
          });
        }
      },
      swapItems(newItems){
        this.items = [];
        newItems.forEach((i) => this.items.push(i));
        this.items.push(this.createHiddenButtonItem());
      },
      createHiddenButtonItem(){
        return { [this.itemText]: '', [this.itemValue]: -1, isButton: true };
      },
    },
  };
</script>