
import { Country } from '@/constants/enums';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    userCountry(): Country {
      return (this as any).$auth.user().tenantCountry as Country;
    },
    translation() {
      let employerIdentificationType: string;
      switch (this.userCountry) {
        case Country.US:
          employerIdentificationType = this.$t('tables.headers.ein').toString();
          break;
        case Country.BR:
          employerIdentificationType = this.$t('tables.headers.cnpj').toString();
          break;
        case Country.CO:
          employerIdentificationType = this.$t('tables.headers.nit').toString();
          break;
        default:
          employerIdentificationType = this.$t('tables.headers.cnpj').toString();
          break;
      }
      return {
        tenant: {
          group: {
            help: {
              about: {
                description: this.$t('tenant.group.help.about.description', {employerIdentificationType}).toString()
              }
            }
          }
        }
      }
    }
  }
})
