<template>
    <v-card min-height="180px" height="100%">
      <v-card-title class="pa-1">
        <v-col cols="auto">
          <strong style="word-break: normal;">{{ $t('productsCloseToExpirationTitle') }}</strong>
          <products-close-to-expiration-help/>
          <router-link :to="`/${$t('path.nameLosses')}`">
            <v-icon>mdi-open-in-new</v-icon>
          </router-link>
          <v-card-subtitle class="justify-center align-center pa-0">{{ $t('productsCloseToExpirationSubTitle') }}</v-card-subtitle>
        </v-col>
        <v-spacer></v-spacer> 
        <v-switch 
          class="mb-6 mr-2 ml-3 pr-2 custom-label"
          hide-details
          :label="$t('labelShowZeroProducts')"
          v-model="expiration.showZeroProducts">
        </v-switch>
      </v-card-title>  
      <v-data-table
        :loading="expiration.loading"
        :headers="expiration.headers"
        :items="expiration.items"
        :options.sync="expiration.paginationOptions"
        :server-items-length="expiration.totalItems"
        hide-default-footer
        hide-default-header
        class="ma-1 pb-2"
      >
        <template #header="{ props: { headers: headers } }">
          <thead>
            <tr>
              <th v-for="h in headers"
                :key="h.value"
                :class="'blue-text'"
                >
                {{ h.text }}
              </th>
            </tr>
          </thead>
        </template>    
        <template #item="{ item }">
          <tr>
            <td>
              <span class="dot ml-2" :style="`background-color: ${item.circle}`"></span>
            </td>
            <td :class="{'expired-product-row': isExpired(item.productExpirationDate)}">{{ item.product.description }}</td>
            <td :class="{'expired-product-row': isExpired(item.productExpirationDate)}">
              {{ $moment(item.productExpirationDate).format('DD/MM/YYYY') }}
            </td>
            <td :class="{'expired-product-row': isExpired(item.productExpirationDate)}">{{ item.currentQuantity }}</td>            
            <td :class="{'expired-product-row': isExpired(item.productExpirationDate)}" class="hide-character">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="hide-character">{{ item.inventoryName }}</span>
                </template>
                <span>{{ item.inventoryName }}</span>
              </v-tooltip>
            </td>            
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </template>
  
  <script>
  import ProductsCloseToExpirationHelp from '@/views/dashboard-new/helpers-new/ProductsCloseToExpirationHelp.vue';
  import moment from "moment"
  
  export default {
    components: {
      ProductsCloseToExpirationHelp,
    },
    data() {
      return {
        expiration: {
          loading: true,
          headers:[
            { text: '', align: 'center', sortable: false, value: 'circle', width: '30px' },
            { text: this.$t('ProductsCloseToExpirationProduct'), align: 'left', sortable: false, value: 'product.description' },
            { text: this.$t('ProductsCloseToExpirationMaturity'), align: 'left', sortable: false, value: 'productExpirationDate' },
            { text: this.$t('ProductsCloseToExpirationQuantityAtPos'), align: 'left', sortable: false, value: 'currentQuantity' },
            { text: this.$t('ProductsCloseToExpirationPos'), align: 'left', sortable: false, value: 'inventoryName' },
          ],
          items: [],
          paginationOptions: {
            sortBy: ['productExpirationDate'],
            sortDesc: [true],
            pageSize: 8
          },
          totalItems: 0,
          showZeroProducts: false,
        },
      };
    },
    watch: {
      'expiration.paginationOptions': {
        handler(newOptions, oldOptions) {
          if (newOptions, oldOptions) {
            this.updateExpiration();
          }
        },
        deep: true
      },
      'expiration.showZeroProducts': { 
      handler(newShowZeroProducts, oldShowZeroProducts) {
        if (newShowZeroProducts !== oldShowZeroProducts) {
          this.updateExpiration();
        }
      }
      }
    },
    methods: {
      updateExpiration() {
        this.expiration.loading = true;
        let params = {
        ...this.expiration.paginationOptions,
        showZeroProducts: this.expiration.showZeroProducts };
        this.axios.get('api/operationalReports/productsToExpire', { params: params })
          .then(response => {
            let items = response.data.items;

            this.expiration.items = items;
            this.expiration.totalItems = response.data.totalItems;

            this.expiration.items.forEach((item) => {
              const expirationDate = moment(item.productExpirationDate);
              const today = moment();
              const daysUntilExpiration = expirationDate.diff(today, 'days');

              if (daysUntilExpiration < 0) {
                item.circle = this.$vuetify.theme.defaults.dashboard.dashboardRed; 
              } else if (daysUntilExpiration <= 15) {
                item.circle = this.$vuetify.theme.defaults.dashboard.dashboardRedDarken1; 
              } else {
                item.circle = this.$vuetify.theme.defaults.dashboard.dashboardRedDarken2; 
              }
            });
          })
          .catch(() => {})
          .finally(() => {
            this.expiration.loading = false;
          });
      },
      isExpired(expirationDate) {
        return new Date(expirationDate) < new Date();
      },
    },
  }
  </script>
  
  <style scoped>
  .v-data-table > .v-data-table__wrapper > table th.blue-text {
    color: var(--card-title-blue);
    font-size: 14px;
  }

  table tbody td {
    border: none !important;
    height: 30px !important;
    vertical-align: top;
  }
  .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
  }
  .v-data-table > .v-data-table__wrapper > table thead th {
  border-bottom: none !important;
}
.expired-product-row {
  color:#FF5252; 
  font-weight: bold
}
.hide-character{
  max-width: 0;
  width: 150px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
* /deep/ .custom-label .v-label {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
}
  </style>
  