export default {
    averageTicketCardTitle: 'Ticket Médio por Locais',
    averageTicketStateLabel: 'Estado',
    averageTicketTotalLocationsMessage: 'Total de locais consultados: ',
    averageTicketTotalLabel: 'Ticket Médio Total',
    averageBillingLabel: 'Média de Faturamento',
    averageProductVarietyLabel: 'Média de Mix de Produtos',
    averageTicketNoResultsMessage: 'Sem resultados',
    averageTicketChartTitle: 'Ticket Médio por Mix de Produtos',
    xAxisLabelAverageTicket: 'Mix de Produtos',
    yAxisLabelAverageTicket: 'Ticket Médio',
    averageTicketLabel: 'Ticket Médio',
    averageTicketCardTitleNew: 'Ticket Médio por Mix de Produtos',
  };
  