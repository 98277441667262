export default {
  tenant: {
    group: {
      help: {
        about: {
          title: 'Creación de nuevos Grupos',
          description: `
          <p>
            En esta funcionalidad, puedes crear nuevos Grupos.
          </p>
          <ul>
            <li>
              Cuando se abre la pantalla de creación de nuevos Grupos, el sistema busca y sugiere el próximo ID disponible para el nuevo Grupo.
            </li>
            <li>
              El campo <strong>{employerIdentificationType}</strong> es obligatorio. 
              <strong>Se destaca que el {employerIdentificationType} completado será utilizado para cobros</strong>.
            </li>
            <li>
              El campo <strong>Nombre del Grupo</strong> es obligatorio y debe tener entre 5 y 60 caracteres. 
              <strong>Se sugiere utilizar el nombre comercial relacionado con el {employerIdentificationType}</strong>.
            </li>
          </ul>
          `
        },
      },
    }
  },
}