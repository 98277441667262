<template>
  <help>
    <template #title>{{ $t('productRegistration') }}</template>
    <p>
    <ul>
      <li>
        <strong>{{ $t('codeProductHelp') }}</strong>: {{ $t('codeDescriptionProductHelp') }}
      </li>
      <li>
        <strong>{{ $t('descriptionProductHelp') }}</strong>:{{ $t('descriptionDetailsProductHelp') }}
      </li>
      <li>
        <strong>{{ $t('categoryProductHelp') }}</strong>: {{ $t('categoryDetailsProductHelp')}}
      </li>
      <li>
        <strong>{{ $t('defaultPriceProductHelp') }}</strong>: {{ $t('defaultPriceDetails')}}
      </li>
      <li>
        <strong>{{ $t('defaultPriceProductHelp') }}</strong>: {{ $t('PriceDetails') }}
      </li>
      <li>
        <strong>{{ $t('ageRestrictedProductHelp') }}</strong>: {{ $t('ageRestrictedDetails') }}
      </li>  
    </ul>
    </p>
    <h3>{{ $t('variablePriceBarcode') }}</h3>
    <p>
      {{ $t('variablePriceBarcodeDetails') }}
    </p>
    <p>
      {{ $t('variablePriceBarcodeExample') }}
    </p>

    <help-video  help-code="MyProducts"/>
  </help>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
