export default {
  validations: {
    requiredField: "Este campo é obrigatório",
    insertValidValue: "Insira um valor válido",
    chooseAction: "Escolha pelo menos uma ação",
    minCodeLength: 'O código precisa ter {length} caracteres',
    invalidEIN: "Insira um EIN válido",
    invalidCNPJ: "Insira um CNPJ válido",
    invalidNIT: "Insira um NIT válido",
    insertInteger: 'Insira um número inteiro',
    invalidEmail: "Insira um email válido",
    invalidPositiveValue: "Insira um valor positivo",
  }
};