<template>
  <div class="text-center">
    <vue-dropzone 
      id="dropzone" 
      ref="Dropzone" 
      :options="dropzoneOptions"
      @vdropzone-file-added="addFile"
      @vdropzone-success="onSuccess"
      @vdropzone-error="onError"
    >
    </vue-dropzone>
    <div v-if="uploadSuccess">
      <span>{{ $t('dictUploadedFileXml') }}</span>
    </div>
    <v-btn
      color="primary"
      v-if="confirmButtonEnable"
      @click.native="onUploadConfirmed"
      class="mr-2 mt-2"
    >
      <v-icon left>cloud_upload</v-icon>
      {{ $t('componentConfirmButton') }}
    </v-btn>
    <v-btn
      v-if="cancelButtonEnable"
      color="error"
      @click.native="onRemove"
      class="mt-2"
    >
      <v-icon left>clear</v-icon>
      {{ $t('componentCancelButton') }}
    </v-btn>

    <feedback ref="feedback"></feedback>
  </div>
</template>

<script>
import i18n from '@/locales/i18n';
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    options: { type: Object, required: true },
    urlRequest: { type: String, required: true },
    overwriteFilename: { type: String, required: false, default: '' }
  },
  data() {
    return {
      images: [],
      confirmButtonEnable:false,
      cancelButtonEnable: false,
      uploadSuccess: false,
      dropzoneOptions: this.options,
      url: this.urlRequest,
      filename: ''
    };
  },
  created() {
    this.url = this.urlRequest
    this.getUploadUrl();
  },
  methods: {
    onSuccess(file) {
        this.filename = file.name;
        this.uploadSuccess = true;
        this.$emit('File-Completed', this.uploadSuccess);
    },
    onUploadConfirmed() {
      this.$refs.Dropzone.processQueue();
      this.confirmButtonEnable = false;
    },
    onRemove() {
      this.$refs.Dropzone.removeAllFiles();
      this.confirmButtonEnable = false;
      this.cancelButtonEnable = false;
      this.uploadSuccess = false;
      this.$emit('File-Removed', this.uploadSuccess);
    },
    addFile() {
      this.confirmButtonEnable = true;
      this.cancelButtonEnable = true;
    },
    onError() {
      this.confirmButtonEnable = false;
    },
    getUploadUrl() {
      const component = this;
      this.dropzoneOptions.accept = function(file, done) {

        const filename = this.overwriteFilename !== '' ? this.overwriteFilename : file.name;
        var query = {'contentType': file.type, 'nameFile': filename};

        component.axios.get(`${this.url}`, { params: query })
          .then(response => {
            file.uploadRequest = response.data;
            file.uploadURL = response.data.url;
            done();
          }).catch(error => {
            const specificError = ((error || {}).response || {}).data || this.i18n.t('dictDoneXml');
            done(`${i18n.t('dictDoneXml')} ${specificError}`);
        });
      }.bind(this);
    },
  },
};
</script>

<style>
.gray-background-text {
  padding: 2px 5px;
  background-color: #666666;
  color: #fff;
  border-radius: 5px;
}
.font-size-icon {
  font-size: 50px;
}
.font-size-text {
  font-size: 14px;
}
.font-size-selection {
  font-size: 18px;
}
.remove-icon {
  color: black;
  border: none !important; 
  cursor: pointer; 
  font-size: 24px;
  background-size: 40px 40px 40px 20px;
}
</style>
