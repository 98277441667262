export type unlockResult = {
    value: string;
    text: string
  }

  export enum primaryUnlockFilter {
    Success = 'Success',
    Invalid = 'Invalid',
    Error = 'Error',
  }

  export const filters = {
    Success: ['LockOpened', 'FacialRecognitionSuccess'],
    Invalid: ['PhoneNotValidated', 'EmailNotValidated', 'CpfNotValidated', 'AgeNotValidated', 'UserBlocked'],
    Error: [
        'Unknown', 'LockNotFound', 'DeviceNotFound', 'DeviceNotPaired',
        'DeviceNotConnected', 'DeviceNotResponsive', 'CommunicationTimeout',
        'CommunicationError', 'TerminalNotReachable', 'BluetoothDisabled',
        'NoCertificate', 'InvalidClientCertificate',
        'InvalidServerCertificate', 'FacialRecognitionFail'],
  }
