
  import Vue from 'vue';
  import agent from "@/api/agent";
  import ProductsTaxGroupFilter from '@/components/filters/ProductsTaxGroupFilter.vue';
  type FormType = {
    productId?: number | null,
    isPrincipal: boolean
  }

export default Vue.extend({
  components: {ProductsTaxGroupFilter},
  props: {
    taxGroupId: {type: Number, required: true}
  },
  data: function () {
    return {
      form: {
        productId: null,
        isPrincipal: false,
      } as FormType,
      messageMain: 'Selecione um produto',
      groupCanBeSetAsMain: false,
      valid: false,
      creating: false,
      label: this.$t("productToTaxGroup").toString(),
      productIdAddedToTaxGroup: -1
    };
  },
  watch: {
    valid: function(isFormValid) {
      if(!isFormValid) {
        this.messageMain = 'Selecione um produto';
        this.groupCanBeSetAsMain = isFormValid;
      }
    }
  },
  methods: {
    cancelCreateMode() {
      this.resetForm();
      this.$emit("cancel-create-mode");
    },
    changeProduct(incomingData: any) {
      if (incomingData != null) {
        this.form.productId = incomingData.id;
        const invalid = !incomingData.canHaveCurrentTaxGroupAsPrincipal;
        if(invalid) {
          this.messageMain = 'Produto pode ter apenas um cenário fiscal como principal';
        } else {
          this.messageMain = 'Tornar o cenário principal';
        }
        this.groupCanBeSetAsMain = !invalid;
        this.form.isPrincipal = !invalid;
      }
    },
    async createItem() {
      if ((this.$refs.form as any).validate()) {
        this.creating = true;
        const body = {
          productId: this.form.productId as number,
          isPrincipal: this.form.isPrincipal
        };
        await agent.Taxings.addProductToTaxGroup(this.taxGroupId, body)
          .then(() => {
            this.$emit("success-creating-product", this.$t("successProductToTaxGroup"));
            this.productIdAddedToTaxGroup = body.productId;
          })
          .catch((error) => {
            this.$emit("error-creating-product", this.$t("errorProductToTaxGroup"), error);
          })
          .finally(() => {
            this.creating = false;
            this.resetForm();
          });
      }
    },
    resetForm() {
      (this.$refs.form as any).reset();
    },
  }
});
