
import Vue from 'vue';
import XmlDialog from './XmlDialog.vue';
import XmlProductsDialog from './XmlProductsDialog.vue';
import {InvoicePurchase, Product, PurchaseType} from "@/types/Purchases"
import DetailXmlDialog from './DetailXmlDialog.vue';
import Feedback from '@/components/Feedback.vue';
import agent from '@/api/agent';
import LocalStorageDialog from '../LocalStorageDialog.vue';

export default Vue.extend({
    components: { 
    XmlDialog,
    XmlProductsDialog,
    DetailXmlDialog,
    Feedback,
    LocalStorageDialog
  },
   data() {
    return {
        currentStep: 1 as number,
        xmlData: {
          fileName: '' as string | null,
          inventoryId: null as number | null,
        },
        invoice: {
              productRegistrationDisabled: false as boolean,
              nfeNumber: '' as string,
              serie: '' as string,
              dateEmitted: '' as string,
              providerName: '' as string,
              key: '' as string,
              purchaseType: PurchaseType.XML,
              inventoryId: null as number | null,
              products: [] as Product[],
            } as InvoicePurchase,
        storageDialog: false as boolean,
        localStorageSelected : false as boolean,
        productOpened : false as boolean,
        indexProductEdit: undefined as number | null | undefined,
    }
   },
   props: {
    value: {
      type: Boolean,
      default: false
    },
   },
   computed: {
    dialog: {
      get() : boolean {
        if(this.value && this.indexProductEdit === undefined)
        {
          this.verifyLocalStorage();
        }
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value);
      }
    },
  },
   methods:{
    updateXmlDialog(value : any) {
      this.xmlData.fileName = value.fileName;
      this.xmlData.inventoryId = value.inventoryId;
      
      this.readXml();
      this.productOpened = true;
    },
    closeDialog(){
        this.dialog = false;
        this.currentStep = 1;

        this.xmlData = {
          fileName: '' as string,
          inventoryId: null as number | null,
        }
        this.invoice = {
          productRegistrationDisabled: false as boolean,
          nfeNumber: '' as string,
          serie: '' as string,
          dateEmitted: '' as string,
          providerName: '' as string,
          key: '' as string,
          purchaseType: PurchaseType.XML,
          inventoryId: null as number | null,
          products: [] as Product[],
        } as InvoicePurchase;
        this.storageDialog = false;
        this.localStorageSelected = false;
        this.indexProductEdit = undefined;
    },
    verifyLocalStorage(){
      const step = parseInt(localStorage.getItem('stepInvoice') as string);
      const invoiceLocalStorage = JSON.parse(localStorage.getItem('invoiceImported') as string);

      if(step !== null && step > 1 && invoiceLocalStorage != null && !this.localStorageSelected && invoiceLocalStorage.purchaseType === PurchaseType.XML){
        this.storageDialog = true;
        this.dialog = false;
      }
      else if(this.localStorageSelected && invoiceLocalStorage.purchaseType === PurchaseType.XML){
        this.getLocalStorage();
      }
      else{
        this.initialProcess();
      }
    },
    getLocalStorage(){
      this.currentStep = parseInt(localStorage.getItem('stepInvoice') as string);
      this.invoice = JSON.parse(localStorage.getItem('invoiceImported') as string);

      if(this.currentStep === 2)
        this.productOpened = true;
    },
    initialProcess(){
      localStorage.removeItem('stepInvoice');
      localStorage.removeItem('invoiceImported');
      this.xmlData.fileName = null;
      this.currentStep = 1;
      this.productOpened = false;
    },
    closeLocalStorage(value : boolean, localStorageSelected : boolean){
      this.localStorageSelected = localStorageSelected;
      this.storageDialog = value;
      this.dialog = true;

      if(!localStorageSelected){
        this.initialProcess();
      }

    },
    completeProduct(xml: InvoicePurchase){
      this.productOpened = false;
      this.invoice.products = xml.products.slice();

      this.currentStep = 3;
      this.setLocalStorage();
      this.indexProductEdit = null;
    },
    handleError(message: string, error: any) {
      this.closeDialog();
      (this.$refs.feedback as any).handleError(message, error);
   },
   handlerSuccess(message: string) {
      this.closeDialog();
      (this.$refs.feedback as any).handleSuccess(message);
   },
   setLocalStorage(){
      localStorage.setItem('stepInvoice', this.currentStep.toString());
      localStorage.setItem('invoiceImported', JSON.stringify(this.invoice));
   },
   readXml(){
            agent.Purchases.readXml({fileName: this.xmlData.fileName}).then((response) => {
                this.invoice = response;
                this.invoice.purchaseType = PurchaseType.XML;
                this.invoice.inventoryId = this.xmlData.inventoryId!;
                this.currentStep = 2;
                this.setLocalStorage();
            }).catch((error) => {
                (this.$refs.feedback as any).handleError(this.$t('stepsDialogMasterError'), error);
                this.closeDialog();
            });
        },
    editProduct(index: number) {
        this.indexProductEdit = index;
        this.productOpened = true;

        this.currentStep = 2;
      }
   }
})
