export default {
	transactions: {
		title: 'Transações',
		status: {
			paid: 'Pago',
			cancelled: 'Cancelado',
			paymentError: 'Erro com o Pagamento',
		},
		internalCode: 'Código Interno',
		discountCoupon: 'Cupom de Desconto',
		errorMessage: 'Mensagem de Erro',
		report: {
			dynamic:{
				title: 'Resumo Dinâmico de Vendas',
				help: {
					description: `
						<p><strong>Nesta página, você pode gerar relatórios personalizados de vendas conforme suas necessidades. Utilize as seguintes funcionalidades para configurar seus relatórios:</strong></p>
						<p><strong>Filtros e Ações:</strong> Selecione o período desejado utilizando as datas iniciais e finais. Além disso, você pode optar por intervalos fixos ou mesmo dia.</p>
						<p><strong>Localização:</strong> Use este filtro para escolher os locais de interesse. Isso pode incluir diferentes Pontos de Venda (PDVs) ou outras localizações relevantes.</p>
						<p><strong>Produto:</strong> Selecione produtos ou categorias específicas para detalhar o relatório de acordo com a sua necessidade de análise.</p>
						<p><strong>Agrupamento de Dados:</strong> Escolha como deseja organizar os dados no relatório. As opções de agrupamento incluem Produtos, Categoria, Clientes, Pontos de Venda (PDVs), Estados, Métodos de Pagamento, Bandeiras de Cartões, Dia, Mês, Dia da Semana ou Hora.</p>
						<p><strong>Salvar Filtros:</strong> Salve suas preferências de filtros para reutilizá-las em relatórios futuros, agilizando o processo.</p
						<p><strong>Exportação de Relatórios:</strong> Após configurar os filtros de acordo com suas necessidades, você pode exportar o relatório em formato Excel clicando no botão "Excel".</p>
						<p><strong>Lembre-se de clicar em "Atualizar" após ajustar os filtros para visualizar as informações mais recentes.<strong></p>
      	`,
				}
			}
		}
	}
}
