// noinspection SpellCheckingInspection

export const ADMINISTRATOR_ROLE = 'Administrador';
export const MANAGER_ROLE = 'Gerente';
export const ACCOUNTING_ROLE = 'Contabilidade';
export const TELEMETRY_ROLE = 'Telemetria';
export const MARKET_ROLE = 'Vendas';
export const TRANSACTIONS_VIEWER_ROLE = 'Visualização Transações';
export const SUPPLIER_ROLE = 'Logistica';
export const SUPPLIER_MOBILE_ROLE = 'TouchPay Abastecedor';
export const BLIND_SUPPLIER_MOBILE_ROLE = 'Inventário às cegas e abastecimento';
export const SECURITY_ROLE = 'Segurança';
export const CASHLESS_ADMINISTRATOR_ROLE = 'Administrador PrivateLabel';
export const CASHLESS_VIEWER_ROLE = 'Visualização PrivateLabel';
export const APP_ADMINISTRATOR_ROLE = 'Administrador App';
export const REGISTERS_ROLE = 'Cadastros';
export const PRICE_MANAGER_ROLE = 'Edição Preços';
export const PLANOGRAM_VIEWER_ROLE = 'Visualização Planogramas';
export const SUPPORT_ROLE = 'Suporte';
export const TAXINGS_ROLE = 'Notas Fiscais';
export const BUILDING_MANAGER_ROLE = 'Síndico';
export const ORDERS_MANAGER_ROLE = 'Gerenciador de pedidos';
export const REMOTE_MONITORING_ROLE = 'Monitoramento Remoto';


// Hierarchy roles, should be named as the low role on the branch.

// 1st level
// Is the Administrator, obviously...

// 2nd level
export const MANAGER_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE];
export const ACCOUNTING_HIERARCHY = [ADMINISTRATOR_ROLE, ACCOUNTING_ROLE];
export const TOP_HIERARCHY = [ADMINISTRATOR_ROLE, ACCOUNTING_ROLE, MANAGER_ROLE];

// 3rd level
export const MARKET_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, MARKET_ROLE];
export const APP_ADMINISTRATOR_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, APP_ADMINISTRATOR_ROLE];
export const SUPPLIER_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, SUPPLIER_ROLE];
export const SECURITY_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, SECURITY_ROLE];
export const TELEMETRY_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, TELEMETRY_ROLE];
export const REGISTERS_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, REGISTERS_ROLE];
export const CASHLESS_ADMINISTRATOR_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, CASHLESS_ADMINISTRATOR_ROLE];
export const ORDERS_MANAGER_HIERARCHY  = [ADMINISTRATOR_ROLE, ORDERS_MANAGER_ROLE];

// 4th level
export const TAXINGS_HIERARCHY = [ADMINISTRATOR_ROLE, ACCOUNTING_ROLE, MANAGER_ROLE, TAXINGS_ROLE];
export const TRANSACTIONS_VIEWER_HIERARCHY = [ADMINISTRATOR_ROLE, ACCOUNTING_ROLE, MANAGER_ROLE, MARKET_ROLE, TRANSACTIONS_VIEWER_ROLE];
export const PRICE_MANAGER_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, MARKET_ROLE, PRICE_MANAGER_ROLE];
export const CASHLESS_VIEWER_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, CASHLESS_ADMINISTRATOR_ROLE, CASHLESS_VIEWER_ROLE];

//5th level
export const PLANOGRAM_VIEWER_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, MARKET_ROLE, PRICE_MANAGER_ROLE, PLANOGRAM_VIEWER_ROLE];
export const OPERATIONAL_DASHBOARD_HIERARCHY = [ADMINISTRATOR_ROLE, MANAGER_ROLE, TELEMETRY_ROLE, MARKET_ROLE, TRANSACTIONS_VIEWER_ROLE, 
  SUPPLIER_ROLE, SUPPLIER_MOBILE_ROLE, BLIND_SUPPLIER_MOBILE_ROLE, SECURITY_ROLE, CASHLESS_ADMINISTRATOR_ROLE, CASHLESS_VIEWER_ROLE, 
  APP_ADMINISTRATOR_ROLE, REGISTERS_ROLE, PRICE_MANAGER_ROLE, PLANOGRAM_VIEWER_ROLE, TAXINGS_ROLE];



export default {
  MANAGER_ROLE
};