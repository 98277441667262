export default {
    xmlProductsDialogCardTitle: 'INFORMAÇÕES DOS PRODUTOS',
    xmlProductsDialogHelp1: 'Informações dos produtos',
    xmlProductsDialogParagraph1: `Nesta página, os dados extraídos de envios via XML estão disponíveis para associação a produtos 
    específicos, permitindo a seleção da unidade de conversão para atualizar o estoque.`,
    xmlProductsDialogProductName: 'Nome do produto',
    xmlProductsDialogProductCod: 'Código do produto',
    xmlProductsDialogUnitConversion: 'unidade de conversão',
    xmlProductsDialogUnit: 'Unidades: ',
    xmlProductsDialogSelectProduct: 'Selecione o Produto ',
    xmlProductsDialogProductSelect: 'Produto já selecionado',
    xmlProductsDialogHelp2: 'Caso o código de barras do produto da nota, for igual ao código de um produto no sistema o mesmo já virá pré-selecionado.',
    xmlProductsDialogUnitConversionLabel: 'Unidade de conversão',
    xmlProductsDialogUnitConversionTitle: 'Unidade de conversão / Unidades',
    xmlProductsDialogProductsTitle: 'Produtos',
    xmlProductsDialogParagraph2: `Se a unidade estiver classificada como desconhecida, isso indica que nosso sistema não conseguiu interpretar 
    a unidade mencionada no XML. Para corrigir, basta selecionar o preço por unidade (como unidades, caixas ou fardos) e 
    indicar quantas unidades compõem essa medida de conversão (por exemplo, unidade = 1, caixa = 10, fardo = 6).<br>
    Observação: a quantidade informada não aceita número decimal.`,
    xmlProductsDialogParagraph3: 'Quantidade total do produto: ',
    xmlProductsDialogHelp3: 'Quantidade total do produto',
    xmlProductsDialogParagraph4: `Após inserir os dados de quantidade e custo, nosso sistema realizará automaticamente o cálculo do total de unidades 
    o preço unitário correspondente. Por exemplo, ao inserir duas caixas, cada uma contendo 10 unidades, ao custo de 
    10 reais por caixa: Total de unidades: 20 | Preço unitário: R$ 1,00. Essa funcionalidade simplifica a visualização do 
    total de produtos e seu custo por unidade, tornando mais fácil a análise das informações.`,
    xmlProductsDialogRegisteredProduct: 'Não tem esse produto Cadastrado?',
    xmlProductsDialogUnitaryValue: 'Valor unitário do produto:',
    xmlProductsDialogClose: 'FECHAR',
    xmlProductsDialogToGoBsck: 'VOLTAR',
    xmlProductsDialogParagraph5: 'Para prosseguir preencha: valor unitário, produto, unidade de conversão, quantidade.',
    xmlProductsDialogRules: 'Apenas números inteiros são aceitos',
    xmlProductsDialogError: 'Erro ao buscar unidades de conversão',
    xmlProductsDialogFeedback: 'Não é possível selecionar um agrupamento',
    xmlProductsDialogProduct: 'Produto',
    xmlProductsDialogProduct2: 'de',
    xmlProductsDialogProductNext: 'PRÓXIMO',
    xmlProductsDialogProductFinish: 'FINALIZAR',
    };