<template>
  <v-card class="mb-16">
    <v-card-title>
      <div class="title">{{ $t('productGroupingsTitle') }}</div>
      <product-groups-help></product-groups-help>
      <v-spacer></v-spacer>

      <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getGroups">
        <v-text-field
          class="mr-2 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="$t('searchGroup')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getGroups()"
          @click:append="options.page = 1; getGroups()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>

      <product-group-dialog
        v-model="itemDialog"
        :edited-index="editedIndex"
        :edited-entry="editedEntry"
        @add="onProductAdded"
        @update="onProductUpdated"
        @error="handleError"
        @image-updated="onImageUpdated"
      ></product-group-dialog>

    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading.groups"
      :headers="headers"
      :items="groups"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="$t('noItemsGroupings')"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
    >
    <template #item.image="{ item }">
      <v-img
        class="py-2"
        v-if="item.imageUrl != null"
        :src="item.imageUrl"
        max-width="150"
        max-height="150"
        contain
      ></v-img>
    </template>
    <template #item.defaultPrice="{ item }">
      {{ $currencyFormatter.format(item.defaultPrice) }}
    </template>
    <template #item.groupId="{ item }" class="justify-center px-0">
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn text icon class="mx-0" v-on="on">
            <v-icon v-if="item.groupId == null">people</v-icon>
            <v-icon v-else>person</v-icon>
          </v-btn>
        </template>
        <span v-if="item.groupId == null">{{ $t('publicGroupings') }}</span>
        <span v-else>{{ $t('groupGroupings') }} {{item.groupId}}</span>
      </v-tooltip>
    </template>
    <template #item.actions="{ item }" class="justify-center px-0">
      <v-btn icon class="mx-0" @click="editItem(item)">
          <v-icon color="info">edit</v-icon>
      </v-btn>
      <v-btn icon class="mx-0" @click.native.stop="confirmRemoval(item)" v-if="!item.isExternal">
          <v-icon color="error">delete</v-icon>
      </v-btn>
    </template>
    </v-data-table>

    <feedback ref="feedback"></feedback>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">{{ $t('confirmRemoveGroup') }} {{removeDialog.item.description}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('cancelGroupings')}}</v-btn>
          <v-btn color="green darken-1" :loading="loading.removeItem" text @click.native="deleteItem(removeDialog.item)">{{ $t('removeGroupings') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import ProductGroupDialog from '@/views/products/ProductGroupDialog';
import ProductGroupsHelp from '@/views/products/ProductGroupsHelp';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';

export default {
  data() {
    return {
      groups: [],
      itemDialog: false,
      editedEntry: {},
      editedIndex: -1,
      headers: [
        { text: this.$t('imageGroupings'), align: 'left', sortable: false, value: 'image' },
        { text: this.$t('idGroupings'), align: 'left', sortable: true, value: 'id' },
        { text: this.$t('codeGroupings'), align: 'left', sortable: true, value: 'code' },
        { text: this.$t('descriptionGroupings'), align: 'left', sortable: true, value: 'description' },
        { text: this.$t('categoryGroupings'), align: 'left', sortable: true, value: 'categoryName' },
        { text: this.$t('defaultPriceGroupings'), align: 'left', sortable: true, value: 'defaultPrice' },
        { text: this.$t('productsCountGroupings'), align: 'left', sortable: true, value: 'productsCount' },
        { text: this.$t('availabilityGroupings'), align: 'left', sortable: true, value: 'groupId' },
        { text: this.$t('actionsGroupings'), align: 'left', sortable: false, value: 'actions' }
      ],
      loading: {
        groups: true,
        removeItem: false,
        newProduct: true
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true
      },
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getGroups();
        }
      },
      deep: true
    },
    itemDialog(isOpen) {
      if (!isOpen) {
        this.editedIndex = -1;
        this.editedEntry = {};
      }
    }
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    getGroups() {
      this.loading.groups = true;
      this.axios.get('api/ProductGroups', { params: toApiPagination(this.options, this.search.text) })
        .then((response) => {
          this.loading.groups = false;
          this.groups = response.data.items;
          this.totalItems = response.data.totalItems;
          this.loading.newProduct = false;
        });
    },
    editItem(item){
      this.editedIndex = this.groups.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.itemDialog = true;
    },
    onProductAdded(product) {
      this.groups.push(product);
      this.editItem(product);
      this.handleSuccess(this.$t('itemSavedSuccessfullyGroupings'));
    },
    onProductUpdated(product) {
      let existingProduct = this.groups.find(p => p.id === product.id);
      if (product) {
        Object.assign(existingProduct, product);
        this.editItem(existingProduct);
      } else {
        this.groups.push(product);
        this.editItem(product);
      }
      this.handleSuccess(this.$t('itemUpdatedSuccessfullyGroupings'));
    },
    onImageUpdated(productId, url) {
      const product = this.groups.find(p => p.id == productId);
      if (product != null) {
        product.imageUrl = url;
      }
      if (this.editedEntry.id == productId) {
        this.editedEntry.imageUrl = url;
      }
    },
    confirmRemoval(item) {
      this.removeDialog.item = item;
      this.removeDialog.enabled = true;
    },
    deleteItem(item) {
      this.loading.removeItem = true;
      this.axios.delete(`api/ProductGroups/${item.id}`)
        .then(() => {
          const entryIndex = this.groups.indexOf(item);
          this.groups.splice(entryIndex, 1);
          this.handleSuccess(this.$t('itemRemovedGroupings'));
        }).catch((error) => this.handleError(this.$t('errorRemovingItemGroupings'), error))
          .then(() => {
            this.loading.removeItem = false;
          });
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.removeDialog.enabled = false;
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    }
  },
  components: {
    ProductGroupDialog,
    ProductGroupsHelp
  }
}
</script>
