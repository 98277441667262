export default {
    revenueMonthcardTitle: 'Crescimento',
    revenueMonthcardSubtitle: 'Veja a variação mensal entre este ano e o anterior',
    accumulatedRevenue: 'Faturamento acumulado',
    higherRevenue: 'Maior faturamento',
    MonthCard: 'Mês de',
    YearCard: 'Ano de',
    salesLegend: 'Vendas ',
    revenueMonthHelp:`
    <p style="text-align: justify;">
        Este indicador apresenta o faturamento mensal atual em comparação com o do ano anterior, proporcionando uma visualização clara e 
        detalhada do desempenho ao longo do tempo. Ele exibe duas colunas de dados: uma para o ano atual e outra para o ano anterior, 
        facilitando a identificação de variações mensais, tendências e sazonalidades.
    </p>
    <p style="text-align: justify;">
        Comparar os valores mensais entre os anos permite uma análise da evolução do desempenho, 
        auxiliando na tomada de decisões e no planejamento.
    </p>`
}    