import { formatCurrencyReal } from '@/utility/TextFormatting';
import { Scatter } from 'vue-chartjs'

export default {
  extends: Scatter,
  props: {
    datasets: { type: Array, required: true },
    title: { type: String, default: 'Vendas por Local'},
    labelX: { type: String, default: '' },
    labelY: { type: String, default: '' },
    FormatY: { type: String, default: 'currency' },
  },
  mounted () {
    const tooltipX = this.labelX;
    const tooltipY = this.labelY;
    const formatAxisY = this.FormatY;
    this.renderChart({
      datasets: this.datasets,
      },{
        title: {
          display: true,
          text: this.title,
          fontSize: 16,
          padding: 30,
          fontColor: '#333'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const datasetIndex = tooltipItem.datasetIndex;
              const index = tooltipItem.index;
              const value = data.datasets[datasetIndex].data[index];

              let formattedValue = value.y;
              if (formatAxisY === 'currency') {
                formattedValue = formatCurrencyReal(value.y);
              }

              let tooltip = tooltipX + ': ' + value.x + ' | ' + tooltipY + ': ' + formattedValue;
    
              return tooltip;
            }
          }
        },
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.labelX
              },
            },
          ],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.labelY
              },
              ticks: {
                callback: function(value) {
                  if(formatAxisY == 'currency'){
                    return formatCurrencyReal(value);
                  }
                  else{
                    return value;
                  }
                }
              }
            }],
          }
      });
  },
}
