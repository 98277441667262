<template>
  <v-expansion-panels v-model="panel" hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <div>
          <v-icon>filter_list</v-icon>
          Filtros e ações
        </div>
        <v-spacer></v-spacer>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="pb-2" eager>
        <v-form ref="form" v-model="valid">

          <!-- Filters Row -->
          <v-row class="pt-5">

            <!-- 1st Column-->
            <v-col cols="12" md="3">
              <interval-date-picker
                  :interval-max-range=31
                  :interval-start-range=7
                  :new-max-date="IntervalDatePicker.newMaxDate"
                  :new-min-date="IntervalDatePicker.newMinDate"
                  :reset-date="IntervalDatePicker.reset"
                  @intervalDateUpdated="onIntervalDateUpdated"
              ></interval-date-picker>
            </v-col>

            <!-- 2nd Column -->
            <v-col cols="12" md="3">
              <inventories-drop-down
                  v-model="form.inventoryIds"
                  :inventoryTypeFilter="inventoryTypeFilter"
                  :multiple="true"
              ></inventories-drop-down>
              <products-filter
                  ref="productDropDown"
                  v-model="form.productId"
                  :required="false"
                  icon='shopping_cart'
              ></products-filter>
            </v-col>

            <!-- 3rd Column -->
            <v-col class="search-field px-2" cols="12" md="3">
              <v-row class="ma-0">
                <p class="search-field my-0">
                  Tipo de estoque:
                </p>
              </v-row>
              <v-row class="ma-0">
                <v-chip-group
                    v-model="form.inventoryType"
                    active-class="deep-purple--text text--accent-4"
                    column
                    mandatory
                >
                  <v-chip
                      v-for="(type, index) in inventoryTypes"
                      :key="index"
                      :value="type.value"
                  >
                    {{ type.text }}
                  </v-chip>
                </v-chip-group>
              </v-row>
            </v-col>

          </v-row>

          <!-- Buttons Row -->
          <v-row class="pa-2" justify="end">
            <v-btn
                class="ma-1"
                color="error"
                text
                @click.native="clearQuery"
            >
              <v-icon left>clear</v-icon>
              Limpar
            </v-btn>

            <v-btn
                :disabled="!valid"
                :loading="loading.excel"
                class="ma-1"
                color="info"
                @click.native="getExcel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Excel
            </v-btn>

            <v-btn
                :disabled="!valid"
                class="ma-1"
                color="success"
                v-on:click.native="emitModification"
            >
              <v-icon left>refresh</v-icon>
              Atualizar
            </v-btn>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import download from '@/components/Download';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import ProductsFilter from "@/components/filters/ProductsFilter";
import IntervalDatePicker from "@/components/filters/IntervalDatePicker";

export default {
  components: {
    IntervalDatePicker,
    ProductsFilter,
    InventoriesDropDown
  },
  props: {
    filtersFromParent: {
      type: Object, default: () => {
      }
    }
  },
  data() {
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        inventoryIds: [],
        productId: null,
        inventoryType: 'distributionCenter'
      },
      loading: {
        excel: false
      },
      IntervalDatePicker: {
        reset: false,
        newMinDate: null,
        newMaxDate: null
      },
      panel: undefined,
      inventoryTypes: [
        {text: "Central", value: "distributionCenter"},
        {text: "PDV", value: "pointOfSale"},
      ],
    };
  },
  mounted() {
    this.emitModification();
  },
  computed: {
    inventoryTypeFilter() {
      if (this.form.inventoryType === this.inventoryType) {
        return null;
      } else return this.form.inventoryType;
    }
  },
  watch: {
    filtersFromParent: {
      handler(newValue) {
        if (Object.keys(newValue).length !== 0) {
          this.panel = 0;
          Object.assign(this.form, newValue);
          this.updateChildren(newValue);
          this.emitModification();
        }
      },
      immediate: true
    }
  },
  methods: {
    getExcel() {
      if (this.$refs.form.validate()) {
        this.loading.excel = true;
        this.axios
            .get('api/ruptures/occurrences/excel', {params: this.form, responseType: 'blob'})
            .then((response) => {
              this.loading.excel = false;
              download(response);
            }).catch(() => {
          this.loading.excel = false;
        });
      }
    },
    emitModification() {
      //if (this.$refs.form.validate()) {
        this.$emit("modify", this.form);
      //}
    },
    updateChildren(newValue) {
      this.IntervalDatePicker.newMinDate = newValue.minDate;
      this.IntervalDatePicker.newMaxDate = newValue.maxDate;
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        inventoryIds: [],
        productId: null,
        inventoryType: 'distributionCenter'
      };
    },
    clearQuery() {
      this.form = this.newForm();
      this.IntervalDatePicker.reset = true;
      this.emitModification();
    },
    onIntervalDateUpdated(minDate, maxDate, resetDate) {
      if (resetDate) {
        this.IntervalDatePicker.reset = false;
      }
      this.form.minDate = minDate;
      this.form.maxDate = maxDate;
    },
  },
};
</script>
