<template>
  <v-dialog
    persistent
    max-width="800"
    v-model="itemDialog"
    @keydown.esc="itemDialog = false"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        v-on="dialog"
        color="success"
        class="ml-2"
      >
        <v-icon left>add</v-icon>Novo Cenário Fiscal
      </v-btn>
    </template>

    <v-card>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="saveItem"
      >
        <v-card-title>
          <span class="title">{{ dialogTitle }}</span>
          <!-- HELP GOES HERE as a omponent-->
        </v-card-title>

        <!-- 1st Row name, Tax region-->
        <v-row class="ma-2">
          <v-col cols="12" md="6">
            <v-text-field
              label="Name"
              v-model="editedEntry.name"
              :rules="[$validations.commonCharactersExpanded, $validations.required, rules.maxCharacters(30)]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <crud-select
              icon="mdi-map-marker-circle"
              :label="$t('taxRegion')"
              api-url="api/FiscalRegion"
              v-model="editedEntry.fiscalRegionId"
              :rules="[rules.nonEmpty]"
              item-text="name"
              item-value="id"
              ></crud-select>
          </v-col>
        </v-row>
     
        <!-- 2nd Row Contry Tax, State Tax, County Tax-->
        <v-row class="ma-2" >
          <v-col cols="12" md="4">
            <v-text-field
              label="Country Tax (%)"
              placeholder="0.00"
              v-model="editedEntry.countryTax"
              :rules="[$validations.float, $validations.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="State Tax (%)"
              placeholder="0.00"
              v-model="editedEntry.stateTax"
              :rules="[$validations.float, $validations.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
          <v-text-field
              label="County Tax (%)"
              placeholder="0.00"
              v-model="editedEntry.countyTax"
              :rules="[$validations.float, $validations.required]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="primary"
            @click.native="itemDialog = false"
            >
              Fechar
            </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid"
            :loading="loading.newItem"
            >
              Salvar
            </v-btn>
        </v-card-actions>

      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>

import BrazilStates from "@/components/BrazilStates";
import { Taxations } from '@/constants/Taxations';
import CrudSelect from "@/components/CrudSelect";
import agent from '@/api/agent';

  export default {
    components: {
      CrudSelect,
    },
    props: {
      value: { type: Boolean, required: true },
      editedIndex: { type: Number, required: true },
      editedEntry: { type: Object },
    },
    data() {
      return {
        rules: {
          maxCharacters(max) {
            return v => (v || '').length <= max 
            || `Limite de ${max} caracteres.`;
          },
          nonEmpty: (v) => 
            /\S/.test(v) || "Insira algum nome válido",
        },
        valid: true,
        loading: {
          newItem: false,
        },
        states: BrazilStates.states,
      };
    },
    computed: {
      itemDialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      dialogTitle() {
        return this.editedIndex === -1 
          ? "Novo cenário fiscal" 
          : "Editar cenário fiscal";
      },
      taxationTypes: function() {
        return Object.keys(Taxations)
          .map(k => ({ name: Taxations[k], value: k }));
    }
    },
    watch: {
      itemDialog(isOpen) {
        if (!isOpen) {
          this.$refs.form.reset();
          this.valid = true;
        }
      },
    },
    methods: {
      async saveItem() {
        if (this.$refs.form.validate()) {
          this.loading.newItem = true;
          const editedIndex = this.editedIndex;
          const editedEntry = this.editedEntry;
          if (editedIndex === -1) {
            try {
              const response = await agent.Taxings.postUsaTax(editedEntry)
              this.$emit("add", response)
              this.loading.newItem = false
              this.itemDialog = false
            }
            catch (error) {
              this.$emit("error", "Erro ao salvar cenário fiscal", error)
              this.loading.newItem = false
            }
          } 
          else {
            try {
              const response = await agent.Taxings.putUsaTax(editedEntry.id, editedEntry)
              this.$emit("add", response)
              this.loading.newItem = false
              this.itemDialog = false
            }
            catch (error) {
              this.$emit("error", "Erro ao salvar cenário fiscal", error)
              this.loading.newItem = false
            }
          }
        }
      },
    },
  };
</script>
