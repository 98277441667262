export default {
    creditPaymentMethods: 'Crédito',
    debitPaymentMethods: 'Débito',
    voucherPaymentMethods: 'Vale',
    remoteCreditPaymentMethods: 'Crédito remoto',
    cashPaymentMethods: 'Efectivo',
    appSalePaymentMethods: 'Muestra',
    picPayPaymentMethods: 'PicPay',
    freePaymentMethods: 'Gratis',
    externalAppPaymentMethods: 'Aplicación externa',
    appCreditCardPaymentMethods: 'Tarjeta de crédito en la aplicación',
    pixPaymentMethods: 'PIX',
    cashlessPaymentMethods: 'Sin efectivo',
    othersPaymentMethods:'Otros',
    qrcodePaymentMethods: 'Código QR',
}
