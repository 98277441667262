<template>
  <span>
    <slot :loading="loading" :download="download">
    </slot>
  </span>
</template>

<script>
import download, { downloadFromUrl } from '@/components/Download';
export default {
  props: {
    url: { type: String, default: null },
    queryParams: { type: Object, default: null },
    filename: { type: String, default: null },
    returnsLink: { type: Boolean, default: false },
    methods: {type: String, default: "GET"}
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    download() {
      this.loading = true;
      if (this.returnsLink) this.getFileLink();
      else this.downloadFile();
    },
    downloadFile() {
      this.axios.get(this.url, { params: this.queryParams, responseType: 'blob' })
        .then(response => download(response, this.filename))
        .catch(error => {
          let errorString = "Erro";
          error.response.data.text()
            .then(result => errorString = result)
            .catch(() => errorString = "Erro ilegível")
            .then(() => this.$emit('error', errorString))
        })
        .then(() => this.loading = false);
    },
    getFileLink() {
      const promise = this.methods == "POST" ?  this.axios.post(this.url, this.queryParams) : this.axios.get(this.url, { params: this.queryParams});

      promise
        .then(response => downloadFromUrl(response.data))
        .catch(error => this.$emit('error', error))
        .then(() => this.loading = false);
    }
  }
}
</script>
