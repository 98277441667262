
import Vue from 'vue';
import { formatDateTimeMinutes } from '@/utility/TextFormatting'
// eslint-disable-next-line no-unused-vars
import {RuptureOccurrenceFilters, RupturePointOfSale } from '@/types/Rupture'
import RupturesPointOfSalesHelp from './helpers/RupturesPointOfSalesHelp.vue'
import RupturesPointOfSalesFilters from './components/RupturesPointOfSalesFilters.vue';

export default Vue.extend({
  components: {RupturesPointOfSalesHelp, RupturesPointOfSalesFilters},
  data() {
    const $currencyFormatter = (this as any).$currencyFormatter;
    return{
      rupturePointOfSales: [] as RupturePointOfSale[] | undefined,
      totals: {
        ocurrences: 0,
        salesLosses: 0,
        profitLosses: 0
      },
      headers: [
        { text: 'Informações PDV', align: 'left', sortable: true, value: 'pointOfSaleInfo' },
        { text: 'Ocorrências', align: 'left', sortable: true, filterable: false, value: 'ruptureOccurrences' },
        { text: 'Total Tempo(Dias)', align: 'left', sortable: true, filterable: false, value: 'totalRuptureDays'},
        { text: 'Total Perdas(Un.)', align: 'left', sortable: true, filterable: false, value: 'totalSalesLost'},
        { text: `Total Perdas(${$currencyFormatter.getSign()})`, align: 'left', sortable: true, filterable: false, value: 'totalProfitLoss'},
        { text: 'Ações', align: 'center', sortable: false, filterable: false, value: 'actions' }
      ],
      loading: {
        rupturePointOfSales: true,
      },
      form: {}  as RuptureOccurrenceFilters | undefined,
      search: '',
    }
  },
  methods: {
    updateFilters(form: any) {
      this.form = form;
      this.getRupturePointOfSales();
    },
    getRupturePointOfSales() {
    this.loading.rupturePointOfSales = true;
    this.axios
      .get('api/ruptures/pointofsales', { params: this.form })
      .then((response) => {
        this.loading.rupturePointOfSales = false;
        this.rupturePointOfSales = response.data.ruptureItems;
        this.totals.ocurrences = response.data.totalOcurrences;
        this.totals.salesLosses = response.data.totalLossesSales;
        this.totals.profitLosses = response.data.totalLossesProfit;
      });
    },
    formatDateTimeMinutes,
    goToItemOcurrences(item: RupturePointOfSale) {
      const filters = {
        inventoryIds: item.inventoryId,
        inventoryType: 'pointOfSale',
        productId: null,
        minDate: this.form?.minDate,
        maxDate: this.form?.maxDate
      }
      // This emit will set a prop in parent RupturesMaster, wich will, in turn,
      // send the filters informations to it's childs components via props.
      this.$emit('goToOcurrences', filters);
    }
  },
});
