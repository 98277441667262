
import Vue from 'vue';
import agent from '@/api/agent';
import { Category } from '@/types/ProductsTypes';

export default Vue.extend({
  props: {
    resetComponent: {},
    value: {},
    rules: { type: Array ,default: () => [] },
    prependIcon: { type: String, default: "mdi-shape" },
    multiple: { type: Boolean, default: false },
    chips: { type: Boolean, default: false }
  },
  data() {
    return {
      categories: [] as Category[],
      params: {} as any,
      description:"" as string,
    };
  },
  computed: {
    productCategoryId: {
      get() : number | null {
        return (this as any).value;
      },
      set(value : number | null) : void{
        (this as any).$emit("input", value);
      }
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.params = { page: 1, pageSize: -1, sortOrder: "Id", descending: false };

      agent.Products.getProductsCategoryDetails(this.params).then((response) => {
        const category = response.items.map((categories) => {
          categories.description = categories.id + " - " + categories.name;
          return categories;
        });
        this.categories = category;
      });
    },
    resetDropDown() {
      this.productCategoryId = null;
    }
  }

});
