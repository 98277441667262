export default {
  connectivity: {
    title: 'Conectividade',
    help: {
      description: `<p>Na aba <strong>Conectividade</strong>, você pode monitorar o status de conexão de cada PDV. 
      A página exibe o nome do PDV e detalha quanto tempo ele permaneceu conectado via Wi-Fi, chip de dados ou quando estava fora de conexão. 
      Isso permite avaliar a estabilidade da conexão dos terminais com a internet, essencial para a realização das vendas.</p>
      <p>Na aba <strong>Linha do Tempo</strong>, você encontra uma visualização gráfica que ilustra a conectividade do PDV ao longo do tempo. 
      Com essa visualização, é possível acompanhar a conectividade e garantir que o PDV permaneça sempre em funcionamento.</p>`
    },
    timeline: {
      title: 'Linha do tempo',
      chart: {
        title: 'Linha do tempo de conectividade',
        xAxis: 'Data e hora',
        yAxis: 'Medições dos Pontos de Venda',
      },
      help: {
        description: `<p align="justify">Nesse gráfico é mostrada a conectividade de pontos de venda durante um período determinado.</p>
          <p align="justify">A conectividade é registrada a partir da telemetria de um ponto de venda.
            Cada ponto no gráfico representa uma telemetria realizada. As telemetrias devem ocorrer de 
            cinco em cinco minutos. Se dois pontos sucessivos tiverem mais de cinco minutos de intervalo entre um
            e o outro, é porque houve um problema de conexão durante esse período.</p>
          <p align="justify">Em "<strong>Data</strong>" você escolhe qual o dia que quer ver das telemetrias de um ponto de venda.</p>
          <p align="justify">Em "<strong>Ponto de Venda</strong>" você escolhe de qual dos seus pontos de venda você quer analisar as telemetrias.</p>`
      }
    },
    errors: {
      loadConnectivity: 'Não foi possível carregar a conectividade dos PDVs',
      loadTimeline: 'Não foi possível carregar a linha do tempo dos PDVs',
      excel: 'Não foi possível fazer o download do arquivo, tente novamente',
    }
  },
}