<template>
  <v-layout wrap>
    <suspicious-transactions-filters
      v-on:modify="update"
      @show-profits-changed="showProfits = $event"
    ></suspicious-transactions-filters>
    <v-flex xs12>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 mb-16"
        must-sort
        :no-data-text="$t('tables.noData')"
        :expanded.sync="expanded"
        show-expand
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
      >
        <template #[`header.data-table-expand`]>
          <v-btn icon>
            <v-icon small @click.native="onExpandClick">{{ expandAllIcon }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.result`]="{ item }">
          <v-tooltip right>
            <template #activator="{ on }">
              <v-icon small :color="getResultColor(item.result)" v-on="on">
                {{ getResultIcon(item.result) }}
              </v-icon>
            </template>
            <span>{{ getResultDescription(item.result) }}</span>
          </v-tooltip>
        </template>
        <template #[`item.paymentAmount`]="{ item }">{{ $currencyFormatter.format(item.paymentAmount)}}</template>
        <template #[`item.cardHolder`]="{ item }">{{ formatCardHolder(item.cardHolder) }}</template>
        <template #[`item.date`]="{ item }">{{ formatDateTimeSeconds(item.date) }}</template>
        <template #[`item.verificationStatus`]="{ item }">
          <v-chip :color="getStatusColor(item.verificationStatus)" dark>{{ formatStatus(item.verificationStatus) }}</v-chip>
        </template>
        <template #[`item.actions`]="{ item }" class="justify-center px-0">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 1)" v-on="on"
                v-bind:disabled="item.verificationStatus == 'Opened' ? false : true">
                <v-icon color="success">mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.verifiedOk') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 2)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'Opened' ? false : true">
                <v-icon color="error">mdi-alert</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.deviation') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 3)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'HasDeviations' ? false : true">
                <v-icon color="success">mdi-cash-multiple</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.refunded') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="handleClick(item.uuid, 4)" v-on="on"
              v-bind:disabled="item.verificationStatus == 'HasDeviations' ? false : true">
                <v-icon color="error">mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.resultDeviationStatus.lost') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon class="mx-0" @click="action(item)" v-on="on">
                <v-icon small :color="getActionsColor(item.available)">
                  {{ getActionsIcon(item.available, item.url) }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('security.deviationAlert.videoAlerts.videos') }}</span>
          </v-tooltip>
        </template>

        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 pa-0">

            <v-simple-table class="details-table blue-grey lighten-5 elevation-0">
              <tbody>
                <tr>
                  <td class="caption"><strong>{{ $t('transactions.errorMessage') }}:</strong> {{ item.errorMessage || $t('tables.none') }}</td>
                  <td class="caption"><strong>{{ $t('transactions.internalCode') }}:</strong> {{ item.uuid }}</td>
                  <td class="caption"><strong>{{ $t('transactions.discountCoupon') }}:</strong> {{ item.discountCode || $t('tables.none') }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table>
              <thead class="blue-grey lighten-5 elevation-0">
                <tr>
                  <th>{{ $t('tables.headers.productCode') }}</th>
                  <th>{{ $t('tables.headers.product') }}</th>
                  <th>{{ $t('tables.headers.productCategory') }}</th>
                  <th>{{ $t('tables.headers.quantity') }}</th>
                  <th v-if="canViewProfits">{{ $t('tables.headers.cmv') }} ({{ $currencyFormatter.getSign() }})</th>
                  <th>{{ $t('tables.headers.price') }} ({{$currencyFormatter.getSign()}})</th>
                  <th v-if="canViewProfits">{{ $t('tables.headers.margin') }} ({{$currencyFormatter.getSign()}})</th>
                  <th v-if="canViewProfits">{{ $t('tables.headers.margin') }} (%)</th>
                  <th>{{ $t('tables.headers.selection') }}</th>
                </tr>
              </thead>
              <tbody class="blue-grey lighten-5">
                <tr v-for="product in item.items" :key="product.productDescription">
                  <td>{{ product.productCode }}</td>
                  <td>{{ product.productDescription }}</td>
                  <td>{{ product.productCategoryName }}</td>
                  <td>{{ product.quantity }}</td>
                  <td v-if="canViewProfits">{{ $currencyFormatter.format(product.costOfSale) }}</td>
                  <td>{{ $currencyFormatter.format(product.discountedPrice) }}</td>
                  <td v-if="canViewProfits">{{ $currencyFormatter.format(product.profits) }}</td>
                  <td v-if="canViewProfits">{{ formatPercentage(product.profitMargins) }}</td>
                  <td>{{ product.selection }}</td>
                </tr>
                <tr class="red lighten-4" v-for="product in item.subtractedItems" :key="product.productDescription">
                  <td>{{ product.productCode }}</td>
                  <td>{{ product.productDescription }}</td>
                  <td>{{ product.productCategoryName }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>-</td>
                  <td>{{ $currencyFormatter.format(product.totalPrice)}}</td>
                  <td>-</td>
                  <td>-</td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import SuspiciousTransactionsFilters from './components/SuspiciousTransactionsFilters.vue';
import { formatDateTimeSeconds, formatPercentage, formatCardHolder } from '@/utility/TextFormatting'
import {REMOTE_MONITORING_ROLE} from '@/constants/Roles';

export default {
  props: {
    activeTab: { type: Number, default: 0 },
    customer: { type: Number, default: null }
  },
  data() {
    const $currencyFormatter = this.$currencyFormatter;
    return {
      headers: [
        {text: this.$t('tables.headers.result'), align: 'left', sortable: true, value: 'result' },
        { text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: true, value: 'pointOfSaleId' },
        { text: this.$t('tables.headers.customer'), align: 'left', sortable: false, value: 'pointOfSaleLocalCustomerName' },
        { text: this.$t('tables.headers.location'), align: 'left', sortable: false, value: 'pointOfSaleLocalName' },
        { text: `${this.$t('tables.headers.value')} (${$currencyFormatter.getSign()})`, align: 'left', sortable: true, value: 'paymentAmount' },
        { text: this.$t('tables.headers.card'), align: 'left', sortable: true, value: 'cardHolder' },
        { text: this.$t('tables.headers.dateHour'), align: 'left', sortable: true, value: 'date' },
        { text: this.$t('tables.headers.verificationStatus'), align: 'left', sortable: true, value: 'verificationStatus' },
        { text: this.$t('tables.headers.actions'), align: 'left', sortable: false, value: 'actions' }
      ],
      items: [],
      showProfits: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
      },
      totalItems: 0,
      expanded: [],
      form: {},
      shouldUpdate: false,
      loading: false,
      updateInterval: null,
      intervalTime: 15000
    }
  },
  computed: {
    isAllExpanded() {
      return this.items && this.items.length > 0 && this.expanded.length === this.items.length;
    },
    expandAllIcon() {
      return this.isAllExpanded ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'
    },
    updateChange() {
      return (this.shouldUpdate == true) && (this.activeTab == 0);
    },
    userIsRemoteMonitoring(){
      return this.$auth.check(REMOTE_MONITORING_ROLE);
    },
    canViewProfits() {
      return this.showProfits && !this.userIsRemoteMonitoring;
    },
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          const wasAllExpanded = this.isAllExpanded;
          this.getSuspiciousTransactions();
          setTimeout(() => {
            this.expanded = wasAllExpanded ? this.items : [];
          }, 700);
        }
      },
      deep: true
    },
    updateChange(hasToUpdate) {
      if (hasToUpdate == true) {
        this.getSuspiciousTransactions();
      }
    }
  },
  methods: {
    update(form) {
      this.form = form;
      this.options.page = 1;
      this.setUpdateInterval();
      const wasAllExpanded = this.isAllExpanded;
      this.getSuspiciousTransactions();
      setTimeout(() => {
        this.expanded = wasAllExpanded ? this.items : [];
      }, 700);
    },
    updateByAction(form){
      this.form = form;
      this.setUpdateInterval();
      this.getSuspiciousTransactions();
    },
    getSuspiciousTransactions() {
      this.loading = true;
      const paginationParams = toApiPagination(this.options);
      const params = { ...paginationParams, ...this.form };
      this.axios.get('api/Suspicious/transactions', { params })
        .then((response) => {
          this.items = response.data.items;
          this.totalItems = response.data.totalItems;
          this.shouldUpdate = false;
          this.items.forEach(i =>  {
            i.available = false;
            i.url = "";
            }
          );
          this.getTransactionsVideos();
          this.loading = false;
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
          this.loading = false;
        })
    },
    getTransactionsVideos() {
      this.axios.get(`api/Video/Transactions`)
        .then((response) => {
          response.data.forEach(video => {
            const index = this.items.findIndex(i => video.fileName.includes(i.uuid));
            if (index >= 0) {
              const item = this.items[index];
              item.available = true;
              item.url = video.url;
              this.$set(this.items, index, item);
            }
          });
        })
        .catch((error) => {
          this.sendFeedBack('error', error);
        })
        .then(() => {});
    },
    setUpdateInterval() {
      if (this.updateInterval) clearInterval(this.updateInterval);

      this.updateInterval = setInterval(() => {
        if (this.activeTab == 0) this.shouldUpdate = true;
      }, this.intervalTime);
    },
    onExpandClick() {
      this.expanded = this.isAllExpanded ? [] : this.items;
    },
    getActionsIcon(available, url) {
      if (available && url) {
        return 'mdi-video';
      } else {
        return 'mdi-download';
      }
    },
    getActionsColor(available) {
      if (available) {
        return 'blue darken-2';
      } else {
        return 'green darken-2';
      }
    },
    action(item) {
      if (item.available) {
        window.open(item.url, '_blank');
      } else {
        this.downloadVideo(item);
      }
    },
    getResultIcon(result) {
      if (result == 'Ok') {
        return 'mdi-check-circle';

      } else if( result == 'Cancelled') {
        return 'mdi-minus-circle';
      }else {
        return 'mdi-close-circle';
      }
    },
    getResultColor(result) {
      if (result == 'Ok' ) {
        return 'green darken-2';
      } else {
        return 'red';
      }
    },
    getResultDescription(result) {
      if (result == 'Cancelled') {
        return this.$t('transactions.status.cancelled');
      } else if (result == 'Ok') {
        return this.$t('transactions.status.paid');
      } else {
        return this.$t('transactions.status.paymentError');
      }
    },
    getStatusColor (status) {
      if (status == 'Opened') return 'orange lighten-1'
      else if (status == 'Verified') return 'green'
      else if (status == 'HasDeviations') return 'red lighten-1'
      else if (status == 'Lost') return 'grey darken-1'
      else return 'blue'
    },
    formatStatus(oldStatus) {
      let newStatus = ""
      if (oldStatus == 'Opened') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.pending')
      else if (oldStatus == 'Verified') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.verifiedOk')
      else if (oldStatus == 'HasDeviations') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.deviation')
      else if (oldStatus == 'Refunded') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.refunded')
      else if (oldStatus == 'Lost') newStatus = this.$t('security.deviationAlert.resultDeviationStatus.lost')

      return newStatus
    },
    handleClick(uuid, action) {
      let newStatus = 'Opened';
      if (action == 1) {
        newStatus = 'Verified';
      } else if (action == 2){
        newStatus = 'HasDeviations';
      } else if (action == 3) {
        newStatus = 'Refunded';
      } else if (action == 4) {
        newStatus = 'Lost';
      }
      this.axios.put(`api/Suspicious/transactions/${uuid}/Status?newStatus=${newStatus}`)
      .then(() => {
        const message = this.$t('security.deviationAlert.messages.success.updateStatusTransaction');
        this.sendFeedBack('success', message);
        this.updateByAction(this.form)
      }).catch((error) => this.sendFeedBack('error', error));
    },
    downloadVideo(item) {
      const timeSpan = 180;
      let startTime = new Date(item.date);
      let endTime = new Date(item.date);
      let params = {
        startTime: new Date(startTime.setSeconds(startTime.getSeconds() - timeSpan)),
        endTime: new Date(endTime.setSeconds(startTime.getSeconds() + timeSpan))
      }
      this.axios.get(`api/Video/${item.pointOfSaleId}/${item.uuid + ".dav"}`, { params })
      .then(() => {
        const message = this.$t('security.deviationAlert.messages.success.downloadRealizeSuccess');
        this.sendFeedBack('success', message);
      })
      .catch((error) => {
        this.sendFeedBack('error', error);
      })
    },
    sendFeedBack(type, content) {
      this.$emit('feedbackSent', {
        type: type,
        content: content
      });
    },
    formatDateTimeSeconds,
    formatPercentage,
    formatCardHolder
  },
  components: {
    SuspiciousTransactionsFilters
  }
}
</script>

<style scoped>
  .details-table {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .details-table tbody tr td {
    border:none !important;
    height: 24px !important;
  }
</style>
