
import Vue from 'vue';
import CouponDiscountFilter from '@/discounts/filter/CouponDiscountFilter.vue';
import { Discount, DiscountFilters} from '@/types/Discounts';
import {typeDiscountTranslation} from '@/constants/TypeDiscount';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import agent from '@/api/agent';
import stepsDialog from './StepsDialog.vue';
import CouponsDialog from './CouponsDialog.vue';
import AddDiscountButton from "@/components/buttons/AddCustomButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import NewCouponDialog from './NewCouponDialog.vue';


interface TypeDiscountTranslation {
  [key: string]: string;
}

export default Vue.extend({
  data() {
    return {
      headers: [
        { text: this.$t('nameDiscount'), value: 'description', align: 'left', sortable: true },
        { text: this.$t('couponsVinculated'), value: 'qtdCoupons', align: 'left', sortable: false },
        { text: this.$t('usageLimit'), value: 'usageLimit', align: 'left', sortable: true },
        { text: this.$t('discountType'), value: 'type', align: 'left', sortable: true },
        { text: this.$t('condition'), value: 'minThreshold', align: 'left', sortable: true },
        { text: this.$t('creatingTheDiscount'), value: 'dateCreated', align: 'left', sortable: true,  },
        { text: this.$t('actions'), value: 'actions', align: 'left', sortable: false },
      ],
      discounts: [] as Discount[],
      loading: {
        table: true as Boolean,
      },
      search: {
        valid: true,
        text: '' as string,
      },
      removeDialog: {
        discount: {} as Discount,
        enabled: false,
        loading: false
      },
      dialogs:{
        stepsDialog: false as Boolean,
      },
      newCouponDialog: {
        enabled: false,
        discount:  null as number | null 
      },
      couponsDialog: {
        enabled: false,
        discount: null as Discount | null
      },
      typeDiscountTranslation: typeDiscountTranslation as TypeDiscountTranslation,
      form: {} as DiscountFilters,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["dateCreated"],
        sortDesc: [false],
        mustSort: true,
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getDiscounts();
        }
      },
      deep: true
    },
  },
  methods: {
    getDiscounts() {
      this.loading.table= true;
      const params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params);

      agent.Discounts.getDiscounts(params)
        .then((response) => {
          this.discounts = response.items;
          this.totalItems = response.totalItems;
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    deleteDiscount(discount: Discount) {
      this.removeDialog.loading = true;
      agent.Discounts.deleteDiscount(discount.id)
        .then(() => {
          (this as any).$refs.feedback.handleSuccess(this.$t('removedDiscountSuccess'));
          const index = this.discounts.indexOf(discount);
          this.discounts.splice(index, 1);
        })
        .catch(error => {
          (this as any).$refs.feedback.handleError(this.$t('removedDiscountError'), error);
        })
        .then(() => {
          this.removeDialog.loading = false;
          this.removeDialog.enabled = false;
        });
    },
    getCouponsText(discount: Discount) {
      const couponCount = discount.coupons.length;
      if (couponCount > 0) return `${couponCount}`;
      return this.$t("noCouponsVinculated");
    },
    confirmRemoval(discount: Discount) {
      this.removeDialog.discount = discount;
      this.removeDialog.enabled = true;
    },
    stepsDialogClosed(value: boolean) {
      this.dialogs.stepsDialog = value;  
      this.getDiscounts();  
    },
    openNewCouponDialog(discountId: number) {
      this.newCouponDialog.discount = discountId;
      this.newCouponDialog.enabled = true;
    },
    updateFilters(form: any) {
      this.form = form;
      this.getDiscounts();
    },
    insertFiltersFromForm(params: any) {
      const form = this.form;
      params.minDate = form.minDate ? this.$moment(form.minDate).format('YYYY-MM-DD') : null;
      params.maxDate = form.maxDate ? this.$moment(form.maxDate).format('YYYY-MM-DD') : null;
      params.type = form.type;
      params.timezoneOffset = new Date().getTimezoneOffset();
      return params;
    },
    formatDateTimeMinutes
  },
  components: {
    AddDiscountButton,
    stepsDialog,
    CouponsDialog,
    IconButton,
    NewCouponDialog,
    CouponDiscountFilter
  },
});
