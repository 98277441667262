import { Scatter } from 'vue-chartjs'
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  extends: Scatter,
  props: {
    timeLines: { type: Array, required: true },
    dateInterval: { type: Array, required: true }
  },
  data() {
    return {
      datasets: [],
      backgroundColors: [
        "#880E4F", // pink-darken-4
        "#3F51B5", // indigo
        "#03A9F4", // light blue
        "#4CAF50", // green
        "#FFC107" // amber
      ],
      dateFormat: 'DD/MM/YYYY'
    }
  },
  computed: {
    country() {
      return this.$auth.user().tenantCountry;
    },
    dateFormatBasedOnRegion() {
      const country = this.country;
      switch (country) {
        case 'US':
          return 'MM/DD/YYYY';
        case 'BR':
          return 'DD/MM/YYYY';
        case 'CO':
          return 'DD/MM/YYYY';
        default:
          return 'DD/MM/YYYY';
      }
    },
  },
  watch: {
    timeLines() {
      this.datasets = this.timeLines.map((timeLine, index) => {
        return {
          label: `${this.$t('filters.labels.pos')} ` + timeLine.posId,
          data: timeLine.telemetries.map(tel => {
            return { x: moment(tel).toDate(), y: index + 1 }
          }),
          backgroundColor: this.backgroundColors[index]
        }
      })

      this.updateChart();
    }
  },
  methods: {
    updateChart() {
      const dateFormat = this.dateFormatBasedOnRegion;

      this.renderChart({
          datasets: this.datasets,
        },
          {
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    parser: 'YYYY-MM-DD HH:mm',
                    unit: 'minute',
                    displayFormats: {
                      minute: dateFormat +' - HH:mm'
                    },
                    tooltipFormat: dateFormat +' - HH:mm'
                  },
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                    min: this.dateInterval[0],
                    max: this.dateInterval[1],
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.$t('connectivity.timeline.chart.xAxis'),
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    display: false,
                    suggestedMin: 0,
                    suggestedMax: this.timeLines.length + 1
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.$t('connectivity.timeline.chart.yAxis')
                  },
                  grids: {
                    display: false
                  },
                },
              ],
            },
            elements: {
              point: {
                radius: 6
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                  return `${datasetLabel}: ${tooltipItem.xLabel}`;
                }
              }
            }
          }
      )
    }
  }
})