export default {
  tenant: {
    group: {
      help: {
        about: {
          title: "Creation of new Groups",
          description: `
          <p>
            In this feature, you can create new Groups.
          </p>
          <ul>
            <li>
              When the new Group creation screen opens, the system searches and suggests the next available ID for the new Group.
            </li>
            <li>
              The <strong>{employerIdentificationType}</strong> field is mandatory. 
              <strong>It is important to note that the completed {employerIdentificationType} will be used for billing purposes</strong>.
            </li>
            <li>
              The <strong>Group Name</strong> field is mandatory and must be between 5 and 60 characters. 
              <strong>It is recommended to use the trade name related to the {employerIdentificationType}</strong>.
            </li>
          </ul>
          `
        },
      },
    }
  },
}