<template>
  <v-autocomplete
    :prepend-icon="prependIcon"
    v-bind:items="pointsOfSale"
    v-model="posValue"
    @input="$emit('input', $event)"
    :loading="loading"
    :label="this.$t('pointOfSaleSelector')"
    item-text="searchText"
    item-value="id"
    :no-data-text="this.$t('noPointOfSaleSelector')"
    :solo-inverted="bold"
    :flat="bold"
    :required="required"
    :disabled="disabled"
    :rules="rules"
    :clearable="clearable"
    :dense="dense"
  ></v-autocomplete>
</template>

<script>
export default {
  computed: {
    posValue: {
      get() {
        return this.value;
      },
      set() {}
    }
  },
  props: {
    value: { type: Number },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rules: { type: Array, default: () => [], required: false },
    filter: { type: Function, default: null },
    bold: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    dense: { type: Boolean, default: false},
    prependIcon: { type: String, default: undefined }
  },
  data() {
    return {
      pointsOfSale: [],
      loading: true
    }
  },
  mounted() {
    this.axios.get("api/PointsOfSale").then(response => {
      this.loading = false;
      this.pointsOfSale = this.filter == null ? response.data : response.data.filter(this.filter);
      this.pointsOfSale.forEach(pos => {
        pos.searchText = `(${this.$t('posSelector')} ${pos.id}) ${pos.localCustomerName} - ${pos.localName}` + 
          (pos.specificLocation != null ? ` - ${pos.specificLocation}` : '');
      });
    });
  }
}
</script>