export default {
    purchaseDetails: 'Purchase',
    purchaseDetailsIdInERP: 'Id in ERP: ',
    purchaseDetailsNoteNumber: 'Note Number: ',
    purchaseDetailsReceiptDate: 'Receipt Date: ',
    purchaseDetailsInventory: 'Inventory: ',
    purchaseDetailsTotalNoteValue: 'Total Note Value: ',
    purchaseDetailsTotalPurchaseCost: 'Total Purchase Cost: ',
    purchaseDetailsQuantity: 'Quantity: ',
    purchaseDetailsUnits: ' units',
    purchaseDetailsSearchProduct: 'Search Product',
    purchaseDetailsUndo: 'Undo',
    purchaseDetailsClose: 'Close',
    purchaseDetailsHeadersID: 'ID',
    purchaseDetailsHeadersProduct: 'Product',
    purchaseDetailsHeadersCategory: 'Category',
    purchaseDetailsHeadersCode: 'Code',
    purchaseDetailsHeadersQuantity: 'Qty.',
    purchaseDetailsHeadersNoteValue: 'Total Note Value ',
    purchaseDetailsHeadersProductTotalCost: 'Product Total Cost ',
    purchaseDetailsErrorFetching: 'Error fetching purchases',
    purchaseDetailsErrorUndo: 'Error undoing purchase',
    purchaseDetailsPurchaseCanceled: 'Purchase canceled',
  };
  