import { defineStore } from 'pinia';
import { Contracts } from '@/api/agent';
import { ref } from 'vue';
import { ContractBillInfoDto } from '@/types/Contract';
import { useAlertsStore } from './alertsStore';


export const useContractsStore = defineStore('contracts', () => {
  const alertsStore = useAlertsStore()

  const midasContract = ref<ContractBillInfoDto>();

  const loadingMidasContract = ref(false);

  async function fetchMidasContract(tenantId: number, groupId: number | null, referenceDateString: string) {
    loadingMidasContract.value = true;
    try {
      const response = await Contracts.getMidasContract(tenantId, groupId, referenceDateString);
      midasContract.value = response;
    } catch (error) {
      alertsStore.showErrorSnackbar((error as any).response.data);
    } finally {
      loadingMidasContract.value = false;
    }
  }

  return {
    midasContract,
    fetchMidasContract,
    loadingMidasContract
  };
})