export default {
  npsDialogQuestionOne: "How likely are you to recommend AMLabs to someone else?",
  npsCategoriesQuestion: "How was your experience?",
  npsLeaveYourFeedback: "Leave your feedback. Share with us how we can improve:",
  npsEaseOfUse: "Easy to Use System",
  npsGoodPerformance: "Great System Performance",
  npsUsefulness: "Functionalities are Adequate",
  npsEffectiveSupport: "Efficient Support Team",
  npsSatisfactoryUpdate: "System Updates are Satisfactory",
  npsOthers: "Others",
  npsHardOfUse: "Difficult to Use System",
  npsUselessness: "Functionalities are Inadequate",
  npsPoorPerformance: "Slow System",
  npsIneffectiveSupport: "Inefficient Support Team",
  npsLimitedUpdates: "System Updates are Limited",
  send: "Send"
}
