
import Vue from 'vue';
import { BuildingManagerReports } from "@/api/agent";
import moment from "moment";
import { formatDateTimeSeconds, formatDateTimeToDate } from "@/utility/TextFormatting";

export default Vue.extend({
  mounted() {
    this.getSalesReport()
  },
  data() {
    const defaultForm = {
      minDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD')
    }
    return {
      valid: false,
      loading: false,
      form: { ...defaultForm },
      sales: [] as {}[],
      headers: [
        { text: this.$t('bManagerSalesPointOfSaleId'), value: 'pointOfSaleId', align: 'left', sortable: true },
        { text: this.$t('bManagerSalesBoxId'), value: 'boxId', align: 'left', sortable: true },
        { text: this.$t('bManagerSalesLocalCustomerName'), value: 'localCustomerName', align: 'left', sortable: false },
        { text: this.$t('bManagerSalesLocalName'), value: 'localName', align: 'left', sortable: false },
        { text: this.$t('bManagerSalesSpecificLocation'), value: 'specificLocation', align: 'left', sortable: false },
        { text: this.$t('bManagerSalesTotal'), value: 'total', align: 'left', sortable: false },
      ],
      total: 0 as number,
      minDateMenu: false,
      maxDateMenu: false,
      today: moment().format('YYYY-MM-DD'),
      sixtyDaysAgo: moment().subtract(60, 'days').format('YYYY-MM-DD'),
      dialog: {
        show: false,
        posId: null as null | number,
        loading: false,
        items: [] as {}[],
        headers: [
          { text: this.$t('categorySalesHeaderCategoryId'), value: 'categoryId', align: 'left', sortable: false },
          { text: this.$t('categorySalesHeaderCategory'), value: 'category', align: 'left', sortable: false },
          { text: this.$t('categorySalesHeaderQuantity'), value: 'quantity', align: 'left', sortable: false },
          { text: this.$t('categorySalesHeaderTotals'), value: 'totals', align: 'left', sortable: false }
        ]
      }
    }
  },
  computed: {
    formattedMinDate() {
      return (this as any).formatDateTimeToDate((this as any).form.minDate)
    },
    formattedMaxDate() {
      return (this as any).formatDateTimeToDate((this as any).form.maxDate)
    }
  },
  methods: {
    async getSalesReport() {
      this.loading = true
      try {
        const response = await BuildingManagerReports.getBuildingManagerSalesReport(this.form)
        this.sales = response.posSales
        this.total = response.totals
      }
      catch(error) {
        this.handleError(error,  this.$t('buildingManagerHandleErrorMessage') as string)
      }
      finally {
        this.loading = false
      }
    },
    async getSalesExcelReport() {
      this.loading = true
      try {
        const response = await BuildingManagerReports.getBuildingManagerExcelReport(this.form)
        this.handleSuccess(response)
      }
      catch(error) {
        this.handleError(error, this.$t('buildingManagerHandleErrorMessage') as string)
      }
      finally {
        this.loading = false
      }
    },
    formatDateTimeSeconds,
    formatDateTimeToDate,
    handleError(error: any, message: string) {
      (this.$refs.feedback as any).handleError(message, error)
    },
    handleSuccess(filename: string) {
      (this.$refs.feedback as any).handleSuccess(this.$t('bManagerExcelDownloadMessage', {filename: filename}))
    },
    openPosCategoryDialog(item: any) {
      this.dialog.show = true
      this.dialog.posId = (item as any).pointOfSaleId
      this.loadCategorySales(this.dialog.posId as number)
    },
    async loadCategorySales(posId: number) {
      this.dialog.loading = true
      try {
        const response = await BuildingManagerReports.getCategorySalesByPos(posId, this.form)
        this.dialog.items = response
      }
      catch(error) {
        this.handleError(error, this.$t('buildingManagerGetCategoriesSalesErrorMessage') as string)
      }
      finally {
        this.dialog.loading = false
      }
    }
  }
})
