export default {
  buildingManagerReportHeader: "Filtros",
  buildingManagerMinDateLabel: "Data inicial",
  buildingManagerMaxDateLabel: "Data final",
  buildingManagerExcel: "Excel",
  buildingManagerUpdate: "Atualizar",
  buildingManagerTableTitle: "Relatório de Vendas",
  bManagerSalesPointOfSaleId: "Id PDV" ,
  bManagerSalesBoxId: "Id Box",
  bManagerSalesLocalCustomerName: "Cliente",
  bManagerSalesLocalName: "Local",
  bManagerSalesSpecificLocation: "Local Específico",
  bManagerSalesTotal: "Vendas",
  buildingManagerHandleErrorMessage: "Houve um erro ao carregar o relatório",
  bManagerExcelDownloadMessage: "O relatório {filename} estará disponível na página de Downloads",
  salesByCategoryDialogTitle: "Categorias mais vendidas do PDV {posId}",
  categorySalesHeaderCategoryId: "Id Categoria",
  categorySalesHeaderCategory: "Categoria",
  categorySalesHeaderQuantity: "Quantidade",
  categorySalesHeaderTotals: "Vendas",
  categorySalesCloseButton: "Fechar",
  buildingManagerGetCategoriesSalesErrorMessage: "Houve um erro ao carregar as vendas por categoria"
}