import { Bar } from 'vue-chartjs'
import Vue from 'vue'

export default Vue.extend({
  extends: Bar,
  props: {
    labels: { type: Array, required: true },
    datasets: { type: Array, required: true },
    barsize:{ type: Number, default: 150},
    labelY: { type: String, default: '' },
    formatY: { type: String, default: 'currency' },
    barPercentage: { type: Number, default: 0.3 },
    categoryPercentage: { type: Number, default: 1 },
  },
  data(){
    return {
      tooltipTotal: 0
    }
  },
  mounted () {
    const formatYAxes = this.formatY;
    const $currencyFormatter = this.$currencyFormatter
    this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      },{
        scales: {
          xAxes: [{
            maxBarThickness: this.barsize,
            stacked: true,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              callback: ((value) => {
                if(Array.isArray(value)){
                  return value.map((v) => this.truncate(v, 10));
                }
                else return this.truncate(value, 10)
              })
            },
            display: true,
            gridLines: {
              color: 'rgba(255, 0, 0, 0)', 
              drawBorder: true,
              zeroLineColor: 'rgba(255, 0, 0, 0)',   
            },
            barPercentage: this.barPercentage,
            categoryPercentage: this.categoryPercentage,
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.labelY,
            },
            display: true,
            ticks: {
              callback: function(value) {
                if (formatYAxes === 'currency') {
                  return $currencyFormatter.format(value)
                } else if (formatYAxes === 'unit') {
                  return value + ' un'; 
                } else {
                  return value; 
                }
              }.bind(this),
              beginAtZero: true 
            },
          }]
        },
        legend: {
          display: false, 
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          position: 'average',
          callbacks: {
            title: function(tooltipItems, data) {
              let label = data.labels[tooltipItems[0].index];
              if(Array.isArray(label)){
                return label.join("\r\n");
              }
              else return label;
            },
            label: function(tooltipItem, data){              
              this.tooltipTotal = 0;
              data.datasets.forEach((dataset) => {
                this.tooltipTotal += dataset.data[tooltipItem.index];
              });
              
            },
            footer: function() {
              if(formatYAxes == 'currency'){
                return $currencyFormatter.format(this.tooltipTotal)
              }
              else if(formatYAxes == 'unit'){
                return this.tooltipTotal + ' un.';
              }
            }
          }
        }
      });
  },
  methods: {
    truncate(str, length){
      if (str != null) {
        return str.length > length ? str.substr(0, length-1) + '...' : str;
      }
      return str;
    }
  }
})
