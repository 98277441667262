export default {
    removeItemDialogTitle: 'Remover item dos planogramas',
    productToRemoveLabel: 'Produto a remover',
    dialogcloseButton: 'Fechar',
    dialogremoveButton: 'Remover',
    removeItems: 'Itens',
    errorWhenRemovingProduct: 'Erro ao remover o produto ',
    fromThePlanogram: ' do planograma',
    removeItemDialogTryAgain: ' Tente novamente.',
    warningRemoveItem: 'O produto será removido <strong>apenas</strong> dos pontos de venda selecionados que possuem planogramas rascunhos.'
  };