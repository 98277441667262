export default {
    productGroupingsTitle: 'Product groupings',
    searchGroup: 'Search grouping',
    noItemsGroupings: 'No items registered',
    confirmRemoveGroup: 'Are you sure you want to remove the grouping',
    cancelGroupings: 'No',
    removeGroupings: 'Remove',
    idGroupings: 'ID',
    codeGroupings: 'Code',
    descriptionGroupings: 'Description',
    categoryGroupings: 'Category',
    defaultPriceGroupings: 'Default price',
    productsCountGroupings: 'Product quantity',
    availabilityGroupings: 'Availability',
    publicGroupings: 'Public',
    imageGroupings: 'Image',
    actionsGroupings: 'Actions',
    itemRemovedGroupings: 'Item removed',
    errorRemovingItemGroupings: 'Error removing item',
    itemSavedSuccessfullyGroupings: 'Grouping saved successfully',
    itemUpdatedSuccessfullyGroupings: 'Grouping successfully updated',
    groupGroupings: 'Group: ',
  };