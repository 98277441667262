
import Vue from 'vue';
import agent from "@/api/agent";

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async removeUnrelatedTaxings() {
      this.submitting = true;
      const requestEndpoint = (this as any).$auth.user().tenantCountry == "US" ?
        agent.Taxings.removeUnrelatedToProductsUsa() :
        agent.Taxings.removeUnrelatedToProducts();
      try {
        const response = await requestEndpoint;
        this.$emit('success', response)
      }
      catch(error) {
        this.$emit('error', 'Não foi possível', error)
      }
      finally {
        this.dialog = false;
        this.submitting = false;
      }
    },
  }
})
