export default {
    averageTicketCardTitle: 'Average Ticket by Locations',
    averageTicketStateLabel: 'State',
    averageTicketTotalLocationsMessage: 'Total locations consulted: ',
    averageTicketTotalLabel: 'Total Average Ticket',
    averageBillingLabel: 'Average Billing',
    averageProductVarietyLabel: 'Average Product Variety',
    averageTicketNoResultsMessage: 'No results',
    averageTicketChartTitle: 'Average Ticket by Product Mix',
    xAxisLabelAverageTicket: 'Product Mix',
    yAxisLabelAverageTicket: 'Average Ticket',
    averageTicketLabel: 'Average Ticket',
    averageTicketCardTitleNew: 'Average Ticket by Product Mix',
};
  