export default {
    xmlProductsDialogCardTitle: 'INFORMACIÓN DE LOS PRODUCTOS',
    xmlProductsDialogHelp1: 'Información de los productos',
    xmlProductsDialogParagraph1: `En esta página, los datos extraídos de envíos vía XML están disponibles para asociación a productos 
    específicos, permitiendo la selección de la unidad de conversión para actualizar el inventario.`,
    xmlProductsDialogProductName: 'Nombre del producto',
    xmlProductsDialogProductCod: 'Código del producto',
    xmlProductsDialogUnitConversion: 'unidad de conversión',
    xmlProductsDialogUnit: 'Unidades: ',
    xmlProductsDialogSelectProduct: 'Seleccione el Producto ',
    xmlProductsDialogProductSelect: 'Producto ya seleccionado',
    xmlProductsDialogHelp2: 'Si el código de barras del producto en la nota es igual al código de un producto en el sistema, el mismo ya vendrá preseleccionado.',
    xmlProductsDialogUnitConversionLabel: 'Unidad de conversión',
    xmlProductsDialogUnitConversionTitle: 'Unidad de conversión / Unidades',
    xmlProductsDialogProductsTitle: 'Productos',
    xmlProductsDialogParagraph2: `Si la unidad está clasificada como desconocida, esto indica que nuestro sistema no pudo interpretar 
    la unidad mencionada en el XML. Para corregirlo, basta con seleccionar el precio por unidad (como unidades, cajas o fardos) e 
    indicar cuántas unidades componen esa medida de conversión (por ejemplo, unidad = 1, caja = 10, fardo = 6).<br>
    Nota: la cantidad informada no acepta número decimal.`,
    xmlProductsDialogParagraph3: 'Cantidad total del producto: ',
    xmlProductsDialogHelp3: 'Cantidad total del producto',
    xmlProductsDialogParagraph4: `Después de ingresar los datos de cantidad y costo, nuestro sistema realizará automáticamente el cálculo del total de unidades 
    y el precio unitario correspondiente. Por ejemplo, al ingresar dos cajas, cada una con 10 unidades, a un costo de 
    10 reales por caja: Total de unidades: 20 | Precio unitario: R$ 1,00. Esta funcionalidad simplifica la visualización del 
    total de productos y su costo por unidad, facilitando el análisis de la información.`,
    xmlProductsDialogRegisteredProduct: '¿No tiene este producto registrado?',
    xmlProductsDialogUnitaryValue: 'Valor unitario del producto:',
    xmlProductsDialogClose: 'CERRAR',
    xmlProductsDialogToGoBsck: 'REGRESAR',
    xmlProductsDialogParagraph5: 'Para continuar, complete: valor unitario, producto, unidad de conversión, cantidad.',
    xmlProductsDialogRules: 'Solo se aceptan números enteros',
    xmlProductsDialogError: 'Error al buscar unidades de conversión',
    xmlProductsDialogFeedback: 'No es posible seleccionar un agrupamiento',
    xmlProductsDialogProduct: 'Producto',
    xmlProductsDialogProduct2: 'de',
    xmlProductsDialogProductNext: 'SIGUIENTE',
    xmlProductsDialogProductFinish: 'FINALIZAR',
};
