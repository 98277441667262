export default {
    creditPaymentMethods: 'Crédito',
    debitPaymentMethods: 'Débito',
    voucherPaymentMethods: 'Voucher',
    remoteCreditPaymentMethods: 'Remoto',
    cashPaymentMethods: 'Espécie',
    appSalePaymentMethods: 'Sampling',
    picPayPaymentMethods: 'PicPay',
    freePaymentMethods: 'Gratuito',
    externalAppPaymentMethods: 'App externo',
    appCreditCardPaymentMethods: 'Crédito pelo app',
    pixPaymentMethods: 'PIX',
    cashlessPaymentMethods: 'Cashless',
    othersPaymentMethods:'Outros',
    qrcodePaymentMethods: 'QR Code',
  }
