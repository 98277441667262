export default{
  alerts:{
    title: "Alertas",
    subtitle: "Agregar nuevo correo electrónico para alerta",
    form:{
      email: "Correo electrónico",
    },
    table:{
      title: "Alertas registradas",
    },
    actions:{
      remove: {
        message: "¿Está seguro de que desea eliminar el correo electrónico?",
      },
    },
    help:{
      title: "Alertas",
      description: `<p>Manténgase informado sobre el estado de los terminales de pago.
      Registre su correo electrónico a continuación para ser notificado en caso de una caída significativa del servicio.</p>
      <strong>Atención</strong>: Utilice un correo electrónico activo para garantizar que las notificaciones lleguen a usted.`,
    }
  },
}