
import Vue from 'vue';
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import { formatCnpj, formatCnpjOrCpfToNumbersOnly } from '@/utility/TextFormatting'
import { TenantGroup, CustomerStatusChangeResponse } from '@/types/Customers';
import Feedback from '@/components/Feedback.vue';

export default Vue.extend({
  components: { Feedback },
  data() {
    return {
      groups: [] as TenantGroup[] | undefined,
      headers: [
        { text: 'Status', align: 'left', sortable: true, value: 'clientStatus' },
        { text: 'Id', align: 'left', sortable: true, value: 'groupId' },
        { text: 'Franqueado', align: 'left', sortable: true, value: 'groupName' },
        { text: 'Cliente', align: 'left', sortable: true, value: 'tenantName' },
        { text: 'Cnpj', align: 'left', sortable: true, value: 'cnpj' },
        { text: 'Cód. ERP', align: 'left', sortable: true, value: 'erpCode' },
        { text: 'Ações', align: 'left', sortable: false, value: 'actions' }
      ],
      totalItems: 0,
      loading: false,
      search: {
        valid: true,
        text: "" as string
      },
      clientStatus: [
        { text: "Ativo", value: "Active" },
        { text: "Sem Acesso", value: "Locked" },
        { text: "Bloqueio Total", value: "Blocked" },
      ],
      clientBlockReason: "Payment" as "Payment" | "Royalties",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["tenantId"],
        sortDesc: [false],
        mustSort: true,
      },
      editedClientStatus: '',
      showStatusWarningDialog: false,
      statusWarningDialogMessage: '',
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getGroups();
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    getGroups() {
      this.loading = true;
      const searchText = this.search.text && this.$validations.isCnpjOrCpf(this.search.text)
        ? formatCnpjOrCpfToNumbersOnly(this.search.text)
        : this.search.text;
      const params = toApiPagination(this.options, searchText);
      this.axios.get("api/tenants/groups", { params: params })
        .then((response) => {
          this.groups = response.data.items;
          this.totalItems = response.data.totalItems;
        })
        .catch(() => {})
        .then(() => this.loading = false);
    },
    checkForBlockedWhileActive(groupEntry: TenantGroup) {
      if (groupEntry.clientStatus === "Active" && this.editedClientStatus === "Blocked") {
        this.editedClientStatus = "Locked";
        this.statusWarningDialogMessage =
          'O status do cliente será mudado para "Sem Acesso" para evitar o bloqueio total antes de passar pelo bloqueio parcial. Caso deseje bloquear totalmente o cliente, basta selecionar "Bloqueio Total" novamente.';
        this.showStatusWarningDialog = true;
      }
      this.saveTenantStatus(groupEntry);
    },
    saveTenantStatus(groupEntry: TenantGroup) {
      let groupRequestDto = Object.assign({}, groupEntry)
      groupRequestDto.clientStatus = this.editedClientStatus
      if (groupRequestDto.clientStatus != "Active") {
        groupRequestDto.clientBlockReason = this.clientBlockReason;
      }
      else {
        groupRequestDto.clientBlockReason = null;
      }
      let feedbackMessage = this.getFeedbackMessage(this.editedClientStatus)

      this.axios.put(`api/Tenants/groups/status`, groupRequestDto)
        .then((response) => {
          groupEntry.clientStatus = this.editedClientStatus
          if (groupEntry.clientStatus != "Active") {
            groupEntry.clientBlockReason = this.clientBlockReason
          } else {
            groupEntry.clientBlockReason = null
          }
          let emailsMessage = this.getEmailsMessage(response.data);
          this.handleSuccess(`Sucesso ao realizar ${feedbackMessage} de ${groupEntry.groupName}.${emailsMessage}`);
        }).catch((error) => {
          this.handleError(`Erro ao realizar ${feedbackMessage}.`, error);
        }).finally(() => {
          this.editedClientStatus = ''
        })
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    getStatusColor(status: string) {
      switch(status){
        case 'Active': return 'green darken-2'
        case 'Locked': return 'amber darken-2'
        case 'Blocked': return 'red darken-2'
      }
    },
    getStatusIcon(status: string) {
      switch(status){
        case 'Active': return 'check_circle'
        case 'Locked': return 'mdi-alert-circle'
        case 'Blocked': return 'mdi-alert-circle'
      }
    },
    getStatusDescription(group: TenantGroup) {
      let text = ""
      switch(group.clientStatus) {
        case 'Active': text =  'Ativo'; break;
        case 'Locked': text =  'Sem Acesso'; break;
        case 'Blocked': text =  'Bloqueio Total'; break;
      }
      switch(group.clientBlockReason) {
        case 'Payment': text += " - Pagamento"; break;
        case 'Royalties': text += " - Franquia"; break;
      }
      return text;
    },
    getFeedbackMessage(status: string) {
      switch(status){
        case 'Active': return 'Desbloqueio'
        case 'Locked': return 'Bloqueio de Acesso'
        case 'Blocked': return 'Bloqueo Total'
      }
    },
    getEmailsMessage(data: CustomerStatusChangeResponse) {
      if (data.emailsFailed + data.emailsSent == 0) {
        return "Nenhum email foi enviado.";
      }

      let emailsMessage = ` Foram enviados ${data.emailsSent} com sucesso.`
      if (data.emailsFailed > 0) {
        emailsMessage + ` ${data.emailsFailed} emails falharam.`;
      }
      return emailsMessage;
    },
    formatCnpj
  }
});
