export default {
    productGroupingsTitle: 'Agrupaciones de productos',
    searchGroup: 'Buscar agrupación',
    noItemsGroupings: 'No hay elementos registrados',
    confirmRemoveGroup: '¿Estás seguro de que deseas eliminar la agrupación?',
    cancelGroupings: 'Cancelar',
    removeGroupings: 'Eliminar',
    idGroupings: 'ID',
    codeGroupings: 'Código',
    descriptionGroupings: 'Descripción',
    categoryGroupings: 'Categoría',
    defaultPriceGroupings: 'Precio predeterminado',
    productsCountGroupings: 'Cantidad de productos',
    availabilityGroupings: 'Disponibilidad',
    publicGroupings: 'Público',
    imageGroupings: 'Imagen',
    actionsGroupings: 'Acciones',
    itemRemovedGroupings: 'Elemento eliminado',
    errorRemovingItemGroupings: 'Error al eliminar el elemento',
    itemSavedSuccessfullyGroupings: 'Agrupación guardada exitosamente',
    itemUpdatedSuccessfullyGroupings: 'Agrupación actualizada exitosamente',
    groupGroupings: 'Grupo: ',
};
