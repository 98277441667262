export default {
  box: {
    code: 'Código',
    terminals: 'Terminais',
    activation: {
      insertText: 'Insira o código de ativação',
      steps: {
        title: 'Novo método de ativação: <strong>Se a maquininha solicitar um código de ativação, siga as etapas abaixo para concluir o processo.</strong>',
        description: `
        <ol>
          <li>Insira o código <strong>{code}</strong>.</li>
          <li>Clique no botão "Ativar".</li>
          <li>Aguarde a confirmação de ativação da maquininha.</li>
        </ol>`,
      },
      response: {
        success: 'O sistema será ativado',
        error: 'Erro com a ativação',
      }
    },
    help: {
      title: 'Novo TouchPay',
      description: 'Na página de Novo Ponto de Venda, você deverá informar o código no aplicativo do terminal. Esse código associa o terminal ao ambiente correspondente.',
    }
  }
};