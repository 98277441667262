export default {
    appRegistration: 'Application registration',
    registerApp: 'Register application',
    additionalChargeInfo: 'Enabling the app implies an additional charge, according to the modality contracted through the AMLabs commercial area',
    storeBrand: 'Store Brand',
    enterBrand: 'Enter the brand to activate the app for this POS',
    locationSubtitleMobileActivation: 'Location/Subtitle',
    enterLocation: 'Enter the location to activate the app for this POS',
    sacWhatsAppOptional: 'SAC WhatsApp (optional)',
    sacWhatsAppNumber: 'WhatsApp number of the storen\'s customer service to create a shortcut in the app',
    displayProductPricesMobileActivation: 'Display product prices',
    verifyCpf: 'Verify CPF with Receita Federal',
    verifyAge: 'Verify age with Receita Federal',
    verifyValidEmail: 'Verify valid e-mail',
    verifyPhoneNumber: 'Verify phone number',
    phoneNumberOption: 'Option only applicable to white labels. ',
    confirmPhoneMobileActivation: 'Requires the user to confirm the phone number via a code sent by SMS in order to open the store locks.',
    addToCartOption: 'Adding products to the shopping cart',
    paymentTokenMobileActivation: 'Payment token',
    disableMobileActivation: 'Disable',
    closeMobileActivation: 'Close',
    updateMobileActivation: 'Update',
    registerMobileActivation: 'Register',
    registrationUpdated: 'Application registration updated!',
    unableToUpdateRegistration: 'Application registration could not be updated',
    appReleased: 'Application released!',
    unableToActivateApp: 'Application could not be activated',
    appDisabled: 'App disabled on this POS.',
    unableToDisableApp: 'App could not be disabled at this POS',
    appCheckoutTypeDisabled: 'Disabled',
    appCheckoutTypeBarcode: 'Barcode only',
    appCheckoutTypeBarcodeAndManual: 'Barcode or manual selection',
    userDocumentVerificationText: `Enabling CPF verification by Receita Federal results in an additional charge,
    according to the modality contracted through the AMLabs commercial area.`,
    userVerificationText: `Enabling verification of users' age by the Receita Federal will incur an additional charge,
    according to the modality contracted through the AMLabs commercial area.`,
    errorFindingToken: 'Could not find a token for the POS.',
  };