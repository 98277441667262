export default {
  pointOfSale: {
    data: {
      id: "Punto de venta {pointOfSaleId}",
      title: {
        pointOfSale: "Punto de Venta",
        pointsOfSale: "Puntos de Venta"
      },
    },
    posManagement: {
      help: {
        title: "Gestión de Puntos de Venta",
        description: `
        <p>
          En esta página, puedes ver y gestionar los Puntos de Venta (PDV) y los terminales de pago asociados a tu entorno. La página está dividida en dos pestañas principales: Puntos de Venta y Terminales.
        </p>
        <p>
          En la pestaña <strong>Puntos de Venta</strong>, encontrarás la lista completa de PDV registrados, incluyendo el ID del PDV, el sistema de pago, el código del POS, el modelo de la máquina, el cliente responsable, la ubicación, el centro de distribución y la fecha de creación. También es posible editar o eliminar la información del PDV.
        </p>
        <p>
          En la pestaña <strong>Terminales</strong>, se muestran todos los terminales de pago registrados, incluso si no están asociados a un PDV. La información incluye el ID de la máquina, el PDV asociado (si lo hay), el modelo de la máquina, la versión (para máquinas vinculadas a un PDV) y la contraseña del terminal. Utiliza las funciones de editar o eliminar para gestionar tus terminales de manera eficiente.
        </p>`,
      }
    }
  },
};