
import Vue from 'vue';
import agent from '@/api/agent';
import PromotionDialog from '@/discounts/promotions/PromotionDialog.vue';
import PromotionFilters from '@/discounts/filter/PromotionFilters.vue';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import { DiscountProduct } from '@/types/DiscountTypes';
import { PointOfSale } from '@/types/PointOfSale';
import { formatDateTimeToDate } from '@/utility/TextFormatting';
import { typeDiscountTranslation } from '@/constants/TypeDiscount';

export type FormFilter = {
  pointOfSaleIds: number[],
  discountType: null,
  startDate: string | null,
  endDate: string | null,
  timezoneOffset: number
};

interface TypeDiscountTranslation {
  [key: string]: string;
}

type Search = {
  valid: boolean,
  text: string | null
};

export default Vue.extend({
  mounted() {
    this.requestPointsOfSale();
  },
  data() {
    return {
      discounts: [] as DiscountProduct[],
      headers: [
        { text: this.$t('promotion.name'), align: 'left', sortable: true, value: 'description' },
        { text: this.$t('promotion.usage'), align: 'left', sortable: false, value: 'usage' },
        { text: this.$t('promotion.type'), align: 'left', sortable: false, value: 'type' },
        { text: this.$t('promotion.starts-on'), align: 'left', sortable: true, value: 'startsOn' },
        { text: this.$t('promotion.expires-on'), align: 'left', sortable: true, value: 'expiresOn' },
        { text: this.$t('promotion.date-created'), align: 'left', sortable: true, value: 'dateCreated' },
        { text: this.$t('promotion.actions'), align: 'left', sortable: false, value: 'actions' }
      ],
      editedEntry: {},
      editedIndex: -1,
      itemDialog: false,
      search: {
        valid: true,
        text: '',
      } as Search,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateCreated'],
        sortDesc: [false],
        mustSort: true
      },
      typeDiscountTranslation: typeDiscountTranslation as TypeDiscountTranslation,
      totalItems: 0,
      totalPages: 0,
      removeDialog: {
        enabled: false,
        discount: {} as DiscountProduct
      },
      loading: {
        discounts: false,
        removeItem: false,
        newDialog: false,
        pointsOfSale: false,
      },
      form: {
        pointOfSaleIds: [],
        discountType: null,
        startDate: null,
        endDate: null,
        timezoneOffset: new Date().getTimezoneOffset()
      } as FormFilter,
      pointsOfSale: [] as PointOfSale[],
      translations: {
        no: this.$t('promotion.no'),
        created: this.$t('promotion.created'),
        edited: this.$t('promotion.edited'),
        removeLabel: this.$t('promotion.remove.label'),
        removeMessage: this.$t('promotion.remove.message'),
        removeSuccess: this.$t('promotion.remove.success'),
        removeFail: this.$t('promotion.remove.fail'),
        noItems: this.$t('promotion.no-items')
      },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.requestDiscountProducts();
        }
      },
      deep: true
    },
    itemDialog(isOpen) {
      if (!isOpen) {
        this.editedIndex = -1;
        this.editedEntry = {};
      }
    }
  },
  methods: {
    requestDiscountProducts() {
      this.loading.discounts = true;
      const params = toApiPagination(this.options, this.search.text);
      this.insertFiltersFromForm(params);

      agent.Discounts.productDiscounts(params)
        .then(response => {
          const discounts = response.items;
          this.totalItems = response.totalItems;
          this.totalPages = response.totalPages;
          this.discounts = discounts;
        })
        .finally(() => {
          this.loading.discounts = false;
        });
    },
    requestPointsOfSale() {
      this.loading.pointsOfSale = true;
      agent.PointsOfSale.all()
        .then((response) => this.pointsOfSale = response)
        .finally(() => this.loading.pointsOfSale = false);
    },
    onFilterUpdated(form: FormFilter) {
      this.options.page = 1;
      Object.assign(this.form, form);
      this.requestDiscountProducts();
    },
    insertFiltersFromForm(params: any) {
      const form = this.form;

      params.startDate = form.startDate ? this.$moment(form.startDate).format('YYYY-MM-DD') : null;
      params.endDate = form.endDate ? this.$moment(form.endDate).format('YYYY-MM-DD') : null;
      params.pointOfSaleIds = form.pointOfSaleIds;
      params.discountType = form.discountType;
      params.discountType = form.discountType;
      params.timezoneOffset = form.timezoneOffset;

      return params;
    },
    editItem(discount: DiscountProduct) {
      this.editedIndex = this.discounts.indexOf(discount);
      this.editedEntry = Object.assign({}, discount);
      this.itemDialog = true;
    },
    onDiscountCreated(discount: DiscountProduct) {
      this.discounts.push(discount);
      this.editItem(discount);
      this.handleSuccess(this.translations.created.toString());
    },
    onDiscountEdited(incomingDiscount: DiscountProduct) {
      let existingDiscount = this.discounts.find(discount => discount.id === incomingDiscount.id);
      if (existingDiscount && incomingDiscount) {
        Object.assign(existingDiscount, incomingDiscount);
      } else {
        this.discounts.push(incomingDiscount);
        this.editItem(incomingDiscount);
      }
      this.handleSuccess(this.translations.edited.toString());
    },
    confirmRemoval(discount: DiscountProduct) {
      this.removeDialog.discount = discount;
      this.removeDialog.enabled = true;
    },
    deleteDiscount(discount: any) {
      this.loading.removeItem = true;
      agent.Discounts.delete(discount.id)
        .then(() => {
          (this.$refs.feedback as any).handleSuccess(this.translations.removeSuccess.toString());
          const index = this.discounts.indexOf(discount);
          this.discounts.splice(index, 1);
        })
        .catch(error => (this.$refs.feedback as any).handleError(this.translations.removeFail.toString(), error))
        .finally(() => {
          this.loading.removeItem = false;
          this.removeDialog.enabled = false;
        });
    },
    handleSuccess(message: string) {
      this.itemDialog = !this.itemDialog;
      this.editedIndex = -1;
      this.editedEntry = {};
      (this.$refs.feedback as any).handleSuccess(message);
      this.removeDialog.enabled = false;
      this.requestDiscountProducts();
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    formatDateTimeToDate
  },
  components: {
    PromotionDialog,
    PromotionFilters
  }
});
