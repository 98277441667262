export default {
    removeItemDialogTitle: 'Remove item from planograms',
    productToRemoveLabel: 'Product to remove',
    dialogcloseButton: 'Close',
    dialogremoveButton: 'Remove',
    removeItems: 'Items',
    errorWhenRemovingProduct: 'Error removing product ',
    fromThePlanogram: ' from the planogram',
    removeItemDialogTryAgain: ' Try again',
    warningRemoveItem: 'The product will be removed <strong>only</strong> from the selected points of sale that have draft planograms.'
  };