export default {
    retryTitleBulkRetryInvoice: 'Reemissão em lote',
    retryDescriptionBulkRetryInvoice: `Essa função serve para reemitir todas as notas em que houve falha na emissão, considerando as transações que ocorreram
    de acordo com os filtros de data desta página. Só é possível reemitir notas de transações que ocorreram nos últimos 35 dias. 
    No máximo 500 notas serão reemitidas por vez.`,
    retryWaitBulkRetryInvoice: 'Para evitar que notas sejam emitidas em duplicidade, aguarde meia hora antes de usar essa função novamente.',
    keepNumberLabelBulkRetryInvoice: 'Manter mesmo número sequencial',
    keepNumberHintBulkRetryInvoice: 'Recomendamos manter o mesmo número sequencial sempre que possível.',
    closeBulkRetryInvoice: 'Fechar',
    retryButtonBulkRetryInvoice: 'Reemitir',
    retryErrorBulkRetryInvoice: 'Não foi possível solicitar a reemissão'
  }
  