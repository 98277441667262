export default {
  taxings: {
    brazil: {
      reductionPercentageIcms: 'Redução base de cálculo do ICMS',
      help: {
        description: `
            <p>
              O percentual de desoneração fiscal do ICMS é utilizado para calcular a isenção parcial ou total do imposto sobre o valor da mercadoria ou serviço.
              <strong>Essa desoneração de imposto não é válida para regimes do tipo simples nacional. Dependendo do Código de Situação Tributária (CST) aplicado, a forma como a desoneração é calculada pode variar:</strong>
            </p>
            <ul>
              <li>
                <strong>CST 20 ou 70</strong>: Nestes casos, a desoneração é calculada com base no percentual de redução aplicado sobre a alíquota do ICMS. O cálculo utiliza a fórmula:
                <br><br>
                <code>
                Valor da Desoneração = (Valor Total × (1 - (Alíquota × (1 - Percentual de Redução base )))) / (1 - Alíquota) - Valor Total
                </code>
                <br><br>
                A alíquota do ICMS é ajustada com base no percentual de redução informado.
              </li><br>
              <li>
                <strong>CST 30 ou 40</strong>: Nesses casos, a desoneração é aplicada integralmente sobre o total do ICMS, e o valor desonerado é calculado diretamente como uma fração do valor total, baseado na alíquota do imposto:
                <br><br>
                <code>
                Valor da Desoneração = Valor Total × Alíquota
                </code>
                <br><br>
              </li>
            </ul>
        `,
        alert: `O motivo da desoneração do imposto na nota, é atribuído com o valor "9" que corresponde a "outros", isso ocorre automaticamente pelo sistema ao preencher o campo de Percentual da base de desoneração ICMS.`
      }
    } 
  }
}