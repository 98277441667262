
import Vue from 'vue';


export default Vue.extend({

   data() {
    return {
        deleteOperation : false as  boolean,
    }
   },
   props: {
    value: {
      type: Boolean,
      default: false
    },
   },
   computed: {
    dialog: {
      get() : boolean {
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value, this.deleteOperation);
      }
    },
  },
  methods: {
    CancelDelete() {
      this.deleteOperation = false;
      this.dialog = false;
  },
  resetData() {
    this.deleteOperation = true;
    this.dialog = false; 
  },
  },
  
})
