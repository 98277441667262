
import Vue from 'vue';

export default Vue.extend({
  name: "ClearButton",
  methods:{
    clearFilters() {
      this.$emit('clear');
    }
  }
});
