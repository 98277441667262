<template>
  <v-chip label dark :color="statusColor" v-on="on">
    {{statusDescription}}
    <v-icon right v-show="showInfo">info</v-icon>
  </v-chip>
</template>

<script>
export default {
  props: {
    status: { type: String, required: true },
    showInfo: { type: Boolean, default: false },
    on: null
  },
  computed: {
    statusDescription() {
      if (this.status === 'Archived') return this.$t('archivedStatus');
      if (this.status === 'Active') return this.$t('activeStatus');
      if (this.status === 'Pending') return this.$t('pendingStatus');
      if (this.status === 'Draft') return this.$t('draftStatus');
      else return '';
    },
    statusColor() {
      if (this.status === 'Archived') return 'grey';
      if (this.status === 'Active') return 'green';
      if (this.status === 'Pending') return 'orange';
      if (this.status === 'Draft') return 'blue'; 
      else return 'white';
    },
  }
}
</script>