export default {
  userSearch: 'Search',
  userNoDataText: 'No users registered',
  userNoPermitToEdit: 'You do not have permission to edit this user',
  userNoPermitToDelete: 'You do not have permission to delete this user',
  userDeleteConfirmation: 'Are you sure you want to remove user {user}?',
  userNo: 'No',
  userRemove: 'Remove',
  userEmailLabel: 'Email',
  userTenantGroupIdLabel: 'Group',
  userRolesLabel: 'Permissions',
  userActionsLabel: 'Actions',
  userDeleteSuccess: 'User {user} access has been blocked',
  userDeleteError: 'Error removing user'
}
