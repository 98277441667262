export default {
  box: {
    code: 'Código',
    terminals: 'Terminales',
    activation: {
      insertText: 'Ingrese el código de activación',
      steps: {
        title: 'Nuevo método de activación: <strong>Si la terminal solicita un código de activación, siga los pasos a continuación para completar el proceso.</strong>',
        description: `
        <ol>
          <li>Ingrese el código <strong>{code}</strong>.</li>
          <li>Haga clic en el botón "Activar".</li>
          <li>Espere la confirmación de activación de la terminal.</li>
        </ol>`,
      },
      response: {
        success: 'El sistema será activado',
        error: 'Error con la activación',
      }
    },
    help: {
      title: 'Nuevo TouchPay',
      description: 'En la página de Nuevo TouchPay, deberá ingresar el código en la aplicación de la terminal. Este código asocia la terminal con el entorno correspondiente.',
    }
  }
}