<template>
  <v-card class="mb-16">
    <news v-if="isBrazil"></news>
    <v-card-title>
      <div class="title">
        <v-icon left>view_comfy</v-icon>{{ $t('planogramsByPos') }}
        <planogram-main-help></planogram-main-help>
      </div>
      <v-spacer></v-spacer>
      <v-flex xs3>
        <v-text-field
          class="pt-0 mr-2"
          v-model="search"
          append-icon="search"
          :label="$t('searchPDV')"
          filled
          rounded
          dense
          single-line
          hide-details
        ></v-text-field>
      </v-flex>

      <!-- Bulk Progress Dialog -->
      <bulk-progress-dialog
        v-model="bulkProgressDialog.enabled"
        :progress="bulkProgressDialog.progress"
        :infoMessages="bulkProgressDialog.infoMessages"
        @input="bulkProgressDialog.enabled = false; bulkProgressDialog.infoMessages = []"
      ></bulk-progress-dialog>

      <!-- Create planogram -->
      <v-tooltip bottom open-delay="50" :disabled="createPlanogramEnabled">
        <template #activator="{ on: tooltip }">
          <span v-on="tooltip">
            <planogram-creation
              :pos-ids="selectedPosIds"
              :copy-from-active-disabled="!copyFromActiveEnabled"
              @progress="onUpdateProgress"
              @updateProgressDialogEnable="onUpdateProgressDialogEnable"
              @success="onFinishedCreation"
              @error="onCreationError"
            >
              <template #activator="{ on: dialog }">
                <v-btn class="mx-2" v-on="dialog" color="success" :disabled="!createPlanogramEnabled">
                  <v-icon left>add</v-icon>{{ $t('planograms') }}
                </v-btn>
              </template>
            </planogram-creation>
          </span>
        </template>
        {{ $t('selectPDVs') }}
      </v-tooltip>

      <!-- Add entry dialog -->
      <entry-dialog
        ref="entryDialog"
        :add-items-enabled="addItemsEnabled"
        :pos-ids="addOrUpdateEntryPosIds"
        @progress="onUpdateProgress"
        @updateProgressDialogEnable="onUpdateProgressDialogEnable"
        @entry-created="onEntryCreated"
        @entry-error="onCreationError"
      ></entry-dialog>
      <!-- Remove entry dialog -->
      <remove-dialog
        :remove-items-enabled="draftActionsEnabled"
        :planogram-ids="selected.filter((pos) => pos.draftPlanogramId != null).map((pos) => pos.draftPlanogramId)"
        @progress="onUpdateProgress"
        @updateProgressDialogEnable="onUpdateProgressDialogEnable"
        @entry-deleted="(value) => onEntryCreated(value, true)"
        @entry-error="onCreationError"
      ></remove-dialog>

      <!-- Post-creation dialog -->
      <v-dialog v-model="postCreationDialog.enabled" max-width="600">
        <v-card>
          <v-card-text class="pt-4">
            <p class="mt-4">
              {{ postCreationDialog.message }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="green darken-1" @click="postCreationDialog.enabled = false; $refs.entryDialog.show = true">
              <v-icon left>add</v-icon>{{ $t('updateItems') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="green darken-1" @click="finishDialog.enabled = true; postCreationDialog.enabled = false">
              <v-icon left>check</v-icon>{{ $t('finishDraft') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Finish planogram dialog -->
      <v-dialog v-model="finishDialog.enabled" max-width="400">
        <v-card>
          <v-card-title class="headline"><v-icon left>info</v-icon>{{ $t('finishDrafts') }}</v-card-title>
          <v-card-text>
            <p>{{ $t('selectionPos') }}</p>

            <p>
              {{ $t('completionMessage') }}
            </p>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text
              @click.native="finishDialog.enabled = false"
              >{{ $t('btnNo') }}
              </v-btn>
            <v-btn
              color="success"
              text
              @click.native="finishPlanograms"
            >{{ $t('btnFinalize') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Menu -->
      <v-menu>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>

          <!-- Finish planogram -->
          <menu-item :enabled="draftActionsEnabled" :error="this.$t('finishPlanogramError')">
            <span @click="finishDialog.enabled = true">{{ $t('finishDrafts') }}</span>
          </menu-item>

          <!-- Delete planogram -->
          <menu-item :enabled="draftActionsEnabled" :error="this.$t('deletePlanogramError')">
            <v-dialog v-model="deleteDialog.enabled" max-width="400">

              <template #activator="{ on: dialog }">
                <span v-on="dialog">{{ $t('removeDrafts') }}</span>
              </template>
              <v-card>
                <v-card-text class="pt-4">{{ $t('confirmRemoveDrafts') }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text
                    @click.native="deleteDialog.enabled = false"
                    >{{ $t('btnNo') }}</v-btn>
                  <v-btn
                    color="success"

                    text
                    @click.native="deletePlanograms"
                  >{{ $t('btnRemove') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </menu-item>

          <!-- Activate planogram -->
          <menu-item :enabled="activatePlanogramsEnabled" :error="this.$t('activatePlanogramError')">
            <v-dialog v-model="activateDialog.enabled" max-width="400">
              <template #activator="{ on: dialog }">
                <span v-on="dialog">{{ $t('activatePending') }}</span>
              </template>
              <v-card>
                <v-card-title class="headline"><v-icon left>info</v-icon>{{ $t('activatePendingPlanograms') }}</v-card-title>
                <v-card-text>
                  <p>{{ $t('confirmActivate') }}</p>

                  <p>
                    {{ $t('makePlanogramActive') }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text
                    @click.native="activateDialog.enabled = false"
                    >{{ $t('btnNo') }}</v-btn>
                  <v-btn
                    color="success"
                    text
                    @click.native="activatePlanograms"
                  >{{ $t('btnFinalize') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </menu-item>

        </v-list>
      </v-menu>

    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="pos"
      :loading="loading.table"
      :search="search"
      show-select
      hide-default-footer
      disable-pagination
      must-sort
      :no-data-text="$t('noResults')"
    >
      <template #item="props">
        <tr @click="props.select(!props.isSelected)">
          <td>
            <v-simple-checkbox
              v-model="props.isSelected"
              @input="props.select(props.isSelected)"
              :ripple="false"
            ></v-simple-checkbox>
          </td>
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.customerName }}</td>
          <td>{{ props.item.localName }}</td>
          <td>{{ props.item.specificLocation }}</td>
          <td>{{ props.item.machineModelName }} / {{ props.item.machineManufacturerName }}</td>
          <td class="green--text">
            <template v-if="props.item.currentPlanogramId != null">
              <router-link :to="`/${$t('path.namePlanogramMaster')}?pdv=${props.item.id}&pog=${props.item.currentPlanogramId}`">
                <v-icon color="green">mdi-open-in-new</v-icon>
              </router-link>
              <strong>{{ props.item.currentPlanogramId }}</strong>
            </template>
          </td>
          <td class="orange--text">
            <template v-if="props.item.nextPlanogramId != null">
              <router-link :to="`/${$t('path.namePlanogramMaster')}?pdv=${props.item.id}&pog=${props.item.nextPlanogramId}`">
                <v-icon color="orange">mdi-open-in-new</v-icon>
              </router-link>
              <strong>{{ props.item.nextPlanogramId }}</strong>
            </template>
          </td>
          <td class="blue--text">
            <template v-if="props.item.draftPlanogramId != null">
              <router-link :to="`/${$t('path.namePlanogramMaster')}?pdv=${props.item.id}&pog=${props.item.draftPlanogramId}`">
                <v-icon color="blue">mdi-open-in-new</v-icon>
              </router-link>              
              <strong>{{ props.item.draftPlanogramId }}</strong>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <feedback ref="feedback"></feedback>
  </v-card>

</template>

<script>
import PlanogramCreationDialog from '@/planogram/PlanogramCreationDialog';
import PlanogramActionsMenuItem from '@/planogram/PlanogramActionsMenuItem';
import PlanogramMainHelp from '@/planogram/PlanogramMainHelp';
import PlanogramAddOrUpdateDialog from '@/planogram/PlanogramAddOrUpdateDialog';
import PlanogramRemoveItemDialog from '@/planogram/PlanogramRemoveItemDialog.vue';
import BulkProgressDialog from '@/components/common/BulkProgressDialog.vue';
import News from '@/components/News';

export default {
  computed: {
    selectedPosIds() {
      return this.selected.map((pos) => pos.id);
    },
    addOrUpdateEntryPosIds() {
      return this.selected.map((pos) => pos.id);
    },
    createPlanogramEnabled() {
      return this.selected.length > 0 &&
        this.selected.every((pos) => pos.nextPlanogramId == null && pos.draftPlanogramId == null);
    },
    copyFromActiveEnabled() {
      return this.selected.every((pos) => pos.currentPlanogramId != null);
    },
    activatePlanogramsEnabled() {
      return this.selected.length > 0 && this.selected.some((pos) => pos.nextPlanogramId != null);
    },
    draftActionsEnabled() {
      return this.selected.length > 0 && this.selected.some((pos) => pos.draftPlanogramId != null);
    },
    addItemsEnabled() {
      return this.selected.length > 0 && this.selected.some((pos) => pos.draftPlanogramId != null
        || pos.currentPlanogramId != null);
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },
  data() {
    return {
      pos: [],
      selected: [],
      search: '',
      headers: [
        { text: this.$t('headersPdvID'), align: 'left', sortable: true, value: 'id' },
        { text: this.$t('headersCustomer'), align: 'left', sortable: true, value: 'customerName' },
        { text: this.$t('headersLocation'), align: 'left', sortable: true, value: 'localName' },
        { text: this.$t('headersSpecificLocation'), align: 'left', sortable: true, value: 'specificLocation' },
        { text: this.$t('headersMachine'), align: 'left', sortable: true, value: 'machineModelName' },
        { text: this.$t('headersActivePog'), align: 'left', sortable: true, value: 'currentPlanogramId' },
        { text: this.$t('headersPendingPog'), align: 'left', sortable: true, value: 'nextPlanogramId' },
        { text: this.$t('headersDraftPog'), align: 'left', sortable: true, value: 'draftPlanogramId' }
      ],
      loading: {
        table: true
      },
      deleteDialog: {
        enabled: false,
      },
      finishDialog: {
        enabled: false
      },
      activateDialog: {
        enabled: false
      },
      itemDialog: {
        enabled: false
      },
      postCreationDialog: {
        enabled: false,
        message: ''
      },
      bulkProgressDialog: {
       enabled: false,
       progress: 0,
       infoMessages: []
      },
      rules: {
        integer: v => /^\d+$/.test(v) || this.$t('numberInteger'),
      },
      posIdsDraftPlanogram: [],
    }
  },
  mounted() {
    this.getPos();
  },
  methods: {
    /**Get objetive on server with useful planogram infos.*/
    getPos() {
      this.loading.table = true;
      this.axios.get('api/planograms/posInfo')
        .then((response) => {
          this.loading.table = false;
          this.pos = response.data;
          this.selected = this.selected
            .map(sel => this.pos.find(pos => pos.id === sel.id))
            .filter(pos => pos != null);
        })
    },

    /**Finish draft Planogram.*/
    finishPlanograms() {
      this.bulkProgressDialog.enabled = true;
      this.finishDialog.enabled = false;
      this.bulkProgressDialog.progress = 0;
      var posIds = this.posIdsDraftPlanogram.length > 0
        ? this.posIdsDraftPlanogram
        : this.selected.filter((pos) => pos.draftPlanogramId != null).map((pos) => pos.id);

      this.bulkProgressDialog.infoMessages =  this.bulkProgressDialog.infoMessages.filter((msg) => !posIds.includes(msg.entityId));
      
      this.axios.put('api/planograms/bulk/finish', null, {
        params: { posIds: posIds }
      }).then((response) => {
        this.getPos();
        this.handleBulkResult(response.data,
          (entityId) => this.$t(`${this.$t('finalizePogSuccess')} ${entityId}`),
          (entityId, error) => (`${this.$t('finalizePogError')} ${entityId}: ${error}`));
      }).finally(() => {
        this.bulkProgressDialog.progress = 100;
      })
    },

    /**Activate a list os given Planograms. */
    async activatePlanograms() {
      const requests = this.selected.filter((pos) => pos.nextPlanogramId != null).map((pos) => pos.id);
      const numberOfReqsParallel = requests.length;
      const elementPercentage = 100 / numberOfReqsParallel;
      const numberOfElements = Math.ceil(requests.length / numberOfReqsParallel);
      const chunckedRequests = this.partition(requests, numberOfElements);

      this.bulkProgressDialog.enabled = true;
      this.activateDialog.enabled = false;
      this.bulkProgressDialog.progress = 0;

      const promiseRequests = [...Array(numberOfReqsParallel).keys()]
        .map(x =>
          this.axios.put('api/planograms/bulk/activate', null, {params: { posIds: chunckedRequests[x] }})
          .catch(() => {
            return {
              data: {
                results: chunckedRequests[x].map(posId => {
                  return {
                    entityId: posId,
                    success: false,
                    error: this.$t('activatePlanogramsError')
                  }
                })
              }
            }
          })
          .finally(() => {
            this.bulkProgressDialog.progress += elementPercentage
          })
        );

      await Promise.allSettled(promiseRequests)
        .then((response) => {
          this.getPos();
          const bulkResult = { results: null }
          bulkResult.results = response.map(x => x.value.data).flatMap(it => it.results);
          this.handleBulkResult(bulkResult,
            (entityId) => this.$t(`${this.$t('activatePogSuccess')} ${entityId}`,
            (entityId, error) => (`${this.$t('activatePogError')} ${entityId}: ${error}`)));

        }).finally(() => {
          this.bulkProgressDialog.progress = 100;
        })
    },
    partition(array, n) {
      return array.length ? [array.splice(0, n)].concat(this.partition(array, n)) : [];
    },

    /**Delete a list of given Planograms. */
    deletePlanograms() {
      this.bulkProgressDialog.enabled = true;
      this.deleteDialog.enabled = false;
      this.bulkProgressDialog.progress = 0;

      this.axios.delete('api/planograms/bulk', {
        params: { ids: this.selected.filter((pos) => pos.draftPlanogramId != null).map((pos) => pos.draftPlanogramId) }
      }).then((response) => {
        this.getPos();
        this.handleBulkResult(response.data,
          (entityId) => (`${this.$t('removePogSuccess')} ${entityId} ${this.$t('removePogSuccessRemoved')}`),
          (entityId, error) => (`${this.$t('removePogError')} ${entityId}: ${error}`));
      }).finally(() => {
        this.bulkProgressDialog.progress = 100;
      })
    },

    /**Handle creation os new Planograms. This method is called by children trigger. */
    onFinishedCreation(bulkResult) {
      this.getPos();
      if (bulkResult.results.some(r => r.success)) {
        this.postCreationDialog.message = this.$t('onFinishedCreationMessage') +
        this.$t('onFinishedCreationMessageAddItem');
        this.postCreationDialog.enabled = true;
      }
      this.handleBulkResult(bulkResult,
        (entityId) => this.$t(`${this.$t('createPogSuccess')} ${entityId}`),
        (entityId, error) => (`${this.$t('createPogError')} ${entityId}: ${error}`));
    },

    onCreationError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },

    /**Handle creation of new items added to planograms.*/
    onEntryCreated(bulkResult, isRemove = false) {
      this.posIdsDraftPlanogram = [...this.posIdsDraftPlanogram, ...this.getPosIdsDraftPlanogram(bulkResult)]
      this.onEntryUpdated(bulkResult, isRemove);
    },
    onEntryUpdated(bulkResult, isRemove = false) {
      this.getPos();
      if (bulkResult.results.some(r => r.success) && 
        !this.OperationIsInActivePlanogram(bulkResult)) {
        this.postCreationDialog.message = this.$t('onEntryUpdatedMessage') +
          this.$t('onEntryUpdatedMessageAddItem');
        this.postCreationDialog.enabled = true;
      }
      this.handleAddOrUpdateResult(bulkResult, isRemove)
    },
    handleAddOrUpdateResult(bulkResult, isRemove = false) {
      const messages = bulkResult.results.map((r) => {
        return {
          entityId: r.entityId,
          type: !r.success ? 'error' : r.error == 'AddOnly' ? 'success' : 'info',
          visible: true,
          text: r.error == "Remove" ? ` ${this.$t('removeItemSuccess')} ${this.$t('handleAddOrUpdateResultText')} ${r.entityId} ` : r.error
        }
      })
      if (!isRemove) {
        messages.map((msg) => msg.text = `${this.$t('PDV')} ${msg.entityId}: ${msg.text}`);
      }
      this.bulkProgressDialog.infoMessages = messages;
    },
    handleBulkResult(bulkResult, successMessage, errorMessage) {
      const messages = bulkResult.results.map((r) => {
        return {
          entityId: r.entityId,
          type: r.success ? 'success' : 'error',
          visible: true,
          text: r.success ? successMessage(r.entityId) : errorMessage(r.entityId, r.error)
        }
      })
      this.bulkProgressDialog.infoMessages = [...this.bulkProgressDialog.infoMessages, ...messages];
    },

    /**BulkProgressDialog Methods triggered by Childrens.*/
    onUpdateProgress(progress) {
      this.bulkProgressDialog.progress = progress
    },
    onUpdateProgressDialogEnable(status) {
      this.bulkProgressDialog.infoMessages = []
      this.bulkProgressDialog.progress = 0
      this.bulkProgressDialog.enabled = status
    },
    getPosIdsDraftPlanogram(bulkResult) {
      return bulkResult.results.filter(r => r.error.toLowerCase().includes(this.$t('rascunho'))).map(r => r.entityId)
    },
    OperationIsInActivePlanogram(bulkResult) {
      return bulkResult.results.some((r) => r.error.toLowerCase().includes(this.$t('ativo'))) 
        && this.posIdsDraftPlanogram.length == 0;
    }
  },

  components: {
    'planogram-creation': PlanogramCreationDialog,
    'menu-item': PlanogramActionsMenuItem,
    'entry-dialog': PlanogramAddOrUpdateDialog,
    PlanogramMainHelp,
    'remove-dialog': PlanogramRemoveItemDialog,
    BulkProgressDialog,
    News
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer
  }
</style>
