<template>
  <v-dialog
    persistent
    max-width="800"
    v-model="itemDialog"
    @keydown.esc="itemDialog = false"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        v-on="dialog"
        color="success"
        class="ml-2"
      >
        <v-icon left>add</v-icon>{{ $t('newTaxScenario') }}
      </v-btn>
    </template>

    <v-card>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="saveItem"
      >
        <v-card-title>
          <span class="title">{{ dialogTitle }}</span>
          <!-- HELP GOES HERE as a omponent-->
        </v-card-title>

        <!-- 1st Row name CFOP regime-->
        <v-row class="ma-2">
          <v-col cols="12" md="6">
            <v-text-field
              id ="scenarioName-input"
              :label="$t('nameLabel')"
              v-model="editedEntry.name"
              :rules="[rules.validCharacters, rules.maxCharacters(30)]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                id="regime-input"
                :items="taxationTypes"
                v-model="editedEntry.taxation"
                :label="$t('regimeLabel')"
                item-text="name"
                clearable
                item-value="value"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-col cols="12" md="6">
            <v-text-field
              id="cfop-input"
              :label="$t('cfopLabel')"
              v-model="editedEntry.cfop"
              :rules="[rules.validCharacters, rules.required, 
                rules.exactLength(4)
              ]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                id="states-input"
                :items="states"
                v-model="editedEntry.state"
                :label="$t('stateLabel')"
                item-text="name"
                clearable
                item-value="value"
            ></v-autocomplete>
          </v-col>
        </v-row>
     
        <!-- 2nd Row CST-->
        <v-card-subtitle>
          Códigos de Situações Tributárias (CST)
        </v-card-subtitle>

        <v-row class="ma-2" >
          <v-col cols="12" md="3">
            <v-text-field
              id="icms-input"
              :label="$t('icmsLabel')"
              v-model="editedEntry.cstIcms"
              :rules="[rules.validCharacters]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              id="pis-input"
              :label="$t('pisLabel')"
              v-model="editedEntry.cstPis"
              :rules="[rules.validCharacters]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
          <v-text-field
              id="cofins"
              :label="$t('cofinsLabel')"
              v-model="editedEntry.cstCofins"
              :rules="[rules.validCharacters]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              id="ipi-input"
              :label="$t('ipiLabel')"
              v-model="editedEntry.cstIpi"
              :rules="[rules.validCharacters]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              id="benefit-input"
              :label="$t('benefitLabel')"
              v-model="editedEntry.benefit"
              :rules="[rules.validCharacters]"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- 3rd Row ALIQUOTAS-->
        <v-card-subtitle>
          {{ $t('aliquotasSubtitle') }}
        </v-card-subtitle>

        <v-row class="ma-2">
          <v-col cols="12" md="3">
            <v-text-field
              placeholder="0.00"
              :label="$t('icmsLabel')"
              v-model="editedEntry.aliquotaIcms"
              :rules="[rules.float]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              placeholder="0.00"
              :label="$t('pisLabel')"
              v-model="editedEntry.aliquotaPis"
              :rules="[rules.float]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
          <v-text-field
              placeholder="0.00"
              :label="$t('cofinsLabel')"
              v-model="editedEntry.aliquotaCofins"
              :rules="[rules.float]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              placeholder="0.00"
              :label="$t('ipiLabel')"
              v-model="editedEntry.aliquotaIpi"
              :rules="[rules.float]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="10" class="mr-0 pr-0">
                <v-text-field
                  placeholder="0.00"
                  :label="$t('taxings.brazil.reductionPercentageIcms')"
                  v-model="editedEntry.reductionPercentageIcms"
                  :rules="[rules.float]"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="ml-0 pl-0 mt-2">
                <help-reduction-percentage-icms/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            text 
            color="primary"
            @click.native="itemDialog = false"
            >
              {{ $t('closeButton') }}
            </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid"
            :loading="loading.newItem"
            >
              {{ $t('saveButton') }}
            </v-btn>
        </v-card-actions>

      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>

import BrazilStates from "@/components/BrazilStates";
import { Taxations } from '@/constants/Taxations';
import HelpReductionPercentageIcms from "./HelpReductionPercentageIcms.vue";

  export default {
    props: {
      value: { type: Boolean, required: true },
      editedIndex: { type: Number, required: true },
      editedEntry: { type: Object },
    },
    data() {
      return {
        rules: {
          exactLength: length => (v) => (v || '').length === length 
            || this.$t('exactLengthRule', {length: length})
          ,
          maxCharacters: max => (v) => (v || '').length <= max 
            || this.$t('maxCharactersRule', {max: max})
          ,
          required: (v) => 
            !!v || this.$t('requiredRule'),
          float: (v) => 
            !isNaN(v) || v == null || this.$t('floatRule'),
          validCharacters: (v) =>
            /^[\w.\-+ ]*$/.test(v) || this.$t('validCharactersRule'),
        },
        valid: true,
        loading: {
          newItem: false,
        },
        states: BrazilStates.states,
      };
    },
    computed: {
      itemDialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      dialogTitle() {
        return this.editedIndex === -1 
          ? this.$t('newTaxScenarioTitle')
          : this.$t('editTaxScenarioTitle');
      },
      taxationTypes: function() {
        return Object.keys(Taxations)
          .map(k => ({ name: Taxations[k], value: k }));
    }
    },
    watch: {
      itemDialog(isOpen) {
        if (!isOpen) {
          this.$refs.form.reset();
          this.valid = true;
        }
      },
    },
    methods: {
      saveItem() {
        if (this.$refs.form.validate()) {
          this.loading.newItem = true;
          const editedIndex = this.editedIndex;
          const editedEntry = this.editedEntry;
          if (editedIndex === -1) {
            this.axios
              .post("api/Taxings", editedEntry)
              .then((response) => {
                this.$emit("add", response.data);
              })
              .catch((error) =>
                this.$emit("error", "Erro ao salvar cenário fiscal", error)
              )
              .then(() => (this.loading.newItem = false));
          } else {
            this.axios
              .put(`api/Taxings/${editedEntry.id}`, editedEntry)
              .then((response) => {
                this.$emit("update", response.data);
              })
              .catch((error) => this.$emit("error", "Erro ao salvar item", error))
              .then(() => (this.loading.newItem = false));
          }
        }
      },
    },
    components: {
      HelpReductionPercentageIcms,
    },
  };
</script>
