
import Vue from 'vue';


export default Vue.extend({
  data() {
    return {
      valid: true,
      items: [] as { description: string, barCode: string }[],
      selectedItem: null as any | null,
      search: '' as string,
      loading: false,
      tooltip: false,
      timeoutId: null as number | null
    }
  },
  computed: {
    searchItems(): any[]{
      if (!this.items.some((i: { description: string; barCode: string }) => i.description === this.search)) {
        return [this.search, ...this.items]
      }

      return this.items;
    }
  },
  watch: {
    search(val) {
      if (val && val != this.selectedItem && val.length > 2) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => { this.fetchItems() }, 500);
      }
    }
  },
  methods: {
    async fetchItems() {
      if (!this.search) {
        this.items = [];
        return;
      }
      
      this.loading = true;
      
      try {
        const response = await this.axios.get("/api/Products/productBaseSimpleInfo", {
            params: {
              Page: 1,
              PageSize: 30,
              Search: this.search
            }
          });
        
        const newItems = response.data.items
        .map((prod: any) => { 
          prod.description = (prod.code != "" ? prod.code + " - " : "") + prod.description;
          return prod;
        });

        this.items = [...new Set([...this.items, ...newItems])]
      } catch (error) {
        this.$emit('error', this.$t('barCodeSearchValueInvalid') + (error as string));
      } finally {
        this.loading = false;
      }
    },
    sendForm() {
      if((this.$refs.form as any).validate()) {
        if (typeof this.selectedItem === 'object' && this.selectedItem !== null && 'description' in this.selectedItem) {
          this.$emit('input', this.selectedItem.barCode);
          return;
        }
  
        if (isNaN(this.selectedItem)) {
          this.$emit('error', this.$t('barCodeSearchValueInvalid'));
          return;
        }
        
        this.$emit('input', this.selectedItem);
        return;
      } else {
        this.tooltip = true;
      }
    }
  }

});
