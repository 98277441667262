export default {
    searchCategory: 'Search category',
    noCategories: 'No categories registered',
    confirmDeleteCategory: 'Are you sure you want to delete the category',
    cancelCategory: 'No',
    removeCategory: 'Remove',
    categoryName: 'Category',
    invalidCharacters: 'Enter valid characters',
    categoryRemovedCategory: 'Category removed',
    errorRemovingCategory: 'Error removing category',
    categoryNameNotChanged: 'Name not changed: invalid characters in name',
    errorChangingName: 'Error changing name',
    myCategories: 'My Categories',
    actionsCategory: 'Actions',
    idCategory: 'ID',
    numberOfProductsCategory: 'Number of products',
  };