
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import { Country } from '@/constants/enums';
import { employerIdentificationMask, employerIdentificationPlaceholder } from '@/utility/TextFormatting';
const maskdir = mask;

export default Vue.extend({
  directives: { maskdir },
  props: {
    value: {
      type: String,
      required: true
    },
  },
  computed: {
    employerIdentification: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      }
    },
    userCountry(): Country {
      return (this as any).$auth.user().tenantCountry as Country;
    },
    employerIdentificationLength(): number {
      switch (this.userCountry) {
        case Country.US:
          return 10;
        case Country.BR:
          return 18;
        default:
          return 12;
      }
    },
    employerIdentificationRules(): any[] {
      const rules: any[] = [this.$validations.required];
      switch (this.userCountry) {
        case Country.US:
          rules.push(this.$validations.validateEIN);
          break;
        case Country.BR:
          rules.push(this.$validations.validateCNPJ);
          break;
      }
      return rules;
    },
    formatMaskEmployerIdentification(): string {
      return employerIdentificationMask(this.userCountry, "##########");
    },
    placeholderEmployerIdentification(): string {
      return employerIdentificationPlaceholder(this.userCountry, "0000000000");
    },
    translations() {
      let employerIdentification: string;
      switch (this.userCountry) {
        case Country.US:
          employerIdentification = this.$t('tables.headers.ein').toString();
          break;
        case Country.BR:
          employerIdentification = this.$t('tables.headers.cnpj').toString();
          break;
        case Country.CO:
          employerIdentification = this.$t('tables.headers.nit').toString();
          break;
        default:
          employerIdentification = this.$t('tables.headers.cnpj').toString();
          break;
      }
      return {
        employerIdentification: employerIdentification
      }
    }
  },
  methods: {
    employerIdentificationMask,
    employerIdentificationPlaceholder,
  }
});
