<template>
  <v-container fluid grid-list-lg class="pa-0">
    <v-layout row wrap>
      <v-flex xs12>

        <div class="ma-5 pb-0">
        <span class="subtitle-1">Selecione os sistemas para enviar as credenciais PagSeguro</span>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="sendCredentials">
          <v-layout wrap class="mb-4">
            <v-flex xs12 sm8 md6 lg3>
              <v-text-field
                label="Email"
                v-model="email"
                :rules="[(v) => /\S+@\S+\.\S+/.test(v) || 'Digite um email válido']"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm8 md6 lg3>
              <v-text-field
                label="Senha"
                v-model="password"
                :rules="[(v) => v.length > 3 || 'Digite uma senha válida']"
                required
              ></v-text-field>
            </v-flex>
            <v-btn type="submit" color="success" :loading="loading.button" :disabled="!valid">Enviar</v-btn>
          </v-layout>
        </v-form>
        </div>

        <div class="text-xs mb-4">{{resultText}}</div>
        
          <v-card>
            <v-card-title>
              Pontos de venda
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Pesquisar"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="mb-10">
            <v-data-table 
              v-model="selected" 
              :headers="headers" 
              :items="pos" 
              must-sort 
              :loading="loading.table"
              no-data-text="Sem resultados" 
              no-results-text="Sem resultados" 
              hide-default-footer
              disable-pagination 
              show-select
              :search="search">
                <template #item.machineMachineModelName="{ item }">
                  {{ item.machineMachineModelName }} / {{ item.machineMachineManufacturerName }}
                </template>
                <template #item.localName="{ item }">
                  {{item.localName}} / {{item.specificLocation}}
                </template>
                <template #item.machineHasCoinChanger="{ item }">
                  <v-icon>{{ item.machineHasCoinChanger ? 'check_circle' : 'cancel' }}</v-icon>
                </template>
                <template #item.machineHasBillValidator="{ item }">
                  <v-icon>{{ item.machineHasBillValidator ? 'check_circle' : 'cancel' }}</v-icon>
                </template>
            </v-data-table>
          </div>
          </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data(){
      return {
        pos: [],
        headers: [{ text: 'Ponto', align: 'left', sortable: true, value: 'id' },
                 { text: 'Sist. de pagamento', align: 'left', sortable: true, value: 'boxId' },
                 { text: 'Cód. PDV', align: 'left', sortable: true, value: 'companyCode' },
                 { text: 'Modelo / Fabricante', align: 'left', sortable: true, value: 'machineMachineModelName' },
                 { text: 'Cliente', align: 'left', sortable: true, value: 'localCustomerName' },
                 { text: 'Localização', align: 'left', sortable: true, value: 'localName' },
                 { text: 'Moedeiro', align: 'left', sortable: false, value: 'machineHasCoinChanger'},
                 { text: 'Noteiro', align: 'left', sortable: false, value: 'machineHasBillValidator'}],
        rowsPerPage: [10, 25, 50, { text: 'Todos', value: -1 }],
        loading: { table: true, button: false },
        search: '',
        valid: true,
        email: null,
        password: null,
        selected: [],
        resultText: null
      }
    },
    mounted() {
      this.axios.get('api/PointsOfSale')
          .then((response) => {
            this.loading.table = false;
            this.pos = response.data;
          });
    },
    methods: {
      sendCredentials(){
        if(this.$refs.form.validate()){
          if(this.selected.length > 0){
            this.resultText = null;
            this.loading.button = true;
            this.axios.post('api/CloudMessages/PagSeguroCredentials', {
                credentials: {
                  email: this.email,
                  password: this.password
                },
                ids: this.selected.map((x) => x.boxId),
                authenticate: false
              })
              .then((response) => {
                if(response.data.length > 0){
                  let okIds = response.data.filter((x) => x.result === 'Ok').map((x) => x.id);
                  let errorIds = response.data.filter((x) => x.result === 'Failed').map((x) => x.id);
                  let text = '';
                  if(okIds.length > 0){
                    text = "Crendenciais enviadas para os sistemas: " + okIds.join(", ") + ". ";
                  }
                  if(errorIds.length > 0){
                    text += "Erro ao enviar para os sistemas: " + errorIds.join(", ");
                  }
                  this.resultText = text;
                }
                else{
                  this.resultText = "Erro ao enviar credenciais";
                }
                
              })
              .catch(() => {
                this.resultText = "Erro ao enviar credenciais";
              })
              .then(() => {
                this.loading.button = false;
              });
          }
          else{
            this.resultText = "Selecione pelo menos um ponto de venda";
          }
        }
      },
    }
  };
</script>