export default {
  filters: {
    filtersActions: 'Filtros e Ações',
    labels: {
      all: 'Todos',
      period: 'Período',
      pos: 'Ponto de Venda',
      onlySubtractedItems: 'Somente itens subtraídos',
      advanced: 'Avançado',
      calendar: "Calendário",
      inventoryType: 'Tipo de Estoque',
      central: 'Central',
      search: 'Pesquisar',
      searchProducts: 'Pesquisar um produto',
      months: {
        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro"
      },
      customer: 'Cliente',
      location: 'Localização',
      locationSpecific: 'Localização Específica',
      pointOfSale: 'Ponto de Venda',
      result: 'Resultado',
      minimumPrice: 'Preço Mínimo',
      maximumPrice: 'Preço Máximo',
      product: 'Produto',
      lastCardDigits: 'Últimos Dígitos do Cartão',
      date: 'Data',
      initialDate: 'Data Inicial',
      finalDate: 'Data Final',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      verificationStatus: 'Status de Verificação',
      viewOnlyTransactionItemsSubtracted: 'Ver apenas transações com itens subtraídos',
      viewProfit: 'Ver Lucro',
      accessDetails: 'Detalhes de Acesso',
      audit: 'Auditoria',
      actions: 'Ações',
      accessResult: 'Acessar Resultado',
      operationType: 'Tipo de Operação',
      lastMovement: 'Última movimentação'
    },
    messages: {
      selectMonth: "Selecione um mês",
      selectCustomer: 'Selecionar um cliente',
      selectLocation: 'Selecionar uma localização',
      selectResult: 'Selecionar resultado para ver',
      selectOperationType: 'Selecione um tipo de operação',
    },
    timelineDateAlert: 'Intervalo máximo de três dias',
    orderSearch: 'Ordenar / Pesquisar',
    status: 'Status',
    customerName: 'Nome do cliente',
    inventory: 'Estoque',
    lastSale: 'Última venda',
    creationDate: 'Data de criação (ID do ponto de venda)',
    order: 'Ordenar',
    sort:{
      asc: 'Crescente',
      desc: 'Decrescente',
    },
  },
}