
import Vue from 'vue';
import { ProgressLinear } from "@/types/ProgressLinear"; 
import LinearBar from '@/components/LinearBar.vue';
import agent from '@/api/agent';
import MostSoldCategoriesHelp from '../helpers-new/MostSoldCategoriesHelp.vue';

export default Vue.extend({
  props: {
    mustCallEndpoints: { type: Boolean, default: false },
    form: { type: Object, required: true },
    selectedDateInterval: { required: true }
  },
  components: {
    LinearBar,
    MostSoldCategoriesHelp
  },
  data() {
    return {
      categoriesItems: [] as ProgressLinear[],
      green: (this.$vuetify.theme as any).defaults.dashboard.dashboardGreenDarken1,
      dateIntervals: [{ text: this.$t('last30days'), value: 30 },
        { text: this.$t('last15days'), value: 15 },
        { text: this.$t('last7days'), value: 7 },
        { text: this.$t('today'), value: 0 },
        { text: this.$t('currentMonth'), value: "currentMonth" }],
    }
  },
  mounted() {
    this.getMostSoldCategories(this.form)
  },
  watch: {
    mustCallEndpoints(value) {
      if (value) {
        this.getMostSoldCategories(this.form)
      }
    }
  },
  computed: {
    dateInterval() {
      const a = (this as any).dateIntervals.find((di: any) => di.value == this.selectedDateInterval)
      return a?.text
    }
  },
  methods: {
    async getMostSoldCategories(form: any) {
      try {
        const response = await agent.SalesDashboard.getMostSoldCategories(form) as any[];
        const totalsSum = response.reduce((acc, i) => acc + i.totals, 0)

        this.categoriesItems = response.map(i => {
          return {
            label: i.category,
            value: (this as any).$currencyFormatter.format(i.totals),
            color: (this.$vuetify.theme as any).defaults.dashboard.dashboardGreenDarken1,
            percentage: Math.round((i.totals / totalsSum) * 100) / 100
          }
        })

      }
      catch(error) {
        this.handleError(this.$t('mostSoldCategoriesErrorMessage') as string, error)
      }      
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  },
})
