
import Vue from 'vue';
import ConnectivityTimeLineHelp from './components/ConnectivityTimeLineHelp.vue';
import ConnectivityTimeLineFilters from './components/ConnectivityTimeLineFilters.vue';
import TimeLineCharts from './components/TimeLineChart'


export default Vue.extend({
  components: {
    ConnectivityTimeLineHelp,
    "time-line-filters": ConnectivityTimeLineFilters,
    TimeLineCharts
  },
  data() {
    return {
      timeLines: [] as any,
      dateInterval: [] as string[],
      loading: false
    }
  },
  methods: {
    getPosTimeLines(form: any): void {
      this.loading = true;
      this.axios.get("api/PointsOfSaleConnectivity/midas-telemetry-timeline", { params: form })
        .then((response) => {
          this.dateInterval = [
            `${form.minDate} ${form.minTime}`,
            `${form.maxDate} ${form.maxTime}`
            ]
          this.loading = false
          this.timeLines = response.data
        })
        .catch((error) => {
          (this.$refs.feedback as any).handleError(`${this.$t('connectivity.errors.loadTimeline')}`, error)
          this.loading = false
        })
    }
  }
})
