<template>
  <v-layout wrap fill-height>
    <v-flex xs12>
      <div><news v-if="isBrazil"></news>
      <sales-report-filters :activeTab="activeTab" v-on:modify="updateReport"></sales-report-filters>
      <v-tabs grow background-color="grey lighten-4" v-model="activeTab">
        <v-tab :key="0">{{$t('salesByDay')}}</v-tab>
        <v-tab :key="1">{{$t('topAndBottonSellingPos')}}</v-tab>
        <v-tab :key="2">{{$t('totalSalesByPos')}}</v-tab>
        <v-tab :key="3">{{$t('salesByProduct')}}</v-tab>
        <v-tab-item :key="0">
          <v-card flat class="mb-16">
            <v-card-title primary-title>
              <div class="title">{{$t('salesByDay')}}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-progress-linear v-if="!dailyChart" indeterminate></v-progress-linear>
              <line-chart :height="150" v-if="dailyChart" :labels="dailyLabels" :datasets="dailyDatasets" :yLabelString="yLabel"></line-chart>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-card flat>
            <v-card-title primary-title>
              <div class="title">{{$t('topSellingPos')}}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-progress-linear v-if="!topPosChart" indeterminate></v-progress-linear>
              <bar-chart :height="150" v-if="topPosChart" :labels="topPosLabels" :datasets="topPosDatasets"></bar-chart>
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-title primary-title>
              <div class="title">{{$t('bottomSellingPos')}}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-progress-linear v-if="!bottomPosChart" indeterminate></v-progress-linear>
              <bar-chart
                :height="150" v-if="bottomPosChart" :labels="bottomPosLabels" :datasets="bottomPosDatasets"
              ></bar-chart>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2">
          <v-card flat class="mb-16">
            <v-card-title>
              <div class="title">{{$t('totalSalesByPos')}}</div>
              <v-spacer></v-spacer>
              <v-btn color="info" @click.native="getPosSalesExcel" :loading="loadingExcel">
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear v-if="!allPosTable" indeterminate></v-progress-linear>
            <v-data-table
              v-if="allPosTable"
              :headers="posHeaders"
              :items="allPos" must-sort
              :no-data-text="$t('noResults')"
              hide-default-footer
              disable-pagination
              sort-by="total"
              sort-desc
            >
              <template #item="{ item }">
                <tr>
                  <td>{{item.pointOfSale.boxId}}</td>
                  <td>{{item.pointOfSale.machineMachineModelName}} / {{item.pointOfSale.machineMachineManufacturerName}}</td>
                  <td>{{item.pointOfSale.localCustomerName}}</td>
                  <td>{{item.pointOfSale.localName}} / {{item.pointOfSale.specificLocation}}</td>
                  <td>{{$currencyFormatter.format(item.totalDebit)}}</td>
                  <td>{{$currencyFormatter.format(item.totalCredit)}}</td>
                  <td v-if="isBrazil()">{{$currencyFormatter.format(item.totalVoucher)}}</td>
                  <td>{{$currencyFormatter.format(item.totalCash)}}</td>
                  <td v-if="isBrazil()">{{$currencyFormatter.format(item.totalPix)}}</td>
                  <td v-if="isBrazil()">{{$currencyFormatter.format(item.totalPicPay)}}</td>
                  <td v-if="isBrazil()">{{$currencyFormatter.format(item.totalAppCredit)}}</td>
                  <td>{{$currencyFormatter.format(item.totalCashless)}}</td>
                  <td>{{$currencyFormatter.format(item.total)}}</td>
                </tr>
              </template>
              <template #body.append>
                <tr>
                <td :colspan="isBrazil() ? posHeaders.length - 9 : posHeaders.length - 5"><strong>{{$t('totalLowerCase')}}</strong></td>
                <td><strong>{{ $currencyFormatter.format(allPosTotals.totalDebit) }}</strong></td>
                <td><strong>{{ $currencyFormatter.format(allPosTotals.totalCredit) }}</strong></td>
                <td v-if="isBrazil()"><strong>{{ $currencyFormatter.format(allPosTotals.totalVoucher) }}</strong></td>
                <td><strong>{{ $currencyFormatter.format(allPosTotals.totalCash) }}</strong></td>
                <td v-if="isBrazil()"><strong>{{ $currencyFormatter.format(allPosTotals.totalPix) }}</strong></td>
                <td v-if="isBrazil()"><strong>{{ $currencyFormatter.format(allPosTotals.totalPicPay) }}</strong></td>
                <td v-if="isBrazil()"><strong>{{ $currencyFormatter.format(allPosTotals.totalAppCredit) }}</strong></td>
                <td><strong>{{ $currencyFormatter.format(allPosTotals.totalCashless) }}</strong></td>
                <td><strong>{{ $currencyFormatter.format(allPosTotals.total) }}</strong></td>
                </tr>
              </template>
            </v-data-table>
            <v-divider class="mb-8"></v-divider>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="3">
          <v-card flat class="mb-16">
            <v-card-title>
              <div class="title">{{$t('salesByProduct')}}</div>
              <v-spacer></v-spacer>
              <v-form @submit.prevent="getProductsReport">
                <v-text-field
                  class="mr-4 pt-0"
                  v-model="tableProducts.search"
                  append-icon="search"
                  :label="$t('searchProduct')"
                  single-line
                  hide-details
                  clearable
                  filled
                  rounded
                  dense
                  @click:clear="tableProducts.search = ''; getProductsReport()"
                  @click:append="tableProducts.options.page = 1; getProductsReport()"
                  @input="tableProducts.options.page = 1"
                ></v-text-field>
              </v-form>
              <v-btn color="info" @click.native="getProductSalesExcel" :loading="productsExcelLoading">
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear v-if="!productsTable" indeterminate></v-progress-linear>
            <v-data-table
              v-if="productsTable"
              :loading="tableProducts.loading"
              :headers="productHeaders"
              :items="products"
              must-sort
              :options.sync="tableProducts.options"
              :server-items-length="tableProducts.totalItems"
              :no-data-text="$t('noResults')"
              :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
            >
              <template #item="props">
                <tr>
                  <td :class="getProductEnabledClass(props.item)">
                    {{props.item.product != null ? props.item.product.code : ''}}
                  </td>
                  <td :class="getProductEnabledClass(props.item)">
                    {{props.item.product != null ? props.item.product.description : $t('productNotRegistered')}}
                  </td>
                  <td :class="getProductEnabledClass(props.item)">
                    {{props.item.product != null ? props.item.product.categoryName : '' }}
                  </td>
                  <td :class="getProductEnabledClass(props.item)">
                    {{ (props.item.product || {}).defaultPrice != null ?
                      $currencyFormatter.format(props.item.product.defaultPrice) : '' }}
                  </td>
                  <td :class="getProductEnabledClass(props.item)">
                    {{ props.item.averagePrice != null ? $currencyFormatter.format(props.item.averagePrice) : null }}
                  </td>
                  <td :class="getProductEnabledClass(props.item)">{{ props.item.count }}</td>
                  <td :class="getProductEnabledClass(props.item)">{{ $currencyFormatter.format(props.item.total) }}</td>
                </tr>
              </template>
              <template #body.append>
                <tr>
                <td :colspan="productHeaders.length - 2"><strong>Total</strong></td>
                <td><strong>{{ productsCount }}</strong></td>
                <td><strong>{{ $currencyFormatter.format(productsSales) }}</strong></td>
                </tr>
              </template>
            </v-data-table>
            <v-divider class="mb-8"></v-divider>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import BarChart from '@/report/BarChart'
import LineChart from '@/report/LineChart'
import SalesReportFilters from '@/components/filters/SalesReportFilters';
import download from '@/components/Download'
import { shouldRefreshData } from '@/components/TableOptionsComparator';
import { toApiPagination } from '../components/TableOptionsComparator';
import News from '@/components/News.vue';

export default {
  data(){
    return {
      activeTab: 0,
      dailyLabels: [],
      dailyDatasets: [],
      dailyChart: false,
      topPosLabels: [],
      topPosDatasets: [],
      topPosChart: false,
      bottomPosLabels: [],
      bottomPosDatasets: [],
      bottomPosChart: false,
      allPos: [],
      products: [],
      productsCount: null,
      productsSales: null,
      allPosTotals: {
        totalDebit: null,
        totalCredit: null,
        totalVoucher: null,
        totalCash: null,
        totalCashless: null,
        total: null
      },
      posHeaders: [
        { text: this.$t("pointOfSaleSystem"), align: 'left', sortable: true, value: 'pointOfSale.boxId' },
        { text: this.$t("modelManufacturer"), align: 'left', sortable: true, value: 'pointOfSale.machineMachineModelName' },
        { text: this.$t("client"), align: 'left', sortable: true, value: 'pointOfSale.localCustomerName' },
        { text: this.$t("location"), align: 'left', sortable: true, value: 'pointOfSale.localName' },
        { text: this.$t("debitPaymentMethods"), align: 'left', sortable: true, value: 'totalDebit' },
        { text: this.$t("creditPaymentMethods"), align: 'left', sortable: true, value: 'totalCredit' },
        { text: this.$t("voucherPaymentMethods"), align: 'left', sortable: true, value: 'totalVoucher' },
        { text: this.$t("cashPaymentMethods"), align: 'left', sortable: true, value: 'totalCash' },
        { text: this.$t("pixPaymentMethods"), align: 'left', sortable: true, value: 'totalPix' },
        { text: this.$t("picPayPaymentMethods"), align: 'left', sortable: true, value: 'totalPicPay' },
        { text: this.$t("appCreditCardPaymentMethods"), align: 'left', sortable: true, value: 'totalAppCredit' },
        { text: this.$t("cashlessPaymentMethods"), align: 'left', sortable: true, value: 'totalCashless' },
        { text: this.$t("totalLowerCase"), align: 'left', sortable: true, value: 'total' }
    ],

      productHeaders: [
        { text: this.$t("productCode"), align: 'left', sortable: false, value: 'product.code' },
        { text: this.$t("productDescription"), align: 'left', sortable: false, value: 'product.description' },
        { text: this.$t("productCategory"), align: 'left', sortable: false, value: 'product.categoryName' },
        { text: this.$t('defaultPrice') , align: 'left', sortable: false, value: 'product.defaultPrice' },
        { text: this.$t('averagePrice'), align: 'left', sortable: false, value: 'product.averagePrice' },
        { text: this.$t('soldQuantity'), align: 'left', sortable: true, value: 'count' },
        { text: this.$t('soldValue'), align: 'left', sortable: true, value: 'total' },
      ],
      allPosTable: false,
      loadingExcel: false,
      productsExcelLoading: false,
      reportFilters: null,
      tableProducts: {
        loading: false,
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['total'],
          sortDesc: [true],
        },
        search: "",
        totalItems: 0
      },
      tablePos: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['id'],
          sortDesc: [false],
          mustSort: true
        },
        totalItems: 0
      },
      productsTable: false,
      yLabel: this.$t('totalSales'),
    }
  },
  watch: {
    'tableProducts.options': {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getProductsReport();
        }
      }
    },
  },
  mounted(){
    if(!this.isBrazil()){
        let brazilianPayment = [this.$t("voucherPaymentMethods"), this.$t("pixPaymentMethods"), this.$t("picPayPaymentMethods"), this.$t("appCreditCardPaymentMethods")]
        this.posHeaders = this.posHeaders.filter(header => !brazilianPayment.includes(header.text))
      }
    
    if (this.$route.query.tab != undefined) {
      this.activeTab = this.$route.query.tab;
      let params = {};
      this.$router.replace({ query: params });
    }
   
    this.getProductsReport();
    
  },
  methods: {
    updateReport(form){
      this.reportFilters = form;
      this.dailyChart = false;
      this.topPosChart = false;
      this.bottomPosChart = false;
      this.allPosTable = false;
      this.axios.get('api/Report/TotalSales', { params: form })
        .then((response) => {
          this.crateDailyDataset(response.data);
        });
      this.axios.get('api/Report/PosSales', { params: form })
        .then((response) => {          
          this.createPosCharts(response.data.posSales);
          this.cratePosTable(response.data);
          
        });
      this.getProductsReport();
    },
    getProductsReport() {
      this.tableProducts.loading = true;
      let params = toApiPagination(this.tableProducts.options, this.tableProducts.search);
      params = this.insertFiltersFromForm(params);
      this.axios.get('api/Report/PaginatedProducts', { params: params })
        .then((response) => {
          this.products = response.data.items;
          this.productsTable = true;
          this.tableProducts.totalItems = response.data.totalItems;
          this.productsCount = response.data.count;
          this.productsSales = response.data.sales;
          this.tableProducts.loading = false;
        });
    },
    getPosSalesExcel() {
      if(this.reportFilters != null) {
        this.loadingExcel = true;
        this.axios.get('api/Report/PosSales/Excel', { params: this.reportFilters, responseType: 'blob' })
        .then((response) => {
            this.loadingExcel = false;
            download(response, `${this.$t('salesByPos')}.xlsx`);
        })
        .catch(() => {
          this.loadingExcel = false;
        });
      }
    },
    getProductSalesExcel() {
      if(this.reportFilters != null) {
        this.productsExcelLoading = true;
        this.axios.get('api/Report/Products/Excel', { params: this.reportFilters, responseType: 'blob' })
          .then((response) => {
            this.productsExcelLoading = false;
            download(response, `${this.$t('salesByProduct')}.xlsx`);
          })
          .catch(() => {
            this.productsExcelLoading = false;
          });
      }
    },
    crateDailyDataset(data){
      this.dailyLabels = data.map((x) => {
        return moment(x.date).format('DD/MM') + ' ' + moment(x.date).format('ddd');
      });
      let totalDebit = data.map((x) => x.totalDebit);
      let totalCredit = data.map((x) => x.totalCredit);
      let totalVoucher = data.map((x) => x.totalVoucher);
      let totalCash = data.map((x) => x.totalCash);
      let totalPix = data.map((x) => x.totalPix);
      let totalPicPay = data.map((x) => x.totalPicPay);
      let totalAppCredit = data.map((x) => x.totalAppCredit);
      let totalCashless = data.map((x) => x.totalCashless);
      this.dailyDatasets = this.createPaymentMethodsDatasets(totalDebit, totalCredit, totalVoucher,
        totalCash, totalPix, totalPicPay, totalAppCredit, totalCashless);

      this.dailyChart = true;
    },
    cratePosTable(data) {
      this.allPos = data.posSales;
      this.allPosTotals = data.totals;

      this.allPosTable = true;
    },



  createPosCharts(data){
      let topData = data.slice(0, 10);
      let bottomData = data.reverse().slice(0, 10);

      let topLabelsDatasets = this.createSinglePosDatasets(topData);
      let bottomLabelsDatasets = this.createSinglePosDatasets(bottomData);
 
      this.topPosLabels = topLabelsDatasets.labels;
      this.topPosDatasets = topLabelsDatasets.datasets;

      this.bottomPosLabels = bottomLabelsDatasets.labels;

      this.bottomPosDatasets = bottomLabelsDatasets.datasets;

      this.topPosChart = true;
      this.bottomPosChart = true;
    },
    createSinglePosDatasets(data){
      return {
        labels: data.map((x) => [x.pointOfSale.localCustomerName, x.pointOfSale.localName,
          x.pointOfSale.specificLocation ? x.pointOfSale.specificLocation : '']),
        datasets: this.createPaymentMethodsDatasets(
          data.map((x) => x.totalDebit),
          data.map((x) => x.totalCredit),
          data.map((x) => x.totalVoucher),
          data.map((x) => x.totalCash),
          data.map((x) => x.totalPix),
          data.map((x) => x.totalPicPay),
          data.map((x) => x.totalAppCredit),
          data.map((x) => x.totalCashless)
        )
      };
    },
    createPaymentMethodsDatasets(debit, credit, voucher, cash, pix, picPay, appCredit, cashless){
      var dataSet = [
        {
          label: this.$t('cashPaymentMethods'),
          backgroundColor: '#116611',
          data: cash,
        },
         {
          label: this.$t('debitPaymentMethods'),
          backgroundColor: '#718EA4',
          data: debit,
        },
        {
          label: this.$t('creditPaymentMethods'),
          backgroundColor: '#29506D',
          data: credit,
        },
        {
          label: this.$t("cashlessPaymentMethods"),
          backgroundColor: '#3A5FCD',
          data: cashless,
        },
      ]

      if(this.isBrazil()){

        var brazilianPayment = [
        {
          label: this.$t("voucherPaymentMethods"),
          backgroundColor: '#042037',
          data: voucher,
        },
        {
          label: this.$t("pixPaymentMethods"),
          backgroundColor: '#a53e79',
          data: pix
        },
        {
          label: this.$t("picPayPaymentMethods"),
          backgroundColor: '#6d2950',
          data: picPay
        },
        {
          label: this.$t("appCreditCardPaymentMethods"),
          backgroundColor: '#b77600',
          data: appCredit
        },
        
      ];

       dataSet = dataSet.concat(brazilianPayment)
      }

      return dataSet;
    },
    getProductEnabledClass(item){
      return item.product !== null ? '' :'grey--text'
    },
    insertFiltersFromForm(params) {
      const form = this.reportFilters;
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.minTime = form.minTime;
      params.maxTime = form.maxTime;
      params.customerId = form.customerId;
      params.localId = form.localId;
      params.pointOfSaleId = form.pointOfSaleId;
      params.categoryId = form.categoryId;
      params.state = form.state ? [form.state] : null;
      params.timezoneOffset = new Date().getTimezoneOffset()
      return params;
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },
  components: {
      'bar-chart': BarChart,
      'line-chart': LineChart,
      'sales-report-filters': SalesReportFilters,
      News,
  }
}
</script>
