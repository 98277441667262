
import Vue from 'vue'
import {primaryUnlockFilter} from "@/types/UnlockResult";
import {getPrimaryUnlockFilterTranslation} from "@/types/UnlockTranslationMapper";


export default Vue.extend({
    data(){
        return{
            unlockResultItems: [
            primaryUnlockFilter.Error,
            primaryUnlockFilter.Invalid,
            primaryUnlockFilter.Success] as primaryUnlockFilter[],
        }
    },
    props: {
      value: { type: Array as () => primaryUnlockFilter[], required: true },
    },
    computed: {
        selectedUnlockResult: {
            get(): primaryUnlockFilter[] {
                return this.value;
            },
            set(value: primaryUnlockFilter[]) {
                this.$emit('input', value);
            },
        },
        translatedUnlockResultItems() {
            return (this as any).unlockResultItems.map((item: primaryUnlockFilter) => ({
                value: item.valueOf(),
                label: (this as any).getPrimaryUnlockFilterTranslation(item),
            }));
        },
    },
    methods: {
        getColor(value: primaryUnlockFilter){
            if(value === primaryUnlockFilter.Success){
                return 'success'
            }
            if(value === primaryUnlockFilter.Invalid){
                return 'warning'
            }
            else{
                return 'error'
            }
        },
        getPrimaryUnlockFilterTranslation
    }, 
})
