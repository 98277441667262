
import Vue from 'vue';
import HelpVideo from '@/components/HelpVideo.vue'

export default Vue.extend({
  components: {
    'help-video': HelpVideo
  },
  props: {
    value: {type: Boolean, required: true},
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  data() {
    return {
      translations: {
        help: {
          about: {
            title: this.$t('priceTable.help.about.title').toString(),
            description: this.$t('priceTable.help.about.description').toString(),
          },
        }
      },
    }
  },
});
