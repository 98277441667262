export default {
    productSalesCardTitle: 'Consulta de Produtos',
    productSalesInfoStateLabel: 'Estado',
    searchProductPlaceholder: 'Pesquise seu produto aqui',
    productRankingLabel: 'Classificação do produto',
    maxMarketPriceLabel: 'Maior preço do mercado',
    minMarketPriceLabel: 'Menor preço do mercado',
    averageMarketPriceLabel: 'Preço médio do mercado',
    productSalesNoResultsMessage: 'Sem resultados',
    noDescriptionMessage: 'Sem descrição para o código de barras',
    noImageAvailable: 'Sem imagem disponível',
    getProductPerformanceError: 'Erro ao carregar vendas do produto: Produto não foi vendido no período filtrado.',
    getProductPerformanceProductSalesError: 'Erro ao carregar vendas do produto',
    getProductDescriptionError: 'Erro ao carregar nome e imagem do produto: Produto não cadastrado no sistema.',
    insertBarcode: 'Insira um código de barras:',
    productSalesLabel: 'vendas',
    standardPrice: 'Preço padrão no sistema',
    nullBarCodeWarningMessage: `Para realizar a pesquisa, precisamos do código de barras do produto cadastrado. Esse
        produto não tem código de barras cadastrado no seu ambiente. Adicione
        um código de barras ao produto para poder pesquisá-lo.`,
    clickHere: "Clique aqui",
    toEditTheProduct: "para editar o produto.",
};