export default {
  mostSoldCategoriesTitle: 'Categorias mais vendidas',
  mostSoldCategoriesSubtitle: 'Identifique as categorias mais populares em sua loja',
  mostSoldCategoriesErrorMessage: 'Houve um erro ao carregar as informações mais vendidas',
  mostSoldCategoriesHelpTitle: 'Categorias mais vendidas',
  mostSoldCategoriesHelpText:
  `
  <p style="text-align: justify;">
    Este indicador exibe as cinco principais categorias de produtos vendidos,
    oferecendo uma visão rápida e direta das áreas mais lucrativas do seu negócio.
  </p>
  <p style="text-align: justify;">
    As categorias são classificadas com base no volume de vendas, permitindo uma
    fácil identificação das tendências de consumo e áreas de oportunidade.
    Use essa informação para direcionar estratégias de marketing, otimizar o estoque e impulsionar suas vendas
  </p>  
  `
}