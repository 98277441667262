<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      v-if="$auth.check()"
      v-model="drawer"
      :permanent="isWebScreen"
      :clipped="!isWebScreen"
      fixed
      :width="isWebScreen ? 100 : '100%'"
    >
      <template #default v-if="isWebScreen">
        <WebNavigationDrawer
          :activeGroup="activeGroup"
          :favoritePages="favoritePages"
          :generalPages="generalPages"
          :salesPages="salesPages"
          :logisticsPages="logisticsPages"
          :telemetryPages="telemetryPages"
          :registerPages="registerPages"
          :crmPages="crmPages"
          :securityPages="securityPages"
          :billingPages="billingPages"
          :amlabsPages="amlabsPages"
          :showSalesButton="showSalesButton"
          :userIsSupplier="userIsSupplier"
          :userIsTelemetry="userIsTelemetry"
          :userIsRegister="userIsRegister"
          :userIsAppAdministrator="userIsAppAdministrator"
          :userIsSecurity="userIsSecurity"
          :user-is-manager="userIsManager"
          :user-is-support="userIsSupport"
          :isBrazil="isBrazil"
          :userIsRemoteMonitoring="userIsRemoteMonitoring"
        />
      </template>
      <template #default v-else>
        <v-toolbar light dense>
          <img src="../public/assets/logo-new.svg" style="height: 40px; margin: 4px 0px 4px 8px;"/>
          <v-spacer></v-spacer>
          <v-icon @click.stop="drawer = !drawer" class="d-md-none">mdi-close</v-icon>
          <!-- <v-btn icon v-on:click="$auth.logout()"><v-icon>mdi-logout</v-icon></v-btn>   -->
        </v-toolbar>
        <MobileNavigationDrawer
          :favoritePages="favoritePages"
          :generalPages="generalPages"
          :salesPages="salesPages"
          :logisticsPages="logisticsPages"
          :telemetryPages="telemetryPages"
          :registerPages="registerPages"
          :crmPages="crmPages"
          :securityPages="securityPages"
          :billingPages="billingPages"
          :amlabsPages="amlabsPages"
          :showSalesButton="showSalesButton"
          :userIsSupplier="userIsSupplier"
          :userIsTelemetry="userIsTelemetry"
          :userIsRegister="userIsRegister"
          :userIsAppAdministrator="userIsAppAdministrator"
          :userIsSecurity="userIsSecurity"
          :user-is-manager="userIsManager"
          :user-is-support="userIsSupport"
          :isBrazil="isBrazil"
          :userIsRemoteMonitoring="userIsRemoteMonitoring"
        />
      </template>
      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$auth.check()"
      app
      dense
      :clipped-left="!isWebScreen"
      :dark="isWebScreen"
      :color="isWebScreen ? barColor : null"
    >
      <template #default>
        <WebAppBar
          v-if="isWebScreen"
          :drawer="drawer"
          @change="(value) => { drawer = value }"
          @favoritePagesUpdate="updateFavoritePages"
          :listed-pages="allPages"
          :color="barColor"
        />
        <!-- Mobile App Bar -->
        <v-row v-else-if="!isCellphoneScreen">
          <img src="../public/assets/logo-new.svg" style="height: 40px; margin: 4px 0px 4px 8px;"/>
          <v-spacer></v-spacer>
          <portal-target class="toolbarItemsPortal" name="toolbar" style="width: 500px"></portal-target>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
          <v-btn icon v-on:click="$auth.logout()"><v-icon>mdi-logout</v-icon></v-btn>
        </v-row>
        <!-- Extra Small Screens -->
        <v-row v-else class="cellphone-app-bar">
          <div class="cellphone-logo">
            <img src="../public/assets/logo-new.svg" style="margin: 4px 0px 4px 8px;"/>
          </div>
          <v-spacer style="flex: 1 50"></v-spacer>
          <div style="flex: 0 1 100px">
            <portal-target class="toolbarItemsPortal" style name="toolbar"></portal-target>
          </div>
          <v-app-bar-nav-icon small @click.stop="drawer = !drawer" class="d-md-none"></v-app-bar-nav-icon>
          <v-btn small icon v-on:click="$auth.logout()"><v-icon>mdi-logout</v-icon></v-btn>
        </v-row>
      </template>
      <template #extension v-if="!isWebScreen">
        <v-toolbar :color="barColor" dense dark>
          <MobileAppBar
            @favoritePagesUpdate="updateFavoritePages"
            :listed-pages="allPages"
          />
        </v-toolbar>
      </template>
    </v-app-bar>

    <NpsFeedbackDialog v-model="showNpsDialog" @close="() => showNpsDialog = false"/>
    <v-main v-if="$auth.check()">
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </v-main>
    <password-reset v-else-if="$route.path === '/redefinir-senha'"></password-reset>
    <support-login v-else-if="$route.path === '/login-suporte'"></support-login>
    <app-login v-else></app-login>

    <snack-bar/>
  </v-app>
</template>

<script>

import AppLogin from '@/components/AppLogin';
import PasswordReset from '@/views/PasswordReset';
import {
  ACCOUNTING_HIERARCHY,
  APP_ADMINISTRATOR_HIERARCHY,
  CASHLESS_VIEWER_HIERARCHY, MANAGER_HIERARCHY,
  MARKET_HIERARCHY,
  PLANOGRAM_VIEWER_HIERARCHY,
  PRICE_MANAGER_HIERARCHY,
  REGISTERS_HIERARCHY,
  SECURITY_HIERARCHY,
  SUPPLIER_HIERARCHY,
  TAXINGS_HIERARCHY,
  SUPPORT_ROLE,
  TELEMETRY_HIERARCHY,
  TRANSACTIONS_VIEWER_HIERARCHY,
  BUILDING_MANAGER_ROLE,
  OPERATIONAL_DASHBOARD_HIERARCHY,
  REMOTE_MONITORING_ROLE,
} from '@/constants/Roles';
import SupportLogin from '@/views/SupportLogin.vue';
import WebAppBar from '@/navigation/WebAppBar.vue';
import MobileAppBar from './navigation/MobileAppBar.vue';
import WebNavigationDrawer from './navigation/WebNavigationDrawer.vue';
import MobileNavigationDrawer from './navigation/MobileNavigationDrawer.vue';
import i18n from './locales/i18n';
import NpsFeedbackDialog from './views/NpsFeedbackDialog.vue';
import moment from "moment";
import SnackBar from "@/components/common/SnackBar.vue";
import { useDataStore } from '@/stores/notifications';

export default {
  props: {
    source: String
  },
  setup() {
    const store  = useDataStore();
    setInterval(() => {
      store.verifyNotification();
    }, 600000);
  },
  data() {
    return {
      clipped: false,
      fixed: false,
      drawer: null,
      title: 'Lista de máquinas',
      swagger: '',
      colorsPalette: [
        { button: "telemetryPages", color: "#86009B" },
        { button: "salesPages", color: "#4E009B" },
        { button: "logisticsPages", color: "#03009B" },
        { button: "generalPages", color: "#00479B" },
        { button: "registerPages", color: "#00889B" },
        { button: "crmPages", color: "#009B92" },
        { button: "securityPages", color: "#009B5A" },
        { button: "billingPages", color: "#00B31D" },
        { button: "amlabsPages", color: "#212121"},
        { button: "profilePage", color: "#0D1F2D"}
      ],
      barColor: "#00479B", // Color of home page (dashboard)
      favoritePages: [],
      showNpsDialog: false
    };
  },
  watch: {
    '$auth.watch.authenticated': function (value) {
      this.renderChat();
      if (value) {
        setTimeout(() => { this.checkNpsDialog() }, 600);
        setTimeout(() => { this.checkNotifications() }, 600);
      }
    },
    activeGroup(value) {
      const color = this.colorsPalette.find(i => i.button == value).color
      this.barColor = color ?? "#212121";
    }
  },
  computed: {
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
    isWebScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isCellphoneScreen() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    userIsManager() {
      return MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsTelemetry() {
      return TELEMETRY_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsTransactionViewer() {
      return TRANSACTIONS_VIEWER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsSupplier() {
      return SUPPLIER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsSecurity() {
      return SECURITY_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsCashlessViewer() {
      return CASHLESS_VIEWER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsMarket() {
      return MARKET_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsAppAdministrator() {
      return APP_ADMINISTRATOR_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsPriceManager() {
      return PRICE_MANAGER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsPlanogramViewer() {
      return PLANOGRAM_VIEWER_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsRegister() {
      return REGISTERS_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsAccounting() {
      return ACCOUNTING_HIERARCHY.some(role => this.$auth.check(role));
    },
    userIsSupport() {
      return this.$auth.check(SUPPORT_ROLE);
    },
    userIsTaxing() {
      return TAXINGS_HIERARCHY.some(role => this.$auth.check(role))
    },
    userIsBuildingManager() {
      return this.$auth.check(BUILDING_MANAGER_ROLE);
    },
    userIsRemoteMonitoring() {
      return this.$auth.check(REMOTE_MONITORING_ROLE);
    },
    userIsOperationHierarchy() {
      return OPERATIONAL_DASHBOARD_HIERARCHY.some(role => this.$auth.check(role));
    },
    showSalesButton() {
      return this.userIsTransactionViewer || this.userIsPriceManager
        || this.userIsAccounting || this.userIsPlanogramViewer
        || this.userIsMarket || this.userIsTaxing || this.userIsRemoteMonitoring;
    },
    generalPages() {
      return this.allPages.filter(page => page.group == "generalPages");
    },
    salesPages() {
      return this.allPages.filter(page => page.group == "salesPages")
    },
    logisticsPages() {
      return this.allPages.filter(page => page.group == "logisticsPages");
    },
    telemetryPages() {
      return this.allPages.filter(page => page.group == "telemetryPages");
    },
    registerPages() {
      return this.allPages.filter(page => page.group == "registerPages");
    },
    crmPages() {
      return this.allPages.filter(page => page.group == "crmPages");
    },
    securityPages() {
      return this.allPages.filter(page => page.group == "securityPages");
    },
    billingPages() {
      return this.allPages.filter(page => page.group === "billingPages");
    },
    amlabsPages() {
      return this.allPages.filter(page => page.group == "amlabsPages");
    },
    allPages() {
      return [
        { group: "telemetryPages", name: this.$t('alerts.title'), route: '/' + i18n.t('path.nameAlerts'), permission: this.userIsManager },
        { group: "telemetryPages", name: this.$t('connectivity.title'), route: '/' + i18n.t('path.nameConnectivityMaster'), permission: this.userIsTelemetry },
        { group: "telemetryPages", name: this.$t('telemetry.title'), route: '/' + i18n.t('path.nameTelemetry'), permission: true },

        { group: "salesPages", name: this.$t('fiscalScenarios'), route: '/' + i18n.t('path.nameTaxScenarios'), permission: this.userIsAccounting},
        { group: "salesPages", name: this.$t('discounts.title'), route: '/' + i18n.t('path.nameDiscountsMaster'), permission: this.userIsPriceManager },
        { group: "salesPages", name: this.$t('fiscalNotes'), route: '/' + i18n.t('path.nameInvoicesMaster'), permission: this.userIsTaxing && this.isBrazil},
        { group: "salesPages", name: this.$t('planograms'), route: '/' + i18n.t('path.namePlanogramMaster'), permission: this.userIsPlanogramViewer },
        { group: "salesPages", name: this.$t('salesReports'), route: '/' + i18n.t('path.nameCharts'), permission: this.userIsMarket },
        { group: "salesPages", name: this.$t('dynamicSalesSummary'), route: '/' + i18n.t('path.nameTransactionsReports'), permission: this.userIsMarket },
        { group: "salesPages", name: this.$t('priceTables'), route: '/' + i18n.t('path.namePriceTables'), permission: this.userIsPriceManager },
        { group: "salesPages", name: this.$t('transactions.title'), route: '/' + i18n.t('path.nameTransactions'), permission: this.userIsTransactionViewer || this.userIsRemoteMonitoring },
        { group: "salesPages", name: this.$t('missingProducts.title'), route: '/' + i18n.t('path.nameMissingProducts'), permission: this.userIsManager },

        { group: "logisticsPages", name: this.$t('replenishment'), route: '/' + i18n.t('path.nameReplenishment'), permission: true },
        { group: "logisticsPages", name: this.$t('purchases'), route: '/' + i18n.t('path.namePurchases'), permission: this.$auth.check('Inventory') },
        { group: "logisticsPages", name: this.$t('inventory.title'), route: '/' + i18n.t('path.nameInventoryMaster'), permission: true },
        { group: "logisticsPages", name: this.$t('pickLists.title'), route: '/' + i18n.t('path.namePickLists'), permission: true },
        { group: "logisticsPages", name: this.$t('products.title'), route: '/' + i18n.t('path.nameProductsMaster'), permission: true },
        { group: "logisticsPages", name: this.$t('ruptureReports'), route: '/' + i18n.t('path.nameRuptures'), permission: true },
        { group: "logisticsPages", name: this.$t('operationalReports'), route: '/' + i18n.t('path.nameLosses'), permission: true },
        { group: "logisticsPages", name: this.$t('dynamicStockSummary'), route: '/' + i18n.t('path.nameInventoryReports'), permission: this.userIsSupplier },
        { group: "logisticsPages", name: this.$t('purchaseSuggestions'), route: '/' + i18n.t('path.namePurchaseSuggestions'), permission: true },

        { group: "generalPages", name: this.$t('help'), route: '/' + i18n.t('path.nameSupport'), permission: this.isBrazil },
        { group: "generalPages", name: this.$t('dashboard'), route: '/' + i18n.t('path.nameDashboard'), permission: true },
        { group: "generalPages", name: this.$t('downloads'), route: '/' + i18n.t('path.nameDownloads'), permission: true },
        { group: "generalPages", name: this.$t('releaseNotes'), route: '/' + i18n.t('path.nameReleaseNotes'), permission: this.isBrazil},
        { group: "generalPages", name: this.$t('condominiumReports'), route: i18n.t('path.nameCondominiumReports'), permission: this.userIsBuildingManager },

        { group: "registerPages", name: this.$t('posRegistration'), route: '/' + i18n.t('path.nameManagePos'), permission: true },
        { group: "registerPages", name: this.$t('newTouchPay'), route: '/' + i18n.t('path.nameNewBox'), permission: true },

        { group: "registerPages", name: this.$t('privateLabel'), route: '/' + i18n.t('path.namePrivateLabel'), permission: this.userIsCashlessViewer && this.isBrazil },
        { group: "registerPages", name: this.$t('users.title'), route: '/' + i18n.t('path.nameUsers'), permission: true },
        { group: "registerPages", name: this.$t('posView'), route: '/' + i18n.t('path.nameNewPointOfSale'), permission: false },

        { group: "crmPages", name: this.$t('evaluations'), route: '/' + i18n.t('path.nameUsersFeedbacks'), permission:  this.isBrazil },
        { group: "crmPages", name: this.$t('orders'), route: '/' + i18n.t('path.nameOrders'), permission:  this.isBrazil },
        { group: "crmPages", name: this.$t('promotionalLogos.title'), route: '/' + i18n.t('path.namePromotionalLogos'), permission:  this.isBrazil },
        { group: "crmPages", name: this.$t('pushNotifications'), route: '/' + i18n.t('path.nameNotifications'), permission:  this.isBrazil },
        { group: "crmPages", name: this.$t('appUsers'), route: '/' + i18n.t('path.nameMobileUsers'), permission:  this.isBrazil },

        { group: "securityPages", name: this.$t('deviationAlerts'), route: '/' + i18n.t('path.nameSuspiciousMaster'), permission: this.$auth.check('Video') },
        { group: "securityPages", name: this.$t('audit.title'), route: '/' + i18n.t('path.nameAudits'), permission: this.userIsSecurity },
        { group: "securityPages", name: this.$t('accessControl'), route: '/' + i18n.t('path.nameAccessControl'), permission: true },

        { group: "billingPages", name: this.$t('myBills'), route: '/' + i18n.t('path.nameMyBills'), permission:  this.isBrazil },

        { group: "profilePage", name: this.$t('myProfile'), route: '/' + i18n.t('path.nameCustomerProfile'), permission: this.userIsManager },

        { group: "amlabsPages", name: this.$t('activations'), route: '/' + i18n.t('path.nameContracts'), permission: true },
        { group: "amlabsPages", name: this.$t('clients'), route: '/' + i18n.t('path.nameCustomersMaster'), permission: true },
        { group: "amlabsPages", name: this.$t('pagSeguro'), route: '/' + i18n.t('path.namePagSeguro'), permission: true },
        { group: "amlabsPages", name: this.$t('swagger'), route: '/' + i18n.t('path.nameSwagger'), permission: true },
        { group: "amlabsPages", name: this.$t('feedback'), route: '/' + i18n.t('path.nameTenantsFeedbacks'), permission: true }
      ]
    },
    activeGroup() {
      const pageAddress = "/" + this.$route.path.split('/')[1];
      const activePage = this.allPages.find(page => page.route == pageAddress) ?? "loginPage";
      return activePage.group ?? "generalPages";
    },
  },
  methods: {
    renderChat() {
        if(!this.isBrazil || this.userIsBuildingManager) return;
        document.getElementById('chat-g4').hidden = false;
      },
    updateFavoritePages(favoritePagesRoutes) {
      this.favoritePages = this.allPages.filter(p => favoritePagesRoutes.some(fpr => fpr == p.route));
    },
    checkNpsDialog() {
      const localStorageString = localStorage.getItem("npsFeedback")
      const localStorageObject = JSON.parse(localStorageString)

      if (localStorageObject == null) {
        this.showNpsDialog = true
        return;
      }

      const daysSinceAnswer = moment().diff(localStorageObject.dateTime, 'days');

      if (localStorageObject.answered == false && daysSinceAnswer > 10) {
        this.showNpsDialog = true
        return;
      }

      if (localStorageObject.answered == true && daysSinceAnswer > 30) {
        this.showNpsDialog = true
        return;
      }
    },
    checkNotifications() {
      const store = useDataStore();
      store.verifyNotification();
    }
  },
  components: {
    SnackBar,
    'app-login': AppLogin,
    PasswordReset,
    SupportLogin,
    WebAppBar,
    MobileAppBar,
    WebNavigationDrawer,
    MobileNavigationDrawer,
    NpsFeedbackDialog
  },
};
</script>

<style scoped>
.cellphone-app-bar {
  display: flex;
  align-items: center;
}
.cellphone-logo {
  flex:0 20 172px;
}

.toolbarItemsPortal {
    height: inherit;
}

/* This class sets the buttons icons heigth */
.button-icon {
  width: 100%;
  text-align: center;
  font-size: calc(0.4 * 100px);
}

.amlabs-icon {
  width: 38px;
  text-align: center;
}

/* This class aligns the contents inside the buttons */
.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, .54)
}

/* This class aligns the button relative to the navigation drawer space */
.button-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
}

.touchpay-logo {
  max-height: 80%;
  max-width: 80%;
}

* /deep/ .v-toolbar__extension {
  padding: 0px !important;
}
</style>
