export default {
    topProductsTitle: 'Produtos mais vendidos',
    loadingMessage: 'Carregando...',
    noResultsMessage: 'Sem resultados',
    topFiveProducts: 'Produtos',
    topFiveState: 'Estado',
    topProductsGraphSubtitle: 'Veja os produtos favoritos dos clientes',
    topProductsSales: 'Vendas',
    topProductsNoRecords: 'Sem registros',
};
  