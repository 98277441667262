export default {
        typeOfLocation: 'Tipo do Local',
        locationTypeDescription: 'Tipo do Local refere-se ao tipo do estabelecimento que você instalou fisicamente o seu PDV. Por exemplo:',
        locationTypeOptions: 
        `<li>PDV instalado em condomínio de casas ou apartamentos</li>
        <li>PDV instalado em empresa de escritório ou indústria</li>
        <li>PDV instalado em hospital</li>
        <li>PDV instalado em universidade</li>
        <li>Outros</li>`,
        locationTypeSelect: 'Selecione o local antes de definir o tipo',
  };
  