export default {
  searchDiscounts: 'Buscar descuentos',
  messageRemoveDiscount: '¿Estás seguro de que deseas eliminar el descuento?',
  messageRemoveCoupon: '¿Estás seguro de que deseas eliminar el cupón?',
  remove: 'Eliminar',
  nameDiscount: 'Nombre del descuento',
  couponsVinculated: 'Cupones vinculados',
  usageLimit: 'Límite de uso',
  discountType: 'Tipo de descuento',
  coupomType: 'Tipo de cupón',
  condition: 'Condición',
  creatingTheDiscount: 'Creando el descuento',
  actions: 'Acciones',
  noCouponsVinculated: 'No hay cupones vinculados',
  removedDiscountSuccess: 'Descuento eliminado con éxito',
  removedDiscountError: 'Error al eliminar el descuento',
  createdDiscount: 'Descuento creado',
  discount: 'Descuento',
  creatingDiscount: 'Creando descuento',
  creatingCoupon: 'Creando cupón',
  couponCode: 'Código del cupón',
  numberUtilization: 'Número de usos',
  startsIn: 'Comienza el',
  endsIn: 'Termina el',
  phoneNumber: 'Número de teléfono',
  textDiscount: 'de descuento',
  minimumPurchase: 'Compra mínima',
  noRemovedDiscount: 'No se pudo eliminar el descuento',
  noRemovedCoupon: 'No se pudo eliminar el cupón',
  newDiscount: 'Nuevo descuento',
  minimumPurchaseOptional: 'Monto mínimo de compra (Opcional)',
  couponLimit: 'Límite de uso del cupón',
  btnNext: 'Siguiente',
  fixed: 'Fijo',
  percentage: 'Porcentaje',
  single: 'Uso único',
  unlimited: 'Ilimitado',
  specified: 'Especificado',
  insertSomeValue: 'Introduce un valor',
  errorCreatingDiscount: 'Problema al crear descuento',
  valueToApply: 'Valor a aplicar',
  forMobile: 'Solo para móvil (Opcional)',
  btnClean: 'Limpiar',
  btnSave: 'Guardar',
  errorCreatingCoupon: 'Problema al crear cupón',
  insertValidPhone: 'Introduce un número de teléfono válido',
  newCoupon: 'Nuevo cupón',
  viewCoupon: 'Ver cupón',
  addCoupon: 'Agregar cupón',
  editDiscount: 'Editar descuento',
  removeDiscount: 'Eliminar descuento',
  removeCoupon: 'Eliminar cupón',
  advanced: 'Avanzado',
  couponCreated: 'Cupón creado',
  onlyNumbers: 'Solo números',
  invalidPhone: 'Número de teléfono inválido',
};
