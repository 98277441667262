export default {
  audit: {
    title: "Audit",
    helpMaster:{
      description : `Here is displayed the audit of edits in the WEB system. The audit allows administrators to see which users created, edited, or deleted certain data in the system, thus assisting in tracking errors in the use of the system.`,
    },
    helpTable:{
        description: `
        <p align="justify">
          Here, the changes made in {auditCategoryName} using the AMLabs TouchPay system
          can be viewed in a table format.
        </p>
        <ul align="justify">
          <li>
            <strong>IP:</strong> IP of the computer that made the modification shown in the row.
          </li>
          <li>
            <strong>User:</strong> User who made the modification shown in the row.
          </li>
          <li>
            <strong>Date and Time:</strong> Moment when the modification shown in the row was made.
          </li>
          <li>
            <strong>Parameters:</strong> Parameters that were used to make the modification shown in the row.
          </li>
          <li>
            <strong>Action:</strong> Action that was taken in the modification shown in the row.
          </li>
        </ul>
      `
    },
    actions: {
      planogram: {
          title: "Planogram",
          finishPlanogram: "Finish Planogram",
          bulkFinishPlanograms: "Bulk Finish Planograms",
          activatePlanogram: "Activate Planogram",
          bulkActivatePlanograms: "Bulk Activate Planograms",
          postPlanogram: "Create Planogram",
          bulkPostPlanogram: "Bulk Create Planograms",
          deletePlanogram: "Delete Planogram",
          bulkDeletePlanograms: "Bulk Delete Planograms",
          postPlanogramWithoutUnusedProduct: "Create Planogram Removing Unused Products"
      },
      pointsOfSale: {
          title: "Points of Sale",
          putPointOfSale: "Edit Point of Sale",
          postPointOfSale: "Create Point of Sale",
          deletePointOfSale: "Delete Point of Sale",
          restoreDeletedPointOfSale: "Restore Deleted Point of Sale"
      },
      priceTable: {
          title: "Price Table",
          postPriceTable: "Create Price Table",
          patchPriceTable: "Edit Price Table Name",
          patchPriceTableType: "Edit Price Table Type",
          deletePriceTable: "Delete Price Table",
          deletePriceTableItem: "Delete Price Table Item",
          bulkApplyPriceTableNew: "Bulk Apply Price Table",
          putUpdateCostFromDistributionCenter: "Update Costs from Distribution Center"
      },
      products: {
          title: "Products",
          putProduct: "Edit Product",
          postProduct: "Add Product",
          deleteProduct: "Delete Product"
      },
      taxScenarios: {
          title: "Tax Scenarios",
          mapProductToTaxGroup: "Modify Product in Tax Scenario",
          addProductToTaxGroup: "Product Added to Tax Scenario",
          removeProductFromTaxGroup: "Product Removed from Tax Scenario",
          postBrazilianTaxing: "Create Tax Scenario",
          putBrazilianTaxing: "Update Tax Scenario",
          deleteBrazilianTaxGroup: "Delete Tax Scenario"
      },
      users: {
          title: "Users",
          deleteUsers: "Delete User",
          putUsers: "Edit User",
          postUsers: "Create User"
      }
    }
  }
}