
import Vue from 'vue';
import NewDiscountDialog from './NewDiscountDialog.vue';
import CouponForm from './CouponForm.vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  components: {
    NewDiscountDialog,
    CouponForm
  },
  data() {
    return {
      currentStep: 1 as number,
      discount: null as number | null,
    };
  },
  computed: {
    dialog: {
      get() : boolean  {
        return this.value;
      },
      set(value : boolean) {
        (this as any).$emit("close", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.currentStep = 1;
    },
    updateDiscountDialog(discountId : number) {
      this.currentStep = 2;
      this.discount = discountId;
    },
    backDialog() {
      this.dialog = false;
      this.currentStep = 1;
    }
  }
});
