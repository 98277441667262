
import {Bill} from "@/types/Bill";
import Vue from "vue";
import {formatDateTimeToDate} from "@/utility/TextFormatting";
import BillStatusChip from "@/components/chips/BillStatusChip.vue";
import MyBillsHelp from "@/views/billing/myBills/helpers/MyBillsHelp.vue";

export default Vue.extend({
  components: {MyBillsHelp, BillStatusChip},
  props: {
    value: {type: Boolean, required: true},
    bills: {type: Array, required: true},
    customerDocumentId: {type: String, required: true},
  },
  data() {
    return {
      customerFinancialIssues: [] as Bill[] | undefined,
      filteredBills: [] as Bill[] | undefined,
      headers: [
        {text: 'Situação', value: 'status'},
        {text: 'Nº Documento', value: 'taxDocumentId'},
        {text: 'Emissão', value: 'issueDateTime'},
        {text: 'Vencimento', value: 'expirationDateTime'},
        {text: 'Previsão', value: 'forecastDateTime'},
        {text: 'Boleto', value: 'omieBillId', sortable: false, width: 100},
        {text: 'NFS-e', value: 'serviceOrderCode', sortable: false, width: 100},
      ],
      customerDocument: null as string | null,
      noDataTexInitialState: 'Digite seu CNPJ/CPF para pesquisar por boletos em aberto',
      noDataText: '',
    };
  },
  mounted() {
    this.filteredBills = this.bills as Bill[];
    this.noDataText = this.noDataTexInitialState;
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    },
    isOlderThanOneQuarter() {
      return (forecastDateTime: string) => {
        const forecastDate = new Date(forecastDateTime);
        const today = new Date();
        const quarterAgo = new Date(today.setMonth(today.getMonth() - 3));
        return forecastDate < quarterAgo;
      }
    }
  },
  methods: {
    formatDateTimeToDate,
    openPDF(url: string | null) {
      if (url) {
        window.open(url, '_blank');
      }
    },
    containsOnlyNumbers(value: string | null) {
      if (value) {
        return /^\d+$/.test(value);
      }
      return false;
    },
    filterPendingBills(customerDocument: string | null) {
      this.filteredBills = [];
      if (customerDocument === this.customerDocumentId) {
        this.filteredBills = this.bills as Bill[];
      } else {
        this.noDataText = 'Nenhum boleto em aberto encontrado, confira o CNPJ/CPF digitado';
      }
    },
    clearDialog() {
      this.customerDocument = null;
      this.filteredBills = [];
      this.noDataText = this.noDataTexInitialState;
    },
  }
})
