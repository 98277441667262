<template>
  <help>
    <template #title>
      {{ $t('telemetry.help.title') }}
    </template>
    <span v-html="$t('telemetry.help.description')"></span>

    <help-video  help-code="MyPointOfSale"/>
  </help>


</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>

<style>

</style>