
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {} as any,
  },
  data() {
    return {
      couponTypes: [
        { value: 'single', text: this.$t('single') },
        { value: 'unlimited', text: this.$t('unlimited') },
        { value: 'specified', text: this.$t('specified') }
      ]
    };
  },
  computed: {
    selectedTypeCoupon: {
      get(): any {
        return this.value;
      },
      set(value: any) {
        this.$emit('input', value);
      }
    }
  }
});
