<template>
  <div 
    v-if="status !== 'Disconnected' && status !== 'DeadBattery'" 
    class="caption grey--text"
  >
    <v-icon small color="grey">{{getIcon()}}</v-icon>
    {{getType()}}
  </div>
  <div v-else class="caption grey--text">
    {{getLatestType()}}
  </div>
</template>

<script>
  export default {
    props: {
      strength: { type: Number },
      type: { type: String },
      subType: { type: String },
      status: { type: String }
    },
    methods: {
      getIcon(){
        if(this.type === 'Mobile'){
            if(this.strength > -80) return 'mdi-wifi-strength-4';
            if(this.strength > -90) return 'mdi-wifi-strength-3';
            if(this.strength > -100) return 'mdi-wifi-strength-2';
            if(this.strength > -110) return 'mdi-wifi-strength-1';
            else return 'mdi-wifi-strength-outline';
        }
        else if(this.type === 'WiFi'){
          if(this.strength > -30) return 'mdi-wifi-strength-4';
          if(this.strength > -60) return 'mdi-wifi-strength-3';
          if(this.strength > -70) return 'mdi-wifi-strength-2';
          if(this.strength > -90) return 'mdi-wifi-strength-1';
          else return 'mdi-wifi-strength-outline';
        }
      },
      getType(){
        if(this.type === 'Mobile'){
          return this.subType;
        }
        else if(this.type === 'WiFi'){
          return this.$t('getTypeWifi');
        }
      },
      getLatestType(){
        if(this.type === 'WiFi'){
          return this.$t('lastNetworkWifi');
        }
        else if (this.type === 'Mobile') {
          return this.$t('lastNetwork') + this.subType
        }
      }
    },
  };
</script>