export default {
    newTaxScenario: 'Nuevo Escenario Fiscal',
    newTaxScenarioTitle: 'Nuevo escenario fiscal',
    editTaxScenarioTitle: 'Editar escenario fiscal',
    nameLabel: "Nombre",
    regimeLabel: "Régimen",
    cfopLabel: "CFOP",
    stateLabel: 'Estado',
    cstSubtitle: 'Códigos de Situaciones Tributarias (CST)',
    icmsLabel: 'ICMS',
    pisLabel: 'PIS',
    cofinsLabel: 'COFINS',
    ipiLabel: 'IPI',
    benefitLabel: 'Beneficio Fiscal',
    aliquotasSubtitle: 'Alícuotas Tributarias (%)',
    closeButton: 'Cerrar',
    saveButton: 'Guardar',
    exactLengthRule: 'Debe contener {length} caracteres',
    maxCharactersRule: 'Límite de {max} caracteres',
    requiredRule: 'Este campo es obligatorio',
    floatRule: 'Ingrese un valor válido',
    validCharactersRule: 'Ingrese caracteres válidos',
  }
  