export default {
    configureFiscalNote: 'Configure invoice (NFC-e)',
    companyCnpj: 'Companyn\'s CNPJ',
    enterCnpj: 'Enter the CNPJ that will issue the NFC-e for this POS',
    noRegisteredCompanies: 'There are no registered companies',
    disableInvoiceConfig: 'Disable',
    issuanceAlreadyDisabled: 'Issuance is already disabled',
    closeInvoiceConfig: 'Close',
    saveInvoiceConfig: 'Save',
    nfceActivatedSuccessfully: 'NFC-e successfully activated for POS!',
    unableToActivateNfce: 'NFC-e could not be activated',
    nfceDeactivatedSuccessfully: 'NFC-e successfully deactivated',
    unableToDeactivateNfce: 'NFC-e could not be deactivated',
    activateAnyway: 'Activate anyway',
  };