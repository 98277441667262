export default {
  newTaxScenario: 'Novo Cenário Fiscal',
  newTaxScenarioTitle: 'Novo cenário fiscal',
  editTaxScenarioTitle: 'Editar cenário fiscal',
  nameLabel: "Nome",
  regimeLabel: "Regime",
  cfopLabel: "CFOP",
  stateLabel: 'Estado',
  cstSubtitle: 'Códigos de Situações Tributárias (CST)',
  icmsLabel: 'ICMS',
  pisLabel: 'PIS',
  cofinsLabel: 'COFINS',
  ipiLabel: 'IPI',
  benefitLabel: 'Benefício Fiscal',
  aliquotasSubtitle: 'Alíquotas Tributárias (%)',
  closeButton: 'Fechar',
  saveButton: 'Salvar',
  exactLengthRule: 'Deve conter {length} caracteres',
  maxCharactersRule: 'Limite de {max} caracteres',
  requiredRule: 'Este campo é obrigatório',
  floatRule: 'Insira um valor válido',
  validCharactersRule: 'Insira caracteres válidos',
}