export default {
    titleLocks: 'Travas cadastradas',
    addLock: 'Cadastrar trava',
    registrationTitleLocks: 'Cadastro de travas',
    registrationDescriptionLocks: `Aqui você pode gerenciar as travas automatizadas compatíveis com o TouchPay para micro-markets, como travas de geladeira e travas da porta principal da loja.',
    A abertura das travas é feita pelo consumidor, que deve escanear o QR code da trava através do app TouchPay.`,
    installationLocks: `Ao instalar a box de comunicação da trava, esta deve ser pareada via Bluetooth com o terminal de pagamento. 
    É importante guardar o nome do dispositivo Bluetooth, pois ele será usado para fazer o cadastro da trava. As informações do cadastro são:`,
    deviceNameLocks: '<strong>Nome do dispositivo</strong>: deve ser exatamente igual ao nome do dispositivo Bluetooth da trava, para que o terminal de pagamento consiga localizá-lo.',
    lockName: '<strong>Nome da trava</strong>: um nome amigável para a identificação, por exemplo: Geladeira bebidas, Porta principal, etc..',
    ageRestrictionLocks: '<strong>Acesso restrito +18</strong>: se essa opção for marcada, a trava só poderá ser aberta por usuários do app que forem maiores de idade.',
    qrCodeGenerationLocks: `Após o cadastro da trava, será gerado o QR code que deve ser colado próximo a trava para que os consumidores
    possam abrí-la. Caso você ainda não tenha a box da trava, mas deseje obter o QR code, é possível fazer o cadastro
    deixando o <em>nome do dispositivo</em> vazio, e preenchê-lo assim que receber o dispositivo.`,
    noLocksLocks: 'Nenhuma trava cadastrada',
    closeLocks: 'Fechar',
    newLockLocks: 'Nova trava',
    successMessageLocks: 'Trava removida',
    errorMessageLocks: 'Não foi possível remover a trava',
    editLocks: 'Editar',
  }
  