export default {
    selectPosWithPlanogram: 'Seleccionar PDVs con algún planograma (excepto pendientes)',
    addOrUpdateItems: 'Agregar o actualizar elementos',
    productNameLabel: 'Producto',
    priceNameLabel: 'Precio',
    desiredQuantityLabel: 'Cantidad deseada (nivel de reorden)',
    criticalMinimumLabel: 'Mínimo crítico',
    optionsLabel: 'Opciones',
    addOnlyLabel: 'Solo agregar',
    updateOnlyLabel: 'Solo actualizar',
    addOrUpdateLabel: 'Agregar o actualizar',
    btnClose: 'Cerrar',
    btnSave: 'Guardar',
    noProducts: 'Sin productos registrados',
    insertValidNameRules: 'Ingrese un nombre válido',
    requiredFieldRules: 'Este campo es obligatorio.',
    insertIntegerRules: 'Ingrese un número entero',
    insertValidValueRules: 'Ingrese un valor válido',
    validUrlRules: 'Ingrese una URL válida',
    addUpdatePos: 'Error al agregar/actualizar elemento en el planograma del PDV ',
    tryAgainPos: ' Inténtelo de nuevo.',
    addPlanogramProducts: 'Aquí es posible agregar un producto en varios planogramas, actualizarlo en varios planogramas, o ambos, según los modos a continuación:',
    justAdd: 'Solo agregar: agrega el producto en los planogramas que no lo contienen. Para los planogramas que ya contienen el producto, no se realiza ningún cambio.',
    justUpdate: 'Solo actualizar: actualiza la información del producto solo en los planogramas que lo contienen. En este modo, solo el campo Producto es obligatorio. Los campos dejados en blanco no se modificarán.',
    addOrUpdate: 'Agregar o actualizar: una combinación de los dos modos anteriores. Agrega el producto en los planogramas que no lo tienen, y actualiza el producto en los planogramas que ya lo tienen.',
    justAddTheProduct: 'Solo agrega el producto a los planogramas que no lo tienen.',
    onlyEditPlanograms: 'Solo edita los planogramas que ya tienen el producto.',
    addsTheProductToPlanograms: 'Agrega el producto a los planogramas que no lo tienen, y lo edita en los planogramas que ya lo tienen.',
    availabilityLocal: 'Local',
    availabilityOrder: 'Pedido',
    availabilityLocalAndOrder: 'Local y pedido',
    btnItems: 'Elementos',
    warningAddOrUpdateItems: 'Los productos serán agregados o actualizados <strong>solo</strong> en los puntos de venta seleccionados que no tienen planogramas pendientes.'
};
