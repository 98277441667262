export default {
    itemDialogContent: 'Itens do planograma',
    itemDialogContentDescription: 'Um item do planograma representa um produto no seu ponto de venda, junto com algumas regras associadas a ele. Entre essas regras, estão:',
    selectionNumberHelp:
        `Número da seleção: no caso de uma máquina, indica qual mola ou compartimento está o produto. 
        Para micro-markets, pode indicar a prateleira ou posição do produto. Ao criar um novo item, a seleção é preenchida automaticamente,
        mas pode ser alterada. Caso não haja necessidade de controle da seleção, como é normalmente o caso para micro-markets,
        é mais simples manter o valor que já vem preenchido.`,
    pricerHelp: `Preço: qual o preço a ser cobrado. Pode ser diferente do preço padrão definido no cadastro de produtos.`,
    maximumCapacityHelp: `Capacidade máxima: apenas para vending machines. Indica qual é o máximo que cabe deste produto nessa seleção.`,
    desiredQuantityHelp:
        `Quantidade desejada (nível de par): qual é a quantidade desejada desse produto no momento do abastecimento. 
        Essa quantidade é usada na geração de pick-lists, e deve ser menor ou igual à capacidade máxima caso esta esteja preenchida.`,
    criticalMinimumHelp: `Mínimo crítico: quando o estoque atingir esse número, será exibido um alerta na página de telemetria.`,
    advancedOptionsHelp: `Opções avançadas (apenas para micro-markets):`,
    availabilityHelp:
        `Disponibilidade: configura se o produto estará disponível localmente, sob encomenda, ou ambos.
        As encomendas podem ser feitas através do app TouchPay Mobile.`,
    sponsoredLinkHelp:
        `Link patrocinado: pode ser usado quando há parceria com algum supermercado, por exemplo.
        Quando esse campo estiver preenchido e o item estiver configurado para encomenda, ao clicar na encomenda pelo app,
        o link é aberto no navegador.`,
    selectionLabel: 'Seleção',
    priceLabel: 'Preço',
    maxCapacityLabel: 'Capacidade máxima',
    desiredQuantityLabel: 'Quantidade desejada (nível de par)',
    criticalMinimumLabel: 'Mínimo crítico',
    availabilityLabel: 'Disponibilidade',
    sponsoredLinkLabel: 'Link patrocinado',
    itemDialogContentClose: 'Fechar',
    itemDialogContentSave: 'Salvar',
    errorSavingItem: 'Erro ao salvar item',
    successItemCreated: 'Item criado com sucesso',
    successItemUpdated: 'Item atualizado com sucesso',
    itemDialogContentLocal: 'Local',
    itemDialogContentOrder: 'Encomenda',
    itemDialogContentLocalAndOrder: 'Local e encomenda',
    itemDialogContentAddItem: 'Adicionar item',
    itemDialogContentChangeItem: 'Alterar item',
    expandAdvanced: 'Avançado',
  };
  