export default {
    searchTransactionsReports: 'Procurar',
    totalTransactionsReports: 'Total',
    selectGroupingsTransactionsReports: 'Selecione um ou mais agrupamentos e clique em Atualizar.',
    productCodeTransactionsReports: 'Cód. Produto',
    productDescriptionTransactionsReports: 'Descrição Produto',
    categoryTransactionsReports: 'Categoria',
    pointOfSaleIdTransactionsReports: 'ID PDV',
    pointOfSaleCodeTransactionsReports: 'Cód. PDV',
    customerTransactionsReports: 'Cliente',
    locationTransactionsReports: 'Local',
    specificLocationTransactionsReports: 'Local específico',
    stateTransactionsReports: 'Estado',    
    paymentMethodTransactionsReports: 'Método Pagamento',
    cardBrandTransactionsReports: 'Bandeira Cartão',
    dateTransactionsReports: 'Data',
    monthTransactionsReports: 'Mês',
    dayOfWeekTransactionsReports: 'Dia da Semana',    
    quantityTransactionsReports: 'Qtd.',
    totalValueTransactionsReports: 'Valor Total',
    totalCostTransactionsReports: 'Custo Total',
    dynamicTransactionSummaryTransactionsReports: 'Resumo dinâmico de transações',
    othersTransactionsReports: 'OUTROS',
    TransactionsReports: 'Resumo dinâmico de transações',
    hour: 'Hora',
};
  