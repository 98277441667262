import { defineStore } from 'pinia';
import { ref } from 'vue';
import agent from '@/api/agent';

export const useDataStore = defineStore('data', () => {
  const hasUpdate = ref(false);
  const loading = ref(false);

  // Check pending status
  async function verifyNotification() {
    loading.value = true;
    try {
      const response = await agent.ChangeOwnershipRequest.hasPendingStatus();
      hasUpdate.value = Boolean(response);

    } catch (error) {
      console.error('Erro ao buscar pedidos pendentes:', error);
    } finally {
      loading.value = false;
    }
  }  

  // Set storage
  function setStorage(update: boolean) {
    hasUpdate.value = update;
  }

  return {
    hasUpdate,
    verifyNotification,
    setStorage,
  };
});
