
import Vue from "vue";
import agent from "@/api/agent";
import { formatDateTimeToDate, formatCnpj } from "@/utility/TextFormatting";
import UploadCertificateDialog from "./UploadCertificateDialog.vue";
import AddCertificateButton from "@/components/buttons/AddCustomButton.vue";
import { Company } from "@/types/Invoices";

export default Vue.extend({
  data() {
    return {
      companies: [] as Company[],
      loading: false,
      headers: [
        { text: "Grupo", value: "groupId", align: "left", sortable: true },
        { text: "CNPJ", value: "cnpj", align: "left", sortable: true },
        {
          text: "Data de validade",
          value: "certificateExpiracyDate",
          align: "left",
          sortable: true,
        },
      ],
      search: "" as string,
      certificateDialog: false,
    };
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.loading = true;
      agent.Invoices.getCompanies()
        .then((response: Company[]) => {
          this.companies = response;
          this.loading = false;
        })
        .catch((error: string) => {
          (this.$refs.feedback as any).handleError(
            "Houve um erro ao carregar os certificados",
            error
          );
          this.loading = false;
        });
    },
    onCertificateDialogClosed(value: boolean) {
      if (value) {
        (this as any).$refs.feedback.handleSuccess("Certificado enviado com sucesso");
        this.getCompanies();
      }
      this.certificateDialog = false;
    },
    formatDateTimeToDate,
    formatCnpj,
  },
  components: {
    UploadCertificateDialog,
    AddCertificateButton,
  },
});
