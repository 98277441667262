export default {
    productRegistration: 'Product registration',
    codeProductHelp: 'Code',
    codeDescriptionProductHelp: 'Internal product code (optional)',
    descriptionProductHelp: 'Description',
    descriptionDetailsProductHelp: ' product description. It will appear in the reports and identify the product for the TouchPay Market end user',
    categoryProductHelp: 'Category',
    categoryDetailsProductHelp: 'Product category (optional). It is useful for visualization in sales reports and can be used to generate pick-lists for one or more specific categories. The TouchPay Market end user also has the option of searching for products by category.',
    defaultPriceProductHelp: 'Default price',
    defaultPriceDetails: ' is the price that will appear by default when the product is added to a planogram. The price of the product at the point of sale is the price defined in the planogram, which may be different from this default price',
    PriceDetails: ' used to identify the product in the micro-market via the barcode scanner. See below how to register barcodes when the price of the product is variable',
    ageRestrictedProductHelp: 'Forbidden to minors',
    ageRestrictedDetails: ' when this option is checked, a confirmation will be required for the end consumer of the micro-market',
    variablePriceBarcode: 'Barcode with variable price',
    variablePriceBarcodeDetails: 'Products that have a price defined by the barcode generally follow the following pattern: Fixed part followed by the price in cents followed by a check digit. Use the # character for the digits corresponding to the price, and the * character for the check digit.',
    variablePriceBarcodeExample: 'Example: if a product barcode is entered as 2345678#####*, and the code 2345678011575 is read on the point of sale reader, the calculated price will be R$11.57.',
  };