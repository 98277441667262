
import Vue from 'vue';
import SalesByWeekday from './components/SalesByWeekday.vue';
import SalesByPaymentMethod from './components/SalesByPaymentMethod.vue';
import RevenueGrowth from './components/RevenueGrowth.vue';
import ProductMostSoldNewVue from './components/ProductMostSoldNew.vue';
import PosMostSales from './components/PosMostSalesNew.vue'
import PosMinoritySales from './components/PosMinoritySales.vue'
import MostSoldCategories from './components/MostSoldCategories.vue';
import SalesSummary from './components/SalesSummary.vue';

export default Vue.extend({
  props: {
    mustUpdate: { type: Boolean, required: true },
    activeTab: { type: Number, required: true },
    form: { type: Object, required: true },
    selectedDateInterval: { required: true }
  },
  components: {
    'ProductMostSold': ProductMostSoldNewVue,
    SalesByWeekday,
    SalesByPaymentMethod,
    RevenueGrowth,
    PosMostSales,
    PosMinoritySales,
    MostSoldCategories,
    SalesSummary
  },
  computed: {
    mustCallEndpoints(): boolean {
      return this.mustUpdate && this.activeTab == 0
    }
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.$nextTick(() => this.$emit('updated'))
        }
      },
      immediate: true
    }
  },
})
