export default {
    configureFiscalNote: 'Configurar nota fiscal (NFC-e)',
    companyCnpj: 'CNPJ da empresa',
    enterCnpj: 'Insira o CNPJ que emitirá a NFC-e para este PDV',
    noRegisteredCompanies: 'Não há empresas cadastradas.',
    disableInvoiceConfig: 'Desabilitar',
    issuanceAlreadyDisabled: 'A emissão já está desabilitada',
    closeInvoiceConfig: 'Fechar',
    saveInvoiceConfig: 'Salvar',
    nfceActivatedSuccessfully: 'NFC-e ativada com sucesso para o PDV!',
    unableToActivateNfce: 'Não foi possível ativar a NFC-e',
    nfceDeactivatedSuccessfully: 'NFC-e desativada com sucesso',
    unableToDeactivateNfce: 'Não foi possível desativar a NFC-e',
    activateAnyway: 'Ativar mesmo assim',
  };
  