
import {defineComponent} from 'vue'
import agent from "@/api/agent";
import TenantGroupDialogHelp from "@/views/users/helpers/TenantGroupDialogHelp.vue";
import {ADMINISTRATOR_ROLE} from "@/constants/Roles";
import EmployerIdentificationText from '@/components/textField/EmployerIdentificationText.vue';

const formInitialState = {
  groupId: null as number | null,
  groupName: null as string | null,
  cnpj: '' as string | null,
};

export default defineComponent({
  components: {TenantGroupDialogHelp, EmployerIdentificationText},
  props: {
    value: {type: Boolean, default: false},
  },
  data() {
    return {
      form: {...formInitialState},
      valid: false,
      submitting: false,
      loading: {
        groupId: false,
      },
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
        if (!value) {
          this.resetForm();
        } else {
          this.getNextAvailableGroupId();
        }
      }
    },
    isValidInitialState(): boolean {
      return this.$data.form.groupId === null
        || this.$data.form.groupName === null
        || this.$data.form.cnpj === null;
    },
    userIsAdministrator() {
      return (this as any).$auth.check(ADMINISTRATOR_ROLE);
    },
  },
  watch: {
    'form.cnpj'(value: string) {
      if(!value) {
        this.form.cnpj = value;
        return;
      }
      const cnpj = value.replace(/\D/g, '');
      this.form.cnpj = cnpj != '' ? cnpj : null;
    },
  },
  methods: {
    getNextAvailableGroupId() {
      this.loading.groupId = true;
      agent.Tenants.getNextAvailableGroupId()
        .then((response) => {
          this.form.groupId = response;
        })
        .catch((error) => {
          this.handleError(this.$t('tenantGroupDialogHandleErrorMessage') as string, error);
        })
        .finally(() => {
          this.loading.groupId = false;
        });
    },
    createGroup() {
      this.submitting = true;
      agent.Tenants.createGroup(this.form)
        .then(() => {
          this.$emit('success', this.$t('tenantGroupDialogEmitSuccessMessage', {groupId: this.form.groupId}));
          this.dialog = false;
        })
        .catch((error) => {
          this.handleError(this.$t('tenantGroupDialogHandleErrorMessage') as string, error);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    resetForm(): void {
      Object.assign(this.form, {...formInitialState});
      (this.$refs.form as any).resetValidation();
    },
    handleSuccess(message: string) {
      (this.$refs.feedback as any).handleSuccess(message);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
})
