export default {
  unlock: {
    operations: {
      result : {
        success: "Éxito",
        invalid: "Inválido",
        error: "Error",
        details: {
          lockOpened: "Cerradura Abierta",
          facialRecognitionSuccess: "Reconocimiento Facial Exitoso",
          phoneNotValidated: "Teléfono No Validado",
          emailNotValidated: "Correo Electrónico No Validado",
          cpfNotValidated: "CPF No Validado",
          ageNotValidated: "Edad No Validada",
          userBlocked: "Usuario Bloqueado",
          unknown: "Desconocido",
          lockNotFound: "Cerradura No Encontrada",
          deviceNotFound: "Dispositivo No Encontrado",
          deviceNotPaired: "Dispositivo No Emparejado",
          deviceNotConnected: "Dispositivo No Conectado",
          deviceNotResponsive: "Dispositivo No Responsable",
          communicationTimeout: "Tiempo de Comunicación Agotado",
          communicationError: "Error de Comunicación",
          terminalNotReachable: "Terminal No Alcanzable",
          bluetoothDisabled: "Bluetooth Desactivado",
          noCertificate: "Sin Certificado",
          invalidClientCertificate: "Certificado de Cliente Inválido",
          invalidServerCertificate: "Certificado de Servidor Inválido",
          facialRecognitionFail: "Fallo en el Reconocimiento Facial"
        }
      }
    }
  }
}
