
import { defineComponent } from 'vue';
import axios from 'axios';
import {CashlessCustomer} from '@/types/Cashless';

export default defineComponent({
  name: "CardConsumerFilter",
  props: {
    value: { type: String, default: null },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  
  data() {
    return {
      isLoadingCards: false,
      cards: [],
    }
  },
  computed: {
    cardNumber: {
      get() : string | null {
        return this.value;
      },
      set(val : string | null) {
        this.$emit('input', val);
      }
    }
  },
  mounted() {
    this.getCashlessCards();
  },
  methods: {
    getCashlessCards() {
      this.isLoadingCards = true;
      axios.get('api/cashless/customers')
      .then((response) => {
       this.cards = response.data.map((customer: CashlessCustomer) => customer.card);
       this.isLoadingCards = false;
      })
      .catch(() => {})
      .finally(() => {
        this.isLoadingCards = false;
      });
    },
    updateValue(val : Number | null) {
      this.$emit('input', val);
    }
  },
});
