<template>
  <v-card class="mb-16">

    <v-card-title>
      <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getTaxings">
        <v-text-field
          class="mr-2 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="$t('textFieldSearchText')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getTaxings();"
          @click:append="options.page = 1; getTaxings()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>

      <v-spacer></v-spacer>

      <v-btn class="ml-2" color="error" @click.native="taxingsBulkRemoveDialog.enabled = true">
        <v-icon left>delete</v-icon>
        {{ $t('deleteNonUsedButton') }}
      </v-btn>

      <taxing-bulk-remove-dialog
        v-model="taxingsBulkRemoveDialog.enabled"
        @success="onTaxingsBulkRemovedSuccess"
        @error="handleError"
      />

      <taxing-dialog
        v-model="itemDialog"
        :edited-index="editedIndex"
        :edited-entry="editedEntry"
        @add="onTaxGroupAdded"
        @update="onTaxGroupUpdated"
        @error="handleError"
      ></taxing-dialog>

      <v-dialog
        v-model="deleteDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>{{ $t('deleteTaxScenarioDeleteDialogText') }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="deleteDialog = false; itemToDelete = null">{{ $t('cancelButtonDeleteDialog') }}</v-btn>
            <v-btn text color="error" @click="deleteItem(itemToDelete)">{{ $t('deleteButtonDeleteDialog') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading.taxGroups"
      :headers="headers"
      :items="taxGroups"
      must-sort
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ itemsPerPageOptions: [ 10, 20, 30, 50, 100 ] }"
      :no-data-text="$t('dataTableNoDataText')"
    >

      <template #[`item.isInvoiceDataComplete`]="{ item }">
        <v-icon small :color="getDataCompleteColor(item.isInvoiceDataComplete)">
          {{ getDataCompleteIcon(item.isInvoiceDataComplete) }}
        </v-icon>
        {{ getDataCompleteDescription(item.isInvoiceDataComplete) }}
      </template>

      <template #[`item.name`]="{ item }">
        {{ item.name ? item.name : "-" }}
      </template>

      <template #[`item.taxation`]="{ item }">
        {{ item.taxation ? taxations[item.taxation] : "-" }}
      </template>

      <template #[`item.countryTax`]="{ item }">
        {{ (item.countryTax/100).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
      </template>

      <template #[`item.stateTax`]="{ item }">
        {{ (item.stateTax/100).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
      </template>

      <template #[`item.countyTax`]="{ item }">
        {{ (item.countyTax/100).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
      </template>

      <template #[`item.numberOfProducts`]="{item}">
            {{ item.numberOfProducts }}
            <v-btn icon @click.stop="openTaxProductsDialog(item)">
              <v-icon color="info">mdi-playlist-check</v-icon>
            </v-btn>
      </template>

      <template #[`item.actions`]="{ item }">
        <td class="nobr">
          <v-btn
            icon
            small
            @click.stop="editItem(item)">
            <v-icon color="info">edit</v-icon>
          </v-btn>
          <v-tooltip left :disabled="!disableDeleteButton(item)">
            <template #activator="{ on }">
              <span v-on="on">
                <v-btn
                  icon
                  small
                  :disabled="disableDeleteButton(item)"
                  @click.stop="deleteDialog = true; itemToDelete = item.id"
                >
                  <v-icon color="error">delete</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ tooltipText(item) }}</span>
          </v-tooltip>
        </td>
      </template>

    </v-data-table>
    <taxing-products-dialog :Visible="productDialog.visible" :TaxId="productDialog.taxId" :TaxName="productDialog.taxName" @close="closeTaxProductsDialog"></taxing-products-dialog>
    <feedback ref="feedback"></feedback>

  </v-card>
</template>
<script>
import {shouldRefreshData, toApiPagination} from '@/components/TableOptionsComparator';
import TaxingDialog from '../TaxingUsa/TaxingUsaDialog.vue';
import Feedback from '../../../components/Feedback.vue';
import {Taxations} from '@/constants/Taxations';
import TaxingBulkRemoveDialog from "@/views/taxings/components/TaxingBulkRemoveDialog.vue";
import TaxingProductsDialog from '../TaxingUsa/TaxingUsaProductsDialog.vue';
import agent from '@/api/agent';

export default {
  components: {TaxingBulkRemoveDialog, TaxingDialog, Feedback, TaxingProductsDialog},
  data() {
    return {
      taxGroups: [],
      itemDialog: false,
      deleteDialog: false,
      itemToDelete: null,
      editedEntry: {},
      editedIndex: -1,
      headers: [
        {text: this.$t('headerIsInvoiceDataComplete'), align: 'left', sortable: false, value: 'isInvoiceDataComplete'},
        {text: this.$t('headerId'), align: 'left', sortable: true, value: 'id'},
        {text: this.$t('headerName'), align: 'left', sortable: true, value: 'name'},
        {text: this.$t('headerFiscalRegion'), align: 'left', sortable: true, value: 'fiscalRegionName'},
        {text: this.$t('headerTaxation'), align: 'left', sortable: true, value: 'taxation'},
        {text: this.$t('headerCountryTax'), align: 'left', sortable: true, value: 'countryTax'},
        {text: this.$t('headerStateTax'), align: 'left', sortable: true, value: 'stateTax'},
        {text: this.$t('headerCountyTax'), align: 'left', sortable: true, value: 'countyTax'},
        {text: this.$t('headerNumberOfProducts'), align: 'left', sortable: true, value: 'numberOfProducts'},
        {text: this.$t('headerActions'), align: 'center', sortable: false, value: 'actions'}
      ],
      loading: {
        taxGroups: true,
        removeItem: false,
        newTaxGroup: true
      },
      search: {
        text: '',
        valid: true
      },
      taxingsBulkRemoveDialog: {
        enabled: false
      },
      taxingInvoiceTestDialog: {
        enabled: false
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true],
        mustSort: true
      },
      productDialog: {
        visible: false,
        taxId: null,
        taxName: null
      },
      taxations: Taxations
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTaxings();
        }
      },
    },
    itemDialog(isOpen) {
      if (!isOpen) {
        this.editedIndex = -1;
        this.editedEntry = {};
      }
    }
  },
  mounted() {
    this.getTaxings();
  },
  methods: {
    async getTaxings() {
      this.loading.taxGroups = true;
      let params = toApiPagination(this.options, this.search.text);
      try {
        const response = await agent.Taxings.getUsaTaxes(params);
        this.taxGroups = response.items;
        this.totalItems = response.totalItems;
      }
      catch(error) {
        this.handleError("Não foi possível carregar os cenários fiscais", error);
      }
      finally {
        this.loading.taxGroups = false;
        this.loading.newTaxGroup = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.taxGroups.indexOf(item);
      this.editedEntry = Object.assign({}, item);
      this.itemDialog = true;
    },
    onTaxGroupAdded() {
      this.getTaxings();
      this.handleSuccess('Cenário fiscal salvo com sucesso');
    },
    onTaxGroupUpdated(taxGroup) {
      let existingTaxGroup = this.taxGroups.find(p => p.id === taxGroup.id);
      if (taxGroup) {
        Object.assign(existingTaxGroup, taxGroup);
      } else {
        this.taxGroups.push(taxGroup);
        this.editItem(taxGroup);
      }
      this.handleSuccess('Cenário fiscal atualizado com sucesso');
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    formatPercentage(number) {
      if (number == null) return "-";
      return number.toLocaleString('pt-BR', {maximumFractionDigits: 2});
    },
    getDataCompleteIcon(isInvoiceDataComplete) {
      if (isInvoiceDataComplete) return 'check_circle';
      else return 'error';
    },
    getDataCompleteColor(isInvoiceDataComplete) {
      if (isInvoiceDataComplete) return 'green darken-2';
      else return 'red darken-2';
    },
    getDataCompleteDescription(isInvoiceDataComplete) {
      switch (isInvoiceDataComplete) {
        case true:
          return "Completo";
        case false:
          return "Incompleto";
      }
    },
    onTaxingsBulkRemovedSuccess(totalEntriesRemoved) {
      this.getTaxings();
      this.handleSuccess(`Um total de ${totalEntriesRemoved} cenários fiscais foram removidos com sucesso`);
    },
    disableDeleteButton(item) {
      if (item.numberOfProducts != 0) return true;
      if (item.groupId != this.$auth.user().tenantGroupId) return true;
      return false;
    },
    tooltipText(item) {
      if (item.numberOfProducts != 0) return "Cenários fiscais com produtos não podem ser deletados";
      if (item.groupId != this.$auth.user().tenantGroupId) return "Esse cenário fiscal não pertence a esse franqueado";
      return "";
    },
    async deleteItem(itemToDeleteId) {
      try {
        await agent.Taxings.deleteUsaTax(itemToDeleteId);
        this.handleSuccess(`O cenário fiscal ${itemToDeleteId} foi deletado com sucesso`);
        this.getTaxings();
      }
      catch (error){
        this.handleError("Erro ao deletar cenário fiscal", error);
      }
      finally {
        this.deleteDialog = false;
        this.itemToDelete = false;
      }
    },
    openTaxProductsDialog(item) {
      this.productDialog.taxId = item.id;
      this.productDialog.taxName = item.name;
      this.productDialog.visible = true;
    },
    closeTaxProductsDialog() {
      this.productDialog.visible = false;
    },
  },
};
</script>

<style scoped>
.nobr { white-space: nowrap }
</style>
