export default {
    helpTitleProductGroupsHelp: 'Agrupaciones de productos',
    introductionProductGroupsHelp: 'Una agrupación de productos sirve para gestionar diferentes variaciones del producto como si fuera un producto único.',
    exampleExplanationProductGroupsHelp: `Por ejemplo, supongamos que vendes huevos en tu tienda y tu proveedor tiene cajas de huevos de la marca A o de la marca B.
      Es importante tener siempre huevos en la tienda, pero no importa si son de la marca A o B.
      Entonces, tienes los productos "Huevos A" y "Huevos B" en tu registro de productos.
      Podrías tener estos dos productos en el planograma, pero sería difícil gestionar las Pick Lists, ya que siempre aparecerían
      ambas marcas de huevos, y tendrías que editar manualmente la Pick List para dejar solo la marca que vas a abastecer ese día.`,
    solutionExplanationProductGroupsHelp: `La agrupación de productos resuelve este problema. Puedes crear una agrupación "Huevos" y agregar los productos "Huevos A" y "Huevos B"
      a esta agrupación. Entonces, en tu planograma, tendrías un elemento con la agrupación en lugar de elementos separados para cada producto.
      De esta manera, se simplifica el control de inventario, las Pick Lists y el abastecimiento.`,
    salesRecordExplanationProductGroupsHelp: `Sin embargo, las ventas se registran con el producto específico. Así, en el informe de ventas por producto es posible identificar cuál de las
      marcas A o B tiene más aceptación entre los consumidores.`,
};
