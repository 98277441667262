<template>
  <v-row no-gutters>
    <portal to="toolbarTitle">
      <help>
        <template #title>
          Relatórios Operacionais
        </template>
        Aqui são exibidos os relatórios de <strong>produtos a vencer</strong>,
        <strong>perdas por PDV</strong> e <strong>perdas por produto</strong>.
        Para que haja informações para gerar esses relatórios, é necessário realizar
        o fluxo de abastecimento e controle de inventário através do terminal de pagamento.
        Esse fluxo está presente apenas nos terminais de micro-market.
        <p></p>
        <help-video  help-code="OperationReport"/>
      </help>
    </portal>
    <v-col cols="12">
      <news v-if="isBrazil"></news>
      <report-filters v-if="activeTab === 1 || activeTab === 2" @modify="updateFilters"></report-filters>
      <products-to-expire-report-filters v-if="activeTab === 0" @modify="updateFilters"></products-to-expire-report-filters>
      <v-tabs v-model="activeTab" grow background-color="grey lighten-4">
        <v-tab :key="0">Produtos a vencer</v-tab>
        <v-tab :key="1">Perdas por PDV</v-tab>
        <v-tab :key="2">Perdas por produto</v-tab>
        <v-tab-item :key="0" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <div class="title">Próximos produtos a vencer</div>
              <v-spacer></v-spacer>
              <v-btn class="mt-2" color="info" @click.native="getExcelExpire" :loading="loading.excel">
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="expiration.loading"
              :headers="expiration.headers"
              :items="expiration.items"
              :options.sync="expiration.paginationOptions"
              :server-items-length="expiration.totalItems"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.productExpirationDate="{ item }">
                <td>{{ $moment.utc(item.productExpirationDate).format('DD/MM/YYYY') }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="1" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <div class="title">Perdas por PDV</div>
              <v-spacer></v-spacer>
              <v-btn class="mt-2" color="info" @click.native="getExcelPoSLosses" :loading="loading.excel" right>
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="posLoss.loading"
              :headers="posLoss.headers"
              :items="posLoss.items"
              :options.sync="posLoss.options"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.loss.soldValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.soldValue) }}</td>
              </template>
              <template #item.loss.loss.expiredValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.expiredValue) }}</td>
              </template>
              <template #item.loss.loss.damagedValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.damagedValue) }}</td>
              </template>
              <template #item.loss.loss.stolenValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.stolenValue) }}</td>
              </template>
              <template #item.loss.loss.otherValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.otherValue) }}</td>
              </template>
              <template #item.loss.loss.gainValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.gainValue) }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" class="mb-16">
          <v-card>
            <v-card-title primary-title>
              <div class="title">Perdas por produto</div>
              <v-spacer></v-spacer>
              <v-btn class="mt-2" color="info" @click.native="getExcelProductLosses" :loading="loading.excel" right>
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :loading="productLoss.loading"
              :headers="productLoss.headers"
              :items="productLoss.items"
              :options.sync="productLoss.options"
              :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
              must-sort
            >
              <template #item.product.defaultPrice="{ item }">
                <td>{{ $currencyFormatter.format(item.product.defaultPrice) }}</td>
              </template>
              <template #item.loss.soldValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.soldValue) }}</td>
              </template>
              <template #item.loss.loss.expiredValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.expiredValue) }}</td>
              </template>
              <template #item.loss.loss.damagedValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.damagedValue) }}</td>
              </template>
              <template #item.loss.loss.stolenValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.stolenValue) }}</td>
              </template>
              <template #item.loss.loss.otherValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.otherValue) }}</td>
              </template>
              <template #item.loss.loss.gainValue="{ item }">
                <td>{{ $currencyFormatter.format(item.loss.loss.gainValue) }}</td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import ProductsToExpireReportFilters from '@/components/ProductsToExpireReportFilters.vue';
import ReportFilters from '@/components/ReportFilters.vue';
import download from '@/components/Download';
import { shouldRefreshData } from '@/components/TableOptionsComparator';
import { toApiPagination } from '../components/TableOptionsComparator';
import HelpVideo from '@/components/HelpVideo.vue';
import News from '@/components/News.vue';


export default {
  data() {
    return {
      expiration: {
        loading: true,
        headers: [
          { text: 'Estoque', align: 'left', sortable: false, value: 'inventoryName' },
          { text: 'Cód. produto', align: 'left', sortable: true, value: 'product.code' },
          { text: 'Nome produto', align: 'left', sortable: true, value: 'product.description' },
          { text: 'Cat. produto', align: 'left', sortable: true, value: 'product.categoryName' },
          { text: 'Qtd. atual', align: 'left', sortable: true, value: 'currentQuantity' },
          { text: 'Expira em', align: 'left', sortable: true, value: 'productExpirationDate' }
        ],
        items: [],
        paginationOptions: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['productExpirationDate'],
          sortDesc: [true]
        },
        totalItems: 0,
      },
      posLoss: {
        loading: true,
        headers: [
          { text: 'PDV', align: 'left', sortable: true, value: 'pointOfSale.id' },
          { text: 'Cliente', align: 'left', sortable: true, value: 'pointOfSale.customerName' },
          { text: 'Local', align: 'left', sortable: true, value: 'pointOfSale.localName' },
          { text: 'Local específico', align: 'left', sortable: true, value: 'pointOfSale.specificLocation' },
          { text: 'Qtd. vendida', align: 'left', sortable: true, value: 'loss.soldCount' },
          { text: 'Valor vendido', align: 'left', sortable: true, value: 'loss.soldValue' },
          { text: 'Qtd. expirada', align: 'left', sortable: true, value: 'loss.loss.expiredCount' },
          { text: 'Valor expirado', align: 'left', sortable: true, value: 'loss.loss.expiredValue' },
          { text: 'Qtd. danificado', align: 'left', sortable: true, value: 'loss.loss.damagedCount' },
          { text: 'Valor danificado', align: 'left', sortable: true, value: 'loss.loss.damagedValue' },
          { text: 'Qtd. desviado', align: 'left', sortable: true, value: 'loss.loss.stolenCount' },
          { text: 'Valor desviado', align: 'left', sortable: true, value: 'loss.loss.stolenValue' },
          { text: 'Qtd. outras perdas', align: 'left', sortable: true, value: 'loss.loss.otherCount' },
          { text: 'Valor outras perdas', align: 'left', sortable: true, value: 'loss.loss.otherValue' },
          { text: 'Qtd. de ganhos', align: 'left', sortable: true, value: 'loss.loss.gainCount' },
          { text: 'Valor de ganhos', align: 'left', sortable: true, value: 'loss.loss.gainValue' },
        ],
        items: [],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['loss.loss.stolenValue'],
          sortDesc: [true]
        },
        totalItems: 0
      },
      productLoss: {
        loading: true,
        headers: [
          { text: 'ID produto', align: 'left', sortable: false, value: 'product.id' },
          { text: 'Cód. produto', align: 'left', sortable: false, value: 'product.code' },
          { text: 'Nome produto', align: 'left', sortable: false, value: 'product.description' },
          { text: 'Cat. produto', align: 'left', sortable: false, value: 'product.categoryName' },
          { text: 'Preço padrão', align: 'left', sortable: false, value: 'product.defaultPrice' },
          { text: 'Qtd. vendida', align: 'left', sortable: true, value: 'loss.soldCount' },
          { text: 'Valor vendido', align: 'left', sortable: true, value: 'loss.soldValue' },
          { text: 'Qtd. expirada', align: 'left', sortable: true, value: 'loss.loss.expiredCount' },
          { text: 'Valor expirado', align: 'left', sortable: true, value: 'loss.loss.expiredValue' },
          { text: 'Qtd. danificado', align: 'left', sortable: true, value: 'loss.loss.damagedCount' },
          { text: 'Valor danificado', align: 'left', sortable: true, value: 'loss.loss.damagedValue' },
          { text: 'Qtd. desviado', align: 'left', sortable: true, value: 'loss.loss.stolenCount' },
          { text: 'Valor desviado', align: 'left', sortable: true, value: 'loss.loss.stolenValue' },
          { text: 'Qtd. outras perdas', align: 'left', sortable: true, value: 'loss.loss.otherCount' },
          { text: 'Valor outras perdas', align: 'left', sortable: true, value: 'loss.loss.otherValue' },
          { text: 'Qtd. de ganhos', align: 'left', sortable: true, value: 'loss.loss.gainCount' },
          { text: 'Valor de ganhos', align: 'left', sortable: true, value: 'loss.loss.gainValue' },
        ],
        items: [],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['loss.loss.stolenValue'],
          sortDesc: [true]
        },
        totalItems: 0
      },
      form: {},
      activeTab: 0,
      shouldLoadLosses: false,
      loading: {
        excel: false
      },
    };
  },
  watch: {
    'expiration.paginationOptions': {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.updateExpiration();
        }
      },
      deep: true
    },
    activeTab(tab) {
      if (this.shouldLoadLosses === false && tab > 0) {
        this.shouldLoadLosses = true;
        this.updateLosses(this.form);
      }
    }
  },
  methods: {
    updateFilters(form) {
      this.form = form;
      this.updateExpiration();
      this.updateLosses(form);
    },
    updateExpiration() {
      this.expiration.loading = true;
      let params = {
        ...toApiPagination(this.expiration.paginationOptions),
        ...this.insertFiltersFromForm(this.form),
      };

      this.axios.get('api/operationalReports/productsToExpire', { params: params })
        .then(response => {
          this.expiration.items = response.data.items;
          this.expiration.totalItems = response.data.totalItems;
        })
        .catch(() => {})
        .then(() => this.expiration.loading = false);
    },
    updateLosses(form) {
      if (this.shouldLoadLosses) {
        this.posLoss.loading = true;
        this.productLoss.loading = true;
        this.axios.get('api/operationalReports/losses', { params: form })
            .then(response => {
              this.posLoss.loading = false;
              this.productLoss.loading = false;
              this.posLoss.items = response.data.pointOfSaleLoss;
              this.productLoss.items = response.data.productLoss;
            }).catch(() => {})
            .then(() => {
              this.posLoss.loading = false;
              this.productLoss.loading = false;
            });
      }
    },
    getExcelExpire() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/productsToExpire/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, 'Produtos a expirar.xlsx')
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    getExcelPoSLosses() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/pointOfSaleLosses/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, 'Perdas por PDV.xlsx')
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    getExcelProductLosses() {
      this.loading.excel = true;
      this.axios.get('api/operationalReports/productLosses/excel', {params: this.form, responseType: 'blob'})
        .then((response) => {
          this.loading.excel = false;
          download(response, 'Perdas por produto.xlsx')
        }).catch(() => {
          this.loading.excel = false;
        });
    },
    insertFiltersFromForm(form) {
      let params = {};
      params.minDate = form.minDate;
      params.maxDate = form.maxDate;
      params.inventoryIds = form.inventoryIds;
      params.inventoryTypes = form.inventoryTypes;
      params.showZeroProducts = form.showZeroProducts;
      return params;
    },
  },
  computed: {
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
  },
  components: {
    ProductsToExpireReportFilters,
    ReportFilters,
    HelpVideo,
    News
  }
}
</script>
