
import Vue from 'vue';
import Connectivity from './Connectivity.vue';
import ConnectivityTimeLine from './ConnectivityTimeLine.vue';
import ConnectivityHelp from './components/ConnectivityHelp.vue';

export default Vue.extend({
  components: {
    Connectivity,
    'time-line': ConnectivityTimeLine,
    ConnectivityHelp
  },
  data() {
    return {
      activeTab: 0,
    }
  },

})
