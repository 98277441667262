<template>
  <v-container fluid grid-list-lg>
    <!-- <v-progress-linear :indeterminate="changeOwnershipNewRequestStore.loadingView"></v-progress-linear> -->
    <v-layout row wrap>
      <v-flex class="mb-16" xs12>
        <v-form
          ref="form"
          class="px-2"
          @submit.prevent>
          <div class="subtitle-1 grey--text text--darken-2"> {{ $t('transferPointOfSale') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>
          <div class="font-size-16">{{ $t('transferProcessDescription') }}
            <router-link :to="`/${$t('path.nameChangeOfOwnership')}`">
              {{ $t('linkApproveTransfers') }}
            </router-link>
          </div>
          <div class="subtitle-1 grey--text text--darken-2 pt-3"> {{ $t('transfer') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>
          <v-row aling="center" justify="space-between">
            <v-col class="search-field pa-3" cols="12" md="7">
              <v-autocomplete
                v-model="changeOwnershipNewRequestStore.changeOwnershipNewRequest.pointOfSaleIds"
                :items="changeOwnershipNewRequestStore.pointsOfSales"
                :label="$t('pointsOfSaleToBeTransferred')"
                class="ma-0 pa-0"
                item-text="title"
                multiple
                prepend-icon="mdi-point-of-sale"
              ></v-autocomplete>
            </v-col>
            <v-col class="ma-0 pa-2" cols="12" md="1">
              <help>
                <template #title>
                  {{ $t('pointsOfSaleHelpTitle') }}
                </template>
                {{ $t('pointsOfSaleHelpDescription') }}
              </help>
            </v-col>
            <v-col class="ma-0 pa-2" cols="12" md="4">
              <destination-cnpj-not-found-dialog
                :show-dialog="changeOwnershipNewRequestStore.showDestinationCnpjNotFoundDialog"
                @close="changeOwnershipNewRequestStore.showDestinationCnpjNotFoundDialog = false">
              </destination-cnpj-not-found-dialog>
            </v-col>
          </v-row>
            <div class="subtitle-1 grey--text text--darken-2"> {{ $t('titleDestination') }}
              <help>
                <template #title>
                  {{ $t('helpTitle') }}
                </template>
                {{ $t('helpDescription') }}
              </help>
            </div>
          <v-spacer class="mb-3"></v-spacer>
          <v-row>
            <v-col cols="12" md="4" >
              <cnpj-text-filter
                :cnpj.sync="changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationCnpj"
                :cnpjIsValid.sync="changeOwnershipNewRequestStore.cnpjIsValid"
                :loading="changeOwnershipNewRequestStore.loadingCnpjField"
              >
              </cnpj-text-filter>
            </v-col>
            <v-col  cols="12" md="4" v-if="changeOwnershipNewRequestStore.showNameAndPhoneNumber">
              <v-text-field
                dense
                v-model="changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationClientName"
                :label="$t('destinationClientName')"
                prepend-icon="mdi-account"
                required>
              </v-text-field>
            </v-col>
            <v-col  cols="12" md="4" v-if="changeOwnershipNewRequestStore.showNameAndPhoneNumber">
              <phone-number-text
                dense
                v-model="changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationClientPhoneNumber"
                :label="$t('destinationClientPhoneNumber')"
                prepend-icon="mdi-cellphone"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="7">
              <v-radio-group
                v-model="changeOwnershipNewRequestStore.enableChangeOwnershipSameFranchise"
                :label="$t('sameNetworkTransfer')"
                prepend-icon="mdi-swap-horizontal-bold"
                required>
                <v-radio :label="$t('yes')" value="true"></v-radio>
                <v-radio :label="$t('no')" value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <div class="subtitle-1 grey--text text--darken-2"> {{ $t('termsAndConditions') }}</div>
          <v-divider class="mb-3 pb-3"></v-divider>
          <div class="font-size-16"><b>{{ $t('termsAndConditionsDescription') }}</b></div>
          <div class="font-size-16">
            <a href="https://www.youtube.com/watch?v=Tb_7f2_hmSw" rel="noopener noreferrer" target="_blank">
              <i class="mdi mdi-youtube" style="font-size: 20px"> </i>{{ $t('watchVideo') }}</a>
          </div>
          <v-radio-group v-model="changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfPOS">
            {{ $t('termsOfResponsibilityPOS') }}
            <v-radio :label="$t('agreeWithTermsOfResponsibilityPOS')" required value="true"
            ></v-radio>
          </v-radio-group>
          <v-radio-group v-model="changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfTransfer"
                         class="mt-0">
            {{ $t('termOfResponsibilityTransfer') }}
            <v-radio :label="$t('agreeWithTermOfResponsibilityTransfer')" required value="true"
            ></v-radio>
          </v-radio-group>
          <div class="font-size-16">{{ $t('termsAndConditionsHelp') }} <a
            href="https://api.whatsapp.com/send/?phone=551151996668&text&type=phone_number&app_absent=0" rel="noopener noreferrer"
            target="_blank">
            (11) 5199-6668</a>.
          </div>

          <v-row>
            <v-col cols="12" md="10">
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                :disabled="!changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfPOS
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipAgreedTermsOfTransfer
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipDebtFreeOrigin
                  || !changeOwnershipNewRequestStore.enableChangeOwnershipDebtFreeDestination
                  || changeOwnershipNewRequestStore.changeOwnershipNewRequest.pointOfSaleIds.length === 0"
                block
                color="primary"
                type="submit"
                @click="changeOwnershipNewRequestStore.createChangeOwnershipNewRequest"
              >
                {{ $t('submitRequest') }}
              </v-btn>

            </v-col>
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CnpjTextFilter from '@/components/textField/CnpjTextFilter.vue';
import DestinationCnpjNotFoundDialog from "@/views/ChangeOwnershipRequest/components/DestinationCnpjNotFoundDialog.vue";
import {useChangeOwnershipNewRequestStore} from "@/stores/changeOwnershipNewRequestStore";
import {watch} from "vue";
import PhoneNumberText from "@/components/textField/PhoneNumberText.vue";

export default {
  components: {
    PhoneNumberText,
    CnpjTextFilter,
    DestinationCnpjNotFoundDialog

  },
  data() {
    return {
      changeOwnershipNewRequestStore: useChangeOwnershipNewRequestStore()
    };
  },
  methods: {
    loadPointOfSales() {
      const user = this.$auth.user();
      const tenantId = this.$auth.user().tenantId;
      const groupId = this.$auth.user().tenantGroupId;
      this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.originTenantId = tenantId;
      this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.originGroupId = groupId;
      this.changeOwnershipNewRequestStore.getAllPointOfSales(user)
      this.changeOwnershipNewRequestStore.checkIfCustomerIsDebtFree(true, tenantId, groupId);
    }
  },
  mounted() {
    this.loadPointOfSales();


    watch(
      () => this.changeOwnershipNewRequestStore.changeOwnershipNewRequest.destinationCnpj,
      async () => {
        if (this.changeOwnershipNewRequestStore.cnpjIsValid) {
          await this.changeOwnershipNewRequestStore.verifyIfCnpjHasAEnvironment();
        }
      }
    );
  }
};
</script>
<style scoped>
.font-size-16 {
  font-size: 16px;
}
</style>
