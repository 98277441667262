export default {
  telemetry: {
    title: 'Telemetría',
    deviceStatus: {
      active: 'Activo',
      inactive: 'Inactivo',
      notConnected: 'No Conectado',
      unknown: 'Desconocido',
    },
    lastUpdate: 'Última actualización',
    code: 'Código',
    version: 'Versión',
    serial: 'Serial',
    system: 'Sistema',
    outdatedVersion: 'Versión desactualizada',
    status: {
      offline: 'Desconectado',
      online: 'En línea',
      alert: 'Alerta',
      noPower: 'Sin energía',
      noPowerConnected: 'El sistema de pago no está conectado a la energía',
      outOfService: 'Fuera de servicio',
      maybeOutOfService: 'Posible mantenimiento o reabastecimiento de la máquina',
      damagedMachine: 'Máquina con daño',
      screenOff: 'Pantalla apagada',
      cardReaderFail: 'Verificar lector de tarjetas',
      maybeCardReaderFail: 'Posible problema con el lector de tarjetas',
      disconnected: 'Sin conexión a internet',
      maybeDisconnected: 'Posible caída de la conexión',
      deadBattery: 'Sin conexión por falta de energía',
      noLauncher: 'La aplicación no es el lanzador predeterminado',
      unknownReason: 'Motivo desconocido',
      last: 'Hace {time}'
    },
    actions: {
      edit: 'Editar',
      replenish: 'Reabastecer',
      restartTerminal: 'Reiniciar terminal',
      pushNotification: 'Notificación push',
      unlockDoor: 'Desbloquear una cerradura',
      remoteCredit: 'Enviar crédito remoto',
    },
    tooltips: {
      help: 'Ir al video de ayuda',
      noPlanogram: 'Sin planograma registrado',
      activePlanogram: 'Planograma activo',
      pendingPlanogram: 'Planograma pendiente de activación',
      lastSale: 'Última venta hace {time}',
      belowMinimumCritical: '{quantity} productos por debajo del mínimo crítico',
    },
    card: {
      sales: {
        today: 'Hoy',
        sevenDays: '7 días',
        thirtyDays: '30 días',
        period: 'Período',
        quantity: 'Cant.',
        price: 'Valor',
        health: 'Rendimiento actual de ventas: {performance}',
        last: 'Hace {time}',
        seeOf: 'Ver transacciones de {time}',
        performance: {
          bad: 'Malo',
          average: 'Regular',
          good: 'Bueno',
        },
      },
      stock: {
        percentage: '{percentage}% inventario',
        products: '{count} productos',
      },
    },
    help: {
      title: 'Telemetría de puntos de venta',
      description: `<p>
        Aquí se muestran todos los puntos de venta en forma de una tarjeta para cada PDV. 
        La barra de color en la parte superior de la tarjeta indica la disponibilidad del PDV. 
        Cuando está <span class="green--text">verde</span>, el PDV está funcionando correctamente.
        <span class="orange--text">Naranja</span> indica que probablemente algo está mal.
        <span class="red--text">Rojo</span> indica que el PDV está no disponible.
      </p>
      <p>
        Junto al nombre y la ubicación del PDV, puedes enviar <strong>créditos remotos</strong> 
        (<i class="mdi mdi-cash"></i>) y consultar los <strong>planogramas</strong> 
        (<i class="mdi mdi-view-comfy"></i>) del PDV.
        El botón del planograma está coloreado de acuerdo a su estado.
      </p>
      <p>
        Más abajo en la tarjeta tenemos una tabla con la cantidad y el valor de productos vendidos
        hoy, en los últimos 7 días y en los últimos 30 días. Junto a esta tabla, tenemos otra información:
        <strong>nivel de señal</strong>, <strong>nivel de inventario</strong>, cantidad
        de productos por debajo del <strong>mínimo crítico</strong>, cuánto tiempo ha pasado desde la 
        <strong>última compra</strong> y el <strong>rendimiento actual de ventas</strong>, respectivamente.
        El rendimiento actual se calcula en base al total de artículos vendidos en las últimas horas, 
        en comparación con el promedio de ventas en los mismos días de la semana y al mismo horario.
      </p>
      <p>
        Al expandir la tarjeta haciendo clic en <i class="mdi mdi-chevron-down"></i>, ves un gráfico de 
        <strong>ventas por hora</strong> del PDV para el día de la semana actual. La curva azul (promedio) es el 
        promedio de ventas por hora en el día de la semana actual, mientras que el segmento naranja representa las ventas por hora
        del momento actual.
      </p>
      <p>
        Debajo del gráfico de ventas por hora tenemos la opción de <strong>reabastecer</strong> el punto de venta.
      </p>`
    }
  },
}  