<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template #default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">Filtrar</v-col>
            <v-col cols="10" class="text--secondary">
              <span v-if="!open">
                Estoque: <strong>{{ form.inventoryIds.length > 0 ? `(${form.inventoryIds.join(', ')})`: "Todos" }}</strong>,
                tipos: <strong>{{ form.inventoryTypes.length  != 2 ? `(${form.inventoryTypes.join(', ')})` : "Todos"}}</strong>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" @submit.prevent="getTransactions()">

          <v-layout row wrap>
            <v-flex sm12 md3 class="search-field mr-10 mt-2">
                <inventories-drop-down 
                  v-model="form.inventoryIds"
                  :inventoryTypeFilter="inventoryTypeFilter"
                  :multiple="true" />
              </v-flex>

            
            <v-flex sm12 md1 class="mt-3 mr-4">
                <inventories-types-chip
                  v-model="form.inventoryTypes"
                />
            </v-flex>

            <v-flex sm12 md3>
                <v-switch
                hide-details
                :label="$t('labelShowZeroProducts')"
                v-model="form.showZeroProducts">
              </v-switch>
            </v-flex>
          </v-layout>
          <v-row>
            <v-col class="search-field text-right mx-10 mb-8">
              <v-row sm12 justify="end" align="center">
              <slot name="excelButton"/>
              <v-btn class="mt-3 px-3 mx-3" color="success" :disabled="!valid" v-on:click.native="emitModification">
                <v-icon left>refresh</v-icon>Atualizar
              </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import InventoriesDropDown from '@/components/filters/InventoriesDropDown.vue';
import InventoriesTypesChip from '@/components/chips/InventoriesTypesChip.vue';

export default {
  components: {
    InventoriesDropDown,
    InventoriesTypesChip,
  },
  data(){
    return {
      valid: false,
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        showZeroProducts: false,
        inventoryIds: [],
        inventoryTypes: ["pointOfSale"],
      },
      loading: {
        locals: false,
        pointsOfSale: false,
      },
      rules:{
          required: (v) => !!v || 'Este campo é obrigatório.',
      }
    }
  },
  mounted(){
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.emitModification();
  },
  methods: {
    emitModification(){
      if(this.$refs.form.validate()){
        this.$emit('modify', this.form);
      }
    },
  },
  computed: {
    inventoryTypeFilter() {
      return this.form.inventoryTypes.length < 2 ? this.form.inventoryTypes[0] : null;
    },
  }
}
</script>