export default {
  inventory : {
    title: 'Estoque',
    help: {
      title: 'Informações de Estoque',
      description: `<p>Mantenha um controle preciso dos itens disponíveis e das movimentações em seu estoque. 
          Utilize esta página para monitorar o inventário em diferentes locais, realizar ajustes e analisar padrões de movimentação.
        </p>
        <p><strong>Inventário: </strong>Explore detalhes do estoque e movimentações com filtros personalizados. 
          Você pode segmentar por Estoque de Loja ou Estoque Central. Faça ajustes no endereço do item, 
          quantidade, limite crítico e quantidade mínima de compra. Além disso, você pode editar quantidades diretamente no sistema e baixar os dados em formato Excel.
        </p>
        <p><strong>Movimentações: </strong>Analise operações por toque específico ou em toda a sua operação com filtros detalhados. 
          Compreenda padrões de movimentação e identifique eventuais erros logísticos para otimizar sua operação.
        </p>`
    },
    items: {
      title: 'Inventário de itens',
      tableTitle: 'Itens no Estoque',
      tooltips : {
        onlyDistributionCenter: 'Selecione apenas um estoque central',
        notDeleteInventoryPDV: 'Não é possível excluir produto do estoque do ponto de venda'
      },
      messages: {
        error: {
          downloadFile: 'Não foi possível fazer o download do arquivo',
          consult: 'Não foi possível consultar o estoque',
        },
        success: {
          minimumQuantityUpdated: 'Quantidade mínima de compra atualizada com sucesso',
          addressUpdated: 'Endereço do estoque atualizado com sucesso',
          minimumCriticalUpdated: 'Quantidade mínima crítica atualizada com sucesso',
          quantityUpdated: 'Quantidade atualizada com sucesso',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Remover produtos do estoque central',
          all: 'Deseja remover todos os produtos do estoque central que nao estão em um planograma ativo?',
          specific: 'Deseja remover o produto {product} do estoque central?',
          attention: 'Atenção',
          messages: {
            success: {
              productsRemoved: 'Produtos foram removidos do estoque central',
              noProductsToRemove: 'Não há produtos para remover do estoque central',
            },
            error: {
              removeProducts: 'Não foi possível remover os produtos do estoque central',
            },
          }
        },
        itemAddress: {
          title: 'Endereço do Estoque',
          messages: {
            error: {
              addressUpdated: 'Não foi possível editar o endereço de estoque',
            },
          },
          help: {
            description: `<p align="justify">
            O <strong>Endereço de estoque</strong> é a localização do item no estoque central.
            </p>
            <p align="justify">
              Esse endereço é padronizado de acordo com o estoque, geralmente é uma especie de código que indica o local que o item foi armazenado no estoque central.
            </p>
            <p align="justify">
              Por exemplo, algumas garrafas de Coca-Cola são armazenadas na prateleira de código PRAT20. 
              Então, O endereço de estoque desse item é PRAT20, dessa forma é possivel localiza-lo no estoque central.
            </p>`
          }
        },
        addProduct: {
          title: 'Adicionar produtos ao estoque central',
          radioButtons: {
            addAllProducts: 'Adicionar todos os produtos do planograma no estoque central',
            addSpecificProducts: 'Adicionar produtos específicos no estoque central',
          },
          messages: {
            success: {
              productsAdded: 'Produtos foram adicionados ao estoque central',
              noProductsToAdd: 'Não há produtos para adicionar ao estoque central',
            },
            error: {
              addProducts: 'Não foi possível adicionar os produtos ao estoque central',
            },
          }
        },
        minimuCritical: {
          title: 'Mínimo Crítico',
          help: {
            description: `<p align="justify">
                O <strong>Mínimo Crítico</strong> é um indicador essencial para o gerenciamento de estoques, sinalizando quando é necessário repor mercadorias e evitando 
                esgotamentos. Manter atenção a esse valor é vital para otimizar a eficiência operacional e garantir a satisfação do cliente. Nossa funcionalidade é completamente 
                editável, proporcionando flexibilidade para ajustá-la conforme as necessidades operacionais específicas. Após a edição, o novo valor é salvo, permitindo controle 
                personalizado do estoque. Por exemplo, se você atualizar o valor de 5 para 10, o sistema considerará 10 como o novo "Mínimo Crítico". Isso mantém o estoque em níveis 
                ideais e evita quedas de produtos essenciais na loja.
              </p>`
          },
          label: 'Editar mínimo crítico',
          messages: {
            error: {
              minimumCriticalUpdated: 'Erro ao salvar ',
            },
          }
        },
        purchaseQuantity: {
          title: 'Quantidade mínima',
          help: {
            description: `<p align="justify">
              A <strong>Quantidade Mínima de Compra</strong> é o pedido mínimo de compra que pode ser feito para se abastecer seu estoque central.
              </p>
              <p align="justify">
                Esse valor é definido com base da <strong>Unidade de Compra</strong> (como caixa, fardo, pacote, etc) e em quantos produtos individuais essa unidade contém.
              </p>
              <p align="justify">
                Por exemplo, um fornecedor de refrigerante só vende a partir de um pedido mínimo de 10 fardos contendo em cada fardo 6 unidades.
                Então, a quantidade mínima de compra nesse caso é 60 unidades. Para isso você preencheria <strong> "10" nesse formulário</strong>, para uma <strong>Quantidade Mínima de Compra de 60 unidades.</strong>
              </p>`
          },
          minQuantity: '<strong>Quantidade Mínima de compra:</strong> total de {minimumPurchaseQuantity} unidades',
          messages: {
            error: {
              minimumQuantityUpdated: 'Não foi possível editar a Quantidade Mínima de Compra',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title:'Movimentações Realizadas',
      tab: 'Movimentações',
      messages: {
        error:{
          generateFile: 'Erro ao gerar arquivo'
        }
      }
    },

    operationTypes: {
      correction: "Correção",
      sale: "Venda",
      purchase: "Compra",
      reversed: "Reversão Operação",
      automaticCorrection: "Correção automática",
      supply: "Abastecimento",
      expired: "Validade vencida",
      damaged: "Produto danificado",
      return: "Devolução",
      removedInventory: "Remoção do Inventário",
      other: "Outros",
      expirationDateChanged: "Data de validade alterada",
      expirationDateAdded: "Data de validade adicionada",
      expirationDateRemoved: "Data de validade removida",
    }
  }
}