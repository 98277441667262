export default {
    userSearch: 'Buscar',
    userNoDataText: 'Sin usuarios registrados',
    userNoPermitToEdit: 'No tienes permiso para editar este usuario',
    userNoPermitToDelete: 'No tienes permiso para eliminar este usuario',
    userDeleteConfirmation: '¿Estás seguro de que quieres eliminar al usuario {user}?',
    userNo: 'No',
    userRemove: 'Eliminar',
    userEmailLabel: 'Correo electrónico',
    userTenantGroupIdLabel: 'Grupo',
    userRolesLabel: 'Permisos',
    userActionsLabel: 'Acciones',
    userDeleteSuccess: 'El acceso del usuario {user} ha sido bloqueado',
    userDeleteError: 'Error al eliminar usuario'
  }
  