export default {
    planogramMainHelpTitle: 'Planogramas por PDV',
    introParagraphHelp: 'Esta página lista los planogramas activos, pendientes y borradores asociados con cada punto de venta. Además, permite acciones en lote para los planogramas, como copiar un planograma para varios PDVs, agregar un elemento a varios planogramas y modificar el precio de un producto en varios planogramas.',
    headingHelp: 'Copiando un planograma para varios PDVs',
    descriptionPosHelp: 'Seleccione los PDVs de destino y haga clic en el botón Planogramas. Marque la opción "Copiar de otro planograma" y seleccione el punto de venta y el planograma de origen. Luego, haga clic en Crear.',
    additionalInfoHelp: 'Los planogramas se crean en modo de borrador para que puedan realizarse cambios eventuales. Por lo tanto, es necesario finalizarlos para que puedan ser activados en los PDVs. Solo es posible seleccionar PDVs que no tengan planogramas en modo de borrador o pendiente.',
    addSeveralHelp: 'Agregando un elemento a varios planogramas',
    selectPosHelp: 'Seleccione los PDVs en los que desea realizar esta operación y haga clic en el botón add Elementos. Los PDVs seleccionados deben tener un planograma activo o en borrador. Si el PDV ya tiene un planograma en borrador, el elemento se agregará a ese planograma. Si solo tiene el planograma activo, el elemento se agregará a un nuevo planograma en borrador que es una copia del planograma activo.',
    modifyingProductHelp: 'Modificando un producto en varios planogramas',
    selectingProductsToModifyHelp: 'Seleccione los PDVs en los que se modificarán los productos y haga clic en el botón edit Elementos. Los PDVs seleccionados deben tener un planograma activo o en borrador. Si el PDV ya tiene un planograma en borrador, los productos se actualizarán en ese planograma. Si solo tiene el planograma activo, los productos se modificarán en un nuevo planograma en borrador que es una copia del planograma activo.',
};
