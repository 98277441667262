const monthNameConvertions = {}

monthNameConvertions['1'] = "Janeiro";
monthNameConvertions['2'] = "Fevereiro";
monthNameConvertions['3'] = "Março";
monthNameConvertions['4'] = "Abril";
monthNameConvertions['5'] = "Maio";
monthNameConvertions['6'] = "Junho";
monthNameConvertions['7'] = "Julho";
monthNameConvertions['8'] = "Agosto";
monthNameConvertions['9'] = "Setembro";
monthNameConvertions['10'] = "Outubro";
monthNameConvertions['11'] = "Novembro";
monthNameConvertions['12'] = "Dezembro";

export { monthNameConvertions };