export default {
    removeConfirmationPosManagement: 'Tem certeza que deseja remover o ponto de venda?',
    searchPosManagement: 'Pesquisar',
    newPosPosManagement: 'Novo PDV',
    idPosManagement: 'ID',
    paymentSystemPosManagement: 'Sist. de pagamento',
    posCodePosManagement: 'Código PDV',
    modelManufacturerPosManagement: 'Modelo / Fabricante',
    customerPosManagement: 'Cliente',
    locationPosManagement: 'Localização',
    distributionCenterPosManagement: 'Centro de Distribuição',
    actionsPosManagement: 'Ações',
    incompletePosWarning: 'Existe pendência cadastral nesse PDV',
    deletePosManagement: 'Remover',
    noResultsPosManagement: 'Sem resultados',
    feedbackErrorPosManagement: 'Não foi possível remover o PDV',
    noPosManagement: 'Não',
    pointsOfSalePosManagement: 'Pontos de venda',
    dateCreatedPosManagement:'Data de criação',
  };