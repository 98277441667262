export default {
    okPosMachineStatus: 'Online',
    noLauncherPosMachineStatus: 'Aplicativo não é launcher padrão',
    noPowerPosMachineStatus: 'Sistema de pagamento não conectado à energia',
    maybeOutOfServicePosMachineStatus: 'Possível manutenção ou abastecimento da máquina',
    maybeDisconnectedPosMachineStatus: 'Possível queda de conexão',
    maybeCardReaderFailPosMachineStatus: 'Possível problema com o leitor de cartão',
    outOfServicePosMachineStatus: 'motivo desconhecido',
    screenOffPosMachineStatus: 'tela desligada',
    cardReaderFailPosMachineStatus: 'verificar leitor de cartão',
    disconnectedPosMachineStatus: 'sem conexão com a internet',
    outOfServiceNoPowerPosMachineStatus: 'falta de energia',
    outOfServiceMachinePosMachineStatus: 'máquina com avaria',
    deadBatteryPosMachineStatus: 'sem conexão por falta de energia',
  };