export default {
    residentialHouse: 'Residencial Casa',
    residentialApartment: 'Residencial Apartamento',
    businessOffice: 'Empresarial Escritório',
    businessIndustry: 'Empresarial Indústria',
    hospitalLocalType: 'Hospital',
    university: 'Universidade',
    othersLocalType: 'Outros',
    vendingSnacks: 'Vending Snacks',
    vendingCafe: 'Vending Café',
    vendingEntertainment: 'Vending Entretenimento',
    microMarketPosType: 'MicroMarket',
};          