export default {
    consultationTitleInvalidationsDialog: 'Invalidation consultation',
    noItemsInvalidationsDialog: 'No items registered',    
    cnpjInvalidationsDialog: 'CNPJ',
    seriesInvalidationsDialog: 'Series',
    initialNumberInvalidationsDialog: 'Initial number',
    finalNumberInvalidationsDialog: 'Final number',
    statusInvalidationsDialog: 'Status',
    reasonInvalidationsDialog: 'Reason',
    justificationInvalidationsDialog: 'Justification',
    sefazProtocolInvalidationsDialog: 'Sefaz Protocol',
    createdDateInvalidationsDialog: 'Created on',
    updatedDateInvalidationsDialog: 'Updated on',    
    processingStatusInvalidationsDialog: 'In processing',
    approvedStatusInvalidationsDialog: 'Approved',
    deniedStatusInvalidationsDialog: 'Rejected',
    requestErrorStatusInvalidationsDialog: 'Not created',
    unknownProblemInvalidationsDialog: 'Unknown problem'
  }