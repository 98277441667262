export default {
    seeMoreCardTitle: 'Información de Mercado',
    seeMoreButtonText: 'Más información',
    policyCardTitle: 'Políticas de privacidad',
    policyCardOutdatedAlert: 'La versión del Término de Consentimiento con el que estás de acuerdo está desactualizada. Para acceder a la Inteligencia de Mercado, debes aceptar el nuevo Término de Consentimiento.',
    policyCardDownloadButton: 'Descargar',
    policyCardHideButton: 'Ocultar',
    policyCardAcceptButton: 'Aceptar',
    posCardUpdateTitle: 'Actualizando tus PDVs',
    posCardUpdateInstructions: 'Para completar la visualización de la información de mercado, es necesario actualizar los siguientes campos',
    posCardUpdateInstructionsLocalType: 'Tipo de Local',
    posCardUpdateInstructionsNumberOfPeople: 'Cantidad de Personas',
    posCardUpdateInstructionsAndOr: 'y/o',
    posCardUpdateInstructionsFollowingPos: 'de los siguientes PDVs: ',
    posCardAllUpdatedMessage: '¡Todos los PDVs han sido actualizados!',
    posCardBackButton: 'Atrás',
    posCardFinishButton: 'Listo',
    posCardUpdateButtonText: 'Actualizar',
    errorLoadingTerms: 'Hubo un error al cargar los términos de consentimiento de la Inteligencia de Mercado. Intente volver a cargar la página.',
    getPrivacyPolicyError: 'Hubo un error al cargar el Término de Consentimiento',
    getIncompletePosError: 'No se pudieron cargar los puntos de venta pendientes',
    putUserAuthorizationError: 'No se pudo registrar la aceptación del Término de Consentimiento',
    putAllPosRegisteredError: 'Inténtelo de nuevo más tarde. Hubo un error en la confirmación',
    updatePosData: 'Actualizar datos del PDV',
    termsOfAcceptingInformation: `Para acceder a la información deberá aceptar las condiciones de uso y cumplimentar los datos solicitados. Clickea en <strong style="color: #1976D2">ACCEDER A TÉRMINOS</strong>.`,
    accessThermosButton: 'ACCEDER A TÉRMINOS',
};
