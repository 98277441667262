export default {
    selectPosWithPlanogram: 'Select POS with a planogram (except pending ones)',
    addOrUpdateItems: 'Add or update items',
    productNameLabel: 'Product',
    priceNameLabel: 'Price',
    desiredQuantityLabel: 'Desired quantity (par level)',
    criticalMinimumLabel: 'Critical minimum',
    optionsLabel: 'Options',
    addOnlyLabel: 'Add only',
    updateOnlyLabel: 'Update only',
    addOrUpdateLabel: 'Add or update',
    btnClose: 'Close',
    btnSave: 'Save',
    noProducts: 'No products registered',
    insertValidNameRules: 'Insert a valid name',
    requiredFieldRules: 'This field is required.',
    insertIntegerRules: 'Insert an integer',
    insertValidValueRules: 'Insert a valid value',
    validUrlRules: 'Insert a valid URL',
    addUpdatePos: 'Error when adding/updating item in POS planogram ',
    tryAgainPos: ' Try again.',
    addPlanogramProducts: 'Here you can add a product to several planograms, update it in several planograms, or both, according to the modes below: ',
    justAdd: 'Add only: adds the product to planograms that do not contain it. For planograms that already contain the product, no changes are made.',
    justUpdate: 'Update only: updates the product information only in the planograms that contain it. In this mode, only the Product field is required. Fields left blank will not be modified.',
    addOrUpdate: 'Add or update: a combination of the two previous modes. It adds the product to planograms that don\'t have it, and updates the product in planograms that already have it.',
    justAddTheProduct: 'Only adds the product to planograms that donn\'t have it',
    onlyEditPlanograms: 'Only edits planograms that already have the product',
    addsTheProductToPlanograms: 'Adds the product to planograms that donn\'t have it, and edits it in planograms that already have it',
    availabilityLocation: 'Location',
    availabilityOrder: 'Order',
    availabilityLocalAndOrder: 'Location and order',
    btnItems: 'Items',
    warningAddOrUpdateItems: 'The products will be added or updated <strong>only</strong> in the selected points of sale that do not have pending planograms.'
};