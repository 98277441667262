<template>
  <v-row row wrap>
    <v-col>
      <v-autocomplete
        :prepend-icon="prependIcon"
        :items="types"
        :rules="!disabled ? rules : []"
        v-model="localType"
        :label="$t('typeOfLocation')"
        item-text="name"
        item-value="value"
        :disabled="disabled"
        :hint="disabled ? $t('locationTypeSelect') : ''"
        persistent-hint
        required
        :rounded="rounded"
        :clearable="clearable"
      >
        <template #append-outer v-if="showHelp">
          <help>
            <template #title>{{ $t('typeOfLocation') }}</template>
            <p>
              {{ $t('locationTypeDescription') }}<br>
                <br>
                  <span v-html="$t('locationTypeOptions')"></span>
            </p>
          </help>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import PointOfSaleType from "@/types/enum/PointOfSaleTypes";

export default {
  data() {
    return {
      types: PointOfSaleType.localType,
    };
  },
  props: {
    value:{},
    disabled: { type: Boolean, default: true },
    rules: { type: Array, default: () => [] },
    rounded: { type: Boolean, default: false },
    prependIcon: { type: String, default: "mdi-home-city" },
    showHelp: { type: Boolean, default: true },
    clearable: { type: Boolean, default: false },
  },
  computed: {
    localType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>