export default {
    productNameDraftEdition: 'Producto',    
    productImageDraftEdition: 'Imagen',
    selectionDraftEdition: 'Selección',
    productDescriptionDraftEdition: 'Descripción del producto',
    priceDraftEdition: 'Precio',
    capacityDraftEdition: 'Capacidad máxima',
    desiredQuantityDraftEdition: 'Cantidad deseada (nivel de par)',
    criticalMinimumDraftEdition: 'Mínimo crítico',
    actionsDraftEdition: 'Acciones',
    removeItemDraftEdition: 'Eliminar ítem',
    addMultipleDraftEdition: 'Agregar varios',
    saveDraftEdition: 'Guardar',
    cancelDraftEdition: 'Cancelar',
    cancelEditPromptDraftEdition: '¿Estás seguro de que quieres cancelar la edición rápida? Se perderán los cambios.',
    noProductsDraftEdition: 'No hay productos registrados',
    fixErrorsDraftEdition: 'Corrige los errores antes de guardar',
    successSaveDraftEdition: 'Planograma editado exitosamente.',
    saveFailureDraftEdition: 'Error al guardar el planograma.',
};
