import { Bar } from 'vue-chartjs'
import Vue from 'vue'

export default Vue.extend({
  extends: Bar,
  props: {
    labels: { type: Array, required: true },
    datasets: { type: Array, required: true },
    barsize:{ type: Number, default: 150},
    labelY: { type: String, default: 'totalSold' },
    title: { type: String, default: 'salesByLocation' },
    formatY: { type: String, default: 'currency' }
  },
  /*watch: {
    labels: function (newValue, oldValue){
      this.renderChart();
    },
    datasets: function (newValue, oldValue){
      this.renderChart();
    }
  },*/
  data(){
    return {
      tooltipTotal: 0
    }
  },
  mounted () {
    const formatYAxes = this.formatY;
    const $currencyFormatter = this.$currencyFormatter
    this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      },{
        title: {
          display: this.title !== '',
          text: this.$t(this.title),
          fontSize: 16,
          padding: 30,
          fontColor: '#333'
        },
        scales: {
          xAxes: [{
            maxBarThickness: this.barsize,
            stacked: true,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              callback: ((value) => {
                if(Array.isArray(value)){
                  return value.map((v) => this.truncate(v, 25));
                }
                else return this.truncate(value, 25)
              })
            }
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.$t(this.labelY),
            },
            ticks: {
              callback: function(value) {

                if(formatYAxes == 'currency'){
                  return $currencyFormatter.format(value);
                }
                else if (formatYAxes == 'unit') {
                    return value + ' un ';
                }
                else{
                  return value;
                }
              }
            }
          }]
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItems, data) {
              let label = data.labels[tooltipItems[0].index];
              if(Array.isArray(label)){
                return label.join("\r\n");
              }
              else return label;
            },
            label: function(tooltipItem, data){
              var label = data.datasets[tooltipItem.datasetIndex].label;
              var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              this.tooltipTotal = 0;
              data.datasets.forEach((dataset) => {
                this.tooltipTotal += dataset.data[tooltipItem.index];
              });
      
              if(formatYAxes == 'currency'){
                this.total += value;
                return label + ": " + $currencyFormatter.format(value)
              }
              else if (formatYAxes == 'uni'){
                return label + ' un. ';
              }

            },
            footer: function() {

              if(formatYAxes == 'currency'){
                return "TOTAL: " + $currencyFormatter.format(this.tooltipTotal)
              }
              return "TOTAL: " + this.tooltipTotal + ' un '
            }
          }
        }
      });
  },
  methods: {
    truncate(str, length){
      if (str != null) {
        return str.length > length ? str.substr(0, length-1) + '...' : str;
      }
      return str;
    }
  }
})
