export default {
  users: {
    title: "Users",
    group: "Group",
    roles: {
      admin: "Administrator",
      adminApp: "Administrator App",
      adminPrivateLabel: "Administrator Private Label",
      registrations: "Registrations",
      accounting: "Accounting",
      priceEditing: "Price Editing",
      orderManager: "Order Manager",
      manager: "Manager",
      blindInventoryAndSupply: "Blind Inventory and Supply",
      logistics: "Logistics",
      remoteMonitoring: "Remote Monitoring",
      invoices: "Invoices",
      pushNotification: "Push Notification",
      security: "Security",
      buildingManager: "Building Manager",
      support: "Support",
      telemetry: "Telemetry",
      touchPaySupplier: "TouchPay Supplier App",
      sales: "Sales",
      planogramView: "Planogram View",
      privateLabelView: "Private Label View",
      transactionView: "Transaction View",
    },
    help: {
      page: {
        title: "Users",
        description: `
          <p>
            On this page, you can view, create, and manage users within the system. The following information is displayed about registered users: email, group, if applicable, and permissions. For each user, there are options for editing or deletion, allowing adjustments to settings or removal from the system.
          </p>
          <p>
            You can create new users by providing only the email and password. To do this, simply use the <strong>New User</strong> feature, which allows you to register and set access permissions.
          </p>
          <p>
            Groups in TouchPay centralize the information of licensees or franchisees into a single viewing environment. Each group has exclusive access to its own operations and information, without seeing data from other groups. This functionality allows franchise to track their operations in a centralized manner while maintaining privacy between groups.
          </p>`,
      },
    },
  }
};