export default {
    buildingManagerReportHeader: "Filtros",
    buildingManagerMinDateLabel: "Fecha de inicio",
    buildingManagerMaxDateLabel: "Fecha de finalización",
    buildingManagerExcel: "Excel",
    buildingManagerUpdate: "Actualizar",
    buildingManagerTableTitle: "Informe de Ventas",
    bManagerSalesPointOfSaleId: "ID PdV" ,
    bManagerSalesBoxId: "ID Caja",
    bManagerSalesLocalCustomerName: "Cliente",
    bManagerSalesLocalName: "Local",
    bManagerSalesSpecificLocation: "Ubicación Específica",
    bManagerSalesTotal: "Ventas",
    buildingManagerHandleErrorMessage: "Se produjo un error al cargar el informe",
    bManagerExcelDownloadMessage: "El informe {filename} estará disponible en la página de Descargas",
    salesByCategoryDialogTitle: "Categorías más vendidas de la PDV {posId}",
    categorySalesHeaderCategoryId: "ID Categoría",
    categorySalesHeaderCategory: "Categoría",
    categorySalesHeaderQuantity: "Cantidad",
    categorySalesHeaderTotals: "Ventas",
    categorySalesCloseButton: "Cerrar",
    buildingManagerGetCategoriesSalesErrorMessage: "Se produjo un error al cargar las ventas por categoría"
};