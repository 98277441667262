export default {
    changeImage: 'Cambiar imagen',
    removeImage: 'Eliminar imagen',
    deleteImageConfirmation: '¿Estás seguro de que quieres eliminar la imagen?',
    noEditableImage: 'No',
    removeEditableImage: 'Eliminar',
    imageRemoved: 'Imagen eliminada',
    unableToRemoveImage: 'No se pudo eliminar la imagen',
    imageSavedSuccessfully: '¡Imagen guardada con éxito!',
    unableToValidateImage: 'No se pudo validar la imagen',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    uploadImageError: 'No se pudo subir la imagen',
    authorizeUploadError: 'No se pudo autorizar la subida de la imagen: ',
    unknownError: 'Error desconocido',
};
