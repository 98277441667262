export default {
    fiscalScenarios: 'Escenarios Fiscales',
    discounts: 'Descuentos',
    fiscalNotes: 'Notas Fiscales',
    planograms: 'Planogramas',
    salesReports: 'Informes de Ventas',
    dynamicSalesSummary: 'Resumen Dinámico de Ventas',
    priceTables: 'Tablas de Precios',
    transactions: 'Transacciones',
    replenishment: 'Reabastecimiento',
    purchases: 'Compras',
    stocks: 'Inventarios',
    ruptureReports: 'Informes de Rupturas',
    operationalReports: 'Informes Operativos',
    dynamicStockSummary: 'Resumen Dinámico de Inventario',
    purchaseSuggestions: 'Sugerencias de Compras',
    help: 'Ayuda',
    condominiumReports: 'Informes de Condominios',
    dashboard: 'Tablero',
    downloads: 'Descargas',
    releaseNotes: 'Notas de Versión',
    posRegistration: 'Registro de PDVs',
    newTouchPay: 'Nuevo TouchPay',
    privateLabel: 'Etiqueta Privada',
    posView: 'Vista PDV',
    evaluations: 'Evaluaciones',
    orders: 'Órdenes',
    pushNotifications: 'Notificaciones Push',
    appUsers: 'Usuarios de la Aplicación',
    deviationAlerts: 'Alertas de Desviaciones',
    audit: 'Auditoría',
    accessControl: 'Control de Acceso',
    myBills: 'Mis Cuentas',
    activations: 'Activaciones',
    clients: 'Clientes',
    pagSeguro: 'PagSeguro',
    swagger: 'Swagger',
    feedback: 'Retroalimentación',
};
