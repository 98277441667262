<template>
  <v-container fluid grid-list-lg>
    <v-progress-linear indeterminate :hidden="!loading"></v-progress-linear>
    <v-layout row wrap v-if="!loading">
      <v-flex xs12>
        <v-form
          class="px-4"
          ref="form"
          lazy-validation
          v-model="valid"
          @submit.prevent="submitPointOfSale(form)"
        >
          <div class="subtitle-1 grey--text text--darken-2">{{ $t('systemPaymentPointOfSale') }}</div>
          <v-divider class="mb-3"></v-divider>
          <v-row justify="space-between" align="center">
            <v-col md="4" cols="12">
                <v-flex sm12 md8>
                  <v-autocomplete
                    prepend-icon="credit_card"
                    :items="boxes"
                    v-model="form.boxId"
                    :loading="!boxesReceived"
                    :label="$t('systemCodePointOfSale')"
                    item-text="text"
                    item-value="id"
                    :hint="$t('identificationCodePointOfSale')"
                    persistent-hint
                    :no-data-text="$t('systemNotFoundPointOfSale')"
                  ></v-autocomplete>
                </v-flex>
            </v-col> 
            <v-col md="4" cols="12">
              <v-flex v-if="isEdit" sm12 md10>
                <template v-if="form.invoiceCompanyId">
                  <v-icon left color="success">mdi-receipt</v-icon>
                  <span class="green--text text--darken-1"
                    >{{ $t('enabledInvoicePointOfSale') }}</span
                  >
                </template>
                <template v-else>
                  <v-icon left color="grey">mdi-receipt</v-icon>
                  <span class="grey--text">{{ $t('disabledInvoicePointOfSale') }}</span>
                </template>
                <br />
                <invoice-config
                  :pos-id="posId"
                  :invoice-company-id="form.invoiceCompanyId"
                  @invoice-change="onInvoiceChanged"
                >
                  <template #activator="{ on }">
                    <v-btn v-on="on" text outlined small class="ml-6"
                      >{{ $t('configurePointOfSale') }}</v-btn
                    >
                  </template>
                </invoice-config>
              </v-flex>
            </v-col>
            <v-col md="4" cols="12">
              <v-flex v-if="isEdit && $auth.user().tenantId != '268'" sm12 md8>
                <mobile-activation :pos-id="posId">
                  <template #activator="{ on, isActive, loading }">
                    <span>
                      <template v-if="isActive">
                        <v-icon left color="success">mdi-cellphone-play</v-icon>
                        <span class="green--text text--darken-1"
                          >{{ $t('appEnabledPointOfSale') }}</span
                        >
                      </template>
                      <template v-else>
                        <v-icon left color="grey">mdi-cellphone-off</v-icon>
                        <span class="grey--text">{{ $t('appDisabledPointOfSale') }}</span>
                      </template>
                    </span>
                    <div>
                      <v-btn
                        v-on="on"
                        :loading="loading"
                        text
                        outlined
                        small
                        class="ml-6"
                      >
                        {{ isActive ? $t('displayPointOfSale') : $t('registerPointOfSale') }}
                      </v-btn>
                    </div>
                  </template>
                </mobile-activation>
              </v-flex>
            </v-col>
            </v-row>
            <v-row justify="space-between" align="center">
              <v-col md="4" cols="12">
                <v-flex sm12 md8>
                  <v-autocomplete
                    v-if="isBrazil"
                    prepend-icon="credit_card"
                    :items="pickPayTokens"
                    v-model="form.picPayTokenId"
                    :label="$t('picPayPointOfSale')"
                    item-text="alias"
                    item-value="id"
                    :hint="$t('picPayTokenPointOfSale')"
                    persistent-hint
                    :no-data-text="$t('tokensNotFoundPointOfSale')"
                  ></v-autocomplete>
                </v-flex>
              </v-col>
              <v-col md="4" cols="12">
                <v-flex v-if="isEdit" sm12 md8>
                  <locks :pos-id="posId">
                    <template #activator="{ on, isActive, loading }">
                      <span>
                        <template v-if="isActive">
                          <v-icon left color="success">mdi-door-closed-lock</v-icon>
                          <span class="green--text text--darken-1"
                            >{{ $t('registeredLocksPointOfSale') }}</span
                          >
                        </template>
                        <template v-else>
                          <v-icon left color="grey">mdi-door-closed-lock</v-icon>
                          <span class="grey--text">{{ $t('noLocksPointOfSale') }}</span>
                        </template>
                      </span>
                      <div>
                        <v-btn
                          v-on="on"
                          :loading="loading"
                          text
                          outlined
                          small
                          class="ml-6"
                        >
                          {{ isActive ? $t('displayPointOfSale') : $t('registerPointOfSale') }}
                        </v-btn>
                      </div>
                    </template>
                  </locks>
                </v-flex>
              </v-col>
              <v-col md="4" cols="12">
                <v-flex v-if="isEdit" sm12 md8>
                  <v-img
                    v-if="form.logoUrl"
                    :src="form.logoUrl"
                    contain
                    max-width="150"
                    max-height="150"
                  ></v-img>
                  <image-selector :pos-id="posId" @logo-change="onLogoChange">
                    <template #activator="{ on }">
                      <v-btn :disabled="form.isLogoBlocked && !userIsAdministrator" text small outlined v-on="on"
                        >{{ form.logoUrl ? $t('changePointOfSale') : $t('addPointOfSale') }} {{ $t('thenPointOfSale') }}</v-btn
                      >
                    </template>
                  </image-selector>
                </v-flex>
              </v-col>
            </v-row>         
          <div class="subtitle-1 grey--text  text--darken-2">{{ $t('clientPointOfSale') }}</div>
          <v-divider class="mb-3"></v-divider>
          <v-layout row wrap>
            <v-flex sm12 md3>
              <crud-select
                icon="account_box"
                :label="$t('clientPointOfSale')"
                api-url="api/Customers"
                v-model="form.localCustomerId"
                :rules="[rules.required, rules.nonEmpty]"
                :hint="$t('selectClientPointOfSale')"
                item-text="name"
                item-value="id"
              ></crud-select>
            </v-flex>
            <v-flex sm12 md3>
              <crud-select
                icon="location_on"
                :label="$t('locationPointOfSale')"
                api-url="api/Locals"
                :api-object="locationApiObject"
                has-custom-items
                :custom-items="locations"
                v-model="form.localId"
                :rules="[rules.required, rules.nonEmpty]"
                :disabled="form.localCustomerId === null"
                :hint="
                  form.localCustomerId === null
                    ? $t('selectLocationPointOfSale')
                    : ''
                "
                persistent-hint
                required
                item-text="name"
                item-value="id"
              ></crud-select>
            </v-flex>
            <v-flex sm12 md3>
              <v-text-field
                id="internalLocation-input"
                :label="$t('internalLocationPointOfSale')"
                prepend-icon="my_location"
                :rules="[rules.nonEmpty]"
                v-model="form.specificLocation"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 md3>
              <v-autocomplete
                id="statesBRA-input"
                v-if="$auth.user().tenantCountry == 'BR'"
                key="brazil-state"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.addressState"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                required
                :rules="[rules.required, rules.nonEmpty]"
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="$auth.user().tenantCountry == 'US'"
                key="usa-state"
                id="stateUSA-input"
                prepend-icon="mdi-map"
                :items="states"
                v-model="form.state"
                :label="$t('statePointOfSale')"
                item-text="name"
                item-value="value"
                required
                :rules="[rules.required, rules.nonEmpty]"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm12 md3>
              <pos-type-selector 
              v-model="form.posType" 
              @input="onPosType"></pos-type-selector>
            </v-flex>
             <v-flex sm12 md3>
              <v-text-field
                prepend-icon="mdi-account-multiple"
                :label="textRelatedPeopleQuantity"
                type="number"
                placeholder="0"
                step="10"
                :rules="hasMarketIntelligence ? [$validations.requiredForMarketIntel] : []"
                v-model="form.localRelatedPeopleQuantity"
                :disabled="form.localId === null"
                :hint="
                  form.localId === null
                    ? $t('totalNumberOfResidentsPointOfSale')
                    : ''
                  "
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex sm12 md3>
              <local-type-selector
                v-model="form.localType"
                @input="onLocalType"
                :disabled="form.localId == null"
                :rules="hasMarketIntelligence ? [$validations.requiredForMarketIntel] : []"
              ></local-type-selector>
            </v-flex>
            <v-flex sm12 md3 v-if="form.localType == 'Others'">
              <v-text-field prepend-icon="mdi-pencil-outline" :label="$t('localTypePointOfSale')" v-model="form.localOtherType"></v-text-field>
            </v-flex>
            <v-flex sm12 md3 v-if="!isBrazil">
              <crud-select
                icon="mdi-map-marker-circle"
                :label="$t('taxRegion')"
                api-url="api/FiscalRegion"
                v-model="form.fiscalRegionId"
                :rules="isBrazil ? [] : [rules.nonEmpty]"
                :required="!isBrazil"
                removable
                item-text="name"
                item-value="id"
              ></crud-select>
            </v-flex>
            <v-flex sm12 md3>
              <building-manager-drop-down
                v-model="form.buildingManagerId"
              ></building-manager-drop-down>
            </v-flex>
          </v-layout>
          <div class="subtitle-1 grey--text  text--darken-2">{{ $t('stockPointOfSale') }}</div>
          <v-divider class="mb-3"></v-divider>
          <v-layout row wrap>
            <v-flex sm12 md4>
              <v-autocomplete
                prepend-icon="mdi-warehouse"
                :items="inventories"
                v-model="form.distributionCenterId"
                :disabled="form.masterPointOfSaleId != null"
                :readonly="form.masterPointOfSaleId != null"
                :loading="!inventoryReceived"
                :label="$t('distributionCenterPointOfSale')"
                item-text="name"
                item-value="id"
                single-line
                :hint="$t('nameDistributionCenterPointOfSale')"
                persistent-hint
                :no-data-text="$t('distributionCenterNotRegistered')"
                clearable
              >
                <template #append-outer>
                  <legend>
                    <help>
                      <template #title>{{ $t('distributionCenterPointOfSale') }}</template>
                      <p>
                        {{ $t('distributionCenterConfiguration') }}
                      </p>
                      <p v-if="form.masterPointOfSaleId != null">
                        <b>
                          <p v-html="$t('cannotBeChangedPointOfSale')"></p>
                        </b>
                      </p>
                      <v-alert class="text-left" dense outlined text type="info" border="left">
                        {{ $t('accessToFunctionality') }}
                      </v-alert>
                    </help>
                  </legend>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex sm12 md4>
              <v-autocomplete
                prepend-icon="all_inbox"
                v-bind:items="pointsOfSale"
                v-model="form.masterPointOfSaleId"
                class="pr-2"
                :label="$t('sharedStockPointOfSale')"
                item-text="searchText"
                item-value="id"
                :no-data-text="$t('noPointsOfSalePointOfSale')"
                :hint="$t('withMoreTerminalPointOfSale')"
                persistent-hint
              >
                <template #append-outer>
                  <help>
                    <template #title>{{ $t('sharedStockPointOfSale') }}</template>
                    <v-alert class="text-left" dense outlined text type="warning" border="left">
                      {{ $t('microMarketsFunction') }}
                    </v-alert>
                    <p>
                      {{ $t('terminalInTheSameStore') }}
                    </p>
                    <p v-html="$t('registrationAtOnlyOnePdv')">
                    </p>
                  </help>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex v-if="isEdit" xs12 md4>
              <qr-code-operator :pos-id="posId">
                <template #activator="{ on, loading }">
                  <span>
                    <template>
                      <v-icon left color="success">mdi-cellphone-play</v-icon>
                      <span class="green--text text--darken-1"
                        >{{ $t('touchPayReplenishmentPointOfSale') }}</span
                      >
                    </template>
                  </span>
                  <div>
                    <v-btn
                      v-on="on"
                      :loading="loading"
                      text
                      outlined
                      small
                      class="ml-6"
                      >{{ $t('displayPointOfSale') }}</v-btn
                    >
                  </div>
                </template>
              </qr-code-operator>
            </v-flex>
          </v-layout>
          <div class="subtitle-1 grey--text text--darken-2">{{ $t('machinePointOfSale') }}</div>
          <v-divider class="mb-3"></v-divider>
          <v-layout row wrap>
            <v-flex sm12 md4>
              <v-text-field
                :label="$t('posCodePointOfSale')"
                prepend-icon="business"
                v-model="form.companyCode"
                class="pr-2"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 md4>
              <crud-select
                icon="build"
                :label="$t('manufacturerPointOfSale')"
                api-url="api/MachineManufacturers"
                v-model="form.machine.machineManufacturerId"
                :rules="[rules.nonEmpty]"
                removable
                item-text="name"
                item-value="id"
              ></crud-select>
            </v-flex>
            <v-flex sm12 md4>
              <crud-select
                icon="star_border"
                :label="$t('modelPointOfSale')"
                api-url="api/MachineModels"
                v-model="form.machine.machineModelId"
                :rules="[rules.nonEmpty]"
                removable
                item-text="name"
                item-value="id"
              ></crud-select>
            </v-flex>
          </v-layout>
          <v-flex xs12 md4>
            <v-btn right type="submit" color="success" :disabled="!valid">
              {{ isEdit ? $t('updatePointOfSale') : $t('addPointOfSale') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-flex>
      <v-snackbar :timeout="5000" color="error" v-model="snackbar">
        {{
          $t('unableToSavePosPointOfSale') +
          (snackbarError == null ? "" : ": " + snackbarError)
        }}
        <v-btn dark text @click.native="snackbar = false">{{ $t('okPointOfSale') }}</v-btn>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
import Locks from "@/views/Locks";
import CrudSelect from "@/components/CrudSelect";
import InvoiceConfig from "@/views/invoice/InvoiceConfig";
import BrazilStates from "@/components/BrazilStates";
import UsaStates from "@/components/UsaStates";
import MobileActivation from "@/views/MobileActivation";
import PointOfSaleImageSelector from "@/views/PointOfSaleImageSelector";
import QrCodeOperator from "@/views/QrCodeOperator";
import LocalTypeSelector from '@/components/filters/LocalTypeSelector';
import PosTypeSelector from '@/components/filters/PosTypeSelector';
import { MarketIntelligenceAccess } from "@/api/agent";
import BuildingManagerDropDown from '@/components/filters/BuildingManagerDropDown.vue';
import {ADMINISTRATOR_ROLE} from "@/constants/Roles";
import i18n from "@/locales/i18n";

export default {
  data() {
    return {
      textRelatedPeopleQuantity: this.$t('residentsQuantityPointOfSale'),
      loading: false,
      posId: parseInt(this.$route.params.posId),
      isEdit: false,
      valid: true,
      form: this.newForm(),
      boxes: [],
      pickPayTokens: [],
      locations: [],
      pointsOfSale: [],
      inventories: [],
      locationApiObject: { customerId: null, name: null },
      rules: {
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
        required: (v) => !!v || this.$t('requiredRules'),
      },
      manufacturerInput: "",
      snackbar: false,
      snackbarError: null,
      posReceived: false,
      boxesReceived: false,
      inventoryReceived: false,
      brazilStates: BrazilStates.states,
      usaStates: UsaStates.states,
      hasMarketIntelligence: false
    };
  },
  mounted() {
    if (this.posId) {
      this.loading = true;
      this.isEdit = true;
      this.axios.get("api/PointsOfSale/" + this.posId).then((response) => {
        this.loading = false;
        this.posReceived = true;
        const form = response.data;
        if (form.machine == null) form.machine = {};
        this.form = form;
      });
    }
    this.getBoxes();
    this.getPicPayTokens();
    this.getPointsOfSale();
    this.getInvetories();
    this.getUserIntelligenceRegister();
  },
  computed: {
    masterPointOfSaleName() {
      return this.pointsOfSale.find(p => p.id === this.form.masterPointOfSaleId)?.searchText || null;
    },
    states() {
      return this.isBrazil ? this.brazilStates : this.usaStates;
    },
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
    },
    userIsAdministrator() {
      return this.$auth.check(ADMINISTRATOR_ROLE);
    },
  },
  watch: {
    "form.localCustomerId": function (newId, oldId) {
			if (oldId !== null) this.form.localId = null;
			if (this.form.localCustomerId != null) {
				this.locationApiObject.customerId = newId;
				this.axios.get("api/Customers/" + newId).then((response) => {
					this.locations = response.data.locals;
				});
			}
		},
    "form.companyCode": function (code) {
      if (code === "") this.form.companyCode = null;
    },
    "form.localId": function (newId) {
      if (this.form.localId != null) {
        const local = this.locations.find(l => l.id === newId);
        this.updateLocalFormFields(local);
      }
    },
    "locations": function (newLocations){
      if (this.form.localId != null) {
        const local = newLocations.find(l => l.id === this.form.localId);
        this.updateLocalFormFields(local);
      }
    },
    "form.localType": function (newType) {
      if(newType == "Apartment" || newType == "House"){
        this.textRelatedPeopleQuantity = this.$t('residentsQuantityPointOfSale');
      }
      else if(newType == "Industry" || newType == "Office"){
        this.textRelatedPeopleQuantity = this.$t('employeesQuantityPointOfSale');
      }
      else if(newType == "University"){
        this.textRelatedPeopleQuantity = this.$t('studentsQuantityPointOfSale');
      }
      else{
        this.textRelatedPeopleQuantity = this.$t('frequentPeopleQuantityPointOfSale');
      }
    },
    snackbar: function (newValue) {
      if (!newValue) this.snackbarError = null;
    },
  },
  methods: {
    newForm() {
      return {
        machine: {
          companyCode: null,
          machineManufacturerId: null,
          machineModelId: null,
          hasCoinChanger: false,
          hasBillValidator: false,
          boxId: null,
        },
        masterPointOfSaleId: null,
        localCustomerId: null,
        localId: null,
        localRelatedPeopleQuantity: null,
        specificLocation: null,
        picPayTokenId: null,
        localType: null,
        localOtherType: null,
        posType: null,
        fiscalRegionId: null,
        buildingManagerId: null,
        isLogoBlocked: false,
      };
    },
    submitPointOfSale(form) {
      form.localOtherType = form.localType == "Others" ? form.localOtherType : null;
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          this.axios
            .put("api/PointsOfSale/" + this.posId, form)
            .then(() => {
              this.$router.push('/' + i18n.t('path.nameManagePos'));
            })
            .catch((error) => this.handleError(error));
        } else {
          this.axios
            .post("api/PointsOfSale", form)
            .then(() => {
              this.$router.push('/' + i18n.t('path.nameManagePos'));
            })
            .catch((error) => this.handleError(error));
        }
      }
    },
    handleError(error) {
      if (
        error.response != null &&
        error.response.data != null &&
        error.response.data.length > 0
      ) {
        this.snackbarError = error.response.data;
      }
      this.snackbar = true;
    },
    getBoxes() {
      this.axios
        .get(`api/Boxes/available?pointOfSaleId=${this.posId}`)
        .then((response) => {
          this.boxesReceived = true;
          this.boxes = response.data;
          this.boxes.forEach(
            (b) =>
              (b.text =
                b.id +
                (b.machineInterfaceId !== null
                  ? ` (MMD-0${b.machineInterfaceId})`
                  : ""))
          );
        });
    },
    getPicPayTokens() {
      this.axios
        .get(`api/mobileactivation/picpay`)
        .then((response) => {
          this.pickPayTokens = response.data;
        })
        .catch((error) => {
          this.$refs.feedback.handleError(
            this.$t('errorFindingTokenPointOfSale'),
            error
          );
        });
    },
    onInvoiceChanged(companyId) {
      this.form.invoiceCompanyId = companyId;
    },
    onLogoChange(logoUrl) {
      this.form.logoUrl = logoUrl;
    },
    onLocalType(Type) {
      this.form.localType = Type;
    },
    onPosType(Type) {
      this.form.posType = Type;
    },
    getPointsOfSale() {
      this.axios.get("api/pointsOfSale").then((response) => {
        this.pointsOfSale = response.data.filter(
          (pos) => pos.id !== this.posId
        );
        this.pointsOfSale.forEach((pos) => {
          pos.searchText =
            `(PDV ${pos.id}) ${pos.localCustomerName} - ${pos.localName}` +
            (pos.specificLocation != null ? ` - ${pos.specificLocation}` : "");
        });
      });
    },
    getInvetories() {
      this.axios.get("api/web/inventory/", { params: { centralOnly: true } })
      .then((response) => {
        this.inventories = response.data;
        this.inventoryReceived = true;
      });
    },
    updateLocalFormFields(local) {
      if (local !== undefined) {
        this.form.localRelatedPeopleQuantity = local.relatedPeopleQuantity;
        this.form.localType = local.type;
        this.form.localOtherType = local.otherType;
      } else {
        this.form.localRelatedPeopleQuantity = null;
        this.form.localType = null;
      }
    },
    async getUserIntelligenceRegister() {
      const params = {
        tenantId: this.$auth.user().tenantId,
        groupId: this.$auth.user().tenantGroupId
      }

      try {
        const userAccess = await MarketIntelligenceAccess.getUserAccess(params)

        if (userAccess.accessAllowed && userAccess.hasAllPosRegistered) 
          this.hasMarketIntelligence = true
      }
      catch (error){
        if (error.response?.status == 404) {
          this.showIncompletePos = false
          this.hasMarketIntelligence = false
        }
        else {
          this.showIncompletePos = true
        }
      }
    }
  },
  components: {
    "crud-select": CrudSelect,
    InvoiceConfig,
    MobileActivation,
    Locks,
    "image-selector": PointOfSaleImageSelector,
    QrCodeOperator,
    LocalTypeSelector,
    PosTypeSelector,
    BuildingManagerDropDown
  },
};
</script>