export default {
    titleLocks: 'Cerraduras registradas',
    addLock: 'Agregar cerradura',
    registrationTitleLocks: 'Registro de cerraduras',
    registrationDescriptionLocks: `Aquí puedes gestionar las cerraduras automáticas compatibles con TouchPay para micro-mercados, como cerraduras de refrigeradores y cerraduras de la puerta principal de la tienda.,
    A abertura das travas é feita pelo consumidor, que deve escanear o QR code da trava através do app TouchPay.`,
    installationLocks: `Al instalar el dispositivo de comunicación de la cerradura, esta debe ser emparejada mediante Bluetooth con el terminal de pago.
    Es importante guardar el nombre del dispositivo Bluetooth, ya que se utilizará para registrar la cerradura. La información del registro es:`,
    deviceNameLocks: '<strong>Nombre del dispositivo</strong>: debe ser exactamente igual al nombre del dispositivo Bluetooth de la cerradura, para que el terminal de pago pueda encontrarlo.',
    lockName: '<strong>Nombre de la cerradura</strong>: un nombre amigable para su identificación, por ejemplo: Refrigerador de bebidas, Puerta principal, etc.',
    ageRestrictionLocks: '<strong>Acceso restringido +18</strong>: si esta opción está marcada, la cerradura solo podrá ser abierta por usuarios de la aplicación que sean mayores de edad.',
    qrCodeGenerationLocks: `Después de registrar la cerradura, se generará el código QR que debe colocarse cerca de la cerradura para que los consumidores puedan abrirla.
    Si aún no tienes el dispositivo de la cerradura pero deseas obtener el código QR, puedes registrar la cerradura dejando el <em>nombre del dispositivo</em> vacío, y completarlo una vez que recibas el dispositivo.`,
    noLocksLocks: 'Ninguna cerradura registrada',
    closeLocks: 'Cerrar',
    newLockLocks: 'Nueva cerradura',
    successMessageLocks: 'Cerradura removida',
    errorMessageLocks: 'No se pudo remover la cerradura',
    editLocks: 'Editar',
}
