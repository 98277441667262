import { render, staticRenderFns } from "./PointOfSaleDialogHelp.vue?vue&type=template&id=dbb56a84&"
import script from "./PointOfSaleDialogHelp.vue?vue&type=script&lang=js&"
export * from "./PointOfSaleDialogHelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports