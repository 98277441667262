<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on" @click="$emit('click')">
        <v-icon left>mdi-plus</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "AddCustomButton",
  props: {
    text: { type: String, default: "" },
  },
};
</script>