import moment from 'moment'

export default {
  getPlanogramDescriptionFromIsoDates: function(planogram, includeId = false) {
    if (planogram.status === "Draft" || planogram.status === "Pending") {
      return `${includeId ? planogram.id + ' - ' : ''}${planogram.status}`;
    }

    const fromText = parseInt(moment(planogram.validFrom).format('YYYY')) <= 1 ? 'Início' : moment(planogram.validFrom).format('DD/MM/YYYY HH:mm');
    const toText = planogram.status === "Active" ? 'Em uso' : moment(planogram.validTo).format('DD/MM/YYYY HH:mm');
    return `${includeId ? planogram.id + ' - ' : ''}${fromText} - ${toText}`;
  }
}