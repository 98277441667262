export default {
    productSalesCardTitle: 'Consulta de Productos',
    productSalesInfoStateLabel: 'Estado',
    searchProductPlaceholder: 'Busca tu producto aquí',
    productRankingLabel: 'Clasificación del producto',
    maxMarketPriceLabel: 'Precio máximo del mercado',
    minMarketPriceLabel: 'Precio mínimo del mercado',
    averageMarketPriceLabel: 'Precio medio del mercado',
    productSalesNoResultsMessage: 'Sin resultados',
    noDescriptionMessage: 'Sin descripción para el código de barras',
    noImageAvailable: 'Imagen no disponible',
    getProductPerformanceError: 'Error al cargar ventas del producto: El producto no se ha vendido en el período filtrado.',
    getProductPerformanceProductSalesError: 'Error al cargar ventas del producto',
    getProductDescriptionError: 'Error al cargar nombre e imagen del producto: El producto no está registrado en el sistema.',
    insertBarcode: 'Inserta un código de barras:',
    productSalesLabel: 'ventas',
    standardPrice: 'Precio estándar en el sistema',
    nullBarCodeWarningMessage: `Para realizar la búsqueda, necesitamos el código de barras registrado del producto. Este
        producto no tiene un código de barras registrado en tu entorno. Agrega un código de barras al producto para poder buscarlo.`,
    clickHere: "Haz clic aquí",
    toEditTheProduct: "para editar el producto.",
};
