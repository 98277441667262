import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import {AlertNotification} from "@/types/AlertNotification";

export const useNewsStore = defineStore('newsStore', () => {

  const alertList = ref<AlertNotification[]>([]);
  const bannerList = ref<AlertNotification[]>([]);
  const alertVisibility = ref<Record<string, boolean>>({});
  const isCarouselVisible = ref(true);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('api/messages/news');
      alertList.value = response.data.filter(
        (n: { messageType: string }) => n.messageType !== 'Banner' && n.messageType !== 'Login'
      );
      bannerList.value = response.data.filter((n: { messageType: string }) => n.messageType === 'Banner');

      updateVisibility();
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const updateVisibility = () => {
    alertList.value.forEach((alert) => {
      const isVisible = localStorage.getItem(`alertClosed_${alert.id}`) !== 'true';
      alertVisibility.value = { ...alertVisibility.value, [alert.id]: isVisible };
    });

    const isCarouselClosed = localStorage.getItem('carouselClosed') === 'true';
    isCarouselVisible.value = !isCarouselClosed;
  };

  const checkNotifications = async () => {
    const lastChecked = localStorage.getItem('lastChecked');
    const now = new Date().toISOString();

    if (!lastChecked || new Date(now).getTime() - new Date(lastChecked).getTime() > 86400000) {
      localStorage.setItem('lastChecked', now);
      alertList.value.forEach((alert) => localStorage.removeItem(`alertClosed_${alert.id}`));
      localStorage.removeItem('carouselClosed');
      await fetchNotifications();
    } else {
      await fetchNotifications();
    }

    setTimeout(checkNotifications, 86400000);
  };

  const closeNotification = (id: string, type: 'alert' | 'banner') => {
    if (type === 'alert') {
      alertVisibility.value = { ...alertVisibility.value, [id]: false };
      localStorage.setItem(`alertClosed_${id}`, 'true');
    } else if (type === 'banner') {
      isCarouselVisible.value = false;
      localStorage.setItem('carouselClosed', 'true');
    }
  };

  const addAlertMessages = (messages: AlertNotification[]) => {
    alertList.value = [...messages, ...alertList.value];
    updateVisibility();
  };

  return {
    alertList,
    bannerList,
    alertVisibility,
    isCarouselVisible,
    checkNotifications,
    closeNotification,
    addAlertMessages
  };
});
