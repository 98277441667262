export default {
  taxings: {
    brazil: {
      reductionPercentageIcms: 'Reducción base de cálculo del ICMS',
      help: {
        description: `
            <p>
              El porcentaje de desoneración fiscal del ICMS se utiliza para calcular la exención parcial o total del impuesto sobre el valor de la mercancía o servicio.
              <strong>Esta desoneración del impuesto no es válida para regímenes del tipo simple nacional. Dependiendo del Código de Situación Tributaria (CST) aplicado, la forma en que se calcula la desoneración puede variar:</strong>
            </p>
            <ul>
              <li>
                <strong>CST 20 o 70</strong>: En estos casos, la desoneración se calcula en función del porcentaje de reducción aplicado sobre la tasa del ICMS. El cálculo utiliza la fórmula:
                <br><br>
                <code>
                Valor de la Desoneración = (Valor Total × (1 - (Tasa × (1 - Porcentaje de Reducción base)))) / (1 - Tasa) - Valor Total
                </code>
                <br><br>
                La tasa del ICMS se ajusta en función del porcentaje de reducción informado.
              </li><br>
              <li>
                <strong>CST 30 o 40</strong>: En estos casos, la desoneración se aplica íntegramente sobre el total del ICMS, y el valor desonerado se calcula directamente como una fracción del valor total, basado en la tasa del impuesto:
                <br><br>
                <code>
                Valor de la Desoneración = Valor Total × Tasa
                </code>
                <br><br>
              </li>
            </ul>
        `,
        alert: `El motivo de la desoneración del impuesto en la factura se asigna con el valor "9" que corresponde a "otros", esto ocurre automáticamente por el sistema al completar el campo del Porcentaje de la base de desoneración ICMS.`
      }
    }
  }
}
