import i18n from "@/locales/i18n";

const translations: { [key: string]: string } = {
  'Administrador': i18n.t('users.roles.admin').toString(),
  'Administrador App': i18n.t('users.roles.adminApp').toString(),
  'Administrador PrivateLabel': i18n.t('users.roles.adminPrivateLabel').toString(),
  'Cadastros': i18n.t('users.roles.registrations').toString(),
  'Contabilidade': i18n.t('users.roles.accounting').toString(),
  'Edição Preços': i18n.t('users.roles.priceEditing').toString(),
  'Gerenciador de pedidos': i18n.t('users.roles.orderManager').toString(),
  'Gerente': i18n.t('users.roles.manager').toString(),
  'Inventário às cegas e abastecimento': i18n.t('users.roles.blindInventoryAndSupply').toString(),
  'Logistica': i18n.t('users.roles.logistics').toString(),
  'Monitoramento Remoto': i18n.t('users.roles.remoteMonitoring').toString(),
  'Notas Fiscais': i18n.t('users.roles.invoices').toString(),
  'PushNotification': i18n.t('users.roles.pushNotification').toString(),
  'Segurança': i18n.t('users.roles.security').toString(),
  'Síndico': i18n.t('users.roles.buildingManager').toString(),
  'Suporte': i18n.t('users.roles.support').toString(),
  'Telemetria': i18n.t('users.roles.telemetry').toString(),
  'TouchPay Abastecedor': i18n.t('users.roles.touchPaySupplier').toString(),
  'Vendas': i18n.t('users.roles.sales').toString(),
  'Visualização Planogramas': i18n.t('users.roles.planogramView').toString(),
  'Visualização PrivateLabel': i18n.t('users.roles.privateLabelView').toString(),
  'Visualização Transações': i18n.t('users.roles.transactionView').toString(),
};

export function getRoleTranslation(key: string): string {
  return translations[key] || 'Unknown Role';
}