
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import {CashlessCustomer, CustomerRequestDto} from '@/types/Cashless';
import CustomerDialog from './components/CustomerDialog.vue';

export default Vue.extend({
  components: {
    CustomerDialog
  },
  mounted() {
    this.getCashlessUsers();
  },
  data() {
    return {
      headers: [
        {text: 'Consumidor', value: 'name', align: 'left', sortable: true},
        {text: 'Matrícula', value: 'externalIdentifier', align: 'left', sortable: true},
        {text: 'Cartão', value: 'card', align: 'left', sortable: true},
        {text: 'Saldo', value: 'credits', align: 'left', sortable: true},
        {text: 'Ações', value: 'actions', align: 'center', sortable: false},
        {text: 'Transações', value: 'transactions', align: 'left', sortable: false},
      ],
      items: [] as CashlessCustomer[],
      loading: false,
      search: "",
      customerDialog: {
        show: false,
        isEdit: false,
        editedCustomer: {} as CustomerRequestDto
      },
      editedCustomerId: null as number | null,
      deleteDialog: {
        show: false,
        loading: false
      },
      selectedCustomer: {
        id: null as number | null,
        name: "",
        credits: 0
      },
      addCreditDialog: {
        valid: false,
        show: false,
        loading: false,
        subtraction: false
      },
      creditToAdd: null as number | null,
    };
  },
  computed: {
    addCreditDialogText(): string {
      return this.addCreditDialog.subtraction ? "Subtrair" : "Adicionar";
    },
  },
  methods: {
    getCashlessUsers(): void {
      this.loading = true;
      this.axios.get('api/cashless/customers')
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((error) => {
            (this.$refs.feedback as any).handleError('Não foi possível carregar os consumidores', error);
            this.loading = false;
          });
    },
    goToCustomerTransactions(customer: CashlessCustomer): void {
      // to be implemented
      // to be implemented
      // to be implemented
      console.log("goToTransactions: " + customer);
    },
    openDialogCredit(customer: CashlessCustomer, subtraction : boolean): void {
      this.addCreditDialog.subtraction = subtraction;

      this.selectedCustomer = {
        id: customer.id,
        name: customer.name,
        credits: customer.credits
      };
      this.addCreditDialog.show = true;
    },
    closeAddCredit(): void {
      this.addCreditDialog.show = false;
      this.resetAddCreditsForm();
    },
    addCredit(): void {

      this.creditToAdd = this.addCreditDialog.subtraction ? -this.creditToAdd! : this.creditToAdd!;

      this.addCreditDialog.loading = true;
      this.axios.post(`api/cashless/customers/${this.selectedCustomer.id}/credits/${this.creditToAdd}`)
          .then(() => {
            this.addCreditDialog.show = false;
            (this.$refs.feedback as any).handleSuccess("Crédito adicionado com sucesso");
            this.items.find(c => c.id == this.selectedCustomer.id)!.credits += Number(this.creditToAdd);
          })
          .catch((error) => {
            (this.$refs.feedback as any).handleError("Não foi possível", error);
          })
          .finally(() => {
            this.addCreditDialog.loading = false;
            this.resetAddCreditsForm();
          });
    },
    resetAddCreditsForm(): void {
      this.creditToAdd = null;
      (this.$refs.creditForm as any).reset();
    },
    editCustomer(customer: CashlessCustomer): void {
      this.customerDialog.show = true;
      this.customerDialog.isEdit = true;
      this.customerDialog.editedCustomer = customer;
      this.editedCustomerId = customer.id;
    },
    openDeleteCustomerDialog(customer: CashlessCustomer): void {
      this.selectedCustomer = {
        id: customer.id,
        name: customer.name,
        credits: customer.credits
      };
      this.deleteDialog.show = true;
    },
    deleteCustomer() {
      this.deleteDialog.loading = true;
      this.axios.delete(`api/cashless/customers/${this.selectedCustomer.id}`)
          .then(() => {
            (this.$refs.feedback as any).handleSuccess("Consumidor deletado com sucesso.");
            this.deleteDialog.show = false;
            this.getCashlessUsers();
          })
          .catch((error) => {
            (this.$refs.feedback as any).handleError("Não foi possível", error);
          })
          .finally(() => {
            this.deleteDialog.loading = false;
          });
    },
    onCustomerDialogShowChanged(value: boolean): void {
      this.customerDialog.show = value;
      if (!value) {
        this.customerDialog.isEdit = false;
      }
    },
    onUpdatedCustomer(message: string): void {
      this.getCashlessUsers();
      this.customerDialog.show = false;
      this.customerDialog.isEdit = false;
      (this.$refs.feedback as any).handleSuccess(message);
    },
    tooltipText(item : any) {
      if (item.card === null) return "Consumidor sem cartão vinculado";
      if (item.card !== null) return "Ver transações do consumidor";
      return "";
    },
  }
});
