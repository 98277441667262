<template>
  <v-layout fill-height>
    <v-flex xs12>
      <news v-if="isBrazil"></news>
      <v-tabs v-model="active" background-color="grey lighten-4" grow>
        <v-tab :key="0">{{ $t('createPickLists') }}</v-tab>
        <v-tab :key="1">{{ $t('pendingPickLists') }}</v-tab>
        <v-tab v-if="$auth.check(SUPPLIER_HIERARCHY)" :key="2">{{ $t('separatedPickLists') }}</v-tab>
        <v-tab :key="3">{{ $t('suppliedPickLists') }}</v-tab>
        <v-tab-item :key="0">
          <pos-without-pick-list ref="posTable" @pick-list-creation="onPickListsCreated">
            <help>
              <template #title>
                {{ $t('pickListCreationPickLists') }}
              </template>
              <span v-html="$t('helpPickList')"></span>
              <p></p>
              <help-video  help-code="PickList"/>
            </help>
          </pos-without-pick-list>
        </v-tab-item>
        <v-tab-item :key="1" eager>
          <pick-list-table
              ref="pendingTable"
              allow-delete
              allow-edit
              show-current-data
              status-filter="Pending"
              @pick-list-deletion="onPickListsDeleted"
              @show-me="active = 1"
              :updated-text="$t('updatedActionPickListTable')"
          >
            {{ $t('pickListPendingPickLists') }}
            <help>
              <template #title>
                {{ $t('pickListPendingPickLists') }}
              </template>
              <span v-html="$t('pendingPickListsHelp')"></span>
            </help>
          </pick-list-table>
        </v-tab-item>
        <v-tab-item v-if="$auth.check(SUPPLIER_HIERARCHY)" :key="2">
          <pick-list-table status-filter="Separated" :updated-text="$t('separationDatePickLists')" @show-me="active = 2">
            {{ $t('pickListSeparatedPickLists') }}
            <help>
              <template #title>
                {{ $t('pickListSeparatedPickLists') }}
              </template>
              <span v-html="$t('pickListSeparatedPickListsHelp')"></span>
            </help>
          </pick-list-table>
        </v-tab-item>
        <v-tab-item :key="3" eager>
          <pick-list-table status-filter="Used" :updated-text="$t('supplyDatePickLists')" @show-me="active = 3">
            {{ $t('pickListSuppliedPickLists') }}
            <help>
              <template #title>
                {{ $t('pickListSuppliedPickLists') }}
              </template>
              <p>
                <span v-html="$t('pickListSuppliedPickListsHelp')"></span>
              </p>
            </help>
          </pick-list-table>
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
import PickListTable from '@/views/picklist/PickListTable';
import PosWithoutPickList from '@/views/picklist/PosWithoutPickList';
import HelpVideo from '@/components/HelpVideo.vue';
import {SUPPLIER_HIERARCHY} from "@/constants/Roles";
import News from '@/components/News.vue';

export default {
  data() {
    return {
      active: 0,
      SUPPLIER_HIERARCHY
    };
  },
  methods: {
    onPickListsCreated(creationResults) {
      this.active = 1;
      this.$refs.pendingTable.onPickListsCreated(creationResults);
    },
    onPickListsDeleted() {
      this.$refs.posTable.onPickListsDeleted();
    }
  },
  computed: {
    isBrazil() {
      return this.$auth.user().tenantCountry == 'BR';
  },
},
components: {
  'pick-list-table': PickListTable,
  'pos-without-pick-list': PosWithoutPickList,
  'help-video': HelpVideo,
  News
},
};
</script>
