export default {
  filters: {
    filtersActions: 'Filtros y Acciones',
    labels: {
      all: 'Todos',
      period: 'Período',
      pos: 'Punto de Venta',
      onlySubtractedItems: 'Solo artículos sustraídos',
      advanced: 'Avanzado',
      calendar: "Calendario",
      inventoryType: 'Tipo de Inventario',
      central: 'Central',
      search: 'Buscar',
      searchProducts: 'Buscar un producto',
      months: {
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Mayo",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre",
      },
      customer: 'Cliente',
      location: 'Ubicación',
      locationSpecific: 'Ubicación Específica',
      pointOfSale :'Punto de Venta',
      result: 'Resultado',
      minimumPrice: 'Precio Mínimo',
      maximumPrice: 'Precio Máximo',
      product: 'Producto',
      lastCardDigits: 'Últimos Dígitos de la Tarjeta',
      date: 'Fecha',
      initialDate: 'Fecha Inicial',
      finalDate: 'Fecha Final',
      initialHour: 'Hora Inicial',
      finalHour: 'Hora Final',
      verificationStatus: 'Estado de Verificación',
      viewOnlyTransactionItemsSubtracted: 'Ver solo transacciones con artículos sustraídos',
      viewProfit: 'Ver Ganancia',
      accessDetails: 'Acceder a Detalles',
      audit: 'Auditoría',
      actions: 'Acciones',
      accessResult: 'Acceder al Resultado',
      operationType: 'Tipo de Operación',
      lastMovement: 'Último movimiento',
    },
    messages: {
      selectMonth: "Seleccione un mes",
      selectCustomer: 'Seleccionar un cliente',
      selectLocation: 'Seleccionar una ubicación',
      selectResult: 'Seleccionar resultado para ver',
      selectOperationType: 'Seleccione un tipo de operación',
    },
    timelineDateAlert: 'Intervalo máximo de tres días',
    orderSearch: 'Ordenar / Buscar',
    status: 'Estado',
    customerName: 'Nombre del cliente',
    inventory: 'Inventario',
    lastSale: 'Última venta',
    creationDate: 'Fecha de creación (ID del punto de venta)',
    order: 'Ordenar',
    sort: {
      asc: 'Ascendente',
      desc: 'Descendente',
    },
  },
}