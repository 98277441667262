<template>
  <help>
    <template #title>{{ auditCategoryName }}</template>
    <div v-html="$t('audit.helpTable.description', { auditCategoryName })"></div>
  </help>
</template>

<script>
export default {
  props: {
    auditCategoryName: {type: String, default: null}
  }
};
</script>

<style scoped>

</style>