export default {
    planogramsByPos: 'Planograms by POS',
    searchPDV: 'Search POS',
    selectPDVs: 'Select POS without pending or draft planograms',
    planograms: 'Planograms',
    updateItems: 'Update items',
    finishDraft: 'Finish draft',
    finishDrafts: 'Finish drafts',
    confirmRemoveDrafts: 'Are you sure you want to remove draft planograms for the selected POS?',
    removeDrafts: 'Remove drafts',
    btnNo: 'No',
    btnRemove: 'Remove',
    activatePending: 'Activate pending',
    confirmActivate: 'Do you want to activate the pending planogram for the selected POS?',
    btnFinalize: 'Finalize',
    noResults: 'No results',
    headersPdvID: 'POS ID',
    headersCustomer: 'Customer',
    headersLocation: 'Location',
    headersSpecificLocation: 'Specific location',
    headersMachine: 'Machine',
    headersActivePog: 'Active POG',
    headersPendingPog: 'Pending POG',
    headersDraftPog: 'Draft POG',
    finalizePogSuccess: 'Planogram finalized for POS',
    finalizePogError: 'Unable to finalize planogram for POS',
    activatePogSuccess: 'Planogram activated for POS',
    activatePogError: 'Unable to activate planogram for POS',
    removePogSuccess: 'Planogram',
    removePogSuccessRemoved: 'removed',
    removePogError: 'Unable to remove planogram',
    createPogSuccess: 'Planogram created for POS',
    createPogError: 'Unable to create planogram for POS',
    addItemSuccess: 'Item ',
    addItemSuccessAdded: 'added',
    removeItemSuccess: 'removed ',
    updateItemSuccess: 'updated ',
    selectionPos: 'Do you want to finalize the draft planogram for the selected POS?',
    completionMessage: 'Once the planogram has been finalized, it will go into pending mode and you will no longer be able to edit it. The pending planogram will replace the active planogram when you Fulfill the POS or Activate the planogram.',
    finishPlanogramError: 'Select only POS with draft planogram',
    deletePlanogramError: 'Select only POS with draft planogram',
    activatePlanogramError: 'Select only POS with draft planogram',
    activatePendingPlanograms: 'Activate pending planograms',
    makePlanogramActive: 'Activation will make the pending planogram active. *Please note: in order to keep the stock count correct, we recommend that instead of activating, you use the option to Fill up when the point of sale is filled up. This way, as well as informing you of the supply, the pending planogram will be activated and will have the stock relating to the replenishment.',
    activatePlanogramsError: 'Error activating the planogram. Please try again.',
    onFinishedCreationMessage: 'The planograms were created as a draft. ',
    onFinishedCreationMessageAddItem: 'You can add more items, edit existing items or finalize the draft. ',
    onEntryUpdatedMessage: 'The operation was applied to the draft planogram. ',
    onEntryUpdatedMessageAddItem: 'You can continue adding items, edit existing items or finalize the draft.',
    handleAddOrUpdateResultText: ' no planogram ',
    rascunho: 'draft',
    ativo: 'active',
    PDV: 'POS',
};