
import Vue from 'vue';

export default Vue.extend({
  props: {
    dateIntervals: { type: Array, required: true },
    mandatory: { type: Boolean, default: true },
    value: { type: Number, required: true }
  },
  computed: {
    intervalSelected: {
      get(): number | null {
        return (this as any).value;
      },
      set(value): void {
        if (value == null || value == undefined) return;
        (this as any).$emit('input', value);
      }
    }
  }
})
