export default {
  planogram: {
    data: {
      version: "Planogram version",
      noData: "No planograms in the points of sale"
    },
    draft: {
      action: {
        create: "Create drafts",
        finish: "Finish drafts",
        activate: "Activate planograms",
        activatePending: "Activate pending planograms"
      },
      description: {
        finish: {
          confirm: "Do you want to finish the draft planogram for the selected points of sale?",
          alert: `
            <p>
              Please note that only the points of sale that were <strong>successful</strong>in the previous step will be included in the finalization.
            </p>
            <p>
              After the planogram is finalized, it will enter <strong>pending</strong> mode and can no longer be edited. The pending planogram will 
              replace the active planogram when <strong>Replenishing</strong> the point of sale or <strong>Activating</strong> the planogram.
            </p>
          `
        },
        activatePending: {
          confirm: "Do you want to activate the pending planogram for the selected points of sale?",
          alert: `
            <p>
              Activating will set the pending planogram as active. 
              <strong>Attention</strong>: to keep the stock count accurate, we recommend using the <strong>Replenish</strong> option at the time of 
              point of sale replenishment instead of activating. 
              This way, in addition to reporting the replenishment, the pending planogram will be activated and the stock will be updated accordingly.
            </p>
          `
        }
      },
      advanced: {
        option: "Advanced options",
        action: {
          add: "Add products from the price table missing in the planogram",
          remove: "Remove from the planogram products missing in the price table"
        }
      },
      response: {
        finish: {
          success: "Planogram finished for the POS {pointOfSaleId}",
          error: "Could not finish planogram for POS {pointOfSaleId}: {errorMessage}"
        }
      }
    },
    response: {
      apply: {
        success: "Price table applied to POS {pointOfSaleId}",
        error: "Could not apply price table to POS {pointOfSaleId}: {errorMessage}",
        reject: "Possible connection error, please try again"
      },
      activate: {
        success: "Planogram activated for POS {pointOfSaleId}",
        error: "Could not finish planogram for POS {pointOfSaleId}: {errorMessage}",
      }
    },
  },
}