export default {
    seeMoreCardTitle: 'Informações de Mercado',
    seeMoreButtonText: 'Saiba mais',
    policyCardTitle: 'Políticas de privacidade',
    policyCardOutdatedAlert: 'A versão do Termo de Consentimento que você concordou está desatualizada. Para acessar a Inteligência de Mercado, é necessário concordar com o novo Termo de Consentimento.',
    policyCardDownloadButton: 'Download',
    policyCardHideButton: 'Ocultar',
    policyCardAcceptButton: 'Aceito',
    posCardUpdateTitle: 'Atualizando seus PDVs',
    posCardUpdateInstructions: 'Para concluir a exibição de informações de mercado é necessário atualizar os campos',
    posCardUpdateInstructionsLocalType: 'Tipo de Local',
    posCardUpdateInstructionsNumberOfPeople: 'Quantidade de Pessoas',
    posCardUpdateInstructionsAndOr: 'e/ou',
    posCardUpdateInstructionsFollowingPos: 'dos seguintes PDVs: ',
    posCardAllUpdatedMessage: 'Todos os PDVs foram atualizados!',
    posCardBackButton: 'Voltar',
    posCardFinishButton: 'Pronto',
    posCardUpdateButtonText: 'Atualizar',
    errorLoadingTerms: 'Houve um erro ao carregar os termos de consentimento da Inteligência de Mercado. Tente recarregar a página.',
    getPrivacyPolicyError: 'Houve um erro ao carregar o Termo de Consentimento',
    getIncompletePosError: 'Não foi possível carregar os pontos de vendas pendentes',
    putUserAuthorizationError: 'Não foi possível registrar o aceite do Termo de Consentimento',
    putAllPosRegisteredError: 'Tente novamente mais tarde. Houve um erro na confirmação',
    updatePosData: 'Atualizar dados do PDV',
    termsOfAcceptingInformation: `Para acessar as informações, é necessário aceitar os termos de uso e preencher os dados solicitados. Clique em <strong style="color: #1976D2">ACESSAR TERMOS</strong>.`,
    accessThermosButton: 'ACESSAR TERMOS',
  };