export default {   
    dictDefaultMessage: "Drag an image file here, paste (Ctrl+V) or click to choose",
    dictFallbackMessage: "This browser is not supported for uploading images. Try a recent version of Chrome or Firefox",
    dictFileTooBig: "The file is too heavy, try one smaller than {{maxFilesize}}MB",
    dictInvalidFileType: "This photo format is not supported",
    dictResponseError: "The image could not be uploaded (error {{statusCode}})",
    dictCancelUpload: "Cancel upload",
    dictUploadCanceled: "The upload has been canceled",
    dictCancelUploadConfirmation: "Do you want to cancel the upload?",
    dictRemoveFile: "Remove",
    dictMaxFilesExceeded: "Maximum file limit reached",
    dictDefaultMessageXml: `Drag and drop your file here, or select a file. `,
    dictDefaultMessageXmlSelectFile: `SELECT FILE`,
    dictInvalidFileTypeXml: "This file format is not supported",
    dictResponseErrorXml: "Unable to upload file (error {{statusCode}})",
    dictUploadedFileXml: "File uploaded!",
    dictErrorXml: "Unknown error",
    dictDoneXml: "Unable to authorize file upload:",
  };