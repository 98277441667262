export default {
    usability: 'Usabilidad',
    easeOfUse: 'Facilidad de uso',
    confusingPage: 'Página confusa',
    performance: 'Rendimiento',
    speed: 'Velocidad',
    slowPage: 'Página lenta',
    usefulness: 'Utilidad',
    usefulnessPositive: 'Útil',
    usefulnessNegative: 'No útil',
    errors: 'Errores',
    errorsPositive: 'Sin errores',
    errorsNegative: 'Errores en la página',
    others: 'Otros',
    othersPositive: 'Otros',
    othersNegative: 'Otros',
};
