<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('dialogTitleRebootDialog') }}
        </v-card-title>
          <v-card-text class="pt-2">
            <span v-html="$t('forcedRestart')"></span>
            <span v-html="$t('scheduledRestart')"></span>
            <v-alert class="text-left mt-2" dense outlined text type="warning" border="left">
              {{ $t('warningRebootDialog') }}
            </v-alert>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="rebootTerminal(posId, true)"
            >
             {{ $t('forcedRestartButton') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="rebootTerminal(posId, false)"
            >
              {{ $t('scheduledRestartButton') }}
            </v-btn>  
          </v-card-actions>
      </v-card>
    </v-dialog>
    <feedback ref="feedback"></feedback>
  </div>
</template>

<script>
export default {
  props: {
    value : { type: Boolean, required: true},
    posId : { type: Number, default: null}
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
    watch: {
    dialog: function(enabled) {
      if (!enabled) {
        this.value = false
			}
    },
  },
  methods:{
    rebootTerminal(posId, forced){
    this.axios.post(`api/cloudMessages/Reboot?id=${posId}&forced=${forced}`).then(() => {
      this.dialog = false
      this.$refs.feedback.handleSuccess(this.$t('rebootTerminalSuccessMessage'))      
    }).catch((error) => {
      this.dialog = false
      this.$refs.feedback.handleError(this.$t('rebootTerminalErrorMessage'), error )
    })
    }
  }
}
</script>