export default {
  planogram: {
    data: {
      version: "Versión del planograma",
      noData: "Sin planogramas en los puntos de venta"
    },
    draft: {
      action: {
        create: "Crear borradores",
        finish: "Finalizar borradores",
        activate: "Activar planogramas",
        activatePending: "Activar planogramas pendientes"
      },
      description: {
        finish: {
          confirm: "¿Desea finalizar el planograma de borrador para los PDVs seleccionados?",
          alert: `
            <p>
              Se destaca que solo los PDVs que hayan <strong>tenido éxito</strong> en la etapa anterior estarán incluidos en la finalización.
            </p>
            <p>
              Después de la finalización del planograma, este entrará en modo <strong>pendiente</strong> y ya no será posible editarlo. 
              El planograma pendiente reemplazará al planograma activo al <strong>Abastecer</strong> el PDV o <strong>Activar</strong> el planograma.
            </p>
          `
        },
        activatePending: {
          confirm: "¿Desea activar el planograma pendiente para los PDVs seleccionados?",
          alert: `
            <p>
              La activación hará que el planograma pendiente se vuelva activo. 
              <strong>Atención</strong>: para mantener la contabilidad de stock correcta, recomendamos, en lugar de activar, usar la opción 
              de <strong>Abastecer</strong> en el momento del abastecimiento del punto de venta. 
              Así, además de reportar el abastecimiento, el planograma pendiente será activado y se actualizará el stock correspondiente.
            </p>
          `
        }
      },
      advanced: {
        option: "Opciones avanzadas",
        action: {
          add: "Añadir productos de la tabla de precios ausentes en el planograma",
          remove: "Eliminar del planograma productos ausentes en la tabla de precios"
        }
      },
      response: {
        finish: {
          success: "Planograma finalizado para el PDV {pointOfSaleId}",
          error: "No se pudo finalizar el planograma para el PDV {pointOfSaleId}: {errorMessage}"
        }
      }
    },
    response: {
      apply: {
        success: "Tabla de precios aplicada en el PDV {pointOfSaleId}",
        error: "No se pudo aplicar la tabla de precios en el PDV {pointOfSaleId}: {errorMessage}",
        reject: "Posible error de conexión, intente de nuevo"
      },
      activate: {
        success: "Planograma activado para el PDV {pointOfSaleId}",
        error: "No se pudo finalizar el planograma para el PDV {pointOfSaleId}: {errorMessage}",
      }
    },
  },
}