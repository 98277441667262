<template>
  <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <feedback ref="feedback"></feedback>

    <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="invalidate">
      <v-card>
        <v-card-title>
          <div class="title">{{ $t('invalidateTitleInvalidationRequest') }}</div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="form.companyId"
                  :items="companies"
                  item-text="cnpj"
                  item-value="id"
                  :loading="loading.companies"
                  :label="$t('cnpjLabelInvalidationRequest')"
                  :hint="$t('cnpjHintInvalidationRequest')"
                  persistent-hint
                  :no-data-text="$t('noCompaniesInvalidationRequest')"
                  required
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :label="$t('seriesLabelInvalidationRequest')"
                  v-model="form.series"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  :label="$t('initialNumberLabel')"
                  v-model="form.initialNumber"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="number"
                  :label="$t('finalNumberLabel')"
                  v-model="form.finalNumber"
                  :rules="[rules.integer]"
                  required
                ></v-text-field>
              </v-col>
                <v-col cols="12">
                <v-text-field
                  :label="$t('reasonLabelInvalidationRequest')"
                  v-model="form.reason"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">{{ $t('closeInvalidationRequest') }}</v-btn>
          <v-btn type="submit" :loading="loading.invalidating" :disabled="!valid" text color="primary">{{ $t('invalidateButton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      valid: true,
      companies: [],
      loading: {
        companies: true,
        invalidating: false
      },
      rules: {
        required: (v) => !!v || this.$t('requiredRules'),
        integer: (v) => /^\d+$/.test(v) || this.$t('integerRules'),
      }
    }
  },
  mounted() {
    this.axios.get('api/invoicesWeb/companies')
      .then((response) => {
        this.companies = response.data;
        this.loading.companies = false;
      });
  },
  methods: {
    invalidate() {
      if (this.$refs.form.validate()) {
        this.form.isProduction = true;
        if (this.form.reason != null && this.form.reason.trim() === '') {
          this.form.reason = null;
        }
        this.loading.invalidating = true;
        this.axios.post('api/invoiceInvalidations', this.form)
          .then(() => {
            this.$refs.feedback.handleInfo(this.$t('requestSentInvalidationRequest'));
          }).catch((error) => this.$refs.feedback.handleError(this.$t('requestFailedInvalidationRequest'), error))
          .then(() => this.loading.invalidating = false);
      }
    }
  }
}
</script>