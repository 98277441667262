export default {
    dialogTitlePushNotificationDialog: 'Send push notification',
    subtitlePushNotificationDialog: 'The message is sent to all users registered in this store',
    messageLabelPushNotificationDialog: 'Message',
    imageSubtitlePushNotificationDialog: '* For better visualization in the application, attach square images.',
    defaultNotificationLabel: 'Default notification',
    defaultNotificationHint: 'The notification will appear to the user every time they access the application',
    expirationLabelPushNotificationDialog: 'Expires on',
    clearLabelPushNotificationDialog: 'Clear',
    applyAllPosLabelPushNotificationDialog: 'Apply to all POS',
    applySelectedPosLabel: 'Only apply to POS',
    closeLabelPushNotificationDialog: 'Close',
    sendLabelPushNotificationDialog: 'Send',
    messageSentPushNotificationDialog: 'Message sent to ',
    usersOfPushNotificationDialog: ' users of ',
    activePushNotificationDialog: ' active',
    activePushNotificationDialogError: ' active. ',
    notificationsNotSent: ' Some notifications were not sent for the reason: ',
    feedbackErrorPushNotificationDialog: 'Error sending notifications',
    requiredMessageOrImage: 'You need to include a message or image',
    thereAreNoUsersWithPermission: 'There are no users with permission to receive notifications',
    getPointOfSaleItem: 'POS',
    nonWhiteLabelPushCharge: 'There is a charge of 10 cents for each customer to whom you send a push notification. ' +
      'This charge does not occur for white label apps.',
    noAppForSendingPush: 'This POS does not have TouchPay Mobile enabled. Enable it on the "POS Registration" page, or select another POS.',
  };
