export default {
    detailsInvoiceDialog: 'Detalhes da NFC-e',
    statusInvoiceDialog: 'Status: ',
    createdAtInvoiceDialog: 'Data criação: ',
    updatedAtInvoiceDialog: 'Data atualização: ',
    seriesInvoiceDialog: 'Série: ',
    numberInvoiceDialog: 'Número: ',
    downloadDanfeInvoiceDialog: 'Baixar DANFE',
    downloadXmlInvoiceDialog: 'Baixar XML',
    reasonInvoiceDialog: 'Motivo: ',
    retryInvoiceDialog: 'Reemitir',
    retryConfirmationInvoiceDialog: 'Reemissão de nota',
    confirmRetryInvoiceDialog: 'Tem certeza que deseja emitir a nota novamente?',
    keepSequentialInvoiceDialog: 'Manter mesmo número sequencial',
    keepSequentialRecommendation: 'Recomendamos manter o mesmo número sequencial sempre que possível.',
    cancelInvoiceDialog: 'Cancelar',
    closeInvoiceDialog: 'Fechar',
    enableNfceInvoiceDialog: 'Para habilitar a emissão da Nota Fiscal de Consumidor Eletrônica (NFC-e),\nentre em contato com a área comercial da AMLabs.',
    okInvoiceDialog: 'Ok',
    processingInvoiceDialog: 'Em processamento',
    approvedInvoiceDialog: 'Aprovada',
    deniedInvoiceDialog: 'Rejeitada',
    requestErrorInvoiceDialog: 'Não criada',
    unknownProblemInvoiceDialog: 'Problema desconhecido',
    successMessageInvoiceDialog: 'A nota foi aprovada',
    errorMessageInvoiceDialog: 'A tentativa de reemissão resultou no status ',
    retryErrorInvoiceDialog: 'Erro ao reemitir a nota'
  }
  