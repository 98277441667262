
import Vue from 'vue';
import TenantsFeedbacksFilter from './TenantsFeedbacksFilter.vue';
import { TenantFeedback }  from "@/api/agent";
import { shouldRefreshData, toApiPagination } from "@/components/TableOptionsComparator";
import { formatDateTimeMinutes } from "@/utility/TextFormatting"
import { feedbackCategories } from "@/types/TenantFeedback"
import moment from "moment";

const initialForm = {
  minDate: moment("20230101", "YYYYMMDD").format("YYYY-MM-DD"),
  maxDate: moment().format("YYYY-MM-DD"),
  webPage: "",
  grade: null as null | number,
  categories: [] as string[],
} as {}

export default Vue.extend({
  components: {
    "filters": TenantsFeedbacksFilter
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getTenantsFeedbacks(this.form);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      feedbacks: [] as any[],
      headers: [
        { text: 'Página', value: 'webPage', align: 'left', sortable: true, width:120 },
        { text: 'Nota', value: 'grade', align: 'left', sortable: true, width:120 },
        { text: 'Data', value: 'date', align: 'left', sortable: true, width:120 },
        { text: 'Comentário', value: 'comment', align: 'left', sortable: true },
        { text: 'Categorias', value: 'categories', align: 'left', sortable: true, width:200 },
        { text: 'Email', value: 'userEmail', align: 'left', sortable: false, width:200 },
        { text: 'Ambiente', value: 'environmentId', align: 'left', sortable: false, width:120 },
        { text: 'Telefone Usuário', value: 'contactNumber', align: 'left', sortable: false, width:120 },
      ],
      totalItems: 0,
      loading: false,
      search: {
        text: ""
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date'],
        sortDesc: [true],
        mustSort: true
      },
      form: { ...initialForm },
      feedbackCategories: feedbackCategories
    }
  },
  methods: {
    async getTenantsFeedbacks(form: {}) {
      this.loading = true;
      this.form = form;
      const pagination = toApiPagination(this.options);
      const params = { ...this.form, ...pagination };
      try {
        const response = await TenantFeedback.getTenantsFeedback(params)
        this.feedbacks = response.items
        this.totalItems = response.totalItems
      }
      catch(error) {
        alert(error)
      }
      finally {
        this.loading = false
      }
    },
    formatDateTimeMinutes,
    getCategoryName(category: string) {
      return this.feedbackCategories.find(c => c.value == category)?.neutralText;
    },
  }
})
