export default {
  inventory: {
    title: 'Inventario',
    help: {
      title: 'Información de Inventario',
      description: `<p>Mantén un control preciso de los artículos disponibles y los movimientos en tu inventario.
          Utiliza esta página para monitorear el inventario en diferentes ubicaciones, realizar ajustes y analizar patrones de movimiento.
        </p>
        <p><strong>Inventario: </strong>Explora detalles del inventario y movimientos con filtros personalizados.
          Puedes segmentar por Inventario de Tienda o Inventario Central. Realiza ajustes en la dirección del artículo,
          cantidad, límite crítico y cantidad mínima de compra. Además, puedes editar cantidades directamente en el sistema y descargar los datos en formato Excel.
        </p>
        <p><strong>Movimientos: </strong>Analiza operaciones por toque específico o en toda tu operación con filtros detallados.
          Comprende patrones de movimiento e identifica posibles errores logísticos para optimizar tu operación.
        </p>`
    },
    items: {
      title: 'Inventario',
      tableTitle: 'Artículos en el Inventario',
      tooltips: {
        onlyDistributionCenter: 'Seleccione solo un inventario central',
        notDeleteInventoryPDV: 'No es posible eliminar un producto del inventario del punto de venta'
      },
      messages: {
        error: {
          downloadFile: 'No fue posible descargar el archivo',
          consult: 'No fue posible consultar el inventario',
        },
        success: {
          minimumQuantityUpdated: 'Cantidad mínima de compra actualizada con éxito',
          addressUpdated: 'Dirección del inventario actualizada con éxito',
          minimumCriticalUpdated: 'Cantidad mínima crítica actualizada con éxito',
          quantityUpdated: 'Cantidad actualizada con éxito',
        }
      },
      dialogs: {
        removeProduct: {
          title: 'Eliminar productos del inventario central',
          all: '¿Desea eliminar todos los productos del inventario central que no están en un planograma activo?',
          specific: '¿Desea eliminar el producto {product} del inventario central?',
          attention: 'Atención',
          messages: {
            success: {
              productsRemoved: 'Los productos fueron eliminados del inventario central',
              noProductsToRemove: 'No hay productos para eliminar del inventario central',
            },
            error: {
              removeProducts: 'No fue posible eliminar los productos del inventario central',
            },
          }
        },
        itemAddress: {
          title: 'Dirección del Inventario',
          messages: {
            error: {
              addressUpdated: 'No fue posible editar la dirección del inventario',
            },
          },
          help: {
            description: `<p align="justify">
              La <strong>Dirección del Inventario</strong> es la ubicación del artículo en el inventario central.
              </p>
              <p align="justify">
                Esta dirección está estandarizada de acuerdo con el inventario, generalmente es una especie de código que indica el lugar donde se almacenó el artículo en el inventario central.
              </p>
              <p align="justify">
                Por ejemplo, algunas botellas de Coca-Cola se almacenan en la estantería con el código PRAT20.
                Entonces, la dirección del inventario de este artículo es PRAT20, lo que permite localizarlo en el inventario central.
              </p>`
          }
        },
        addProduct: {
          title: 'Agregar productos al inventario central',
          radioButtons: {
            addAllProducts: 'Agregar todos los productos del planograma al inventario central',
            addSpecificProducts: 'Agregar productos específicos al inventario central',
          },
          messages: {
            success: {
              productsAdded: 'Productos fueron añadidos al inventario central',
              noProductsToAdd: 'No hay productos para añadir al inventario central',
            },
            error: {
              addProducts: 'No fue posible añadir los productos al inventario central',
            },
          }
        },
        minimuCritical: {
          title: 'Mínimo Crítico',
          help: {
            description: `<p align="justify">
                El <strong>Mínimo Crítico</strong> es un indicador esencial para la gestión de inventarios, señalando cuándo es necesario reponer mercancías y evitando 
                agotamientos. Mantener atención a este valor es vital para optimizar la eficiencia operativa y garantizar la satisfacción del cliente. Nuestra funcionalidad es completamente 
                editable, proporcionando flexibilidad para ajustarla conforme a las necesidades operativas específicas. Tras la edición, el nuevo valor se guarda, permitiendo control 
                personalizado del inventario. Por ejemplo, si actualizas el valor de 5 a 10, el sistema considerará 10 como el nuevo "Mínimo Crítico". Esto mantiene el inventario en niveles 
                ideales y evita faltas de productos esenciales en la tienda.
              </p>`
          },
          label: 'Editar mínimo crítico',
          messages: {
            error: {
              minimumCriticalUpdated: 'Error al guardar',
            },
          }
        },
        purchaseQuantity: {
          title: 'Cantidad Mínima',
          help: {
            description: `<p align="justify">
              La <strong>Cantidad Mínima de Compra</strong> es el pedido mínimo de compra que se puede realizar para abastecer tu inventario central.
              </p>
              <p align="justify">
                Este valor se define en base a la <strong>Unidad de Compra</strong> (como caja, paquete, etc.) y cuántos productos individuales contiene esa unidad.
              </p>
              <p align="justify">
                Por ejemplo, un proveedor de refrescos solo vende a partir de un pedido mínimo de 10 cajas, con cada caja conteniendo 6 unidades.
                Entonces, la cantidad mínima de compra en este caso es de 60 unidades. Para ello, introducirías <strong>"10" en este formulario</strong>, para una <strong>Cantidad Mínima de Compra de 60 unidades.</strong>
              </p>`
          },
          minQuantity: '<strong>Cantidad Mínima de Compra:</strong> total de {minimumPurchaseQuantity} unidades',
          messages: {
            error: {
              minimumQuantityUpdated: 'No fue posible editar la Cantidad Mínima de Compra',
            },
          }
        }
      }
    },
    inventoryTransaction: {
      title: 'Movimientos Realizados',
      tab: 'Movimientos',
      messages: {
        error: {
          generateFile: 'Error al generar el archivo'
        }
      }
    },
    operationTypes: {
      correction: "Corrección",
      sale: "Venta",
      purchase: "Compra",
      reversed: "Reversión de Operación",
      automaticCorrection: "Corrección automática",
      supply: "Abastecimiento",
      expired: "Vencido",
      damaged: "Producto dañado",
      return: "Devolución",
      removedInventory: "Eliminación del Inventario",
      other: "Otros",
      expirationDateChanged: "Fecha de vencimiento cambiada",
      expirationDateAdded: "Fecha de vencimiento añadida",
      expirationDateRemoved: "Fecha de vencimiento eliminada",
    }
  }
}
