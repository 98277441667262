export default {
    posStatus: 'POS status',
    posStatusSubTitle:'View the status of your POS',
    totalPos: 'Total POS',
    onlineQuantity: 'Online Quantity',
    offlineQuantity: 'Offline Quantity',
    onlinePercent: 'Online(%)',
    offlinePercent: 'Offline(%)',
    noResults: 'No results',
    posStatusPercentage: 'Percentage',
    posStatusPercentageOnline: 'Online',
    posStatusPercentageOffline: 'Offline',
    posStatusPercentageAlert: 'Alert',    
    posStatusAmount: 'Amount',
    modeOnline: 'Online(%)',
    modeOffline: 'Offline(%)',
    quantityPos: 'POS in total',
    onlineColor: "Green",
    offlineColor: "Red",
    alertColor: "Yellow",
    onlineDescription: "Terminal is operational.",
    offlineDescription: "Terminal completely out of service due to reasons such as screen off, lack of internet/power connection, machine breakdown, or unknown reason but with persistent issue.",
    alertDescription: "Terminal with issues such as power outage, undergoing maintenance, loss of internet connection, or problem with card reader.",
    posStatusDescription: "In order to ensure an efficient payment experience, our system performs connectivity checks every five minutes. The statuses are designated by colors",
    posStatusRecommendation: "If your terminal remains in red state for an extended period, we recommend contacting our technical support for immediate assistance."
  };
  