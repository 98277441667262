export default {
    invalidateTitleInvalidationRequest: 'Invalidate numbering',
    cnpjLabelInvalidationRequest: 'Companyn\'s CNPJ',
    cnpjHintInvalidationRequest: 'Enter the companyn\'s CNPJ',
    noCompaniesInvalidationRequest: 'There are no companies registered',
    seriesLabelInvalidationRequest: 'Note series',
    initialNumberLabel: 'Initial number',
    finalNumberLabel: 'Final number',
    reasonLabelInvalidationRequest: 'Reason (optional)',
    closeInvalidationRequest: 'Close',
    invalidateButton: 'Invalidate',
    requestSentInvalidationRequest: 'Request sent to Sefaz. Follow the status via the Consult inutilizations menu.',
    requestFailedInvalidationRequest: 'It was not possible to request the invalidation'
  }