export default {
    creditPaymentMethods: 'Credit',
    debitPaymentMethods: 'Debit',
    voucherPaymentMethods: 'Voucher',
    remoteCreditPaymentMethods: 'Remote',
    cashPaymentMethods: 'Cash',
    appSalePaymentMethods: 'Sampling',
    picPayPaymentMethods: 'PicPay',
    freePaymentMethods: 'Free',
    externalAppPaymentMethods: 'External App',
    appCreditCardPaymentMethods: 'Credit from app',
    pixPaymentMethods: 'PIX',
    cashlessPaymentMethods: 'Cashless',
    othersPaymentMethods:'Others',
    qrcodePaymentMethods: 'QR Code',
  }
