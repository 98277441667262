<template>
  <v-dialog persistent v-model="show" :max-width="1000" :key="anyLock" @keydown.esc="show = false">
    <v-card>
			<v-card-title>
				{{ anyLock ? $t('titleLocks') : $t('addLock')  }}
        <help>
          <template #title>{{ $t('registrationTitleLocks') }}</template>
          <p>
            {{ $t('registrationDescriptionLocks') }}
          </p>
          <p>
            {{ $t('installationLocks') }}
            <ul>
              <li>
                <p v-html="$t('deviceNameLocks')"></p>
              </li>
              <li>
                <p v-html="$t('lockName')"></p>
              </li>
              <li>
                <p v-html="$t('ageRestrictionLocks')"></p>
              </li>
            </ul>
          </p>
          <p v-html="$t('qrCodeGenerationLocks')"></p>
        </help>
			</v-card-title>
			<v-card-text class="container">
			<v-layout row wrap v-if="anyLock">
				<v-flex sm6 md4 lg3 v-for="lock in lockList" :key="lock.uuid">
          <v-card class="mx-2">
            <v-card-title>
              <v-row class="title" align="center" justify="center">{{ lock.friendlyName }}
                <v-icon v-if="anyLock && lock.ageRestricted">liquor</v-icon>
              </v-row>
            </v-card-title>
            <v-card-text class="container" :id="`container-${lock.uuid}`">
              <qrcode-vue :value="lock.code" render-as="svg" :size="120" id="qrcode-svg" />
              <qrcode-vue v-show="false" :value="lock.code" :size="120" id="qrcode-canvas" />
              <v-container>
                <v-row align="center" justify="center">
                    <v-btn class="mr-2" color="secondary" @click="downloadPng(lock.uuid)">
                      <v-icon left>download</v-icon>PNG
                    </v-btn>
                    <v-btn class="ml-2" color="secondary" @click="downloadSvg(lock.uuid)">
                      <v-icon left>download</v-icon>SVG
                    </v-btn>
                </v-row>
              </v-container>
            </v-card-text>
            <v-row align="center" justify="center">
              {{ lock.deviceName }}
            </v-row>
            <v-card-actions>
              <v-btn text small color="primary" @click.native="onEditClick(lock)">
                <v-icon left>edit</v-icon>{{ $t('editLocks') }}
              </v-btn> <v-spacer />
              <v-btn text icon color="primary" class="ma-0" @click.native="deleteLock(lock.uuid)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
				</v-flex>
			</v-layout>
      <v-layout v-else>
        <div>{{ $t('noLocksLocks') }}</div>
      </v-layout>
			</v-card-text>
			<v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="show = false">{{ $t('closeLocks') }}</v-btn>
        <v-btn @click.native="lockDialog.show = true; universalLock=emptyLock()" text color="primary">{{ $t('newLockLocks') }}</v-btn>
			</v-card-actions>
    </v-card>
    <template #activator="{ on }">
      <slot name="activator" :on="on" :is-active="anyLock" :loading="loading.retrieving"></slot>
    </template>
  <feedback ref="feedback"></feedback>
  <lock-form v-model="lockDialog.show" @updated="update"
            :pos-id="posId" :lock="universalLock"></lock-form>
  </v-dialog>
</template>

<script>
import LockForm from '../components/LockForm.vue';
import QrcodeVue from 'qrcode.vue';
export default {
  props: {
    posId: { type: Number, required: true },
  },
  data() {
    return {
      anyLock: false,
      show: false,
      lockList: null,
      loading: {
        retrieving: false,
        saving: false
      },
      universalLock: {
        uuid: null,
        pointOfSaleId: this.posId,
        deviceName: null,
        friendlyName: null,
        ageRestricted: false,
      },
      lockDialog: {
        show: false,
      },
    }
  },
  mounted() {
    this.getLocks()
  },
  methods: {
    getLocks() {
      this.loading.retrieving = true;
      this.axios.get(`api/accessControl/getLock/${this.posId}`)
      .then(response => {
        this.lockList = response.data.locks;
        if (this.lockList[0]) {
          this.anyLock = true;
        }
        else{
          this.anyLock = false;
        }
        this.loading.retrieving = false;
      }).catch(error => {
        if (error?.response?.status === 404) {
          this.anyLock = false;
          this.loading.retrieving = false;
        }
        })
    },
    deleteLock(uuid)
    {
      this.loading.retrieving = true;
      this.axios.delete(`api/AccessControl/lock/${uuid}`)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('successMessageLocks'));
          this.update();
        }).catch(error => {
          this.$refs.feedback.handleError(this.$t('errorMessageLocks'), error)
        }).then(() => {
          this.loading.retrieving = false;
        });
    },
    emptyLock(){
      return {
        uuid: null,
        pointOfSaleId: this.posId,
        deviceName: null,
        friendlyName: null,
        ageRestricted: false,
      }
    },
    onEditClick(lock) {
      this.universalLock = Object.assign({}, lock);
      this.lockDialog.show = true;
    },
    update(){
      this.getLocks();
      this.$forceUpdate();
    },
    downloadPng(uuid) {
      const canvas = document.querySelector(`#container-${uuid} canvas`);
      const qrcode = canvas.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
      const link = document.createElement('a');

      link.href = qrcode;
      link.target = "_blank";
      link.download = "qrcode.png";
      link.click();

      URL.revokeObjectURL(link.href);
    },
    downloadSvg(uuid) {
      const svg = document.querySelector(`#container-${uuid} svg`);
      const qrcode = svg.outerHTML;
      const blob = new Blob([qrcode], {
        type: "image/svg+xml"
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.target = "_blank";
      link.download = "qrcode.svg";
      link.click();

      URL.revokeObjectURL(link.href);
    }
  },
    components: {
      'lock-form': LockForm,
      QrcodeVue
    }
  }
</script>

<style scoped>
  .container {
    text-align: center;
  }
</style>