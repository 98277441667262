
import Vue from 'vue';
import BrazilStates from "@/components/BrazilStates";

export default Vue.extend({
    data() {
        return {
            states: BrazilStates.states,
            selectedStates: [] as String[],
        }
    },
    props: {
        multipleSelector: { type: Boolean, default: false},
        value: { type: Array, required: true },
        soloInverted: { type: Boolean, default: true },
    },
    computed: {
    vModelValue: {
      get (): String | String[] | null {
        if (this.multipleSelector)  
        {
            return this.selectedStates;
        }   
        else {
            return this.selectedStates.length > 0 ? this.selectedStates[0] : null;
        }
      },
      set(){}
    },
  },
})
