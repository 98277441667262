<template>
  <div class="login-background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
    <v-row justify-center align="center" no-gutters>
      <v-col cols="12" md="5">
        <v-card ref="outervcard" height="100vh" class="cards flex-container" :style="{ overflowY: 'auto' }">
          <v-card-text>
          <v-form v-model="valid" @submit.prevent="onConfirm" class="cards flex-item">
              <v-row>
               <img id="login-logo" src="../../public/assets/logo-new.svg"/>
              </v-row>
              <v-row>
                <span id="product-name">{{ recoveryPassword? $t('recoverPassword') : $t('welcome') }}</span>
              </v-row>
              <v-row>
                <v-col xs12 class="py-0 mb-4">
                  <v-col class="px-0 green--text" v-if="emailSent">{{ emailSent }}</v-col>
                  <v-col class="px-0 red--text" v-if="hasFinancialIssue">{{ $t('hasFinancialIssues') }}</v-col>
                  <v-btn
                    class="py-5"
                    v-if="hasFinancialIssue"
                    color="warning"
                    :loading="loading.financialIssues"
                    @click="loginPendingBills.dialog = true"
                    block
                  >
                    {{ $t('pendingBills') }}
                  </v-btn>
                  <v-col class="px-0 red--text" v-if="error && !hasFinancialIssue">{{ error }}</v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col xs12>
                  <v-text-field
                    id="email-input"
                    autocomplete="username"
                    type="email"
                    name="username"
                    autofocus
                    :label="$t('emailLabel')"
                    v-model="email"
                    :rules="[v => !!v || this.$t('fillInEmail')]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row row wrap v-if="!recoveryPassword">
                <v-col xs12>
                  <v-text-field
                    id="password-input"
                    autocomplete="current-password"
                    name="password"
                    :label="$t('passwordLabel')"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[v => !!v || this.$t('fillInPassword')]"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>
                  <v-switch v-bind:label="$t(`keepConnected`)" v-model="keepLogged" light v-if="!recoveryPassword"></v-switch>
                </v-col>
              </v-row>
              <v-row cols="12" class="px-0" justify="center">
                <v-col cols="12">
                  <v-btn
                    :color="error===$t('paymentBlock') ? 'grey' : 'info'"
                    type="submit"
                    :loading="loading.login"
                    :disabled="error===$t('paymentBlock')"
                    block
                  >
                    {{ recoveryPassword ? $t('buttonContinue') : $t('buttonLogin') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" align="center">
                  <v-btn
                    v-if="!recoveryPassword"
                    @click="showRecoveryPassword()"
                    small
                    text
                    class="text-none"
                    style="color:#0087C8"
                  >
                    {{ $t('forgotPassword') }} <strong style="text-decoration-line: underline">{{ $t('clickHere') }}</strong>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="recoveryPassword" justify="center">
                <v-col cols="12" align="center">
                  <div>
                    <v-btn small text class="text-none" @click="showRecoveryPassword()"> {{ $t('returnToLogin') }}</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col v-if="error && error === $t('paymentBlock') && mayShowTrustPeriod" cols="12" align="center">
                  <p>{{ $t('trustPeriodText') }}</p>
                  <v-col cols="12">
                    <v-btn color="primary" @click="trustPeriodDialog = true" block>
                      {{ $t('trustPeriodButton') }}
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <login-pending-bills-dialog
            v-model="loginPendingBills.dialog"
            :bills="loginPendingBills.bills"
            :customer-document-id="loginPendingBills.customerDocumentId"/>
          <trust-period-dialog
            v-model="trustPeriodDialog"
            :email="email"
            @login="login"
            @error="(errorMessage) => this.error = errorMessage"/>
          </v-card>
          <v-card
            v-if="showPopup"
            class="popup-card pa-3"
            :style="{ height: calculatePopupHeight(), width: calculatePopupWidth() }"
            tile
          >
            <v-row justify="end" class="mb-2 mt-2 mr-2">
              <v-btn icon small @click="showPopup = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mr-4 ml-4 text-center">
              <v-col>
                <p class="popup-text">{{ $t('trustPeriodText') }}</p>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn
                  color="primary"
                  @click="trustPeriodDialog = true"
                  block
                >
                  {{ $t('trustPeriodButton') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import LoginPendingBillsDialog from "@/views/billing/myBills/LoginPendingBillsDialog.vue";
  import TrustPeriodDialog from "@/views/billing/myBills/dialogs/TrustPeriodDialog.vue";
  import { BUILDING_MANAGER_ROLE, ACCOUNTING_ROLE, OPERATIONAL_DASHBOARD_HIERARCHY, REMOTE_MONITORING_ROLE } from "@/constants/Roles"
  import i18n from "@/locales/i18n"

  export default {
    components: {
      LoginPendingBillsDialog,
      TrustPeriodDialog
    },
    data() {
      return {
        dialog: true,
        email: '',
        password: '',
        keepLogged: '',
        valid: false,
        error: null,
        emailSent: null,
        recoveryPassword: false,
        loading: {
          login: false,
          financialIssues: false
        },
        showPassword: false,
        backgroundImage: null,
        hasFinancialIssue: false,
        trustPeriodDialog: false,
        loginPendingBills: {
          dialog: false,
          bills: [],
          customerDocumentId: "",
        },
        showPopup: false,
      };
    },
    mounted() {
      this.getImage();
      this.$nextTick(() => {
        this.checkPopupVisibility();
      });
    },
    watch: {
    '$vuetify.breakpoint.width': function () {
      this.checkPopupVisibility();
    },
    },
    computed:{
      userCanAccessDashboard() {
        return OPERATIONAL_DASHBOARD_HIERARCHY.some(role => this.$auth.check(role)) && this.$auth.user().tenantCountry == 'BR'
      },
      userIsAccounting() {
        return this.$auth.check(ACCOUNTING_ROLE)
      },
      userIsBuildingManager() {
        return this.$auth.check(BUILDING_MANAGER_ROLE)
      },
      userIsRemoteMonitoring() {
      return this.$auth.check(REMOTE_MONITORING_ROLE);
      },
    },
    methods: {
      login() {
        this.loading.login = true;
        const loginInfo = {
          data: { email: this.email, password: this.password },
          rememberMe: this.keepLogged,
          success: function() {
            let redirection = '/';

            switch(true){
              case this.userCanAccessDashboard:
                redirection = '/';
                break;
              case this.userIsAccounting:
                redirection = i18n.t('path.nameInvoicesMaster');
                break;
              case this.userIsBuildingManager:
                redirection = i18n.t('path.nameCondominiumReports');
                break;
              case this.userIsRemoteMonitoring:
                redirection = i18n.t('path.nameTelemetry');
                break;
              default:
                redirection = '/';
            }

            this.$router.push(redirection)
          }
        }
        this.$auth.login(loginInfo)
          .catch(error => {
            if (error.message === "Network Error") {
              this.error = this.$t('systemUnavailable');
              this.loading.login = false;
            }
            else if (error.message === "Request failed with status code 401") {
              if (error.response.data.clientBlockReason === "Royalties") {
                this.error = this.$t('royaltyBlocking');
              }
              else if (error.response.data.clientBlockReason === "Payment") {
                this.error = this.$t('paymentBlock');
                this.mayShowTrustPeriod = error.response.data.mayShowTrustPeriod;
                if (this.mayShowTrustPeriod) {
                  requestAnimationFrame(() => {
                    this.checkPopupVisibility();
                  });
                }
              }
              else {
                this.error = this.$t('pendingContract');
              }
              this.loading.login = false;
            } else {
              this.error = this.$t('userInvalidPassword');
              this.loading.login = false;
            }
            if (error.response.data.bills && error.response.data.bills.length > 0) {
              this.hasFinancialIssue = true;
              this.loginPendingBills.bills = error.response.data.bills;
              this.loginPendingBills.customerDocumentId = error.response.data.customerDocumentId;
            }
          });
      },
      showRecoveryPassword() {
        this.recoveryPassword = !this.recoveryPassword
        this.error = null
        this.emailSent = null
      },
      onConfirm() {
        this.cleanLastLoginTry();
        if (this.recoveryPassword) this.sendEmail();
        else this.login();
      },
      sendEmail() {
        if (this.email != null) {
          this.loading.login = true
          this.axios.post(`Account/ForgotPassword?email=${this.email}`)
          .then((response) => {
            this.emailSent = this.$t('seeEmailBox')
            this.loading.login = false
            console.log(response)
          }).catch((error) => {
            this.error = this.$t('somethingWentWrong') + ' ' + error
            this.loading.login = false
            console.log(error)
          });
        }
      },
      getImage() {
        this.axios.get('api/messages/LoginImage')
        .then((response) => {
          this.backgroundImage = (response.data[0].content)
        })
      },
      cleanLastLoginTry() {
        this.hasFinancialIssue = false;
        this.loginPendingBills.bills = [];
        this.loginPendingBills.customerDocumentId = "";
        this.error = null;
      },
      checkPopupVisibility() {
        this.showPopup = this.mayShowTrustPeriod && this.isVCardScrollable();
      },
      isVCardScrollable() {
        return this.$refs.outervcard.$el.scrollHeight > this.$refs.outervcard.$el.clientHeight;
      },
      calculatePopupWidth() {
        return this.$vuetify.breakpoint.width < 960 ? '100%' : '41.6%';
      },
      calculatePopupHeight() {
        const height = this.$vuetify.breakpoint.height;
        switch (true) {
          case height <= 540:
            return '60%';
          case height <= 620:
            return '50%';
          case height <= 700:
            return '45%';
          case height <= 820:
            return '40%';
          default:
            return '35%';
        }
      },
    },
  };
</script>

<style scoped>
@media (min-width: 960px)
{
  .v-sheet.v-card {
    border-radius: 0px 30px 30px 0px;
  }
}

#product-name {
  color:#0087C8;
}

.flex-container {
  display: flex;
  justify-content: safe center;
  align-items: safe center;
  align-content: center;
}

.popup-card {
  position: fixed;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.4) !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  overflow-y: auto;
}

.popup-text {
  font-size: 14px;
}

</style>
