<template>
    <v-autocomplete
        prepend-icon="mdi-point-of-sale"
        :items="types"
        v-model="posType"
        :label="$t('posTypeSelector')"
        item-text="name"
        item-value="value"
        :rules="[rules.required]"
        required
    >
    <template #append-outer>
          <help>
            <template #title>{{ $t('posTypeSelector') }}</template>
            <p>
              {{ $t('enterTheSearchFilterPOS') }}
            </p>
          </help>
        </template>
    </v-autocomplete>
</template>

<script>
import PointOfSaleType from '@/types/enum/PointOfSaleTypes';

export default {

  props: ['value'],
  data() {
    return{
        types: PointOfSaleType.posType,
        rules: {
        required: (v) => !!v || this.$t('requiredRules'),
      },
    }
  },
  computed: {
    posType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  }
}
</script>