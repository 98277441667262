<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="960"
    @keydown.esc="dialog = false"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    
    <v-form
      ref="planogramForm"
      lazy-validation
      v-model="valid"
      @submit.prevent="handleCreateButtonClick"
    >
      <v-card>
        <v-card-title>
          <span class="title">{{ $t('createPlanogramTitle') }}</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="creationType">
            <v-radio :disabled="fromActiveDisabled" :label="fromActiveText" value="fromActive"></v-radio>
            <v-radio :label="$t('emptyPlanogram')" value="empty"></v-radio>
            <v-radio :label="$t('copyFromOtherPlanogram')" value="fromCopy"></v-radio>
            <v-radio :label="$t('copyFromPriceTable')" value="fromPriceTable"></v-radio> 
          </v-radio-group>
          <template v-if="creationType === 'fromCopy'">
            <pos-selector 
              v-model="searchPosId" 
              required
              :rules="[rules.required]"
              :filter="filterPosWithPlanogram"
            ></pos-selector>
            <v-autocomplete
              :items="listOfVersions"
              v-model="versionOfPlanogramId"
              :loading="loading.planograms"
              :label="this.$t('versionOfPlanogram')"
              item-text="searchVersion"
              item-value="id"
              :no-data-text="this.$t('noPlanograms')"
              required
              :rules="[rules.required]"
            ></v-autocomplete>
          </template>
            <template v-if="creationType === 'fromPriceTable'">      
              <v-autocomplete
              v-model="selectedPriceTable"
              required
              :label="this.$t('priceTableLabel')"
              :items="priceTables"
              item-text="displayNameId"
              item-value="id"
              :rules="[rules.required]"
              >  
            </v-autocomplete>
            <v-row cols="12" align="center" no-gutters>
              <v-col cols="7" class="mt-0">                  
                <v-checkbox
                  v-model="apply.addFromPriceTable"
                  :label="this.$t('addProductsFromPriceTable')"
                />
                <v-checkbox
                  v-model="apply.removeFromPlanogram"
                  :label="this.$t('removeProductsFromPlanogram')"
                  class="ma-0"
                />                    
              </v-col>
            </v-row>                
            </template>         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('btnClose') }}</v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="creationType == null || !valid"
            :loading="loading.newPlanogram"
          >{{ $t('btnCreate') }}</v-btn>
        </v-card-actions>
        <v-dialog
          v-model="showWarningDialog"
          width="960"
          >
        <v-card>
          <v-card-title>{{ $t('attentionEmptyPlanogramCreation') }}</v-card-title>
          <v-card-text>
            <span v-html="$t('warningEmptyPlanogramCreation')"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="showWarningDialog = false"
              color="primary"
              text              
                >{{ $t('cancelButton') }}
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              @click="createPlanogram"
              >{{ $t('btnCreate') }}
            </v-btn>            
          </v-card-actions>
        </v-card>
        </v-dialog>
      </v-card>
    </v-form>
    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import PlanogramDates from '@/planogram/PlanogramDates'
import PointOfSaleSelector from '@/components/PointOfSaleSelector'

export default {
  props: {
    posId: { type: Number, default: null },
    posIds: { type: Array, default: null },
    copyFromActiveDisabled: { type: Boolean, default: false }
  },
  data(){
    return {
      on: null,
      creationType: null,
      searchPosId: null,
      dialog: false,
      valid: true,
      versionOfPlanogramId: null,
      activePlanogram: null,
      listOfVersions: [],
      progress: 0,
      loading: {
        pointsOfSale: true,
        planograms: false,
        newPlanogram: false
      },
      rules: {
				required: (v) => !!v || this.$t('requiredField')
			},
      priceTables: [],
      selectedPriceTable: null,
      apply: {
        addFromPriceTable: false,
        removeFromPlanogram: false
      },
      showWarningDialog: false
    }
  },
  computed: {
    fromActiveText() {
      return this.$t('copyFromActive') + (this.activePlanogram != null ? ` (${this.$t('planogramReturn')} ${this.activePlanogram.id})` : '');
    },
    fromActiveDisabled() {
      return this.copyFromActiveDisabled || (this.posIds != null && this.activePlanogram != null);
    },
  },
  watch: {
    dialog: function(dialog){
      if (dialog) {
        if (this.activePlanogram != null || (this.posIds != null && !this.copyFromActiveDisabled)) {
          this.$nextTick(() => this.creationType = 'fromActive');
        } else {
          this.$nextTick(() => this.creationType = 'empty');
        }
				this.valid = true;
			}
    },
    searchPosId: function(searchId){
      this.listOfVersions = [];
      if(searchId != null) {
        this.loading.planograms = true;
        this.getVersions();
      }
    }
  },
  mounted() {
    if (this.posId == null) return;
    this.axios.get("api/Planograms?posId=" + this.posId)
      .then((response) => {
        const active = response.data.find((planogram) => planogram.status === 'Active');
        if (active != null) this.activePlanogram = active;
        if (this.creationType == null) this.creationType = 'fromActive';
      });
  },
  created() {
    this.PriceTables();
  },
  methods: {
    getVersions() {
			this.axios.get("api/Planograms?posId=" + this.searchPosId)
				.then((response) => {
					this.loading.planograms = false;
					this.listOfVersions = [];
					if (response.data.length > 0) {
						this.listOfVersions = response.data.map(x => {
              x.searchVersion = PlanogramDates.getPlanogramDescriptionFromIsoDates(x, true);
              return x;
						});
					}
				});  
    },   
    handleCreateButtonClick() {
      if (this.creationType === 'empty') {
        this.showWarningDialog = true;
      } else {
        this.createPlanogram();
      }},

    async createPlanogram() {
			if (this.$refs.planogramForm.validate()) {
        this.loading.newPlanogram = true;

        this.apply.addFromPriceTable = this.apply.addFromPriceTable == null ? false: this.apply.addFromPriceTable
        this.apply.removeFromPlanogram = this.apply.removeFromPlanogram == null ? false : this.apply.removeFromPlanogram

        if (this.creationType === 'fromPriceTable') {
          if (!this.selectedPriceTable) {
            this.$emit('error', this.$t('selectPriceTable'));
            this.loading.newPlanogram = false;
            return;
          }
          const requests = this.createPriceTablesRequest(this.posIds ?? [this.posId], this.apply.addFromPriceTable, this.apply.removeFromPlanogram);          
          const elementPercentage = 100 / requests.length;

          this.$emit('updateProgressDialogEnable', true);
          this.progress = 0;

          const promiseRequests = requests
            .map(async request => {
              return this.axios.post("api/PriceTables/bulk/apply/" + this.selectedPriceTable, null, { params: request })
              .catch(() => {
                return {
                  data: {
                    results: request.posIds.map(posId => {
                      return {
                        entityId: posId,
                        success: false,
                        error: `${this.$t('createPlanogramError')} ${this.selectedPriceTable} ${this.$t('createPlanogramPos')} ${posId}. ${this.$t('createPlanogramTryAgain')}`
                      }
                    })
                  }
                }
              })
              .finally(() => {
                this.progress += elementPercentage
                this.$emit('progress', this.progress);
              })
            });

          await Promise.allSettled(promiseRequests)
            .then(response => {
              const bulkResult = { results: null }
              bulkResult.results = response.map(x => x.value.data).flatMap(it => it.results);
              if (this.posId != null && bulkResult.results.some(x => x.success === false)) {
                this.$emit('error', this.$t('errorCopyingPriceTable') + bulkResult.results[0].error);
              }
              else {
                this.$emit('success', bulkResult);
              }
              this.dialog = false;
            }).finally(() => {
              this.loading.newPlanogram = false
              this.$emit('progress', 100)
            });       
        }       
        else if (this.posId != null) { // Single creation
          const request = this.createRequest(this.posId);
          this.axios.post('api/Planograms', request)
          .then(response => {
            this.$emit('success', response.data);
						this.dialog = false;
          }).catch(error => this.$emit('error', this.$t('errorCreatingPlanogram'), error))
          .then(() => (this.loading.newPlanogram = false));
          
        } else if (this.posIds != null) { // Bulk creation
          const numberOfElements = 4;
          const requests = this.posIds.map(id => this.createRequest(id));
          const chunckedRequests = this.partition(requests, numberOfElements);
          const elementPercentage = 100 / chunckedRequests.length;
          
          this.$emit('updateProgressDialogEnable', true);
          this.progress = 0;

           const promiseRequests = [...Array(chunckedRequests.length).keys()]
            .map(x => 
              this.axios.post("api/Planograms/bulk", chunckedRequests[x])
                .catch(() => {
                  return {
                    data: {
                      results: chunckedRequests[x].map(request => {
                        return {
                          entityId: request.destinationPosId,
                          success: false,
                          error: `${this.$t('errorCreatingPosPlanograms')} ${request.destinationPosId}. ${this.$t('createPlanogramTryAgain')}`
                        }
                      })
                    }
                  }
                })
                .finally(() => {
                  this.progress += elementPercentage
                  this.$emit('progress', this.progress);
                })
              );

          await Promise.allSettled(promiseRequests)
            .then(response => {
              const bulkResult = { results: null }
              bulkResult.results = response.map(x => x.value.data).flatMap(it => it.results);
              this.$emit('success', bulkResult);
              this.dialog = false;
            }).finally(() => {
              this.loading.newPlanogram = false
              this.$emit('progress', 100)
            });
        } else {
          throw "Null POS ID or list of POS IDs";
        }
			}
    },
    partition(array, n) {
      return array.length ? [array.splice(0, n)].concat(this.partition(array, n)) : [];
    },
    createRequest(posId) {
      const request = { destinationPosId: posId };
      if (this.creationType == 'empty') {
        request.creationType = 'Empty';
      }
      else if (this.creationType == 'fromActive') {
        request.creationType = 'FromActive';
      } 
      else if (this.creationType == 'fromCopy') {
        request.creationType = 'FromCopy';
        request.sourcePlanogramId = this.versionOfPlanogramId;
      }
      else if (this.creationType == 'fromPriceTable') {
        request.creationType = 'FromPriceTable';
      }
      return request;
    },
    filterPosWithPlanogram(pos) {
      return pos.currentPlanogramId != null;
    },
    async PriceTables() {
      try {
        const response = await this.axios.get(`/api/PriceTables/list`);
        this.priceTables = response.data.map(item => ({
          id: item.id,
          displayNameId: `${item.id} - ${item.name}`
        }));
      } catch (error) {
        this.$refs.feedback.handleError(this.$t('errorLoadingPriceTables'), error);
      }
    },
    createPriceTablesRequest(posIds, addFromPriceTable, removeFromPlanogram) {
      const numberOfElements = 4;
      const requests = [];      
      for (let i = 0; i < posIds.length; i += numberOfElements) {
        const chunkIds = posIds.slice(i, i + numberOfElements);
        const request = {
          posIds: chunkIds,
          addFromPriceTable: addFromPriceTable,
          removeFromPlanogram: removeFromPlanogram,
        };
        requests.push(request);
      }
      return requests
    } 
  }, 
  components: {
    'pos-selector': PointOfSaleSelector
  }
}
</script>

