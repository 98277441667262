export default {
    myInfo: 'Meus Dados',
    phone: 'Telefone',
    dateOfBirth: 'Data de Nascimento',
    dateFieldPlaceHolder: 'dd/mm/aaaa',
    updateInfoDialogText: 'Tem certeza de que deseja atualizar suas informações?',
    infoUpdatedMessage: 'Informações atualizadas com sucesso',
    editButton: 'Editar',
    onlyNumbers: 'Somente números',
    registrationData: 'Dados Cadastrais',
    registrationDataSubtitle: 'Mantenha sempre seus dados atualizados para garantir a autenticidade e facilitar a comunicação com sua conta!',
}