export default {
    statusCanceled: 'Cancelada',
    statusActive: 'Ativa',
    purchasesHeaderIdOperation: 'ID Operação',
    purchasesHeaderStatus: 'Status',
    purchasesHeaderIdErp: 'ID no ERP',
    purchasesHeaderNoteNumber: 'Número da nota',
    purchasesHeaderInventoryId: 'ID Estoque',
    purchasesHeaderStock: 'Estoque',
    purchasesHeaderQuantityItems: 'Quantidade de itens',
    purchasesHeaderNoteValue: 'Valor da Nota',
    purchasesHeaderTotalCostPurchase: 'Custo total da compra',
    purchasesHeaderReceivedDate: 'Data de recebimento',
    purchasesError: 'Erro ao pesquisar compras'
  };
  
  