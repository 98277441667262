export default {
    lossesProductAndPosTitleProduct: 'Perdas por produtos', 
    lossesProductAndPosSubTitleProduct:'Identifique os itens com maiores perdas em sua loja',
    lossesProductAndPosTitlePos: 'Perdas por PDVs',  
    lossesProductAndPosSubTitlePos:'Identifique os PDVs com maiores perdas',
    lossesProductAndPosLabel: 'Motivo',
    lossesProductAndPosyColumnProduct: 'Produtos',
    lossesProductAndPosColumnLossesValue: 'Valor perdido',
    lossesProductAndPosColumnAmount: 'Quantidade',
    lossesProductAndPosLabelToViewPos: 'Visualizar PDVs',
    lossesProductAndPosLabelToViewProduct: 'Visualizar produtos',
    reasonsForLossesExpired: 'Validade vencida',
    reasonsForLossesDamaged: 'Produto danificado',
    reasonsForLossesOther: 'Outro',
    reasonsForLossesDetour: 'Desvio',
    lossesProductAndPosyColumnPos: 'PDVs',
}