export default {
  purchase: {
    title: 'Compras',
    entry: {
      product: {
        index: 'Producto {index} de {total}',
        value: 'Valor Unitario del producto',
        requests: {
          error: {
            selectGrouping: 'No es posible seleccionar un agrupamiento',
            conversionUnit: 'Error al buscar unidades de conversión'
          }
        },
        quantity: {
          total: 'Cantidad Total del producto',
          item: 'Cantidad del ítem',
        },
        help: {
          title: 'Información de los productos',
          description: `En esta página, los datos extraídos de envíos vía XML están disponibles para asociación a productos específicos, 
          permitiendo la selección de la unidad de conversión para actualizar el inventario.`,
          product: {
            title: 'Productos',
            description: `Si el código de barras del producto en la nota es igual al código de un producto en el sistema, el mismo ya vendrá preseleccionado.`
          },
          conversionUnit: {
            title: 'Unidad de conversión / Unidades',
            description: `Si la unidad está clasificada como desconocida, esto indica que nuestro sistema no pudo interpretar la unidad mencionada en el XML. 
            Para corregir, basta con seleccionar el precio por unidad (como unidades, cajas o paquetes) e indicar cuántas unidades componen esa medida de conversión 
            (por ejemplo, unidad = 1, caja = 10, paquete = 6).
            Nota: la cantidad informada no acepta números decimales.`
          },
          quantity: {
            title: 'Cantidad Total del producto',
            description: `Después de ingresar los datos de cantidad y costo, nuestro sistema realizará automáticamente el cálculo del total de unidades y el precio unitario correspondiente. 
            Por ejemplo, al ingresar dos cajas, cada una con 10 unidades, al costo de 10 reales por caja: Total de unidades: 20 | Precio unitario: R$ 1,00. 
            Esta funcionalidad simplifica la visualización del total de productos y su costo por unidad, facilitando el análisis de la información.`
          },
        },
        xml: {
          stepTitle: 'Agregar Productos',
          title: 'INFORMACIÓN DE LOS PRODUCTOS',
          invalid: 'Para continuar complete: valor unitario, producto, unidad de conversión, cantidad.',
        },
        manual: {

        }
      },
    }
  }
}
