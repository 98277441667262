
import Vue from 'vue';


export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
    progress: {type: Number, required: true},
    firstOptionText: {type: String, required: false},
    firstOptionEnabled: {type: Boolean, required: false},
    secondOptionText: {type: String, required: false},
    secondOptionEnabled: {type: Boolean, required: false},
    infoMessages: {type: Array}
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    getColor: function (progress: number) {
      if (progress > 75) {
        return 'green';
      } else if (progress > 25) {
        return 'orange';
      } else if (progress > 0) {
        return 'red';
      }
      return 'red';
    }
  }
});
