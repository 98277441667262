export default {
    consultationTitleInvalidationsDialog: 'Consulta de inutilizações',
    noItemsInvalidationsDialog: 'Sem itens cadastrados',    
    cnpjInvalidationsDialog: 'CNPJ',
    seriesInvalidationsDialog: 'Série',
    initialNumberInvalidationsDialog: 'Número inicial',
    finalNumberInvalidationsDialog: 'Número final',
    statusInvalidationsDialog: 'Status',
    reasonInvalidationsDialog: 'Motivo',
    justificationInvalidationsDialog: 'Justificativa',
    sefazProtocolInvalidationsDialog: 'Protocolo Sefaz',
    createdDateInvalidationsDialog: 'Criada em',
    updatedDateInvalidationsDialog: 'Atualizada em',    
    processingStatusInvalidationsDialog: 'Em processamento',
    approvedStatusInvalidationsDialog: 'Aprovada',
    deniedStatusInvalidationsDialog: 'Rejeitada',
    requestErrorStatusInvalidationsDialog: 'Não criada',
    unknownProblemInvalidationsDialog: 'Problema desconhecido'
  }
  