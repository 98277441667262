
import Vue from 'vue';
import agent from "@/api/agent";
import moment from "moment"
import TopSellingProductsHelp from '@/views/dashboard-new/helpers-new/TopSellingProductsHelpNew.vue'
import { ProductByRegion } from '@/types/DashboardMarketIntelligence';
import BrazilStates from "@/components/BrazilStates";

export default Vue.extend({
  props: {
    mustCallEndpoints: {
      type: Boolean, default: false
    },
    form: {
      type: Object, required: true
    }
  },
  components: {
    TopSellingProductsHelp,
  },
  data() {
    return {
      productList: [] as ProductByRegion[],
      params: {
        state: [] as string[],
        maxDate: moment().format('YYYY-MM-DD'),
        minDate: moment().add(-30, 'days').format('YYYY-MM-DD')
      },
      states: BrazilStates.states,
      headers: [
        { text: this.$t('topFiveProducts'), align: 'left', sortable: false, value: 'productDescription' },
        { text: this.$t('topProductsSales'), align: 'left', sortable: false, value: 'quantity' },
      ],
      loading: {
        table: false,
      },      
    };
  },
  mounted() {
    this.getProductsByRegion();
  },
  watch: {
    mustCallEndpoints: {
      handler(value) {
        if (value) {
          this.getProductsByRegion();
        }
      },
    }
  },
  methods: {
    async getProductsByRegion() {
      try {
        this.loading.table = true
        this.params = { minDate:  this.form.minDate, maxDate: this.form.maxDate, state: this.form.state }

        const response = await agent.DashboradMarketIntelligence.getProductsByRegion(this.params)

        this.productList = response;
      } 
        finally {        
          this.loading.table = false;
      }  
    }
  },
  });
