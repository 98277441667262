export default {
    appRegistration: 'Cadastro do aplicativo',
    registerApp: 'Cadastrar aplicativo',
    additionalChargeInfo: 'A habilitação do app implica em cobrança adicional, de acordo com a modalidade contratada através da área comercial da AMLabs.',
    storeBrand: 'Marca da Loja',
    enterBrand: 'Insira a marca para ativar aplicativo para este PDV',
    locationSubtitleMobileActivation: 'Local/Subtítulo',
    enterLocation: 'Insira o local para ativar aplicativo para este PDV',
    sacWhatsAppOptional: 'WhatsApp do SAC (opcional)',
    sacWhatsAppNumber: 'Número de WhatsApp do SAC da loja para criar um atalho no app',
    displayProductPricesMobileActivation: 'Exibir preços dos produtos',
    verifyCpf: 'Verificar CPF com Receita Federal',
    verifyAge: 'Verificar idade com Receita Federal',
    verifyValidEmail: 'Verificar e-mail válido',
    verifyPhoneNumber: 'Verificar número de telefone',
    phoneNumberOption: 'Opção aplicável apenas para white labels. ',
    confirmPhoneMobileActivation: 'Exige que o usuário confirme o número de telefone através de um código enviado por SMS para poder abrir as travas da loja.',
    addToCartOption: 'Adição de produtos no carrinho de compras',
    paymentTokenMobileActivation: 'Token de pagamento',
    disableMobileActivation: 'Desabilitar',
    closeMobileActivation: 'Fechar',
    updateMobileActivation: 'Atualizar',
    registerMobileActivation: 'Cadastrar',
    registrationUpdated: 'Cadastro do aplicativo atualizado!',
    unableToUpdateRegistration: 'Não foi possível atualizar o cadastro do aplicativo.',
    appReleased: 'Aplicativo liberado!',
    unableToActivateApp: 'Não foi possível ativar o aplicativo.',
    appDisabled: 'App desabilitado neste PDV.',
    unableToDisableApp: 'Não foi possível desabilitar o App nesse PDV',
    appCheckoutTypeDisabled: 'Desabilitado',
    appCheckoutTypeBarcode: 'Apenas por código de barras',
    appCheckoutTypeBarcodeAndManual: 'Código de barras ou seleção manual',
    userDocumentVerificationText: `A habilitação da verificação de CPF pela Receita Federal acarreta em cobrança adicional,
    de acordo com a modalidade contratada através da área comercial da AMLabs.`,
    userVerificationText: `A habilitação da verificação da idade dos usuários pela Receita Federal acarreta em cobrança adicional,
    de acordo com a modalidade contratada através da área comercial da AMLabs.`,
    errorFindingToken: 'Não foi possível encontrar um token para o PDV.',
  };
  