export default {
    filterFilesDownloads: 'Filtrar arquivos',
    generatingFileDownloads: 'Gerando arquivo',
    fileGenerationFailure: 'Falha na geração do arquivo',
    downloadFileError: 'Não foi possível baixar o arquivo',
    deleteFileError: 'Não foi possível excluir o arquivo',
    dateDownloads: 'Data',
    fileDownloads: 'Arquivo',
    downloadSize: 'Tamanho',
    download: 'Baixar',
    excelFilesDownloads: 'Arquivos Excel',
    compressedFilesDownloads: 'Arquivos Compactados',
    downloadAllDownloads: 'Baixar todos',
    downloadSelected: 'Baixar selecionados',
    deleteAllDownloads: 'Excluir todos',
    deleteSelectedDownloads: 'Excluir selecionados',
    confirmDeleteDownloads: 'Tem certeza que deseja remover ',
    filesQuestionDownloads: ' arquivo(s)?',
    allFilesQuestionDownloads: 'todos os arquivos?',
  };
  