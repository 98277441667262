<template>
  <v-text-field
    :label="label"
    :dense="dense"
    :required="required"
    :prepend-icon="prependIcon"
    v-model="formattedPhone"
    @input="onInput"
    :error-messages="errorMessage"
    clearable
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedPhone: this.formatPhone(this.value),
      errorMessage: '',
    };
  },
  watch: {

    value(newVal) {
      this.formattedPhone = this.formatPhone(newVal);
    },
  },
  methods: {

    onInput() {
      let phone = this.formattedPhone.replace(/\D/g, '');
      if (!phone.startsWith('55')) {
        phone = '55' + phone;
      }
      if (phone.length > 13) {
        phone = phone.slice(0, 13);
      }

      this.formattedPhone = this.formatPhone(phone);

      if (!this.isValidPhone(phone)) {
        this.errorMessage = 'Telefone Invalido';
      } else {
        this.errorMessage = '';
      }

      this.$emit('input', this.parsePhone(phone));
    },


    formatPhone(phone) {
      if (!phone) return '+55 ';
      const cleaned = phone.replace(/\D/g, '');
      let match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      } else if ((match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/))) {

        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }
      return cleaned.startsWith('55') ? `+${cleaned}` : `+55 ${cleaned}`;
    },


    parsePhone(phone) {
      const cleaned = phone.replace(/\D/g, '');
      return cleaned.startsWith('55') ? cleaned : `55${cleaned}`;
    },

    // Valida o número de telefone
    isValidPhone(phone) {
      const cleaned = phone.replace(/\D/g, '');
      return cleaned.length === 13 || cleaned.length === 12;
    },
  },
};
</script>

<style scoped>

.v-text-field {
  max-width: 300px;
}
</style>
