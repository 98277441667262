export default {
    createPickLists: 'Criar',
    pendingPickLists: 'Pendentes',
    separatedPickLists: 'Separadas',
    suppliedPickLists: 'Abastecidas',
    pickListCreationPickLists: 'Criação de Pick Lists',
    helpPickList: `<p>
    Aqui são exibidos os pontos de venda que possuem planograma ativo ou pendente, mas que não possuem
    nenhuma Pick List pendente.
    </p>
    <p>
    <strong>Geração de Pick Lists:</strong>
    </p>
    <ul>
    <li>
        Para criar uma Pick List, basta selecionar um ou mais pontos de venda e clicar em <strong>"Gerar pick
        list"</strong>,
        podendo configurar a Pick List em 2 tipos:
        <ul>
        <li>
            <strong>Pick List Primária:</strong>
            <ul>
            <li>
                a Pick List primária serve como a principal Pick List, normalmente com a maioria dos produtos
                do seu planograma. Só é possível ter uma Pick List principal por vez;
            </li>
            <li>
                Pick Lists primárias aparecem com o símbolo de "<v-icon>mdi-check</v-icon>" na aba "Pendentes";
            </li>
            </ul>
        </li>
        <li>
            <strong>Pick List Secundária:</strong>
            <ul>
            <li>
                a Pick List secundária serve como Pick List auxiliar, podendo ter mais de uma Pick List
                secundária por PDV. Você pode nomeá-las de acordo com o conteúdo;
            </li>
            <li>
                para marcar a Pick List como secundária, basta clicar em <strong>"Avançado"</strong> e marcar
                a opção;
            </li>
            <li>
                Pick Lists secundárias aparecem com o símbolo de "
                <v-icon>mdi-close</v-icon>
                " na aba "Pendentes";
            </li>
            <li>
                colocando a opção "Filtrar produtos por categoria" + 
                "Gerar pick list por categoria", isso cria uma pick list secundária
                separada para cada categoria selecionada, para cada PDV selecionado.
            </li>
            </ul>
        </li>
        </ul>
    </li>
    <li>
        Além dos 2 tipos de Pick List, você pode configurar o conteúdo da Pick List, podendo selecionar todos
        os produtos do seu planograma, ou filtrar por categorias específicas;
    </li>
    <li>
        Você pode também incluir apenas os produtos que estão com o estoque crítico, independente de ser todos
        os produtos ou apenas as categorias escolhidas.
    </li>
    </ul>`, 
    pickListPendingPickLists: 'Pick Lists Pendentes', 
    pendingPickListsHelp: `<p>
    A Pick List é uma lista com os produtos a serem levados no próximo abastecimento do ponto de venda.
    Para ver ao alterar os produtos e respectivas quantidades, clique sobre uma Pick List.
    </p>
    <p>
    Caso haja uma Pick List pendente para um ponto de venda,
    ao usar o recurso <strong>Abastecer</strong> do sistema de pagamento,
    a quantidade de itens da Pick List será acrescentada ao estoque do ponto de venda.
    A Pick List entrará então na tabela de Pick Lists <strong>abastecidas</strong>,
    e o ponto de venda voltará para a tabela <strong>PDVs sem Pick List</strong>.
    </p>`,  
    pickListSeparatedPickLists: 'Pick  Lists Separadas',
    pickListSeparatedPickListsHelp: `<p>
    Aqui são exibidas as Pick Lists que foram usadas através do recurso <strong>Separar</strong>
    do sistema de pagamento. São semelhantes às Pick Lists pendentes, mas já tiveram seus produtos
    separados no estoque, restando apenas serem usadas para de fato abastecer o Ponto de Venda.
    </p>`,
    pickListSuppliedPickLists: 'Pick Lists Abastecidas',
    pickListSuppliedPickListsHelp: `<p>
    Aqui são exibidas as Pick Lists que foram usadas através do recurso <strong>Abastecer</strong>
    do sistema de pagamento. Servem apenas para consulta, não tendo nenhum efeito nos próximos
    abastecimentos dos pontos de venda.</p>`,
    separationDatePickLists: 'Separada em ',
    supplyDatePickLists: 'Abastecida em ',
}
  