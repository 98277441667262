<template>
  <v-dialog v-model="show" persistent scrollable max-width="1200">
    <template #activator="{ on }">
      <v-btn v-on="on" @click="init()" class="ml-2" color="amber">
        <v-icon left>mdi-cart-remove</v-icon>{{ $t('titleNegativeItemsDialog') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="blue-grey lighten-5">
        <div class="title">{{ $t('titleNegativeItemsDialog') }}</div>
        <help>
          <template #title>{{ $t('titleNegativeItemsDialog') }}</template>
          <p>
            <span v-html="$t('descriptionNegativeItemsDialog')"></span>
          </p>
          <p>
            <span v-html="$t('content3NegativeItemsDialog')"></span>
          </p>
          <v-alert class="text-left" dense outlined text type="info" border="left">
            {{ $t('noteNegativeItemsDialog') }}
          </v-alert>
        </help>
        <v-spacer></v-spacer>
        <div class="ml-5 my-2">
          <v-text-field
            class="mr-4 pt-0"
            v-model="search"
            append-icon="search"
            :label="productDetailsView ? $t('searchLabelNegativeItemsNegativeItemsDialog') : $t('searchLabelNegativeItemsDialog')"
            single-line
            hide-details
            clearable
            filled
            rounded
            dense
            @click:clear="search.text = ''; getSuspiciousAccess();"
            @click:append="options.page = 1; getSearch()"
            @keydown.enter="getSearch"
            @input="options.page = 1"
          ></v-text-field>
        </div>
      </v-card-title>        
      <template v-if="!productDetailsView">
        <v-card-text class="ma-0 pa-0">
            
          <v-data-table
            fixed-header
            :headers="headers"
            :items="products"
            :options.sync="options"
            :loading="loading.table"
            :server-items-length="totalItems"
            :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
          >
            <template #item.actions="{ item }">
              <v-btn text color="primary" @click="selectedInventoryItem = item; productDetailsView = true">
                <v-icon>mdi-arrow-right-thick</v-icon>
              </v-btn>
            </template>
          </v-data-table>
            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="show = false">{{ $t('closeButtonTextNegativeItemsDialog') }}</v-btn>
          <v-btn v-if="productDetailsView" color="primary">{{ $t('saveButtonNegativeItemsDialog') }}</v-btn>
        </v-card-actions>
      </template>
        
      <template v-else>
        <negative-product-details
          ref="detailsView"
          :selected-inventory-item="selectedInventoryItem"  
          :search="search"
          @return-view="returnView()"
        ></negative-product-details>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="show = false; productDetailsView = false">{{ $t('closeButtonTextNegativeItemsDialog') }}</v-btn>
        </v-card-actions>
      </template>
        
    </v-card>
  </v-dialog>
</template>

<script>
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import NegativeProductDetails from '@/views/picklist/NegativeProductDetails';

export default {
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getProducts();
        }
      },
      deep: true
    },
    productDetailsView(newView) {
      if (newView === false) {
        this.search = "";
        this.getProducts();
      }
      else if (newView === true) {
        this.search = "";
      }
    }
  },
  data() {
    return {
      search: "",
      products: [],
      headers: [{ text: this.$t('headersIdNegativeItemsDialog'), align: 'left', sortable: true, value: 'id' },
                { text: this.$t('headersProductNegativeItemsDialog'), align: 'left', sortable: true, value: 'productDescription' },
                { text: this.$t('headersProductCodeNegativeItemsDialog'), align: 'left', sortable: true, value: 'productCode' },
                { text: this.$t('headersStockNegativeItemsDialog'), align: 'left', sortable: true, value: 'inventoryName' },
                { text: this.$t('headersQtyInStockNegativeItemsDialog'), align: 'left', sortable: true, value: 'quantity' },
                { text: this.$t('headersReservationsNegativeItemsDialog'), align: 'left', sortable: true, value: 'reservedQuantity' },
                { text: this.$t('headersBalanceNegativeItemsDialog'), align: 'left', sortable: true, value: 'balance' },
                { text: this.$t('headersDetailsNegativeItemsDialog'), align: 'left', sortable: false, value: 'actions' }],
      loading: {
        table: false
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
      },
      totalItems: 0,
      show: false,
      productDetailsView: false,
      selectedInventoryItem: null
    }
  },
  methods: {
    init() {
      this.search = "";
      this.getProducts();
    },
    getProducts() {
      this.loading.table = true;
      this.axios.get('api/web/inventory/items/negativebalance', { params: toApiPagination(this.options, this.search) })
        .then((response) => {
          this.loading.table = false;
          this.products = response.data.items;
          this.totalItems = response.data.totalItems;
        });
    },
    returnView() {
      this.productDetailsView = false;
      this.getProducts();
    },
    clearSearch() {
      this.search = "";
      this.getSearch();
    },
    getSearch() {
      !this.productDetailsView ? this.getProducts() : this.$nextTick(() => this.$refs.detailsView.getProductDetails());
    }
  },
  components: {
    'negative-product-details': NegativeProductDetails,
  }
}
</script>

<style>

</style>