export default {
    deleteNonUsedButton: "Eliminar no utilizados",
    deleteTaxScenarioDeleteDialogText: "¿Estás seguro de que deseas eliminar este escenario fiscal?",
    cancelButtonDeleteDialog: "Cancelar",
    deleteButtonDeleteDialog: "Eliminar",
    headerIsInvoiceDataComplete: "Estado",
    headerId: "Id",
    headerName: "Nombre",
    headerFiscalRegion: 'Región Fiscal',
    headerTaxation: "Regimen Fiscal",
    headerCountryTax: "Imp. País (%)",
    headerStateTax: "Imp. Estado (%)",
    headerCountyTax: "Imp. Condado (%)",
    headerNumberOfProducts: "Nº de Productos",
    headerActions: "Acciones",
    dataTableNoDataText: "No hay escenarios fiscales registrados",
    textFieldSearchText: "Buscar"
  }
  