
import Vue from 'vue';
import PointOfSalesMultipleSelector from '@/components/filters/PointOfSalesMultipleSelector.vue';
import { FormFilter } from '../promotions/Promotions.vue';
import DatePicker from '@/components/filters/DatePicker.vue';
import i18n from '@/locales/i18n';
import DiscountType from '@/components/filters/DiscountType.vue';
import ClearButton from '@/components/buttons/ClearButton.vue';
import UpdateButton from '@/components/buttons/UpdateButton.vue';
const _ = require('lodash');

const newForm = {
  pointOfSaleIds: [] as number[],
  discountType: null,
  startDate: '' as string | null,
  endDate: '' as string | null,
  timezoneOffset: new Date().getTimezoneOffset(),
} as FormFilter;

export default Vue.extend({
  components: {
    'point-of-sale-selector': PointOfSalesMultipleSelector,
    DatePicker,
    DiscountType,
    ClearButton,
    UpdateButton,
  },
  props: {
    pointsOfSale: { type: Array, required: true },
    formFilter: { type: Object, required: true }
  },
  data() {
    return {
      valid: false,
      hasClearDate: false,
      form: newForm as FormFilter,
      dateRange: [] as string[],
      translations: {
        pointOfSale: i18n.t('pointsOfSale').toString(),
        period: i18n.t('promotion.period').toString(),
        promotionType: i18n.t('promotion.details.discount.type.text').toString(),
        chooseType: i18n.t('promotion.details.discount.choose').toString(),
        clear: i18n.t('componentClearButton').toString(),
        update: i18n.t('update').toString(),
      },
    };
  },
  watch: {
    dateRange(newRange: string[]) {
      if (newRange.length === 2) {
        this.form.startDate = newRange[0];
        this.form.endDate = newRange[1];
      }
    },
    formFilter(originalFilter: FormFilter) {
      if(!originalFilter) {
        return;
      }
      this.form = _.cloneDeep(originalFilter);
      this.dateRange = [this.form.startDate!, this.form.endDate!];
    }
  },
  mounted() {
    this.emitModification();
  },
  methods: {
    emitModification() {
      if ((this.$refs.form as any).validate()) {
        this.$emit("modify", this.form);
      }
    },
    clearQuery() {
      this.dateRange = [];
      this.form.pointOfSaleIds = [];
      this.form.discountType = null;
      this.form.startDate = null;
      this.form.endDate = null;
      this.hasClearDate = true;
      this.emitModification();
    },
    updateDateRange(date : string[]) {
      [this.form.startDate, this.form.endDate] = date
    },
  },
});
