
import Vue from "vue";
import agent from "@/api/agent";
import { Company } from "@/types/Invoices";

export default Vue.extend({
  props: {
    value: { type: String, default: null },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      companies: [] as Company[],
      loading: false,
    };
  },
  computed: {
    companyId: {
      get(): string {
        return this.value;
      },
      set(value: string): void {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.loading = true;

    agent.Invoices.getCompanies()
    .then((response: Company[]) => {
        this.companies = response;
    })
    .finally(() => {
        this.loading = false;
    });
  },
});
