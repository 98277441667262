export default {
  priceTable: {
    action: {
      new: "New price table",
      create: {
        action: "Create price table",
        option: {
          empty: "Create empty table",
          planogram: "Copy from planogram"
        }
      },
      edit: "Edit products",
      remove: {
        action: "Remove",
        description: "the price table"
      },
      divergence: "View divergences",
      updateMarkupByCategory: "Set markup by category",
      importfromCentralStock: "Import from central stock",
      updateMarkUp: "Update markup",
      return: "Back to price tables"
    },
    data: {
      table: "Table",
      name: "Price table name",
      cost: "Cost",
      markUp: "Markup",
      price: "Price",
      category: "Category"
    },
    response: {
      fetch: {
        error: "Unable to load the price tables, please try again"
      },
      create: {
        success: "Successfully created price table {priceTableName}",
        successPlanogram: "Successfully created price table {priceTableName} from planogram {planogramId}",
        error: "Unable to create the price table"
      },
      edit: {
        success: "Successfully edited price table from {priceTableNamePrevious} to {priceTableName}",
        error: "Unable to edit the price table"
      },
      remove: {
        success: "Successfully removed price table {priceTableName}",
        error: "Unable to remove the price table"
      },
      updateMarkupByCategory: {
        success: "Successfully updated markup and price by category",
        error: "Unable to update markup by category"
      },
      changePriceTableType: {
        markUpOnly: "in adding cost and markup fields for each item in the price table",
        priceOnly: "in the irreversible loss of all values added in cost and markup"
      },
      importfromCentralStock: {
        success: "Successfully imported cost from central stock",
        error: "Unable to import cost from central stock, please try again"
      }
    },
    help: {
      divergence: {
        title: "Divergences in table application",
        type: {
          priceTable: {
            description: "Price Table",
            absenceReason: "{productId} - {productDescription} is at POS {pointOfSaleId} but is absent from the applied price table."
          },
          planogram: {
            description: "Planogram",
            absenceReason: "{productId} - {productDescription} is in the applied price table but is absent from POS {pointOfSaleId}."
          }
        },
        description: `
          <p align="justify">
            This dialog box shows the divergences that occurred when applying the price table to the selected Points of Sale.
          </p>
          <p align="justify">
            There are two possible types of divergences: <strong>Price Table</strong> and <strong>Planogram</strong> divergences.
          </p>
          <ul>
            <li align="justify">
              <strong>Price Table:</strong> A Price Table divergence occurs when a product is present<strong> at the POS</strong> to be applied, 
              but is <strong>absent</strong> from the <strong>applied price table.</strong>
            </li>
            <li align="justify">
              <strong>Planogram:</strong> A Planogram divergence occurs when a product is present <strong>in the Price Table</strong> 
              but is <strong>absent</strong> from the <strong>applied POS planogram</strong>.
            </li>
          </ul>
        `,
      },
      about: {
        title: "Price tables",
        description: `
          <p>
            This page lists the Price Tables that have been created; allowing you to create, edit, and activate them in various points of sale.
          </p>
          <p>
            <strong>Create a Price Table:</strong>
          </p>
          <ul>
            <li>
              To create a price table, just click on <strong>"+ NEW PRICE TABLE"</strong>, add the name of your table and select between two creation 
              methods.
              <ul>
                <li>
                  <strong>Create empty table -</strong> This method simply creates the Price Table without any information;
                </li>
                <li>
                  <strong>Copy from planogram -</strong> When selecting this method, tabs open to select which point of sale you want to copy the 
                  planogram from and its version;
                </li>
              </ul>
            </li>
            <li>
              To add products to your price table, click on <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i> and then click on 
              <strong>"+ ADD PRICE"</strong> and fill in the Product and Price fields, then click <strong>add</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Edit the price table:</strong>
          </p>
          <ul>
            <li>
              To edit the name of the price table, just click on <i style="font-size: 16px;" class="mdi mdi-pencil info--text"></i> and change the name, clicking 
              <strong>save</strong> to finish.
            </li>
            <li>
              To edit the price of any product, just click on <i style="font-size: 16px;" class="mdi mdi-playlist-edit info--text"></i>  and change the value in the price 
              field, when clicking outside or navigating using TAB (on the keyboard) the value is <strong>automatically saved</strong>.
            </li>
          </ul>
          <br/>
          <p>
            <strong>Activate the price table:</strong>
          </p>
          <ul>
            <li>
              To activate the price table, just click on <i style="font-size: 16px;" class="mdi mdi-domain info--text"></i>, select the points of sale where you want 
              to apply the price table, and then click <strong>CREATE DRAFTS</strong>.
            </li>
            <li>
              After clicking Proceed, a draft planogram will be created for the selected points of sale, remembering that it is 
              <strong>necessary</strong> to have the product in the planogram beforehand for prices to be applied.
            </li>
          </ul>
          <p></p>
        `,
      },
      centralStock: {
        title: "Import from central stock",
        description: `
          <p>
            In this feature, you can select which central stock will be used for updating the price table.
          </p>
          <p>
            <strong>Selecting Central Stock:</strong>
          </p>
          <p>
            Selecting which central stock the costs will be imported from.
          </p>
          <p>
            <strong>Markup and Price Calculations:</strong>
          </p>
          <p>
            The price table will be updated according to the following criteria.
          </p>
          <ul>
            <li>
              For prices without cost or markup. The cost will be imported from the stock and the markup will be calculated.
            </li>
            <li>
              For prices already completed*, by default the new price will be calculated based on the imported cost.
            </li>
            <li>
              For prices already completed* and with the "Update Markup" option active, the new Markup will be calculated without changing the price.
            </li>
            </ul>
            <br/>
            <p>
              <i>*Cost and Markup fields already filled in</i>
            </p>
        `,
      },
      markUpCategory: {
        title: "Markup by category",
        description: "In this feature, you can update the products' markup in the table by category.",
        option: {
          category: {
            action: "Category Selection:",
            description: "Selection of which category you want to update the markup."
          },
          markUp: {
            action: "Markup:",
            description: "New markup value to update the products in the selected category."
          },
          condition: "*Only products that are in the table and match the selected category will be updated."
        }
      },
      table: {
        action: {
          toMarkUp: `
            Changing the table from only <strong>Price</strong> to <strong>Markup</strong> will result in the addition of Cost and Markup fields 
            for each item in the Price Table.
            <br />
            Do you wish to continue?
          `,
          toPrice: `
            Changing the table from <strong>Markup</strong> to only <strong>Price</strong> will result in the irreversible loss of all values added 
            in Cost and Markup.
            <br />
            Do you wish to continue?
          `
        }
      }
    },
    item: {
      action: {
        create: "Add price",
        importCost: "Import cost",
        remove: "Remove product",
      },
      response: {
        create: {
          success: "Successfully created price",
          error: "Failed to create price"
        },
        fetch: {
          error: "Failed to load prices, please try again"
        },
        edit: {
          success: "Successfully edited price of {productDescription}",
          error: "Failed to edit price of {productDescription}"
        },
        remove: {
          success: "Successfully removed price of {productDescription}.",
          error: "Failed to remove price of {productDescription}"
        },
        priceTableTypeChange: {
          success: "Successfully changed price table type",
          error: "Failed to change price table"
        }
      },
      help: {
        about: {
          title: "Price Table Products",
          description: `
            <p>
              This page lists the prices from the selected table, allowing you to create, edit, and delete their respective values.
            </p>
            <p> <strong>Data persistence:</strong> </p>
            <ul>
              <li>
                The price table is designed to facilitate data persistence. When one of the fields 
                (Cost, Markup, or Price) loses focus (selects another field), it is saved to the database. 
                With this, navigation and editing are done with fewer steps.
                A good practice is to use the <strong>TAB</strong> and <strong>SHIFT + TAB</strong> keys on your keyboard.
              </li>
            </ul>
            <br/>
            <p> <strong>Markup and Price Calculations:</strong> </p>
            <p>
              The price table has calculations that work as follows:
            </p>
            <ul>
              <li> The <strong>Cost</strong> has no calculations and will not be affected by the Markup or Price. </li>
              <li> The <strong>Markup</strong> is calculated by editing the Cost or Price. </li>
              <li> The <strong>Price</strong> is calculated by editing the Markup. </li>
            </ul>
            <br/>
            <p>
              For the calculations to work, all three fields (Cost, Markup, and Price) must be filled. However, if 
              you only have the Price, simply input the Cost, and the Markup will be calculated automatically.
            </p>
            <p>
              The price table is designed to provide maximum flexibility, with the only mandatory field being the price.
            </p>
            <p>
              <strong>Create a Price:</strong>
            </p>
            <ul>
              <li>
                To create a new price, simply click on <strong>"+ ADD PRICE"</strong>. At the top of the table, 
                a line will appear to select the product to which you wish to add the Price, Markup, and Cost.
              </li>
            </ul>
            <br/>
          `
        }
      }
    }
  }
}