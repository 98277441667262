<template>
  <div class="login-background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
    <v-row justify-center align="center" no-gutters>
      <v-col cols="12" md="5">
        <v-card height="100vh" class="cards flex-container">
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
            @submit.prevent="resetPassword()"
            class="cards flex-item"
          >
            <v-card-text class="cards">
              <v-row>
                <img id="login-logo" src="../../public/assets/logo-new.svg"/>
              </v-row>
              <v-row>
                <span id="product-name">Redefinição de senha</span>
              </v-row>
              <v-row v-if="!success">
                <v-col>
                  <v-text-field
                    label="Senha"
                    v-model="password"
                    type="password"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Confirmar senha"
                    v-model="passwordConfirmation"
                    type="password"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-alert
                :value="success"
                type="success"
              >A senha foi alterada com sucesso</v-alert>
              <v-row v-if="error">
                <div style="color:red">{{ error }}</div>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" align="center">
                  <v-btn
                    v-if="!success"
                    color="info"
                    :loading="loading"
                    type="submit"
                    :disabled="!valid"
                    block
                  >
                    Redefinir
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dialog: true,
        error: null,
        userId: null,
        code: null,
        password: '',
        passwordConfirmation: '',
        valid: false,
        success: false,
        loading: false,
        backgroundImage: null,
        rules: [
          (v) => v.length >= 8 || 'A senha deve conter pelo menos 8 caracteres',
        ]
      };
    },
    mounted() {
      const params = this.$route.query;
      this.userId = params.id;
      this.code = params.codigo;
    },

    methods: {
      resetPassword() {
        if (this.passwordConfirmation !== this.password) {
          this.error = "A confirmação da senha está diferente da senha";
          return;
        }
        if (this.userId == null || this.code == null) {
          this.error = "O link de redefinição de senha não é válido";
          return;
        }
        this.error = null;
        const request = {
          userId: this.userId,
          code: this.code,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation
        }
        if(this.$refs.form.validate()) {
          this.loading = true;
          this.axios.post('Account/ResetPassword', request)
            .then(() => this.success = true)
            .catch(error => {
              if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') this.error = error.response.data;
                else if (Array.isArray(error.response.data)) this.error = error.response.data[0];
                else if (error.response.data.title) this.error = error.response.data.title;
              }
            })
            .then(() => this.loading = false)
        }
      },
      getImage() {
        this.axios.get('api/messages/LoginImage')
      .then((response) => {
        this.backgroundImage = (response.data[0].content)
      })
    }
    },
  };
</script>

<style scoped>
@media (min-width: 960px)
{
  .v-sheet.v-card {
    border-radius: 0px 30px 30px 0px;
  }
}

#product-name {
  color:#0087C8;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-item {
  flex-grow: 1
}
</style>
