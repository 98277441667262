export default {
    helpPurchaseTitle: 'Compras',
    helpPurchaseDescription: `
      En esta página, se organizan las notas de compra ingresadas por el usuario, ya sea a través de archivos XML, así como las importadas 
      de un sistema ERP. Al seleccionar una compra específica, es posible acceder a detalles sobre los productos involucrados en el proceso.
    `,
    helpPurchaseInfo: 'Información de la compra:',
    helpOperationId: `
      <strong>ID de la Operación:</strong>
      ID de identificación de la compra. Útil para identificar el movimiento realizado en el stock 
      correspondiente en las pestañas: Stocks &rarr; Movimientos.
    `,
    helpStatus: `
      <strong>Estado:</strong> 
      Tan pronto como se importa, la compra tiene el estado <strong>Activa</strong>.
      Si se cancela la compra, su estado se convierte en <strong>Cancelada</strong>
      y sus movimientos de stock se deshacen.
    `,
    helpErpId: `
      <strong>ID en ERP:</strong> 
      ID de identificación interna de la compra en el ERP (solo para notas importadas de sistemas ERP)
    `,
    helpNoteNumber: `
      <strong>Número de la Factura:</strong> 
      Número de identificación de la factura.
    `,
    helpInventoryId: `
      <strong>ID del Inventario y Stock:</strong> 
      Stock donde se realizaron los movimientos de compra.
    `,
    helpItemQuantity: `
      <strong>Cantidad de Artículos:</strong> 
      Cantidad total de artículos contenidos en la compra.
    `,
    helpNoteValue: `
      <strong>Valor de la Factura:</strong> 
      Representa el valor total de todos los productos contenidos en la factura de compra.
    `,
    helpTotalCost: `
      <strong>Costo Total de la Compra:</strong> 
      Representa el costo total de todos los productos contenidos en la factura de compra
      ya considerando la estimación de impuestos calculados.
    `,
    helpReceivedDate: `
      <strong>Fecha de Recepción:</strong> 
      Fecha y hora en que se realizó la recepción de la compra en el ERP.
    `
  };
  