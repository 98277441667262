<template>
    <help>
        <template #title>{{ $t('DialogTokenHelpTitle') }}</template>
        <p v-html="$t('DialogTokenBody')"></p>
        <p><strong>{{ $t('DialogTokenMessage') }}</strong></p>
        <help-video  help-code="IntegrationTransaction"/>
    </help>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
components: {
    'help-video': HelpVideo
  },
}
</script>
