export default {
    titleSellingProducts: 'Productos más vendidos',
    abcText: `{numberOfAProducts} de los {totalNoOfProducts} productos representan el 80% de sus ingresos y
    {numberOfCProducts} de los {totalNoOfProducts} productos representan el 5% de sus ingresos`,
    newTabLink: 'Abrir en nueva pestaña',
    pointOfSaleLabel: 'Puntos de Venta',
    quantitySold: 'Cantidad Vendida',
    totalSold: 'Total Vendido',
    productsSoldNoResults: 'Sin resultados',
    notRegistered: 'Producto no registrado',
    productsSold: 'Producto',
    productsSoldAmount: 'Cantidad vendida',
    valueSoldProducts: 'Valor vendido',
    valueRupturesProducts: 'Rupturas',
    rupturesGetErrorMessage: 'Hubo un error al cargar las rupturas de los productos',
    abcCurveGetErrorMessage: 'Hubo un error al cargar la información de la curva ABC',
    productsMostSoldHelpText: ` 
    <p style="text-align: justify;">
    ¡Descubre cuáles son los productos más demandados en tus tiendas! Entiende sus ventas y evita posibles rupturas debido a la falta de suministro.
    </p>
    <p style="text-align: justify;">
    Además, ofrecemos análisis sobre la importancia de la mezcla de productos en tu operación. Identificamos los elementos que impulsan significativamente tus ingresos, representando el 80% de tus ventas.
    </p>
    <p style="text-align: justify;">
    Por otro lado, también destacamos los productos de bajo rendimiento, que contribuyen solo con el 5% de tus ingresos totales.
    </p>`
};
