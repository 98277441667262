<template>
  <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <feedback ref="feedback"></feedback>

    <v-card>
      <v-card-title>
        <div class="title">{{ $t('retryTitleBulkRetryInvoice') }}</div>
      </v-card-title>
      
      <v-card-text>
        {{ $t('retryDescriptionBulkRetryInvoice') }}
        <v-alert class="text-left mt-2" dense outlined text type="warning" border="left">
          {{ $t('retryWaitBulkRetryInvoice') }}
        </v-alert>
        <v-switch :label="$t('keepNumberLabelBulkRetryInvoice')" v-model="keepNumber" light></v-switch>
        <v-alert class="text-left mt-2" dense outlined text type="info" border="left">
          {{ $t('keepNumberHintBulkRetryInvoice') }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary" 
          :disabled="loading" 
          text 
          @click.native="dialog = false"
        >{{ $t('closeBulkRetryInvoice') }}</v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="finished"
          text
          @click.native="retry"
        >{{ $t('retryButtonBulkRetryInvoice') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    initialDate: { type: String, required: true },
    finalDate: { type: String, required: true }
  },
  watch: {
    dialog(value) {
      if (!value) this.finished = false;
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      keepNumber: true,
      finished: false
    }
  },
  methods: {
    retry() {
      this.loading = true;
      this.axios.post('api/invoicesWeb/bulkRetry', {
        initialDate: this.initialDate,
        finalDate: this.finalDate,
        createNew: !this.keepNumber
      }).then((response) => {
        this.$refs.feedback.handleSuccess(response.data);
        this.finished = true;
      })
      .catch((error) => this.$refs.feedback.handleError(this.$t('retryErrorBulkRetryInvoice'), error))
      .then(() => this.loading = false);
    }
  }
}
</script>