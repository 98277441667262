export default {
  discounts: {
    title: 'Discounts',
    coupons: {
      title: 'Coupons',
    },
    promotions: {
      title: 'Promotions',
    },
    help: {
      description: {
        coupons: `<p>On this page, you can create discounts (button <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Discount) for end consumers as a means of their loyalty. There are two types of discount: coupons and promotions. Each has a corresponding tab on the page. Discounts are available for the TouchPay Market terminal and the TouchPay Mobile app.</p>

        <h3>Coupons</h3>
        <p>For a consumer to use a coupon discount, they will need to enter the coupon code on the payment terminal or in the app. A coupon is always associated with a specific discount. To create coupons, use the button <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Coupons in the desired discount. If the coupon is removed, no one else will be able to use it. It is also possible to set an expiration date for the coupon.</p>
    
        <p>The coupon discount can have a fixed value in reais or be a percentage of the total purchase value. It is also possible to define a minimum purchase value from which the discount can be applied.</p>
    
        <p>The coupon can be associated with the buyer's cell phone number. In this case, it can only be used if the consumer, when entering the coupon, also enters their cell phone number, and this number is equal to the number associated with the coupon.</p>
        `,
        alert: 'For promotions to work, it is necessary that TouchPay Market is at version 3.8.0 or higher, and TouchPay Mobile at version 1.14.0 or higher.',
        promotions: `
          <p>Promotions are applied to products or product combos. When adding a discount of the promotion type, it is necessary to choose the products that are part of the promotion. Some application examples:</p>
    
          <ul>
            <li>When buying 2 AMColas, the second one gets a 50% discount: mark the discount type as <strong>%</strong>. Add an AMCola product with quantity 1 and discount 0. Then add another AMCola with quantity 1 and discount 50.</li>
            <li>When buying one AMCola and two AMChips, the combo has a 20% discount: mark the discount type as <strong>%</strong>. Add an AMCola product with quantity 1 and discount 20. Then add the AMChips product with quantity 2 and discount 20.</li>
            <li>When buying 2 boxes of AMCoffee Expresso or AMCoffee Longo capsules, get a 10 reais discount: we created a grouping (menu <strong>Groupings</strong>) called AMCoffee containing the two types of coffee. On the groupings page, there is more information about how to create and use them. Then we created a new discount, marking the type as <strong>$</strong>. We added a new AMCoffee product (which is the grouping) in the discount, with quantity 2 and discount 10.</li>
          </ul>
    
          <p>A promotion can be applied to all points of sale or be valid only in some PDVs. By default, the promotion is valid for all PDVs. To restrict it to only specific PDVs, click on <strong>Advanced</strong> and uncheck the option <strong>Apply to all PDVs</strong>. Then it is possible to choose only the desired PDVs.</p>
        `,
      }
    }
  }
}
