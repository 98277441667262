export default {
    welcome: 'Welcome!',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    keepConnected: 'Keep me logged in',
    buttonLogin: 'Login',
    buttonContinue: 'Continue',
    forgotPassword: 'Forgot password?',
    clickHere: 'Click here!',
    recoverPassword: 'Recover password',
    returnToLogin: 'Back to login',
    seeEmailBox: 'Check your email inbox',
    systemUnavailable: 'System unavailable, please try again later',
    userInvalidPassword: 'Invalid username or password',
    royaltyBlocking: 'Your access is blocked. Please contact your Franchisor.',
    paymentBlock: 'Your access is blocked. Please contact the administration at (11) 5199-6668.',
    pendingContract: 'There are pending issues in your contract. Please contact the administration at (11) 5199-6668.',
    somethingWentWrong: 'Oops! Something went wrong',
    fillInEmail: 'Fill in your email.',
    fillInPassword: 'Fill in password.',
    hasFinancialIssues: 'Your access is blocked. Click on the button below to check for any pending bills. If the problem persists, please contact the administration at (11) 5199-6668.',
    pendingBills: 'Go To Pending Bills',
    twoFactorCode: 'Authentication code',
    twoFactorFailed: 'Could not validate the authentication code, please check it again.',
    trustPeriodText: 'In case the payment has been made but is still in processing, you can click the button below to unblock your access for 3 days. If the payment is not confirmed within this period, your access will be blocked again, and this option will become unavailable.',
    trustPeriodUpdateFailed: 'Could not update the trust period. Please contact the administration at +55 (11) 5199-6668.',
    trustPeriodButton: 'Start Trust Period',
    trustPeriodDialogText: 'This will unblock your access for 3 days. If the payment is not confirmed within this period, your access will be blocked again, and this option will become unavailable. Do you wish to proceed?',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel'
  };
