<template>
  <v-flex>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value="selectedDates"
      transition="scale-transition"
      offset-y
      min-width="auto"
      max-width="300"
      @modify="emitDateRange"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDateRange"
          :label="$t('filters.labels.calendar')"
          prepend-icon="mdi-calendar-range"
          readonly
          v-bind="attrs"
          v-on="on"
          lazy-validation
          :rules="dateRangeRules"
        />
      </template>

      <v-date-picker
        v-model="selectedDates"
        range
        no-title
        @change="handleDateChange"
        :allowed-dates="allowedDates"
      >
        <v-row>
          <v-col cols="12" sm="12" class="d-flex justify-end mt-3">
            <v-btn text color="primary" @click="clearSelectedDates">{{$t('buttons.clear')}}</v-btn>
            <v-btn text color="primary" @click="confirmDateSelection" :disabled="isSaveDisabled">Ok</v-btn>
          </v-col>
        </v-row>
      </v-date-picker>
    </v-menu>
  </v-flex>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    clear: { type: Boolean, default: false },
    allowedDates: { type: Function, default: () => true },
    minDate: { type: String, default: () => moment().subtract(30, 'days').format('YYYY-MM-DD') },
    maxDate: { type: String, default: () => moment().format('YYYY-MM-DD') },
    requiredDates: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedDates: [],
      menu: false,
    };
  },
  computed: {
    formattedDateRange() {
      const [startDate, endDate] = this.selectedDates;
      if (startDate && endDate) {
        return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
      }
      return '';
    },
    isSaveDisabled() {
      return this.requiredDates && !this.areDatesValid;
    },
    areDatesValid() {
      const [startDate, endDate] = this.selectedDates;
      return startDate && endDate;
    },
    dateRangeRules() {
      return this.requiredDates ? [(value) => !!value || 'A data de início e fim são obrigatórias'] : [];
    },
  },
  watch: {
    menu(newValue) {
      if (!newValue) {
        this.emitDateRange();
      }
    },
    clear(newValue) {
      if (newValue) {
        this.clearSelectedDates();
        this.$emit('clearComplete');
      }
    },
  },
  mounted() {
    this.selectedDates = [this.minDate, this.maxDate];
  },
  methods: {
    handleDateChange(value) {
      this.normalizeDateOrder(value);
      this.$emit('input', this.selectedDates);
    },
    normalizeDateOrder(dates) {
      if (dates.length === 2) {
        const [startDate, endDate] = dates;
        this.selectedDates = moment(startDate).isAfter(endDate) ? [endDate, startDate] : [startDate, endDate];
      }
    },
    clearSelectedDates() {
      this.selectedDates = [];
      this.emitDateRange();
    },
    confirmDateSelection() {
      if (this.areDatesValid || !this.requiredDates) {
        this.menu = false;
        this.emitDateRange();
      }
    },
    emitDateRange() {
      this.$emit('modify', this.selectedDates);
    },
  },
};
</script>
