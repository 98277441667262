export default {
  purchase: {
    title: 'Purchases',
    entry: {
      product: {
        index: 'Product {index} of {total}',
        value: 'Unit Value of the product',
        requests: {
          error: {
            selectGrouping: 'Unable to select a grouping',
            conversionUnit: 'Error retrieving conversion units'
          }
        },
        quantity: {
          total: 'Total Quantity of the product',
          item: 'Item quantity',
        },
        help: {
          title: 'Product Information',
          description: `On this page, data extracted from XML shipments is available for association with specific products, 
          allowing the selection of the conversion unit to update the stock.`,
          product: {
            title: 'Products',
            description: `If the product barcode from the invoice matches the code of a product in the system, it will already be pre-selected.`
          },
          conversionUnit: {
            title: 'Conversion Unit / Units',
            description: `If the unit is classified as unknown, this indicates that our system could not interpret the unit mentioned in the XML. 
            To fix this, simply select the unit price (such as units, boxes, or bundles) and indicate how many units make up that conversion measure 
            (for example, unit = 1, box = 10, bundle = 6).
            Note: the quantity entered does not accept decimal numbers.`
          },
          quantity: {
            title: 'Total Quantity of the product',
            description: `After entering the quantity and cost data, our system will automatically calculate the total units and the corresponding unit price. 
            For example, when entering two boxes, each containing 10 units, at a cost of 10 reais per box: Total units: 20 | Unit price: R$ 1.00. 
            This functionality simplifies viewing the total products and their cost per unit, making it easier to analyze the information.`
          },
        },
        xml: {
          stepTitle: 'Add Products',
          title: 'PRODUCT INFORMATION',
          invalid: 'To proceed, fill in: unit value, product, conversion unit, quantity.',
        },
        manual: {

        }
      },
    }
  }
}
