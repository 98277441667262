
import Vue from 'vue';
import {InvoicePurchase, Product} from "@/types/Purchases"
import agent from '@/api/agent';
import IdsProductsDropDownVue from '@/components/filters/IdsProductsDropDown.vue';
import ProductDialog from '@/views/products/ProductDialog.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import NextFinishButton from '@/components/buttons/NextFinishButton.vue';

export default Vue.extend({
    data() {
        return {
            invoice: {
              productRegistrationDisabled: false as Boolean,
              nfeNumber: '' as string,
              serie: '' as string,
              dateEmitted: '' as string,
              providerName: '' as string,
              key: '' as string,
              inventoryId: null as number | null,
              products: [] as Product[],
            } as InvoicePurchase,
            currentProduct : {
              productId: 0 as number | null,
              barCode: '' as string | null,
              unitTypeByUser: '' as string | null,
              unitValue: 0 as number | null,
              quantityByUser: 0 as number | null,
              codeByUser: '' as string | null,
              descriptionByUser: '' as string | null,
            } as Product,
            unitTypeText: '' as string | null,
            currentIndexProducts: 0 as number,
            listUnitConversion : [] as { value: string, text: string } [],
            quantityProductDisabled: false as boolean,
            editedIndex: -1,
            productDialog: false as boolean,
            editedEntry: {
              code: '' as string,
              barCode: '' as string,
              description: '' as string,
              productTaxGroups: [] as any[],
            },
            productSelected: false as Boolean,
        }
    },
  props: {
    invoiceXml: { type: Object, required: true },
    open: { type: Boolean, required: true, default: false },
    indexEdit: { type: Number, required: false, default: null },
  },
    components: {
        IdsProductsDropDownVue,
        ProductDialog,
        BackButton,
        CloseButton,
        NextFinishButton,
    },
    watch: {
      invoiceXml: {
        immediate: true,
        handler: function (val: InvoicePurchase) {
          this.invoice = val;
          if(this.invoice.products.length > 0)
            this.getCurrentObject();
        },
      },
      open: {
        immediate: true,
        handler: function (val: boolean) {
          if(val){
            this.getUnitConversion();
          }
        },
      },
      indexEdit:{
        immediate: true,
        handler: function (val: number) {
          if(val != null){
            this.currentIndexProducts = val;
            this.getCurrentObject();
          }
        },
      }
    },
    methods: {
        getUnitConversion(){
          this.axios.get("api/Products/conversionUnitsList").then((response : any) => {
            this.listUnitConversion = response.data;
        }).catch((error : any) => {
            this.$emit("error", this.$t('purchase.entry.product.requests.error.conversionUnit'), error);
            this.resetData();
        });
        },
        nextProduct(){
          if(this.currentIndexProducts < this.invoice.products.length - 1  && this.indexEdit == null){
            this.invoice.products[this.currentIndexProducts] = this.currentProduct;
            this.setLocalStorage();
            this.currentIndexProducts++;
            this.getCurrentObject();
          }
          else{
            localStorage.removeItem('stepInvoice');
            localStorage.removeItem('invoiceImported');
            this.$emit("complete", this.invoice);
            this.resetData();
          }
        },
        backProduct(){
          if(this.currentIndexProducts > 0 && this.indexEdit == null){
            this.invoice.products[this.currentIndexProducts] = this.currentProduct;
            this.currentIndexProducts--;
            this.getCurrentObject();
          }
          else{
            this.closeDialog();
          }
        },
        closeDialog(){
          this.$emit("close");
          this.resetData();
        },
        getCurrentObject(){
          let unitType: { value: string, text: string } | undefined = this.listUnitConversion.find((unit : any) => unit.value == this.invoice.products[this.currentIndexProducts].unitType);

          this.currentProduct = this.invoice.products[this.currentIndexProducts];

          var quantity = this.currentProduct.quantityByUser != null ? this.currentProduct.quantityByUser : 0;
          Vue.set(this.currentProduct, "quantityByUser", quantity);

          this.changeProduct();
          this.unitTypeText = unitType?.text != null ? unitType?.text : null ;
          this.changeUnitTypeProduct();
        },
        resetData(){
          this.currentProduct = {
              productId: 0 as number | null,
              unitTypeByUser: '' as string,
              unitValue: 0 as number,
              quantityByUser: 0 as number,
              codeByUser: '' as string,
              descriptionByUser: '' as string,
            } as Product,

          this.currentIndexProducts = 0 as number;
          this.editedIndex = -1;
          this.productDialog = false;
          this.editedEntry = {
            code: '' as string,
            barCode: '' as string,
            description: '' as string,
            productTaxGroups: [] as any[],
          };
        },
        nextButtonEnable() : boolean{
          return (this.currentProduct.unitValue > 0 &&
            this.currentProduct.productId != null &&
            this.currentProduct.unitTypeByUser != null && 
            this.currentProduct.quantityByUser! > 0) &&
            Number.isInteger(Number(this.currentProduct.quantityByUser!));
        },
       
        changeUnitTypeProduct(){
          if(this.currentProduct.unitTypeByUser == "Unit"){
            Vue.set(this.currentProduct, "quantityByUser", 1);
            this.quantityProductDisabled = true;
          }
          else{
            this.quantityProductDisabled = false;
          }
        },
        changeProduct(){
           this.verifyProductSelected();
           this.editedIndex = this.currentProduct.productId != null ? this.currentProduct.productId : -1;
      
           if(this.editedIndex != -1)
           {
              this.getProuctEdit();
           }
           else{
            this.editedEntry = {
              code: '' as string,
              barCode: this.currentProduct.barCode,
              description: this.currentProduct.description,
              productTaxGroups: [] as any[],
            };
           }
        },
        getProuctEdit(){
          agent.Products.productById(this.currentProduct.productId!).then((response) => {
            this.editedEntry = response;
            this.currentProduct.codeByUser = this.editedEntry.code;
            this.currentProduct.descriptionByUser = this.editedEntry.description;
            this.currentProduct.barCode = this.editedEntry.barCode;
          }).catch(() => {
            this.editedEntry = {
              code: '' as string,
              barCode: this.currentProduct.barCode,
              description:  this.currentProduct.description,
              productTaxGroups: [] as any[],
            };
            this.editedIndex = -1;
            this.currentProduct.codeByUser = '';
            this.currentProduct.descriptionByUser = '';
            this.currentProduct.productId = null;
            (this.$refs.feedback as any).handleError(this.$t('purchase.entry.product.requests.error.selectGrouping'));
          });

        },
        onProduct(product: any){
          this.productDialog = false;
          (this as any).$refs.productDropDown.loadDropdownProducts();
          this.currentProduct.productId = product.id;
          this.editedIndex = this.currentProduct.productId! ;

          this.editedEntry = product;

          this.currentProduct.barCode = this.editedEntry.barCode;
          this.currentProduct.codeByUser = this.editedEntry.code;
          this.currentProduct.descriptionByUser = this.editedEntry.description;

        },
        handleError(message : any, error : any) {
          this.productDialog = false;
          (this.$refs.feedback as any).handleError(message, error);
        },
        setLocalStorage(){
          localStorage.setItem('stepInvoice', "2")
          localStorage.setItem('invoiceImported', JSON.stringify(this.invoice))
        },
        verifyProductSelected(){
          this.productSelected = this.invoice.products
                  .filter((item, index) => index < this.currentIndexProducts)
                  .some(item => item.productId == this.currentProduct.productId && this.currentProduct.productId != null);
        },
    },
})
