export default {
    dialogTitleSupply: 'Replenishment',
    helpTitleSupply: 'Point of sale Replenishment',
    supplyCompleteLabel: 'Replenishment complete',
    planogramLabelSupply: 'planogram ',
    supplyPickListLabel: 'Replenishment by Pick List',
    closeLabelSupply: 'Close',
    supplyLabel: 'Replenishment',
    chooseSupplyTypeTooltip: 'A Replenishment option must be chosen',
    noPickListTooltipSupply: 'There is no pending Replenishment Pick List for this point of sale',
    noPlanogramTooltipSupply: 'There is no planogram active or pending at this point of sale',
    feedbackSuccessSupply: 'Point of sale stocked!',
    feedbackErrorSupply: 'Error Replenishment point of sale',
    pickListSupply: 'Pick List ',
    pickListMainSupply: ' (main)',
    pickListSecondarySupply: ' (secondary)',
  };