
import Vue from 'vue';
import MyContractsCards from "@/views/billing/myBills/MyContractsCards.vue";
import { useContractsStore } from "@/stores/contractsStore";
import { GetContractBillInfoDto, ContractBillItemDto } from "@/types/Contract";
import { formatCnpj } from "@/utility/TextFormatting";
import { billServiceTypesGroups, billServiceCardTitle } from "./constants/enums"
import moment from "moment";

export default Vue.extend({
  components: {
    MyContractsCards
  },
  data() {
    return {
      contractsStore: useContractsStore(),
      contractItems: {} as { [key: string]: ContractBillItemDto[] },
    }
  },
  methods: {
    showCards(key: string): boolean {
      return this.contractItems[key]
        && this.contractItems[key].some(item => item.chargedQuantity > 0);
    },
    async loadContracts() {
      const tenantId = (this as any).$auth.user().tenantId;
      const groupId = (this as any).$auth.user().tenantGroupId;
      const referenceDateString = moment().format("DD-MM-YYYY");
      await this.contractsStore.fetchMidasContract(tenantId, groupId, referenceDateString);
      const contract = this.contractsStore.midasContract?.contract as GetContractBillInfoDto | undefined | null
      this.contractItems = this.groupItemsByServiceType(contract?.items || [])
    },
    formatCnpj(cnpj: string) {
      return formatCnpj(cnpj);
    },
    /**
     * As some services have to be grouped together, this method Groups the items by service type.
     *
     * @param {ContractBillItemDto[]} items - The array of contract bill items.
     * @returns {Object} - An object containing the grouped items by service type.
     */
    groupItemsByServiceType(items: ContractBillItemDto[]): { [key: string]: ContractBillItemDto[] } {
      const groupings = {} as { [key: string]: ContractBillItemDto[] };

      items = this.removeUnavailableServices(items);

      items.forEach(contractItem => {
        const key = (billServiceTypesGroups as any)[contractItem.serviceTypeEnumName] || contractItem.serviceTypeEnumName

        if (Array.isArray(key) && key.length > 1) {
          key.forEach(k => {
            if (!groupings[k]) {
              groupings[k] = [];
            }
            groupings[k].push(contractItem);
          });

          return;
        }

        if (!groupings[key]) {
          groupings[key] = [];
        }
        groupings[key].push(contractItem);
      });

      return groupings;
    },
    /**
     * Removes unavailable services from the given list of contract bill items.
     * A service is considered unavailable if it has a charged quantity of 0 and a unit price of 0.
     *
     * @param {ContractBillItemDto[]} items - The list of contract bill items.
     * @returns {ContractBillItemDto[]} - The updated list of contract bill items with unavailable services removed.
     */
    removeUnavailableServices(items: ContractBillItemDto[]): ContractBillItemDto[] {
      return items.filter(item => !(item.chargedQuantity == 0 && item.unitPrice == 0));
    },
    getCardTitle(serviceType: string): string {
      return (billServiceCardTitle as any)[serviceType] || serviceType;
    },
  },
  mounted() {
    this.loadContracts();
  }
})
