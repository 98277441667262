export default {
    searchMenu: 'Search',
    searchPage: 'Search Page',
    favoritesMenu: 'Favorites',
    telemetryMenu: 'Telemetry',
    salesMenu: 'Sales',
    stockMenu: 'Stock',
    generalMenu: 'General',
    registersMenu: 'Registers',
    crmMenu: 'CRM',
    securityMenu: 'Security',
    financialMenu: 'Financial',
    savedPagesMenu: 'There are no pages saved',
    "Attention": "Attention",
    newBillingAccessDialogText: `<p>We are moving the <strong style="color:#00B31D">My Invoices</strong> screen to the user area
      <strong>located at the top of the screen</strong>, please check the instructions below:</p>`,
    "Close": "Close",
  };