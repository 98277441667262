<template>
  <v-container fluid class="pa-0">
    <v-row cols="12">
      <v-col md="4" sm="6">
        <v-select
          v-model="filter.categories"
          label="Filtrar por categoria"
          multiple
          chips
          :items="categories"
          class="pa-5"
          @click:clear="search.text = ''; getReleases();"
          @click:append="options.page = 1; getReleases()"
          @input="options.page = 1"
        >
          <template #selection="{ item }">
            <v-chip small :color="getCategoryColor(item.value)">
              {{ item.text }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
      </v-col>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel
          v-for="(releaseGroup, i) in releases"
          :key="i"
        >
          <v-expansion-panel-header>
            <h1 class="header">{{ formatReleaseDate(releaseGroup.date) }}</h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(releaseNote, j) in releaseGroup.releaseNotes"
              :key="j"
              class="display: inline-block"
            >
              <span class="title">{{ releaseNote.title }}: </span>
              <span v-html="releaseNote.content"></span>
              <v-chip-group class="display: inline-block">
                <v-chip
                  v-for="category in releaseNote.categories"
                  :key="category"
                  small
                  :color="getCategoryColor(category)"
                >{{ getCategoryTranslation(category) }}</v-chip>
              </v-chip-group>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div class="text-center">
      <v-pagination
        v-model="options.page"
        :length="totalPages"
        :total-visible="3"
      ></v-pagination>
    </div>
    <feedback ref="feedback"></feedback>
    <div class="px-5 pl-5">
      <div class="caption"><strong>Versão front end</strong>: {{ frontEndVersion }}</div>
      <div class="caption"><strong>Versão back end</strong>: {{ backEndVersion }}</div>
    </div>
  </v-container>
</template>

<script>
import { toApiPagination } from '../components/TableOptionsComparator';
import moment from 'moment';

const categoriesColors = {
  App: "amber lighten-1",
  WebSystem: "green lighten-2",
  MicroMarket: "teal darken-1",
  Integration: "orange lighten-1",
  VendingMachine: "yellow lighten-1"
}

export default {
  data() {
    return {
      loading: false,
      filter: {
        categories: []
      },
      releases: [],
      categories: [
        { text: "Aplicativo", value: "App" },
        { text: "Sistema Web", value: "WebSystem" },
        { text: "Micro Market", value: "MicroMarket" },
        { text: "Integrações", value: "Integration" },
        { text: "Vending Machine", value: "VendingMachine" },
      ],
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [true],
        mustSort: true
      },
      panel: [],
      totalPages: 0,
      frontEndVersion: process.env.VUE_APP_VERSION,
      backEndVersion: ''
    }
  },
  watch: {
    'options.page'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.panel = [];
        this.getReleases();
      }
    }
  },
  mounted() {
    this.getReleases();
    this.getBackEndVersion();
  },
  methods: {
    getReleases() {
      this.loading = true;
      let params = toApiPagination(this.options);
      params = this.insertFiltersFromForm(params);
      this.axios.get('api/releaseNotes', { params: params })
        .then((response) => {
          this.releases = response.data.items;
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => this.$refs.feedback.handleError(error))
        .finally(() => this.loading = false);
    },
    async getBackEndVersion() {
      // transformResponse is required to interpret response as string instead of JSON
      const response = await this.axios.get('api/health/version', { transformResponse: undefined });
      this.backEndVersion = response.data;
    },
    insertFiltersFromForm(params) {
      params.categories = this.filter.categories;
      return params;
    },
    formatReleaseDate(date) {
      return moment(date).format("MMMM YYYY");
    },
    getCategoryColor(category) {
      return categoriesColors[category];
    },
    getCategoryTranslation(category) {
      const pair = this.categories.filter(i => i.value == category);
      return pair[0].text;
    }
  }
}
</script>

<style>

</style>