export default {
    promotionalLogos: {
        title: 'Promotional Logos',
        apply: "Apply Promotional Logo",
        suggestion: "Suggestions",
        addLogo: "Add new logo",
        handleSuccess: "Logo updated for the POS",
        handleError: "Could not update logo for the POS",
        buttons: {
            delete: "Remove logo",
            apply: "Apply logos to POS",
            updateLogo: "Update logo",
        },
        
        block: "Block Logo",
        blockSelected: "Block logo change for selected POS",
        helpBlockLogo: {
            title: "Block logo change",
            description: "By enabling this option, changing the logo at the point of sale will be restricted to users with administrator permission.",
        },
    },
};
