
import Vue from 'vue';
import InvoicesFilter from './InvoicesFilter.vue';
import {shouldRefreshData, toApiPagination} from "@/components/TableOptionsComparator";
import { formatDateTimeSeconds } from "@/utility/TextFormatting";
import InvoiceDialog from "@/views/invoice/InvoiceDialog.vue";
import agent from "@/api/agent"

export default Vue.extend({
  components: {
    InvoicesFilter,
    InvoiceDialog
  },
  data() {
    return {
      headers: [
        { text: "Cliente", value: "pointOfSaleLocalCustomerName", align: "left", sortable: false },
        { text: "Local", value: "pointOfSaleLocalName", align: "left", sortable: false },
        { text: "PDV", value: "pointOfSaleId", align: "left", sortable: false },
        { text: "Data/hora Transação", value: "transactionDate", align: "left", sortable: false },
        { text: "Data/hora Emissão", value: "dateEmitted", align: "left", sortable: true },
        { text: 'Série', value: 'series', align: 'left', sortable: false },
        { text: 'Número', value: 'number', align: 'left', sortable: true },
        { text: "Status", value: "status", align: "left", sortable: false },
        { text: 'Ações', value: 'actions', align: 'left', sortable: false },
      ],
      totalItems: 0,
      invoices: [] as any[],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateEmitted'] as string[],
        sortDesc: [true],
        mustSort: true,
      },
      search: "" as string | null,
      form: {
        minDate: "" as string,
        maxDate: "" as string,
        status: [] as string[]
      },
      invoiceDialog: {
        enabled: false,
        invoice: null as {} | null,
      },
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getInvoices();
        }
      },
      deep: true,
    },
  },
  methods: {
    async getInvoices() {
      this.loading = true;
      let params = toApiPagination(this.options, this.search as any);
      params = { ...params, ...this.form }
      try {
        const response = await agent.Invoices.getInvoicesPaginated(params);
        this.invoices = response.items;
        this.totalItems = response.totalItems;
      } catch (error) {
        (this.$refs.feedback as any).handleError("Houve um erro ao carregar as NFC-e", error)
      }
      this.loading = false;
    },
    formatDateTimeSeconds,
    updateTable(form: any) {
      this.form = form;
      this.getInvoices()
    },
    getStatusText(value: string): string {
      let text = "-";
      switch(value) {
        case 'Processing' : text = "Em processamento"; break;
        case 'Approved' : text = "Aprovada"; break;
        case 'Denied' : text = "Negada"; break;
        case 'RequestError' : text = "Não criada"; break;
        default: break;
      }
      return text;
    },
    getStatusColor(value: string): string {
      let color = "";
      switch(value) {
        case 'Processing' : color = "yellow"; break;
        case 'Approved' : color = "success"; break;
        case 'Denied' : color = "error"; break;
        case 'RequestError' : color = "orange"; break;
        default: break;
      }
      return color;
    },
    openInvoiceDetails(invoice: {}) {
      this.invoiceDialog.invoice = invoice;
      this.invoiceDialog.enabled = true;
    },
    onNewInvoice(newInvoice: any, oldInvoiceUuid: string) {
      this.invoiceDialog.invoice = newInvoice;
      let invoiceToReplace = this.invoices.find(
        (t) => t.id === oldInvoiceUuid
      );
      if (invoiceToReplace) {
        invoiceToReplace = newInvoice;
      }
    }
  }

})
