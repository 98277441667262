export default{
    dialogProductsUnusedTitle: 'Products without movements',
    dialogProductsUnusedCloseButton: 'Close',
    dialogProductsUnusedOpenConfirmDialog: 'Remove Products',
    dialogProductsUnusedOpenConfirmDialogWithItems: 'Remove selected products',
    dialogProductsUnusedFilter30Days: '30 days',
    dialogProductsUnusedFilter60Days: '60 days',
    dialogProductsUnusedFilter90Days: '90 days',
    dialogProductsUnusedFilterCustom: 'Custom',
    dialogProductsUnusedCodeProduct: 'Code Product',
    dialogProductsUnusedDescription: 'Description',
    dialogProductsUnusedTablePlanogramId: 'Planogram Id',
    dialogProductsUnusedLastMovement: 'Last Movement',
    dialogProductsUnusedQuantity:'Qty.',
    dialogProductsUnusedNoMovement:'No movements',
    dialogProductsUnusedRemoveSelectedItems: 'Select items to remove',
    dialogProductsUnusedErrorMensage: 'Error to load products without movements',
    dialogProductsUnusedHelpTitle: 'Products without movements',
    dialogProductsUnusedIntroParagraphHelp: 'In this screen, you can list the products that have not had movements in different time intervals. The predefined options are:',
    dialogProductsUnusedFilterPeriod:'Filters of 30, 60 and 90 days: lists only products that have not had movements from 30, 60 or 90 days before today, according to the selected filter.',
    dialogProductsUnusedFilterPeriodSingleDate: 'If you prefer to select a different period than the ones mentioned above, just click on the calendar icon and choose the desired date to customize the time interval according to your needs.',
    dialogProductsUnusedFilterPeriodDescription: 'You can also remove products from the planogram without movements. Check "Select items to remove", choose the products and click on the red button "remove products" to remove from the planogram.',
};