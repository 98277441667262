export default {
    January: 'Janeiro',
    Jan: 'Jan',
    February: 'Fevereiro',
    Feb: 'Fev',
    March: 'Março',
    Mar: 'Mar',
    April: 'Abril',
    Apr: 'Abr',
    May: 'Maio',
    June: 'Junho',
    Jun: 'Jun',
    July: 'Julho',
    Jul: 'Jul',
    August: 'Agosto',
    Aug: 'Ago',
    September: 'Setembro',
    Sep: 'Set',
    October: 'Outubro',
    Oct: 'Out',
    November: 'Novembro',
    Nov: 'Nov',
    December: 'Dezembro',
    Dec: 'Dez',
}