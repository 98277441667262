
import Vue from 'vue';
import {CashlessOperations , CashlessOperationsFilters } from '@/types/Cashless'; 
import agent from '@/api/agent'; 
import { shouldRefreshData } from '@/components/TableOptionsComparator';
import { cashlessOperationTypes } from '@/constants/CashlessOperation';
import PrivateLabelOperationsFilter from './components/PrivateLabelOperationsFilter.vue';

export default Vue.extend({
  components: {
    PrivateLabelOperationsFilter
  },
  data() {
    return {
      cashlessOperations: [] as CashlessOperations[],
      headers: [
        { text: 'Cartão', value: 'cardNumber', align: 'left', sortable: true },
        { text: 'Consumidor', value: 'customerName', align: 'left', sortable: true },
        //{ text: 'Cliente', value: 'posCustomerName', align: 'left', sortable: false },
        { text: 'Tipo de operação', value: 'type', align: 'left', sortable: true },
        { text: 'Valor de transação (R$)', value: 'initialCredits', align: 'left', sortable: true },
        { text: 'Valor final (R$)', value: 'finalCredits', align: 'left', sortable: true },
        { text: 'Data/Hora', value: 'date', align: 'left', sortable: true },
      ],
      loading: {
        table: true,
      },
      params: {},
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true],
        mustSort: true,
      },
      cashlessOperationTypes,
      form: {} as CashlessOperationsFilters ,
    }
  },
  mounted() {
    this.getCashlessOperations();
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getCashlessOperations();
        }
      },
      deep: true
    },
  },
  methods: {
    getCashlessOperations() {
      this.loading.table = true;
      this.params = {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
        page: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortOrder: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        pointOfSaleIds: this.form.pointOfSaleIds ?? [],
        cardNumber: this.form.cardNumber,
        consumerIds: this.form.consumerIds ?? [],
        types: this.form.types ?? [],
      }
      agent.CashlessTransactions.getCashlessOperations(this.params)
        .then((response) => {
          this.loading.table = false;
          this.cashlessOperations = response.items;
          this.totalItems = response.totalItems;
        })
    },
    updateFilters(form: any) {
      this.form = form;
      this.getCashlessOperations();
    },
  }
})
