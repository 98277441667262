export default {
    consultationTitleInvalidationsDialog: 'Consulta de anulaciones',
    noItemsInvalidationsDialog: 'No hay elementos registrados',
    cnpjInvalidationsDialog: 'CNPJ',
    seriesInvalidationsDialog: 'Serie',
    initialNumberInvalidationsDialog: 'Número inicial',
    finalNumberInvalidationsDialog: 'Número final',
    statusInvalidationsDialog: 'Estado',
    reasonInvalidationsDialog: 'Motivo',
    justificationInvalidationsDialog: 'Justificación',
    sefazProtocolInvalidationsDialog: 'Protocolo Sefaz',
    createdDateInvalidationsDialog: 'Creada en',
    updatedDateInvalidationsDialog: 'Actualizada en',
    processingStatusInvalidationsDialog: 'En procesamiento',
    approvedStatusInvalidationsDialog: 'Aprobada',
    deniedStatusInvalidationsDialog: 'Rechazada',
    requestErrorStatusInvalidationsDialog: 'No creada',
    unknownProblemInvalidationsDialog: 'Problema desconocido'
}
