export default {
    dialogTitleRebootDialog: 'Selecione a opção de reinício',
    forcedRestart: '<b> Reinício forçado: </b> o terminal será reiniciado imediatamente, interrompendo todas as ações que estão sendo executadas no momento.',
    scheduledRestart: '<br><b> Reinício agendado: </b> o terminal será reiniciado apenas se estiver na tela incial e não estiver realizando nenhuma operação.',
    warningRebootDialog: 'Essa função está disponível apenas para Moderninhas da PagSeguro. O reinício agendado só funciona corretamente a partir da versão 4.1.0 do app do terminal.',
    forcedRestartButton: 'Reinício forçado',
    scheduledRestartButton: 'Reinício agendado',
    rebootTerminalSuccessMessage: 'Reinício enviado para o terminal.',
    rebootTerminalErrorMessage: 'Não foi possível reiniciar o terminal, tente novamente mais tarde.',
  };
  