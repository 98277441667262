<template>
  <v-expansion-panels v-model="expansionIndex" hover>
    <v-expansion-panel>

      <v-expansion-panel-header>
        <template #default="{ open }">
          <v-row no-gutters>
            <v-col cols="2">Filtrar</v-col>

            <v-col cols="10" class="text--secondary">
              <span v-if="!open">
                Período: <strong>{{ formattedMinDate }} - {{ formattedMaxDate }}</strong>,
                Cliente: <strong>{{ (customers.find(c => c.id === form.customerId)||{}).name||"Todos" }}</strong>,
                Local: <strong>{{ (locations.find(l => l.id === form.localId)||{}).name||"Todos" }}</strong>,
                PDV: <strong>{{ (pointsOfSale.find(p => p.value === form.pointOfSaleId)||{}).text||"Todos" }}</strong>
                <!-- Status: <strong>{{ (status.find(s => s.id === form.status)||{}).text||"Todos" }}</strong> -->
              </span>
            </v-col>

          </v-row>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class='pb-2'>
        <v-form ref="form" v-model="valid" @submit.prevent="getTransactions()">
          <v-layout row wrap>

            <v-flex sm12 md3 class="search-field">

              <v-flex sm12 class="search-field">
                <v-menu class="pr-2" :close-on-content-click="true" v-model="hasMinDate" transition="scale-transition"
                    offset-y :nudge-right="40" max-width="290px" min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field v-on="on" label="Data inicial" :rules="[rules.required]" required
                      v-model="formattedMinDate" prepend-icon="date_range" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="form.minDate" no-title scrollable actions locale="pt-br"
                                :allowed-dates="getAllowedMinDates">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMinDate">Limpar</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex sm12 class="search-field">
                <v-menu :close-on-content-click="true" v-model="hasMaxDate" transition="scale-transition"
                    offset-y :nudge-right="40" max-width="290px" min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field v-on="on" label="Data final" :rules="[rules.required]" required
                      v-model="formattedMaxDate" prepend-icon="event_available" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="form.maxDate" no-title scrollable actions 
                    locale="pt-br" :allowed-dates="getAllowedMaxDates">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="clearMaxDate">Limpar</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-menu>
              </v-flex>

            </v-flex>

            <v-flex sm12 md3 class="search-field">

              <v-flex sm12 class="search-field">
                <v-autocomplete 
                  prepend-icon="account_box" 
                  :items="customers" 
                  v-model="form.customerId" 
                  label="Cliente"
                  item-text="name"
                  item-value="id"
                  single-line 
                  clearable>
                </v-autocomplete>
              </v-flex>

              <v-flex sm12 class="search-field">
                <v-autocomplete 
                  prepend-icon="location_on"
                  :disabled="form.customerId === null || loading.locals"
                  :hint="form.customerId === null ? 'Selecione antes um cliente' : ''"
                  :items="locations" 
                  v-model="form.localId"
                  :loading="loading.locals"
                  item-text="name"
                  item-value="id"
                  label="Localização"
                  single-line
                  persistent-hint
                  clearable>
                </v-autocomplete>
              </v-flex>

            </v-flex>

            <v-flex sm12 md3 class="search-field">

              <v-flex sm12 class="search-field">
                <v-autocomplete 
                  prepend-icon="business" 
                  :disabled="form.localId === null || loading.pointsOfSale"
                  :hint="form.localId === null ? 'Selecione antes uma localização' : ''"
                  :items="pointsOfSale" 
                  v-model="form.pointOfSaleId"
                  :loading="loading.pointsOfSale"
                  label="Ponto de venda"
                  single-line 
                  persistent-hint
                  clearable>
                </v-autocomplete>
              </v-flex>

            </v-flex>

            <v-flex sm12 md3 class="search-field">
              
              <v-flex sm12 class="search-field">
                <v-select
                  prepend-icon="mdi-list-status"
                  v-bind:items="status" 
                  v-model="form.status" 
                  label="Status"
                  hint="Selecione status para vizualização"
                  multiple
                  chips
                  clearable
                >
                  <template #selection="{ item }">
                    <v-chip small :color="getColor(item.value)" dark>{{item.text}}</v-chip>
                  </template>
                
                </v-select>
              </v-flex>


            </v-flex>

          </v-layout>
          <v-row sm12 class="search-field" justify="end">
            <download-button
              url="api/Orders/excel"
              filename="Encomendas.xlsx"
              :query-params="form"
              #default="{ loading, download }"
            >
              <v-btn
                color="info"
                class="mt-3 mr-2"
                @click.native="download" 
                :loading="loading"
              >
                <v-icon left>mdi-file-excel</v-icon>Excel
              </v-btn>
            </download-button>
            <v-btn class="mt-3" color="success" :disabled="!valid" v-on:click.native="emitModification">
              <v-icon left>refresh</v-icon>Atualizar
            </v-btn>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import DownloadButton from '../../components/DownloadButton.vue';

export default {
  components: {
    DownloadButton
  },
  data(){
    return {
      valid: false,
      hasMinDate: null,
      hasMaxDate: null,
      customers: [],
      locations: [],
      users: [],
      pointsOfSale: [],
      status: [
        {text: 'Pendente', value: 'Opened'},
        {text: 'Confirmado', value: 'Confirmed'},
        {text: 'Disponível para retirar', value: 'Available'},
        {text: 'Retirado', value: 'Purchased'},
        {text: 'Cancelado', value: 'Cancelled'}
      ],
      expansionIndex: 0,
      form: {
        minDate: null,
        maxDate: null,
        customerId: null,
        localId: null,
        userId: null,
        pointOfSaleId: null,
        status: null
      },
      loading: {
        locals: false,
        pointsOfSale: false,
      },
      rules:{
          required: (v) => !!v || 'Este campo é obrigatório.',
      }
    }
  },
  computed: {
    formattedMinDate: function(){
      return this.formatDate(this.form.minDate);
    },
    formattedMaxDate: function(){
      return this.formatDate(this.form.maxDate);
    }
  },
  watch: {
    'form.customerId': function(newId) {
      if(newId !== null){
        this.loading.locals = true;
        this.axios.get('api/Customers/' + newId)
          .then((response) => {
            this.loading.locals = false;
            this.locations = response.data.locals;
          });
      }
      else {
        this.form.localId = null;
      }
    },
    'form.localId': function(newId) {
      if(newId != null){
        this.loading.pointsOfSale = true;
        this.axios.get('api/Locals/' + newId + '/PointsOfSale')
          .then((response) => {
            this.loading.pointsOfSale = false;
            this.pointsOfSale = response.data.map((p) => ({ text: p.id + (p.specificLocation !== null ? ' / ' + p.specificLocation : ''), value: p.id }));
          });
      }
      else{
        this.form.pointOfSaleId = null;
      }
    },
  },
  mounted(){
    const preSelectedStatus = [
      'Opened',
      'Confirmed',
      'Available'
    ]
    this.form.status = preSelectedStatus
    const maxDate = new Date();
    const minDate = new Date(maxDate.getTime());
    minDate.setDate(minDate.getDate() - 30);
    this.form.maxDate = moment(maxDate).format('YYYY-MM-DD');
    this.form.minDate = moment(minDate).format('YYYY-MM-DD');
    this.$emit('modify', this.form);
    this.axios.get('api/Customers/Active')
      .then((response) => {
        this.customers = response.data
      });
  },
  methods: {
    getColor (status) {
      if (status == 'Opened') return 'orange'
      else if (status == 'Confirmed') return 'green'
      else if (status == 'Cancelled') return 'red lighten-3'
      else if (status == 'Available') return 'blue'
      else return 'grey lighten-1'
    },
    emitModification(){
      if(this.$refs.form.validate()){
        this.$emit('modify', this.form);
        this.expansionIndex = undefined;
      }
    },
    newForm() {
      return {
        minDate: this.form.minDate,
        maxDate: this.form.maxDate,
      };
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    clearMinDate() {
      this.form.minDate = null;
      this.formattedMinDate = null;
    },
    clearMaxDate() {
      this.form.maxDate = null;
      this.formattedMaxDate = null;
    },
    getAllowedMinDates(minDate) {
      if (this.form.maxDate !== null) {
        return this.isDateRangeAllowed(new Date(minDate), new Date(this.form.maxDate));
      }
      return true;
    },
    getAllowedMaxDates(maxDate) {
      if (this.form.minDate !== null) {
        return this.isDateRangeAllowed(new Date(this.form.minDate), new Date(maxDate));
      }
      return true;
    },
    isDateRangeAllowed(min, max){
      return max >= min && Math.round(max - min)/(1000*60*60*24) < 90;
    }
  },
}
</script>