<template>
  <v-menu v-model="saveReportMenu" offset-y style="max-width: 600px" :close-on-content-click="false">
    <template #activator="{ on: dialog }">
      <v-btn v-on="dialog" class="mx-2" text>
        <v-icon left>mdi-star</v-icon> {{ $t('savedFiltersSaveReportMenu') }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click.stop="saveReportDialog = true">
        <v-list-item-action>
          <v-icon large>mdi-star-plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ $t('saveFiltersSaveReportMenu') }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0" expand-icon="">
              <template>
                <div align="left" class="nobr">
                <v-icon large class="pr-7">mdi-star</v-icon> {{ $t('savedFiltersSaveReportMenu') }}
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-select
                  :label="$t('savedFiltersSaveReportMenu')"
                  v-model="selectedReport"
                  return-object
                  :items="savedReports"
                  item-text="description"
                  :loading="loading.savedReports"
                  clearable
                >
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.description }}</v-list-item-title>
                    </v-list-item-content>
                    <v-tooltip left :disabled="data.item.userId != null ">
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            color="error"
                            text
                            :disabled="data.item.userId == null"
                            @click.native.capture.stop="removeSavedReport(data.item)">
                            <v-icon>remove_circle_outline</v-icon>
                          </v-btn>
                        </div>
                      </template> 
                      <span>{{ $t('onlyUserFiltersSaveReportMenu') }}</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-list-item>
    </v-list>
    <v-dialog v-model="saveReportDialog" max-width="500px">
      <v-form ref="form" lazy-validation @submit.prevent="saveFilters">
        <v-card max-width="500px">
          <v-card-title>{{ $t('titleSaveReportMenu') }}</v-card-title>
          <v-card-text>
            <v-row class="mx-0 py-2">
              <v-text-field
                v-model="newReportDescription"
                :label="$t('reportNameSaveReportMenu')"
                :rules="[rules.required, rules.nonEmpty]"
                required
              >
              </v-text-field>
            </v-row>
            <v-row cols="12">
              <v-col cols="6" v-if="filtersList.length > 0">
                <h3>{{ $t('filtersSaveReportMenu') }}</h3>
                <div v-for="(filter, i) in filtersList" :key="i">
                  {{ returnFilterName(filter.filterName) }}: {{ filter.filterValue }}
                </div>
              </v-col>
              <v-col cols="6" v-if="groups.length > 0">
                <h3>{{ $t('groupingsSaveReportMenu') }}</h3>
                <div v-for="(group, i) in groups" :key="i">
                  {{ i + 1 }}. {{ returnGroupName(group) }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="saveReportDialog = false; newReportDescription = ''">{{ $t('cancelSaveReportMenu') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="!valid" :loading="loading.saveFilter">{{ $t('saveSaveReportMenu') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <feedback ref="feedback"></feedback>
    </v-dialog>
  </v-menu>
</template>

<script>
import FilterNamesTranslations from './FilterNamesTranslations';

export default {
  props: {
    value: { type: Boolean, required: true },
    // filtersList: { type: Array, default: () => [] },
    form: { type: Object, default: () => {} },
    definedDateInterval: { type: Number, default: null },
    groups: { type: Array, default: () => {} },
    type: { type: String, default: "Transactions" },
    selectedSavedReport: { type: Object, default: () => {} }
  },
  mounted() {
    this.getSavedReports();
  },
  computed: {
    saveReportMenu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('window', value);
      }
    },
    selectedReport: {
      get() {
        return this.selectedSavedReport;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    // The Object below (filtersList) will be sent to the back end.
    // It has to be a list<(filtername: key, filterValue: value)>, value != null, for every filter.
    // It will *NOT* have the group[] values.
    // It will *NOT* have minDate either maxDate.
    // It *WILL* have dateInterval, if it is != null.
    filtersList: function() {
      let list = Object.entries(this.form);   
      list.splice(list.findIndex(i => i[0] == "minDate"), 1); // Removes minDate 
      list.splice(list.findIndex(i => i[0] == "maxDate"), 1); // Removes maxDate
      list.splice(list.findIndex(i => i[0] == "group"), 1);   // Removes group[]
      if (this.definedDateInterval !== null) list.push(["dateInterval", this.definedDateInterval]);
      
      // The function below filters the null filters
      function pairValueIsNull(element) {
        return element[1] === null || element[1] === "" || element[1].length === 0;
      }

      // The function below converts the Array[[]] into an Array[object{"filterName", "filterValue"}]
      function convertArrayToObject(pair) {   
        return { filterName: pair[0], filterValue: JSON.stringify(pair[1]) }
      }
      list = list.filter(i => !pairValueIsNull(i));         // Filter null filters
      let finalList = list.map(pair => convertArrayToObject(pair));     // Convert array(array) to array(object)
      return finalList;
    }    
  },
  data() {
    return {
      newReportDescription: "",
      filterNames: FilterNamesTranslations.filterNames,
      valid: true,
      rules: {
        required: (v) => !!v || this.$t('requiredRules'),
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
      },
      loading: {
        saveFilter: false,
        savedReports: true,
      },
      groupNames: [
        { text: this.$t('productSaveReportMenu'), value: "product" },
        { text: this.$t('categorySaveReportMenu'), value: "category" },
        { text: this.$t('stockSaveReportMenu'), value: "inventory" },
        { text: this.$t('operationSaveReportMenu'), value: "type" },
        { text: this.$t('daySaveReportMenu'), value: "day" },
        { text: this.$t('monthSaveReportMenu'), value: "month" },
        { text: this.$t('dayOfTheWeekSaveReportMenu'), value: "dayOfWeek" },
        { text: this.$t('customersSaveReportMenu'), value: "customerName" },
        { text: this.$t('pointsOfSaleSaveReportMenu'), value: "pointOfSaleId" },
        { text: this.$t('statesSaveReportMenu'), value: "addressState" },
        { text: this.$t('paymentMethodsSaveReportMenu'), value: "paymentMethod" },
        { text: this.$t('cardFlagsSaveReportMenu'), value: "cardBrand" },
      ],
      saveReportDialog: false,
      savedReports: [],
    }
  },
  methods: {
    saveFilters() {
      if(this.$refs.form.validate()) {
        this.loading.saveFilter = true;
        let params = {
          id: 0,
          description: this.newReportDescription,
          reportType: this.type,
          groupColumns: this.groups,
          savedReportFilters: this.filtersList
        }
        this.axios.post("/api/SavedReports", params)
          .then((response) => {
            this.loading.saveFilter = false;
            this.$emit('filterAdded', `${this.$t('filterSaveReportMenu')} '${response.data.description}' ${this.$t('savedSuccessfullySaveReportMenu')}`);
            this.savedReports.push(response.data);
            this.saveReportMenu = false;
            this.newReportDescription = null;
          })
          .catch((error) => {
            this.$refs.feedback.handleError(this.$t('errorWhenSavingFiltersSaveReportMenu'), error);
            this.loading.saveFilter = false;
          });
      }
    },
    returnFilterName(filter) {
      const pair = this.filterNames.find(pair => pair.value == filter);
      return pair?.name || null;
    },
    returnGroupName(group) {
      const pair = this.groupNames.find(pair => pair.value == group);
      return pair?.text;
    },
    getSavedReports() {
      this.loading.savedReports = true;
      this.axios.get(`/api/SavedReports/${this.type}`)
        .then((response) => {
          this.savedReports = response.data;
        })
        .catch(() => {})
        .then(() => this.loading.savedReports = false);
    },
    removeSavedReport(item) {
      let confirmed = confirm(`${this.$t('deleteConfirmationSaveReportMenu')} '${item.description}'?`);
      if (confirmed == false) return;
      const id = item.id;
      this.axios.delete(`/api/SavedReports/${id}`)
        .then(() => {
          this.savedReports = this.savedReports.filter(sr => sr.id != id);
        })
        .catch((error) => {
          if(error.response && error.response.data) error = error.response.data;
          const message = this.$t('unableToRemoveSaveReportMenu') + (error == null ? '' : (': ' + error));
          alert(message);
        });
    },
  }
}
</script>

<style>
.nobr { white-space: nowrap;
        font-size: 16px; }
</style>