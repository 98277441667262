import { render, staticRenderFns } from "./DetailXmlDialog.vue?vue&type=template&id=70cc4a87&scoped=true&"
import script from "./DetailXmlDialog.vue?vue&type=script&lang=ts&"
export * from "./DetailXmlDialog.vue?vue&type=script&lang=ts&"
import style0 from "./DetailXmlDialog.vue?vue&type=style&index=0&id=70cc4a87&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70cc4a87",
  null
  
)

export default component.exports