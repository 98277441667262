// AcquisitionOrderApprovalTableDto.ts
export type AcquisitionOrderApprovalTableDto= {
  id: number;
  fantasyName: string;
  documentId: string;
  email: string;
  phoneNumber: string;
  dateCreated: Date;
  taxArrangement: TaxArrangement;
  enableInvoiceIssuance: boolean;
  status: OrderStatus;
  itensDetails: ItensDetailsDto[];
  comments: string|null;
}

// ItensDetailsDto.ts
export type ItensDetailsDto ={
  imageUrl: string;
  name: string;
  quantity: number;
  description: string;
  itemType: ItemType;
}

// TaxArrangement enum
export enum TaxArrangement {
  PessoaFisica = 0,
  SimplesNacional = 1,
  Mei = 2,
  Other = 3,
  NotSpecified = 4,
  ErrorOnCheck = 5,
}

// AcquisitionOrderStatus enum
export enum OrderStatus {
  Pending = 0,
  Approved = 1,
  Cancelled = 2
}

// AcquisitionOrderApprovalTableFilterDto.ts
export type AcquisitionOrderApprovalTableFilterDto = {
  tenantId: number;
}

export enum ItemType {
  Leased = 0,
  Owned = 1
}

export type AcquisitionOrderUpdateStatusdDto = {
  id: number;
  status: OrderStatus | null; //It can be null when the status is not changed, when the user only wants to add comments
  comments: string;
}
