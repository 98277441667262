export default {
    titleInvoiceManual: 'INFORMAÇÕES DA NOTA FISCAL',
    infoInvoiceManual: 'Preencha com os dados da nota',
    numberInvoiceManual: 'Número da Nota',
    keyInvoiceManual: 'Chave de acesso (NFe)',
    dateEmmitedInvoiceManual: 'Data de emissão',
    closeInvoiceManual: 'FECHAR',
    nextInvoiceManual: 'PRÓXIMO',
    validMessageInvoiceManual: 'Para prosseguir preencha todos os dados',
    invoiceManualHelpTitle: 'Informações da nota',
    invoiceManualHelp: 'Preencha com os dados da nota fiscal, número da nota, chave de acesso (NFe) e data de emissão.',
    invoiceManualHelpNumber: 'Número da Nota:',
    invoiceManualHelpNumberText: 'Representa o número da nota fiscal.',
    invoiceManualHelpKey: 'Chave de acesso (NFe):',
    invoiceManualHelpKeyText: 'Representa a chave de acesso da nota fiscal. Nosso sistema nao permite subir duas notas com a mesma chave de acesso .',
    invoiceManualHelpDate: 'Data de emissão:',
    invoiceManualHelpDateText: 'Representa a data de emissão da nota fiscal.',
}