<template>
  <v-dialog v-model="dialog" width="unset" @keydown.esc="dialog = false">
    <v-card v-if="pickList != null">
      <v-card-title class="blue-grey lighten-5">
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <div class="title">{{pickList.pointOfSale.customerName}}</div>
          <div class="info-subtitle">
            {{pickList.pointOfSale.localName}} / {{pickList.pointOfSale.specificLocation}}<br>
            {{pickList.pointOfSale.machineModelName}} / {{pickList.pointOfSale.machineManufacturerName}}
          </div>
        </div>
        <div class="ml-2">
          <v-btn text small outlined :disabled="selected.length == 0" @click.native="emptySelected">
            {{ $t('resetSelectedPickListDetails') }}
          </v-btn>
          <v-switch class="mt-2" hide-details v-model="showItemsWithZero" :label="$t('showZeroPickListDetails')"></v-switch>
        </div>
        <div class="ml-2 my-2">
          <v-text-field
            class="pt-0"
            v-model="search.text"
            append-icon="search"
            :label="$t('searchProductPickListDetails')"
            single-line
            hide-details
            clearable
            filled
            rounded
            dense
            @click:clear="search.text = ''"
            @click:append="search"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <div class="text-right">
          <div class="info-subtitle">
            <span v-if="$vuetify.breakpoint.smAndDown">
              <strong>{{ pickList.pointOfSale.customerName }}</strong><br>
              {{pickList.pointOfSale.localName}} / {{pickList.pointOfSale.specificLocation}}
            </span><br>
              {{ $t('pickListInfoPickListDetails') }} {{pickList.id}} -
            <span :class="getStatusClass(pickList.status)">{{translateStatus(pickList.status)}}</span><br>
              {{ $t('pickListInfoPosDetails') }} {{pickList.pointOfSale.id}} / {{ $t('pickListInfoPlanogramDetails') }} {{pickList.planogramId}}<br>
              {{ $t('pickListInfoCreatedDetails') }} {{formatDate(pickList.dateCreated)}}<br>
              {{ $t('pickListInfoUpdatedDetails') }} {{formatDate(pickList.dateUpdated)}}
          </div>
        </div>
        <v-btn icon class="ml-2" @click.native="dialog = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>

        <v-data-table
          v-model="selected"
          item-key="productId"
          :headers="filteredHeaders" 
          :items="visibleItems" 
          :loading="loading.table"
          show-select
          hide-default-footer
          disable-pagination
          sort-by="quantity"
          sort-desc
          must-sort
          @click:row="(item, props) => props.select(!props.isSelected)"
          :search="search.text"
          :item-class="itemRowBackground"
          fixed-header
          :height="fixedHeaderHeight"
        >
          <template #item.quantity="{ item }">
            <v-edit-dialog
              v-if="allowEdit"
              large
              :cancel-text="$t('cancelPickListDetails')"
              :save-text="$t('toSavePickListDetails')"
              @open="openQuantityText(item)"
              @save="saveQuantityText(item)"
              @cancel="cancelQuantityText"
            >{{item.quantity.toString() + (item.isEdited ? '*' : '')}}
              <v-icon class="pb-1 pl-1">edit</v-icon>

              <template #input>
                <v-text-field
                  v-model="editedQuantity"
                  :label="$t('quantityPickListDetails')"
                  :rules="[rules.integer]"
                  single-line
                ></v-text-field>
              </template>

            </v-edit-dialog>
            <template v-else>
              {{item.quantity}}
            </template>
          </template>

        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="dialog = false">{{ $t('closePickListDetails') }}</v-btn>
        </v-card-actions>

    </v-card>

    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: { type: Boolean, required: true },
    id: { type: Number, required: true },
    allowEdit: { type: Boolean, default: false },
    showCurrentData: { type: Boolean, default: false }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    visibleItems() {
      return this.showItemsWithZero ? this.pickList.items : 
        this.pickList.items.filter(i => i.quantity > 0);
    },
    hasInventory() {
      return ((this.pickList||{}).items||[]).some((i) => i.productInventoryQuantity != null);
    },
    filteredHeaders() {
      return this.showCurrentData ? 
        this.headers.filter((h) => h.value !== 'productInventoryQuantity' || this.hasInventory) :
        this.headers.filter((h) => !h.value.startsWith('planogramEntry') && h.value !== 'productInventoryQuantity');
    },
    fixedHeaderHeight() {
      const headerHeight = 48;
      const rowHeight = 48;
      const minHeight = 100;
      const mobileHeight = 300;
      const rows = this.visibleItems.length;

      if (rows === 0) {
        return minHeight;
      }
      if(this.$vuetify.breakpoint.smAndDown) {
        return mobileHeight;
      }

      return Math.min(
        headerHeight + rowHeight * rows,
        window.innerHeight - 300 
      ) 
    }
  },
  watch: {
    dialog: function(enabled) {
      if (enabled) {
        this.getItems();
      }
      else {
        this.items = [];
      }
    },
  },
  data() {
    return {
      pickList: null,
      selected: [],
      showItemsWithZero: false,
      headers: [
        { text: this.$t('productCodePickListDetails'), align: 'left', sortable: true, value: 'productCode' },
        { text: this.$t('productNamePickListDetails'), align: 'left', sortable: true, value: 'productDescription' },
        { text: this.$t('productBarCodePickListDetails'), align: 'left', sortable: true, value: 'productBarCode' },
        { text: this.$t('categoryPickListDetails'), align: 'left', sortable: true, value: 'productCategoryName' },
        { text: this.$t('quantityPickListDetails'), align: 'left', sortable: true, value: 'quantity' },
        { text: this.$t('currentQuantityPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.currentQuantity' },
        { text: this.$t('desiredQuantityPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.quantityToSupply' },
        { text: this.$t('criticalMinimumPickListDetails'), align: 'left', sortable: true, value: 'planogramEntry.minimumQuantity' },
        { text: this.$t('salesThisWeekPickListDetails'), align: 'left', sortable: true, value: 'count' },
        { text: this.$t('centralStockQuantity'), align: 'left', sortable: true, value: 'productInventoryQuantity' }
      ],
      loading: {
        table: true,
      },
      editedQuantity: 0,
      rules :{
        integer: (v) => /^\d+$/.test(v) || this.$t('integerRulesPositive')
      },
      search: {
        text: '',
        valid: true
      }
    }
  },
  methods: {
    getItems() {
      if (this.id == null || this.id === 0) return;
      this.loading.table = true;
      this.axios.get(`api/PickLists/${this.id}`)
        .then((response) => {
          this.loading.table = false;
          this.pickList = response.data;
        })
    },
    openQuantityText(item) {
      this.editedQuantity = item.quantity;
    },
    saveQuantityText(item) {
      const quantityToSave = this.editedQuantity;
      if (typeof this.rules.integer(quantityToSave) === 'string') {
        this.editedQuantity = 0;
        this.$refs.feedback.handleError(this.$t('quantityNotChangedPickListDetails'));
        return;
      }
      this.loading.table = true;
      this.axios.put(`api/PickLists/${this.id}/Quantity/${quantityToSave}`, [item.productId])
        .then(() => {
          item.quantity = quantityToSave;
          item.isEdited = true;
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('changeErrorPickListDetails'), error);
        }).then(() => {
          this.loading.table = false;
          this.editedQuantity = 0;
        })
    },
    emptySelected() {
      const itemsToEmpty = this.selected.map(i => i.productId);
      this.loading.table = true;
      this.axios.put(`api/PickLists/${this.id}/Quantity/0`, itemsToEmpty)
        .then(() => {
          this.selected = [];
          this.getItems();
        }).catch((error) => {
          this.$refs.feedback.handleError(this.$t('emptySelectedError'), error);
          this.loading.table = false;
        })
    },
    cancelQuantityText() {
      this.editedQuantity = 0;
    },
    translateStatus(status) {
      if (status === 'Pending') return this.$t('pendingPickListDetails');
      if (status === 'Used') return this.$t('usedPickListDetails');
    },
    getStatusClass(status) {
      if (status === 'Pending') return 'orange--text text--darken-2';
      if (status === 'Used') return 'green--text text--darken-2';
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    itemRowBackground: function (item) {
     if (this.hasInventory && item.productInventoryQuantity < item.quantity) return 'red lighten-4'
    },

  }
}
</script>