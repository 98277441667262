
import Vue from 'vue';
import ProductsFilter from '@/components/filters/ProductsFilter.vue';
import agent from "@/api/agent";

export default Vue.extend({
  components: {ProductsFilter},
  props: {
    priceTableId: {type: Number, required: true},
    isMarkup: {type: Boolean, required: true}
  },
  data: function () {
    return {
      form: {
        productId: null,
        cost: null,
        markup: null,
        price: null,
      },
      valid: false,
      creating: false,
      translations: {
        data: {
          cost: this.$t('priceTable.data.cost').toString(),
          markUp: this.$t('priceTable.data.markUp').toString(),
          price: this.$t('priceTable.data.price').toString(),
        },
        button: {
          cancel: this.$t('buttons.cancel').toString(),
          add: this.$t('buttons.add').toString(),
        },
        item: {
          response: {
            create: {
              success: this.$t('priceTable.item.response.create.success').toString(),
              error: this.$t('priceTable.item.response.create.error').toString()
            }
          }
        }
      },
    };
  },
  methods: {
    cancelCreateMode() {
      this.resetForm();
      this.$emit("cancel-create-mode");
    },
    changeProduct(product: any) {
      if (product != null) {
        this.form.price = product.defaultPrice;
      }
    },
    calculatePrice() {
      if (this.form.cost && this.form.markup) {
        this.form.price = Math.round(((this.form.cost! * this.form.markup!) + Number.EPSILON) * 100) / 100 as any;
      }
    },
    calculateMarkup() {
      if (this.form.price && this.form.cost) {
        this.form.markup = Math.round(((this.form.price as any / this.form.cost!) + Number.EPSILON) * 1000) / 1000 as any;
      }
    },
    // eslint-disable-next-line no-undef
    async createItem(bytes: BufferSource) {
      if ((this.$refs.form as any).validate(bytes)) {
        this.creating = true;
        await agent.PriceTables.createItem(this.priceTableId, this.form)
            .then(() => {
              this.$emit("success-creating-price", this.translations.item.response.create.success);
            })
            .catch((error) => {
              this.$emit("error-creating-price", this.translations.item.response.create.error, error);
            })
            .finally(() => {
              this.creating = false;
              this.resetForm();
            });
      }
    },
    resetForm() {
      (this.$refs.form as any).reset();
    }
  }
});
