
import Vue from 'vue';

export default Vue.extend({
  name: "CloseButton",

  props: {
    disabled: { type: Boolean, default: false },
    asTextButton: { type: Boolean, default: false },
    color: { type: String, default: 'error' },
  },
});
