export default {
    dialogTitlePushNotificationDialog: 'Enviar notificação push',
    subtitlePushNotificationDialog: 'A mensagem é enviada para todos os usuários cadastrados nesta loja',
    messageLabelPushNotificationDialog: 'Mensagem',
    imageSubtitlePushNotificationDialog: '* Para melhor visualização no aplicativo, anexe imagens quadradas.',
    defaultNotificationLabel: 'Notificação padrão',
    defaultNotificationHint: 'A notificação aparecerá para o usuário sempre que acessar o aplicativo.',
    expirationLabelPushNotificationDialog: 'Expira em',
    clearLabelPushNotificationDialog: 'Limpar',
    applyAllPosLabelPushNotificationDialog: 'Aplicar em todos PDVs',
    applySelectedPosLabel: 'Aplicar apenas nos PDVs',
    closeLabelPushNotificationDialog: 'Fechar',
    sendLabelPushNotificationDialog: 'Enviar',
    messageSentPushNotificationDialog: 'Mensagem enviada para ',
    usersOfPushNotificationDialog: ' usuários dos ',
    activePushNotificationDialog: ' ativos',
    activePushNotificationDialogError: ' ativos. ',
    notificationsNotSent: ' Algumas notificações não foram enviadas pelo motivo: ',
    feedbackErrorPushNotificationDialog: 'Erro ao enviar as notificações',
    requiredMessageOrImage: 'É necessário incluir uma mensagem ou imagem.',
    thereAreNoUsersWithPermission: 'Não há usuários com permissão para receber notificações.',
    getPointOfSaleItem: 'PDV',
    nonWhiteLabelPushCharge: 'Há uma cobrança de 10 centavos para cada cliente para o qual você envia uma notificação push. ' +
      'Essa cobrança não ocorre para apps white label.',
    noAppForSendingPush: 'Este PDV não possui o TouchPay Mobile habilitado. Habilite na página "Cadastro PDVs", ou selecione outro PDV.',
  };
