export default {
    searchCategory: 'Buscar categoría',
    noCategories: 'No hay categorías registradas',
    confirmDeleteCategory: '¿Estás seguro de que deseas eliminar la categoría?',
    cancelCategory: 'Cancelar',
    removeCategory: 'Eliminar',
    categoryName: 'Categoría',
    invalidCharacters: 'Ingrese caracteres válidos',
    categoryRemovedCategory: 'Categoría eliminada',
    errorRemovingCategory: 'Error al eliminar la categoría',
    categoryNameNotChanged: 'Nombre no cambiado: caracteres inválidos en el nombre',
    errorChangingName: 'Error al cambiar el nombre',
    myCategories: 'Mis Categorías',
    actionsCategory: 'Acciones',
    idCategory: 'ID',
    numberOfProductsCategory: 'Nº de productos',
};
