import i18n from "@/locales/i18n";

export default { 
  url: 'https://pagamento.amlabs.com.br',
  method: 'PUT',
  headers: { "Cache-Control": "max-age=31536000" },
  thumbnailMethod: 'contain',
  maxFiles: 1,
  maxFilesize: 2,
  autoProcessQueue: false,
  dictDefaultMessage: i18n.t('dictDefaultMessage'),
  dictFallbackMessage: i18n.t('dictFallbackMessage'),
  dictFileTooBig: i18n.t('dictFileTooBig'),
  dictInvalidFileType: i18n.t('dictInvalidFileType'),
  dictResponseError: i18n.t('dictResponseError'),
  dictCancelUpload: i18n.t('dictCancelUpload'), 
  dictUploadCanceled: i18n.t('dictUploadCanceled'),
  dictCancelUploadConfirmation:  i18n.t('dictCancelUploadConfirmation'),
  dictRemoveFile: i18n.t('dictRemoveFile'),
  dictMaxFilesExceeded: i18n.t('dictMaxFilesExceeded'),
  dictFileSizeUnits: { tb: "TB", gb: "GB", mb: "MB", kb: "KB", b: "b" },
  init: function() {
    this.on('addedfile', function() { // Display one photo preview at most
      if (this.files.length > 1) {
        this.removeFile(this.files[0]);
      }
    });
    this.on('thumbnail', function() { // Hide loading bar before photo is confirmed
      const elements = document.getElementsByClassName('dz-progress');
      [].forEach.call(elements, function(element) {
        element.style.visibility = 'hidden';
      });
    });
    this.on('processing', function (file) { // Put loading bar back when processing
      this.options.url = file.uploadURL;
      const elements = document.getElementsByClassName('dz-progress');
      [].forEach.call(elements, function (element) {
        element.style.visibility = 'visible';
      });
    });
  },
  sending(file, xhr) {
    const send = xhr.send;
    xhr.send = () => {
      send.call(xhr, file);
    }
  }
}