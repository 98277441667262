export default {
    removeConfirmationPosManagement: '¿Estás seguro de que deseas eliminar el punto de venta?',
    searchPosManagement: 'Buscar',
    newPosPosManagement: 'Nuevo PDV',
    idPosManagement: 'ID',
    paymentSystemPosManagement: 'Sistema de pago',
    posCodePosManagement: 'Código de PDV',
    modelManufacturerPosManagement: 'Modelo / Fabricante',
    customerPosManagement: 'Cliente',
    locationPosManagement: 'Ubicación',
    distributionCenterPosManagement: 'Centro de Distribución',
    actionsPosManagement: 'Acciones',
    incompletePosWarning: 'Hay pendientes en este PDV',
    deletePosManagement: 'Eliminar',
    noResultsPosManagement: 'Sin resultados',
    feedbackErrorPosManagement: 'No se pudo eliminar el PDV',
    noPosManagement: 'No',
    pointsOfSalePosManagement: 'Puntos de Venta',
    dateCreatedPosManagement:'Fecha de creación',
};
