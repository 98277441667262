
import { PickListExcelRequest, pickListColumnsFixed, pickListColumnsOptional, pickListItemsColumnsFixed, pickListItemsColumnsOptional} from '@/types/PickLists';
import agent from '@/api/agent';
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            dialogVisible: false as boolean,
            params: {} as PickListExcelRequest,
            models: {} as {
                pickListColumnsFixed: string[];
                pickListColumnsOptional: string[];
                pickListItemsColumnsFixed: string[];
                pickListItemsColumnsOptional: string[];
            },
            pickListColumnsFixed: pickListColumnsFixed,
            pickListColumnsOptional: pickListColumnsOptional,
            pickListItemsColumnsFixed: pickListItemsColumnsFixed,
            pickListItemsColumnsOptional: pickListItemsColumnsOptional,
            viewZeroQuantity: false,
        };
    },
    props: {
        value: { type: Boolean, required: true, default: false },
        inventoryId: { type: Array(), required: true, default: [] },
    },
    mounted() {
        this.models.pickListColumnsFixed = this.pickListColumnsFixed.map(column => column.value);
        this.models.pickListColumnsOptional = this.pickListColumnsOptional.map(column => column.value);
        this.models.pickListItemsColumnsFixed = this.pickListItemsColumnsFixed.map(column => column.value);
        this.models.pickListItemsColumnsOptional = this.pickListItemsColumnsOptional.map(column => column.value);
    },
    computed: {
        dialog: {
            get() : boolean {
                return this.value;
            },
            set(value: boolean) {
                (this as any).$emit('input', value);
            }
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        GetPickListsExcel(){
            let columns = this.insertFilters(this.models.pickListColumnsFixed, this.models.pickListColumnsOptional, 
            this.models.pickListItemsColumnsFixed, this.models.pickListItemsColumnsOptional);
            
            this.params = {
                ids: this.inventoryId,
                pickListColumns: columns.pickListColumn,
                pickListItemColumns: columns.pickListItemsColumn,
                viewZeroQuantity: this.viewZeroQuantity,
            };

            agent.PickLists.excel(this.params)
            .then(response => { 
                this.$emit('success', response);
            })
            .catch(error => {
                (this as any).$refs.feedback.handleError(this.$t('generateReportErrorPickListTable'), error);
            }).finally(() => {
                this.dialog = false;
            });
        },
        insertFilters(pickListColumnsFixed: string[], pickListColumnsOptional: string[], pickListItemsColumnsFixed: string[], pickListItemsColumnsOptional: string[]){
            let pickListColumn = pickListColumnsFixed.concat(pickListColumnsOptional);

            let pickListItemsColumn = pickListItemsColumnsFixed.concat(pickListItemsColumnsOptional)

            return ({pickListColumn, pickListItemsColumn});
        },
    },
});
