export default {
    lastSupplyTitle: 'Latest operations',
    lastSupplySubTitle:'Check the most recent operations',
    lastSupplyLabel: 'Operation type',
    lastSupplyColumnPos: 'POS Number',
    lastSupplyColumnCustomerLocation: 'Customer/Location',
    lastSupplyColumnOperationType: 'Operation type',
    lastSupplyColumnDate: 'Date',
    lastSupplyColumnLosses: 'Losses',
    lastSupplyTypeOperationsSupply: 'Supply',
    lastSupplyTypeOperationsInventory: 'Inventory',
    lastSupplyTypeOperationsPicking: 'Picking',
    lastSupplyTypeOperationsCombined: 'Combined',
    lastSupplyTypeOperationsFull: 'Complete',
    lastSupplyColumnUser: 'User',
    lastSupplyColumnDuration: 'Duration',
};