<template>
  <v-dialog
    v-model="show"
    width="400"
    @keydown.esc="show = false"
  >
    <template #activator="{ dialog }">
      <v-btn color="error" v-on="dialog" @click="show = true" :disabled="!removeItemsEnabled">
        <v-icon>mdi-pencil-remove</v-icon>{{ $t('removeItems') }}
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-form @submit.prevent="removeItem" ref="form" lazy-validation v-model="valid">
          <v-card-title>
            <span class="title">{{ $t('removeItemDialogTitle') }}</span>
          </v-card-title>
          <v-card-subtitle>
            <span v-html="$t('warningRemoveItem')" align="justify" class="subtitle red--text" style="font-size: smaller;" />
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <products-filter
                v-model="productId"
                :label="this.$t('productToRemoveLabel')"
                :required="true"
                :rules="[rules.required, rules.nonEmpty]"
              ></products-filter>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click.native="show = false"
            >{{ $t('dialogcloseButton') }}</v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              type="submit"
              :loading="loading"
            >{{ $t('dialogremoveButton') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import ProductsFilter from '../components/filters/ProductsFilter.vue'
export default {
  components: {
    ProductsFilter
  },
  props: {
    planogramIds: { type: Array, default: () => [] },
    removeItemsEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      show: false,
      valid: true,
      productId: null,
      loading: false,
      rules: {
        nonEmpty: (v) => /\S/.test(v) || this.$t('nonEmptyRules'),
        required: (v) => !!v || this.$t('requiredRules'),        
      }
    }
  },
  methods: {
    async removeItem() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const numberOfElements = 4;
        const chunkedPlanogramIds = this.partition(this.planogramIds, numberOfElements);
        const elementPercentage = 100 / chunkedPlanogramIds.length;

        this.$emit('updateProgressDialogEnable', true);
        this.progress = 0;

        const promiseRequests = [...Array(chunkedPlanogramIds.length).keys()]
          .map(x => 
            this.axios.delete('api/PlanogramEntries/bulk', { params: {
              planogramIds: chunkedPlanogramIds[x], productId: this.productId } })
              .catch(() => {
                return {
                  data: {
                    results: chunkedPlanogramIds[x].map(planogramId => {
                      return {
                        entityId: planogramId,
                        success: false,
                        error: `${this.$t('errorWhenRemovingProduct')} ${this.productId} ${this.$t('fromThePlanogram')} ${planogramId}. ${this.$t('removeItemDialogTryAgain')}`
                      }
                    })
                  }
                }
              })
              .finally(() => {
                this.progress += elementPercentage
                this.$emit('progress', this.progress);
              })
          );

        await Promise.allSettled(promiseRequests)
          .then(response => {
            const bulkResult = { results: null }
            bulkResult.results = response.map(x => x.value.data).flatMap(it => it.results);
            this.$emit('entry-deleted', bulkResult);
            this.show = false;
          }).finally(() => {
            this.loading = false
            this.$emit('progress', 100);
          })
      }
    },
    partition(array, n) {
      return array.length ? [array.splice(0, n)].concat(this.partition(array, n)) : [];
    },    
  }
}
</script>

<style>

</style>