export default {
    removeItemDialogTitle: 'Eliminar elemento de los planogramas',
    productToRemoveLabel: 'Producto a eliminar',
    dialogcloseButton: 'Cerrar',
    dialogremoveButton: 'Eliminar',
    removeItems: 'Elementos',
    errorWhenRemovingProduct: 'Error al eliminar el producto ',
    fromThePlanogram: ' del planograma',
    removeItemDialogTryAgain: ' Intente nuevamente.',
    warningRemoveItem: 'El producto será retirado <strong>solo</strong> de los puntos de venta seleccionados que tengan planogramas pendientes.'
};
