import i18n from "@/locales/i18n";

export type TenantFeedbackCategory = {
  neutralText: string;
  positiveText: string;
  negativeText: string;
  value: string;
};

export type TenantFeedbackForm = {
  webPage: string,
  grade: number,
  comment: string, 
  categories: string[],
  contactNumber: string | null
};

export type TenantFeedbackDto = {
  userId: string,
  webPage: string,
  grade: number,
  date: string,
  comment: string,
  categories?: string[]
};

export const feedbackCategories = [
  { neutralText: i18n.t('usability'), positiveText: i18n.t('easeOfUse'), negativeText: i18n.t('confusingPage'), value: "EaseOfUse" },
  { neutralText: i18n.t('performance'), positiveText: i18n.t('speed'), negativeText: i18n.t('slowPage'), value: "Performance" },
  { neutralText: i18n.t('usefulness'), positiveText: i18n.t('usefulnessPositive'), negativeText: i18n.t('usefulnessNegative'), value: "Usefulness" },
  { neutralText: i18n.t('errors'), positiveText: i18n.t('errorsPositive'), negativeText: i18n.t('errorsNegative'), value: "Errors" },
  { neutralText: i18n.t('others'), positiveText: i18n.t('othersPositive'), negativeText: i18n.t('othersNegative'), value: "Others" }
] as TenantFeedbackCategory[]