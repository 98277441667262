export default {
    titleLocks: 'Registered locks',
    addLock: 'Register lock',
    registrationTitleLocks: 'Register locks',
    registrationDescriptionLocks: `Here you can manage TouchPay-compatible automated locks for micro-markets, such as fridge locks and store front door locks,
    The locks are opened by the consumer, who must scan the lock's QR code via the TouchPay app.`,
    installationLocks: `When installing the lock's communication box, it must be paired via Bluetooth with the payment terminal. 
    It is important to save the name of the Bluetooth device, as it will be used to register the lock. The registration information is:`,
    deviceNameLocks: '<strong>Device name</strong>: must be exactly the same as the name of the lockn\'s Bluetooth device, so that the payment terminal can locate it.',
    lockName: '<strong>Lock name</strong>: a friendly name for identification, for example: Drinks fridge, Main door, etc..',
    ageRestrictionLocks: '<strong>Restricted access +18</strong>: if this option is checked, the lock can only be opened by app users who are of legal age.',
    qrCodeGenerationLocks: `Once the lock has been registered, a QR code will be generated and pasted next to the lock so that consumers can open it.
    can open it. If you don't yet have a lock box but would like to obtain the QR code, you can register it here 
    leaving the <em>device name</em> empty, and fill it in as soon as you receive the device`,
    noLocksLocks: 'No locks registered',
    closeLocks: 'Close',
    newLockLocks: 'New lock',
    successMessageLocks: 'Lock removed',
    errorMessageLocks: 'Lock could not be removed',
    editLocks: 'Edit',
  }
