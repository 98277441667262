export default {
    resetSelectedPickListDetails: 'Reset selected',
    showZeroPickListDetails: 'Show zeroed',
    searchProductPickListDetails: 'Search product',
    productCodePickListDetails: 'Product code',
    productNamePickListDetails: 'Product name',
    productBarCodePickListDetails: 'Barcode',
    categoryPickListDetails: 'Category',
    quantityPickListDetails: 'Quantity',
    currentQuantityPickListDetails: 'Current qty.',
    desiredQuantityPickListDetails: 'Desired qty.',
    criticalMinimumPickListDetails: 'Critical minimum',
    salesThisWeekPickListDetails: 'Sales last 7 days',
    centralStockQuantity: 'Central stock qty.',
    closePickListDetails: 'Close',
    pickListInfoPickListDetails: 'Pick List',
    pickListInfoPosDetails: 'POS',
    pickListInfoPlanogramDetails: 'Planogram',
    pickListInfoCreatedDetails: 'Created: ',
    pickListInfoUpdatedDetails: 'Updated: ',
    changeErrorPickListDetails: 'Error changing quantity',
    quantityNotChangedPickListDetails: 'Quantity not changed: number format is invalid',
    cancelPickListDetails: 'Cancel',
    toSavePickListDetails: 'Save',
    emptySelectedError: 'Error resetting items',
    pendingPickListDetails: 'Pending',
    usedPickListDetails: 'Used',
};
