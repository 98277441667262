export default {
  unlock: {
    operations: {
      result : {
        success: "Sucesso",
        invalid: "Inválido",
        error: "Erro",
        details: {
          lockOpened: "Sucesso",
          facialRecognitionSuccess: "Sucesso Reconhecimento Facial",
          phoneNotValidated: "Telefone Não Validado",
          emailNotValidated: "E-mail Não Validado",
          cpfNotValidated: "CPF Não Validado",
          ageNotValidated: "Idade Não Validada",
          userBlocked: "Usuário Bloqueado",
          unknown: "Desconhecido",
          lockNotFound: "Tranca Não Encontrada",
          deviceNotFound: "Dispositivo Não Encontrado",
          deviceNotPaired: "Dispositivo Não Emparelhado",
          deviceNotConnected: "Dispositivo Não Conectado",
          deviceNotResponsive: "Dispositivo Não Responsivo",
          communicationTimeout: "Tempo de Comunicação Esgotado",
          communicationError: "Erro de Comunicação",
          terminalNotReachable: "Terminal Não Acessível",
          bluetoothDisabled: "Bluetooth Desativado",
          noCertificate: "Sem Certificado",
          invalidClientCertificate: "Certificado de Cliente Inválido",
          invalidServerCertificate: "Certificado de Servidor Inválido",
          facialRecognitionFail: "Falha no Reconhecimento Facial"
        }
      }
    }
  }
}
