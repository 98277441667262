import { PaginatedQuery } from "@/types/common/PaginatedQuery";

export function shouldRefreshData(newOptions: any, oldOptions: any): boolean {
  if (newOptions == null) return false;
  return oldOptions == null ||
    (newOptions.sortBy && newOptions.sortBy[0] !== (oldOptions.sortBy || [])[0]) ||
    (newOptions.sortDesc && newOptions.sortDesc[0] !== (oldOptions.sortDesc || [])[0]) ||
    (newOptions.page > 0 && newOptions.page !== oldOptions.page) ||
    (newOptions.itemsPerPage > 0 && newOptions.itemsPerPage !== oldOptions.itemsPerPage);
}

export function toApiPagination(options: any, search: string | null = null): PaginatedQuery {
  return new PaginatedQuery(
    options.page,
    options.itemsPerPage,
    options.sortBy[0],
    options.sortDesc[0],
    search ? search : null);
}
