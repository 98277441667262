export default {
    appRegistration: 'Registro de la aplicación',
    registerApp: 'Registrar aplicación',
    additionalChargeInfo: 'La activación de la aplicación implica un cargo adicional, de acuerdo con la modalidad contratada a través del área comercial de AMLabs.',
    storeBrand: 'Marca de la tienda',
    enterBrand: 'Ingrese la marca para activar la aplicación para este PDV',
    locationSubtitleMobileActivation: 'Local/Subtítulo',
    enterLocation: 'Ingrese la ubicación para activar la aplicación para este PDV',
    sacWhatsAppOptional: 'WhatsApp de SAC (opcional)',
    sacWhatsAppNumber: 'Número de WhatsApp del SAC de la tienda para crear un acceso directo en la aplicación',
    displayProductPricesMobileActivation: 'Mostrar precios de los productos',
    verifyCpf: 'Verificar CPF con la Receita Federal (Agencia Tributaria de Brasil)',
    verifyAge: 'Verificar edad con la Receita Federal',
    verifyValidEmail: 'Verificar correo electrónico válido',
    verifyPhoneNumber: 'Verificar número de teléfono',
    phoneNumberOption: 'Opción aplicable solo para etiquetas blancas.',
    confirmPhoneMobileActivation: 'Requiere que el usuario confirme el número de teléfono mediante un código enviado por SMS para poder abrir las cerraduras de la tienda.',
    addToCartOption: 'Agregar productos al carrito de compras',
    paymentTokenMobileActivation: 'Token de pago',
    disableMobileActivation: 'Desactivar',
    closeMobileActivation: 'Cerrar',
    updateMobileActivation: 'Actualizar',
    registerMobileActivation: 'Registrar',
    registrationUpdated: '¡Registro de la aplicación actualizado!',
    unableToUpdateRegistration: 'No se pudo actualizar el registro de la aplicación.',
    appReleased: '¡Aplicación liberada!',
    unableToActivateApp: 'No se pudo activar la aplicación.',
    appDisabled: 'Aplicación desactivada en este PDV.',
    unableToDisableApp: 'No se pudo desactivar la aplicación en este PDV',
    appCheckoutTypeDisabled: 'Deshabilitado',
    appCheckoutTypeBarcode: 'Solo por código de barras',
    appCheckoutTypeBarcodeAndManual: 'Código de barras o selección manual',
    userDocumentVerificationText: `La activación de la verificación de CPF por la Receita Federal conlleva un cargo adicional,
    de acuerdo con la modalidad contratada a través del área comercial de AMLabs.`,
    userVerificationText: `La activación de la verificación de edad de los usuarios por la Receita Federal conlleva un cargo adicional,
    de acuerdo con la modalidad contratada a través del área comercial de AMLabs.`,
    errorFindingToken: 'No se pudo encontrar un token para el PDV.',
};
