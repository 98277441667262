<template>
  <v-dialog persistent v-model="show" max-width="720" @keydown.esc="show = false">
    <v-card>
      <v-form ref="form" lazy-validation v-model="valid" @submit.prevent="startActivation(selectedCompanyId)">
        <v-card-title>
          {{ $t('configureFiscalNote') }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            ref="autocomplete"
            v-model="selectedCompanyId"
            :items="companies"
            item-text="cnpj"
            item-value="id"
            :loading="loading.companies"
            :label="$t('companyCnpj')"
            :hint="$t('enterCnpj')"
            persistent-hint
            :no-data-text="$t('noRegisteredCompanies')"
            required
            :rules="[(v) => !!v || $t('requiredRules')]"
          ></v-autocomplete>
          <v-alert class="mt-4" :value="error.visible" type="error">
            {{ error.message }}<v-btn text @click.native="startActivation(selectedCompanyId, true)">{{ $t('activateAnyway') }}</v-btn>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip top :disabled="invoiceCompanyId != null">
            <template top #activator="{ on }">
              <div v-on="on">
                <v-btn 
                  text color="error" 
                  :loading="loading.deactivating"
                  @click="startDeactivation(invoiceCompanyId, true)"
                  :disabled="invoiceCompanyId === null"
                >{{ $t('disableInvoiceConfig') }}</v-btn>
              </div>
            </template>
            <span>{{ $t('issuanceAlreadyDisabled') }}</span>
          </v-tooltip>
          <v-btn text color="primary" @click="show = false">{{ $t('closeInvoiceConfig') }}</v-btn>
          <v-btn type="submit" :loading="loading.activating" :disabled="error.visible" text color="primary">{{ $t('saveInvoiceConfig') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <feedback ref="feedback"></feedback>
  </v-dialog>
</template>

<script>
export default {
  props: {
    posId: { type: Number, default: null },
    invoiceCompanyId: { type: String, default: null }
  },
  watch: {
    selectedCompanyId(newValue, oldValue) {
      if (newValue != oldValue) this.error.visible = false;
    },
    invoiceCompanyId(value) {
      this.selectedCompanyId = value;
    }
  },
  data() {
    return {
      show: false,
      valid: true,
      companies: [],
      loading: {
        companies: true,
        activating: false
      },
      selectedCompanyId: this.invoiceCompanyId,
      error: {
        visible: false,
        message: null
      }
    }
  },
  mounted() {
    this.axios.get('api/invoicesWeb/companies')
      .then(response => {
        this.companies = response.data;
        this.loading.companies = false;
      })
  },
  methods: {
    startActivation(selectedCompanyId, ignoreValidation = false) {
      if (this.$refs.form.validate()) {
        this.loading.activating = true;
        this.error.visible = false;
        const request = {  
          pointOfSaleId: this.posId,
          companyId: selectedCompanyId,
          enabled: true,
          ignoreValidation: ignoreValidation
        }
        this.axios.put('api/invoicesWeb/configureInvoice', request)
          .then(() => {
            this.$refs.feedback.handleSuccess(this.$t('nfceActivatedSuccessfully'));
            this.$emit('invoice-change', selectedCompanyId);
          }).catch(error => {
            if (error.response && error.response.status == 409) {
              this.error.message = error.response.data;
              this.error.visible = true;
            } else {
              this.$refs.feedback.handleError(this.$t('unableToActivateNfce'), error);
            }
          }).then(() => {
            this.loading.activating = false;
          });
      }
    },
    startDeactivation(selectedCompanyId, ignoreValidation = true) {
      this.loading.activating = true;
      this.error.visible = false;
      const request = {  
        pointOfSaleId: this.posId,
        companyId: selectedCompanyId,
        enabled: false,
        ignoreValidation: ignoreValidation
      }
      this.axios.put('api/invoicesWeb/configureInvoice', request)
        .then(() => {
          this.$refs.feedback.handleSuccess(this.$t('nfceDeactivatedSuccessfully'));
          this.$emit('invoice-change', null);
          this.$refs.autocomplete.reset();
        }).catch(error => {
          if (error.response && error.response.status == 409) {
            this.error.message = error.response.data;
            this.error.visible = true;
          } else {
            this.$refs.feedback.handleError(this.$t('unableToDeactivateNfce'), error);
          }
        }).then(() => {
          this.loading.activating = false;
        });     
    }
  }
}
</script>

<style>

</style>