
import Vue from 'vue';
import agent from "@/api/agent";
// eslint-disable-next-line no-unused-vars
import {PurchaseSuggestion, PurchaseSuggestionsFilter} from '@/types/PurchaseSuggestions';
import PurchaseSuggestionsFilters from "@/views/purchaseSuggestions/PurchaseSuggestionsFilters.vue";
import PurchaseSuggestionsHelp from "@/views/purchaseSuggestions/helpers/PurchaseSuggestionsHelp.vue";
import PickListCreationDialog from "@/views/picklist/PickListCreationDialog.vue";

export default Vue.extend({
  components: {
    PickListCreationDialog,
    PurchaseSuggestionsHelp,
    PurchaseSuggestionsFilters
  },
  data() {
    return {
      headers: [
        {text: 'Cód.', value: 'product.code', align: 'left', sortable: true},
        {text: 'Cód. Barras', value: 'product.barCode', align: 'left', sortable: true},
        {text: 'Produto', value: 'product.description', align: 'left', sortable: true},
        {text: 'Categoria', value: 'product.category', align: 'left', sortable: true},
        {text: 'Média saídas X dias', value: 'averageWithdraw', align: 'left', sortable: true},
        {text: 'Média rupturas X dias', value: 'averageRuptures', align: 'left', sortable: true},
        {text: 'Estoque', value: 'inventoryItem.quantity', align: 'left', sortable: true},
        {text: 'Mínimo Crítico', value: 'inventoryItem.minimumQuantity', align: 'left', sortable: true},
        {text: 'Sugestão(Un.)', value: 'unitsToBuySuggestion', align: 'left', sortable: true},
        {text: 'Qtd. Mínima(Un.)', value: 'inventoryItem.minimumPurchaseQuantity', align: 'left', sortable: true},
        {text: 'Un. Conversão', value: 'product.conversionUnitRatio', align: 'left', sortable: true},
        {text: 'Sugestão(U.C.)', value: 'convertedToBuySuggestion', align: 'left', sortable: true},
        {text: 'Qtd. Mínima(U.C.)', value: 'convertedMinimumPurchaseQuantity', align: 'left', sortable: true},
        {text: 'Tempo de estoque', value: 'inventoryDaysLeft', align: 'left', sortable: true},
      ] as object[],
      filters: {} as PurchaseSuggestionsFilter,
      loading: false as boolean,
      purchaseSuggestions: [] as PurchaseSuggestion[],
      search: null as string | null,
      pickListCreationDialog: {
        selectedPos: [] as number[] | null
      },
      generatePicklistButton: {
        isAbleToGeneratePickList: false,
        loading: false,
      }
    };
  },
  methods: {
    performPickListRequirements: function (filters: PurchaseSuggestionsFilter, acquiredPointOfSales: object[]) {
      if (filters.inventoryType === 'pointOfSale' && filters.inventoryIds.length === 1 && this.purchaseSuggestions.length > 0) {
        this.generatePicklistButton.isAbleToGeneratePickList = true;
        this.pickListCreationDialog.selectedPos = this.acquirePointOfSaleId(acquiredPointOfSales);
      } else {
        this.generatePicklistButton.isAbleToGeneratePickList = false;
      }
    },
    acquirePointOfSaleId: function (acquiredPointOfSales: object[]): number[] {
      let posId: number[] = [];
      acquiredPointOfSales.forEach((pos: any) => {
        if (pos.inventoryId === this.filters.inventoryIds[0]) {
          posId.push(pos.id);
        }
      });
      return posId;
    },
    getPurchaseSuggestions: async function (filters: PurchaseSuggestionsFilter, acquiredPointOfSales: object[]) {
      // Change table headers text
      let avgWithdraw = this.headers.find(he => (he as any).value == 'averageWithdraw');
      Object.assign(avgWithdraw as any, {text: `Média Saídas ${filters.pastDays} dias`});

      let avgRuptures = this.headers.find(he => (he as any).value == 'averageRuptures');
      Object.assign(avgRuptures as any, {text: `Média Rupturas ${filters.pastDays} dias`});

      this.loading = true;
      this.filters = filters;
      let params = this.filters;

      try {
        this.purchaseSuggestions = await agent.PurchaseSuggestions.list(params);
      } catch (error) {
        this.handleError(`Não foi possível gerar o relatório`, error);
      } finally {
        this.loading = false;
        this.performPickListRequirements(this.filters, acquiredPointOfSales);
      }
    },
    onPickListCreation() {
      (this.$refs.feedback as any).handleSuccess("Pick List criada com sucesso")
    },
    onResetFilters() {
      this.generatePicklistButton.isAbleToGeneratePickList = false;
    },
    onSinglePicklistCreationError(error: any) {
      this.handleError("Não foi possível", error);
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
    minimumCriticalAlert(item: PurchaseSuggestion) {
      return item.inventoryItem.minimumQuantity >= item.inventoryItem.quantity;
    },
  },
});
