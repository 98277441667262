export default {
  discounts: {
    title: 'Descuentos',
    coupons: {
      title: 'Cupones',
    },
    promotions: {
      title: 'Promociones',
    },
    help: {
      description: {
        coupons: `<p>En esta página puedes crear descuentos (botón <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Descuento) para los consumidores finales, como medio de fidelización de los mismos. Existen dos tipos de descuento: cupones y promociones. Cada uno tiene una pestaña correspondiente en la página. Los descuentos están disponibles para el terminal TouchPay Market y la app TouchPay Mobile.</p>

        <h3>Cupones</h3>
        <p>Para que un consumidor pueda usar un descuento de cupón, necesitará ingresar el código del cupón en el terminal de pago o en la app. Un cupón siempre está asociado a un descuento específico. Para crear cupones, usa el botón <i class="mdi mdi-plus-circle success--text" style="font-size:22px"></i> Cupones en el descuento deseado. Si el cupón es eliminado, nadie más podrá utilizarlo. También es posible definir una fecha de expiración para el cupón.</p>
    
        <p>El descuento del cupón puede tener un valor fijo en reales, o ser un porcentaje del valor total de la compra. También es posible definir un valor mínimo de compra a partir del cual se podrá aplicar el descuento.</p>
    
        <p>El cupón puede estar asociado al número de celular del comprador. En este caso, solo podrá ser utilizado si el consumidor, al ingresar el cupón, también ingresa su número de celular y ese número es igual al número asociado al cupón.</p>
        `,
        alert: 'Para que las promociones funcionen, es necesario que TouchPay Market esté en una versión a partir de la 3.8.0, y TouchPay Mobile en una versión a partir de la 1.14.0.',
        promotions: `
          <p>Las promociones se aplican sobre productos o combos de productos. Al agregar un descuento del tipo promoción, es necesario elegir los productos que forman parte de la promoción. Algunos ejemplos de aplicación:</p>
    
          <ul>
            <li>Al comprar 2 AMColas, la segunda sale con un 50% de descuento: marca el tipo de descuento como <strong>%</strong>. Agrega un producto AMCola con cantidad 1 y descuento 0. Luego agrega otra AMCola con cantidad 1 y descuento 50.</li>
            <li>Al comprar una AMCola y dos AMChips, el combo tiene un 20% de descuento: marca el tipo de descuento como <strong>%</strong>. Agrega un producto AMCola con cantidad 1 y descuento 20. Luego agrega el producto AMChips con cantidad 2 y descuento 20.</li>
            <li>Al comprar 2 cajas de cápsulas de café AMCoffee Expresso o AMCoffee Longo, obtén un descuento de 10 reales: creamos un agrupamiento (menú <strong>Agrupamientos</strong>) llamado AMCoffee que contiene los dos tipos de café. En la página de agrupamientos hay más información sobre cómo crearlos y usarlos. Luego creamos un nuevo descuento, marcando el tipo como <strong>COP$</strong>. Agregamos un nuevo producto AMCoffee (que es el agrupamiento) en el descuento, con cantidad 2 y descuento 10.</li>
          </ul>
    
          <p>Una promoción puede aplicarse a todos los puntos de venta, o ser válida solo en algunos PDVs. Por defecto, la promoción es válida para todos los PDVs. Para restringirla a solo algunos PDVs específicos, haz clic en <strong>Avanzado</strong> y desmarca la opción <strong>Aplicar en todos los PDVs</strong>. Luego es posible elegir solo los PDVs deseados.</p>
        `,
      }
    }
  }
}
