<template>
  <v-row>
    <portal to="toolbarTitle">
    <help>
        <template #title>Avaliações</template>
        <h3>Descubra o que seus clientes acham de sua(s) loja(s)!</h3>
        <p>
          Receba avaliações direto de seus clientes e aprimore a experiência de compra em suas lojas. 
          Ao finalizar uma compra, os clientes são convidados a deixar uma avaliação, ajudando você a entender o que estão achando do seu estabelecimento. 
        </p>
        <p>As avaliações são categorizadas em duas seções:</p>
        <ul>
          <li><strong>Via Terminal:</strong> Disponível para todos os clientes, permitindo avaliações rápidas e imediatas.</li>
          <li><strong>Via Aplicativo:</strong> Exclusivo para clientes com a versão White Label do nosso aplicativo. Em caso de dúvidas, entre em contato com nosso time comercial.</li>
        </ul>
        <p></p>
        <p>
          Utilize essas avaliações para compreender melhor as preferências dos seus clientes e identificar áreas que necessitam de melhorias. 
          Esteja sempre atento as avaliações recebidas para garantir que suas lojas ofereçam a melhor experiência possível aos seus clientes.
        </p>

      </help>
    </portal>
    
    <v-col cols="12">
      <users-feedbacks-filters :activeTab="activeTab" @modify="updateForm"></users-feedbacks-filters>
      <v-tabs
        v-model="activeTab"
        grow
        background-color="#009B92 lighten-4"
        active-class="custom-active-tab"
        class="custom-tabs"
      >
        <v-tab :key="0">Ponto de venda</v-tab>
        <v-tab :key="1">Aplicativo</v-tab>
        <v-tab-item :key="0">
          <users-feedbacks-pos :form="form"></users-feedbacks-pos>          
        </v-tab-item>
        <v-tab-item :key="1">
          <users-feedbacks-app :form="form"></users-feedbacks-app>          
        </v-tab-item>
      </v-tabs>      
    </v-col>
  </v-row>
</template>

<script>
import UsersFeedbacksApp from './UsersFeedbacksApp.vue';
import UsersFeedbacksPos from './UsersFeedbacksPos.vue';
import UsersFeedbacksFilters from './UsersFeedbacksFilters.vue';

export default {
  data() {
    return {
      activeTab: 0,
      form: {}
    };
  },
  components: {
    UsersFeedbacksApp,
    UsersFeedbacksPos,
    UsersFeedbacksFilters,
  },
  computed: {
    routeParams() {
      return Object.assign({}, this.$route.query);
    }
  },
  methods: {
    updateForm(newForm) {
      this.form = { ...newForm };
    },
  },
};
</script>

<style scoped>
.custom-active-tab {
  color: #009B92;
}
::v-deep .custom-tabs .v-tabs-bar .v-tabs-slider {
  background-color: #009B92;
}
</style>

