
import Vue from 'vue';
import agent from "@/api/agent";
import PriceTableCategoryMarkupSelectorHelp from "@/views/priceTables/helpers/PriceTableCategoryMarkupSelectorHelp.vue";
import CategoriesDropDown from '@/components/filters/CategoriesDropDown.vue';

export default Vue.extend({
  components: {PriceTableCategoryMarkupSelectorHelp, CategoriesDropDown},
  props: {
    priceTableId: {type: Number, required: true},
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      categoryId: null as number | null,
      markup: null as number | null,
      loading: false,
      valid: false,
      helpDialog: {
        enabled: false,
      },
      translations: {
        action: {
          updateMarkupByCategory: this.$t('priceTable.action.divergence').toString(),
        },
        response: {
          updateMarkupByCategory: {
            success: this.$t('priceTable.response.updateMarkupByCategory.success').toString(),
            error: this.$t('priceTable.response.updateMarkupByCategory.error').toString(),
          }
        },
        button: {
          close: this.$t('buttons.close').toString(),
          apply: this.$t('buttons.apply').toString()
        }
      },
    };
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
        if (!value) {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-undef
    updateByCategory(bytes: BufferSource){
      if ((this.$refs.form as any).validate(bytes)) {
        agent.PriceTables.updateMarkupByCategory(this.priceTableId, this.categoryId!, this.markup!)
            .then(() => {
              this.$emit("success-updating-markup", this.translations.response.updateMarkupByCategory.success);
              this.dialog = false;
            })
            .catch((error) => {
              this.handleError(this.translations.response.updateMarkupByCategory.error, error);
            })
            .finally(() => {
              this.loading = false;
              this.resetForm();
            });
      }
    },
    resetForm(): void {
      (this.$refs.form as any).reset();
    },
    handleError(message: string, error: any) {
      (this.$refs.feedback as any).handleError(message, error);
    },
  }
});
