
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, required: true},
  },
  data() {
    return {
      translations: {
        draft: {
          action: {
            finish: this.$t('planogram.draft.action.finish').toString(),
          },
          description: {
            finish: {
              confirm: this.$t('planogram.draft.description.finish.confirm').toString(),
              alert: this.$t('planogram.draft.description.finish.alert').toString(),
            }
          },
        },
        button: {
          finish: this.$t('buttons.finish').toString(),
          no: this.$t('buttons.no').toString(),
        }
      }
    }
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    finishDrafts() {
      this.$emit("finishDraftsConfirmation");
    },
  }
});
