<template>
  <v-flex xs12>
  <v-card class="mb-16">
    <v-card-title>
      {{ $t('boxesManagement') }}
      <v-spacer></v-spacer>
      <v-form
        v-model="search.valid"
        @submit.prevent="getBoxesPaginated"
      >
        <v-text-field
          class="mr-2 my-1 pt-0"
          v-model="search.text"
          append-icon="search"
          :label="$t('searchBoxManagement')"
          single-line
          hide-details
          clearable
          filled
          rounded
          dense
          @click:clear="search.text = ''; getBoxesPaginated();"
          @click:append="options.page = 1; getBoxesPaginated()"
          @input="options.page = 1"
        ></v-text-field>
      </v-form>
    </v-card-title>

    <v-divider></v-divider>
    <v-data-table
      :loading="loading"
      :headers="filterHeaders"
      :items="boxIds"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="$t('noResultsBoxManagement')"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30, 50, 100] }"
      :search="search"
    >
      <template #[`item.boxId`]="{ item }">
        {{ item.boxId }}
      </template>
      <template #[`item.pdv`]="{ item }">
        {{ item.pdv }}
      </template>
      <template #[`item.password`]="{ item }">
        <v-btn icon small @click="item.showPassword ? item.showPassword = !item.showPassword : getPassword(item)"
               style="margin-right: 10px;">
          <v-icon>{{ item.showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        </v-btn>
        <span style="width: 300px; display: inline-block;">{{
            item.showPassword ? item.password : '••••••'
          }}</span>
      </template>
      <template #[`item.acquirer`]="{ item }">
        {{ item.acquirer }}
      </template>
      <template #[`item.version`]="{ item }">
        {{ item.version }}
      </template>
    </v-data-table>
  </v-card>
  </v-flex>
</template>

<script>
import Vue from 'vue';
import {shouldRefreshData} from "@/components/TableOptionsComparator";

export default Vue.extend({
  data() {
    return {
      boxIds: [],
      headers: [
        {text: this.$t('idBoxManagement'), align: 'left', sortable: true, value: 'id'},
        {text: this.$t('tables.headers.pointOfSaleId'), align: 'left', sortable: false, value: 'pointOfSaleId'},
        {text: this.$t('acquirerBoxManagement'), align: 'left', sortable: true, value: 'phoneModel'},
        {text: this.$t('versionBoxManagement'), align: 'left', sortable: true, value: 'currentVersion'},
        {text: this.$t('passwordBoxManagement'), align: 'left', sortable: false, value: 'password'},
      ],
      filterHeaders: [],
      loading: true,
      showPassword: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["id"],
        sortDesc: [false],
        mustSort: true,
      },
      search: {
        valid: true,
        text: "",
      },
      totalItems: 0,
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getBoxesPaginated();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getBoxesPaginated();
    this.filterHeaders = this.headers;
  },
  methods: {
    getBoxesPaginated() {
      this.loading = true;
      const params = {
        params: {
          page: this.options.page,
          pageSize: this.options.itemsPerPage,
          sortOrder: this.options.sortBy[0],
          descending: this.options.sortDesc[0],
          search: this.search.text ? this.search.text : null,
        },
      }

      const boxesQuery = this.axios.get('/api/Boxes/paginated', params);

      boxesQuery
        .then(response => {
          this.totalItems = response.data.totalItems;
          this.totalPages = response.data.totalPages;
          this.boxIds = response.data.items.map(item => ({
            ...item,
            pointOfSaleId: item.pointOfSale ? item.pointOfSale.id : null,
            showPassword: false
          }));
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPassword(box) {
      this.axios.get(`/api/Boxes/masterBarcode/${box.id}`)
        .then(response => {
          box.password = response.data;
          box.showPassword = !box.showPassword;
        })
        .catch(() => {
          box.password = this.$t('notFoundMasterBarcode');
          box.showPassword = !box.showPassword;
        });
    },
  }
});
</script>
