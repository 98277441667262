
import { defineComponent } from 'vue';
import axios from 'axios';
import { CashlessCustomer } from '@/types/Cashless';

export default defineComponent({
  name: "CashlessCustomerFilter",
  props: {
    value: { type: Number, default: null },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoadingConsumers: false,
      consumers: [] as Array<CashlessCustomer>,
      selectedConsumerId: this.value
    }
  },
  watch: {
    selectedConsumerId(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.selectedConsumerId = val;
    }
  },
  mounted() {
    this.getCashlessUsers(); 
  },
  methods: {
    getCashlessUsers(): void {
      this.isLoadingConsumers = true;
      axios.get('api/cashless/customers')
        .then((response) => {
          this.consumers = response.data;
          this.isLoadingConsumers = false;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoadingConsumers = false;
        });
    }
  }
});
