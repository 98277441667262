export const billServiceTypesGroups = {
  "LeaseMicroMarketTotemMaster": "LeaseMicroMarket",
  "LeaseMicroMarketTotemSlave": "LeaseMicroMarket",

  "OwnedMicroMarketTotemMaster": "OwnedMicroMarket",
  "OwnedMicroMarketTotemSlave": "OwnedMicroMarket",

  "InvoiceServicePos": "Invoice",
  "InvoiceServiceQuantity": "Invoice",
  "InvoiceMonthlyFeePosService": "Invoice",

  "WhiteLabelService": "WhiteLabelService",
  "IndividualAppPosService": "IndividualAppPosService",

  "AppUserValidation": ["WhiteLabelService","IndividualAppPosService"],
  "PushNotificationService": ["WhiteLabelService","IndividualAppPosService"],

  "DeviationAlertBaseService": "DeviationAlert",
  "DeviationAlertAdditionalCameraService": "DeviationAlert",
  
  "Internet3GService": "Touchpay",
  "Touchpay": "Touchpay",

  "TerminalUserValidation": "TerminalUserValidation",
  "ErpModule": "ErpModule",
  "Cashless": "Cashless",
  "CoreStock": "CoreStock",
}

export const billServiceCardTitle = {
  "LeaseMicroMarket": "Totem de Pagamento Comodato",
  "OwnedMicroMarket": "Totem de Pagamento Venda",
  "Invoice": "Módulo NFCe",
  "IndividualAppPosService": "TouchPay Mobile",
  "WhiteLabelService": "TouchPay WhiteLabel",
  "AppUserValidation": "AppUserValidation",
  "ErpModule": "Módulo ERP",
  "Internet3GService": "Internet3GService",
  "DeviationAlert": "Alerta de Desvios",
  "Cashless": "Cashless",
  "CoreStock": "Estoque Central",
  "PushNotificationService": "PushNotificationService",
  "Touchpay": "Licença TouchPay",
  "TerminalUserValidation": "Validação de Usuário Terminal",
  // "": "Controle de Acesso",
  // "": "Pague e Pegue",
  // "": "Trava de Geladeira",
}
