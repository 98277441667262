export default {
  security: {
    deviationAlert: {
      title: "Alerta de Desvios",
      suspiciousAccess: {
        title: "Acessos Suspeitos",
      },
      suspiciousTransactions: {
        title: "Transações Suspeitas",
        accessDetailsDialogSubtitle: "Transações suspeitas em {date} de {timeStart} a {timeEnd}"
      },
      videoAlerts: {
        title: "Alertas de Vídeo",
        videos: "Vídeos",
      },
      messages: {
        tokenNotActivated: `Este ambiente não tem sincronização de transações com o adquirente habilitado. 
          Portanto, pode haver transações com resultados que diferem da declaração do adquirente. 
          Para habilitar a sincronização de transações, entre em contato com nosso suporte.`,
        success: {
          updateStatusTransaction: "Status da transação atualizado com sucesso",
          downloadRealizeSuccess: "O download está em andamento e pode ser baixado em breve",
        },
        error: {
          choosePosInitialSearch: "Escolha um ponto de venda para iniciar a busca",
        },
      },
      tooltips: {
        relatedSuspiciuosTransactions: "Transações suspeitas relacionadas",
      },
      resultDeviationStatus: {
        pending: "Pendente",
        verifiedOk: "Verificado Ok",
        deviation: "Tem Desvio",
        refunded: "Reembolsado",
        lost: "Perdido",
      },
      help: {
        description: `
          <p>
            Aqui são exibidas as vendas realizadas, dados importantes para o controle de desvios e se a compra foi cancelada ou não.
            É possível acessar os vídeos das transações e configurar o status de verificação do desvio.
          </p>
          <p>
            <strong>Alertas de Vídeo:</strong>
          </p>
          <ul>
            <li>
              Você pode acessar as gravações de cada câmera de PDV separadamente.
            </li>
          </ul>
          <br>
          <p>
            <strong>Transações Suspeitas:</strong>
          </p>
          <ul>
            <li>
              Em transações suspeitas, você tem uma visão de todas as tentativas de transação no PDV, mostrando transações canceladas
              <i class="mdi mdi-minus-circle success--text" style="font-size:22px"></i>
              e transações pagas
              <i class="mdi mdi-check-circle error--text" style="font-size:22px"></i>;
            </li>
            <li>
              Você pode ver mais detalhes das tentativas clicando para expandir a transação, onde pode ver os produtos
              que foram adicionados ao carrinho no momento da compra, juntamente com os produtos que foram removidos posteriormente, marcados em vermelho;
            </li>
            <li>
              As transações aparecerão por padrão com o status de verificação "Pendente", e dependendo da ação que você selecionar,
              esse status mudará. As ações são:
              <ul>
                <li>
                  <i class="mdi mdi-check-circle success--text" style="font-size:22px"></i>
                  : indica que não houve desvios e a transação está correta.
                </li>
                <li>
                  <i class="mdi mdi-alert error--text" style="font-size:22px"></i>
                  : indica que um desvio foi identificado.
                </li>
                <li>
                  <i class="mdi mdi-cash-multiple success--text" style="font-size:22px"></i>
                  : indica que o desvio foi reembolsado.
                </li>
                <li>
                  <i class="mdi mdi-close-circle error--text" style="font-size:22px" color=""></i>
                  : indica que o desvio não será reembolsado e é considerado uma perda.
                </li>
                <li>
                  <i class="mdi mdi-download success--text" style="font-size:22px"></i>
                  : quando ativado, inicia a tentativa de baixar o vídeo.
                  <strong>É muito importante que este botão seja pressionado apenas uma vez para evitar a desaceleração do sistema.</strong>
                </li>
                <li>
                  <i class="mdi mdi-video info--text" style="font-size:22px"></i>
                  : será exibido se o download for concluído com sucesso, e quando ativado,
                  permite visualizar o vídeo baixado.
                </li>
              </ul>
            </li>
          </ul>
          <br>
          <p>
            <strong>Acesso Suspeito:</strong>
          </p>
          <ul>
            <li>
              Em acessos suspeitos, aparecerão todos os momentos em que houve acesso pelo módulo de controle de acesso e nenhuma transação foi notificada dentro de 5 minutos;
            </li>
            <li>
              Você pode realizar as mesmas ações que em Transações Suspeitas, com a opção de baixar o vídeo do momento de acesso;
            </li>
            <li>
              Ao clicar no botão
              <i class="mdi mdi-magnify info--text" style="font-size:22px"></i>
              ao lado da data, você pode ver as 5 transações que ocorreram após o acesso dentro de um período de 1 hora,
              o que permite baixar o vídeo, tomar ações e verificar os detalhes da transação.
            </li>
          </ul>
          <br>
          `,
          alert: 'Para acessar os vídeos de uma transação de ponto de venda, é necessário que a integração de vídeo esteja habilitada.'
      },
    },
    accessControl: {
      title: "Controle de Acesso",
      help: {
        description: `
        <p>
          Aqui é exibido todo o acesso a cada fechadura, junto com a telemetria de cada dispositivo, tudo em abas separadas.
        </p>
        <p>
          Começando pela aba <strong>Lista de Acessos</strong>, você verá uma lista dos acessos realizados, junto com o resultado da ação, que pode ser <strong style='color: red'>Erro</strong> ou <strong style='color: green'>Sucesso</strong>.
          Ao expandir a linha clicando em <i class="material-icons" style="vertical-align: middle; margin-left: 2px;">keyboard_arrow_down</i>, você verá uma listagem com informações sobre a pessoa responsável por abrir a fechadura, o nome da fechadura e do dispositivo, bem como o erro específico que ocorreu durante a abertura, se houver.
        </p>
        <p>
          Ao passar para a aba <strong>Telemetria</strong>, você verá dados de telemetria relevantes com informações sobre o cliente, o dispositivo e seu estado.
        </p>
        `,
      },
      tooltips: {
        blockedUser: "Bloquear usuário na loja",
        unblockedUser: "Desbloquear usuário na loja",
      },
      messages: {
        blocked: "Bloqueado",
        unblocked: "Desbloqueado",
        success: "Sucesso ao realizar {action} para {fullName}.",
        error: "Erro ao realizar {action}."
      },
    }
  }
}
