export default {
    detailsInvoiceDialog: 'Detalles de la NFC-e',
    statusInvoiceDialog: 'Estado: ',
    createdAtInvoiceDialog: 'Fecha de creación: ',
    updatedAtInvoiceDialog: 'Fecha de actualización: ',
    seriesInvoiceDialog: 'Serie: ',
    numberInvoiceDialog: 'Número: ',
    downloadDanfeInvoiceDialog: 'Descargar DANFE',
    downloadXmlInvoiceDialog: 'Descargar XML',
    reasonInvoiceDialog: 'Motivo: ',
    retryInvoiceDialog: 'Reintentar',
    retryConfirmationInvoiceDialog: 'Reemisión de factura',
    confirmRetryInvoiceDialog: '¿Está seguro de que desea emitir la factura nuevamente?',
    keepSequentialInvoiceDialog: 'Mantener el mismo número secuencial',
    keepSequentialRecommendation: 'Recomendamos mantener el mismo número secuencial siempre que sea posible.',
    cancelInvoiceDialog: 'Cancelar',
    closeInvoiceDialog: 'Cerrar',
    enableNfceInvoiceDialog: 'Para habilitar la emisión de la Nota Fiscal de Consumidor Eletrônica (NFC-e),\ncontacte con el área comercial de AMLabs.',
    okInvoiceDialog: 'Ok',
    processingInvoiceDialog: 'Procesando',
    approvedInvoiceDialog: 'Aprobada',
    deniedInvoiceDialog: 'Rechazada',
    requestErrorInvoiceDialog: 'No creada',
    unknownProblemInvoiceDialog: 'Problema desconocido',
    successMessageInvoiceDialog: 'La factura ha sido aprobada',
    errorMessageInvoiceDialog: 'El intento de reemisión resultó en el estado ',
    retryErrorInvoiceDialog: 'Error al reintentar la factura'
}
