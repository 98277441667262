export default {
    welcome : 'Bem-vindo!',
    emailLabel: 'Email',
    passwordLabel: 'Senha',
    keepConnected: 'Manter-me conectado',
    buttonLogin: 'Entrar',
    buttonContinue: 'Continuar',
    forgotPassword: 'Esqueceu a senha?',
    clickHere: 'Clique aqui!',
    recoverPassword: 'Recuperar senha',
    returnToLogin: 'Voltar para login',
    seeEmailBox: 'Verifique sua caixa de email',
    systemUnavailable: 'Sistema indisponível, tente novamente mais tarde',
    userInvalidPassword: 'Usuário ou senha inválida',
    royaltyBlocking: 'Seu acesso está bloqueado. Por favor, contate a sua Franqueadora.',
    paymentBlock: 'Seu acesso está bloqueado. Por favor, contate o administrativo pelo número (11) 5199-6668.',
    pendingContract: 'Existem pendências em seu contrato. Por favor, contate o administrativo pelo número (11) 5199-6668.',
    somethingWentWrong: 'Ops! Algo deu errado',
    fillInEmail: 'Preencha seu email.',
    fillInPassword: 'Preencha a senha.',
    hasFinancialIssues: 'Seu acesso está bloqueado. Clique no botão abaixo para ver se há pendências financeiras. Se o problema persistir, por favor contate o administrativo pelo número (11) 5199-6668.',
    pendingBills: 'Ir Para Pendências Financeiras',
    twoFactorCode: 'Código de autenticação',
    twoFactorFailed: 'Não foi possível validar o código de autenticação, por favor verifique-o novamente.',
    trustPeriodText: 'Caso o pagamento já tenha sido realizado mas ainda esteja em processamento, você pode clicar no botão abaixo para desbloquear seu acesso por 3 dias. Caso o pagamento não seja confirmado dentro deste período, seu acesso será bloqueado novamente, e esta opção se tornará indisponível.',
    trustPeriodUpdateFailed: 'Não foi possível atualizar o período de confiança. Por favor, contate o administrativo pelo número +55 (11) 5199-6668.',
    trustPeriodButton: 'Iniciar Período de Confiança',
    trustPeriodDialogText: 'Isso desbloqueará seu acesso por 3 dias. Caso o pagamento não seja confirmado dentro deste período, seu acesso será bloqueado novamente, e esta opção se tornará indisponível. Deseja continuar?',
    buttonConfirm: 'Confirmar',
    buttonCancel: 'Cancelar'
  };
