export default {
    productNameDraftEdition: 'Produto',    
    productImageDraftEdition: 'Imagem',
    selectionDraftEdition: 'Seleção',
    productDescriptionDraftEdition: 'Nome do produto',
    priceDraftEdition: 'Preço',
    capacityDraftEdition: 'Capacidade Máx.',
    desiredQuantityDraftEdition: 'Quantidade desejada (nível de par)',
    criticalMinimumDraftEdition: 'Mínimo crítico',
    actionsDraftEdition: 'Ações',
    removeItemDraftEdition: 'Remover item',
    addMultipleDraftEdition: 'Adicionar vários',
    saveDraftEdition: 'Salvar',
    cancelDraftEdition: 'Cancelar',
    cancelEditPromptDraftEdition: 'Tem certeza de que deseja cancelar a edição rápida? As modificações serão perdidas.',
    noProductsDraftEdition: 'Sem produtos cadastrados',
    fixErrorsDraftEdition: 'Corrija os erros antes de salvar',
    successSaveDraftEdition: 'Planograma editado com sucesso.',
    saveFailureDraftEdition: 'Houve uma falha ao salvar o planograma',
  };