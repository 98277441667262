
import Vue from 'vue';
import RupturesOccurrencesFilters from './components/RupturesOccurrencesFilters.vue';
import RupturesOccurrencesHelp from './helpers/RupturesOccurrencesHelp.vue';
import { formatDateTimeMinutes } from '@/utility/TextFormatting'
// eslint-disable-next-line no-unused-vars
import { RuptureOccurrence } from '@/types/Rupture'

export default Vue.extend({
  components: {RupturesOccurrencesFilters, RupturesOccurrencesHelp},
  props: {
    filtersFromParent: { type: Object, default: () => {} }
  },
  watch: {
  },
  computed: {
    totals(): object {
      return {
        salesLosses: (this.onlyOpenedRuptures ? this.totalsOpened.salesLosses : 0) +
          (this.onlyClosedRuptures ? this.totalsClosed.salesLosses : 0),
        profitLosses: (this.onlyOpenedRuptures ? this.totalsOpened.profitLosses : 0) +
          (this.onlyClosedRuptures ? this.totalsClosed.profitLosses : 0)
      }
    },
  },
  data() {
    const $currencyFormatter = (this as any).$currencyFormatter;
    return{
      ruptureOccurrences: [] as RuptureOccurrence[] | undefined,
      filteredRuptureItems: [] as RuptureOccurrence[] | undefined,
      totalsOpened: {
        salesLosses: 0,
        profitLosses: 0
      },
      totalsClosed: {
        salesLosses: 0,
        profitLosses: 0
      },
      headers: [
        { text: 'Id. Item', align: 'left', sortable: true, value: 'itemId'},
        { text: 'Cód. Produto', align: 'left', sortable: true, value: 'itemProductCode'},
        { text: 'Descrição', align: 'left', sortable: true, value: 'itemProductDescription' },
        { text: 'Categoria', align: 'left', sortable: true, value: 'itemProductCategoryName' },
        { text: 'Estoque', align: 'left', sortable: true, value: 'itemInventoryName'},
        { text: 'Data-Início', align: 'left', sortable: true, filterable: false, value: 'startDate'},
        { text: 'Data-Fim', align: 'left', sortable: true, filterable: false, value: 'endDate'},
        { text: 'Tempo(Dias)', align: 'left', sortable: true, filterable: false, value: 'ruptureDays'},
        { text: 'Perdas(Un.)', align: 'left', sortable: true, filterable: false, value: 'salesLost'},
        { text: `Perdas(${$currencyFormatter.getSign()})`, align: 'left', sortable: true, filterable: false, value: 'profitLoss'},
      ],
      loading: {
        ruptureOccurrences: true,
      },
      form: {},
      search: '',
      onlyClosedRuptures: true,
      onlyOpenedRuptures: true,
    }
  },
  mounted() {
    if (Object.keys(this.filtersFromParent).length != 0) {
      Object.assign(this.form, this.filtersFromParent);
    }
  },
  methods: {
    filterRuptureOccurrences() {
      const openedRupture = this.acquireOpenedRuptures() as RuptureOccurrence[] | undefined;
      const closedRupture = this.acquireClosedRuptures() as RuptureOccurrence[] | undefined;
      this.filteredRuptureItems = [...(openedRupture || []), ...(closedRupture || [])];

      this.totalsOpened.salesLosses = openedRupture?.reduce((a,b) => a + b.salesLost, 0) as number;
      this.totalsOpened.profitLosses = openedRupture?.reduce((a,b) => a + b.profitLoss, 0) as number;

      this.totalsClosed.salesLosses = closedRupture?.reduce((a,b) => a + b.salesLost, 0) as number;
      this.totalsClosed.profitLosses = closedRupture?.reduce((a,b) => a + b.profitLoss, 0) as number;
    },
    acquireOpenedRuptures(): RuptureOccurrence[] | undefined {
      if (this.onlyOpenedRuptures)
        return this.ruptureOccurrences?.filter(i => i.endDate == null);
    },
    acquireClosedRuptures(): RuptureOccurrence[] | undefined {
      if (this.onlyClosedRuptures)
        return this.ruptureOccurrences?.filter(i => i.endDate != null);
    },
    updateFilters(form: any) {
      this.form = form;
      this.getRuptureOccurrences();
    },
    getRuptureOccurrences() {
      this.loading.ruptureOccurrences = true;
      this.axios
        .get('api/ruptures/occurrences', { params: this.form })
        .then((response) => {
          this.loading.ruptureOccurrences = false;
          this.ruptureOccurrences = response.data.ruptureItems;
          this.filterRuptureOccurrences();
        });
    },
    formatDateTimeMinutes,
  },
});
