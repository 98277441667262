export default {
  missingProducts:{
    title: 'Missing Products',
    typeOfAlert: {
      title: 'Alert Types',
      insertInPlanogram: 'Insert in Planogram',
      insertProduct: 'Register Product',
      productIdentified: 'Product Identified',
    },
    error: {
      excel: 'Error exporting excel',
    },
    insertInPlanogram: {
      title: 'Do you want to insert the product into the active planogram?',
      message: 'By clicking "yes", your product will be directly added to the active planogram of your point of sale.',
    },
    excelFileName: 'Products_not_found.xlsx',
    help:{
      description:`
      <p>
        On the Products Not Found page, you can view the barcodes that, after being scanned, were not found, either because they were not registered in the system or because they are not part of the current planogram for the respective point of sale.

        In the Alert Types column, you can view the following statuses:
        <ul>
          <li><strong>Register Product:</strong> The system does not have this barcode registered, so it is necessary to register the product before adding it to the planogram.</li>
          <li><strong>Add to Planogram:</strong> The product is registered in the system but is not part of the current planogram for the point of sale.</li>
          <li><strong>Product Identified:</strong> The product has been scanned and is now registered in the respective planogram.</li>
        </ul>
      </p>`,
    }
  },
};