export default {
    January: 'January',
    Jan: 'Jan',
    February: 'February',
    Feb: 'Feb',
    March: 'March',
    Mar: 'Mar',
    April: 'April',
    Apr: 'Apr',
    May: 'May',
    June: 'June',
    Jun: 'Jun',
    July: 'July',
    Jul: 'Jul',
    August: 'August',
    Aug: 'Aug',
    September: 'September',
    Sep: 'Sep',
    October: 'October',
    Oct: 'Oct',
    November: 'November',
    Nov: 'Nov',
    December: 'December',
    Dec: 'Dec',
}