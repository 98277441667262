export default {
    titleSellingProducts: 'Produtos mais vendidos',
    abcText: `{numberOfAProducts} dos {totalNoOfProducts} produtos correspondem a 80% do seu faturamento e
    {numberOfCProducts} dos {totalNoOfProducts} produtos correspondem a 5% do seu faturamento`,
    newTabLink: 'Abrir em nova aba',
    pointOfSaleLabel: 'Pontos de Venda',
    quantitySold: 'Quantidade Vendida',
    totalSold: 'Total Vendido',
    productsSoldNoResults: 'Sem resultados',
    notRegistered: 'Produto não cadastrado',
    productsSold: 'Produto',
    productsSoldAmount: 'Quantidade vendida',
    valueSoldProducts: 'Valor vendido',
    valueRupturesProducts: 'Rupturas',
    rupturesGetErrorMessage: 'Houve um erro ao carregar as rupturas dos produtos',
    abcCurveGetErrorMessage: 'Houve um erro ao carregar as informações da curva ABC',
    productsMostSoldHelpText: `
    <p style="text-align: justify;">
    Saiba quais são os produtos mais procurados em suas lojas! 
    Entenda quais foram suas vendas e evite possíveis rupturas por falta de abastecimentos.
    </p>
    <p style="text-align: justify;">
    Além disso, oferecemos análises sobre a importância do mix de produtos em sua operação. 
    Identificamos os itens que impulsionam significativamente seu faturamento, representando 80% das suas vendas.
    </p>
    <p style="text-align: justify;">
    Por outro lado, também destacamos os produtos de baixo desempenho, que contribuem apenas com 5% do seu rendimento total.
    </p>`
    
};