export default {
    supplyHelpTitle: `Abastecimiento`,
    textSupplyHelp: `<p>En esta pantalla se muestran los reabastecimientos y controles de inventario realizados en TouchPay.</p>
    <p>La opción de reabastecimiento se puede acceder de tres formas:</p>
    <ul>
        <li><strong>Reabastecimiento por terminal</strong>: La opción de reabastecimiento por terminal se accede a través del menú de administrador del terminal de pago.</li>
        <li><strong>Reabastecimiento por la aplicación TouchPay Abastecedor</strong>: El reabastecimiento por la aplicación se puede realizar cuando una Pick List está pendiente/separada. En esta opción, el usuario puede editar cantidades y fechas de vencimiento, además de retirar los artículos.</li>
        <li><strong>Reabastecimiento por telemetría</strong>: El reabastecimiento también se puede realizar a través de la página de telemetría del sistema web, pero en este modo no es posible editar las cantidades reabastecidas, realizar el control de inventario o informar la fecha de vencimiento de los productos. Es posible realizar el reabastecimiento de una Pick List creada o un reabastecimiento completo. En un reabastecimiento completo, se reabastecerán los artículos suficientes para alcanzar la cantidad deseada de ese planograma, siempre será la diferencia entre la cantidad actual y el valor deseado.</li>
    </ul>
    <p>El reabastecimiento actualiza el inventario de su punto de venta con los productos reabastecidos. Se requiere una Pick List para cada reabastecimiento que no sea completo.</p>
    <p>La opción de inventario se puede acceder de tres formas:</p>
    <ul>
        <li><strong>Inventario por terminal</strong>: La opción de inventario por terminal se accede a través del menú de administrador del terminal de pago.</li>
        <li><strong>Inventario por la aplicación TouchPay Abastecedor</strong>: El inventario por la aplicación se puede realizar cuando un punto de venta tiene un Planograma. En esta opción, el usuario puede editar cantidades y fechas de vencimiento, además de retirar los artículos.</li>
        <li><strong>Inventario por Almacén</strong>: El inventario también se puede realizar a través de la página de inventarios del sistema web, pero en este modo no es posible editar la fecha de vencimiento de los productos y no tenemos la referencia de la cantidad deseada. Esta operación solo puede ser realizada por usuarios con permiso de gerente o administrador.</li>
    </ul>`,
}
