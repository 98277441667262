<template>
    <v-dialog :max-width="width" v-model="showDialog">
      <v-card>
      <div class="modal-content">
        <h2>ATENÇÃO</h2>
        <p class="error-message">
          Não encontramos o cadastro deste cliente em nosso banco de dados.
        </p>
        <p>
          Para prosseguirmos com a solicitação de troca de titularidade, por favor, notifique o novo titular para que acesse o link abaixo e preencha o formulário para seguirmos com a solicitação!
        </p>
        <a :href="link" target="_blank">{{ link }}</a>
        <button @click="copyLink">Copiar link e fechar</button>
      </div>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: {type: Number, required: false, default: 360},
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      link: 'https://app.pipefy.com/public/form/ZJbgPqZw',
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.link);
      this.$emit('close'); // Emite o evento de fechamento
    },
  },
};
</script>

<style scoped>
/* Estilos básicos para modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
}

.error-message {
  color: red;
  font-weight: bold;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
</style>
