export default {
  salesByDay: 'Total de vendas por dia',
  topSellingPos: 'PDVs que mais vendem',
  topAndBottonSellingPos: 'PDVs que mais e menos vendem',
  bottomSellingPos: 'PDVs que menos vendem',
  salesByPos: 'Total de vendas por PDV',
  salesByProduct: 'Vendas por produto',
  excel: 'Excel',
  totalLowerCase: 'Total',
  productDescription: 'Descrição',
  productCategory: 'Categoria',
  defaultPrice: 'Preço padrão',
  averagePrice: 'Preço médio',
  pointOfSaleSystem: 'Sist. de pagamento',
  modelManufacturer: 'Modelo / Fabricante',
  productNotRegistered: 'Produtos não cadastrados',
  salesSummary: 'Resumo de vendas',
  loading: 'Carregando',
  salesReport: 'Relatório de Vendas',
  productReport: 'Relatório de Produtos',
  totalSalesByPos: 'Total de vendas por PDV',
  productSales: 'Vendas por produto',
  searchPlaceholder: 'Procurar produto',
  quantity: 'Quantidade',
  value: 'Valor',
  location: 'Localização',
};
