
import Vue from 'vue';

export default Vue.extend({
  props: {
    icon: { type: String, default: "mdi-clock-time-four-outline" },
    label: { type: String, required: true },
    clearable: { type: Boolean, default: true },
    value: { required: true },
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    time: {
      get(): string | null {
        return (this as any).value;
      },
      set(value: string | null): void {
        (this as any).$emit("input", value);
      }
    }
  }
})
