<template>
  <help>
    <template #title>{{ $t('security.deviationAlert.title') }}</template>
    <div v-html="$t('security.deviationAlert.help.description')"></div>
    <v-alert border="left" class="text-left" dense outlined text type="info">
            {{$t('security.deviationAlert.help.alert')}}
    </v-alert>
    <help-video help-code="DeviationAlert" />
  </help>
</template>

<script>
import HelpVideo from '@/components/HelpVideo.vue'

export default {
  components: {
    'help-video': HelpVideo
  }
}
</script>
