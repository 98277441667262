export default {
    resetSelectedPickListDetails: 'Restablecer seleccionados',
    showZeroPickListDetails: 'Mostrar ceros',
    searchProductPickListDetails: 'Buscar producto',
    productCodePickListDetails: 'Código de producto',
    productNamePickListDetails: 'Nombre del producto',
    productBarCodePickListDetails: 'Código de barras',
    categoryPickListDetails: 'Categoría',
    quantityPickListDetails: 'Cantidad',
    currentQuantityPickListDetails: 'Cant. actual',
    desiredQuantityPickListDetails: 'Cant. deseada',
    criticalMinimumPickListDetails: 'Mínimo crítico',
    salesThisWeekPickListDetails: 'Ventas últimos 7 días',
    centralStockQuantity: 'Cant. en almacén central',
    closePickListDetails: 'Cerrar',
    pickListInfoPickListDetails: 'Lista de recogida',
    pickListInfoPosDetails: 'PDV',
    pickListInfoPlanogramDetails: 'Planograma',
    pickListInfoCreatedDetails: 'Creada: ',
    pickListInfoUpdatedDetails: 'Actualizada: ',
    changeErrorPickListDetails: 'Error al cambiar la cantidad',
    quantityNotChangedPickListDetails: 'Cantidad no cambiada: formato del número es inválido',
    cancelPickListDetails: 'Cancelar',
    toSavePickListDetails: 'Guardar',
    emptySelectedError: 'Error al restablecer artículos',
    pendingPickListDetails: 'Pendiente',
    usedPickListDetails: 'Usada',
};
