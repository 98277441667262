export default {
    requiredField: 'Este campo é obrigatório',
    numberInteger: 'Insira um número inteiro',
    commonCharacters: 'Insira caracteres válidos',
    minLength: 'Insira pelo menos {min} caracteres',
    noWhitespace:'Espaços em branco não são permitidos',
    phoneField: 'Houve um problema ao verificar o número de telefone. Tente inserir apenas números. Se o problema persistir, entre em contato com a administração pelo número (11) 5199-6668.',
    insertValidValue: 'Insira um valor válido',
    percentageMustBeBetween0And100: 'Porcentagem deve estar entre 0 e 100',
};
