import i18n from "@/locales/i18n";

export default { 
        url: "#",
        headers: { "Cache-Control": "max-age=31536000" },
        method: 'PUT',
        thumbnailWidth: 600,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        acceptedFiles: ".xml",
        maxFiles: 1,
        autoProcessQueue: false,
        dictDefaultMessage: "<i class='mdi mdi-cloud-upload-outline font-size-icon'><br><br></i>" + 
        `<span class='font-size-text'> ${i18n.t('dictDefaultMessageXml')}</span>` +
        `<br><br><br><span class='gray-background-text font-size-selection'><strong>${i18n.t('dictDefaultMessageXmlSelectFile')}</strong></span>`,
        dictFallbackMessage: i18n.t('dictFallbackMessage'),
        dictFileTooBig: i18n.t('dictFileTooBig'),
        dictInvalidFileType: i18n.t('dictInvalidFileTypeXml'),
        dictResponseError: i18n.t('dictResponseErrorXml'),
        dictCancelUpload: i18n.t('dictCancelUpload'),
        dictUploadCanceled: i18n.t('dictUploadCanceled'),
        dictCancelUploadConfirmation: i18n.t('dictCancelUploadConfirmation'),
        dictRemoveFile: '',
        dictMaxFilesExceeded: i18n.t('dictMaxFilesExceeded'),
        dictFileSizeUnits: { tb: "TB", gb: "GB", mb: "MB", kb: "KB", b: "b" },
        init: function() {
          this.on('addedfile', function(file) {

            const previewElement = file.previewElement.querySelector('.dz-progress');
            if (previewElement) {
              previewElement.style.display = 'none';
            }
            if (this.files.length > 1) {
              this.removeFile(this.files[0]);
            }
          });
          this.on('processing', function (file) { 
            const elements = document.getElementsByClassName('dz-progress');
            [].forEach.call(elements, function (element) {
              element.style.visibility = 'visible';
            });

            this.options.url = file.uploadURL;
          });
        },
        sending(file, xhr) {
          const send = xhr.send;
          xhr.send = () => {
            send.call(xhr, file);
          }
        },
}