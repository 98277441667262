export default {
  "promotion": {
    "name": "Nombre de la promoción",
    "type": "Tipo de promoción",
    "starts-on": "Empieza el",
    "expires-on": "Expira el",
    "date-created": "Fecha de creación",
    "usage": "Número de usos",
    "actions": "Acciones",
    "no": "No",
    "created": "Promoción creada",
    "edited": "Promoción editada",
    "no-items": "No hay promociones registradas",
    "remove": {
      "label": "Eliminar",
      "message": "¿Estás seguro de que deseas eliminar la promoción?",
      "success": "Promoción eliminada con éxito",
      "fail": "Error al eliminar la promoción",
    },
    "period": "Período de la promoción",
    "details": {
      "new": {
        "text": "Nueva promoción",
        "error": "Error al guardar la promoción",
      },
      "edit": {
        "text": "Editar promoción",
        "error": "Error al editar la promoción"
      },
      "title": "Promoción {description}",
      "description": "Breve descripción sobre la promoción",
      "starts-on": "Fecha de inicio",
      "starts-on-label": "Fecha de inicio de la promoción",
      "expires-on": "Fecha de finalización",
      "expires-on-label": "Fecha de finalización de la promoción",
      "expires-on-no-date": "Sin fecha de expiración",
      "points-of-sale": {
        "all": "Aplicar en todos los puntos de venta",
        "specific": "Aplicar en puntos de venta específicos",
        "specific-error": "Elige al menos un punto de venta",
        "choose": "Elige los puntos de venta donde se aplicará",
      },
      "product": {
        "add": "Añadir productos",
        "name": "Producto",
        "name-label": "Elige un producto",
        "items": "Cantidad de artículos por compra",
        "items-label": "Unidades del producto",
        "button-add": "Añadir producto",
        "error": {
          "required": "La promoción debe tener al menos un producto",
        },
      },
      "discount": {
        "type": {
          "text": "Tipo de descuento",
          "label": "En porcentaje o valor fijo"
        },
        "invalid": "Valor de descuento inválido",
        "amount": {
          "label": "Valor que se aplicará",
          "percentage": "Valor en porcentaje",
          "fixed": "Valor fijo en",
        },
        "choose": "Selecciona el tipo de descuento",
      },
      "missing-data": "Faltan datos",
    }
  }
}