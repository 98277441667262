
import Vue from 'vue';
import DiscountType from '@/components/filters/DiscountType.vue';
import DatePicker from '@/components/filters/DatePicker.vue';
import ClearButton from '@/components/buttons/ClearButton.vue';
import UpdateButton from '@/components/buttons/UpdateButton.vue';

export default Vue.extend({
  data() {
    return {
      valid: false as boolean,
      selectedDates: [] as string[],
      form: {
        minDate: null as string | null,
        maxDate: null as string | null,
        type: null,
        timezoneOffset: new Date().getTimezoneOffset() as number,
      },
      clearDate: false,
    };
  },
  mounted() {
    this.emitModification();
  },
  methods: {
    applyFilters() {
      this.emitModification();
    },
    filterDateChanged(date : string[]) {
      [this.form.minDate, this.form.maxDate] = date
    },
    clearFilters() {
      this.selectedDates = [];
      this.form.minDate = null;
      this.form.maxDate = null;
      this.clearDate = true;
      this.form.type = null;
      this.emitModification();
    },
    emitModification() {
      this.$emit('apply-filters', this.form);
    }
  },
  components: {
    DiscountType,
    DatePicker,
    ClearButton,
    UpdateButton
  }
});
