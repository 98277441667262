export default {
  newTaxScenario: 'New Fiscal Scenario',
  newTaxScenarioTitle: 'New Fiscal scenario',
  editTaxScenarioTitle: 'Edit fiscal scenario',
  nameLabel: "Name",
  regimeLabel: "Regime",
  cfopLabel: "CFOP",
  stateLabel: 'State',
  cstSubtitle: 'Códigos de Situações Tributárias (CST)',
  icmsLabel: 'ICMS',
  pisLabel: 'PIS',
  cofinsLabel: 'COFINS',
  ipiLabel: 'IPI',
  benefitLabel: 'Fiscal Benefit',
  aliquotasSubtitle: 'Tax Rates (%)',
  closeButton: 'Close',
  saveButton: 'Save',
  exactLengthRule: 'Must have {length} characters',
  maxCharactersRule: 'Limit of {max} characteres',
  requiredRule: 'This field is required',
  floatRule: 'Enter a valid value',
  validCharactersRule: 'Enter valid characters',
}