export default {
    dialogTitleRebootDialog: 'Seleccione la opción de reinicio',
    forcedRestart: '<b> Reinicio forzado: </b> El terminal se reiniciará inmediatamente, interrumpiendo todas las acciones que se estén ejecutando en ese momento.',
    scheduledRestart: '<br><b> Reinicio programado: </b> El terminal se reiniciará solo si está en la pantalla de inicio y no está realizando ninguna operación.',
    warningRebootDialog: 'Esta función solo está disponible para las Moderninhas de PagSeguro. El reinicio programado solo funciona correctamente a partir de la versión 4.1.0 de la aplicación del terminal.',
    forcedRestartButton: 'Reinicio forzado',
    scheduledRestartButton: 'Reinicio programado',
    rebootTerminalSuccessMessage: 'Reinicio enviado al terminal.',
    rebootTerminalErrorMessage: 'No se pudo reiniciar el terminal, inténtelo nuevamente más tarde.',
};
