export default {
    seeMoreCardTitle: 'Market Information',
    seeMoreButtonText: 'Learn more',
    policyCardTitle: 'Privacy Policies',
    policyCardOutdatedAlert: 'The version of the Consent Agreement you agreed to is outdated. To access Market Intelligence, you need to agree to the new Consent Agreement.',
    policyCardDownloadButton: 'Download',
    policyCardHideButton: 'Hide',
    policyCardAcceptButton: 'I Accept',
    posCardUpdateTitle: 'Updating Your POS',
    posCardUpdateInstructions: 'To complete the display of market information, it is necessary to update the fields',
    posCardUpdateInstructionsLocalType: 'TLocation Type',
    posCardUpdateInstructionsNumberOfPeople: 'Amount of people',
    posCardUpdateInstructionsAndOr: 'and/or',
    posCardUpdateInstructionsFollowingPos: 'from the following POS: ',
    posCardAllUpdatedMessage: 'All POS have been updated!',
    posCardBackButton: 'Back',
    posCardFinishButton: 'Done',
    posCardUpdateButtonText: 'Update',
    errorLoadingTerms: 'There was an error loading the Market Intelligence consent terms. Try reloading the page.',
    getPrivacyPolicyError: 'There was an error loading the Consent Form',
    getIncompletePosError: 'Unable to load pending points of sale',
    putUserAuthorizationError: 'Unable to register acceptance of the Consent Form',
    putAllPosRegisteredError: 'Try again later. There was an error in confirmation',
    updatePosData: 'Update POS data',
    termsOfAcceptingInformation: `To access the information, you must accept the terms of use and fill in the requested data. Click in <strong style="color: #1976D2">ACCESS TERMS</strong>.`,
    accessThermosButton: 'ACCESS TERMS',
  };
  