export default {
    recentTransactionsRemoteCredit: 'Latest transactions',
    dispensedRemoteCredit: 'Dismissed',
    amountRemoteCredit: 'Amount',
    methodRemoteCredit: 'Method',
    cardRemoteCredit: 'Card',
    productRemoteCredit: 'Product',
    dateTimeRemoteCredit: 'Date/time',
    creditTransferRemoteCredit: 'Sending credits',
    quantityRemoteCredit: 'Quantity',
    resetCreditsRemoteCredit: 'Reset credits',
    closeRemoteCredit: 'Close',
    sendRemoteCredit: 'Send',
    noResultsRemoteCredit: 'No results',
    creditsSentRemoteCredit: 'Credits forwarded',
    creditsReceivedRemoteCredit: 'Credits received',
    creditsUsedRemoteCredit: 'Credits used',
    errorSendingCredits: 'Error sending credits',
    errorUsingRemoteCredit: 'Error using remote credit',
    paymentMethodCreditRemoteCredit: 'Credit',
    paymentMethodDebitRemoteCredit: 'Debit',
    paymentMethodVoucherRemoteCredit: 'Voucher',
    remotePaymentMethod: 'Remote',
    paymentMethodKindRemoteCredit: 'Kind',
    runknownRemoteCredit: 'Unknown',
    failedToDismissRemoteCredit: 'Failed to dismiss',
    reversedRemoteCredit: 'Reversed',
    reversalCancelledRemoteCredit: 'Reversal cancelled',
    errorReversingRemoteCredit: 'Error reversing',
    productNotSelectedRemoteCredit: 'Product not selected',
  };