export default {
    productSalesCardTitle: 'Product Inquiry',
    productSalesInfoStateLabel: 'State',
    searchProductPlaceholder: 'Search for your product here',
    productRankingLabel: 'Product Ranking',
    maxMarketPriceLabel: 'Maximum market price',
    minMarketPriceLabel: 'Minimum market price',
    averageMarketPriceLabel: 'Average market price',
    productSalesNoResultsMessage: 'No results',
    noDescriptionMessage: 'No description for the barcode',
    noImageAvailable: 'No image available',
    getProductPerformanceError: 'Error loading product sales: Product was not sold in the filtered period',
    getProductPerformanceProductSalesError: 'Error loading product sales',
    getProductDescriptionError: 'Error loading product name and image: Product not registered in the system.',
    insertBarcode: 'Enter a barcode:',
    productSalesLabel: ' sales',
    standardPrice: 'Standard price in the system',
    nullBarCodeWarningMessage: `To perform the search, we need the product's registered barcode. This
        product does not have a registered barcode in your environment. Add a barcode to the product to be able to search for it.`,
    clickHere: "Click here",
    toEditTheProduct: "to edit the product.",
};