export default {
  deleteNonUsedButton: "Excluir não utilizados",
  deleteTaxScenarioDeleteDialogText: "Tem certeza de que deseja deletar esse cenário fiscal?",
  cancelButtonDeleteDialog: "Cancelar",
  deleteButtonDeleteDialog: "Deletar",
  headerIsInvoiceDataComplete: "Status",
  headerId: "Id",
  headerName: "Nome",
  headerFiscalRegion: 'Região Fiscal',
  headerTaxation: "Regime Fiscal",
  headerCountryTax: "Imp. País (%)",
  headerStateTax: "Imp. Estado (%)",
  headerCountyTax: "Imp. Condado (%)",
  headerNumberOfProducts: "Nº de Produtos",
  headerActions: "Ações",
  dataTableNoDataText: "Sem cenários fiscais cadastrados",
  textFieldSearchText: "Procurar"
}