<template>
  <v-card class="mb-16">
    <v-card-title>
      <!-- <div class="title">{{ $t('myProducts') }}</div>
      <v-spacer></v-spacer> -->

      <portal to="toolbarTitle"><products-help></products-help></portal>

      <v-row no-gutters>
        <v-col cols="10" sm="4" lg="4" class="d-flex justify-start my-2">
          <v-form ref="form" lazy-validation v-model="search.valid" @submit.prevent="getProducts">
            <v-text-field
              v-model="search.text"
              append-icon="search"
              :label="$t('searchProduct')"
              single-line
              hide-details
              clearable
              filled
              rounded
              dense
              @click:clear="search.text = ''; getProducts()"
              @click:append="options.page = 1; getProducts()"
              @input="options.page = 1"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="9" sm="4" lg="4" class="d-flex justify-end ml-14 mr-8 my-2">
          <v-switch
            class="mt-1"
            hide-details
            :label="$t('viewProductDeleted')"
            v-model="viewDeleted"
            @change="getProducts"
          >
          </v-switch>
        </v-col>
        <v-col cols="6" sm="2" lg="1" class="d-flex justify-end mr-8 my-2">
          <v-btn
            :loading="loading.excel"
            color="info"
            @click.native="getExcel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              {{ $t('excelProduct') }}
            </v-btn>
        </v-col>
        <v-col cols="6" sm="2" lg="1" class="ml-16 d-flex justify-end ml-0 pl-0 my-2">
          <product-dialog
            ref="itemDialog"
            v-model="itemDialog"
            :edited-index="editedIndex"
            :edited-entry="editedEntry"
            :product-registration-disabled="productRegistrationDisabled"
            :pos-link="queryParams.pdv"
            :planogram-link="queryParams.pog"
            @add="onProductAdded"
            @update="onProductUpdated"
            @error="handleError"
            @image-updated="onImageUpdated"
          ></product-dialog>
        </v-col>
      </v-row>

    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      :loading="loading.products"
      :headers="headers"
      :items="products"
      :item-class="getItemClass"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="$t('noItemsRegisteredProduct')"
      :footer-props="{ itemsPerPageOptions: [10, 20, 30] }"
    >
    <template #[`item.image`]="{ item }">
      <v-img
        class="py-2"
        v-if="item.imageUrl != null"
        :src="item.imageUrl"
        max-width="150"
        max-height="150"
        contain
      ></v-img>
    </template>
    <template #[`item.defaultPrice`]="{ item }">
      {{ $currencyFormatter.format(item.defaultPrice) }}
    </template>
    <template #[`item.groupId`]="{ item }">
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn text icon class="mx-0" v-on="on">
            <v-icon v-if="item.groupId == null">people</v-icon>
            <v-icon v-else>person</v-icon>
          </v-btn>
        </template>
        <span v-if="item.groupId == null">{{ $t('groupIdPublicProduct') }}</span>
        <span v-else>{{ $t('groupIdGroupProduct') }} {{item.groupId}}</span>
      </v-tooltip>
    </template>
    <template #[`item.actions`]="{ item }" class="justify-center px-0">
      <td class="nobr-products-page">
        <v-btn :disabled="!item.isActivated" icon class="mx-0 nobr-products-page" @click="editItem(item)">
            <v-icon color="info">edit</v-icon>
        </v-btn>

        <v-tooltip left>
          <template #activator="{ on }">
            <div v-on="on" class="display: d-inline-block nobr-products-page">
              <v-btn @click="copyItem(item)" class="mx-0 nobr-products-page" icon :disabled="productRegistrationDisabled || !item.isActivated">
                <v-icon color="success">mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="productRegistrationDisabled">{{ $t('productsMustBeAddedErp') }}</span>
          <span v-else>{{ $t('copyProduct') }}</span>
        </v-tooltip>

        <v-btn icon class="mx-0 nobr-products-page" @click.native.stop="confirmRemoval(item)" v-if="!item.isExternal && item.isActivated">
            <v-icon color="error">delete</v-icon>
        </v-btn>
        <v-btn icon class="mx-0 nobr-products-page" @click.native.stop="activeProduct(item.id)" v-if="!productRegistrationDisabled && !item.isActivated">
            <v-icon color="success">mdi-delete-restore</v-icon>
        </v-btn>
      </td>
    </template>
    <template #[`item.dateCreated`]="{ item }">
      {{ formatDateTimeMinutes(item.dateCreated) }}
    </template>
    </v-data-table>

    <feedback ref="feedback"></feedback>

    <!-- Remove dialog -->
    <v-dialog v-model="removeDialog.enabled" max-width="400">
      <v-card>
        <v-card-text class="pt-4">{{ $t('confirmRemoveProduct') }} {{removeDialog.item.description}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="removeDialog.enabled = false">{{ $t('noProduct') }}</v-btn>
          <v-btn color="green darken-1" :loading="loading.removeItem" text @click.native="deleteItem(removeDialog.item)">{{ $t('removeProduct') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <download-dialog
      v-model="downloadDialog.show"
      :fileName="downloadDialog.fileName"
      @update="(value) => downloadDialog.show = value"
    />

  </v-card>
</template>

<script>
import ProductDialog from '@/views/products/ProductDialog';
import { shouldRefreshData, toApiPagination } from '@/components/TableOptionsComparator';
import agent from '@/api/agent';
import DownloadDialog from '@/components/common/DownloadDialog.vue';
import { formatDateTimeMinutes } from '@/utility/TextFormatting';
import ProductsHelp from './ProductsHelp.vue';

export default {
  data() {
    return {
      products: [],
      itemDialog: false,
      editedEntry: { productTaxGroups: [] },
      editedIndex: -1,
      productRegistrationDisabled: false,
      headers: [
        { text: this.$t('imageProduct'), align: 'left', sortable: false, value: 'image' },
        { text: this.$t('idProduct'), align: 'left', sortable: true, value: 'id' },
        { text: this.$t('codeProduct'), align: 'left', sortable: true, value: 'code' },
        { text: this.$t('descriptionProduct'), align: 'left', sortable: true, value: 'description' },
        { text: this.$t('categoryProduct'), align: 'left', sortable: true, value: 'categoryName' },
        { text: this.$t('defaultPriceProduct'), align: 'left', sortable: true, value: 'defaultPrice' },
        { text: this.$t('barCodeProduct'), align: 'left', sortable: true, value: 'barCode' },
        { text: this.$t('dateCreatedProduct'), align: 'left', sortable: true, value: 'dateCreated' },
        { text: this.$t('availabilityProduct'), align: 'left', sortable: true, value: 'groupId' },
        { text: this.$t('actionsProduct'), align: 'left', sortable: false , value: 'actions' }
      ],
      loading: {
        products: true,
        removeItem: false,
        newProduct: true,
        excel: false
      },
      removeDialog: {
        enabled: false,
        item: {}
      },
      search: {
        text: '',
        valid: true
      },
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true
      },
      queryParams: {
        id: null,
        pdv: null,
        pog: null
      },
      downloadDialog: {
        show: false,
        fileName: ""
      },
      viewDeleted: false,
    }
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (shouldRefreshData(newOptions, oldOptions)) {
          this.getProducts();
        }
      },
      deep: true
    },
    itemDialog(isOpen) {
      if (!isOpen) {
        this.editedIndex = -1;
        this.editedEntry = { productTaxGroups: [] };
      }
    }
  },
  mounted() {
    const params = Object.assign({}, this.$route.query);
    if (Object.keys(params).length != 0) {
      this.queryParams.id = params.id
      this.queryParams.pdv = params.pdv
      this.queryParams.pog = params.pog
      delete params.id
      delete params.pdv
      delete params.pog
      this.$router.replace({ query: params });
    }

    this.getProducts();
  },
  methods: {
    getItemClass(item) {
      return item.isActivated ? '' : 'deleted-item-products-page';
    },
    activeProduct(productId){
      agent.Products.activeProductById(productId)
        .then(() => {
          this.handleSuccess(this.$t('productActivatedSuccess'));
        })
        .catch((error) => { 
          this.handleError(this.$t('errorActivateProduct'), error)
        }).finally(() => {
          this.getProducts();
        });
    },
    getProducts() {
      this.loading.products = true;
      let params = {
        ...toApiPagination(this.options, this.search.text),
        viewDeleted: this.viewDeleted
      };

      this.axios.get('api/Products', { params: params })
        .then((response) => {
          this.loading.products = false;
          this.products = response.data.items;
          this.totalItems = response.data.totalItems;
          this.productRegistrationDisabled = response.data.disableProductRegistration;
          this.loading.newProduct = false;
        });

      if (this.queryParams.id) {
        this.editItemByRouteId(this.queryParams.id)
      }
    },
    getExcel () {
      this.loading.excel = true;

      agent.Products.excel()
          .then((response) => {
            this.downloadDialog.fileName = response;
            this.downloadDialog.show = true;
          })
          .catch((error) => this.handleError(this.$t('errorGenerateExcelProduct'), error))
          .finally(() => {
            this.loading.excel = false;
          });
    },
    editItemByRouteId(id){
      this.axios.get('api/Products/' + id)
        .then((response) => {
          this.editedIndex = -2; // is existing item from route
          this.editedEntry = Object.assign({}, response.data);
        });
    },
    editItem(item){
      this.editedIndex = this.products.indexOf(item);
      this.editedEntry = JSON.parse(JSON.stringify(item));
      this.itemDialog = true;
    },
    onProductAdded(product) {
      this.products.push(product);
      this.editItem(product);
      this.handleSuccess(this.$t('productSavedSuccessProduct'));
    },
    onProductUpdated(product) {
      let existingProduct = this.products.find(p => p.id === product.id);
      if (product) {
        Object.assign(existingProduct, product);
      } else {
        this.products.push(product);
        this.editItem(product);
      }
      this.handleSuccess(this.$t('productUpdatedSuccessProduct'));
    },
    onImageUpdated(productId, url) {
      const product = this.products.find(p => p.id == productId);
      if (product != null) {
        product.imageUrl = url;
      }
      if (this.editedEntry.id === productId) {
        this.editedEntry.imageUrl = url;
      }
    },
    confirmRemoval(item) {
      this.removeDialog.item = item;
      this.removeDialog.enabled = true;
    },
    deleteItem(item) {
      this.loading.removeItem = true;
      this.axios.delete(`api/Products/${item.id}`)
        .then(() => {
          this.handleSuccess(this.$t('itemRemovedProduct'));
        }).catch((error) => this.handleError(this.$t('errorRemoveItemProduct'), error))
          .finally(() => {
            this.getProducts();
            this.loading.removeItem = false;
          });
    },
    handleSuccess(message) {
      this.$refs.feedback.handleSuccess(message);
      this.removeDialog.enabled = false;
    },
    handleError(message, error) {
      this.$refs.feedback.handleError(message, error);
    },
    copyItem(item) {
      this.editedEntry = Object.assign({}, item);
      this.editedEntry.code = null;
      this.editedEntry.description = "";
      this.editedEntry.barCode = null;
      this.editedEntry.imageUrl = null;
      this.editedEntry.isExternal = false;
      delete this.editedEntry.externalId;
      delete this.editedEntry.id;

      this.editedIndex = -1;
      this.itemDialog = true;
      this.$refs.itemDialog.$refs.form.resetValidation();
    },
    formatDateTimeMinutes,
  },
  components: {
    ProductDialog,
    DownloadDialog,
    ProductsHelp
  }
}
</script>

<style>
.nobr-products-page { white-space: nowrap }
.deleted-item-products-page {
  background-color: #f2f2f2;
  color: #888; 
}
</style>
