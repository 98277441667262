// noinspection SpellCheckingInspection

import i18n from "@/locales/i18n";

export const posMachineStatus = {
  OK: i18n.t('okPosMachineStatus'),
  NoLauncher: i18n.t('noLauncherPosMachineStatus'),
  NoPower: i18n.t('noPowerPosMachineStatus'),
  MaybeOutOfService: i18n.t('maybeOutOfServicePosMachineStatus'),
  MaybeDisconnected: i18n.t('maybeDisconnectedPosMachineStatus'),
  MaybeCardReaderFail: i18n.t('maybeCardReaderFailPosMachineStatus'),
  OutOfService: i18n.t('outOfServicePosMachineStatus'),
  ScreenOff: i18n.t('screenOffPosMachineStatus'),
  CardReaderFail: i18n.t('cardReaderFailPosMachineStatus'),
  Disconnected: i18n.t('disconnectedPosMachineStatus'),
  OutOfServiceNoPower: i18n.t('outOfServiceNoPowerPosMachineStatus'),
  OutOfServiceMachine: i18n.t('outOfServiceMachinePosMachineStatus'),
  DeadBattery: i18n.t('deadBatteryPosMachineStatus'),
};

// README
// From OutOfService to DeadBattery, they use the method getOutOfServiceText. See Telemetry for future full use on refctoring Telemetry.vue.